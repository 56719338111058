import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import utils from 'utils';
import { DISCOUNTS } from 'constants';

const discountMessages = {
  [DISCOUNTS.PROMOTION_TYPE]: utils.i18n('promotion_applied'),
  [DISCOUNTS.CONTRACT_TYPE]: utils.i18n('contract_applied'),
  [DISCOUNTS.COUPON_TYPE_DISCOUNT]: utils.i18n('coupon_applied'),
  [DISCOUNTS.COUPON_TYPE_UPGRADE]: utils.i18n('coupon_applied'),
  [DISCOUNTS.COUPON_TYPE_FREE_DAY]: utils.i18n('coupon_applied'),
  [DISCOUNTS.CORPORATE_TYPE]: utils.i18n('coupon_applied'),
};

const discountClassNames = {
  [DISCOUNTS.PROMOTION_TYPE]: 'discount-notification--price-tag',
  [DISCOUNTS.COUPON_TYPE_DISCOUNT]: 'discount-notification--price-tag',
  [DISCOUNTS.COUPON_TYPE_UPGRADE]: 'discount-notification--price-tag',
  [DISCOUNTS.COUPON_TYPE_FREE_DAY]: 'discount-notification--price-tag',
  [DISCOUNTS.CORPORATE_TYPE]: 'discount-notification--star',
};

/**
 * DiscountNotification component shows the discount banner on Vehicle Select for CIDs and Alamo Insider
 *
 * @param {object}    props - React Props
 * @param {string}    props.type - ["promotion" or  "corporate"] discount type determines styling and copy
 * @param {string}    props.contractName - contract name to show in banner message
 * @param {boolean}   props.defaultLoyaltyContract - indicates whether contract is Alamo Insiders
 * @return {JSX}      DiscountNotification component
 */
const DiscountNotification = ({ type, contractName, defaultLoyaltyContract }) => (
  <div className={cn('discount-notification', discountClassNames[type])}>
    <span className='discount-notification__message'>
      {defaultLoyaltyContract
        ? utils.i18n('alamo_insider_discount_banner')
        : `${discountMessages[type]} ${contractName}`}
    </span>
  </div>
);

DiscountNotification.propTypes = {
  type: PropTypes.string.isRequired,
  contractName: PropTypes.string,
  defaultLoyaltyContract: PropTypes.bool,
};

export default DiscountNotification;
