import React, { useEffect, useState } from 'react';

const VehicleDetailsCardImage = React.memo(({ carImageSrc }) => {
  const [imageSrc, setImageSrc] = useState('');
  useEffect(() => {
    setImageSrc(carImageSrc);
  }, []);
  return <img src={imageSrc} loading='lazy' alt='' width='640' height='480' />;
});

export default VehicleDetailsCardImage;
