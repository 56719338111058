import PropTypes from 'prop-types';

export const propTypes = {
  isOtherVehicle: PropTypes.bool,
  captionText: PropTypes.string,
  copyText: PropTypes.string,
  images: PropTypes.object,
  carAssetMobile: PropTypes.string,
  carAssetTablet: PropTypes.string,
  electric: PropTypes.string,
  transmission: PropTypes.string,
  peopleCapacity: PropTypes.string,
  luggageCapacity: PropTypes.string,
  url: PropTypes.string.isRequired,
  twoOrFourDoorText: PropTypes.string,
};

export const defaultProps = {
  isOtherVehicle: false,
};
