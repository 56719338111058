import { connect } from 'react-redux';
import utils from 'utils';
import { actions } from '@ehi/global-marketing-interface';
import {
  appSelectedLocationPath,
  appLocationFinderBrandsPath,
  appShowingPartnerLocationsPath,
  appShowSoldOutNerabyLocationsPath,
} from 'paths/app';
import MODAL from 'constants/modals';
import { resolveBranchURL } from 'actions/locationSearch';
import {
  setLocation,
  unsetLocation,
  setLocationData,
  unsetLocationData,
  fetchLocations,
  setLocationDataUnavailableLocationModal,
  showPartnerResults,
  setCurrentTimestamp,
  composeFilters,
} from 'actions/locationFinder';
import { setMobileExpanded, setExpandedTab } from 'actions/progressBar';
import { breakpointSelector } from 'selectors/breakpoint';
import { getInitiatePickupTime, getInitiateReturnTime } from 'selectors/reservationSelectors';
import { getAppLocationFinderTimestamp, getUpdatedSoldOutDateTime, getAdjustLocationId } from 'selectors/appSelectors';
import { isLocationWithNoVehiclesAvailableSelector, isPickupTimeInThePastSelector } from 'selectors/errorSelectors';
import { uiInitiateLocationsSelector, locationTypeFilterVehicleCodes, numFiltersSelectedFilterSelector, currentFiltersSelector } from 'selectors/uiStateSelectors';
import { getLocationFilters } from 'selectors/locationSelectSelectors';
import { getProgressBarExpandedTab, getProgressBarMobileExpanded } from 'selectors/reservationFlowSelectors';
import { getNumOfSelectedVehiclesCountSelector } from 'selectors/vehicleSelectSelectors';
import { isInitiateDateTimeisChanged, dateTimeInitiateRequestValuesSelector } from 'selectors/progressBarSelectors';
import { updateReservationFlowLocation } from "actions/reservation/updateReservationFlowLocation";
import { handleClearAllFilter } from "actions/filter/handleClearAllFilter";
import { openModal } from "actions/modal/openModal";
import LocationFinder from './LocationFinder';

const GMI_CONFIG = utils.config.getGMIConfig();
const storeKey = 'location_finder';

const mapStateToProps = (state, ownProps) => {
  const selectedLocation = ownProps.selectedLocation || state.getIn(appSelectedLocationPath);
  const locationsOnVehicleClassSelected = locationTypeFilterVehicleCodes(state, storeKey);
  const numFiltersSelectedFilter = numFiltersSelectedFilterSelector(state, storeKey);
  const currentFilters = currentFiltersSelector(state, storeKey)
  const getInitiateDateTimeisChanged = isInitiateDateTimeisChanged(state);
  const getDateTimeInitiateRequestValuesSelector = dateTimeInitiateRequestValuesSelector(state);

  const pickup_time = getInitiatePickupTime(state);
  const return_time = getInitiateReturnTime(state);

  const pickup_time_split = pickup_time && pickup_time.split('T');
  const return_time_split = return_time && return_time.split('T');

  const pickupDate = pickup_time_split && pickup_time_split[0];
  const pickupTime = pickup_time_split && pickup_time_split[1];
  const dropoffDate = return_time_split && return_time_split[0];
  const dropoffTime = return_time_split && return_time_split[1];

  const { uiPickupLocation } = uiInitiateLocationsSelector(state);

  const numOfVehiclesSelectedCount = getNumOfSelectedVehiclesCountSelector(state);
  const isAvailableLocations = numOfVehiclesSelectedCount > 0 ? true : !!locationsOnVehicleClassSelected.length;

  return {
    brands_in_result: state.getIn(appLocationFinderBrandsPath),
    breakpoint: breakpointSelector(state),
    locations: locationsOnVehicleClassSelected,
    selectedLocation,
    uiPickupLocation,
    defaultCountry: GMI_CONFIG.COUNTRY,
    showingPartnerLocations: state.getIn(appShowingPartnerLocationsPath),
    storeKey,
    reservationInfo: { pickupDate, pickupTime, dropoffDate, dropoffTime },
    countryOfResidence: state.getIn(['gmi', 'session', 'gma', 'country_of_residence', 'domain_value']),
    timestamp: getAppLocationFinderTimestamp(state),
    showSoldOutNearbyLocations: state.getIn(appShowSoldOutNerabyLocationsPath),
    isLocationWithNoVehiclesAvailable: isLocationWithNoVehiclesAvailableSelector(state),
    locationFilters: getLocationFilters(state, ownProps.reservationFlow),
    updatedSoldOutDateTime: getUpdatedSoldOutDateTime(state),
    adjustLocationId: getAdjustLocationId(state),
    isPickupTimeInThePast: isPickupTimeInThePastSelector(state),
    isProgressBarExpanded: getProgressBarExpandedTab(state),
    isProgressBarMobileExpanded: getProgressBarMobileExpanded(state),
    carClassAvailability: locationsOnVehicleClassSelected,
    numOfVehicleSelected: getNumOfSelectedVehiclesCountSelector(state),
    numFiltersSelected: numFiltersSelectedFilter,
    currentFilters,
    isAvailableLocations,
    isInitiateDateTimeisChanged: getInitiateDateTimeisChanged,
    isDateTimeInitiateRequestValues: getDateTimeInitiateRequestValuesSelector,
  };
};

const mapDispatchToProps = {
  fetchLocations,
  composeFilters,
  setLocation,
  unsetLocation,
  setLocationData,
  unsetLocationData,
  openModal,
  setLocationDataUnavailableLocationModal,
  showPartnerResults,
  resolveBranchURL,
  setCurrentTimestamp,
  openSoldOutModal: () => openModal(MODAL.SOLD_OUT_MODAL),
  updateReservationFlowLocation,
  dispatchMobileExpanded: expanded => setMobileExpanded(expanded),
  dispatchExpandedTab: id => setExpandedTab(id),
  showVehicleClassFilterModal: () => openModal(MODAL.VEHICLE_CLASS_FILTER_MODAL),
  showLocationTypeFilterModal: () => openModal(MODAL.LOCATION_TYPE_FILTER_MODAL),
  onDateChange: (timestamp, exchangeType) =>
    actions.reservations.initiateState.set('date', { timestamp, exchangeType }),
  onTimeChange: (timestamp, exchangeType) =>
    actions.reservations.initiateState.set('time', { timestamp, exchangeType }),
    handleClearAllFilter,
};

const enhance = utils.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  utils.toJS
);

export default enhance(LocationFinder);
