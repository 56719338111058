import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { inflightPaymentSelector, selectedPaymentMethodSelector } from 'selectors/reservationSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import CreditCardDetails from 'components/CreditCardDetails';
import { removeInflightPaymentDetails } from "actions/reservation/removeInflightPaymentDetails";

/**
 * STCPaymentCardInfo
 * Section for STC Payment Information tile with Payment Card details and legal copy
 *
 * @param {object} props - React props
 * @param {boolean} props.isRemovable - Is the payment card info removable?
 *
 * @return {JSX}
 */
const STCPaymentCardInfo = ({ isRemovable }) => {
  const inflightPaymentDetails = useSelector(inflightPaymentSelector);
  const selectedPaymentMethodDetails = useSelector(selectedPaymentMethodSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const handleRemoveInflightPaymentDetails = useActions(() =>
    removeInflightPaymentDetails({ payment: true, cardSubmissionUrl: true })
  );

  return (
    <div className='skip-the-counter-flow-payment__card-info'>
      <CreditCardDetails
        cardDetails={inflightPaymentDetails || selectedPaymentMethodDetails}
        remove={isRemovable && handleRemoveInflightPaymentDetails}
        removeDTMTrack='check_in|counter|remove_payment'
      />
      <p className='skip-the-counter-flow-payment__legal-copy skip-the-counter-flow-payment__legal-copy--compact-mobile'>
        {isCustomPathReservation
          ? utils.i18n('stc_payment_information_copy_custom_path')
          : utils.i18n('stc_payment_information_copy')}
      </p>
    </div>
  );
};

STCPaymentCardInfo.propTypes = {
  isRemovable: PropTypes.bool,
};

export default STCPaymentCardInfo;
