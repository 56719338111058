import React, { Component } from 'react';
import utils from 'utils';
import { THEMES } from 'constants';
import TextField from 'components/Form/TextField';
import proptypes from './PartnerRewardsFieldsPropTypes';

class PartnerRewardsFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.partnerPogramUUID = utils.dom.uuid();
  }

  static propTypes = proptypes;

  handleOnChange = e => {
    const { value } = e.target;
    this.setState({ value }, () => {
      utils.safeExecute(this.props.setPartnerMemberNumber, value);
    });
  };

  render() {
    const { submitFailed } = this.props;
    const { value } = this.state;
    const inputHasError = submitFailed && !value;

    return (
      <>
        <label className='partner-rewards__label'>{utils.i18n('partner_rewards_label')}</label>
        <TextField
          id={`partnerProgram-${this.partnerPogramUUID}`}
          label={utils.i18n('partner_rewards_program_label')}
          name='partnerRewardsProgram'
          required
          readOnly
        />
        <TextField
          id={`memberId-${this.partnerPogramUUID}`}
          label={utils.i18n('partner_rewards_member_number_label')}
          name='partnerRewardsProgramMemberId'
          onChange={this.handleOnChange}
          required='Member Number Required'
          errorTheme={THEMES.LIGHT_BLUE}
          forceError={inputHasError}
        />
      </>
    );
  }
}

export default PartnerRewardsFields;
