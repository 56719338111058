import { connect } from 'react-redux';
import utils from 'utils';
import { breakpointSelector } from 'selectors';
import { selectedCarClassCoupons } from 'selectors/reservationFlowSelectors';
import ProgressBarVehicleDrawer from './ProgressBarVehicleDrawer';

const carClassDetailCursor = ['gmi', 'session', 'gbo', 'reservation', 'car_class_details'];

const mapStateToProps = state => {
  const carClassDetails = state.getIn(carClassDetailCursor);
  const extras = state.getIn(['gmi', 'ui', 'reservations', 'extras'])?.toJS() || [];

  return {
    vehicleName: carClassDetails?.get('name'),
    code: carClassDetails?.get('code'),
    makeModelOrSimilarText: carClassDetails?.get('make_model_or_similar_text'),
    features: carClassDetails?.get('features'),
    peopleCapacity: carClassDetails?.get('people_capacity'),
    luggageCapacity: carClassDetails?.get('luggage_capacity'),
    twoOrFourDoorText: carClassDetails?.get('two_or_four_door_text'),
    images: carClassDetails?.get('images'),
    numberOfExtrasSelected: extras.length || 0,
    breakpoint: breakpointSelector(state),
    coupons: selectedCarClassCoupons(state),
  };
};

export default connect(mapStateToProps)(utils.toJS(ProgressBarVehicleDrawer));
