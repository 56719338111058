import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const ConfirmationAnimation = ({ className, srcImage, altText, disabledAnimationEffect = false }) => (
  /**
   * @param {string} srcImage - Image source url
   * @param {string} altText - Image alt text
   * @param {boolean} disabledAnimationEffect - Disable animation effect -- Default (False)
   * @return {JSX}
   */

  <div className={cn('animation_static-image-wrapper', { animation_off: disabledAnimationEffect })}>
    <img className={`animation_static-image ${className}`} src={srcImage} alt={altText} />
    {!disabledAnimationEffect && (
      <div className='animation_confetti-group'>
        <div className='ring' />
        <div className='confetti' />
        <div className='confetti two' />
        <div className='confetti three' />
        <div className='confetti four' />
        <div className='confetti--purple' />
        <div className='confetti--purple two' />
        <div className='confetti--purple three' />
        <div className='confetti--purple four' />
      </div>
    )}
  </div>
);

ConfirmationAnimation.propTypes = {
  /**
   * Error Message
   */
  srcImage: PropTypes.string,
  disabledAnimationEffect: PropTypes.bool,
  altText: PropTypes.string,
};

export default ConfirmationAnimation;
