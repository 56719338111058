import React from 'react';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import utils from 'utils';

/**
 * LearnMoreAboutSkipTheCounterIneligibleModal - Show a description of ineligible skip the counter
 * @return {JSX} LearnMoreAboutSkipTheCounterIneligibleModal component
 */

const LearnMoreAboutSkipTheCounterIneligibleModal = () => (
  <Modal
    modalKey={MODAL.LEARN_MORE_ABOUT_SKIP_THE_COUNTER_INELIGIBLE_MODAL}
    customClass='oci-stc-modal'
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('learn_more_about_skip_the_counter_modal_title')}>
    <>
      <p>{utils.i18n('trip_card_skip_the_counter_not_eligible_description')}</p>

      <p>{utils.i18n('confirmation_stc_show_more_ineligible_list_description')}</p>
      <ul>
        <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_1')}</li>
        <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_2')}</li>
        <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_3')}</li>
        <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_4')}</li>
        <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_5')}</li>
      </ul>
    </>
  </Modal>
);

export default LearnMoreAboutSkipTheCounterIneligibleModal;
