import { connect } from 'react-redux';
import utils from 'utils';
import { driverDetailsSelector } from 'selectors/reviewSelectors';
import { profileDataSelector, authenticatedSelector } from 'selectors/profileSelectors';
import { isEuropeanUnionCountry, getModifyFlag } from 'selectors/reservationSelectors';
import DriverDetailsCard from './DriverDetailsCard';

const mapStateToProps = (state) => ({
  isTrueModify: getModifyFlag(state),
  ...driverDetailsSelector(state),
  isEuropeanUnionCountry: isEuropeanUnionCountry(state),
  authUserProfile: profileDataSelector(state, { memberNumber: true, email_preference: true }),
  isAuth: authenticatedSelector(state),
});

export default connect(mapStateToProps)(utils.toJS(DriverDetailsCard));
