import { connect } from 'react-redux';
import utils from 'utils';
import { getAlamoContactUsSupportPhoneNumber } from 'selectors/supportSelectors';
import ExtendRentalInformationModalContent from './ExtendRentalInformationModalContent';

const mapStateToProps = (state, ownProps) => ({
  phoneNumber: getAlamoContactUsSupportPhoneNumber(state, ownProps.country_code)?.phone_number,
});

export default connect(mapStateToProps)(utils.toJS(ExtendRentalInformationModalContent));
