import React, { Component } from 'react';
import cn from 'classnames';
import utils from 'utils';
import { BREAKPOINTS } from 'constants';
import ExtraFlyoutTabDrawer from 'components/ReservationFlow/Extras/ExtraFlyoutTabDrawer';
import FlyoutTab from './FlyoutTab';
import FlyoutTabDrawer from './FlyoutTabDrawer';
import { propTypes, defaultProps } from './FlyoutTabsPropTypes';

const maxItemsPerRow = 4;
class FlyoutTabs extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  state = {
    expandedId: null,
    uuid: utils.dom.uuid(),
  };

  handleExpand = id => () => {
    const expandedId = this.state.expandedId !== id ? id : null;
    this.setState({
      expandedId,
    });
  };

  getParentItemsArray = () => {
    const { items = [] } = this.props;
    const parentItemsArray = [];

    for (let i = 0; i < items.length; i += maxItemsPerRow) {
      parentItemsArray.push(items.slice(i, i + maxItemsPerRow));
    }

    return parentItemsArray;
  };

  render() {
    const { breakpoint, className, contentKey, fullWidth, icon, labelKey, hasClearBelow, isExtrasDrawer } = this.props;
    const { expandedId, uuid } = this.state;
    const isSmall = breakpoint === BREAKPOINTS.SMALL;
    const parentItemsArray = this.getParentItemsArray();
    const TabDrawer = isExtrasDrawer ? ExtraFlyoutTabDrawer : FlyoutTabDrawer;

    return parentItemsArray.map((itemsArray, itemsArray_index) => (
      <section
        key={itemsArray_index}
        className={cn('flyout-tabs', { 'full-width': fullWidth, 'half-width': !fullWidth }, className)}>
        <ul className='flyout-tabs__list'>
          {itemsArray.map((item, index) => {
            const itemId = `${uuid}_${itemsArray_index}_${index}`;

            return (
              <FlyoutTab
                icon={icon}
                item={item}
                isExpanded={itemId === expandedId}
                isSmall={isSmall}
                fullWidth={fullWidth}
                labelKey={labelKey}
                contentKey={contentKey}
                key={itemId}
                onClick={this.handleExpand(itemId)}
                uuid={itemId}
                isExtrasDrawer={isExtrasDrawer}
              />
            );
          })}
        </ul>
        {!isSmall && fullWidth && (
          <ul className='flyout-tabs__drawer-list'>
            {itemsArray.map((item, index) => {
              const itemId = `${uuid}_${itemsArray_index}_${index}`;

              return (
                <li key={itemId}>
                  <TabDrawer
                    contentKey={contentKey}
                    item={item}
                    isExpanded={itemId === expandedId}
                    uuid={itemId}
                    hasClearBelow={hasClearBelow}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </section>
    ));
  }
}

export default FlyoutTabs;
