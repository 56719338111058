import React from 'react';
import { useSelector } from 'react-redux';
import {
  getRentalLastReturnDate,
  getReturnDateAndTime,
  getRentalReturnLocation,
} from 'selectors/rentalTicketSelectors';
import ModifyReturn from './ModifyReturn';

const ModifyReturnContainer = (props) => {
  const lastReturnDate = useSelector(getRentalLastReturnDate);
  const returnDateAndTime = useSelector(getReturnDateAndTime);
  const returnLocation = useSelector(getRentalReturnLocation);

  return (
    <ModifyReturn
      {...{
        returnLocation,
        returnDateAndTime,
        lastReturnDate,
        ...props,
      }}
    />
  );
};

export default ModifyReturnContainer;
