import React from 'react';
import { getFormattedPrice } from 'utils/prices';

const ConfirmModifySummaryTable = (props) => {
  const { sectionItems } = props;

  if (sectionItems?.length > 0) {
    return (
      <div className='modify-return-confirmation__summary-table'>
        {sectionItems.map((item, index) => (
          <div
            key={`modify-return-confirmation__summary-table_${index}`}
            className={`modify-return-confirmation__summary-item ${
              item.bold ? 'modify-return-confirmation__summary-item--bold' : ''
            }`}
          >
            <div>{item.label}</div>
            <div>
              {item.isNegative ? '- ' : ''}
              {getFormattedPrice(item.amount)}
            </div>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export default ConfirmModifySummaryTable;
