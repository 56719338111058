import { connect } from 'react-redux';
import { getUpcomingTrips } from 'actions/trips';
import { upcomingTripsSelector } from 'selectors/tripsSelectors';
import { profileDataSelector } from 'selectors/profileSelectors';
import { MODAL } from 'constants';
import { retrieveReservation } from "actions/reservation/retrieveReservation";
import { openModal } from "actions/modal/openModal";
import UpcomingTrips from './UpcomingTrips';

const mapStateToProps = state => ({
  upcomingTrips: upcomingTripsSelector(state),
  profileData: profileDataSelector(state, { first_name: true, last_name: true }),
});

const mapDispatchToProps = {
  getUpcomingTrips,
  retrieveReservation,
  openMissingTripsModal: () => openModal(MODAL.MISSING_TRIPS_MODAL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingTrips);
