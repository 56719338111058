import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { clearProfile } from 'actions/profile';
import { onlineCheckinProfileAbort } from 'actions/checkin';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES, ANALYTICS } from 'constants';
import Modal from 'components/Modal';
import useActions from 'hooks/useActions';
import useWizardSteps from 'hooks/useWizardSteps';
import { ModalFooter } from 'components/Modal/ModalFooter';
import DriverLookup from 'components/ReservationFlow/InResAcceleratedCheckin/DriverLookup';
import ProfileForm from 'components/ReservationFlow/InResAcceleratedCheckin/ProfileForm';
import SignInPartialProfile from 'components/ReservationFlow/InResAcceleratedCheckin/SignInPartialProfile';
import {
  onlineCheckInStatusSelector,
  isUnauthUserWithLoyaltyProfileSelector,
  isUnauthPartialProfileSelector,
  getHasOnlineCheckInProfile,
  isResFirstNameDifferentThanOciProfileSelector,
} from 'selectors/checkinSelectors';
import { authenticatedSelector } from 'selectors/profileSelectors';
import { closeModal } from "actions/modal/closeModal";

/**
 * stepMap
 * Holds all possible configuration objects for steps in this wizard modal.;
 */
const stepMap = {
  driversLicenseStep: {
    analyticsKey: 'check in driver look up modal',
    title: utils.i18n('check_in_step_driver_lookup'),
    text: `${utils.i18n('check_in_step_driver_lookup_text_1')} ${utils.i18n('check_in_step_driver_lookup_text_2')}`,
    Form: DriverLookup,
    stepId: 'driversLicenseStep',
  },
  noMatchStep: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('check_in_step_profile_add_info'),
    text: `${utils.i18n('check_in_step_profile_not_found_text')} ${utils.i18n(
      'check_in_step_profile_pickup_info_text'
    )}`,
    Form: ProfileForm,
    stepId: 'noMatchStep',
  },
  matchStep: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('in_res_oci_profile_confirm_info'),
    text: `${utils.i18n('check_in_step_profile_found_text')} ${utils.i18n('check_in_step_profile_pickup_info_text')}`,
    Form: ProfileForm,
    stepId: 'matchStep',
  },
  unauthLoyaltyProfileMatchStep: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('in_res_oci_profile_confirm_info'),
    text: `${utils.i18n('check_in_step_profile_loyalty_found_text')} ${utils.i18n(
      'check_in_step_profile_pickup_info_text'
    )}`,
    Form: ProfileForm,
    stepId: 'unauthLoyaltyProfileMatchStep',
  },
  unauthLoyaltyProfileConflictNameMatchStep: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('in_res_oci_profile_confirm_info'),
    text: utils.i18n('check_in_step_profile_loyalty_found_first_name_conflict_description'),
    Form: ProfileForm,
    stepId: 'unauthLoyaltyProfileConflictNameMatchStep',
  },
  // Unauth partial profile found steps
  unauthPartialProfileMatchStep: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('check_in_step_profile_renter_info'),
    text: utils.i18n('check_in_step_partial_profile_found_sign_in_oci_in_res_description'),
    Form: SignInPartialProfile,
    id: 'unauthPartialProfileMatchStep',
  },
  unauthPartialProfileConflicNameMatchStep: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('check_in_step_profile_renter_info'),
    text: utils.i18n('check_in_step_partial_profile_found_first_name_conflict_description'),
    Form: SignInPartialProfile,
    id: 'unauthPartialProfileConflicNameMatchStep',
  },
  unauthPartialProfileSignedIn: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('check_in_step_profile_renter_info'),
    text: utils.i18n('check_in_step_partial_profile_found_signed_in_oci_in_res_description'),
    Form: ProfileForm,
    id: 'unauthPartialProfileSignedIn',
  },
  unauthPartialProfileNotSignedIn: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('check_in_step_profile_renter_info'),
    text: utils.i18n('check_in_step_partial_profile_found_not_signed_in_oci_in_res_description'),
    Form: ProfileForm,
    id: 'unauthPartialProfileNotSignedIn',
  },
};

const getSteps = ({
  profileMatch,
  isUnauthUserWithLoyaltyProfile,
  isResFirstNameDifferentThanOciProfile,
  isUnauthPartialProfileLogic,
  signedIn,
}) => {
  const steps = [stepMap.driversLicenseStep];
  if (isUnauthPartialProfileLogic) {
    isResFirstNameDifferentThanOciProfile
      ? steps.push(stepMap.unauthPartialProfileConflicNameMatchStep)
      : steps.push(stepMap.unauthPartialProfileMatchStep);
    steps.push(signedIn ? stepMap.unauthPartialProfileSignedIn : stepMap.unauthPartialProfileNotSignedIn);
    return steps;
  }
  if (isUnauthUserWithLoyaltyProfile) {
    isResFirstNameDifferentThanOciProfile
      ? steps.push(stepMap.unauthLoyaltyProfileConflictNameMatchStep)
      : steps.push(stepMap.unauthLoyaltyProfileMatchStep);
    return steps;
  }
  steps.push(profileMatch ? stepMap.matchStep : stepMap.noMatchStep);
  return steps;
};

/**
 * InResOciWizardModal
 * Handles DriverLookup and EditProfile forms in a wizard-like, step-by-step modal.
 *
 * @return {JSX} InResOciWizardModal jsx component
 */
const InResOciWizardModal = () => {
  // TODO - preserving firstName here while gma doesn't save it in online_check_in_profile, remove once available in gma
  const [firstName, setFirstName] = useState('');
  const [editingExistingProfile, setEditing] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [clearedProfile, setClearedProfile] = useState(false);
  const [wasUnauthPartialProfileFound, setWasUnauthPartialProfileFound] = useState(false);
  const handleClose = useActions(closeModal);
  const clearProfileAction = useActions(clearProfile);
  const abortOCI = useActions(onlineCheckinProfileAbort);
  const isAuth = useSelector(authenticatedSelector);
  const { driverProfileComplete, contactProfileComplete } = useSelector(onlineCheckInStatusSelector);
  const isUnauthUserWithLoyaltyProfile = useSelector(isUnauthUserWithLoyaltyProfileSelector);
  const isUnauthPartialProfile = useSelector(isUnauthPartialProfileSelector);
  const hasOnlineCheckInProfile = useSelector(getHasOnlineCheckInProfile);
  const isResFirstNameDifferentThanOciProfile = useSelector(isResFirstNameDifferentThanOciProfileSelector);
  const foundCompleteProfile = driverProfileComplete && contactProfileComplete;
  const isUnauthPartialProfileLogic = (isUnauthPartialProfile || wasUnauthPartialProfileFound) && !foundCompleteProfile;

  const inResOciSteps = useMemo(
    () =>
      getSteps({
        profileMatch: driverProfileComplete,
        isUnauthUserWithLoyaltyProfile,
        isResFirstNameDifferentThanOciProfile,
        isUnauthPartialProfileLogic,
        signedIn,
      }),
    [driverProfileComplete, isResFirstNameDifferentThanOciProfile]
  );

  const stepsConfig = useWizardSteps(inResOciSteps, handleClose);
  const { steps, currentStep, resetSteps, isFinalStep, addStep } = stepsConfig;
  const { analyticsKey, title, text, Form, stepId } = steps[currentStep - 1];

  const handleModalClose = useCallback(
    () => {
      setWasUnauthPartialProfileFound(false);
      setClearedProfile(false);
      resetSteps();
      if (hasOnlineCheckInProfile && !hasConfirmed) {
        abortOCI();
      }
    },
    [abortOCI, hasOnlineCheckInProfile, hasConfirmed, resetSteps]
  );

  // resets steps when profile data comes in
  useEffect(
    () => {
      resetSteps(inResOciSteps);
    },
    [inResOciSteps]
  );

  // sets the flow for unauth partial profile found even if the user is going to login in the next step
  useEffect(
    () => {
      isUnauthPartialProfile && setWasUnauthPartialProfileFound(true);
    },
    [isUnauthPartialProfile]
  );

  useEffect(
    () => {
      clearedProfile && addStep();
    },
    [clearedProfile]
  );

  const renderFooter = formSubmit => {
    let saveLabel = 'common_next';
    if (isFinalStep) {
      saveLabel = driverProfileComplete && !editingExistingProfile ? 'common_confirm' : 'common_save';
    }
    const confirmValue = {
      common_next: 'next',
      common_confirm: 'confirm',
      common_save: 'save',
    };
    const buttons = [
      {
        type: MODAL_BUTTON_TYPES.DECLINE,
        label: utils.i18n('common_cancel'),
        analyticsKey,
        handler: () => {
          if (isResFirstNameDifferentThanOciProfile && !isAuth) {
            // On cancel for different name conflict, should treat as no profile found
            clearProfileAction().then(() => setClearedProfile(true));
          } else {
            handleClose();
          }
        },
        analyticsValue: ANALYTICS.CANCEL,
      },
      {
        type: MODAL_BUTTON_TYPES.ACCEPT,
        label: utils.i18n(saveLabel),
        handler: () => {
          formSubmit();
          setHasConfirmed(isFinalStep);
        },
        analyticsHandler: () => utils.analytics.interaction(ANALYTICS.MODAL, analyticsKey, confirmValue[saveLabel]),
      },
    ];

    return <ModalFooter buttons={buttons} disableStickyFooter />;
  };

  const onSubmitSuccess = scrollToModalTop => () => {
    addStep();
    scrollToModalTop();

    if (isFinalStep) {
      // dispatches analyticsRenterExpedited event
      utils.analytics.dispatchEventWithDOM('RenterExpedited');
    }
  };

  const onReset = scrollToModalTop => () => {
    resetSteps();
    scrollToModalTop();
  };

  return (
    <Modal
      modalKey={MODAL.IN_RES_OCI_WIZARD_MODAL}
      header={utils.i18n('accelerated_check_in_modal_title')}
      customClass='in-res-oci-modal'
      theme={MODAL_THEMES.WHITE}
      onClose={handleModalClose}
      dataDtmTrackOpen={`check_in|step${currentStep}_modal|open`}
      dataDtmTrackClose={`check_in|step${currentStep}_modal|close`}
      descId={`${stepId}_desc`}>
      {({ scrollToModalTop }) => (
        <>
          {title && text && (
            <p id={`${stepId}_desc`} className='in-res-oci-modal__description'>
              <span className='in-res-oci-modal__step-label'>
                {utils.i18n('in_res_oci_wizard_modal_steps', [
                  currentStep < 2 ? currentStep : 2,
                  isUnauthPartialProfileLogic ? 2 : steps.length,
                  title,
                ])}
              </span>
              {text}
            </p>
          )}
          <Form
            onSubmitSuccess={onSubmitSuccess(scrollToModalTop)}
            renderFooter={renderFooter}
            setHasConfirmed={setHasConfirmed}
            resetSteps={onReset(scrollToModalTop)}
            scrollToModalTop={scrollToModalTop}
            firstName={firstName}
            setFirstName={setFirstName}
            setEditing={setEditing}
            setSignedIn={setSignedIn}
            isFoundProfileWithConflictName={isResFirstNameDifferentThanOciProfile}
          />
        </>
      )}
    </Modal>
  );
};

export default InResOciWizardModal;
