import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import {
  inPathAdditionalDriversSelector,
  additionalDriverReservationDetailsSelector,
} from 'selectors/reservationFlowSelectors';
import useAdditionalDriversList from 'hooks/useAdditionalDriversList';

const AdditionalDrivers = ({ isThirdPartyReservation }) => {
  const driversList = useSelector(inPathAdditionalDriversSelector);
  const addtlDriverDetails = useSelector(additionalDriverReservationDetailsSelector);
  const { number_of_free_additional_drivers } = addtlDriverDetails;
  const { hasYoungDriverFee, additionalDriverPrices } = useAdditionalDriversList();

  const additionalDriverPrice = (idx) => {
    if (number_of_free_additional_drivers - idx > 0) {
      return utils.i18n('review_additional_drivers_status_included');
    }
    // TODO: replace with secret_rate flag
    if (isThirdPartyReservation && !additionalDriverPrices?.[idx]?.total_amount_view) {
      return utils.i18n('third_party_reservation_net_rate');
    }
    return utils.getFormattedPrice(additionalDriverPrices?.[idx]?.total_amount_view, { asterisk: true });
  };

  return driversList.length > 0 ? (
    <div className='rental-details__section'>
      <table>
        <thead className='rental-details__table-section-label'>
          <tr>
            <th colSpan='2' align='left'>
              {utils.i18n('review_additional_drivers_count', [driversList.length])}
            </th>
          </tr>
        </thead>
        <tbody>
          {driversList.map(({ first_name, last_name, individual_id }, idx) => (
            <React.Fragment key={`${first_name}-${last_name}`}>
              <tr
                key={`${first_name}-${last_name}`}
                className='rental-details__line-item-row driver-list-item__driver-price-row'
              >
                <td align='left'>{`${first_name} ${last_name}`}</td>
                <td align='right'>{additionalDriverPrice(idx)}</td>
              </tr>
              {hasYoungDriverFee(individual_id) && (
                <tr className='rental-details__taxes-line-item-row'>
                  <td colSpan='2' scope='colgroup' align='right' className='driver-list-item__driver-fee'>
                    {utils.i18n('additional_driver_young_driver_fee')}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
};

AdditionalDrivers.propTypes = {
  isThirdPartyReservation: PropTypes.bool,
};

AdditionalDrivers.defaultProps = {
  isThirdPartyReservation: false,
};

export default AdditionalDrivers;
