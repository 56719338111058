import { connect } from 'react-redux';
import { modifyReservationStart } from "actions/reservation/modifyReservationStart";
import ModifyReservationModal from './ModifyReservationModal';

const mapDispatchToProps = {
  modifyReservationStart,
};

export default connect(
  null,
  mapDispatchToProps
)(ModifyReservationModal);
