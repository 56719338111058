import React, { PureComponent } from 'react';
import cn from 'classnames';
import utils from 'utils';
import FieldsState from 'components/Form/FieldsState';
import { propTypes } from './fieldGroupCardPropTypes';

class FieldGroupCard extends PureComponent {
  static propTypes = propTypes;

  state = {
    fields: [],
  };

  _fieldGroupRef = React.createRef();

  componentDidMount() {
    this.setState({
      fields: this.getFormFields(this._fieldGroupRef),
    });
  }

  componentDidUpdate(prevProps, { fields: prevFields }) {
    const currentFields = this.getFormFields(this._fieldGroupRef);
    const currentFieldsArraySignature = currentFields.reduce((acc, field) => acc.concat(`${field.name} `), '');
    const prevFieldsArraySignature = prevFields.reduce((acc, field) => acc.concat(`${field.name} `), '');

    if (currentFieldsArraySignature !== prevFieldsArraySignature) {
      this.setState({
        fields: currentFields,
      });
    }
  }

  getFormFields = fieldGroup => [...(fieldGroup?.current?.querySelectorAll('[name]') || [])];

  hasIncompleteFields = fieldsState =>
    Object.keys(fieldsState).some(key => {
      const field = fieldsState[key];
      const isActive = field.meta.active;
      const isInvalid = !field.meta.valid;
      const isEmpty = field.input.value === '';
      return isInvalid || isActive || (this.props.completeWhenFilled && isEmpty);
    });

  render() {
    const {
      title,
      headerBorder,
      optionalLabel,
      children,
      themeClass,
      hideTitle = false,
      titleContainerClass,
      showComplete,
    } = this.props;
    const fields = this.state.fields.map(field => field.name);

    const stylingThemeClass = themeClass || 'theme--light component-theme--light';

    const completeCheckmark = (
      <span className='review__section-card__header__complete-indicator'>
        <span className='review__section-card__header__complete-indicator__label'>
          {utils.i18n('review_section_card_complete_indicator')}
        </span>
      </span>
    );

    return (
      <section
        ref={this._fieldGroupRef}
        className={cn('review__section-card', stylingThemeClass, {
          'review__section-card-oci': hideTitle,
        })}>
        {!hideTitle && (
          <div
            className={cn('review__section-card__header', titleContainerClass, {
              'review__section-card__header--border': headerBorder,
            })}>
            <h3 className='review__section-card__title'>
              {title}
              {optionalLabel && (
                <span className='review__section-card__title__optional-indicator'>
                  {` ${utils.i18n('review_flight_info_form_title_optional')}`}
                </span>
              )}
            </h3>
            <FieldsState names={fields}>
              {fieldsState =>
                showComplete
                  ? completeCheckmark
                  : utils.gmi.isArrayNotEmpty(fields) && !this.hasIncompleteFields(fieldsState) && completeCheckmark
              }
            </FieldsState>
          </div>
        )}
        {children}
      </section>
    );
  }
}

export default FieldGroupCard;
