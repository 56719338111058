import { connect } from 'react-redux';
import utils from 'utils';
import RenterInformationCommonContainer from '../RenterInformationCommonContainer';

import ConfirmedRental from './ConfirmedRental';

const mapStateToProps = (state) => {
  const renterInfoDetails = RenterInformationCommonContainer(state);

  return {
    ...renterInfoDetails,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ConfirmedRental));
