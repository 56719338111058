import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import utils from 'utils';
import PROFILE from 'constants/profile';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import Button from 'components/Button';
import Anchor from 'components/Anchor';

/**
 * Receipt Header
 *
 * @param {object} props - React Props
 * @param {boolean} props.isUnathReceipt - Identify when the Receipt component is being used to show unath receipts
 * @return {JSX}
 */
const ReceiptHeader = ({ isUnathReceipt }) => (
  <div className='receipt__header'>
    <div className='receipt__header--column'>
      <h3 className='receipt__header-title'>{utils.i18n('receipt_page_title')}</h3>

      {isUnathReceipt ? (
        <Anchor
          href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.UNAUTH_PAST_TRIPS_PAGE)}
          className='link receipt__header-back-link'
          data-dtm-track='past_trips|rental_receipt|back_to_rentals'>
          {utils.i18n('receipt_back_my_rentals')}
        </Anchor>
      ) : (
        <Link
          to={{
            pathname: PROFILE.PROFILE_PATHS_CONFIG.myTrips[0],
            state: {
              fromReceipt: true,
            },
          }}
          data-dtm-track='past_trips|rental_receipt|back_to_rentals'
          className='link receipt__header-back-link'>
          {utils.i18n('receipt_back_my_rentals')}
        </Link>
      )}
    </div>

    <div className='receipt__header--column'>
      <ul className='receipt__header-links'>
        <li className='receipt__header-links-item'>
          <Button
            className='receipt__header-links-button'
            data-dtm-track='past_trips|rental_receipt|print'
            onClick={() => utils.print(['.header', '.receipt__container'])}>
            {utils.i18n('confirmation_print')}
          </Button>
        </li>
      </ul>
    </div>
  </div>
);

ReceiptHeader.propTypes = {
  isUnathReceipt: PropTypes.bool,
};

export default ReceiptHeader;
