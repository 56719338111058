import React from 'react';
import cn from 'classnames';
import utils from 'utils';
import Button from 'components/Button';
import useActions from 'hooks/useActions';
import MODAL from 'constants/modals';
import useAdditionalDriversList from 'hooks/useAdditionalDriversList';
import { openModalWithProps } from "actions/modal/openModalWithProps";

const DriverListItem = ({ driver, cost, included, addDriverDetails, rateType, isPostRes, submitFailed }) => {
  const { first_name, last_name, individual_id, partial_driver_info } = driver;
  const driverDetailsComplete = !partial_driver_info;
  const totalCost = driver?.total_amount_view?.format;
  const { hasYoungDriverFee } = useAdditionalDriversList();

  const [openRemoveModal, openAddLicense] = useActions([
    () => openModalWithProps(MODAL.ADDITONAL_DRIVER_REMOVE_MODAL, { driver }),
    () => openModalWithProps(MODAL.ADDITIONAL_DRIVER_DETAILS_MODAL, { driver }),
  ]);

  const price = utils.config.getIsIncrementalPricingEnabled() ? (
    <p className='driver-list-item__incremental-pricing'>
      {cost}/{utils.i18n(`vehicle_select_incremental_unit_${rateType}`)}{' '}
      <span className='driver-list-item__cost-total'>({totalCost})</span>
    </p>
  ) : (
    <p>{totalCost}</p>
  );

  return (
    <>
      <div className='driver-list-item' data-dtm-track={isPostRes ? 'post_res|action|driver_added' : null}>
        <p className='driver-list-item__name'>
          {`${first_name} ${last_name}`}
          {addDriverDetails && (
            <span
              className={cn(
                'driver-list-item__driver-details-checkmark',
                driverDetailsComplete && 'driver-list-item__driver-details-checkmark--completed'
              )}
            />
          )}

          {hasYoungDriverFee(individual_id) && (
            <span className='driver-list-item__driver-fee'>{utils.i18n('additional_driver_young_driver_fee')}</span>
          )}
        </p>

        <div className='driver-list-item__cost'>
          {included ? <p>{utils.i18n('review_additional_drivers_status_included')}</p> : price}
        </div>

        <div className='driver-list-item__actions--add-license'>
          {addDriverDetails && !driverDetailsComplete && (
            <Button
              className={cn('driver-list-item__action--add-license', {
                'driver-list-item__error-message': submitFailed,
              })}
              link
              onClick={openAddLicense}>
              {utils.i18n('additional_driver_add_license_button')}
            </Button>
          )}

          <Button className='driver-list-item__action' link onClick={openRemoveModal}>
            {utils.i18n('review_additional_drivers_driver_remove')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default DriverListItem;
