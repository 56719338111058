import React from 'react';
import { useSelector } from 'react-redux';
import { showBundlesSectionSelector } from 'selectors/extrasSelectors';
import { breakpointSelector } from 'selectors/breakpoint';
import useVehicleUpgradeDetails from 'hooks/useVehicleUpgradeDetails';
import useActions from 'hooks/useActions';
import Picture from 'components/Picture';
import LoadingWidget from 'components/LoadingWidget';
import { upgradeCarClass } from "actions/reservation/upgradeCarClass";
import UpgradeCta from './UpgradeCta';
import UpgradeTotalCopy from './UpgradeTotalCopy';
import UpgradeDescription from './UpgradeDescription';
import UpgradeBannerCompactLayout from './UpgradeBannerCompactLayout';

/**
 * InPathVehicleUpgradeReview - Review card vehicle upgrade banner.
 *
 * @return {JSX}  InPathUpgradeWrapper styled container div and child elements defined here
 */
const InPathVehicleUpgradeReview = () => {
  // InPathVehicleUpgradeReview banner should be visible only when bundles are shown
  const visible = useSelector(showBundlesSectionSelector);

  const { inPathUpgradeAvailable, inPathUpgradeDetails, isFechingRates } = useVehicleUpgradeDetails({
    shouldGetDetailsConditional: visible,
    // This banner doesn't need to get details if hidden, because the InPathVehicleUpgradeRentalDetails banner will handle it
  });
  const upgradeHandler = useActions(upgradeCarClass);
  const { isMobile } = useSelector(breakpointSelector);

  const { upgradeName, upgradeMakeModelOrSimilarText, priceDifference, total, upgradeImagePath, upgradeClass } =
    inPathUpgradeDetails || {};

  return inPathUpgradeAvailable && visible ? (
    <>
      {isFechingRates && (
        <div className='in-path-upgrade in-path-upgrade-review review__section-card'>
          <LoadingWidget />
        </div>
      )}
      {inPathUpgradeDetails && (
        <div className='in-path-upgrade in-path-upgrade-review review__section-card'>
          {isMobile ? (
            <UpgradeBannerCompactLayout
              total={total}
              makeModelOrSimilarText={upgradeMakeModelOrSimilarText}
              imagePath={upgradeImagePath}
              name={upgradeName}
              priceDifference={priceDifference}
              upgradeHandler={() => upgradeHandler(upgradeClass)}
              upgradeClass={upgradeClass}
            />
          ) : (
            <>
              <div className='in-path-upgrade__car-details'>
                <UpgradeDescription
                  name={upgradeName}
                  makeModelOrSimilarText={upgradeMakeModelOrSimilarText}
                  priceDifference={priceDifference}
                />
                <UpgradeCta
                  upgradeHandler={() => upgradeHandler(upgradeClass)}
                  page='review'
                  upgradeClass={upgradeClass}
                />
                <UpgradeTotalCopy total={total} />
              </div>
              <Picture
                src={upgradeImagePath}
                alt={upgradeMakeModelOrSimilarText}
                pictureClassName='in-path-upgrade__picture'
              />
            </>
          )}
        </div>
      )}
    </>
  ) : null;
};

export default InPathVehicleUpgradeReview;
