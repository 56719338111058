import { connect } from 'react-redux';
import utils from 'utils';
import { getInflightPaymentDetails } from "actions/reservation/getInflightPaymentDetails";
import { removeInflightPaymentDetails } from "actions/reservation/removeInflightPaymentDetails";
import GuaranteedResIframeModalContent from './GuaranteedResIframeModalContent';

const mapDispatchToProps = {
  getInflightPaymentDetails,
  clearCardSubmissionUrl: () => removeInflightPaymentDetails({ cardSubmissionUrl: true }),
};

export default connect(
  null,
  mapDispatchToProps
)(utils.toJS(GuaranteedResIframeModalContent));
