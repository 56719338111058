import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import useAdditionalDriversList from 'hooks/useAdditionalDriversList';
import useYoungDriverFeeModal from 'hooks/useYoungDriverFeeModal';
import { isEUPrepaySelector } from 'selectors/reservationFlowSelectors';
import TOOLTIP_THEMES from 'constants/tooltipThemes';
import LearnMoreCta from 'components/AdditionalDrivers/LearnMoreCta';
import DriverListItem from 'components/AdditionalDrivers/DriverListItem';
import AddAdditionalDriverButton from 'components/AdditionalDrivers/AddAdditionalDriverButton';
import OtherDriverCost from 'components/AdditionalDrivers/OtherDriverCost';
import SpouseDriverList from 'components/AdditionalDrivers/SpouseDriverList';
import RemoveDriverModal from 'components/modals/AdditionalDriverModals/RemoveDriverModal';
import AdditionalDriverDetailsModal from 'components/modals/AdditionalDriverModals/AdditionalDriverDetailsModal';
import AddAdditionalDriverModal from 'components/modals/AdditionalDriverModals/AddAdditionalDriverModal';
import EuYoungDriverFeeModal from 'components/modals/AdditionalDriverModals/EuYoungDriverFeeModal';
import ToolTip from 'components/ToolTip';
import ServiceErrors from 'components/ServiceErrors';

const AdditionalDriverListCard = () => {
  const {
    driversList,
    allowMoreDrivers,
    maxAdditionalDrivers,
    numberOfFreeDrivers,
    otherDriverCost,
    fees,
    rateType,
    totalPrice,
  } = useAdditionalDriversList();
  const isEUPrePay = useSelector(isEUPrepaySelector);

  useYoungDriverFeeModal();

  const addDriverSection = allowMoreDrivers ? (
    <AddAdditionalDriverButton numDriversRemaining={Math.max(numberOfFreeDrivers - driversList.length, 0)} />
  ) : (
    <p className='additional-drivers__max-added'>
      {utils.pluralHandler(
        maxAdditionalDrivers,
        'review_additional_drivers_reached_max_drivers_singular',
        'review_additional_drivers_reached_max_drivers_plural',
        false,
        maxAdditionalDrivers
      )}
    </p>
  );

  return (
    <article className='additional-driver-flow__list-card check-in-template__content component-theme--light'>
      <h3 className='additional-driver-flow__card-header'>{utils.i18n('common_additional_drivers')}</h3>
      <LearnMoreCta />
      <SpouseDriverList />
      <ServiceErrors />
      <OtherDriverCost />

      <div className='additional-drivers__drivers-list'>
        <p className='additional-drivers__drivers-count'>
          {utils.i18n('review_additional_drivers_count', [driversList.length])}

          <span className='additional-drivers__other-driver-tooltip'>
            <ToolTip theme={TOOLTIP_THEMES.REGULAR_SLATE} placement='top-start'>
              <label>{utils.i18n('additional_driver_tooltip')}</label>
            </ToolTip>
          </span>
        </p>
        <p className='additional-driver-flow__driver-list-description'>
          {utils.i18n('additional_driver_oci_add_license_copy')}
        </p>
        {driversList.map((driver, idx) => (
          <DriverListItem
            key={`${driver.first_name}-${driver.last_name}`}
            driver={driver}
            cost={otherDriverCost}
            rateType={rateType}
            totalPrice={totalPrice}
            included={numberOfFreeDrivers - idx > 0}
            addDriverDetails
            fees={fees}
            isPostRes
          />
        ))}
      </div>

      {!isEUPrePay && addDriverSection}

      <AdditionalDriverDetailsModal />
      <AddAdditionalDriverModal />
      <RemoveDriverModal />
      <EuYoungDriverFeeModal />
    </article>
  );
};

export default AdditionalDriverListCard;
