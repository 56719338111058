import PropTypes from 'prop-types';

export const propTypes = {
  name: PropTypes.string.isRequired,
  locationType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  addressLines: PropTypes.arrayOf(PropTypes.string).isRequired,
  formattedCityStateZip: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
};
