import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import FieldControl from 'components/Form/FieldControl';

/**
 * SelectField
 * A `select` form element wrapped by `FieldControl`.
 *
 * @param {object} props - React Props
 * @param {array<object>} props.options - Array of objects containing options' data for the select field
 *  @example options = { country_code: 'US', country_name: 'United States', active: false }
 * @param {string} props.labelKey - Property (key) of the options' objects that maps to the label displayed to the user
 *  @example labelKey = 'country_name'
 * @param {string} props.valueKey - Property (key) of the options' objects that maps to the value of the option element
 *  @example valueKey = 'country_code'
 * @param {string} props.disabledKey - Property (key) of the objects that indicates whether the option should be disabled from selection
 *  @example disabledKey = 'active'
 * @param {function} props.decorate - Function that will be called to decorate the option labels when applicable
 * @param {boolean} props.includeHiddenOption - Whether to show a default hidden/disabled option with no value
 * @param {boolean} props.includeAllOption - Whether to show a default 'All' option with no value
 * @param {string} props.dataDtmAnalytics - A string to add to the `<select>` tag's `data-dtm-analytics` attribute for Analytics tracking purposes
 * @param {string} props.maskedOption - When not empty, will display the masked value as the default option (for GBO sourced masked values)
 *
 * @return {JSX} SelectField component
 */
const SelectField = ({
  options,
  labelKey,
  valueKey,
  disabledKey,
  decorate,
  includeHiddenOption,
  includeAllOption,
  maskedOption,
  dataDtmTrack,
  ...props
}) => {
  const componentId = useRef(props.id || utils.dom.uuid());

  return (
    <FieldControl id={componentId.current} {...props} type='select'>
      <select data-dtm-analytics={dataDtmTrack}>
        {includeHiddenOption && <option value='' key='none' hidden disabled />}
        {includeAllOption && (
          <option value='' key='all_option'>
            {utils.i18n('common_all')}
          </option>
        )}
        {utils.fieldValidation.isMasked(maskedOption) && (
          <option value={maskedOption} key='masked' hidden disabled>
            {maskedOption}
          </option>
        )}
        {options.map(option => (
          <option value={option[valueKey]} key={option[valueKey]} disabled={option[disabledKey]}>
            {decorate ? decorate(option[labelKey]) : option[labelKey]}
          </option>
        ))}
      </select>
    </FieldControl>
  );
};

SelectField.defaultProps = {
  theme: 'light',
  options: [],
  labelKey: 'label',
  valueKey: 'value',
  disabledKey: 'disabled',
  includeHiddenOption: false,
  includeAllOption: false,
  maskedOption: '',
};

SelectField.propTypes = {
  // passed into the form field wrapper component
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  theme: PropTypes.string,
  // used on SelectField itself
  options: PropTypes.arrayOf(PropTypes.object),
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  disabledKey: PropTypes.string,
  decorate: PropTypes.func,
  includeHiddenOption: PropTypes.bool,
  includeAllOption: PropTypes.bool,
  maskedOption: PropTypes.string,
  dataDtmAnalytics: PropTypes.string,
};

export default SelectField;
