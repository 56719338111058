import React, { Component } from 'react';
import utils from 'utils';
import cn from 'classnames';
import Picture from 'components/Picture';
import { propTypes } from './bundlesPropTypes';
import BundlesCard from './BundlesCard';

export default class Bundles extends Component {
  static propTypes = propTypes;

  handleClickBundle = (bundle_code, isSelected) => {
    const { selectCarClass, code, bundles, isPayLater } = this.props;

    selectCarClass({
      code,
      bundle_code: !isSelected ? bundle_code : bundles.find(item => item.default_bundle).name,
      prepay: !isPayLater,
      redirect: false,
    });
  };

  render() {
    const {
      bundles,
      includedBundlesExtras,
      showBundlesDetailsModal,
      displayCarClass,
      scrollToRef,
      hasClearAbove,
      vehicleImages,
      makeModelOrSimilarText,
      additionalDriversPolicy,
    } = this.props;
    const carImage = utils.getImageFromImagesObject(vehicleImages);

    return (
      <>
        <div className={cn('bundles__container', { 'bundles__container--clear-above': hasClearAbove })}>
          {displayCarClass && (
            <div className='bundles__vehicle-img-container'>
              <img className='bundles__vehicle-img--background' />
              <Picture
                className='bundles__vehicle-img'
                alt={makeModelOrSimilarText}
                src={carImage}
                srcMobile={carImage}
                srcTablet={carImage}
              />
            </div>
          )}
          <div className='bundles__header'>
            <h2>{utils.i18n('bundles_section_header')}</h2>
            <p>
              {utils.i18n('bundles_section_description')}&nbsp;
              <a className='link link--text normal-font' onClick={scrollToRef}>
                {utils.i18n('bundles_section_description_link')}
              </a>
            </p>
          </div>
          {bundles && (
            <div className='bundles__cards-wrapper'>
              {bundles.map(
                bundle =>
                  !bundle?.default_bundle && (
                    <BundlesCard
                      key={bundle.name}
                      isSelected={bundle.selected}
                      onClickBundle={this.handleClickBundle}
                      showBundlesDetailsModal={showBundlesDetailsModal}
                      includedBundlesExtras={includedBundlesExtras}
                      additionalDriversPolicy={additionalDriversPolicy}
                      {...bundle}
                    />
                  )
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}
