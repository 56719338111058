import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import GenericDrawer from 'components/GenericDrawer/GenericDrawer';
import VisHiddenText from 'components/VisHiddenText';
import BranchLocationMapView from './BranchLocationMapView';
import BranchLocationTimeDrawer from './BranchLocationTimeDrawer';

const BranchLocationDetail = ({
  address,
  lat,
  long,
  locationType,
  formattedPhone,
  directions = [],
  id,
  confirmation,
}) => {
  const directionsFormatted = directions.map(direction => direction.text).join(' ');
  const googleMapsLink = utils.locations.getGoogleMapsURL(address);

  return (
    <div className='branch-location-detail__container'>
      <div className='branch-location-detail__google-map'>
        <a
          className='branch-location-detail__google-maps-external'
          target='_blank'
          rel='noopener noreferrer'
          href={googleMapsLink}>
          {utils.i18n('confirmation_branch_details_google_map_external_link')}
        </a>
        <BranchLocationMapView address={address} lat={lat} long={long} locationType={locationType} />
      </div>
      <div className='branch-location-detail__details-container'>
        <div className='branch-location-detail__divider'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            className='branch-location-detail__section branch-location-detail__address-section branch-location-detail__external'
            href={googleMapsLink}>
            <span className='generic-drawer__label'>
              {address} <VisHiddenText message='Opens in a new window' />{' '}
            </span>
          </a>
        </div>
        <div className='branch-location-detail__divider'>
          <a
            rel='noopener noreferrer'
            className='branch-location-detail__section branch-location-detail__phone-number branch-location-detail__external'
            href={`tel:${formattedPhone}`}>
            <span className='generic-drawer__label'>
              {formattedPhone} <VisHiddenText message='Opens in a new window' />{' '}
            </span>
          </a>
        </div>
        <BranchLocationTimeDrawer id={id} confirmation={confirmation} />
        {!!directions.length && (
          <div className='branch-location-detail__divider branch-location-detail__drawer'>
            <GenericDrawer
              headerText={utils.i18n('branch_page_way_finding')}
              analyticsText={utils.i18n('branch_page_way_finding')}
              icon='generic-drawer__bus'
              confirmation={confirmation}
              key={`branch-location-detail-${utils.i18n('branch_page_way_finding')}`}>
              <div dangerouslySetInnerHTML={utils.sanitize(directionsFormatted)} />
            </GenericDrawer>
          </div>
        )}
      </div>
    </div>
  );
};

BranchLocationDetail.propTypes = {
  address: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  long: PropTypes.number.isRequired,
  locationType: PropTypes.string.isRequired,
  directions: PropTypes.array,
  id: PropTypes.string.isRequired,
  formattedPhone: PropTypes.string.isRequired,
  unFormattedPhone: PropTypes.string.isRequired,
  confirmation: PropTypes.bool,
};

export default BranchLocationDetail;
