import { connect } from 'react-redux';
import utils from 'utils';
import { profileDataSelector, sessionReservationObjectSelector } from 'selectors';
import { openModal } from "actions/modal/openModal";
import ProgressBarCID from './ProgressBarCID';

const mapStateToProps = state => {
  const reservationObj = sessionReservationObjectSelector(state);
  const profileData = profileDataSelector(state, { partnerRewardsProgram: true });
  const partner_reward_program = reservationObj?.get('partner_reward_program');
  const showPartnerRewards = partner_reward_program?.get('code') !== profileData?.partnerRewardsProgram?.code;
  return {
    partner_reward_program: showPartnerRewards ? partner_reward_program : null,
  };
};

const mapDispatchToProps = {
  openModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(ProgressBarCID));
