import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import utils from 'utils';
import TextField from 'components/Form/TextField';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * This creates a handler that is passed to a FormSpy to listen for changes in form state.
 * The handler checks for invalid input in the date fieldset and sets the error state on the component.
 * Note that this is for displaying the overall error message only - field validation is handled by FinalForm props.
 *
 * @param {string} id - The date field id used to get the right field values in the form's data set
 * @param {function} setDateFieldSetErrors - Setter for the error state object on the date fieldset component
 */
const handleDateFieldsetChanges =
  (id, setDateFieldSetErrors) =>
  ({ values, submitFailed }) => {
    // should only really display errors after submit fails
    if (!submitFailed) {
      return;
    }

    const errors = utils.date.getDateFieldErrors(values?.[id]);
    setTimeout(() => setDateFieldSetErrors(errors), 0);
  };
/**
 * Field configs for each date part field
 * @property {string} fieldKey - field key used in the id, name, etc - used to identify the part on FinalForm's state
 * @property {string} i18nKey - the content key for the field's label
 * @property {number} maxLength - max number of characters allowed on the field
 * @property {array<function>} validations - array of field validations applied specifically to the field
 */
const datePartFieldConfigs = {
  month: {
    fieldKey: 'month',
    i18nKey: 'common_month',
    maxLength: 2,
    validations: [utils.date.datePartValidation(utils.date.isValidMonth)],
  },
  day: {
    fieldKey: 'day',
    i18nKey: 'common_day',
    maxLength: 2,
    validations: [utils.date.datePartValidation(utils.date.isValidDay)],
  },
  year: {
    fieldKey: 'year',
    i18nKey: 'common_year',
    maxLength: 4,
    validations: [utils.date.datePartValidation(utils.date.isValidYear)],
  },
};

/**
 * Outline of possible date formats
 * @property {string} label - Display alongside the label of the fieldset; shows the user-friendly format
 * @property {array<object>} fieldOrder - Array of field configs (see `datePartFieldConfigs`)
 *  - should be in the order in which these should appear.
 */
const formatMap = {
  US: {
    label: 'MM/DD/YYYY',
    fieldOrder: [datePartFieldConfigs.month, datePartFieldConfigs.day, datePartFieldConfigs.year],
  },
  INTL: {
    label: 'DD/MM/YYYY',
    fieldOrder: [datePartFieldConfigs.day, datePartFieldConfigs.month, datePartFieldConfigs.year],
  },
};

/**
 * Gets the appropriate format config for the field, according to the selected country.
 * Only US should display a different field format (mm/dd/yyyy) vs any other country (dd/mm/yyyy).
 * @param {string} countryCode
 */
const getFieldFormatConfig = (countryCode) => (countryCode === 'US' ? formatMap.US : formatMap.INTL);

/**
 * DateFieldset component
 * Shows a set of inputs for the user to inform a date, separated between day, month and year
 *
 * @param {object} props - React Props
 * @param {string} props.id - The date id/name in the form context
 * @param {string} props.label - The field label, shown above the inputs
 * @param {object} props.initialValue - Initial Value of the date parts - should be in the shape { day, month, year }
 * @param {object} props.countryCode - Country code user selected on the surrounding form; dictates the date format, defaults to domain
 * @param {boolean} props.isRequired
 * @param {boolean} props.validate - flag to check if needs field level validation
 * @param {object} props.form - FinalForm instance - used to change all date fields value on removeAllDateFields
 */
const DateFieldset = ({ id, label, initialValue, countryCode = domainCountryCode, isRequired, validate, form }) => {
  const [dateFieldSetErrors, setDateFieldSetErrors] = useState({});
  const [dateInitialValues, setDateInitialValues] = useState(initialValue);

  // We keep the config in state to prevent unnecessary re-renders on a prop change,
  // and we only change the config when the country code itself changes.
  const [fieldFormatConfig, setFieldFormatConfig] = useState(getFieldFormatConfig(countryCode));
  useEffect(() => {
    setFieldFormatConfig(getFieldFormatConfig(countryCode));
  }, [countryCode]);

  // checks if there are any true errors in the error state
  const hasErrors = Object.values(dateFieldSetErrors)?.find(Boolean);

  const removeAllDateFields = (clearInitialValue) => {
    clearInitialValue();

    // Needed to remove the remove button
    setDateInitialValues({
      year: '',
      month: '',
      day: '',
    });

    form.change(`${id}.year`, '');
    form.change(`${id}.month`, '');
    form.change(`${id}.day`, '');
  };

  return (
    <fieldset className='date-fieldset'>
      <legend id={`label-${id}`} className='date-fieldset__label'>
        {label}
        <span className='date-fieldset__label__format'>{` (${fieldFormatConfig.label})`}</span>
      </legend>
      <div className='date-fieldset__inputs'>
        {fieldFormatConfig.fieldOrder.map(({ fieldKey, i18nKey, maxLength, validations }) => (
          <TextField
            id={`${id}.${fieldKey}`}
            name={`${id}.${fieldKey}`}
            key={`${id}.${fieldKey}`}
            label={utils.i18n(i18nKey)}
            fill
            initialValueButton={dateInitialValues?.[fieldKey]}
            initialValueButtonHandler={removeAllDateFields}
            maxLength={maxLength}
            forceError={validate && dateFieldSetErrors[fieldKey]}
            required={isRequired}
            validations={validate && validations}
            inputType='tel'
          />
        ))}
      </div>
      {isRequired && hasErrors ? (
        <span className='date-fieldset__error-message'>
          {dateFieldSetErrors.empty
            ? utils.i18n('field_required_dont_forget_to_enter', [label?.toLowerCase()])
            : utils.i18n('date_fieldset_please_check_date')}
        </span>
      ) : null}
      {/* 
        hack for handleChange to get called  before render, referred from here https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373  
      */}
      <FormSpy
        onChange={handleDateFieldsetChanges(id, setDateFieldSetErrors)}
        subscription={{
          values: true,
          submitFailed: true,
        }}
      />
    </fieldset>
  );
};

DateFieldset.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  initialValue: PropTypes.shape({
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
  }),
  countryCode: PropTypes.string,
  isRequired: PropTypes.bool,
  validate: PropTypes.bool,
  form: PropTypes.object,
};

DateFieldset.defaultProps = {
  isRequired: true,
  validate: true,
};

export default DateFieldset;
