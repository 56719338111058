import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { actions as gmiActions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import GMI_SERVICE_PATHS from 'constants/gmiServicePaths';
import useActions from 'hooks/useActions';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';
import { modifyProfile } from 'actions/profile';
import { profileDataSelector } from 'selectors/profileSelectors';
import Form from 'components/Form/Form';
import ServiceErrors from 'components/ServiceErrors';
import Anchor from 'components/Anchor';
import ToolTip from 'components/ToolTip';
import TextField from 'components/Form/TextField';
import SelectField from 'components/Form/SelectField';
import DateFieldset from 'components/Form/DateFieldset';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * Parses form values into payload object expected by modifyProfile.
 * In this form's case, it is used mostly to parse dates from the fieldset.
 * @param {object} formData
 * @return {object} payload data object to be sent as request body on modifyProfile
 */
const parseFormData = formData => {
  const {
    license_issue_date,
    initial_license_issue_date,
    license_expiration_date,
    initial_license_expiration_date,
    birth_date,
    ...data
  } = formData;

  // format fieldset values into GBO-friendly date strings (or fallback to initial masked values)
  if (utils.date.isValidDateFieldSetInput(license_expiration_date)) {
    data.license_expiration_date = utils.date.getGBOFormatDateFromDateFieldSet(
      license_expiration_date,
      initial_license_expiration_date
    );
  }
  if (utils.date.isValidDateFieldSetInput(license_issue_date)) {
    data.license_issue_date = utils.date.getGBOFormatDateFromDateFieldSet(
      license_issue_date,
      initial_license_issue_date
    );
  }
  if (utils.date.isValidDateFieldSetInput(birth_date)) {
    data.birth_date = utils.date.getGBOFormatDateFromDateFieldSet(birth_date);
  }

  return {
    drivers_license: data,
    // birth date, if present, should also be sent "outside" of the license object
    ...(data.birth_date ? { date_of_birth: data.birth_date } : null),
  };
};

/**
 * EditDriversLicenseForm
 * Form that handles addition/update of DL info on My Profile
 *
 * @param {object} props - React Props
 * @param {function} props.onBeforeSubmit - Handler function to be called along/before the modify profile call
 * @param {function} props.onSubmitSuccess - Handler function for a successful form submit
 * @param {function} props.footer - Render Props function to display the modal's footer
 * @param {array<function>} props.decorators - Array of decorator function to be passed into the final form instance
 * @param {function} props.scrollToModalTop - Passed from the Modal wrapper - handles scrolling to top when form renders
 *
 * @return {JSX} EditDriversLicenseForm jsx component
 */
const EditDriversLicenseForm = ({ onBeforeSubmit, onSubmitSuccess, footer, decorators, scrollToModalTop }) => {
  const profileData = useSelector(state => profileDataSelector(state, { license_profile: true }));
  const alamoInsidersSupportNumber = useSupportPhoneNumber();

  const cleanMessagesAction = useActions(gmiActions.cleanMessages);
  const modifyProfileAction = useActions(modifyProfile);

  const { license_profile } = profileData || {};
  const profileCountry = license_profile?.country_code || domainCountryCode;

  const { selectedCountry, setSelectedCountry, countryConfig, countries, statesOrProvinces } = useCountryFieldsConfig(
    profileCountry
  );

  const {
    shouldShowIssueDate,
    shouldShowExpirationDate,
    shouldShowBothIssueAndExpirationDate,
    shouldShowSubdivisionField,
    shouldShowIssuingAuthorityField,
    issuingAuthorities,
  } = countryConfig;

  useEffect(() => {
    scrollToModalTop();

    // Clean GMI error messages on form unmount
    return cleanMessagesAction;
  }, []);

  const {
    license_number,
    country_subdivision_code,
    issuing_authority,
    license_issue_date,
    license_expiration_date,
    birth_date,
  } = license_profile || {};

  // the initial values should never change after the initial render, so we keep a ref to them
  const initialProfileData = useRef({
    ...license_profile,
    license_issue_date: utils.gmi.parseGBODate(license_issue_date),
    license_expiration_date: utils.gmi.parseGBODate(license_expiration_date),
    initial_license_issue_date: license_issue_date,
    initial_license_expiration_date: license_expiration_date,
    country_code: profileCountry,
  });

  const setCountry = event => {
    const countryObject = countries.find(({ country_code }) => country_code === event?.target?.value);
    setSelectedCountry(countryObject);
  };

  /**
   * Submit handler passed to the React Final Form instance
   * @param {object} formData - FinalForm's inputs' values
   */
  const onSubmit = formData => {
    const payload = parseFormData(formData);

    // Store both calls in an array in order to make them concurrently, since they're not co-dependent
    const serviceActionCalls = [modifyProfileAction(payload)];
    if (utils.gmi.types(onBeforeSubmit).isFunction) {
      serviceActionCalls.push(onBeforeSubmit(payload));
    }

    Promise.all(serviceActionCalls).then(resp => {
      const [modifyResponse, lookupData = {}] = resp;
      // if the response contains no error messages, redirect
      // error messages will already be printed inside the component at ServiceErrors
      if (!modifyResponse?.messages) {
        onSubmitSuccess({ ...lookupData, ...payload });
      }
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialProfileData.current}
      validate={values =>
        utils.date.validateIssueAndExpiration(
          values,
          shouldShowBothIssueAndExpirationDate,
          shouldShowIssueDate,
          shouldShowExpirationDate
        )
      }
      decorators={decorators}
      subscription={{ submitFailed: true, values: true }}>
      {({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit} className='my-profile__modal-form component-theme--light' noValidate>
          <p className='my-profile__modal-form__required-note'>
            {utils.i18n('my_profile_drivers_license_modal_required_note')}
          </p>
          <ServiceErrors statePath={GMI_SERVICE_PATHS.MODIFY_PROFILE} />
          <div className='my-profile__modal-form__field-row'>
            <TextField
              id='license_number'
              name='license_number'
              label={utils.i18n('my_profile_drivers_license_number')}
              fill
              required
              autoComplete='drivers_license_number'
              initialValueButton={license_number}
            />
          </div>
          <div className='my-profile__modal-form__field-row'>
            {!!countries?.length && (
              <SelectField
                id='country_code'
                name='country_code'
                label={utils.i18n('my_profile_drivers_license_issuing_country')}
                onChange={setCountry}
                options={countries}
                valueKey='country_code'
                labelKey='country_name'
                required
                includeHiddenOption
              />
            )}
            {shouldShowSubdivisionField && !shouldShowIssuingAuthorityField && (
              <SelectField
                id='country_subdivision_code'
                name='country_subdivision_code'
                label={utils.i18n('my_profile_drivers_license_state_province')}
                options={statesOrProvinces[selectedCountry.country_code]}
                valueKey='country_subdivision_code'
                labelKey='country_subdivision_name'
                required
                includeHiddenOption={!country_subdivision_code}
                maskedOption={country_subdivision_code}
              />
            )}
            {shouldShowIssuingAuthorityField && !!issuingAuthorities?.length && (
              <SelectField
                id='issuing_authority'
                name='issuing_authority'
                label={utils.i18n('my_profile_drivers_license_issuing_authority')}
                options={issuingAuthorities}
                valueKey='country_subdivision_code'
                labelKey='country_subdivision_name'
                required
                includeHiddenOption={!issuing_authority}
                maskedOption={issuing_authority}
              />
            )}
          </div>

          {shouldShowBothIssueAndExpirationDate && (
            <div className='check-in-flow__date-info'>
              <h4 className='check-in-flow__date-info__title'>{utils.i18n('check_in_driver_license_drivers_date')}</h4>
              <p>{utils.i18n('check_in_driver_license_drivers_date_description')}</p>
            </div>
          )}
          {shouldShowExpirationDate && (
            <div
              className={cn('my-profile__modal-form__date-row', {
                'my-profile__modal-form__date-row-no-padding': shouldShowBothIssueAndExpirationDate,
              })}>
              <DateFieldset
                id='license_expiration_date'
                label={utils.i18n('my_profile_drivers_license_expiration_date')}
                initialValue={initialProfileData.current.license_expiration_date}
                countryCode={selectedCountry?.country_code}
                isRequired={!shouldShowBothIssueAndExpirationDate}
                validate={false}
                form={form}
              />
            </div>
          )}
          {shouldShowIssueDate && (
            <div className='my-profile__modal-form__date-row'>
              <DateFieldset
                id='license_issue_date'
                label={utils.i18n('my_profile_drivers_license_issue_date')}
                initialValue={initialProfileData.current.license_issue_date}
                countryCode={selectedCountry?.country_code}
                isRequired={!shouldShowBothIssueAndExpirationDate}
                validate={false}
                form={form}
              />
            </div>
          )}
          {shouldShowBothIssueAndExpirationDate && utils.date.isExpirationAndIssueFormStateNotValid(form) && (
            <span className='check-in-flow__form__date-error-message'>
              {utils.i18n('my_profile_drivers_license_expiration_or_issue_date_required')}
            </span>
          )}
          {shouldShowBothIssueAndExpirationDate && <hr className='check-in-flow__divider' />}

          {birth_date ? (
            <div className='my-profile__modal-form__field-row my-profile__modal-form__date-field'>
              <div className='my-profile__modal-form__date-field__label'>
                {utils.i18n('my_profile_drivers_license_date_of_birth')}
                {` (${utils.config.getDateTimeFormat('l')})`}
                <ToolTip placement='top-start'>
                  <span>
                    {utils.i18n(
                      'my_profile_drivers_license_dob_tooltip',
                      [
                        <Anchor
                          key='0'
                          className='link my-profile__modal-form__tooltip-link'
                          href={`tel:${alamoInsidersSupportNumber}`}
                          aria-label={alamoInsidersSupportNumber}>
                          {alamoInsidersSupportNumber}
                        </Anchor>,
                      ],
                      { jsx: true }
                    )}
                  </span>
                </ToolTip>
              </div>
              <p>{utils.gmi.getMaskedGBODate(birth_date)}</p>
            </div>
          ) : (
            <div className='my-profile__modal-form__date-row'>
              <DateFieldset
                id='birth_date'
                label={utils.i18n('my_profile_drivers_license_date_of_birth')}
                countryCode={selectedCountry?.country_code}
              />
            </div>
          )}

          {footer(handleSubmit)}
        </form>
      )}
    </Form>
  );
};

EditDriversLicenseForm.defaultProps = {
  decorators: [],
  scrollToModalTop: () => {},
};

EditDriversLicenseForm.propTypes = {
  onBeforeSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func.isRequired,
  footer: PropTypes.func.isRequired,
  decorators: PropTypes.arrayOf(PropTypes.func),
  scrollToModalTop: PropTypes.func,
};

export default EditDriversLicenseForm;
