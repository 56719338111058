import React from 'react';
import { propTypes } from './ImagePropTypes';

class Image extends React.Component {
  static propTypes = propTypes;

  renderPicture = () => {
    const { fileReference, alt, displayPopupTitle, title } = this.props;
    return (
      <picture className='lazy-load'>
        <source data-srcset={`${fileReference}?imwidth=480`} media='(max-width: 480px)' />
        <source data-srcset={`${fileReference}?imwidth=1024`} media='(min-width: 480px) and (max-width: 1024px)' />
        <source data-srcset={fileReference} media='(min-width: 1024px)' />
        <img data-src={fileReference} alt={alt} title={displayPopupTitle && title} />
      </picture>
    );
  };

  componentDidMount() {
    /**
     * This will let the `IntersectionObserver` API know that a new react
     * image is in placeand that it should be lazy loaded. @see lazyLoad.js
     */
    window.dispatchEvent(new CustomEvent('reactpicturemounted'));
  }

  renderNoScript = () => {
    const { lazyEnabled, widths, areas } = this.props;
    const renderNoScriptWrapper = !lazyEnabled && widths && widths.length <= 1 && !areas;
    return (
      <>
        {renderNoScriptWrapper && <noscript data-cmp-hook-image='noscript'>{this.renderPicture()}</noscript>}
        {!renderNoScriptWrapper && this.renderPicture()}
      </>
    );
  };

  renderMainComp = () => {
    const { link, title, displayPopupTitle } = this.props;
    const renderTitle = displayPopupTitle && title;
    return (
      <>
        {link && (
          <a className='cmp-image__link' href={link} data-cmp-hook-image='link'>
            {this.renderNoScript()}
          </a>
        )}
        {!link && this.renderNoScript()}
        {!renderTitle && (
          <span className='cmp-image__title' itemProp='caption'>
            {title}
          </span>
        )}
        {renderTitle && <meta itemProp='caption' content={title} />}
      </>
    );
  };

  render() {
    const { title, displayPopupTitle } = this.props;
    const renderTitle = displayPopupTitle && title;
    if (renderTitle) {
      return (
        <div itemScope itemType='http://schema.org/ImageObject'>
          {this.renderMainComp()}
        </div>
      );
    }
    return this.renderMainComp();
  }
}

export default Image;
