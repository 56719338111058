import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { addLoyaltyAuthenticatedInfo } from 'utils/analytics';
import GMI_SERVICE_PATHS from 'constants/gmiServicePaths';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import useActions from 'hooks/useActions';
import { searchProfileByDriverLicense } from 'actions/checkin';
import { onlineCheckInProfileSelector } from 'selectors/checkinSelectors';
import { getReservationRenterInfoLastName } from 'selectors/reservationSelectors';
import Form from 'components/Form/Form';
import DoNotRentModal from 'components/modals/DoNotRentModal';
import CheckInTemplate from '../CheckInTemplate';
import DriverLookupForm from './DriverLookupForm';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * CheckInDriverLookup
 * Form that handles DL info lookup during the Check-in process
 *
 * @return {JSX} CheckInDriverLookup jsx component
 */
const CheckInDriverLookup = () => {
  const profile = useSelector(onlineCheckInProfileSelector);
  const lastName = useSelector(getReservationRenterInfoLastName);
  const searchProfileByDriverLicenseAction = useActions(searchProfileByDriverLicense);

  const initialCountryCode = profile.license_profile.country_code || domainCountryCode;

  const onSubmit = (formData) => {
    searchProfileByDriverLicenseAction({ ...formData, last_name: lastName }).then((response) => {
      if (!response.messages) {
        addLoyaltyAuthenticatedInfo();
      }
    });
  };

  // when profile is available/changes, get country and subdivision as initial values for license and contact info
  const initialValues = useMemo(
    () => ({
      country: initialCountryCode,
      country_subdivision: utils.fieldValidation.getIfNotMasked(profile.license_profile.country_subdivision_code),
      issuing_authority: utils.fieldValidation.getIfNotMasked(profile.license_profile.issuing_authority),
      driver_license_number: utils.fieldValidation.getIfNotMasked(profile.license_profile.license_number),
    }),
    [profile.license_profile.license_number]
  );

  const onDNRModalClose = () => utils.loadingRedirect(WINDOW_OBJECT_KEYS.CHECKIN_LOOKUP);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true, submitFailed: true }}
    >
      {({ form, handleSubmit }) => (
        <CheckInTemplate nextButton={{ action: handleSubmit }}>
          <DriverLookupForm form={form} handleSubmit={handleSubmit} errorStatePath={GMI_SERVICE_PATHS.MODIFY_PROFILE} />
          <DoNotRentModal onModalClose={onDNRModalClose} />
        </CheckInTemplate>
      )}
    </Form>
  );
};

export default CheckInDriverLookup;
