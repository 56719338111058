import React from 'react';
import utils from 'utils';
import cn from 'classnames';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';
import { accordionContentDefaultProps, accordionContentPropTypes } from './AccordionListPropTypes';

const AccordionContent = ({ isActive, titleId, contentId, isVertical, children, print, analyticsTitle }) => {
  const contentClass = cn('tabs__content', {
    'tabs-vertical__content': isVertical,
    'tabs__content--active': isActive,
  });

  return (
    <div id={contentId} className={contentClass} role='tabpanel' aria-labelledby={titleId} aria-hidden={!isActive}>
      {print && (
        <Button
          link
          data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, analyticsTitle, ANALYTICS.PRINT)}
          className='tabs__content__print'
          onClick={window.print}>
          <span>{utils.i18n('tab_content_print')}</span>
        </Button>
      )}
      {children}
    </div>
  );
};

AccordionContent.propTypes = accordionContentPropTypes;
AccordionContent.defaultProps = accordionContentDefaultProps;
export default AccordionContent;
