import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import { clearProfile } from 'actions/profile';
import { onlineCheckInProfileSelector } from 'selectors/checkinSelectors';
import Modal from 'components/Modal';
import SignIn from 'components/SignIn';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * PartialProfileFirstNameConflictModal
 * Modal that displays the Sign In component for a unauth partial profile found.
 * After a successful login, the modal closes and user will be OCI enabled (Loyalty Profile).
 * On cancel, user will be treated as no profile found
 *
 * @return {JSX} PartialProfileFirstNameConflictModal component
 */
const PartialProfileFirstNameConflictModal = () => {
  const clearProfileAction = useActions(clearProfile);
  const profile = useSelector(onlineCheckInProfileSelector);
  const { first_name, last_name } = profile.basic_profile;
  const { license_number, country_subdivision_code } = profile.license_profile;

  const driversLicenseCountryCode = profile.license_profile.country_code || domainCountryCode;
  const { selectedCountry } = useCountryFieldsConfig(driversLicenseCountryCode);

  const onSuccessSignIn = handleClose => () => {
    utils.analytics.interaction(ANALYTICS.MODAL, MODAL.PARTIAL_PROFILE_FIRST_NAME_CONFLICT_MODAL, ANALYTICS.SIGN_IN);
    handleClose();
  };

  const onCancelSignIn = handleClose => () => {
    const analyticsObj = {
      analyticsHandler: () =>
        utils.analytics.interaction(
          ANALYTICS.MODAL,
          utils.analytics.prepareKey(MODAL.PARTIAL_PROFILE_FIRST_NAME_CONFLICT_MODAL),
          ANALYTICS.CANCEL
        ),
    };
    clearProfileAction().then(() => {
      handleClose(analyticsObj);
    });
  };

  return (
    <Modal
      modalKey={MODAL.PARTIAL_PROFILE_FIRST_NAME_CONFLICT_MODAL}
      header={utils.i18n('accelerated_check_in_modal_title')}
      customActionOnClose={clearProfileAction}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose }) => (
        <>
          <div className='check-in-flow__form component-theme--light'>
            <p className='modal-themed__paragraph'>
              {utils.i18n('check_in_step_partial_profile_found_first_name_conflict_description')}
            </p>
            <div className='check-in-flow__form__section-title'>
              <h4>{utils.i18n('common_name')}</h4>
            </div>
            <p className='check-in-flow__form__info-row'>
              {first_name} {last_name}
            </p>
            <div className='check-in-flow__form__partial-profile-driver-details'>
              <div className='check-in-flow__form__section-title'>
                <h4>{utils.i18n('common_drivers_license_details')}</h4>
              </div>
              <p className='check-in-flow__form__info-row'>
                {utils.i18n('check_in_driver_license_issued_by')}: {selectedCountry?.country_name}
                {country_subdivision_code && !utils.fieldValidation.isMasked(country_subdivision_code)
                  ? `, ${country_subdivision_code}`
                  : ''}
              </p>
              <p className='check-in-flow__form__info-row'>
                {utils.i18n('my_profile_drivers_license_number')}: {license_number}
              </p>
            </div>
            <div className='in-res-oci-modal__signin in-res-oci-modal__signin'>
              <div className='in-res-oci-modal__signin-header'>
                <h4>{utils.i18n('signin_submit')}</h4>
              </div>
              <SignIn onSuccess={onSuccessSignIn(handleClose)} onCancel={onCancelSignIn(handleClose)} />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default PartialProfileFirstNameConflictModal;
