import { connect } from 'react-redux';
import utils from 'utils';
import { MODAL } from 'constants';
import { cancelFeeSelector, selectedPaymentMethodSelector } from 'selectors';
import { openModal } from "actions/modal/openModal";
import AboutPayLater from './AboutPayLater';

const mapStateToProps = state => ({
  cancelFee: cancelFeeSelector(state),
  selectedPaymentMethod: selectedPaymentMethodSelector(state),
});

const mapDispatchToProps = {
  showGuaranteedResModal: () => openModal(MODAL.GUARANTEED_RES),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(AboutPayLater));
