import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import BranchHours from 'components/BranchHours';

const MapLocationDetailTileExpandedState = ({
  additional_data: { formatted_phone } = {},
  getAdditionalInfo,
  id,
  isPartner,
  locationOptions,
  phones,
  reservationFlow,
  showReturnLocation,
}) => {
  useEffect(() => {
    !isPartner && getAdditionalInfo?.(id);
  }, []);

  const locationServices = useMemo(
    () =>
      locationOptions?.map(
        ({ has, label }, idx) =>
          !utils.gmi.types(has).isNull && (
            <li
              className={cn({
                'map-location-detail-tile-expanded-state__location--has-service': has,
                'map-location-detail-tile-expanded-state__location--no-service': !has,
              })}
              key={`${idx}--${label}`}>
              {utils.i18n(label)}
            </li>
          )
      ),
    [locationOptions]
  );

  const phoneNumber = `tel:${phones?.[0].phone_number}`;

  return (
    <div className='map-location-detail-tile-expanded-state__main-container'>
      <div className='map-location-detail-tile-expanded-state__title-container'>
        <p>{utils.i18n('map_locations_tiles_expanded_state_tile')}</p>
      </div>
      <a
        data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.LOCATION_ID(id), ANALYTICS.CALL)}
        href={phoneNumber}
        className='map-location-detail-tile-expanded-state__phone-number'>
        {formatted_phone}
      </a>

      <BranchHours {...{ id, reservationFlow, showReturnLocation }} />

      {!isPartner && (
        <>
          <p className='map-location-detail-tile-expanded-state__location-info'>
            {utils.i18n('map_locations_tiles_expanded_state_location_info')}
          </p>
          <ul className='map-location-detail-tile-expanded-state__location-options-container'>{locationServices}</ul>
        </>
      )}
    </div>
  );
};

MapLocationDetailTileExpandedState.propTypes = {
  additional_data: PropTypes.object,
  getAdditionalInfo: PropTypes.func,
  id: PropTypes.string,
  isPartner: PropTypes.bool,
  locationOptions: PropTypes.array,
  openBookWithPartnerModal: PropTypes.func,
  phones: PropTypes.array,
  reservationFlow: PropTypes.bool,
  showReturnLocation: PropTypes.bool,
};

export default MapLocationDetailTileExpandedState;
