import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import GMA_RESPONSE_CODES from 'constants/gmaResponseCodes';
import useActions from 'hooks/useActions';
import { searchProfileByDriverLicense } from 'actions/profile';
import { getUiStateLastName, getUiStateFirstName } from 'selectors/uiStateSelectors';
import { onlineCheckInProfileSelector } from 'selectors/checkinSelectors';
import { getModifyFlag } from 'selectors/reservationSelectors';
import DriverLookupForm from 'components/AcceleratedCheckInFlow/CheckInDriverLookup/DriverLookupForm';
import Form from 'components/Form/Form';
import TextField from 'components/Form/TextField';
import ServiceErrors from 'components/ServiceErrors';
import RequiredFieldsIndicator from 'components/RequiredFieldsIndicator';
import utils from 'utils';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * DriverLookup Component
 * In res OCI driver lookup form rendering within In Res OCI wizard modal
 *
 * @param {object} props - React Props
 * @param {func} props.renderFooter - function used to render Modal footer, takes the onSubmit handler as first param
 * @param {func} props.onSubmitSuccess - callback passed by In Res OCI wizard modal, that runs wizard modal functionality
 * @param {string} props.firstName - first name saved in InResAcceleratedCheckinWizardModal, used to set initialState
 * @param {func} props.setFirstName - handler to set firstname in parent InResAcceleratedCheckinWizardModal on form submit
 * @param {func} props.scrollToModalTop - handler to scroll parent modal, used to show errors if they pop up
 *
 * @return {JSX} DriverLookup jsx component
 */
const DriverLookup = ({ renderFooter, onSubmitSuccess, firstName, setFirstName, scrollToModalTop }) => {
  const driverLookup = useActions(searchProfileByDriverLicense);
  const commitStateFirstName = useSelector(getUiStateFirstName);
  const commitStateLastName = useSelector(getUiStateLastName);
  const isTrueModify = useSelector(getModifyFlag);
  const { basic_profile, license_profile } = useSelector(onlineCheckInProfileSelector);

  const handleLookup = ({ first_name, last_name, country, country_subdivision, driver_license_number }) => {
    const isOci = true;
    // TODO - once custom service of in res oci is available, pass in full form data and let it handle parsing which values to take
    driverLookup(
      {
        country,
        country_subdivision,
        driver_license_number,
        last_name,
      },
      isOci
    ).then(resp => {
      if (!resp.messages) {
        // No error messages returned, proceed to next step
        // TODO: remove firstName set and read state once first name available in online_checkin_profile (awaiting gma change)
        setFirstName(first_name);
        onSubmitSuccess();
      } else {
        // Don't execute scrollToModalTop if the error is DNR as this code have a special handler that clears modal queue
        // and open another modal with Customer Support information.
        !resp.messages.some(msg => msg.code === GMA_RESPONSE_CODES.GMA_PROFILE_DO_NOT_RENT) && scrollToModalTop();
      }
    });
  };

  const initialValues = useMemo(
    () => ({
      first_name: firstName || commitStateFirstName,
      last_name: basic_profile.last_name || commitStateLastName,
      country: license_profile.country_code || domainCountryCode,
      country_subdivision: utils.fieldValidation.getIfNotMasked(license_profile.country_subdivision_code),
      issuing_authority: utils.fieldValidation.getIfNotMasked(license_profile.issuing_authority),
      driver_license_number: utils.fieldValidation.getIfNotMasked(license_profile.license_number),
    }),
    [firstName, commitStateFirstName, commitStateLastName, basic_profile, license_profile]
  );

  return (
    <Form onSubmit={handleLookup} initialValues={initialValues}>
      {({ form, handleSubmit }) => (
        <>
          <RequiredFieldsIndicator />
          <div className='in-res-driver-lookup'>
            <ServiceErrors runAllHandlers />
            <h3 className='in-res-driver-lookup__name-label'>{utils.i18n('common_name')}</h3>
            <form noValidate>
              <div className='in-res-driver-lookup__name-row'>
                <TextField
                  id='first_name'
                  name='first_name'
                  label={utils.i18n('common_first_name')}
                  required
                  autoComplete='first_name'
                  disabled={isTrueModify}
                />
                <TextField
                  id='last_name'
                  name='last_name'
                  label={utils.i18n('common_last_name')}
                  required
                  autoComplete='last_name'
                  disabled={isTrueModify}
                />
              </div>
            </form>
            <DriverLookupForm form={form} handleSubmit={handleSubmit} />
            {renderFooter(handleSubmit)}
          </div>
        </>
      )}
    </Form>
  );
};

DriverLookup.propTypes = {
  renderFooter: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func,
  scrollToModalTop: PropTypes.func,
};

export default DriverLookup;
