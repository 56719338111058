import React from 'react';
import { i18n } from 'utils/i18n';

const CreditCardDateExpirationContentModal = () => (
  <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
    {i18n('credit_card_expires_before_return')}
  </p>
);

export default CreditCardDateExpirationContentModal;
