import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import utils from 'utils';
import { dispatchEventWithDOM, dtm } from 'utils/analytics';
import RESERVATIONS from 'constants/reservations';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import {
  getIsAfterHoursPickup,
  getPickupLocationMultiTerminal,
  reservationAirlineInfoSelector,
  travelIndicatorIsFlightSelector,
} from 'selectors/reservationSelectors';
import { airlineOptionsSelector } from 'selectors/reviewSelectors';
import { isVirginReservationSelector, isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import Button from 'components/Button';
import ResponsiveListbox from 'components/Form/ResponsiveListbox';
import TextField from 'components/Form/TextField';
import { updateCommitState } from "actions/reservation/updateCommitState";

const { AIRLINE_NO_FLIGHT, AIRLINE_NO_AIRLINE } = RESERVATIONS;

/**
 * PostResFlightInfo
 *
 * @return {JSX}
 */
const PostResFlightInfo = ({ fieldsOnly }) => {
  const updateCommitStateHandler = useActions(updateCommitState);
  const airlineInfo = useSelector(reservationAirlineInfoSelector);
  const isFlightTravelIndicator = useSelector(travelIndicatorIsFlightSelector);
  const isMultiTerminalAirport = useSelector(getPickupLocationMultiTerminal);
  const isAfterHoursPickup = useSelector(getIsAfterHoursPickup);
  const airlineOptions = useSelector(airlineOptionsSelector);
  const isVirginReservation = useSelector(isVirginReservationSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const flightNumber = useRef();
  const flightCode = useRef();

  const [airlineNotListed, setAirlineNotListed] = useState(
    [AIRLINE_NO_FLIGHT, AIRLINE_NO_AIRLINE].includes(airlineInfo.code)
  );
  const [noFlight, setNoFlight] = useState(airlineInfo.code === AIRLINE_NO_FLIGHT);
  // Expand the form by default if the reservation have Airline Info or if the location is
  // a multi terminal airport or is a after hours pickup or if it's a Flight travel indicator
  const isRequired = isAfterHoursPickup || isMultiTerminalAirport || isFlightTravelIndicator;
  const [formExpanded, setFormExpanded] = useState(!!airlineInfo.code || isRequired);
  // If the reservation already have a Flight Information, we show it in a "display mode"
  const [isDisplayOnly, setIsDisplayOnly] = useState(!!airlineInfo.code);

  const [virginAirlinesOptions, setVirginAirlinesOptions] = useState([]);

  useEffect(
    () => {
      if (isVirginReservation) {
        const virginAirlines = airlineOptions.filter(({ label }) => label.includes('VIRGIN'));
        const notVirginAirlines = airlineOptions.filter(({ label }) => !label.includes('VIRGIN'));
        setVirginAirlinesOptions([...virginAirlines, ...notVirginAirlines]);
      }
    },
    [airlineOptions, isVirginReservation]
  );

  // Also change the form description text if the location is multi terminal
  const formDescription = isMultiTerminalAirport
    ? 'review_flight_info_form_description_multi_terminal'
    : 'review_flight_info_form_description';

  // Handler for "Dont Have a Flight" and "Airline Not Listed"
  const handleSetAirline = (code, isNotListed) => () => {
    setAirlineNotListed(!!isNotListed);
    setNoFlight(code === AIRLINE_NO_FLIGHT);
    updateCommitStateHandler('flight_number', code);
  };

  const curriedUpdateCommitState = stateKey => e => {
    // The value is either e.target.value for inputs or just the param
    // in the case of the ResponsiveListBox airline selector.
    const value = e.target ? e.target.value : e;
    updateCommitStateHandler(stateKey, value);
  };

  const toggleEdit = () => {
    setFormExpanded(true);
    setIsDisplayOnly(false);
  };

  // Added this debounce because onAddFlightName was getting called twice on select
  const onAddFlightName = gmiUtils.debounce(e => {
    curriedUpdateCommitState('code')(e);
    // Analytics event
    !!e && dispatchEventWithDOM('FlightAdded');
  }, 500);

  return (
    <div className='post-res__content theme--light'>
      {fieldsOnly && (
        <>
          <div className='post-res-form__field-row'>
            <ResponsiveListbox
              name='airline_information.code'
              label={utils.i18n('review_flight_info_airline_name_input_label')}
              onChange={onAddFlightName}
              options={isVirginReservation ? virginAirlinesOptions : airlineOptions}
              fill
              required={true}
              ref={flightCode}
            />
            <TextField
              name='airline_information.flight_number'
              label={utils.i18n('review_flight_info_flight_number_input_label')}
              onChange={curriedUpdateCommitState('flight_number')}
              fill
              required={true}
              ref={flightNumber}
            />
          </div>
        </>
      )}

      {!fieldsOnly && (
        <>
          <div className='post-res__section-header'>
            <h3 className='post-res__section-title'>
              {utils.i18n('review_flight_info_form_title')}
              <span className='post-res__title__optional-indicator'>{utils.i18n('common_optional')}</span>
            </h3>
            {isDisplayOnly && (
              <Button onClick={toggleEdit} className='post-res__link-text post-res__link-text--inline' linkText>
                {utils.i18n('common_edit')}
              </Button>
            )}
          </div>

          {isDisplayOnly ? (
            <p className='post-res__paragraph'>
              {airlineInfo.description} {airlineInfo.flight_number}
            </p>
          ) : (
            <>
              <p className='post-res__paragraph post-res__paragraph--margin-bottom'>
                {airlineNotListed ? (
                  <>
                    {utils.i18n('review_flight_info_airline_not_listed_selected_label')}{' '}
                    <b>
                      {utils.i18n(
                        noFlight ? 'review_flight_info_dont_have_flight' : 'review_flight_info_airline_not_listed'
                      )}
                      {'.'}
                    </b>
                    <Button
                      onClick={handleSetAirline(null)}
                      className='post-res__link-text post-res__link-text--inline'
                      linkText>
                      {utils.i18n('review_flight_info_airline_not_listed_edit')}
                    </Button>
                  </>
                ) : (
                  utils.i18n(formDescription)
                )}
              </p>
              {!formExpanded && (
                <Button
                  onClick={() => setFormExpanded(true)}
                  className='post-res-flight-info__add-button'
                  button={false}>
                  {utils.i18n('review_flight_info_add_flight_button')}
                </Button>
              )}
              {formExpanded && !airlineNotListed && (
                <>
                  <div className='post-res-form__field-row'>
                    <ResponsiveListbox
                      name='airline_information.code'
                      label={utils.i18n('review_flight_info_airline_name_input_label')}
                      onChange={onAddFlightName}
                      options={isVirginReservation ? virginAirlinesOptions : airlineOptions}
                      fill
                      required={true}
                    />
                    <TextField
                      name='airline_information.flight_number'
                      label={utils.i18n('review_flight_info_flight_number_input_label')}
                      onChange={curriedUpdateCommitState('flight_number')}
                      fill
                      required={true}
                    />
                  </div>
                  <div className='post-res-flight-info__actions-group'>
                    <Button
                      onClick={handleSetAirline(AIRLINE_NO_FLIGHT, true)}
                      className='post-res__link-text'
                      button={false}
                      linkText
                      data-dtm-track={dtm(
                        isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.CHECK_IN,
                        ANALYTICS.REVIEW,
                        'no_flight'
                      )}>
                      {utils.i18n('review_flight_info_dont_have_flight')}
                    </Button>
                    <Button
                      onClick={handleSetAirline(AIRLINE_NO_AIRLINE, true)}
                      className='post-res__link-text'
                      button={false}
                      linkText
                      data-dtm-track={dtm(
                        isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.CHECK_IN,
                        ANALYTICS.REVIEW,
                        'airline_not_listed'
                      )}>
                      {utils.i18n('review_flight_info_airline_not_listed')}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PostResFlightInfo;
