import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Button from 'components/Button';
import { RESERVATIONS, GLOBAL } from 'constants';
import cn from 'classnames';
import FormattedPrice from 'components/FormattedPrice';

const { EXTRAS_INCLUDED_STATUS, EXTRAS_WAIVED_STATUS, ADDITIONAL_DRIVER_INCLUDED_STATUS } = RESERVATIONS;
const extraStatusesArray = [EXTRAS_INCLUDED_STATUS, EXTRAS_WAIVED_STATUS, ADDITIONAL_DRIVER_INCLUDED_STATUS];

function getExtraPriceOrStatus(total_amount_view, status, asterisk) {
  if (extraStatusesArray.indexOf(status) > -1) {
    return utils.i18n(`extras_status_${status}`);
  }

  return utils.getFormattedPrice(total_amount_view, { asterisk });
}

const Extras = ({
  extras,
  handleEdit,
  showPrice = true,
  asterisk,
  allowLimitedInventoryIconExtra,
  selectedBundleWithExtras = {},
  extrasWithoutBundles,
  numberOfExtrasSelected,
  isThirdPartyReservation,
  hideEditButtons,
  displayAsList,
}) => {
  const bundlesExtras = selectedBundleWithExtras?.bundleExtras || [];
  const hasBundleExtras = !!bundlesExtras.length;
  const bundledAdditionalDrivers =
    selectedBundleWithExtras?.vehicle_rates?.supplemental?.additional_driver?.number_of_free_additional_drivers || null;
  const extrasToMap = hasBundleExtras ? extrasWithoutBundles : extras;

  const extrasTitle = utils.i18n('rental_details_extras');
  const editButtonTextKey = numberOfExtrasSelected ? 'rental_details_edit' : 'rental_details_add';
  const dtmTrackEditButtonTextKey = numberOfExtrasSelected ? 'edit' : 'add';
  const editButtonAriaText = utils.i18n(`${editButtonTextKey}_aria_label`, [extrasTitle]);

  const tableFormatted = () => (
    <div className='rental-details__section rental-details__locations'>
      <table>
        <caption className='rental-details__table-section-caption'>
          {handleEdit && !hideEditButtons ? (
            <React.Fragment>
              {utils.i18n('rental_details_extras_count', [numberOfExtrasSelected])}

              <Button
                link
                onClick={handleEdit('extras')}
                ariaText={editButtonAriaText}
                data-dtm-track={`rental_details|${utils.i18n('rental_details_extras')}|${dtmTrackEditButtonTextKey}`}
              >
                {utils.i18n(editButtonTextKey)}
              </Button>
            </React.Fragment>
          ) : (
            utils.i18n('rental_details_extras_count', [numberOfExtrasSelected])
          )}
        </caption>
        <tbody>
          {!numberOfExtrasSelected && (
            <tr className='rental-details__extras-empty'>
              <td>{utils.i18n('rental_details_extras_empty')}</td>
            </tr>
          )}
          {!!numberOfExtrasSelected &&
            extrasToMap.map(({ code, name, total_amount_view, selected_quantity, status, allocation, description }) => (
              <tr className='rental-details__line-item-row' key={code}>
                <th
                  align='left'
                  colSpan={showPrice ? null : '2'}
                  className={cn('rental-details__extra-name', {
                    'rental-details__limited-inventory-icon--extra':
                      allocation === GLOBAL.LIMITED_INVENTORY_EXTRA_CODE && allowLimitedInventoryIconExtra,
                  })}
                >
                  {name || description}
                  {selected_quantity > 1 && ` ${utils.i18n('rental_details_extra_quantity', [selected_quantity])}`}
                </th>
                {showPrice && (
                  <td align='right'>
                    <FormattedPrice classNames='rental-details__extra-price'>
                      {/* TODO: replace with secret_rate flag */}
                      {isThirdPartyReservation && (parseInt(total_amount_view, 10) === 0 || !total_amount_view)
                        ? utils.i18n('third_party_reservation_net_rate')
                        : getExtraPriceOrStatus(total_amount_view, status, asterisk)}
                    </FormattedPrice>
                  </td>
                )}
              </tr>
            ))}
          {hasBundleExtras && (
            <tr className='rental-details__line-item-row rental-details__line-item--bundle'>
              <th align='left' colSpan={showPrice ? null : '2'} className='rental-details__extra-name'>
                {'Personal Accident Insurance'} {selectedBundleWithExtras?.title}
              </th>
              {showPrice && (
                <td align='right' className='rental-details__extra-price'>
                  {utils.i18n('extras_added')}
                </td>
              )}
            </tr>
          )}
          {bundlesExtras.map(({ code, name, selected_quantity, allocation }) => (
            <tr className='rental-details__line-item-row rental-details__line-item-row-bundle-item' key={code}>
              <td
                colSpan='2'
                align='left'
                className={cn('rental-details__extra-name', {
                  'rental-details__limited-inventory-icon--extra':
                    allocation === GLOBAL.LIMITED_INVENTORY_EXTRA_CODE && allowLimitedInventoryIconExtra,
                })}
              >
                {name} {selected_quantity > 1 ? utils.i18n('rental_details_extra_quantity', [selected_quantity]) : null}
              </td>
            </tr>
          ))}
          {bundledAdditionalDrivers && (
            <tr className='rental-details__line-item-row rental-details__line-item--bundle-item'>
              <td colSpan='2' align='left' className='rental-details__extra-name'>
                {utils.i18n('bundles_card_additional_drivers', [bundledAdditionalDrivers])}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  const listFormatted = () => (
    <div className='rental-details__section rental-details__locations'>
      <h3 className='rental-details__table-section-caption'>
        {handleEdit && !hideEditButtons ? (
          <React.Fragment>
            {utils.i18n('rental_details_extras_count', [numberOfExtrasSelected])}

            <Button
              link
              onClick={handleEdit('extras')}
              ariaText={editButtonAriaText}
              data-dtm-track={`rental_details|${utils.i18n('rental_details_extras')}|${dtmTrackEditButtonTextKey}`}
            >
              {utils.i18n(editButtonTextKey)}
            </Button>
          </React.Fragment>
        ) : (
          utils.i18n('rental_details_extras_count', [numberOfExtrasSelected])
        )}
      </h3>

      <ul className='rental-details__list-item'>
        {!numberOfExtrasSelected && (
          <li className='rental-details__extras-empty'>
            <span>{utils.i18n('rental_details_extras_empty')}</span>
          </li>
        )}
        {!!numberOfExtrasSelected &&
          extrasToMap.map(({ code, name, total_amount_view, selected_quantity, status, allocation }) => (
            <li className='rental-details__line-item-row' key={code}>
              <span
                className={cn('rental-details__extra-name', {
                  'rental-details__limited-inventory-icon--extra':
                    allocation === GLOBAL.LIMITED_INVENTORY_EXTRA_CODE && allowLimitedInventoryIconExtra,
                })}
              >
                {name}
                {selected_quantity > 1 && ` ${utils.i18n('rental_details_extra_quantity', [selected_quantity])}`}
              </span>
              {showPrice && (
                <span>
                  <FormattedPrice classNames='rental-details__extra-price'>
                    {/* TODO: replace with secret_rate flag */}
                    {isThirdPartyReservation && (parseInt(total_amount_view, 10) === 0 || !total_amount_view)
                      ? utils.i18n('third_party_reservation_net_rate')
                      : getExtraPriceOrStatus(total_amount_view, status, asterisk)}
                  </FormattedPrice>
                </span>
              )}
            </li>
          ))}
        {hasBundleExtras && (
          <li className='rental-details__line-item-row rental-details__line-item--bundle'>
            <span className='rental-details__extra-name'>
              {'Personal Accident Insurance'} {selectedBundleWithExtras?.title}
            </span>
            {showPrice && <span className='rental-details__extra-price'>{utils.i18n('extras_added')}</span>}
          </li>
        )}
        {bundlesExtras.map(({ code, name, selected_quantity, allocation }) => (
          <li className='rental-details__line-item-row rental-details__line-item-row-bundle-item' key={code}>
            <span
              className={cn('rental-details__extra-name', {
                'rental-details__limited-inventory-icon--extra':
                  allocation === GLOBAL.LIMITED_INVENTORY_EXTRA_CODE && allowLimitedInventoryIconExtra,
              })}
            >
              {name} {selected_quantity > 1 ? utils.i18n('rental_details_extra_quantity', [selected_quantity]) : null}
            </span>
          </li>
        ))}
        {bundledAdditionalDrivers && (
          <li className='rental-details__line-item-row rental-details__line-item--bundle-item'>
            <span className='rental-details__extra-name'>
              {utils.i18n('bundles_card_additional_drivers', [bundledAdditionalDrivers])}
            </span>
          </li>
        )}
      </ul>
    </div>
  );

  return displayAsList ? listFormatted() : tableFormatted();
};

Extras.propTypes = {
  extras: PropTypes.array,
  handleEdit: PropTypes.func,
  hideEditButtons: PropTypes.bool,
  showPrice: PropTypes.bool,
  asterisk: PropTypes.bool,
  allowLimitedInventoryIconExtra: PropTypes.bool,
  selectedBundleWithExtras: PropTypes.object,
  extrasWithoutBundles: PropTypes.array,
  numberOfExtrasSelected: PropTypes.number,
  isThirdPartyReservation: PropTypes.bool,
  displayAsList: PropTypes.bool,
};

Extras.defaultProps = {
  isThirdPartyReservation: false,
};

export default Extras;
