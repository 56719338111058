import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';

const CardHolderDisclaimer = props => {
  const { enableInUSorCAonly } = props;
  const { config, i18n } = utils;
  const isUnitedStatesDomain = config.isUnitedStatesDomain();
  const isCanadianDomain = config.isCanadianDomain();
  const showBanner = !enableInUSorCAonly || (enableInUSorCAonly && (isUnitedStatesDomain || isCanadianDomain));

  return showBanner ? (
    <p className='card-holder-disclaimer__container'>{i18n('stc_add_card_cardholder_disclaimer')}</p>
  ) : null;
};

CardHolderDisclaimer.propTypes = {
  enableInUSorCAonly: PropTypes.bool,
};

export default CardHolderDisclaimer;
