import React, { Component } from 'react';
import Mileage from 'components/RentalDetails/Mileage';
import utils from 'utils';
import TimeAndDistance from 'components/RentalDetails/RentalDetailsSharedComponents/TimeAndDistance';
import Extras from 'components/RentalDetails/Extras';
import TotalPrice from 'components/RentalDetails/TotalPrice';
import TaxesFees from 'components/RentalDetails/TaxesFees';
import BundleAddedCost from 'components/RentalDetails/RentalDetailsSharedComponents/BundleAddedCost';
import AdditionalDrivers from 'components/RentalDetails/AdditionalDrivers';
import ANALYTICS from 'constants/analytics';
import propTypes from './progressBarTotalPropTypes';

const asterisk = true;

class ProgressBarTotal extends Component {
  static propTypes = propTypes;

  state = {
    taxesDrawerExpanded: false,
  };

  componentDidMount() {
    utils.scrollLock();
  }

  componentWillUnmount() {
    utils.scrollLock({ toggleValue: false });
  }

  handleToggleTaxesDrawer = () => {
    this.setState(({ taxesDrawerExpanded }) => ({ taxesDrawerExpanded: !taxesDrawerExpanded }));
  };

  handlePrepayFlop = () => {
    const { prepayFlopCurrentSession, reservation, coupons, showCouponDoesNotApplyModal } = this.props;
    const isPayLater = !reservation?.prepay_selected;

    // Should show modal if current rate is pay-later with coupon applied and will change to pre-pay
    const shouldShowCouponModal = isPayLater && coupons.length > 0;

    shouldShowCouponModal
      ? showCouponDoesNotApplyModal((continueWithoutCoupon) => {
          continueWithoutCoupon && prepayFlopCurrentSession();
        })
      : prepayFlopCurrentSession();

    utils.analytics.interaction(
      ANALYTICS.DRAWER,
      'total price',
      isPayLater ? 'switch to pre-pay' : 'switch to pay later'
    );
  };

  render() {
    const {
      reservation,
      showViewDetailsModal,
      vehicleRate,
      extras,
      showLearnMoreModal,
      oppositeVehicleRate,
      extrasWithoutBundles,
      selectedBundleWithExtras,
      numberOfExtrasSelected,
      coupons,
    } = this.props;
    const mileage_info = reservation?.car_class_details?.mileage_info;
    const { taxesDrawerExpanded } = this.state;
    const {
      payment_line_items,
      estimated_total_vehicle_view,
      estimated_total_vehicle_payment,
      estimated_total_payment,
      estimated_total_view,
    } = vehicleRate.price_summary;
    const isPayLater = !reservation?.prepay_selected;
    const chargedRate = payment_line_items?.VEHICLE_RATE || [];
    const savingsAdjustments = payment_line_items?.SAVINGS || [];
    const oppositeEstimatedTotal = oppositeVehicleRate?.price_summary?.estimated_total_view;
    const freeUpgradeApplied = utils.isFreeUpgradeAvailable(coupons);

    return (
      <div className='progress-bar-total__container theme--light-blue'>
        <div className='progress-bar-total__milage-container'>
          <Mileage mileageInfo={mileage_info} theme='component-theme--light-blue' />
        </div>
        <div className='progress-bar-total__rental-rate-and-extras'>
          <h4 className='progress-bar-total__title'>{utils.i18n('total_progress_bar_rental_rate_title')}</h4>
          <TimeAndDistance
            chargedRate={chargedRate}
            estimatedTotalVehicleView={estimated_total_vehicle_view}
            estimatedTotalVehiclePayment={estimated_total_vehicle_payment}
            asterisk={asterisk}
          />
          <BundleAddedCost selectedBundleWithExtras={selectedBundleWithExtras} />
        </div>
        <Extras
          numberOfExtrasSelected={numberOfExtrasSelected}
          extras={extras}
          asterisk={asterisk}
          selectedBundleWithExtras={selectedBundleWithExtras}
          extrasWithoutBundles={extrasWithoutBundles}
        />
        <AdditionalDrivers />
        <TaxesFees
          asterisk={asterisk}
          handleViewDetailsModal={showViewDetailsModal}
          isReview
          handleToggleTaxesDrawer={this.handleToggleTaxesDrawer}
          taxesDrawerExpanded={taxesDrawerExpanded}
          priceSummary={vehicleRate.price_summary}
        />
        <TotalPrice
          displayPrePayFlop
          isReview
          asterisk={asterisk}
          estimatedTotalView={estimated_total_view}
          estimatedTotalPayment={estimated_total_payment}
          showLearnMoreModal={showLearnMoreModal}
          oppositeEstimatedTotal={oppositeEstimatedTotal}
          isPayLater={isPayLater}
          handlePrepayFlop={this.handlePrepayFlop}
          adjustments={savingsAdjustments}
          freeUpgradeApplied={freeUpgradeApplied}
          doubleAsteriskTotalView={true}
        />
      </div>
    );
  }
}

export default ProgressBarTotal;
