import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import cn from 'classnames';
import { ANALYTICS, THEMES, PROFILE, GMI_SERVICE_PATHS, MODAL } from 'constants';
import Form from 'components/Form/Form';
import Button from 'components/Button';
import ServiceErrors from 'components/ServiceErrors';
import TextField from 'components/Form/TextField';
import SelectField from 'components/Form/SelectField';
import Checkbox from 'components/Form/Checkbox';
import LoadingWidget from 'components/LoadingWidget';

const domainCountryCode = utils.config.getDomainCountry();

const defaultOptionValue = 'NONE';
const defaultOption = { label: utils.i18n('my_profile_edit_prp_modal_program_none_option'), value: defaultOptionValue };

const onSubmit = (modifyProfile, onSuccess, availablePrograms) => formData => {
  const profilePrograms = [];

  if (formData.code !== defaultOptionValue) {
    profilePrograms.push({
      code: formData.code,
      name: availablePrograms.find(program => program.code === formData.code)?.name,
      number: formData.number,
      accept_surcharge: !!formData.surcharge,
    });
  }

  const payload = {
    preference: {
      reward_preferences: {
        partner_rewards_programs: profilePrograms,
        reward_type: PROFILE.PREFERENCE_REWARD_PARTNER,
      },
    },
  };

  modifyProfile(payload).then(res => {
    // If no message returns from the request, modal should be closed
    // Otherwise GBO errors will show automatically on top of the modal content
    !res?.messages && onSuccess();
  });
};

/**
 * EditProfilePRPModal
 * Handles adding or updating profile's PRP preference in a single-purpose modal.
 *
 * @param {object} props - React Props
 * @param {object} props.profileData - Auth user profile data with PRP information
 * @param {object} props.partnerRewardsPrograms - Available Partner Rewards Programs
 * @param {function} props.getPartnerRewardsPrograms - Function to retrieve available Partner Programs for program select
 * @param {function} props.modifyProfile - Function to update user's profile with PRP data
 * @param {string} props.surchargeContent - Content with all Surcharge details
 * @param {function} props.getPRPSurchargeContent - Function to retrieve surcharge details content
 * @param {function} props.footer - Render Props function to display the modal's footer
 * @param {function} props.onSubmitSuccess - Handler function for a successful form submit
 * @return {JSX} EditProfilePRPModal jsx component
 */
const EditProfilePRPForm = ({
  profileData,
  partnerRewardsPrograms,
  getPartnerRewardsPrograms,
  surchargeContent,
  getPRPSurchargeContent,
  modifyProfile,
  onSubmitSuccess,
  footer = () => {},
}) => {
  const { partnerRewardsProgram } = profileData || {};
  const initialValues = {
    code: partnerRewardsProgram?.code || defaultOptionValue,
    number: partnerRewardsProgram?.number,
  };

  const [showMemberIdField, setShowMemberIdField] = useState(!!partnerRewardsProgram);
  const [expanded, setExpanded] = useState(false);

  const availablePrograms = partnerRewardsPrograms?.[domainCountryCode]?.partner_rewards_programs;
  const selectOptions = availablePrograms?.map(program => ({ label: program.name, value: program.code })) || [];

  useEffect(
    () => {
      // Load available Partner Rewards Programs if not present
      if (utils.gmi.isObjectEmpty(partnerRewardsPrograms)) {
        getPartnerRewardsPrograms(domainCountryCode);
        // After loading the programs, recheck if Member ID and surcharge fields should be visible
        setShowMemberIdField(!!partnerRewardsProgram);
      }
    },
    [partnerRewardsPrograms]
  );

  useEffect(
    () => {
      // Load available Partner Rewards Program Surcharge content if not present
      if (utils.gmi.isObjectEmpty(surchargeContent)) {
        getPRPSurchargeContent();
      }
    },
    [surchargeContent]
  );

  function handleCodeChange(e) {
    // If user selects the clear the Partner Rewards Program (option 'None'), then hide Member ID and Surcharge fields.
    setShowMemberIdField(e.target.value !== defaultOptionValue);
  }

  function toggleDrawer() {
    utils.analytics.interaction(
      ANALYTICS.DRAWER,
      'partner rewards surcharges',
      expanded ? ANALYTICS.CLOSE : ANALYTICS.OPEN
    );
    setExpanded(!expanded);
  }

  const renderFields = () => (
    <>
      <div className='my-profile__modal-form__field-row'>
        <SelectField
          id='code'
          name='code'
          onChange={handleCodeChange}
          options={[defaultOption, ...selectOptions]}
          label={utils.i18n('my_profile_edit_prp_modal_program_label')}
          theme={THEMES.LIGHT}
          validations={[utils.fieldValidation.required]}
          required
        />

        {showMemberIdField && (
          <TextField
            name='number'
            label={utils.i18n('my_profile_edit_prp_modal_member_id_label')}
            initialValueButton={partnerRewardsProgram?.number}
            validations={[utils.fieldValidation.required]}
            required
            fill
          />
        )}
      </div>
      {showMemberIdField && (
        <div>
          <Checkbox
            initialValue={!!partnerRewardsProgram}
            label={utils.i18n('my_profile_edit_prp_modal_surcharge_label')}
            theme={THEMES.LIGHT}
            name='surcharge'
            validate={utils.fieldValidation.isTrueBool}
            required={utils.i18n('my_profile_edit_prp_modal_surcharge_error')}
          />

          <p className='my-profile__modal-form__checkbox-helper'>
            <b>{utils.i18n('my_profile_edit_prp_modal_freq_traveler_copy_title')}:</b>
            {` ${utils.i18n('my_profile_edit_prp_modal_freq_traveler_copy')}`}
          </p>

          <div className='modal-themed__drawer-container modal-themed__drawer-container--checkbox-aligned'>
            <Button
              button={false}
              className={cn('modal-themed__drawer', { 'modal-themed__drawer--content-expanded': expanded })}
              onClick={toggleDrawer}
              type='button'
              ariaExpanded={expanded.toString()}>
              {utils.i18n('my_profile_edit_prp_modal_surcharge_list_title')}
            </Button>
            {expanded && (
              <div
                className='modal-themed__section modal-themed__gbo-content'
                dangerouslySetInnerHTML={utils.sanitize(surchargeContent)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );

  return (
    <Form
      onSubmit={onSubmit(modifyProfile, onSubmitSuccess, availablePrograms)}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true, submitFailed: true, values: true }}>
      {({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit} className='my-profile__modal-form component-theme--light' noValidate>
          <p id={`${MODAL.EDIT_PROFILE_PRP_MODAL}_desc`}>{utils.i18n('my_profile_edit_prp_modal_copy')}</p>

          <p className='my-profile__modal-form__required-note'>
            {utils.i18n('my_profile_edit_prp_modal_required_note')}
          </p>

          <ServiceErrors statePath={GMI_SERVICE_PATHS.MODIFY_PROFILE} />

          {!availablePrograms ? <LoadingWidget /> : renderFields()}

          {footer(handleSubmit)}
        </form>
      )}
    </Form>
  );
};

EditProfilePRPForm.propTypes = {
  profileData: PropTypes.object,
  partnerRewardsPrograms: PropTypes.object,
  getPartnerRewardsPrograms: PropTypes.func,
  modifyProfile: PropTypes.func,
  surchargeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // When empty, returns a empty object
  getPRPSurchargeContent: PropTypes.func,
  onSubmitSuccess: PropTypes.func.isRequired,
  footer: PropTypes.func.isRequired,
};

export default EditProfilePRPForm;
