import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT } = MODAL_BUTTON_TYPES;

/**
 * AlreadyCheckedInHelpModal
 * @return {JSX}
 */
const AlreadyCheckedInHelpModal = () => (
  <Modal
    modalKey={MODAL.ALREADY_CHECKED_IN_HELP_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('already_checked_in_help_title')}>
    <>
      <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
        {utils.i18n('already_checked_in_help_copy')}
      </p>

      <ModalFooter
        buttons={[
          {
            type: ACCEPT,
            label: utils.i18n('common_close'),
          },
        ]}
      />
    </>
  </Modal>
);

export default AlreadyCheckedInHelpModal;
