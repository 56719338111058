import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';

const VehicleDetailsIconList = ({
  transmission,
  peopleCapacity,
  luggageCapacity,
  twoOrFourDoorText,
  isDriveHappyDeal,
  features,
}) => (
  <ul className='vehicle-details-icon-list'>
    {features &&
      features.map(
        (feature) =>
          feature.code === '170' && (
            <li className='vehicle-details-icon-list__icon vehicle-details-icon-list__icon--ev' key={feature.code}>
              <span className='vehicle-details-icon-list__icon--sr-only'>
                {utils.i18n('vehicle_details_electric')}:
              </span>
              {utils.i18n('vehicle_details_electric')}
            </li>
          )
      )}
    {transmission && (
      <li className='vehicle-details-icon-list__icon vehicle-details-icon-list__icon--transmission'>
        <span className='vehicle-details-icon-list__icon--sr-only'>{utils.i18n('vehicle_details_transmission')}:</span>
        {transmission}
      </li>
    )}
    {!isDriveHappyDeal && peopleCapacity && (
      <li className='vehicle-details-icon-list__icon vehicle-details-icon-list__icon--passenger'>
        <span className='vehicle-details-icon-list__icon--sr-only'>
          {utils.i18n('vehicle_details_passenger_capacity')}
        </span>
        {peopleCapacity}
      </li>
    )}
    {!isDriveHappyDeal && luggageCapacity > 0 && (
      <li className='vehicle-details-icon-list__icon vehicle-details-icon-list__icon--suitcase'>
        <span className='vehicle-details-icon-list__icon--sr-only'>
          {utils.i18n('vehicle_details_baggage_capacity')}:
        </span>
        {luggageCapacity}
      </li>
    )}
    {!isDriveHappyDeal && twoOrFourDoorText && (
      <li className='vehicle-details-icon-list__icon vehicle-details-icon-list__icon--doors'>
        <span className='vehicle-details-icon-list__icon--sr-only'>
          {utils.i18n('vehicle_details_number_of_doors')}:
        </span>
        {twoOrFourDoorText}
      </li>
    )}
  </ul>
);

VehicleDetailsIconList.propTypes = {
  transmission: PropTypes.string,
  peopleCapacity: PropTypes.number,
  luggageCapacity: PropTypes.number,
  twoOrFourDoorText: PropTypes.string,
  isDriveHappyDeal: PropTypes.bool,
  features: PropTypes.array,
};

export default VehicleDetailsIconList;
