import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ANALYTICS, MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';
import FormattedPrice from 'components/FormattedPrice';
import ServiceErrors from 'components/ServiceErrors';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * ConfirmCancelReservationModal - confirms that user wants to cancel reservation, showing any fees this may entail
 *
 * @param {object} props - React Props
 * @param {object} props.cancelReservation - dispatch cancelReservation action
 * @param {string} props.prepaySelected - prepay flag
 * @param {object}  props.appliedCancelFeeDetails - object regarding cancellation fee applied
 * @param {bool}   props.guaranteedResCardRequired - guaranteed res flag
 * @param {object} props.cancelFee - object regarding cancellation fee
 * @return {JSX}
 */
export default class ConfirmCancelReservationModal extends Component {
  renderGuaranteedResCancelFee = () => {
    const { cancelFee } = this.props;
    return <strong key={0}>{utils.getFormattedPrice(cancelFee.fee_amount_view)}</strong>;
  };

  renderCancellationMessage = () => {
    const { prepaySelected, guaranteedResCardRequired, cancelFee, appliedCancelFeeDetails } = this.props;

    if (prepaySelected) {
      return <p className='confirmation__modal-cancel-copy'>{appliedCancelFeeDetails?.cancellation_message}</p>;
    }

    if (guaranteedResCardRequired && cancelFee?.fee_apply) {
      return (
        <p className='modal-themed__section'>
          {utils.i18n('guaranteed_res_cancel_modal_copy', [this.renderGuaranteedResCancelFee()], { jsx: true })}
        </p>
      );
    }

    return (
      <p className='confirmation__modal-cancel-copy'>{utils.i18n('confirmation_reservation_cancel_modal_copy')}</p>
    );
  };

  renderPrepayCancellationRefund = () => {
    const { prepaySelected, guaranteedResCardRequired, cancelFee, appliedCancelFeeDetails } = this.props;

    if (prepaySelected) {
      const { fee_amount_view, original_amount_view, refund_amount_view } = appliedCancelFeeDetails || {};
      return (
        <div className='confirmation__modal-cancel-refund'>
          <p className='refund-line-item'>
            <span className='refund-line-item__label'>
              {utils.i18n('confirmation_reservation_cancel_original_amount')}
            </span>
            <FormattedPrice classNames='refund-line-item__value'>
              {utils.getFormattedPrice(original_amount_view)}
            </FormattedPrice>
          </p>
          <p className='refund-line-item'>
            <span className='refund-line-item__label'>{utils.i18n('confirmation_reservation_cancel_fee')}</span>
            <FormattedPrice classNames='refund-line-item__value'>
              {utils.getFormattedPrice(fee_amount_view)}
            </FormattedPrice>
          </p>
          <p className='refund-line-item total'>
            <span className='refund-line-item__label'>
              {utils.i18n('confirmation_reservation_cancel_amount_refunded')}
            </span>
            <FormattedPrice classNames='refund-line-item__value'>
              {utils.getFormattedPrice(refund_amount_view)}
            </FormattedPrice>
          </p>
        </div>
      );
    }

    if (guaranteedResCardRequired && cancelFee?.fee_apply) {
      return (
        <p className='modal-themed__section'>
          {utils.i18n('guaranteed_res_cancel_modal_copy_2', [this.renderGuaranteedResCancelFee()], { jsx: true })}
        </p>
      );
    }

    return null;
  };

  acceptAndClose = (handleClose) => () => {
    this.props.cancelReservation().then((response) => {
      if (!response.messages) {
        handleClose({ skipAnalytics: true });
      } else {
        utils.dom.loadingOverlay(false);
      }
    });
  };

  render() {
    return (
      <Modal
        modalKey={MODAL.CONFIRM_CANCEL_RESERVATION_MODAL}
        header={utils.i18n('confirmation_reservation_cancel_modal_header')}
        theme={MODAL_THEMES.WHITE}
      >
        {({ handleClose }) => (
          <>
            <ServiceErrors />
            <div id={`${MODAL.CONFIRM_CANCEL_RESERVATION_MODAL}_desc`}>{this.renderCancellationMessage()}</div>

            <div id={`${MODAL.CONFIRM_CANCEL_RESERVATION_MODAL}_desc`}>{this.renderPrepayCancellationRefund()}</div>

            <ModalFooter
              fullWidthButton
              buttons={[
                {
                  type: DECLINE,
                  label: utils.i18n('confirmation_reservation_cancel_modal_decline'),
                  analyticsValue: ANALYTICS.KEEP_RESERVATION,
                  analyticsKey: ANALYTICS.CANCEL_RESERVATION_KEY,
                },
                {
                  type: ACCEPT,
                  label: utils.i18n('confirmation_reservation_cancel_modal_accept'),
                  handler: this.acceptAndClose(handleClose),
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      ANALYTICS.MODAL,
                      ANALYTICS.CANCEL_RESERVATION_KEY,
                      ANALYTICS.CANCEL_RESERVATION_KEY
                    ),
                },
              ]}
            />
          </>
        )}
      </Modal>
    );
  }
}

ConfirmCancelReservationModal.propTypes = {
  cancelReservation: PropTypes.func.isRequired,
  prepaySelected: PropTypes.bool,
  appliedCancelFeeDetails: PropTypes.object,
  guaranteedResCardRequired: PropTypes.bool,
  cancelFee: PropTypes.object,
};
