import React from 'react';
import { i18n } from 'utils/i18n';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import Modal from 'components/Modal';

/**
 * Informs About Virgin Flight Club
 * @return {JSX}
 */
const VirginAtlanticFlyingClubModal = () => (
  <Modal
    modalKey={MODAL.VIRGIN_ATLANTIC_FLIGHT_CLUB_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={i18n('virgin_atlantic_flying_club')}>
    <p className='modal-themed__copy modal-themed__paragraph'>{i18n('virgin_atlantic_flying_club_modal_content')}</p>
  </Modal>
);

export default VirginAtlanticFlyingClubModal;
