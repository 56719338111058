import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import MyProfileSectionTitle from '../MyProfileSectionTitle';
import EditDriversLicenseModal from '../EditDriversLicenseModal';

/**
 * DriversLicense
 * block to show users driver's license details
 *
 * @param {object} props - React Props
 * @param {object} props.profileData - Authenticated user profile data
 * @param {object} props.profileData.license_profile - Authenticated user drivers license info
 * @param {object} props.profileData.oci_individual_status - Alamo Insiders individual status for Online Check In
 * @param {function} props.openEditModal - Function to open modal with edit form
 *
 * @return {JSX}
 */
const DriversLicense = ({ profileData, openEditModal }) => {
  const { license_profile, oci_individual_status } = profileData || {};
  const { license_number, license_expiration_date, license_issue_date, birth_date } = license_profile || {};

  return (
    <div className='my-profile__section my-profile__section--top-border my-profile__section--desktop-half-width'>
      <MyProfileSectionTitle
        title={utils.i18n('my_profile_drivers_license')}
        handleEdit={openEditModal}
        isCompleted={oci_individual_status?.driverlicense_details_present && oci_individual_status?.dob_details_present}
      />
      {!!license_profile && (
        <>
          <p className='my-profile__info-row'>
            {utils.i18n('my_profile_drivers_license_number')}: {license_number}
          </p>
          {license_expiration_date && (
            <p className='my-profile__info-row'>
              {utils.i18n('my_profile_drivers_license_expiration_date')}:{' '}
              {utils.gmi.getMaskedGBODate(license_expiration_date)}
            </p>
          )}
          {license_issue_date && (
            <p className='my-profile__info-row'>
              {utils.i18n('my_profile_drivers_license_issue_date')}: {utils.gmi.getMaskedGBODate(license_issue_date)}
            </p>
          )}
          <p className='my-profile__info-row'>
            {utils.i18n('my_profile_drivers_license_birth_date')}: {utils.gmi.getMaskedGBODate(birth_date)}
          </p>
        </>
      )}

      <EditDriversLicenseModal />
    </div>
  );
};

DriversLicense.propTypes = {
  profileData: PropTypes.shape({
    license_profile: PropTypes.object,
    oci_individual_status: PropTypes.object,
  }),
  openEditModal: PropTypes.func,
};

export default DriversLicense;
