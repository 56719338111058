import React, { useState } from 'react';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { fetchCurrentSession } from 'actions/session';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { MODAL, MODAL_THEMES } from 'constants';
import SessionTimeoutModalContent from './SessionTimeoutModalContent';

/**
 * TimeOutModal open the timeout modal when the user is inactive on the site for the 20minutes
 * @param {object} props - Properties passed into the react component",
 */

const SessionTimeoutModal = ({ sessionTimeoutValue }) => {
  const updateCurrentSession = useActions(fetchCurrentSession);

  const [documentTitleState] = useState(document.title);
  const TAB_ID_SESSION = utils.localStorage.getTabIdSession();

  return (
    <Modal
      modalKey={MODAL.SESSION_TIMEOUT_MODAL}
      header={utils.i18n('session_timeout_message')}
      customActionOnClose={() => {
        updateCurrentSession();
        sessionStorage.removeItem(`sessionDeadlineValue_${TAB_ID_SESSION}`);
        sessionStorage.setItem(`pauseTimer_${TAB_ID_SESSION}`, true);
        document.title = documentTitleState;
      }}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose }) => (
        <div id={`${MODAL.SESSION_TIMEOUT_MODAL}_desc`}>
          <SessionTimeoutModalContent
            sessionTimeoutValue={sessionTimeoutValue}
            handleClose={handleClose}
            documentTitleState={documentTitleState}
          />
        </div>
      )}
    </Modal>
  );
};

export default SessionTimeoutModal;

SessionTimeoutModal.propTypes = {
  runTimer: PropTypes.bool,
  countDown: PropTypes.number,
  modalKey: PropTypes.bool,
};
