import React, { Component } from 'react';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';
import { propTypes } from './PolicyTabPropTypes';

class PolicyTab extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = {
      index: props.index,
      uuid: utils.dom.uuid(),
      isExpanded: props.expanded,
    };
  }

  static getDerivedStateFromProps(props) {
    // If onToggle is set, let callback handle expanding states via prop
    return props.onToggle ? { isExpanded: props.expanded } : null;
  }

  handleToggle = (e) => {
    const { onToggle, name } = this.props;
    const { isExpanded } = this.state;

    e.preventDefault();

    // Analytics
    utils.analytics.interaction(ANALYTICS.DRAWER, name, isExpanded ? ANALYTICS.CLOSE : ANALYTICS.OPEN);

    // If onToggle is set, let callback handle expanding states via prop
    onToggle ? onToggle(this.state) : this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { name, content, disabled } = this.props;
    const { uuid, isExpanded } = this.state;

    return (
      <div className={cn('policy-tab', { disabled, expanded: !disabled && isExpanded })}>
        <h3 className='policy-tab__wrapper'>
          <Button
            plain
            className='policy-tab__toggler'
            id={uuid}
            role='tab'
            aria-label={name}
            aria-expanded={isExpanded.toString()}
            aria-controls={`${uuid}__drawer`}
            onClick={this.handleToggle}
          >
            <span className='policy-tab__title'>{name}</span>
          </Button>
        </h3>

        <div
          className={`policy-tab__drawer`}
          id={`${uuid}__drawer`}
          aria-labelledby={uuid}
          role='tabpanel'
          aria-hidden={!isExpanded || disabled}
        >
          {isExpanded && <div className='policy-tab__content' dangerouslySetInnerHTML={utils.sanitize(content)} />}
        </div>
      </div>
    );
  }
}

export default PolicyTab;
