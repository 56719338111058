import PropTypes from 'prop-types';

export const propTypes = {
  address: PropTypes.string.isRequired,
  lat: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  long: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  locationType: PropTypes.string.isRequired,
};

export const defaultProps = {};
