import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';
import VisHiddenText from 'components/VisHiddenText';

/**
 * MyProfileSectionTitle
 * title for each section in MyProfile details with completion indicator and edit button
 *
 * @param {object} props - React Props
 * @param {string} props.title - Section title
 * @param {boolean} props.isCompleted - Section is completed or not (showing gray or green checkmark)
 * @param {function} props.handleEdit - Function that will be trigger uppon Edit CTA click
 *
 * @return {JSX} ...
 */
const MyProfileSectionTitle = ({ title, isCompleted, handleEdit }) => (
  <div className='my-profile-section-header'>
    <h3 className='my-profile-section-header__title'>
      {title}
      <span
        className={cn('my-profile-section-header__icon', {
          'my-profile-section-header__icon--completed': isCompleted,
        })}>
        <VisHiddenText message={isCompleted ? ANALYTICS.COMPLETE : ANALYTICS.IN_COMPLETE} />
      </span>
    </h3>
    <Button
      onClick={handleEdit}
      className='my-profile-section-header__edit-cta'
      link
      linkText
      data-dtm-track={utils.analytics.dtm(ANALYTICS.PROFILE, title, isCompleted ? ANALYTICS.EDIT : ANALYTICS.ADD)}>
      {utils.i18n(isCompleted ? 'my_profile_edit_button' : 'my_profile_add_button')}
    </Button>
  </div>
);

MyProfileSectionTitle.propTypes = {
  title: PropTypes.string,
  isCompleted: PropTypes.bool,
  handleEdit: PropTypes.func,
};

export default MyProfileSectionTitle;
