import { connect } from 'react-redux';
import utils from 'utils';
import { breakpointSelector } from 'selectors';
import { formattedPoliciesSelector } from 'selectors/policiesSelectors';
import { openModal } from "actions/modal/openModal";
import KeyFactsDropDown from './KeyFactsDropDown';

const mapStateToProps = (state) => ({
  breakpoint: breakpointSelector(state),
  policies: formattedPoliciesSelector(state),
  shouldShowKeyFacts:
    !utils.gmi.isObjectEmpty(state.getIn(['gmi', 'session', 'gbo', 'reservation', 'car_class_details']) || {}) &&
    !utils.config.isUnitedStatesDomain() &&
    !utils.config.isCanadianDomain(),
  isRentalConfirmedFlow: state.getIn(['gmi', 'session', 'rental', 'rental_agreement', 'confirm_modify']),
});

const mapDispatchToProps = {
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(KeyFactsDropDown));
