import React, { Component } from 'react';
import cn from 'classnames';
import { ANALYTICS, CURSORS } from 'constants';
import utils from 'utils';
import PropTypes from 'prop-types';
import Button from 'components/Button';

/**
 * BranchHours - used on the store finder and used on the branch pages that are generated in HTL for SEO.  This displays
 *               the hours of a location
 * @param {object} props           - React Props
 * @param {string} props.hideTodaysTimes - if we should hide todays times
 * @param {string} props.getBranchHours  - function to get branch hours
 * @param {string} props.reservationFlow - if we are in reservation flow
 * @param {string} props.showReturnLocation if we should show the return location
 * @param {string} props.pickUpTime       - the pick up time
 * @param {string} props.dropOffTime      - the dropoff time
 * @return {JSX} Branch Hours Component
 */

//  Getting the start of the week according GMA
const startOfWeek = utils.config.getWeek('dow');
//  This component is
class BranchHours extends Component {
  constructor(props) {
    super(props);
    const { reservationFlow, showReturnLocation, pickUpTime, dropOffTime } = this.props;
    //  If this is in StoreFinder use current day - otherwise cal it off of what date is coming down from ResFlow
    //  The leadDay pattern is the driver for this whole component.  When we have the lead day we use the var `arrayToPrint`
    //  go from the leadDay till the day we 'want'.  We store the results in `arrayToPrint`
    const leadDay = reservationFlow
      ? utils.gmi.getDateTimeObjFromTs((showReturnLocation && dropOffTime.split('T')[0]) || pickUpTime.split('T')[0])
      : utils.gmi.getCurrentDateTimeObj();

    this.state = {
      //  The hours come down as an object with the key as the day, we use this slice of state
      //  to quickly get the hours for that day
      currentDayHash: utils.gmi.getDateTimeObjFromTs(leadDay).format('YYYY-MM-DD'),
      //  This is the same day as the hash, just in a readable format
      currentDayReadable: utils.gmi.getDateTimeObjFromTs(leadDay).format('LL'),
      isLocationDataLoaded: false,
      canGoForward: true,
      canGoBackwards: true,
      leadDay,
    };
  }

  componentDidMount() {
    const { getBranchHours, hoursForStore, id } = this.props;
    if (hoursForStore === null) {
      getBranchHours(id);
    } else {
      this.setState(
        {
          isLocationDataLoaded: true,
        },
        () => {
          this.setUpTimeTable();
        }
      );
    }
  }

  componentDidUpdate() {
    const { hoursForStore } = this.props;
    const { isLocationDataLoaded } = this.state;

    if (hoursForStore !== null && !isLocationDataLoaded) {
      this.setState(
        {
          isLocationDataLoaded: true,
        },
        () => {
          this.setUpTimeTable();
        }
      );
    }
  }

  // This takes the lead day and builds the days we should print out
  setUpTimeTable = () => {
    const { hoursForStore } = this.props;
    const { leadDay } = this.state;
    const arrayToPrint = [];
    for (let i = 0; i < 7; i += 1) {
      const dayInUseableFormat = utils.gmi.getDateTimeObjFromTs(leadDay).add(i, 'days');
      const readableDate = dayInUseableFormat.format('dddd');
      const hashForDate = dayInUseableFormat.format('YYYY-MM-DD');
      const latestIndexOfArrayToPrint = arrayToPrint[arrayToPrint.length - 1];
      //  The logic in these if statements covers the the fact if two days have the same hours and they are next to each other
      //  we show them like `DAY - DAY: <SAME TIMES>`
      if (
        arrayToPrint.length === 0 ||
        !utils.date.areDaysTimesSame(hashForDate, latestIndexOfArrayToPrint.hashForDate, hoursForStore)
      ) {
        arrayToPrint.push({ hashForDate, readableDate, dayInUseableFormat, hasRepeatTimesOnDay: false });
      } else {
        //  Times are the same we should print them like DAY - DAY:
        latestIndexOfArrayToPrint.readableDate = `${latestIndexOfArrayToPrint.dayInUseableFormat.format(
          'dddd'
        )} - ${readableDate}`;
        latestIndexOfArrayToPrint.lastMomentObjectInSameDateRange = dayInUseableFormat;
        latestIndexOfArrayToPrint.hasRepeatTimesOnDay = true;
      }
      //  If the day we are looking at is before the present day we disregard it
      if (
        utils.gmi.getDateTimeObjFromTs(hashForDate).isBefore(utils.gmi.getCurrentDateTimeObj().format('YYYY-MM-DD'))
      ) {
        arrayToPrint.pop();
      }

      //  We stop one day before the start of the week (defined by ehi object)
      //  This way the arrayToPrint, which holds the times to print out stops right before
      //  the start of the week.  That way every time we go forward or backward we always start with the
      //  start of the week AND don't have two 'starts of the week' in one time table.
      //  EX: Mon,Tues,Wed, Thur, Fri, Sat,Sun, Mon <= Mon being the start of the week
      if (dayInUseableFormat.day() === startOfWeek - 1) {
        //  We have hit the start of the week
        break;
      }
    }

    //  Can the user go forward or backwards
    const canGoForward = utils.date.validWeekDateRange(
      utils.gmi.getDateTimeObjFromTs(arrayToPrint[0].dayInUseableFormat).add(1, 'week'),
      hoursForStore
    );
    const canGoBackwards = utils.date.validWeekDateRange(
      utils.gmi.getDateTimeObjFromTs(arrayToPrint[0].dayInUseableFormat).subtract(1, 'week'),
      hoursForStore
    );

    const head = utils.gmi.getDateTimeObjFromTs(arrayToPrint[0].dayInUseableFormat).format('LL');
    const latestArryToPrintIndex = arrayToPrint[arrayToPrint.length - 1];
    let tail = '';
    if (!latestArryToPrintIndex.hasRepeatTimesOnDay) {
      tail = latestArryToPrintIndex.dayInUseableFormat.format('LL');
    } else {
      tail = utils.gmi.getDateTimeObjFromTs(latestArryToPrintIndex.lastMomentObjectInSameDateRange).format('LL');
    }

    this.setState({
      isLocationDataLoaded: true,
      currentHours: arrayToPrint,
      readableDateRange: `${head} - ${tail}`,
      canGoForward,
      canGoBackwards,
    });
  };

  correctLeadDay = (oldLeadDay) => {
    let leadDay = oldLeadDay;
    //  Keep adding days until we hit the 'start of the week', defined in the ehi object
    while (
      utils.gmi.getDateTimeObjFromTs(leadDay).day().toString() !== startOfWeek ||
      utils.gmi.getDateTimeObjFromTs(leadDay).isBefore(utils.gmi.getCurrentDateTimeObj())
    ) {
      leadDay = utils.gmi.getDateTimeObjFromTs(leadDay).add(1, 'days');
    }

    return utils.gmi.getDateTimeObjFromTs(leadDay);
  };

  handleForwardMonth = () => {
    const { leadDay } = this.state;
    const leadDayDayOfWeek = utils.gmi.getDateTimeObjFromTs(leadDay).day();
    let dayInUseableFormat = null;
    // If we are already at the correct start of the week just add seven days
    // other wise keep adding days until we get to the start day
    if (leadDayDayOfWeek.toString() === startOfWeek) {
      dayInUseableFormat = utils.gmi.getDateTimeObjFromTs(leadDay).add(1, 'week');
    } else {
      dayInUseableFormat = this.correctLeadDay(leadDay);
    }

    this.setState(
      {
        leadDay: utils.gmi.getDateTimeObjFromTs(dayInUseableFormat).format('YYYY-MM-DD'),
      },
      () => {
        this.setUpTimeTable();
      }
    );
  };

  formatToReadableTime = (time) => utils.gmi.getDateTimeObjFromTs(time, 'HH:mm').format('LT');

  handleBackMonth = () => {
    const { leadDay } = this.state;
    const dayInUseableFormat = utils.gmi.getDateTimeObjFromTs(leadDay).subtract(1, 'week');

    this.setState(
      {
        leadDay: utils.gmi.getDateTimeObjFromTs(dayInUseableFormat).format('YYYY-MM-DD'),
      },
      () => {
        this.setUpTimeTable();
      }
    );
  };

  formatHours = (hoursObject, className = '') => {
    if (!hoursObject) {
      return null;
    }
    if (hoursObject.hours.length === 0) {
      const { open24Hours, closed } = hoursObject;
      if (closed) {
        return <p className={className}>{utils.i18n('location_finder_timetable_closed')}</p>;
      }
      if (open24Hours) {
        return <p className={className}>{utils.i18n('location_finder_timetable_open_24_hours')}</p>;
      }
      return null;
    }
    return hoursObject.hours.map(({ open, close }, index) => (
      <p key={index} className={className}>
        {`${this.formatToReadableTime(open)} ${utils.i18n('map_locations_between_time')}`}{' '}
        <span className='map-location-detail-tile-expanded-state__time-close'>{this.formatToReadableTime(close)}</span>
      </p>
    ));
  };

  //  function for printing the hours out
  printHours = () => {
    const { hoursForStore } = this.props;
    const { currentHours } = this.state;
    return (
      currentHours &&
      currentHours.map(
        ({ hashForDate, readableDate }, idx) =>
          hoursForStore && hoursForStore[hashForDate] && (
            <div key={`${idx}--${hashForDate}`} className='map-location-detail-tile-expanded-state__time-container'>
              <p className='map-location-detail-tile-expanded-state__date'>{readableDate}</p>
              <div className='map-location-detail-tile-expanded-state__time-hours'>
                {this.formatHours(hoursForStore[hashForDate][CURSORS.STANDARD])}
              </div>
            </div>
          )
      )
    );
  };

  render() {
    const {
      isLocationDataLoaded,
      currentDayHash,
      currentDayReadable,
      readableDateRange,
      canGoForward,
      canGoBackwards,
    } = this.state;
    const { hoursForStore, hideTodaysTimes } = this.props;

    return (
      isLocationDataLoaded && (
        <>
          <div
            className={cn('map-location-detail-tile-expanded-state__time-header', {
              stack:
                hoursForStore &&
                !hideTodaysTimes &&
                hoursForStore[currentDayHash] &&
                hoursForStore[currentDayHash][CURSORS.STANDARD]?.hours?.length > 1,
            })}
          >
            {!hideTodaysTimes && hoursForStore && hoursForStore[currentDayHash] && (
              <>
                <p className='map-location-detail-tile-expanded-state__current-date'>{currentDayReadable}</p>
                <div>
                  {this.formatHours(
                    hoursForStore[currentDayHash][CURSORS.STANDARD],
                    'map-location-detail-tile-expanded-state__current-time'
                  )}
                </div>
              </>
            )}
          </div>

          <div
            className={cn('map-location-detail-tile-expanded-state__location-hours-container', {
              'map-location-detail-tile-expanded-state__location-hours-container--on-branch-page': hideTodaysTimes,
            })}
          >
            <p
              className={cn('map-location-detail-tile-expanded-state__current-week-hours', {
                'map-location-detail-tile-expanded-state__hours-header-text-branch-page': hideTodaysTimes,
              })}
            >{`${utils.i18n('map_locations_tiles_expanded_state_prefix_current_week')} ${readableDateRange}`}</p>
            {this.printHours()}
            <div
              className={cn({
                'map-location-detail-tile-expanded-state__time-table-time-group': canGoBackwards && canGoForward,
                'map-location-detail-tile-expanded-state__time-table-time-group--forward':
                  !canGoBackwards && canGoForward,
                'map-location-detail-tile-expanded-state__time-table-time-group--backward':
                  canGoBackwards && !canGoForward,
              })}
            >
              {canGoBackwards && (
                <Button
                  plain
                  data-dtm-track={utils.analytics.dtm(
                    ANALYTICS.UI_BUTTON,
                    ANALYTICS.HOURS_OF_OPERATION,
                    ANALYTICS.PREVIOUS
                  )}
                  onClick={this.handleBackMonth}
                  aria-label={utils.i18n('map_locations_tiles_expanded_state_previous_week_hours')}
                  className={cn('link map-location-detail-tile-expanded-state__cycle-week-forward', {
                    'map-location-detail-tile-expanded-state__location-details': hideTodaysTimes,
                  })}
                >
                  {utils.i18n('map_locations_tiles_expanded_state_previous_week_hours')}
                </Button>
              )}
              {canGoForward && (
                <Button
                  plain
                  data-dtm-track={utils.analytics.dtm(
                    ANALYTICS.UI_BUTTON,
                    ANALYTICS.HOURS_OF_OPERATION,
                    ANALYTICS.NEXT
                  )}
                  onClick={this.handleForwardMonth}
                  aria-label={utils.i18n('map_locations_tiles_expanded_state_future_week_hours')}
                  className={cn('link map-location-detail-tile-expanded-state__cycle-week-backwards', {
                    'map-location-detail-tile-expanded-state__location-details': hideTodaysTimes,
                  })}
                >
                  {utils.i18n('map_locations_tiles_expanded_state_future_week_hours')}
                </Button>
              )}
            </div>
          </div>
        </>
      )
    );
  }
}

BranchHours.propTypes = {
  hideTodaysTimes: PropTypes.bool,
  getBranchHours: PropTypes.func,
  reservationFlow: PropTypes.bool,
  showReturnLocation: PropTypes.bool,
  pickUpTime: PropTypes.string,
  dropOffTime: PropTypes.string,
};

export default BranchHours;
