import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import {
  selectedExtras,
  selectedBundleWithExtrasSelector,
  extrasWithoutBundlesSelector,
  numberOfExtrasSelector,
} from 'selectors/extrasSelectors';
import { mileageInfoSelector } from 'selectors/reservationSelectors';
import { priceSummarySelector, reservationCouponsSelector } from 'selectors/reservationFlowSelectors';
import Modal from 'components/Modal';
import { TaxesFees, Extras, TotalPrice, Mileage } from 'components/RentalDetails';
import BundleAddedCost from 'components/RentalDetails/RentalDetailsSharedComponents/BundleAddedCost';
import TimeAndDistance from 'components/RentalDetails/RentalDetailsSharedComponents/TimeAndDistance';
import AdditionalDrivers from 'components/RentalDetails/AdditionalDrivers';

/**
 * PriceBreakdownModal - Modal shown to the user trying to start Skip The Counter
 * with a Reservation that OCI is not activated, giving the option to start check-in flow.
 * @return {JSX}
 */
const PriceBreakdownModal = ({ showLearnMoreModal }) => {
  const mileageInfo = useSelector(mileageInfoSelector);
  const priceSummary = useSelector(priceSummarySelector);
  const extras = useSelector(selectedExtras);
  const selectedBundleWithExtras = useSelector(selectedBundleWithExtrasSelector);
  const extrasWithoutBundles = useSelector(extrasWithoutBundlesSelector);
  const numberOfExtrasSelected = useSelector(numberOfExtrasSelector);
  const coupons = useSelector(reservationCouponsSelector);
  const [taxesDrawerExpanded, setTaxesDrawerExpanded] = useState(false);

  const toggleTaxesDrawerExpanded = () => {
    setTaxesDrawerExpanded(!taxesDrawerExpanded);
  };

  const handleViewCurrencyConversionModal = handleClose => {
    handleClose();
    showLearnMoreModal();
  };

  const {
    estimated_total_payment,
    estimated_total_view,
    estimated_total_vehicle_view,
    estimated_total_vehicle_payment,
    payment_line_items,
  } = priceSummary;
  const chargedRate = payment_line_items?.VEHICLE_RATE || [];
  const freeUpgradeApplied = utils.isFreeUpgradeAvailable(coupons);

  return (
    <Modal
      modalKey={MODAL.PRICE_BREAKDOWN_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('price_breakdown_title')}
      customClass='price-breakdown-modal'>
      {({ handleClose }) => (
        <>
          <Mileage mileageInfo={mileageInfo} theme='component-theme--light-blue' />

          <h4 className='price-breakdown-modal__title'>{utils.i18n('total_progress_bar_rental_rate_title')}</h4>
          <TimeAndDistance
            chargedRate={chargedRate}
            estimatedTotalVehicleView={estimated_total_vehicle_view}
            estimatedTotalVehiclePayment={estimated_total_vehicle_payment}
            asterisk
          />
          <BundleAddedCost selectedBundleWithExtras={selectedBundleWithExtras} />
          <AdditionalDrivers />
          <Extras
            numberOfExtrasSelected={numberOfExtrasSelected}
            extras={extras}
            asterisk
            selectedBundleWithExtras={selectedBundleWithExtras}
            extrasWithoutBundles={extrasWithoutBundles}
          />
          <TaxesFees
            asterisk
            isReview
            handleToggleTaxesDrawer={toggleTaxesDrawerExpanded}
            taxesDrawerExpanded={taxesDrawerExpanded}
            priceSummary={priceSummary}
          />
          <TotalPrice
            isReview
            asterisk
            estimatedTotalView={estimated_total_view}
            estimatedTotalPayment={estimated_total_payment}
            freeUpgradeApplied={freeUpgradeApplied}
            isLight
            doubleAsteriskTotalView={true}
            showLearnMoreModal={() => handleViewCurrencyConversionModal(handleClose)}
          />
        </>
      )}
    </Modal>
  );
};

export default PriceBreakdownModal;
