import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';

export const propTypes = {
  /**
   * Tile index
   *
   * @type {Number}
   */
  index: PropTypes.number,

  /**
   * Tile name text
   *
   * @type {String}
   */
  name: PropTypes.string.isRequired,

  /**
   * Tile disabled boolean
   *
   * @type {Boolean}
   */
  disabled: PropTypes.bool,

  /**
   * Tile expanded state boolean
   *
   * @type {Boolean}
   */
  expanded: PropTypes.bool,

  /**
   * Tile toggle callback function
   *
   * @type {Function}
   */
  onToggle: PropTypes.func,
};

export const defaultProps = setDefaultProps();
