import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import TOOLTIP_THEMES from 'constants/tooltipThemes';
import { estimatedTotalViewSelector } from 'selectors/reservationFlowSelectors';
import {
  inflightPaymentSelector,
  isTourVoucherReservationSelector,
  voucherExcludedSelector,
} from 'selectors/reservationSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import ToolTip from 'components/ToolTip';
import STCAddPaymentCardFooter from './STCAddPaymentCardFooter';
import STCPaymentCardInfo from './STCPaymentCardInfo';

/**
 * STCPaymentInformationPayLater
 *
 * @param {object} props - React Props
 *
 * @return {JSX}
 */
const STCPaymentInformationPayLater = () => {
  const isTourVoucherReservation = useSelector(isTourVoucherReservationSelector);
  const voucherExcludedValue = useSelector(voucherExcludedSelector);
  const paymentDetails = useSelector(inflightPaymentSelector);
  const estimatedTotalView = useSelector(estimatedTotalViewSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const zeroChargeValue = utils.getFormattedZeroPrice(estimatedTotalView);
  return (
    <>
      {paymentDetails && <STCPaymentCardInfo isRemovable />}

      <dl className='skip-the-counter-flow-payment-list'>
        <dt className='skip-the-counter-flow-payment-list__item-title'>
          {isCustomPathReservation
            ? `${utils.i18n('stc_payment_info_trip_total_custom_path')}:`
            : `${utils.i18n('stc_payment_information_trip_total')}:`}
          <span className='skip-the-counter-flow-payment-list__item-detail'>
            {!isCustomPathReservation && `(${utils.i18n('stc_payment_information_due_time_of_rental')})`}
            {!isCustomPathReservation && (
              <ToolTip theme={TOOLTIP_THEMES.REGULAR_SLATE}>
                <label>{utils.i18n('stc_payment_information_due_time_of_rental_tooltip')}</label>
              </ToolTip>
            )}
          </span>
        </dt>
        <dd className='skip-the-counter-flow-payment-list__item-data skip-the-counter-flow-payment-list__price-details'>
          {isTourVoucherReservation
            ? utils.getFormattedPrice(voucherExcludedValue.view)
            : utils.getFormattedPrice(estimatedTotalView)}
        </dd>

        <dt className='skip-the-counter-flow-payment-list__item-title skip-the-counter-flow-payment-list__item-title--bold skip-the-counter-flow-payment-list__item-title--row-divider'>
          {utils.i18n('stc_payment_information_charged_today')}:
        </dt>
        <dd className='skip-the-counter-flow-payment-list__item-data skip-the-counter-flow-payment-list__item-data--bold skip-the-counter-flow-payment-list__item-data--row-divider'>
          {zeroChargeValue}
        </dd>
      </dl>

      {!paymentDetails && <STCAddPaymentCardFooter />}
    </>
  );
};

export default STCPaymentInformationPayLater;
