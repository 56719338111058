import React from 'react';
import { THEMES } from 'constants';
import Image from 'components/Image';
import { propTypes } from './MagicTilePropTypes';

const MagicTile = ({ ctaLink: { url, label }, description, fileReference, imageAlt }) => (
  <div className={`location-tile magic-tile component-theme--${THEMES.LIGHT_BLUE}`}>
    <div className='magic-title__content'>
      <Image fileReference={fileReference} alt={imageAlt} />
      <p className='magic-tile__description'>{description}</p>
    </div>
    <a href={url} className='magic-tile__link link link--arrow'>
      {label}
    </a>
  </div>
);

MagicTile.propTypes = propTypes;

export default MagicTile;
