import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import utils from 'utils';

/**
 * GuaranteedReservationModal Description
 *
 * @param {object} props - React Props
 * @param {object} props.guaranteedResPolicy - object created by guaranteedResPolicySelector that contains any data components may need from this policy in the session res object
 * @param {string} props.guaranteedResPolicy.text - the gbo policy html
 * @return {JSX}
 */

const GuaranteedReservationModal = ({ guaranteedResPolicy }) => (
  <Modal
    modalKey={MODAL.GUARANTEED_RES}
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('guaranteed_reservation_modal_header')}>
    <>
      {guaranteedResPolicy ? (
        <div
          id={`${MODAL.GUARANTEED_RES}_desc`}
          className='modal-themed__gbo-content'
          dangerouslySetInnerHTML={utils.sanitize(guaranteedResPolicy.text)}
        />
      ) : (
        <p id={`${MODAL.GUARANTEED_RES}_desc`} className='modal-themed__section'>
          {utils.i18n('guaranteed_reservation_modal_key_fallback')}
        </p>
      )}

      <ModalFooter
        buttons={[
          {
            type: MODAL_BUTTON_TYPES.ACCEPT,
            label: utils.i18n('common_close'),
          },
        ]}
      />
    </>
  </Modal>
);

GuaranteedReservationModal.propTypes = {
  guaranteedResPolicy: PropTypes.shape({
    text: PropTypes.string,
  }),
};
export default GuaranteedReservationModal;
