import PropTypes from 'prop-types';

export default ({/**
   * Array of options under main title, has link + text
   * {name, url}
   * @type {Array}
   */
  locations: PropTypes.array.isRequired,
  /**
   * Array of options under sub title, has text
   *
   * @type {Array}
   */
  otherLocations: PropTypes.array,});
