import PropTypes from 'prop-types';

export default ({/**
   * Country selector options
   *
   * @type {Array}
   */
  countries: PropTypes.array.isRequired,

  /**
   * Currently selected country
   *
   * @type {String}
   */
  currentPageTitle: PropTypes.string.isRequired,});
