import PropTypes from 'prop-types';

export const propTypes = {
  item: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const defaultProps = {
  isExpanded: false,
};
