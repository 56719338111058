import React, { useRef, forwardRef } from 'react';
import utils from 'utils';
import useClickOutside from 'hooks/useClickOutside';
import Button from 'components/Button/Button';

/**
 * CountryList Description:
 *
 * @param {object}          props
 * @param {state | boolean} props.showList
 * @param {state | boolean} props.setShowList
 * @param {Array}           props.selectedCountry
 * @param {Array}           props.countries
 * @param {function}        props.handleSelectCountry
 * @return {JSX} ...
 *
 */

const CountryList = forwardRef(({ showList, setShowList, countries, selectedCountry, handleSelectCountry }) => {
  const flagsDropdown = useRef();

  const handleClickOutside = () => {
    setShowList(false);
  };

  useClickOutside(
    {
      container: flagsDropdown,
      handler: handleClickOutside,
      listenerConditional: showList,
    },
    [showList]
  );

  const gcsIsoFlagsPath = utils.config.getGcsIsoFlagsPath();
  const getFlagUrl = (countryCode) => `${gcsIsoFlagsPath}${countryCode}.svg`;

  return (
    <div className='phone-number-field__country-box'>
      <Button link className='phone-number-field__btn' onClick={() => setShowList(!showList)}>
        {selectedCountry && (
          <img className='phone-number-field__flag' src={getFlagUrl(selectedCountry?.country_code)} />
        )}
      </Button>

      <ul className={`phone-number-field__list ${showList ? 'open' : ''}`} ref={flagsDropdown}>
        {countries?.map((country) => (
          <li
            className='phone-number-field__list-item'
            onClick={() => handleSelectCountry(country)}
            key={country?.country_code}
          >
            <img className='phone-number-field__flag' src={getFlagUrl(country?.country_code)} />
            <span className='phone-number-field__dialing-code'>{country?.country_content?.dialing_code}</span>
            <span className='phone-number-field__country-name'>{country?.country_name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
});

export default CountryList;
