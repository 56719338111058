import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import RESERVATIONS from 'constants/reservations';
import TripCard from 'components/TripCard';
import LearnMoreAboutSkipTheCounterModal from 'components/modals/LearnMoreAboutSkipTheCounterModal/LearnMoreAboutSkipTheCounterModal';
import LearnMoreAboutSkipTheCounterEligibleModal from 'components/modals/LearnMoreAboutSkipTheCounterEligibleModal';
import LearnMoreAboutSkipTheCounterIneligibleModal from 'components/modals/LearnMoreAboutSkipTheCounterIneligibleModal';
import LearnMoreAboutOCIInactiveModal from 'components/modals/LearnMoreAboutOCIInactiveModal';
import WayfindingsModal from 'components/modals/WayfindingsModal';
import STCIneligibleModal from 'components/modals/STCIneligibleModal';
import RentalNotEligibleForModificationsModal from 'components/modals/RentalNotEligibleForModificationsModal';
import UpcomingTripsEmpty from './UpcomingTripsEmpty';

/**
 * UpcomingTrips Description
 * will display the currently active trip, the next upcoming trip, or a 0 upcoming trips notification
 * @param {object} props - React Props
 * @param {Function} props.getUpcomingTrips - function responsible to load upcoming trips data
 * @param {Function} props.retrieveReservation - redirect to trip details
 * @param {array} props.upcomingTrips - array of data representing future trips
 * @param {boolean} props.loadingOverlay - in case of true, it will show whole page loader
 * @param {object} props.profileData.first_name - First name of authenticated user
 * @param {object} props.profileData.last_name - Last name of authenticated user
 * @param {boolean} props.showProfileEmptyState - in case of true, it will show profile empty state UI
 * @param {function} props.openMissingTripsModal - opens MISSING_TRIPS_MODAL
 * @return {JSX}
 */

const UpcomingTrips = ({
  getUpcomingTrips,
  retrieveReservation,
  upcomingTrips,
  loadingOverlay,
  profileData = {},
  showProfileEmptyState,
  openMissingTripsModal,
}) => {
  useEffect(() => {
    getUpcomingTrips(loadingOverlay);
  }, []);

  return (
    <div className='upcoming-trips row'>
      {upcomingTrips.length === 0 ? (
        <UpcomingTripsEmpty
          showProfileEmptyState={showProfileEmptyState}
          openMissingTripsModal={openMissingTripsModal}
        />
      ) : (
        <>
          {upcomingTrips.map((trip, index) => {
            const userAlreadyCheckedIn = trip.reservation_sub_status === RESERVATIONS.RESERVATION_SUB_STATUS.CHECKED_IN;
            const skipTheCounterCompleted =
              trip.reservation_sub_status === RESERVATIONS.RESERVATION_SUB_STATUS.COUNTER_BYPASS;
            return (
              <TripCard
                key={`trip_card_${index}`}
                icon={userAlreadyCheckedIn ? 'calendar-checkmark' : 'calendar-star'}
                location={trip.pickup_location.name}
                locationWayfindings={trip?.pickup_location.wayfindings}
                firstName={profileData?.first_name}
                lastName={profileData?.last_name}
                confirmation_number={trip?.confirmation_number}
                agreement_number={trip?.ticket_number || trip?.agreement_number}
                carClassImage={utils.getImageFromImagesObject(trip.vehicle_details.images, { type: '0', width: 295 })}
                carClassImageAlt={trip.vehicle_details.make_model_or_similar_text}
                dateOfRentInteraction={utils.gmi
                  .getDateTimeObjFromTs(trip.pickup_time)
                  .format(utils.config.getDateTimeLongFormat('llll_with_comma'))}
                remainingDaysCopy={utils.date.formatRemainingDays(trip.remaining_days_for_pick_up, 'pickup')}
                ctaLabel={utils.i18n('trip_card_view_modify_cancel_cta')}
                locationDetailsID={trip.pickup_location.id}
                userAlreadyCheckedIn={userAlreadyCheckedIn}
                isPickupStatusAddedAutomatically={trip.auto_check_in}
                skipTheCounterCompleted={skipTheCounterCompleted}
                userCanActiveSkipTheCounter={trip.counter_bypass_eligible}
                ctaAction={() =>
                  retrieveReservation({
                    firstName: profileData?.first_name,
                    lastName: profileData?.last_name,
                    confirmationNumber: trip.confirmation_number,
                  })
                }
                within24hrsReturnTime={trip.within24_hrs_of_return_time}
                ticketNumber={trip?.ticket_number}
                rentalReturnConfirmed={
                  Object.hasOwn(trip, 'rental_return_confirmed') ? trip.rental_return_confirmed : trip?.return_confirmed
                }
                hasConfirmModify={trip?.confirm_modify}
              />
            );
          })}
          <LearnMoreAboutSkipTheCounterModal />
          <LearnMoreAboutSkipTheCounterEligibleModal />
          <LearnMoreAboutSkipTheCounterIneligibleModal />
          <LearnMoreAboutOCIInactiveModal />
          <WayfindingsModal />
          <STCIneligibleModal />
          <RentalNotEligibleForModificationsModal />
        </>
      )}
    </div>
  );
};

UpcomingTrips.propTypes = {
  upcomingTrips: PropTypes.array,
  getUpcomingTrips: PropTypes.func,
  retrieveReservation: PropTypes.func,
  loadingOverlay: PropTypes.bool,
  showProfileEmptyState: PropTypes.bool,
  profileData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  openMissingTripsModal: PropTypes.func,
};

export default UpcomingTrips;
