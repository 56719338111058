import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import cn from 'classnames';
import Button from 'components/Button';

const FlyoutTabDrawer = ({ uuid, contentKey, isExpanded, item, hasClearBelow, hasLink, onClickLink, linkText }) => {
  const { disabled } = item;
  const buttonId = `${uuid}_button`;
  const drawerId = `${uuid}_drawer`;

  return (
    <div
      className={cn('flyout-tab__drawer', { expanded: isExpanded, 'flyout-tab__drawer--clear-below': hasClearBelow })}
      id={drawerId}
      aria-labelledby={buttonId}
      role='tabpanel'
      aria-hidden={!isExpanded || disabled}>
      <div
        className='flyout-tab__content component-theme--light'
        dangerouslySetInnerHTML={utils.sanitize(item[contentKey])}
      />
      {hasLink && (
        <Button linkText className='flyout-tab__link' onClick={onClickLink} button={false}>
          {linkText}
        </Button>
      )}
    </div>
  );
};

FlyoutTabDrawer.propTypes = {
  uuid: PropTypes.string,
  item: PropTypes.object,
  contentKey: PropTypes.string.isRequired,
  hasClearBelow: PropTypes.bool,
  isExpanded: PropTypes.bool,
  hasLink: PropTypes.bool,
  onClickLink: PropTypes.func,
  linkText: PropTypes.string,
};

export default FlyoutTabDrawer;
