import PropTypes from 'prop-types';

const commonProps = {
  isActive: PropTypes.bool,
  titleId: PropTypes.string,
  contentId: PropTypes.string,
  isVertical: PropTypes.bool,
};

const commonDefaultProps = {
  isActive: false,
  titleId: '',
  contentId: '',
  isVertical: false,
};

export const accordionTitlePropTypes = {
  ...commonProps,
  title: PropTypes.string,
  analyticsTitle: PropTypes.string,
  index: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

export const accordionTitleDefaultProps = {
  ...commonDefaultProps,
  title: '',
  index: 0,
  setActiveIndex: () => {},
};

export const accordionContentPropTypes = {
  ...commonProps,
  analyticsTitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export const accordionContentDefaultProps = commonDefaultProps;

export const accordionListPropTypes = {
  activeIndex: PropTypes.number,
  isCollapsedInVertical: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export const accordionListDefaultProps = {
  activeIndex: 0,
  isCollapsedInVertical: true,
  children: null,
};
