import React, { Component } from 'react';
import utils from 'utils';
import { URL, ASSETS } from 'constants';
import Picture from 'components/Picture';
import { propTypes } from './branchLocationMapViewPropTypes';

class BranchLocationMapView extends Component {
  static propTypes = propTypes;

  handleClick = () => {
    const { address } = this.props;
    const googleMapsLink = utils.locations.getGoogleMapsURL(address);
    window.open(googleMapsLink, '_blank');
  };

  urlObject = location_type => {
    const PROTOCOL = `${window.location.protocol}//`;
    const HOST = window.location.host;
    const mapPinHashTable = {
      AIRPORT: `${PROTOCOL}${HOST}${ASSETS.GOOGLE_MAP_AIRPORT}`,
      CITY: `${PROTOCOL}${HOST}${ASSETS.GOOGLE_MAP_BRANCH}`,
      RAILROAD: `${PROTOCOL}${HOST}${ASSETS.GOOGLE_MAP_RAIL}`,
    };
    return mapPinHashTable[location_type] || mapPinHashTable.BRANCH;
  };

  render() {
    const googleMapsApiKey = utils.config.getGoogleMapsAPIKey();
    const { lat, long, locationType } = this.props;
    return (
      <Picture
        pictureClassName='branch-location-detail__picture'
        onClick={this.handleClick}
        srcMobile={URL.GOOGLE_MAPS_STATIC_CALL(googleMapsApiKey, this.urlObject(locationType), lat, long, '500', '400')}
        srcTablet={URL.GOOGLE_MAPS_STATIC_CALL(googleMapsApiKey, this.urlObject(locationType), lat, long, '400', '250')}
        src={URL.GOOGLE_MAPS_STATIC_CALL(googleMapsApiKey, this.urlObject(locationType), lat, long, '500', '300')}
      />
    );
  }
}

export default BranchLocationMapView;
