import { connect } from 'react-redux';
import utils from 'utils';
import { profileDataSelector } from 'selectors/profileSelectors';
import EnrollmentConfirmationHeader from './EnrollmentConfirmationHeader';

const mapStateToProps = state => ({
  profileData: profileDataSelector(state, { first_name: true, email: true, memberNumber: true }),
});

export default connect(
  mapStateToProps,
  null
)(utils.toJS(EnrollmentConfirmationHeader));
