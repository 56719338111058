import React from 'react';
import cn from 'classnames';
import FieldControl from 'components/Form/FieldControl';
import FieldState from 'components/Form/FieldsState';
import { propTypes } from './radioGroupPropTypes';

const RadioGroup = ({ name, buttons, onChange, errorMessage, theme, isRequired = true }) => (
  <FieldState names={[name]}>
    {(fieldsState) => {
      const { error, submitFailed } = fieldsState[name].meta;
      const showError = error && submitFailed;

      return (
        <div className={cn('field-control__radio-container', { 'radio-group-error': showError })}>
          {showError && <span className='field-control__error-message'>{errorMessage}</span>}

          {buttons.map(({ value, label, description, type = 'radio' }) => (
            <FieldControl
              key={`${name}--${value}`}
              name={name}
              type={type}
              value={value}
              label={label}
              description={description}
              id={`${name}--${value}`}
              onChange={onChange}
              required={isRequired}
              asterisk={false}
              dontShowError
              theme={theme}
            >
              <input />
            </FieldControl>
          ))}
        </div>
      );
    }}
  </FieldState>
);

RadioGroup.propTypes = propTypes;

export default RadioGroup;
