import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import THEMES from 'constants/themes';
import ASSETS from 'constants/assets';
import { breakpointSelector } from 'selectors/breakpoint';
import PostResLayout from 'components/PostRes/PostResLayout';
import PostResRentalInfoLine from 'components/PostRes/PostResRentalInfoLine';
import SkipTheCounterBridgeInfo from 'components/AcceleratedCheckInFlow/CheckInSkipTheCounterBridge/SkipTheCounterBridgeInfo';
import Picture from 'components/Picture';

const AdditionalDriverSTCBridge = () => {
  const { isDesktop } = useSelector(breakpointSelector);
  return (
    <PostResLayout theme={THEMES.LIGHT_BLUE}>
      <section className='skip-the-counter-bridge row'>
        <section className='skip-the-counter-bridge__column'>
          <h1 className='skip-the-counter-bridge__heading'>{utils.i18n('additional_driver_success_page_title')}</h1>
          <PostResRentalInfoLine />
          <article className='skip-the-counter-bridge__content'>
            <h3 className='skip-the-counter-bridge__thank-you-header'>
              {utils.i18n('additional_driver_success_thank_you_header')}
            </h3>
            <p className='skip-the-counter-bridge__thank-you-copy'>
              {utils.i18n('additional_driver_success_thank_you_copy')}
            </p>
          </article>
          <SkipTheCounterBridgeInfo />
        </section>
        {isDesktop && (
          <aside className='skip-the-counter-bridge__aside'>
            <Picture
              src={ASSETS.ADDITIONAL_DRIVER_CONFETTI}
              className={'additional-driver-success__illustration'}
              isDecorative
            />
          </aside>
        )}
      </section>
    </PostResLayout>
  );
};

export default AdditionalDriverSTCBridge;
