import PropTypes from 'prop-types';

export const propTypes = {
  // The URL for the cta
  url: PropTypes.string,
  // The text for CTA
  label: PropTypes.string,
  // main text
  description: PropTypes.string,
  //  src text for image
  fileReference: PropTypes.string,
  //  alt text
  imageAlt: PropTypes.string,
};
