import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import { isUnauthUserWithLoyaltyProfileSelector } from 'selectors/checkinSelectors';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import ProfileForm from 'components/ReservationFlow/InResAcceleratedCheckin/ProfileForm';
import EditOciProfileForm from 'components/ReservationFlow/InResAcceleratedCheckin/EditOciProfileForm';

const InResAcceleratedCheckinEditModal = () => {
  const isUnauthUserWithLoyaltyProfile = useSelector(isUnauthUserWithLoyaltyProfileSelector);

  const renderFooter = formSubmit => {
    const buttons = [
      {
        type: MODAL_BUTTON_TYPES.DECLINE,
        label: utils.i18n('common_cancel'),
        analyticsValue: ANALYTICS.CANCEL,
      },
      {
        type: MODAL_BUTTON_TYPES.ACCEPT,
        label: utils.i18n('common_save'),
        handler: formSubmit,
        analyticsHandler: () =>
          utils.analytics.interaction(
            ANALYTICS.MODAL,
            utils.analytics.prepareKey(MODAL.IN_RES_OCI_EDIT_PROFILE_MODAL),
            ANALYTICS.SAVE
          ),
      },
    ];

    return <ModalFooter buttons={buttons} disableStickyFooter />;
  };

  return (
    <Modal
      modalKey={MODAL.IN_RES_OCI_EDIT_PROFILE_MODAL}
      header={utils.i18n('in_res_oci_edit_profile')}
      customClass='in-res-oci-modal'
      theme={MODAL_THEMES.WHITE}>
      {({ scrollToModalTop, handleClose }) =>
        isUnauthUserWithLoyaltyProfile ? (
          <ProfileForm onSubmitSuccess={handleClose} scrollToModalTop={scrollToModalTop} renderFooter={renderFooter} />
        ) : (
          <EditOciProfileForm
            onSubmitSuccess={handleClose}
            onSubmitFail={scrollToModalTop}
            renderFooter={renderFooter}
          />
        )
      }
    </Modal>
  );
};

export default InResAcceleratedCheckinEditModal;
