import React from 'react';
import utils from 'utils';
import { utils as globalUtils } from '@ehi/global-marketing-interface';
import useActions from 'hooks/useActions';
import { setDiscountCodeExpanded } from 'actions/bookingWidget';
import Button from 'components/Button';
import { ANALYTICS, RESERVATIONS } from 'constants';

const CouponContract = ({
  handleEdit,
  stepTwo,
  hideEditButtons,
  isTabletOrMobile,
  reservationContractAndCoupons,
  contractName,
  history,
  isRental,
}) => {
  const dispatchDiscountCodesExpandedTab = useActions(setDiscountCodeExpanded);
  const CouponAndContractAddorEditTextKey = globalUtils.isArrayNotEmpty(reservationContractAndCoupons)
    ? utils.i18n('rental_details_edit')
    : utils.i18n('rental_details_add');
  const CouponAndContractTitle = utils.i18n('rental_details_coupon_contract');

  const handleEditClick = (sectionPath) => {
    const path = RESERVATIONS?.RESFLOW_PATHS_CONFIG?.[sectionPath][0];
    if (path) {
      history.push(path);
    }
    utils.analytics.interaction(ANALYTICS.REVIEW, 'coupons contracts', CouponAndContractAddorEditTextKey.toLowerCase());
    dispatchDiscountCodesExpandedTab(true);
  };

  return (
    <div className='rental-details__section'>
      <p className='rental-details__section-label'>
        <span>{CouponAndContractTitle}</span>
        {handleEdit && !stepTwo && (!hideEditButtons || isTabletOrMobile) && (
          <Button
            link
            onClick={() => handleEditClick('start')}
            ariaText={utils.i18n('rental_details_edit_aria_label', [CouponAndContractTitle])}
          >
            {CouponAndContractAddorEditTextKey}
          </Button>
        )}
      </p>
      {globalUtils.isArrayNotEmpty(reservationContractAndCoupons) &&
        reservationContractAndCoupons.map((coupon, index) => (
          <p key={index} className='rental-details__coupon-contract-label'>
            {coupon.description}
          </p>
        ))}
      {!isRental && !globalUtils.isArrayNotEmpty(reservationContractAndCoupons) && (
        <p className='rental-details__coupon-contract-empty'>
          {utils.i18n('rental_details_coupon_contract_missing_discount')}
        </p>
      )}

      {contractName && <p className='rental-details__coupon-contract-empty'>{contractName}</p>}
    </div>
  );
};

export default CouponContract;
