import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import utils from 'utils';
import { ANALYTICS, RESERVATIONS } from 'constants';
import FormattedPrice from 'components/FormattedPrice';
import Button from 'components/Button';
import ProgressBarDrawerWrapper from '../ProgressBarDrawerWrapper';
import { propTypes } from './progressBarExtrasDrawerPropTypes';

class ProgressBarExtrasDrawer extends Component {
  static propTypes = propTypes;

  render() {
    const {
      includedExtrasAndDrivers,
      addedExtras,
      selectedBundle,
      addedExtrasAmount,
      bundledAdditionalDrivers,
    } = this.props;
    const extrasPath = RESERVATIONS?.RESFLOW_PATHS_CONFIG?.extras[0];

    return (
      <ProgressBarDrawerWrapper shouldScroll>
        <div className='progress-bar-extras-drawer'>
          <div className='progress-bar-extras-drawer__details'>
            <h2 className='progress-bar-extras-drawer__title'>{utils.i18n('progress_bar_extras_drawer_title')}</h2>
            <div className='progress-bar-extras-drawer__extras_list'>
              {/* included */}
              {!!includedExtrasAndDrivers.length && (
                <section className='extras-drawer-details-section-list'>
                  <h4 className='extras-drawer-details-section-list__title'>
                    {utils.i18n('progress_bar_extras_drawer_included_extras_title', [includedExtrasAndDrivers.length])}
                  </h4>
                  <div className='extras-drawer-details-section-list__items'>
                    {includedExtrasAndDrivers.map(({ name, code, selected_quantity }) => (
                      <li
                        key={code}
                        className='extras-drawer-details-section-list__item extras-drawer-details-section-list__item--included'>
                        {name}
                        {selected_quantity > 1 ? ` (x${selected_quantity})` : null}
                        <span className='extras-drawer-details-section-list__item--check' />
                      </li>
                    ))}
                  </div>
                </section>
              )}
              {/* added */}
              <section className='extras-drawer-details-section-list'>
                <h4 className='extras-drawer-details-section-list__title'>
                  {utils.i18n('progress_bar_extras_drawer_added_extras_title', [addedExtrasAmount])}
                </h4>
                {addedExtrasAmount ? (
                  <div className='extras-drawer-details-section-list__items'>
                    {/* Bundles */}
                    {selectedBundle?.name && (
                      <>
                        <div className='extras-drawer-details-section-list__item extras-drawer-details-section-list__item__bundle'>
                          {selectedBundle.title}
                          <FormattedPrice classNames='extras-drawer-details-section-list__item__prices'>
                            {utils.getFormattedPrice(selectedBundle.upgrade_bundle_view)}
                          </FormattedPrice>
                          <div className='extras-drawer-details-section-list__item__bundle--items'>
                            {selectedBundle.bundleExtras.map(({ name, code, selected_quantity }) => (
                              <div key={code} className='extras-drawer-details-section-list__item__bundle--item'>
                                {name}
                                {selected_quantity > 1 ? ` (x${selected_quantity})` : null}
                              </div>
                            ))}
                            {bundledAdditionalDrivers && (
                              <div key={'addt_drv'} className='extras-drawer-details-section-list__item__bundle--item'>
                                {utils.i18n('bundles_card_additional_drivers', [bundledAdditionalDrivers])}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {/* Added Items (not in bundles) */}
                    {addedExtras.map(
                      ({ name, code, rate_amount_view, rate_type, selected_quantity, total_amount_view, status }) => {
                        const rateAmount = utils.getFormattedPrice(rate_amount_view);
                        const totalAmount = utils.getFormattedPrice(total_amount_view);
                        const isFeeWaived = status === RESERVATIONS.EXTRAS_WAIVED_STATUS;
                        const isMandatory = status === RESERVATIONS.EXTRAS_MANDATORY_STATUS;
                        return (
                          <li key={code} className='extras-drawer-details-section-list__item'>
                            {name}
                            {selected_quantity > 1 ? ` (x${selected_quantity})` : null}
                            {isMandatory && (
                              <span className='extras-drawer-details-section-list__item--mandatory'>
                                &nbsp;({utils.i18n('extras_status_MANDATORY')})
                              </span>
                            )}

                            {(rateAmount || totalAmount) && (
                              <span className='extras-drawer-details-section-list__item__prices'>
                                {!!rate_type && !!rateAmount && (
                                  <FormattedPrice classNames='extras-drawer-details-section-list__item--rate-price'>
                                    {utils.i18n(`progress_bar_extras_drawer_added_extras_rate_${rate_type}`, [
                                      rateAmount,
                                    ])}
                                  </FormattedPrice>
                                )}
                                {totalAmount && (
                                  <FormattedPrice classNames='extras-drawer-details-section-list__item--total-price'>
                                    {isFeeWaived ? utils.i18n('extras_status_WAIVED') : totalAmount}
                                  </FormattedPrice>
                                )}
                              </span>
                            )}
                          </li>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div className='extras-drawer-details-section-list__items'>
                    <p> {utils.i18n('progress_bar_extras_drawer_added_extras_zero')}</p>
                  </div>
                )}
              </section>
            </div>
          </div>
          <div className='progress-bar-extras-drawer__edit'>
            {/* edit button */}
            <Link to={extrasPath}>
              <Button
                data-dtm-track={utils.analytics.dtm(
                  ANALYTICS.UI_BUTTON,
                  ANALYTICS.PROGRESS_BAR,
                  addedExtrasAmount ? 'edit_extras' : 'add_extras'
                )}>
                {addedExtrasAmount
                  ? utils.i18n('progress_bar_extras_drawer_edit_extras_btn')
                  : utils.i18n('progress_bar_extras_drawer_add_extras_btn')}
              </Button>
            </Link>
          </div>
        </div>
      </ProgressBarDrawerWrapper>
    );
  }
}

export default ProgressBarExtrasDrawer;
