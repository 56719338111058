import { connect } from 'react-redux';
import utils from 'utils';
import { getBranchHours } from 'actions/location';
import { getUiLocationTimezone } from 'selectors/uiStateSelectors';
import DateTimeFieldset from './DateTimeFieldset';

const mapDispatchToProps = {
  getBranchHours,
};

const mapStateToProps = (state, ownProps) => ({
  hoursForStore: state.getIn(['gmi', 'solr', 'hours', ownProps.locationId, 'data']),
  locationTimezone: getUiLocationTimezone(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(DateTimeFieldset));
