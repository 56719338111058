import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';

const GenericDrawer = ({ headerText, icon, children, analyticsText, openCta, closeCta }) => {
  const [expanded, setExpanded] = useState(false);

  const handleCheck = () => {
    // Analytics
    utils.analytics.interaction(ANALYTICS.DRAWER, analyticsText, !expanded ? ANALYTICS.OPEN : ANALYTICS.CLOSE);

    setExpanded(!expanded);
  };

  return (
    <div className='generic-drawer__container'>
      <Button
        button={false}
        className={cn('generic-drawer__button', 'generic-drawer-mobile__button', icon, {
          'generic-drawer__button-expanded': expanded,
        })}
        onClick={handleCheck}
        type='button'
        ariaText={analyticsText}
        ariaExpanded={expanded.toString()}>
        {headerText}
        {openCta && !expanded && (
          <span className='generic-drawer__cta-text generic-drawer__cta-text--open'>{openCta}</span>
        )}
        {closeCta && expanded && (
          <span className='generic-drawer__cta-text generic-drawer__cta-text--close'>{closeCta}</span>
        )}
      </Button>
      <div
        aria-hidden={!expanded}
        className={cn('generic-drawer__content', {
          'generic-drawer__content-expanded': expanded,
        })}>
        {children}
      </div>
    </div>
  );
};

GenericDrawer.propTypes = {
  headerText: PropTypes.any.isRequired,
  icon: PropTypes.string,
  analyticsText: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  openCta: PropTypes.string,
  closeCta: PropTypes.string,
};

export default GenericDrawer;
