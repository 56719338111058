import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import Button from 'components/Button';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import utils from 'utils';
import { additionalDriverPolicySelector } from 'selectors/policiesSelectors';
import { breakpointSelector } from 'selectors/breakpoint';
import { reservationHasAdditionalDriverFreeSpouseSelector } from 'selectors/reservationFlowSelectors';

/**
 * LearnMoreAboutAdditionalDriver - displays the additional driver policy from the reservation
 * @return {JSX}
 */

const LearnMoreAboutAdditionalDriverModal = () => {
  const [expanded, setExpanded] = useState(false);
  const addrPolicyObject = useSelector(additionalDriverPolicySelector);
  const { isMobile } = useSelector(breakpointSelector);
  const hasAdditionalDriverFreeSpouse = useSelector(reservationHasAdditionalDriverFreeSpouseSelector);

  return (
    <Modal
      modalKey={MODAL.LEARN_MORE_ABOUT_ADDITIONAL_DRIVER}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('review_additional_drivers_learn_more_header')}
      customClass='additional-drivers__learn-more-modal'
      onClose={() => setExpanded(false)}>
      <>
        <p className='modal-themed__paragraph'>{utils.i18n('additional_drivers_learn_more_description')}</p>
        <p className='additional-drivers__learn-more-modal__list-header'>
          {utils.i18n('additional_drivers_learn_more_list_header')}
        </p>
        <ul className='additional-drivers__learn-more-modal__list'>
          <li className='additional-drivers__learn-more-modal__list-item'>
            {utils.i18n('additional_drivers_learn_more_list_item_1')}
          </li>
          <li className='additional-drivers__learn-more-modal__list-item'>
            {utils.i18n('additional_drivers_learn_more_list_item_2')}
          </li>
          <li className='additional-drivers__learn-more-modal__list-item'>
            {utils.i18n('additional_drivers_learn_more_list_item_3')}
          </li>
          <li className='additional-drivers__learn-more-modal__list-item'>
            {utils.i18n('additional_drivers_learn_more_list_item_4')}
          </li>
        </ul>

        {hasAdditionalDriverFreeSpouse && (
          <div className='additional-drivers__spouse-banner'>
            <h4 className='additional-drivers__spouse-banner__header'>
              {utils.i18n('add_additional_driver_spouse_banner_title')}
            </h4>
            <p>{utils.i18n('add_additional_driver_spouse_banner_description')}</p>
          </div>
        )}

        <div className='modal-themed__drawer-container'>
          <Button
            button={false}
            type='button'
            ariaExpanded={expanded.toString()}
            className={cn('modal-themed__drawer', { 'modal-themed__drawer--expanded': expanded })}
            onClick={() => setExpanded(!expanded)}>
            {utils.i18n('additional_drivers_learn_more_full_details')}
          </Button>
        </div>

        {expanded && addrPolicyObject && <div dangerouslySetInnerHTML={utils.sanitize(addrPolicyObject.text)} />}

        {isMobile && (
          <ModalFooter
            buttons={[
              {
                type: MODAL_BUTTON_TYPES.ACCEPT,
                label: utils.i18n('common_close'),
              },
            ]}
          />
        )}
      </>
    </Modal>
  );
};

export default LearnMoreAboutAdditionalDriverModal;
