import React from 'react';
import { useSelector } from 'react-redux';
import { carClassDetailsSelector, guaranteedResCardRequiredSelector } from 'selectors/reservationSelectors';
import { selectedExtras, hasLimitedInventoryExtraSelector } from 'selectors/extrasSelectors';
import { formattedPoliciesSelector } from 'selectors/policiesSelectors';
import GLOBAL from 'constants/global';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import ModifyReservationModal from 'components/modals/ModifyReservationModal';
import ReviewRentalDetails from 'components/ReservationFlow/Review/ReviewRentalDetails';
import CurrencyConversionModal from 'components/modals/CurrencyConversionModal';
import TaxesModalContent from 'components/TaxesModalContent';
import Modal from 'components/Modal';
import useActions from 'hooks/useActions';
import { i18n } from 'utils/i18n';
import { openModal } from "actions/modal/openModal";

/**
 * Skip The Counter Info Component
 * Sidebar that displays the current step info during Skip The Counter Flow.
 *
 * @return {JSX} SkipTheCounterStepInfo jsx component
 */
const SkipTheCounterLeftSide = ({ stepTwo }) => {
  const carClassDetails = useSelector(carClassDetailsSelector);
  const isVehicleLimitedInventory = carClassDetails?.status?.indexOf(GLOBAL.LIMITED_INVENTORY_CAR_CODE) !== -1;
  const extras = useSelector(selectedExtras);
  const policies = useSelector(formattedPoliciesSelector);
  const guaranteedResCardRequired = useSelector(guaranteedResCardRequiredSelector);
  const hasLimitedInventoryExtra = useSelector(hasLimitedInventoryExtraSelector);
  const resHasLimitedInventoryItemOrVehicle = isVehicleLimitedInventory || hasLimitedInventoryExtra;
  const showLearnMoreModal = useActions(() => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL));

  return (
    <section className='skip-the-counter-template__sidebar'>
      <div className='review__aside'>
        <div className='review__rental-details'>
          <ReviewRentalDetails
            hideEditButtons
            stcModifyButton
            resHasLimitedInventoryItemOrVehicle={resHasLimitedInventoryItemOrVehicle}
            policies={policies}
            extras={extras}
            isVehicleLimitedInventory={isVehicleLimitedInventory}
            allowLimitedInventoryIconExtra
            isGuaranteedRes={guaranteedResCardRequired}
            isSTCFlow
            showLearnMoreModal={showLearnMoreModal}
            stepTwo={stepTwo}
          />
        </div>
      </div>
      <ModifyReservationModal isSTCFlow={true} isTrueModify={true} />
      <Modal
        modalKey={MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL}
        theme={MODAL_THEMES.WHITE}
        header={i18n('rental_details_taxes_fees_modal_header')}>
        <TaxesModalContent />
      </Modal>
      <CurrencyConversionModal />
    </section>
  );
};

export default SkipTheCounterLeftSide;
