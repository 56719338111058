import React from 'react';
import PropTypes from 'prop-types';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import { getImageFromImagesObject } from 'utils/vehicles';
import useActions from 'hooks/useActions';
import { MODAL } from 'constants';
import { formatRemainingDays } from 'utils/date';
import { config } from 'utils/config';
import { openModal } from 'actions/modal/openModal';
import TripCard from 'components/TripCard';
import ExtendRentalInformationModal from 'components/modals/ExtendRentalInformationModal';
import AfterHoursReturnInstructionsModal from 'components/modals/AfterHoursReturnInstructionsModal';
import ReturnDirectionsModal from 'components/modals/ReturnDirectionsModal';
import LearnMoreAboutRoadsideAssistanceModal from 'components/modals/LearnMoreAboutRoadsideAssistanceModal';
import RentalNotEligibleForModificationsModal from 'components/modals/RentalNotEligibleForModificationsModal';
import CurrentTripsEmpty from './CurrentTripsEmpty';

/**
 * CurrentTrip Component - Show the session of Current Trip in My Trips page
 *
 * @param {object} props - React Props
 * @param {object} props.currentTrips - Result of getCurrentTripDetails
 * @return {JSX} - CurrentTrip React Component
 */

const CurrentTrip = ({ currentTrips }) => {
  const openMissingTripsModal = useActions(openModal(MODAL.MISSING_TRIPS_MODAL));

  const currentTripsOrderDate = gmiUtils.sortArrayOfObjectsByKey(currentTrips, 'return_time');

  const handleOpenMissingModal = () => {
    openMissingTripsModal();
  };

  return (
    <div className='current-trip row'>
      {currentTrips.length === 0 ? (
        <CurrentTripsEmpty openMissingTripsModal={handleOpenMissingModal} />
      ) : (
        <>
          {currentTripsOrderDate.map((trip) => (
            <TripCard
              currentTripCard
              key={trip.ticket_number}
              icon='car-front'
              location={trip.return_location.name}
              confirmation_number={trip.confirmation_number}
              agreement_number={trip?.ticket_number || trip?.agreement_number}
              vehicleDetails={trip.vehicle_details}
              remainingDaysCopy={formatRemainingDays(trip.remaining_days_for_drop_off, 'return')}
              ctaLabel={i18n('trip_card_call_to_modify_cta')}
              locationDetailsID={trip.return_location.id}
              showRenterResources
              showAfterHoursCta={trip.return_location.after_hours_return}
              return_location={trip.return_location}
              dateOfRentInteraction={gmiUtils
                .getDateTimeObjFromTs(trip.return_time)
                .format(config.getDateTimeLongFormat('llll_with_comma'))}
              within24hrsReturnTime={trip.within24_hrs_of_return_time}
              ticketNumber={trip.ticket_number}
              rentalReturnConfirmed={
                Object.hasOwn(trip, 'rental_return_confirmed') ? trip.rental_return_confirmed : trip?.return_confirmed
              }
              hasConfirmModify={trip?.confirm_modify}
              carClassImage={
                trip.vehicle_details?.images &&
                getImageFromImagesObject(trip.vehicle_details.images, { type: '0', width: 352 })
              }
              carClassImageAlt={trip.vehicle_details?.make_model_or_similar_text}
            />
          ))}
        </>
      )}

      <ReturnDirectionsModal />
      <AfterHoursReturnInstructionsModal />
      <ExtendRentalInformationModal />
      <LearnMoreAboutRoadsideAssistanceModal />
      <RentalNotEligibleForModificationsModal />
    </div>
  );
};

CurrentTrip.propTypes = {
  currentTrips: PropTypes.arrayOf(PropTypes.object),
  openMissingTripsModal: PropTypes.func,
};

export default CurrentTrip;
