import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import Modal from 'components/Modal';
import RequiredFieldsIndicator from 'components/RequiredFieldsIndicator';
import TextField from 'components/Form/TextField';
import { ModalFooter } from 'components/Modal/ModalFooter';
import ANALYTICS from 'constants/analytics';
import { MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';
import useActions from 'hooks/useActions';
import Form from 'components/Form/Form';
import LoadingWidget from 'components/LoadingWidget';
import FreeSpouseBanner from 'components/modals/AdditionalDriverModals/FreeSpouseBanner';
import { getShowAdditionalDriverIsSpouseSelector } from 'selectors/reservationSelectors';
import { addAdditionalDriverByName } from 'actions/reservation/addAdditionalDriverByName';
import { closeModal } from 'actions/modal/closeModal';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const AddAdditionalDriverModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hideBanner, setHideBanner] = useState(false);
  const [isSpouseLocalState, setIsSpouseLocalState] = useState(false);
  const modalKey = MODAL.ADDITIONAL_DRIVER_ADD_MODAL;

  const [handleAccept, handleCloseModal] = useActions([addAdditionalDriverByName, closeModal]);
  const isSpouse = useSelector(getShowAdditionalDriverIsSpouseSelector);

  const handleFormSubmit = (values) => {
    setIsLoading(true);

    return handleAccept({ ...values }).then(() => {
      handleCloseModal({ skipAnalytics: true });
      setIsLoading(false);
    });
  };

  const getButtons = (handleSubmit) => {
    const buttons = [
      {
        type: isSpouse && !hideBanner ? ACCEPT : DECLINE,
        label: utils.i18n(isSpouse && !hideBanner ? 'common_close' : 'common_cancel'),
        analyticsKey: utils.analytics.prepareKey(MODAL.ADDITIONAL_DRIVER_ADD_MODAL),
        analyticsValue: ANALYTICS.CANCEL,
      },
    ];

    if (!isSpouse || hideBanner) {
      buttons.push({
        type: ACCEPT,
        label: utils.i18n('add_additional_driver_modal_accept'),
        handler: handleSubmit,
        analyticsHandler: () =>
          utils.analytics.interaction(
            ANALYTICS.MODAL,
            utils.analytics.prepareKey(MODAL.ADDITIONAL_DRIVER_ADD_MODAL),
            ANALYTICS.ADD_DRIVER
          ),
      });
    }
    return buttons;
  };

  const onCloseModal = () => {
    if (isSpouse) {
      setHideBanner(true);
    } else if (!isSpouse && isSpouseLocalState) {
      setIsSpouseLocalState(false);
    }
  };

  return (
    <Modal
      modalKey={modalKey}
      header={utils.i18n('add_additional_driver_modal_header')}
      theme={MODAL_THEMES.WHITE}
      onClose={onCloseModal}
    >
      <Form onSubmit={handleFormSubmit}>
        {({ handleSubmit }) => (
          <>
            {!hideBanner && (
              <FreeSpouseBanner
                handleCloseModal={handleCloseModal}
                isSpouseLocalState={isSpouseLocalState}
                setIsSpouseLocalState={setIsSpouseLocalState}
                modalKey={modalKey}
              />
            )}
            {(!isSpouseLocalState || hideBanner) && (
              <form className='additional-drivers__add-modal'>
                {isLoading ? (
                  <LoadingWidget />
                ) : (
                  <>
                    <RequiredFieldsIndicator />

                    <div className='additional-drivers__add-modal__fields'>
                      <TextField
                        id='firstName'
                        name='firstName'
                        required={utils.i18n('additional_driver_first_name_required_error')}
                        className='field-control__input-field'
                        label={utils.i18n('add_additional_driver_modal_first_name')}
                        autoComplete='given-name'
                      />

                      <TextField
                        id='lastName'
                        name='lastName'
                        required={utils.i18n('additional_driver_last_name_required_error')}
                        className='field-control__input-field'
                        label={utils.i18n('add_additional_driver_modal_last_name')}
                        autoComplete='family-name'
                      />
                    </div>

                    <ModalFooter buttons={getButtons(handleSubmit)} />
                  </>
                )}
              </form>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddAdditionalDriverModal;
