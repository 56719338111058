import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { DISCOUNTS, MODAL, MODAL_THEMES } from 'constants';
import cn from 'classnames';
import Button from 'components/Button';
import Modal from 'components/Modal';

const ProgressBarCID = ({
  message,
  type,
  isTrueModify,
  termsConditions,
  partner_reward_program,
  openModal,
  isCustomPathFlowSPA,
}) => {
  const { program_name } = partner_reward_program || {};

  const handleOpenRemoveCIDModal = () => {
    openModal(MODAL.REMOVE_CID_MODAL);
  };

  const handleOpenRemovePartnerRewardsModal = () => {
    openModal(MODAL.REMOVE_PARTNER_REWARDS_MODAL);
  };

  const openTermsConditions = () => {
    openModal(MODAL.CID_TERMS_AND_CONDITIONS_MODAL);
  };

  return (
    <div className={cn('progress-bar-cid__container', { 'progress-bar-cid__container--modify': isTrueModify })}>
      <div className='progress-bar-cid__label-container'>
        <p
          className={cn('progress-bar-cid__contract-label', {
            'discount-notification--price-tag':
              !isCustomPathFlowSPA && !program_name && type === DISCOUNTS.PROMOTION_TYPE,
            'discount-notification--star': !isCustomPathFlowSPA && !program_name && type === DISCOUNTS.CORPORATE_TYPE,
            'discount-notification--partner-rewards': !isCustomPathFlowSPA && program_name,
            'progress-bar-cid__contract-label--custom-path': isCustomPathFlowSPA,
          })}>
          {/* Partner rewards (program_name) will take precedence over CID name (message) */}
          <span className='progress-bar-cid__ellipsis-wrapper'>{program_name || message}</span>
        </p>
        {!isTrueModify && !isCustomPathFlowSPA && (
          <Button
            className='progress-bar-cid__remove-cid'
            ariaText={utils.i18n('progress_bar_cid_remove_cid')}
            onClick={program_name ? handleOpenRemovePartnerRewardsModal : handleOpenRemoveCIDModal}
            link
          />
        )}
      </div>
      {!!termsConditions && (
        <>
          <Button linkText className='progress-bar-cid__terms-conditions' onClick={openTermsConditions}>
            {utils.i18n('progress_bar_cid_terms_conditions')}
          </Button>

          <Modal
            modalKey={MODAL.CID_TERMS_AND_CONDITIONS_MODAL}
            header={utils.i18n('progress_bar_cid_terms_conditions')}
            theme={MODAL_THEMES.WHITE}>
            <div dangerouslySetInnerHTML={utils.sanitize(termsConditions)} />
          </Modal>
        </>
      )}
    </div>
  );
};

ProgressBarCID.propTypes = {
  pickup_time: PropTypes.string,
  contractType: PropTypes.string,
  openModal: PropTypes.func,
  isTrueModify: PropTypes.bool,
  termsConditions: PropTypes.string,
  partner_reward_program: PropTypes.shape({
    code: PropTypes.string,
    member_id: PropTypes.string,
    program_name: PropTypes.string,
  }),
  message: PropTypes.string,
  type: PropTypes.string,
  isCustomPathFlowSPA: PropTypes.bool,
};

ProgressBarCID.defaultProps = {
  partner_reward_program: {},
};

export default ProgressBarCID;
