import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { useSelector } from 'react-redux';
import { isThirdPartyReservationSelector } from 'selectors/reservationFlowSelectors';
import { isDestinationCurrencySelected } from 'selectors/vehicleSelectSelectors';
import FormattedPrice from 'components/FormattedPrice';
import TimeAndDistanceLineItem from './TimeAndDistanceLineItem';

/**
 * Time & Distance section of Vehicle info displayed on the Review screen
 * @param {object} props                              - React Props
 * @param {array} props.chargedRate                   - Array of vehicle rates charged for the chosen vehicle class
 * @param {object} props.estimatedTotalVehicleView    - Total value charged for the chosen vehicle class
 * @param {boolean} props.asterisk                    - Indicates whether values displayed should show an asterisk or not
 * @return {JSX} TimeAndDistance component
 */
const TimeAndDistance = ({ estimatedTotalVehicleView, estimatedTotalVehiclePayment, chargedRate, asterisk, isRental }) => {
  const isThirdPartyReservation = useSelector(isThirdPartyReservationSelector);
  const destinationCurrencySelected = useSelector(isDestinationCurrencySelected);

  const estimatedTotalPayment = destinationCurrencySelected ? estimatedTotalVehiclePayment : estimatedTotalVehicleView;
  // TODO: replace with secret_rate flag
  const isThirdPartyNetRate =
    isThirdPartyReservation && (parseInt(estimatedTotalPayment?.amount, 10) === 0 || !estimatedTotalPayment);


  const showChargedRatesTimeAndDistanceLineItems = isRental || !isThirdPartyNetRate && utils.config.getIsIncrementalPricingEnabled() && chargedRate?.length;

  return (
    <>
      {showChargedRatesTimeAndDistanceLineItems ? (
        chargedRate.map(({ rate_quantity, rate_amount_view, rate_type, total_amount_view }) => (
          <TimeAndDistanceLineItem
            label={utils.i18n(
              `rental_details_time_distance_${rate_type}`,
              [
                <Fragment key='0'>{rate_quantity}</Fragment>,
                <FormattedPrice key='1'>{utils.getFormattedPrice(rate_amount_view, { asterisk })}</FormattedPrice>,
              ],
              { jsx: true }
            )}
            total={utils.getFormattedPrice(total_amount_view, { asterisk })}
            key={rate_type}
          />
        ))
      ) : (
        <TimeAndDistanceLineItem
          label={utils.i18n('rental_details_time_distance')}
          total={
            isThirdPartyNetRate
              ? utils.i18n('third_party_reservation_net_rate')
              : utils.getFormattedPrice(estimatedTotalPayment, { asterisk })
          }
        />
      )}
    </>
  );
};

TimeAndDistance.propTypes = {
  chargedRate: PropTypes.array,
  estimatedTotalVehicleView: PropTypes.object,
  asterisk: PropTypes.bool,
  isRental: PropTypes.bool,
  estimatedTotalVehiclePayment: PropTypes.object
};

export default TimeAndDistance;