import { connect } from 'react-redux';
import utils from 'utils';
import { profileDataSelector } from 'selectors/profileSelectors';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import ProfileProgressSection from './ProfileProgressSection';

const mapStateToProps = state => ({
  profileData: profileDataSelector(state, { profile_completion: true, oci_individual_status: true }),
});

const mapDispatchToProps = {
  // Note: Analytics for these three `openModal` states will be handled by the modal content's life cyles/hooks
  // The "wizard" steps need to be tracked (in particular step 1 to step 2)
  openWizardModal: () => openModal(MODAL.EDIT_PROFILE_WIZARD_MODAL, { skipAnalytics: true }),
  openDriversLicenseModal: () => openModal(MODAL.EDIT_DRIVERS_LICENSE_MODAL),
  openContactInfoModal: () => openModal(MODAL.EDIT_PROFILE_CONTACT_INFORMATION_MODAL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(ProfileProgressSection));
