import { connect } from 'react-redux';
import { getProgressBarExpandedTab } from 'selectors/reservationFlowSelectors';
import TotalDropDown from './TotalDropDown';

const mapStateToProps = state => ({
  expandedTab: getProgressBarExpandedTab(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalDropDown);
