import React from 'react';
import { i18n } from 'utils/i18n';
import { dtm } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import ASSETS from 'constants/assets';
import Picture from 'components/Picture';
import Button from 'components/Button/Button';
import PropTypes from 'prop-types';

/**
 * CurrentTripsEmpty Description
 * will be displayed when user has no upcoming trips
 * @param {object} props - React Props
 * @param {Boolean} props.showProfileEmptyState - if true, will show UI for profile page. If false, will show My Trips UI
 * @param {function} props.openMissingTripsModal - opens MISSING_TRIPS_MODAL
 * @return {JSX} UpcomingTripsEmpty component
 */
const CurrentTripsEmpty = ({ openMissingTripsModal }) => {
  const pictureSrcProps = {
    srcMobile: ASSETS.NO_UPCOMING_TRIPS_1,
    srcTablet: ASSETS.NO_UPCOMING_TRIPS_2,
    src: ASSETS.NO_UPCOMING_TRIPS_3,
  };

  return (
    <div className='upcoming-trips__empty'>
      <Picture {...pictureSrcProps} pictureClassName={'upcoming-trips__empty__image'} isDecorative />
      <div className='upcoming-trips__empty__content'>
        <>
          <h3>{i18n('my_trips_no_current_trips_title')}</h3>
          <p>{i18n('my_trips_no_current_trips_description')}</p>
          <Button
            onClick={openMissingTripsModal}
            button
            data-dtm-track={dtm(ANALYTICS.MY_TRIPS, 'missing_trip', ANALYTICS.SELECT)}
          >
            {i18n('my_trips_missing_a_trip_cta')}
          </Button>
        </>
      </div>
    </div>
  );
};

CurrentTripsEmpty.propTypes = {
  openMissingTripsModal: PropTypes.func,
};

export default CurrentTripsEmpty;
