import React from 'react';
import utils from 'utils';
import { WINDOW_OBJECT_KEYS } from 'constants';
import Anchor from 'components/Anchor';

const customerServiceUrl = utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT);

const FindingReservationTipsModalContent = () => (
  <div className='finding-reservation-tips-modal modal-themed__section'>
    <ul className='modal-themed__bulleted-list'>
      <li>{utils.i18n('reservation_lookup_tips_modal_copy_1')}</li>
      <li>{utils.i18n('reservation_lookup_tips_modal_copy_2')}</li>
      <li>{utils.i18n('reservation_lookup_tips_modal_copy_3')}</li>
      <li>
        {utils.i18n(
          'reservation_lookup_tips_modal_copy_4',
          [
            <Anchor
              className='link--text'
              key='finding-reservation-tips-modal-customer-service-link_0'
              href={customerServiceUrl}
              isExternal
              hideExternalIcon>
              {utils.i18n('reservation_lookup_tips_modal_customer_service_link')}
            </Anchor>,
          ],
          {
            jsx: true,
          }
        )}
      </li>
    </ul>
  </div>
);

export default FindingReservationTipsModalContent;
