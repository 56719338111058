/**
 * Toggler for vehicle filters in tablet and mobile views
 */
import React from 'react';
import { i18n } from 'utils/i18n';
import { dtm } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import { getUiStateNumberOfPassengersFiltersSelector } from 'selectors/uiStateSelectors';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { propTypes, defaultProps } from './VehicleFilterTogglePropTypes';

const VehicleFilterToggle = ({ numFiltersSelected, onClick, hasSorting }) => {
  const passengersCheckboxesSelected = useSelector(getUiStateNumberOfPassengersFiltersSelector);

  return (
    <div className='vehicle-filters__filterToggle'>
      <Button
        plain
        className='link'
        onClick={onClick}
        aria-label={i18n(hasSorting ? 'sort_and_filter' : 'vehicle_filters_filter')}
        data-dtm-track={dtm(ANALYTICS.RW, ANALYTICS.CARS, ANALYTICS.SORT_AND_FILTER)}
      >
        {i18n(hasSorting ? 'sort_and_filter' : 'vehicle_filters_filter')}
        {numFiltersSelected && numFiltersSelected > 0 ? (
          <span>{`(${numFiltersSelected + passengersCheckboxesSelected})`}</span>
        ) : (
          ''
        )}
      </Button>
    </div>
  );
};

VehicleFilterToggle.defaultProps = defaultProps;
VehicleFilterToggle.propTypes = propTypes;

export default VehicleFilterToggle;
