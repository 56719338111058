import PropTypes from 'prop-types';

export default {
  // modal contentProps passed through openModalWithProps
  header: PropTypes.string,
  extras: PropTypes.array,
  description: PropTypes.string,
  bundledAdditionalDrivers: PropTypes.number,
  additionalDriversPolicy: PropTypes.object,
};
