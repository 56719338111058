import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';
import { utils } from '@ehi/global-marketing-interface';
import PropTypes from './GuidedFormIndicatorPropTypes';

// place GuidedFormIndicator at the bottom of your final-form render function
// make sure to `import setFieldData from 'final-form-set-field-data';`
// and pass it as `mutators={{ setFieldData }}` to the <Form />
// see BookingWidget.js for a working example
// it will set the `meta.data.isFocusedActive` to the first invalid field

class GuidedFormIndicator extends Component {
  state = {
    previousIndicatedField: null,
    userEndedGuidedFlow: false,
  };

  getOrderedFieldNames = (formElement) => {
    const collection = utils.get(formElement, 'current.elements') || [];
    return [...collection].filter((e) => e.name).map((e) => e.name);
  };

  setIndicatedField = (formState) => {
    const {
      breakpoint,
      disableGuidedFlow,
      form: {
        mutators: { setFieldData },
      },
      formRef,
    } = this.props;
    const { userEndedGuidedFlow, previousIndicatedField } = this.state;
    const { active, errors, dirty } = formState;
    const shouldIndicate = !!(previousIndicatedField && !breakpoint.isMobile && !disableGuidedFlow);
    const registeredFields = this.getOrderedFieldNames(formRef);
    const invalidFieldKeys = Object.keys(errors);

    // check if the fields are being activated/filled in order
    // if an error field index is lower than the active field index, this indicates the user broke the flow order
    const activeFieldIndex = registeredFields.indexOf(active);
    const [firstInvalidField] = invalidFieldKeys;
    const firstInvalidFieldIndex = registeredFields.indexOf(firstInvalidField);
    const userSkippedOrder = userEndedGuidedFlow || activeFieldIndex > firstInvalidFieldIndex; // re-feed `userEndedGuidedFlow` into this const so we don't ever re-enable guided flow once broken

    // indicate the first invalid field
    let indicatedField = null;
    registeredFields.forEach((key) => {
      if (!indicatedField && invalidFieldKeys.includes(key)) {
        setFieldData(key, {
          isGuided: true,
          isNext: true,
          isFocusedActive: shouldIndicate,
        });

        indicatedField = key;
      } else {
        setFieldData(key, {
          isGuided: true,
          isNext: false,
          isFocusedActive: false,
        });
      }
    });

    const indicatedFieldHasChanged = indicatedField && indicatedField !== previousIndicatedField;

    this.setState(
      {
        previousIndicatedField: indicatedField,
        userEndedGuidedFlow: userSkippedOrder,
      },
      () => {
        if (indicatedFieldHasChanged) {
          const shouldFocusAndActivate = shouldIndicate && dirty && !this.state.userEndedGuidedFlow; // must grab `userEndedGuidedFlow` from the updated state in the callback

          if (shouldFocusAndActivate) {
            const [field] = document.getElementsByName(indicatedField);
            setTimeout(() => {
              field && field.focus();
            }, 0);
          }
        }
      }
    );
  };

  handleChange = (formState) => {
    this.setIndicatedField(formState);
  };

  // hack for handleChange to get called  before render, referred from here https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
  render() {
    return (
      <FormSpy
        onChange={(formState) => setTimeout(() => this.handleChange(formState), 0)}
        subscription={{ active: true, errors: true, submitFailed: true, dirty: true }}
      />
    );
  }
}

GuidedFormIndicator.propTypes = PropTypes;

export default GuidedFormIndicator;
