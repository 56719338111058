import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { actions } from '@ehi/global-marketing-interface';
import useActions from 'hooks/useActions';
import ANALYTICS from 'constants/analytics';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { reservationAirlineInfoSelector } from 'selectors/reservationSelectors';
import { updateCommitState } from "actions/reservation/updateCommitState";

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const FlightDelayedModal = ({ openUpdateFlightInfoModal, isThirdPartyReservation, modifyNotAllowedReasons }) => {
  const airlineInfo = useSelector(reservationAirlineInfoSelector);
  const cleanMessagesAction = useActions(actions.cleanMessages);
  const updateCommitStateHandler = useActions(updateCommitState);
  const isEligible = !modifyNotAllowedReasons && !isThirdPartyReservation;

  const handleAccept = (handleClose) => () => {
    handleClose();
    updateCommitStateHandler('flight_number', airlineInfo.flight_number);
    updateCommitStateHandler('code', airlineInfo.code);
    openUpdateFlightInfoModal();
    utils.analytics.interaction(ANALYTICS.MODAL, ANALYTICS.UPDATE_FLIGHT_INFORMATION, ANALYTICS.OPEN);
    // clear the GMA error message on updateFlightInfo modal open
    cleanMessagesAction();
  };

  return (
    <Modal
      modalKey={MODAL.FLIGHT_DELAYED_MODAL}
      header={utils.i18n('flight_delayed_changed_title')}
      theme={MODAL_THEMES.WHITE}
      showHeaderTextIcon
      customClass='modal-themed__expanded-content'
      customActionOnClose={() => {
        utils.analytics.interaction(
          ANALYTICS.MODAL,
          isEligible ? 'flight modification eligible' : 'flight delayed faq ineligible',
          ANALYTICS.CLOSE
        );
      }}
      // We need to pass skip analytics for the "clearModalQueue" action if we are pushing _analytics.interaction manually on open/close modal
      handleModalQueue={{ skipAnalytics: true }}
      headerTextIcons={'plane'}
    >
      {({ handleClose }) => (
        <div className='modal-themed__flight-change-delayed-modal'>
          <p className='modal-themed__paragraph'>
            <b>{utils.i18n('flight_delayed_changed_description_bold')}</b>
            {isEligible
              ? utils.i18n('flight_delayed_changed_description')
              : utils.i18n('flight_delayed_changed_description_non_eligible')}
          </p>
          <ul className='modal-themed_paragraph-list'>
            <li>{utils.i18n('flight_delayed_bullet_one')}</li>
            <li>
              {isEligible
                ? utils.i18n('flight_delayed_bullet_two')
                : utils.i18n('flight_delayed_bullet_two_non_eligible')}
              {WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT ? (
                <a
                  className='link link--text small-font'
                  href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)}
                >
                  {utils.i18n('flight_delayed_customer_support')}.
                </a>
              ) : (
                `${utils.i18n('flight_delayed_customer_support')}.`
              )}
            </li>
          </ul>
          <div className='flight-delayed-changed-modal-footer column-reverse-footer'>
            {isEligible ? (
              <ModalFooter
                buttons={[
                  {
                    type: DECLINE,
                    label: utils.i18n('flight_delayed_changed_back_to_reservation'),
                    analyticsHandler: () =>
                      utils.analytics.interaction(ANALYTICS.MODAL, 'flight modification eligible', ANALYTICS.CANCEL),
                  },
                  {
                    type: ACCEPT,
                    label: utils.i18n('flight_delayed_changed_update_flight_info'),
                    handler: handleAccept(handleClose),
                  },
                ]}
              />
            ) : (
              <ModalFooter
                buttons={[
                  {
                    type: DECLINE,
                    label: utils.i18n('flight_delayed_changed_back_to_reservation'),
                    analyticsHandler: () =>
                      utils.analytics.interaction(ANALYTICS.MODAL, 'flight delayed faq ineligible', ANALYTICS.CANCEL),
                  },
                ]}
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

FlightDelayedModal.propTypes = {
  handleClose: PropTypes.func,
  openUpdateFlightInfoModal: PropTypes.func,
  isThirdPartyReservation: PropTypes.bool,
  modifyNotAllowedReasons: PropTypes.array,
};

export default FlightDelayedModal;
