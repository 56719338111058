import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS, MODAL, MODAL_THEMES } from 'constants';
import Modal from 'components/Modal';
import Button from 'components/Button';

/**
 * RentalTermsAndConditionsModal
 *
 * @param {object} props - React Props
 * @param {array} props.rentalTermsAndConditions
 * @param {function} props.getRentalTermsAndConditions
 * @return {JSX} - RentalTermsAndConditionsModal react component
 */

class RentalTermsAndConditionsModal extends Component {
  state = {
    selectedLanguage: null,
    termsByLang: {},
  };

  componentDidMount() {
    const { rentalTermsAndConditions, getRentalTermsAndConditions } = this.props;
    if (rentalTermsAndConditions?.length) {
      this.setLanguageContent();
    } else {
      getRentalTermsAndConditions();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { rentalTermsAndConditions, isFetchingSession, getRentalTermsAndConditions, gboReservationExists } =
      this.props;

    if (
      rentalTermsAndConditions?.length &&
      ((!prevProps.rentalTermsAndConditions && !prevState.selectedLanguage) ||
        rentalTermsAndConditions !== prevProps.rentalTermsAndConditions)
    ) {
      this.setLanguageContent(rentalTermsAndConditions !== prevProps.rentalTermsAndConditions);
    }

    // condition to wait for the response of the current session call `current` and
    // rentalTermsAndConditions` based on if `gbo.reservation` object is present or not.

    if (
      !rentalTermsAndConditions?.length &&
      !isFetchingSession &&
      prevProps.isFetchingSession &&
      gboReservationExists
    ) {
      getRentalTermsAndConditions();
    }
  }

  setLanguageContent = (rentalTC) => {
    const { rentalTermsAndConditions } = this.props;
    const { termsByLang } = this.state;
    const locale = utils.config.getLocale();
    let selectedLanguage = termsByLang[locale];

    // first render to default to terms for domain locale
    if (!selectedLanguage) {
      rentalTermsAndConditions?.forEach((term) => {
        termsByLang[term.locale] = termsByLang[term.locale] || term;
      });
      selectedLanguage = termsByLang[locale] || rentalTermsAndConditions?.[0]; // if not found, we'll default the first in the arry
      this.setState({ selectedLanguage, termsByLang });
    } else if (rentalTC && rentalTermsAndConditions) {
      // if rentalTC is updated
      rentalTermsAndConditions?.forEach((term) => {
        termsByLang[term.locale] = termsByLang[term.locale] || term;
      });
      selectedLanguage = rentalTermsAndConditions?.[0] || termsByLang[locale];
      this.setState({ selectedLanguage, termsByLang });
    } else {
      this.setState({ selectedLanguage });
    }
  };

  handleChange = (e) => {
    const code = e.target.value;
    const { termsByLang } = this.state;
    const selectedLanguage = termsByLang[code];
    this.setState({ selectedLanguage });
  };

  handlePrint = () => utils.print([]);

  getRentalTermsAndConditionsText = (selectedLanguage, isFranchisee, franchiseeName) => {
    let selectedContent = selectedLanguage?.rental_terms_and_conditions_text || '';

    if (selectedContent && isFranchisee && franchiseeName) {
      selectedContent = `<p>${utils.i18n('rental_will_be_serviced_by', [franchiseeName])}</p>${selectedContent}`;
    }

    return selectedContent;
  };

  render() {
    const { rentalTermsAndConditions, isFranchisee, franchiseeName } = this.props;
    const { selectedLanguage } = this.state;
    const selectedContent = this.getRentalTermsAndConditionsText(selectedLanguage, isFranchisee, franchiseeName);

    return (
      <Modal
        customClass='key-facts-modal key-facts-modal__rental-terms-conditions'
        modalKey={MODAL.KEY_RENTAL_FACTS_TERMS_AND_CONDITIONS}
        header={utils.i18n('key_facts_modal_rental_terms_and_conditions_title')}
        theme={MODAL_THEMES.WHITE}
      >
        <div className='key-facts-modal__container key-facts-modal__container--terms-and-conditions'>
          <div className='key-facts-modal__actions-container'>
            {/* Language Toggle */}
            <div className='key-facts-modal__language-toggle__container'>
              <select
                className='key-facts-modal__language-toggle__select'
                aria-label={utils.i18n('key_facts_modal_rental_terms_and_conditions_language_toggle')}
                onChange={this.handleChange}
                value='selected_language'
              >
                <option value={selectedLanguage?.locale}>{selectedLanguage?.locale_label}</option>
                {rentalTermsAndConditions &&
                  rentalTermsAndConditions.map(
                    (option) =>
                      option?.locale !== selectedLanguage?.locale && (
                        <option key={option?.locale} value={option?.locale}>
                          {option?.locale_label}
                        </option>
                      )
                  )}
              </select>
              <span id='selected_language' className='language-toggle__selected'>
                {selectedLanguage?.locale_label}
              </span>
            </div>

            {/* Print Button */}
            <Button
              link
              data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.TERMS_AND_CONDITIONS, ANALYTICS.PRINT)}
              className='key-facts-modal__print'
              onClick={this.handlePrint}
              ariaText={utils.i18n('tab_content_print')}
            >
              <span>{utils.i18n('tab_content_print')}</span>
            </Button>
          </div>

          {/* Content */}
          <div
            id={`${MODAL.KEY_RENTAL_FACTS_TERMS_AND_CONDITIONS}_desc`}
            className='key-facts-modal__content'
            dangerouslySetInnerHTML={utils.sanitize(selectedContent)}
          />
        </div>
      </Modal>
    );
  }
}

RentalTermsAndConditionsModal.propTypes = {
  rentalTermsAndConditions: PropTypes.array,
  getRentalTermsAndConditions: PropTypes.func,
  franchiseeName: PropTypes.string,
  isFranchisee: PropTypes.bool,
};

export default RentalTermsAndConditionsModal;
