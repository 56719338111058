import { connect } from 'react-redux';
import { actions } from '@ehi/global-marketing-interface';
import { driversAgeOptionsSelector } from 'selectors';
import utils from 'utils';
import DriversAge from './DriversAge';

const mapStateToProps = state => ({
  label: utils.i18n('common_renters_age_label'),
  options: driversAgeOptionsSelector(state),
});

const mapDispatchToProps = dispatch => {
  const { initiateState } = actions.reservations;

  return {
    setAge: renter_age => dispatch(initiateState.set('renter_age', { renter_age: parseInt(renter_age, 10) })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriversAge);
