import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import cn from 'classnames';
import { MODAL } from 'constants';
import Button from 'components/Button';
import useClickOutside from 'hooks/useClickOutside';
import PoliciesModal from 'components/modals/PoliciesModal';
import KeyFactsOption from './KeyFactsOption';

const KeyFactsDropDown = ({
  shouldShowKeyFacts,
  policies,
  openModal,
  rightSide,
  breakpoint,
  isRentalConfirmedFlow,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownContainer = useRef();

  const handleDrawerToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useClickOutside(
    {
      container: dropdownContainer,
      handler: handleDrawerToggle,
      listenerConditional: isExpanded,
      // only activate the click listener while expanded
    },
    [isExpanded]
  );

  const handleOptionClick = (modalKey) => () => {
    openModal(modalKey);
    setIsExpanded(false);
  };

  const keyFactsData = () => [
    {
      title: 'key_facts_facts_title',
      modalKey: MODAL.KEY_RENTAL_FACTS,
      shouldShow: shouldShowKeyFacts,
    },
    {
      title: 'terms_and_conditions_policies',
      modalKey: MODAL.RENTAL_POLICIES,
      shouldShow: policies.length !== 0,
    },
  ];

  const getTitle = () => {
    const { isDesktop } = breakpoint;
    let title;
    if (!isDesktop) {
      title = i18n('key_facts_policies_title');
    } else {
      title = i18n('key_facts_title');
    }
    return title;
  };

  return (
    <div className='key-facts-dropdown__container' ref={dropdownContainer}>
      <div
        className={cn('policies-link__container', {
          'key-facts-dropdown__title-draw-icon': shouldShowKeyFacts,
          'key-facts-dropdown__title-draw-icon--open': shouldShowKeyFacts && isExpanded,
        })}
      >
        <Button
          plain
          aria-label={getTitle()}
          onClick={shouldShowKeyFacts ? handleDrawerToggle : () => openModal(MODAL.RENTAL_POLICIES)}
          aria-expanded={isExpanded}
          className='policies-link__cta'
        >
          {getTitle()}
        </Button>
      </div>
      <div
        aria-hidden={!isExpanded}
        className={cn('key-facts-dropdown__options-container', {
          'key-facts-dropdown__options-container--open': isExpanded,
          'key-facts-dropdown__options-container--open-to-left': isExpanded && rightSide,
        })}
      >
        {keyFactsData().map(
          (data) => data.shouldShow && <KeyFactsOption key={data.modalKey} clickHandler={handleOptionClick} {...data} />
        )}
      </div>

      <PoliciesModal isRentalFlow={isRentalConfirmedFlow} />
      <PoliciesModal keyRentalFacts isRentalFlow={isRentalConfirmedFlow} />
    </div>
  );
};

KeyFactsDropDown.propTypes = {
  openModal: PropTypes.func,
  policies: PropTypes.array,
  shouldShowKeyFacts: PropTypes.bool,
  rightSide: PropTypes.bool,
};

KeyFactsDropDown.defaultProps = {
  rightSide: false,
};

export default KeyFactsDropDown;
