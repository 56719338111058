import React from 'react';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import utils from 'utils';

/**
 * LearnMoreAboutOCIInactiveModal - Show a little description of what is accelerated check-in
 * @return {JSX} LearnMoreAboutOCIInactiveModal component
 */

const LearnMoreAboutOCIInactiveModal = () => (
  <Modal
    modalKey={MODAL.LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_INACTIVE_MODAL}
    customClass='oci-stc-modal'
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('learn_more_about_accelerated_checkin_modal_title')}>
    <>
      <p>
        {utils.i18n('trip_card_accelerated_checkin_description')}
        <br />
        {utils.i18n('confirmation_oci_check_in_show_more_info')}
      </p>

      <ul>
        <li>{utils.i18n('confirmation_oci_check_in_show_more_item_1')}</li>
        <li>{utils.i18n('confirmation_oci_check_in_show_more_item_2')}</li>
        <li>{utils.i18n('confirmation_oci_check_in_show_more_item_3')}</li>
      </ul>
    </>
  </Modal>
);

export default LearnMoreAboutOCIInactiveModal;
