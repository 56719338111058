import { connect } from 'react-redux';
import utils from 'utils';
import { sessionReservationObjectSelector } from 'selectors';
import { clearDiscountInReservationFlow } from "actions/reservation/clearDiscountInReservationFlow";
import RemoveCIDModalContent from './RemoveCIDModalContent';

const mapStateToProps = state => {
  const gmiReservationState = sessionReservationObjectSelector(state);
  return {
    contract_name: gmiReservationState?.getIn(['contract_details', 'contract_name']),
  };
};

const mapDispatchToProps = {
  clearDiscountInReservationFlow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(RemoveCIDModalContent));
