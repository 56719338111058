import React from 'react';
import utils from 'utils';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL_BUTTON_TYPES } from 'constants/modals';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import ANALYTICS from 'constants/analytics';
import { SOURCE_CODE } from 'constants/urlParam';

const DealsEmailSpecialsModalContent = () => (
  <div className='modal-content__inner padding-top--condensed'>
    <p>{utils.i18n('deals_email_specials_modal_sign_up')}</p>
    <ModalFooter
      fullWidthButton
      disableStickyFooter
      buttons={[
        {
          type: MODAL_BUTTON_TYPES.DECLINE,
          label: utils.i18n('deals_email_specials_modal_no_button'),
          analyticsKey: ANALYTICS.EMAIL_SIGNUP_MODAL,
          analyticsValue: ANALYTICS.NO_THANKS_SIGNUP_MODAL,
        },
        {
          type: MODAL_BUTTON_TYPES.ACCEPT,
          label: utils.i18n('deals_email_specials_modal_sign_up_button'),
          handler: () => {
            const searchParams = new URLSearchParams();
            searchParams.set(SOURCE_CODE, utils.config.getDealsModalSourceCode());
            utils.loadingRedirect(WINDOW_OBJECT_KEYS.EMAIL_SPECIALS, true, null, searchParams);
          },
          analyticsHandler: () =>
            utils.analytics.interaction(ANALYTICS.MODAL, ANALYTICS.EMAIL_SIGNUP_MODAL, ANALYTICS.SIGN_UP),
          // The handler above can be removed when the bug with the modal buttons' link type is fixed
          // These are not used because analytics are not tracked for links due to the bug listed above
          analyticsKey: ANALYTICS.EMAIL_SIGNUP_MODAL,
          analyticsValue: ANALYTICS.SIGN_UP,
        },
      ]}
    />
  </div>
);
export default DealsEmailSpecialsModalContent;
