import PropTypes from 'prop-types';

export const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  errorMessage: PropTypes.string,
};
