import React from 'react';
import PropTypes from 'prop-types';
import Picture from 'components/Picture';
import utils from 'utils';
import ASSETS from 'constants/assets';
import Button from 'components/Button';

/**
 * STCConfirmationPickupInstructions - Skip the Counter Pickup Instructions
 * @param {object} props - React Props
 * @param {function} props.openViewTicketModal - Toggle flag to open the wayfinding modal
 * @param {string} props.paymentCardType - Credit Card type
 * @param {string} props.paymentCardNumber - Credit Card number
 * @param {function} props.onPrintConfirmation - Trigger to print the page
 * @return {JSX}
 */
const STCConfirmationPickupInstructions = ({
  paymentCardType,
  paymentCardNumber = '',
  noImg,
  scrollToLocationTermsAndConditions,
  isTourVoucherReservation,
}) => {
  let paymentCardTypeLabel = paymentCardType;
  if (isTourVoucherReservation) {
    paymentCardTypeLabel = '';
  }

  return (
    <div className='skip-the-counter-confirmation__section-pickup-instructions-block'>
      {!noImg && (
        <Picture
          className='skip-the-counter-confirmation__section-pickup-instructions-block-image'
          src={ASSETS.PICKUP_INSTRUCTIONS}
          alt={utils.i18n('stc_pickup_instructions')}
        />
      )}

      <div className='skip-the-counter-confirmation__section-pickup-instructions-block-list'>
        <span className='skip-the-counter-confirmation__sub-heading'>{utils.i18n('stc_make_sure_bring')}</span>

        <ul className='skip-the-counter-confirmation__section-list skip-the-counter-confirmation__section-list-bring-items'>
          <li>
            {utils.i18n('stc_pickup_ticket_label')} ({utils.i18n('stc_show_ticket_on_phone')})
          </li>
          <li>{utils.i18n('stc_credit_card_label', [paymentCardTypeLabel, `******${paymentCardNumber}`])}</li>
          <li>
            {utils.i18n('stc_driver_license_drivers')}{' '}
            <Button link onClick={scrollToLocationTermsAndConditions} className='skip-the-counter-confirmation__link'>
              {utils.i18n('stc_view_license_requirements')}
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

STCConfirmationPickupInstructions.propTypes = {
  openViewTicketModal: PropTypes.func,
  paymentCardType: PropTypes.string,
  paymentCardNumber: PropTypes.string,
  onPrintConfirmation: PropTypes.func,
  noImg: PropTypes.bool,
  scrollToLocationTermsAndConditions: PropTypes.func,
  isTourVoucherReservation: PropTypes.bool,
};

export default STCConfirmationPickupInstructions;
