import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import { config } from 'utils/config';
import { safeToJS } from 'utils/immutable';
import { dispatchEventWithDOM } from 'utils/analytics';
import FORMS from 'constants/forms';
import MODAL from 'constants/modals';
import { getPartnerRewardsPrograms, getPRPSurchargeContent } from 'actions/partnerRewardsProgram';
import {
  getAvailablePartnerRewardsPrograms,
  reservationPartnerRewardsProgramSelector,
  getSurchargeContent,
} from 'selectors/partnerRewardsSelectors';
import { isVirginReservationSelector, isRestrictedReservationSelector } from 'selectors/customPathCheckInSelectors';
import Button from 'components/Button';
import ResponsiveListbox from 'components/Form/ResponsiveListbox';
import TextField from 'components/Form/TextField';
import LoadingWidget from 'components/LoadingWidget';
import { openModal } from "actions/modal/openModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";

const virginAtlanticFlyingClubCode = 'VS';

/**
 * PostResFrequentTravelInfo
 * Handles adding or updating partner rewards program in a reservation
 *
 * @return {JSX} EditProfilePRPModal jsx component
 */
const PostResFrequentTravelInfo = () => {
  const partnerRewardsProgramsList = useSelector(getAvailablePartnerRewardsPrograms);
  const reservationPartnerRewardsProgram = useSelector(reservationPartnerRewardsProgramSelector);
  const surchargeContent = useSelector(getSurchargeContent);
  const isRestrictedReservation = useSelector(isRestrictedReservationSelector);
  const isVirginReservation = useSelector(isVirginReservationSelector);

  const getPartnerRewardsProgramsAction = useActions(getPartnerRewardsPrograms);
  const getPRPSurchargeContentAction = useActions(getPRPSurchargeContent);
  const openLearnMore = useActions(
    () => openModalWithProps(MODAL.FREQUENT_TRAVELER_SURCHARGE_MODAL, { surchargeContent }),
    [surchargeContent]
  );
  const openVirginFlightClubModal = useActions(() => openModal(MODAL.VIRGIN_ATLANTIC_FLIGHT_CLUB_MODAL));

  const [showMemberIdField, setShowMemberIdField] = useState(false);
  const [formExpanded, setFormExpanded] = useState(!!reservationPartnerRewardsProgram);
  const [isDisplayOnly, setIsDisplayOnly] = useState(!!reservationPartnerRewardsProgram); // If the reservation already has partner rewards, we show it in a "display mode"

  const domainCountryCode = config.getDomainCountry();
  const defaultOption = {
    label: i18n('my_profile_edit_prp_modal_program_none_option'),
    value: FORMS.NO_SELECTION,
  };
  const availablePrograms = partnerRewardsProgramsList?.[domainCountryCode]?.partner_rewards_programs;
  const selectOptions = isVirginReservation
    ? availablePrograms?.filter(option => option.code === virginAtlanticFlyingClubCode)
    : availablePrograms;
  const formattedOptions = selectOptions?.map(program => ({ label: program.name, value: program.code })) || [];

  useEffect(() => {
    // Load available Partner Rewards Programs if not present
    if (gmiUtils.isObjectEmpty(partnerRewardsProgramsList)) {
      getPartnerRewardsProgramsAction(domainCountryCode);
    }
  }, []);

  useEffect(() => {
    // Load available Partner Rewards Program Surcharge content if not present
    if (gmiUtils.isObjectEmpty(safeToJS(surchargeContent))) {
      getPRPSurchargeContentAction();
    }
  }, []);

  const handleCodeChange = e => {
    // If user selects Partner Rewards Program (option 'None'), then hide Member ID and Surcharge fields.
    if (e) {
      setShowMemberIdField(e !== FORMS.NO_SELECTION);
      dispatchEventWithDOM('FrequentTravelerAdded');
    }
  };

  const toggleEdit = () => {
    setFormExpanded(true);
    setIsDisplayOnly(false);
  };

  return (
    <div className='post-res__content theme--light'>
      <div className='post-res__section-header'>
        <h3 className='post-res__section-title'>
          {i18n('review_frequent_travel_form_title')}
          <span className='post-res__title__optional-indicator'>{`${i18n('common_optional')}`}</span>
        </h3>
        {isDisplayOnly && (
          <Button onClick={toggleEdit} className='post-res__link-text post-res__link-text--inline' linkText>
            {i18n('common_edit')}
          </Button>
        )}
        {!isDisplayOnly && isRestrictedReservation && surchargeContent && (
          <Button key={0} linkText onClick={openLearnMore} className='post-res__link-text'>
            {i18n('common_learn_more')}
          </Button>
        )}
      </div>

      {isDisplayOnly ? (
        <p className='post-res__paragraph'>
          {reservationPartnerRewardsProgram?.programName} {reservationPartnerRewardsProgram?.memberId}
        </p>
      ) : (
        <>
          {isVirginReservation && (
            <p className='post-res__paragraph post-res__paragraph--margin-bottom'>
              {i18n(
                'review_frequent_travel_virgin_form_description',
                [
                  <Button key={0} linkText onClick={openVirginFlightClubModal} className='post-res__link-text'>
                    {`${i18n('virgin_atlantic_flying_club')}?`}
                  </Button>,
                ],
                { jsx: true }
              )}
            </p>
          )}
          {!formExpanded && (
            <Button onClick={() => setFormExpanded(true)} className='post-res-flight-info__add-button' button={false}>
              {i18n('review_frequent_travel_add_button')}
            </Button>
          )}
          {formExpanded &&
            (availablePrograms ? (
              <>
                <div className='post-res-form__field-row'>
                  <ResponsiveListbox
                    name='partner_reward_program_code'
                    onChange={handleCodeChange}
                    options={[defaultOption, ...formattedOptions]}
                    label={i18n('review_frequent_travel_partner_input_label')}
                  />
                  {showMemberIdField && (
                    <TextField
                      name='partner_reward_program_member_id'
                      label={i18n('review_frequent_travel_number_input_label')}
                      initialValueButton={reservationPartnerRewardsProgram?.number}
                      fill
                      required
                    />
                  )}
                </div>
              </>
            ) : (
              <LoadingWidget />
            ))}
        </>
      )}
    </div>
  );
};

export default PostResFrequentTravelInfo;
