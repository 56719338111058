import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import { RESERVATIONS } from 'constants';
import { travelIndicatorIsFlightSelector } from 'selectors/reservationSelectors';
import Button from 'components/Button';
import ResponsiveListbox from 'components/Form/ResponsiveListbox';
import TextField from 'components/Form/TextField';
import FieldGroupCard from '../FieldGroupCard';

/**
 * Component for Review Page were user can add the flight information to the reservation
 *
 * @param {object}    props - React Props
 * @param {boolean}   props.isAfterHoursPickup - Flag if the pickup is set for a after hour for the selected location
 * @param {boolean}   props.airlineNotListed - Flag for when the user's flight airline is not in the list
 * @param {boolean}   props.hasNoFlight - Flag for user that have no flight
 * @param {function}  props.handleSetAirline - Function to set the Airline info to the reservation
 * @param {boolean}   props.addFlightInfo - Flag to show Flight Informations form
 * @param {array}     props.airlineOptions - List of airlines options
 * @param {func}      props.curriedUpdateCommitState - Curried function to update the commit state
 * @param {func}      props.handleToggleFlightInfo - Handler to toggle the `addFlightInfo` flag
 * @param {boolean}   props.isMultiTerminalAirport - Flag for airports with multiple Alamo locations
 * @return {JSX}      AirlineDetailsCard component
 */
const AirlineDetailsCard = ({
  isAfterHoursPickup,
  airlineNotListed,
  hasNoFlight,
  handleSetAirline,
  addFlightInfo,
  airlineOptions,
  curriedUpdateCommitState,
  handleToggleFlightInfo,
  isMultiTerminalAirport,
}) => {
  const isFlightTravelIndicator = useSelector(travelIndicatorIsFlightSelector);

  // Expand the form by default if the addFlightInfo tag is true or if the location is
  // a multi terminal airport or is a after hours pickup or if it's a Flight travel indicator
  const isRequired = isAfterHoursPickup || isMultiTerminalAirport || isFlightTravelIndicator;
  const isFormExpanded = addFlightInfo || isRequired;
  // Also change the form description text if the location is multi terminal
  const formDescription = isMultiTerminalAirport
    ? 'review_flight_info_form_description_multi_terminal'
    : 'review_flight_info_form_description';

  return (
    <FieldGroupCard
      title={utils.i18n('review_flight_info_form_title')}
      optionalLabel={!isRequired}
      completeWhenFilled
      showComplete={addFlightInfo && (hasNoFlight || airlineNotListed)}>
      <div className='review__flight-information'>
        <p className='review__flight-information__description'>
          {!airlineNotListed && utils.i18n(formDescription)}
          {airlineNotListed && (
            <>
              {utils.i18n('review_flight_info_airline_not_listed_selected_label')}{' '}
              <b>
                {utils.i18n(
                  hasNoFlight ? 'review_flight_info_dont_have_flight' : 'review_flight_info_airline_not_listed'
                )}
                {'.'}
              </b>
              <a className='link link--text' onClick={handleSetAirline(null)}>
                {utils.i18n('review_flight_info_airline_not_listed_edit')}
              </a>
            </>
          )}
        </p>
        {!isFormExpanded && (
          <Button button={false} className='add-button' onClick={handleToggleFlightInfo}>
            {utils.i18n('review_flight_info_add_flight_button')}
          </Button>
        )}
        {isFormExpanded && !airlineNotListed && (
          <>
            <div className='review__section-card__field-row'>
              <ResponsiveListbox
                name='airline_information.code'
                label={utils.i18n('review_flight_info_airline_name_input_label')}
                onChange={curriedUpdateCommitState('code')}
                options={airlineOptions}
                fill
                required={true}
              />
              <TextField
                name='airline_information.flight_number'
                label={utils.i18n('review_flight_info_flight_number_input_label')}
                onChange={curriedUpdateCommitState('flight_number')}
                fill
                required={true}
              />
            </div>
            <div className='review__flight-information__actions-group'>
              <Button link className='link--text' onClick={handleSetAirline(RESERVATIONS.AIRLINE_NO_FLIGHT)}>
                {utils.i18n('review_flight_info_dont_have_flight')}
              </Button>
              <Button link className='link--text' onClick={handleSetAirline(RESERVATIONS.AIRLINE_NO_AIRLINE)}>
                {utils.i18n('review_flight_info_airline_not_listed')}
              </Button>
            </div>
          </>
        )}
      </div>
    </FieldGroupCard>
  );
};

AirlineDetailsCard.propTypes = {
  isAfterHoursPickup: PropTypes.bool,
  airlineNotListed: PropTypes.bool,
  hasNoFlight: PropTypes.bool,
  handleSetAirline: PropTypes.func,
  addFlightInfo: PropTypes.bool,
  airlineOptions: PropTypes.array,
  curriedUpdateCommitState: PropTypes.func,
  handleToggleFlightInfo: PropTypes.func,
  isMultiTerminalAirport: PropTypes.bool,
};

export default AirlineDetailsCard;
