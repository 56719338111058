import React from 'react';
import PropTypes from 'prop-types';
import { initiateLocationSelector } from 'selectors/progressBarSelectors';
import { gmaInitiateLocationsSelector, getModifyFlag } from 'selectors/reservationSelectors';
import { getReservationInitiateError } from 'selectors/errorSelectors';
import { useSelector } from 'react-redux';
import cn from 'classnames';

const ProgressBarLocation = ({ currentStep, config, match }) => {
  const values = useSelector(initiateLocationSelector);
  const isTrueModify = useSelector(getModifyFlag);
  const reservationInitiateError = useSelector(getReservationInitiateError);
  const pickup_return_LocationInitiateRequest = useSelector(gmaInitiateLocationsSelector);

  const { pickupLocation, returnLocation, oneWayRental } = values;
  const showReturnLocation = match && match.path === config.paths[1];

  let pickupLocationName = pickupLocation && pickupLocation?.name;
  let returnLocationName = returnLocation && returnLocation?.name;

  if (isTrueModify && reservationInitiateError.length > 0) {
    pickupLocationName = pickup_return_LocationInitiateRequest?.pickupLocation.name;
    returnLocationName =
      pickup_return_LocationInitiateRequest?.oneWayRental && pickup_return_LocationInitiateRequest?.returnLocation.name;
  }

  return (
    <span className={cn('section__values location', { 'one-way-rental': oneWayRental })}>
      {pickupLocation && (
        <span className={cn('section__value pickup-location', { active: currentStep && !showReturnLocation })}>
          {pickupLocationName}
        </span>
      )}
      {oneWayRental && returnLocation && (
        <span className={cn('section__value return-location', { active: currentStep && showReturnLocation })}>
          {returnLocationName}
        </span>
      )}
    </span>
  );
};

ProgressBarLocation.propTypes = {
  currentStep: PropTypes.bool,
  match: PropTypes.object,
  config: PropTypes.object,
};

export default ProgressBarLocation;
