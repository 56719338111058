import React, { Fragment } from 'react';
import utils from 'utils';
import { RESERVATIONS } from 'constants';
import FormattedPrice from 'components/FormattedPrice';
import { propTypes } from './progressBarExtrasPropTypes';

const ProgressBarExtrasMobile = ({
  includedExtrasAndDriversSelector,
  addedExtras,
  selectedBundle,
  numberOfExtrasSelected,
  addedExtrasAmount,
  bundledAdditionalDrivers,
}) => (
  <>
    {!numberOfExtrasSelected ? (
      <div className='section__value'>{utils.i18n('rental_details_extras_empty')}</div>
    ) : (
      <>
        {!!includedExtrasAndDriversSelector.length && (
          <div className='section__value-label'>
            {utils.i18n('progress_bar_mobile_included_extras_label', [includedExtrasAndDriversSelector.length])}
          </div>
        )}

        {includedExtrasAndDriversSelector?.map(({ code, name, showCount, selected_quantity }) => (
          <div key={code} className='section__value-group'>
            <div>
              {name}
              {showCount ? ` (x${selected_quantity})` : ''}
            </div>
          </div>
        ))}

        {(!!addedExtras.length || selectedBundle) && (
          <div className='section__value-label'>
            {utils.i18n('progress_bar_mobile_added_extras_label', [addedExtrasAmount])}
          </div>
        )}

        {selectedBundle && (
          <>
            <div>{selectedBundle.title}</div>
            <div className='section__bundle-group'>
              {selectedBundle.bundleExtras.map(({ name, total_amount_view, status, code }) => (
                <Fragment key={code}>
                  <div className='bundle-group__extra-title'>{name}</div>
                  {status !== RESERVATIONS.EXTRAS_INCLUDED_STATUS && (
                    <div>
                      <FormattedPrice>{utils.getFormattedPrice(total_amount_view)}</FormattedPrice>
                    </div>
                  )}
                </Fragment>
              ))}
              {bundledAdditionalDrivers && (
                <Fragment key='addt_drv'>
                  <div className='bundle-group__extra-title'>
                    {utils.i18n('bundles_card_additional_drivers', [bundledAdditionalDrivers])}
                  </div>
                </Fragment>
              )}
            </div>
          </>
        )}

        {addedExtras?.map(
          ({ name, rate_type, rate_amount_view, total_amount_view, status, selected_quantity, code }) => (
            <div key={code} className='section__value-group'>
              <div>
                {name}
                {selected_quantity > 1 ? ` (x${selected_quantity})` : null}
                {status === RESERVATIONS.EXTRAS_MANDATORY_STATUS && (
                  <span className='extras-drawer-details-section-list__item--mandatory'>
                    &nbsp;({utils.i18n('extras_status_MANDATORY')})
                  </span>
                )}
              </div>
              <div>
                {status === RESERVATIONS.EXTRAS_WAIVED_STATUS ? (
                  utils.i18n('extras_status_WAIVED')
                ) : (
                  <>
                    {`${utils.getFormattedPrice(total_amount_view)} `}
                    {!!rate_type &&
                      utils.i18n(
                        `progress_bar_extras_drawer_added_extras_rate_${rate_type}`,
                        [<FormattedPrice key='0'>{utils.getFormattedPrice(rate_amount_view)}</FormattedPrice>],
                        { jsx: true }
                      )}
                  </>
                )}
              </div>
            </div>
          )
        )}
      </>
    )}
  </>
);

ProgressBarExtrasMobile.propTypes = propTypes;

export default ProgressBarExtrasMobile;
