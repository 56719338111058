import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { breakpointSelector } from 'selectors/breakpoint';
import { MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';

const { ACCEPT } = MODAL_BUTTON_TYPES;

const EmailUpdatedSuccessModal = () => {
  const breakpoints = useSelector(breakpointSelector);
  const { isMobile } = breakpoints;

  return (
    <Modal
      modalKey={MODAL.EMAIL_UPDATE_SUCCESS}
      header={i18n('email_update_success_title')}
      theme={MODAL_THEMES.WHITE}
      customClass='update-email-address-modal'
      showHeaderTextIcon
      headerTextIcons={'checkmark-narrow'}
      headerIconBgColor={'modal-green'}
      handleModalQueue={{ skipAnalytics: true }}
    >
      {({ handleClose }) => (
        <div className='update-email-address-modal__success-container'>
          <ModalFooter
            fullWidthButton
            stickyFooterCTA={isMobile}
            buttons={[
              {
                type: ACCEPT,
                extraLargeButton: true,
                label: i18n('common_close'),
                handler: () => handleClose({ skipAnalytics: true }),
              },
            ]}
          />
        </div>
      )}
    </Modal>
  );
};

EmailUpdatedSuccessModal.propTypes = {
  handleClose: PropTypes.func,
};

export default EmailUpdatedSuccessModal;
