import React from 'react';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import useActions from 'hooks/useActions';
import * as checkinActions from 'actions/checkin';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * ExitCheckInFlowModal - Modal shown to the user trying to exit the OCI Flow.
 *  Prompts the user to either save or discard their info before redirecting them.
 *  The action that causes the final redirect should be passed as a prop
 *  to the action that opens this modal (see `openModalWithProps`).
 *
 * @return {JSX}
 */
const ExitCheckInFlowModal = () => {
  const { checkinAbort, checkinCommit } = useActions(checkinActions);

  const discardHandler = onExit => () => {
    checkinAbort({ redirect: false }).then(utils.runOnSuccessResponse(onExit));
  };

  const saveHandler = onExit => () => {
    checkinCommit(null, { redirect: false }).then(utils.runOnSuccessResponse(onExit));
  };

  return (
    <Modal
      modalKey={MODAL.EXIT_CHECK_IN_FLOW_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('check_in_exit_modal_title')}>
      {({ onExit }) => (
        <>
          <p className='modal-themed__paragraph'>{utils.i18n('check_in_exit_modal_content')}</p>

          <ModalFooter
            buttons={[
              {
                type: DECLINE,
                label: utils.i18n('check_in_exit_modal_discard_button'),
                handler: discardHandler(onExit),
                largeButton: true,
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(MODAL.EXIT_CHECK_IN_FLOW_MODAL),
                    ANALYTICS.DISCARD
                  ),
              },
              {
                type: ACCEPT,
                label: utils.i18n('check_in_exit_modal_save_button'),
                handler: saveHandler(onExit),
                largeButton: true,
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(MODAL.EXIT_CHECK_IN_FLOW_MODAL),
                    ANALYTICS.SAVE
                  ),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default ExitCheckInFlowModal;
