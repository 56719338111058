import React from 'react';
import utils from 'utils';
import { propTypes } from './RegionTileContentPropTypes';

const RegionTileContent = ({ countries, totalBranches, name, mapImage }) => (
  <section className='region-tile__content component-theme--light'>
    <aside className='region-tile__content__sidebar'>
      <img src={mapImage} alt={name} className='region-tile__content__sidebar__image' />
      <p className='region-tile__content__sidebar__count'>{totalBranches}</p>
      <p className='region-tile__content__sidebar__label'>{utils.i18n('region_mosaic_tile_sidebar_label', [name])}</p>
    </aside>
    <div className='region-tile__content__countries'>
      <h4 className='region-tile__content__countries__title'>{utils.i18n('region_mosaic_countries_served_title')}</h4>
      <ul className='region-tile__content__countries__list'>
        {Object.values(countries).map((country, idx) => (
          <li key={`${country.countryCode}${idx}`}>
            <a href='' className='link--arrow'>
              ${country.countryNameTranslation}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

RegionTileContent.propTypes = propTypes;

export default RegionTileContent;
