import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import VisHiddenText from 'components/VisHiddenText';

/**
 * PasswordRequirements:
 * A formspy component that must be placed inside of a FinalForm component
 * it validates the password field in comparison to the other 3 fields
 * it displays our password requirements and only shows X icons in certain situations
 *
 * @param {object} props - React Props
 * @param {string} props.password - name of the password field
 * @param {string} props.firstName - name of the firstName field
 * @param {string} props.lastName - name of the lastName field
 * @param {string} props.email - name of the email field
 * @param {function(valid: boolean)} props.setPasswordValid - setter for password requirements validity on the parent form
 *
 * @return {JSX} ...
 */
class PasswordRequirements extends Component {
  state = {
    hideXIcons: false,
    isReset: true,
    passwordActive: false,
    showRequirements: false,
    values: null,
  };

  PASSWORD_TESTS = [
    {
      validator: ({ password }) => password.length >= 8,
      i18n: 'password_requirements_minimum_length', // include at least 8 characters'
    },
    {
      validator: ({ password }) => password.match(/\D/g),
      i18n: 'password_requirements_contains_letter', // include at least 1 letter'
    },
    {
      validator: ({ password }) => password.match(/\d/g),
      i18n: 'password_requirements_contains_number', // include at least 1 number'
    },
    {
      validator: ({ password }) => !password.match(/\s/g),
      i18n: 'password_requirements_no_spaces', // cannot contain spaces'
    },
    {
      validator: ({ password, ...fields }) =>
        Object.keys(fields).every((field) => !password.toLowerCase().match(fields[field].toLowerCase())),
      i18n: this.props.email ? 'password_requirements_name_or_email' : 'password_requirements_name', // cannot contain your name or email address
    },
  ];

  // every subscribed form change triggers this function
  handleChange = (formState) => {
    this.setState((prevState) => {
      const { password, firstName, lastName, email, setPasswordValid } = this.props;
      const { isReset, passwordActive, showRequirements } = prevState;
      const { active, values, visited } = formState;
      const nextState = {};

      // the field has focus
      nextState.passwordActive = active === password;

      // default isReset to its previous value
      nextState.isReset = isReset;

      // only change isReset for these cases
      if (nextState.passwordActive && !values[password] && !!prevState.values[password]) {
        // user cleared the field
        nextState.isReset = true;
      } else if (passwordActive && !nextState.passwordActive && !!values[password]) {
        // user blurred the field
        nextState.isReset = false;
      }

      // if the password field ever received focus, show requirements
      nextState.showRequirements = showRequirements || visited[password];

      // hide the X icons if: there is no value, the field was reset
      nextState.hideXIcons = !values[password] || nextState.isReset;

      // remove untracked fields from form values to avoid false results
      const trackableFields = [password, firstName, lastName, email];
      const trackableValues = { ...values };
      Object.keys(values).forEach((fieldName) => {
        if (!trackableFields.includes(fieldName)) {
          delete trackableValues[fieldName];
        }
      });

      // track values in state to pass to validation
      nextState.values = trackableValues;

      // if submit failed and password exists, check if any validation failed to force an error on the form
      if (trackableValues.password) {
        const passwordValid = this.PASSWORD_TESTS.every(({ validator }) => validator(trackableValues));
        setPasswordValid(passwordValid);
      }

      return { ...nextState };
    });
  };

  render() {
    const { password } = this.props;
    const { showRequirements, hideXIcons, values } = this.state;
    const passwordValue = values?.[password];

    return (
      <>
        {showRequirements && (
          <div className='password-requirements'>
            <h6 className='password-requirements__heading'>{utils.i18n('password_requirements_heading')}</h6>
            <ul className='password-requirements__list'>
              {this.PASSWORD_TESTS.map(({ validator, i18n }) => {
                const passed = passwordValue && validator(values);
                const failed = !passed && !hideXIcons;
                const dashed = !passed && hideXIcons;
                let message = '';
                if (passed) {
                  message = ANALYTICS.PASSED;
                }
                if (failed) {
                  message = ANALYTICS.FAILED;
                }

                return (
                  <li className={cn('password-requirements__list-item', { passed, failed, dashed })} key={i18n}>
                    <VisHiddenText message={message} />
                    {utils.i18n(i18n)}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {/* hack for handleChange to get called  before render, referred from here https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
         */}
        <FormSpy
          onChange={(formState) => setTimeout(() => this.handleChange(formState), 0)}
          subscription={{ active: true, values: true, visited: true, submitFailed: true }}
        />
      </>
    );
  }
}

PasswordRequirements.propTypes = {
  password: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string, // not required
  setPasswordValid: PropTypes.func.isRequired,
};

/**
 * A function that is aware of "setPasswordValid"'s latest state (isPasswordValid), and returns a Final Form validation function.
 * The function is used to mark the Password field invalid, when Password Requirements are not met.
 * Apply this validation function to your form, or create your own custom version
 * 
 * example usage:
 * <Form
      onSubmit={this.handleSubmit}
      validate={customPasswordValidation(isPasswordValid)}
 */
export const customPasswordValidation = (isPasswordValid) => () => {
  const errors = {};

  if (!isPasswordValid) {
    errors.password = true;
  }

  return errors;
};

export default PasswordRequirements;
