import React, { Component } from 'react';
import utils from 'utils';
import Picture from 'components/Picture';
import LoadingWidget from 'components/LoadingWidget';
import { NO_REDIRECT } from 'constants/urlParam';
import { propTypes } from './deeplinkPropTypes';

class Deeplink extends Component {
  static propTypes = propTypes;

  componentDidMount() {
    const { deeplinkIntoReservationFlow } = this.props;

    const params = new URLSearchParams(location.search);
    const noRedirect = params.has(NO_REDIRECT);
    const options = {
      redirect: !noRedirect,
    };

    deeplinkIntoReservationFlow && location.search && deeplinkIntoReservationFlow(location.search, options);
  }

  render() {
    return (
      <div className='deep-link theme--light'>
        <Picture
          className='deep-link__logo'
          src={utils.config.getSiteLogoImage()}
          alt={utils.i18n('progress_bar_alamo_image_alt')}
        />
        <h1 className='deep-link__heading'>{utils.i18n('deeplink_header')}</h1>
        <p className='deep-link__copy'>{utils.i18n('deeplink_copy')}</p>
        <LoadingWidget />
      </div>
    );
  }
}

export default Deeplink;
