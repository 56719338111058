import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS, MODAL_BUTTON_TYPES } from 'constants';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT, SKIP } = MODAL_BUTTON_TYPES;

/**
 * renderFooter
 * Takes some parameters and returns a render prop passed into the Form components;
 * The render prop closure receives a `formSubmit` from react-final-form used on the save button.
 *
 * @param {boolean} isFinalStep - indicates whether this is the final step; this changes the "Save" button text
 * @param {boolean} showSkip - indicates whether the "skip for now" button should be shown
 * @param {function} addStep - increments the wizard modal step count - passed as handler to the "skip for now" button
 * @param {string} analyticsKey - a string used only for analytics that defined in `stepMap`
 *                                (`EditProfileWizardModal.js`)and passed down here.
 * @return {function} Render prop closure passed into the Form components
 */
const renderFooter = (isFinalStep, showSkip, addStep, analyticsKey) => formSubmit => {
  const saveLabel = isFinalStep ? 'my_profile_wizard_modal_save' : 'my_profile_wizard_modal_save_and_continue';
  const buttons = [];

  if (showSkip) {
    buttons.push({
      type: SKIP,
      label: utils.i18n('my_profile_wizard_modal_skip'),
      handler: addStep,
      analyticsHandler: () => utils.analytics.interaction(ANALYTICS.MODAL, analyticsKey, 'skip for now'),
    });
  }

  buttons.push({
    type: ACCEPT,
    label: utils.i18n(saveLabel),
    handler: formSubmit,
    analyticsHandler: () => utils.analytics.interaction(ANALYTICS.MODAL, analyticsKey, ANALYTICS.SAVE),
  });

  return <ModalFooter buttons={buttons} />;
};

/**
 * getRequiredFieldsDecorator
 * This returns a decorator function passed into the react-final-form instance.
 * A bit more info: https://final-form.org/docs/final-form/types/Decorator
 *
 * This decorator subscribes to the `errors` object, which is checked for `required` errors;
 * if such errors exist, the "skip for now" button should appear in the wizard modal.
 *
 * @param {function} setShowSkip - The setter for the "skip for now" button display status
 * @return {function} A decorator function passed into <Form />
 */
const getRequiredFieldsDecorator = setShowSkip => form => {
  // `form.subscribe` returns an unsubscribe function that should be returned by the decorator function
  const unsubscribe = form.subscribe(
    ({ errors }) => {
      setShowSkip(utils.fieldValidation.noRequiredErrors(errors));
    },
    {
      errors: true,
    }
  );
  return unsubscribe;
};

/**
 * EditProfileWizardModalContent
 * Handles showing the steps configured on EditProfileWizardModal
 *
 * @param {object} props - React Props
 * @param {object} props.scrollToModalTop - Prop passed from Modal - scrolls to the top of the modal content
 * @param {object} props.stepConfig - Prop passed from EditProfileWizardModal - Object with steps logic state and functionality;
 *  - for stepConfig properties' description, check the `useWizardSteps` hook docs return.
 * @return {JSX} EditProfileWizardModalContent jsx component
 */
const EditProfileWizardModalContent = ({ scrollToModalTop, stepsConfig }) => {
  // state to verify whether "skip for now" button should show
  const [showSkip, setShowSkip] = useState(false);
  const validate = useRef(getRequiredFieldsDecorator(setShowSkip));

  const { steps, currentStep, addStep, isFinalStep, resetSteps, previousStepData } = stepsConfig;
  const { analyticsKey, text, Form, onBeforeSubmit } = steps[currentStep - 1];

  // should reset steps if the modal is no longer opened
  useEffect(() => {
    resetSteps();
  }, []);

  return (
    <>
      <p className='my-profile__modal-form__description'>
        <span className='my-profile__modal-form__step-label'>
          {utils.i18n('my_profile_wizard_modal_steps', [currentStep, steps.length])}
        </span>
        {text}
      </p>
      <Form
        onSubmitSuccess={addStep}
        onBeforeSubmit={onBeforeSubmit}
        previousStepData={previousStepData}
        footer={renderFooter(isFinalStep, showSkip, addStep, analyticsKey)}
        decorators={[validate.current]}
        scrollToModalTop={scrollToModalTop}
      />
    </>
  );
};

EditProfileWizardModalContent.propTypes = {
  scrollToModalTop: PropTypes.func,
  stepsConfig: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.object),
    setSteps: PropTypes.func,
    currentStep: PropTypes.number,
    setCurrentStep: PropTypes.func,
    addStep: PropTypes.func,
    isFinalStep: PropTypes.bool,
    resetSteps: PropTypes.func,
  }),
};

export default EditProfileWizardModalContent;
