import PropTypes from 'prop-types';

export const propTypes = {
  // from ServiceErrorHandlerHoc
  serviceErrorsController: PropTypes.shape({
    serviceErrorsArray: PropTypes.array,
    newError: PropTypes.bool,
  }),

  // Following are not used in ServiceErrors but control ServiceErrorHandlerHoc to filter errors and control error handling
  errorCodes: PropTypes.arrayOf(PropTypes.string),
  statePath: PropTypes.string,
  runAllHandlers: PropTypes.bool,
};
