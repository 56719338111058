import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import GenericDrawer from 'components/GenericDrawer/GenericDrawer';
import BranchHours from 'components/BranchHours';

const BranchLocationTimeDrawer = ({ id, hoursForStore, confirmation }) => {
  const formatToReadableTime = time => utils.gmi.getDateTimeObjFromTs(time, 'HH:m mm').format('LT');

  const formatHours = hoursObject => {
    if (!hoursObject) {
      return null;
    }

    const todaysHoursObject = hoursObject[utils.gmi.getCurrentDateTimeObj().format('YYYY-MM-DD')]?.STANDARD;
    // return null if todaysHoursObject is undefined
    if (!todaysHoursObject) {
      return null;
    }

    if (todaysHoursObject.hours.length === 0) {
      const { open24Hours, closed } = todaysHoursObject;
      if (closed) {
        return <p>{utils.i18n('location_finder_timetable_closed')}</p>;
      }
      if (open24Hours) {
        return utils.i18n('location_finder_timetable_open_24_hours');
      }
      return null;
    }
    return todaysHoursObject.hours.map(({ open, close }) => (
      <span key={`${open}-${close}`} className='branch-location-detail__hours-dot-item'>
        {`${formatToReadableTime(open)} ${utils.i18n('map_locations_between_time')}  ${formatToReadableTime(close)}`}
      </span>
    ));
  };

  const title = (
    <span>
      <span className='branch-location-detail__title'>{utils.i18n('branch_page_hours_title')}</span>
      <span className='branch-location-detail__hours-dot'>{formatHours(hoursForStore)}</span>
    </span>
  );

  return (
    <div className='branch-location-detail__divider branch-location-detail__drawer'>
      <GenericDrawer
        headerText={title}
        confirmation={confirmation}
        analyticsText={utils.i18n('branch_page_hours_title')}
        icon='generic-drawer__clock'>
        <BranchHours id={id} reservationFlow={false} hideTodaysTimes={true} />
      </GenericDrawer>
    </div>
  );
};

BranchLocationTimeDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  hoursForStore: PropTypes.object,
  confirmation: PropTypes.bool,
};

export default BranchLocationTimeDrawer;
