import PropTypes from 'prop-types';

export const propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bundle_value_view: PropTypes.object,
  upgrade_bundle_view: PropTypes.object,
  savings_view: PropTypes.object,
  isSelected: PropTypes.bool.isRequired,
  vehicle_rates: PropTypes.object.isRequired,
  includedBundlesExtras: PropTypes.object.isRequired,
  image_icon: PropTypes.object.isRequired,
};
