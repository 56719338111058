import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const LimitedInventoryModal = () => (
  <Modal
    modalKey={MODAL.LIMITED_INVENTORY_MODAL}
    header={utils.i18n('limited_inventory_modal_title')}
    theme={MODAL_THEMES.WHITE}>
    {({ handleAccept, handleClose }) => (
      <>
        <p>{utils.i18n('limited_inventory_modal_content')}</p>
        <ModalFooter
          buttons={[
            {
              type: DECLINE,
              label: utils.i18n('limited_inventory_modal_decline'),
            },
            {
              type: ACCEPT,
              label: utils.i18n('limited_inventory_modal_accept'),
              handler: () => {
                handleAccept();
                handleClose({
                  analyticsValue: ANALYTICS.LIMITED_INVENTORY,
                });
              },
            },
          ]}
        />
      </>
    )}
  </Modal>
);

export default LimitedInventoryModal;
