import React from 'react';
import utils from 'utils';
import propTypes from 'prop-types';
import RESERVATIONS from 'constants/reservations';
import ReceiptBlockPrices from './ReceiptBlockPrices';

/**
 * ReceiptRentalCharges - Receipt rental charges
 * @param {object} props - React Props
 * @param {object} props.receipt - Receipt information
 * @return {JSX}
 */
const ReceiptRentalCharges = ({ receipt }) => {
  const priceSummary = receipt?.price_summary;
  const paymentItems = priceSummary?.payment_line_items || {};

  const rentalRate = [
    ...(paymentItems?.VEHICLE_RATE || []).map(rate => ({
      ...rate,
      description: utils.formatTimeAndDistance(rate),
    })),
    ...(receipt.unlimited_mileage
      ? [
          {
            description: utils.i18n('receipt_rental_included_mileage'),
            value: utils.i18n('vehicle_select_extras_included'),
            status: RESERVATIONS.EXTRAS_INCLUDED_STATUS,
          },
        ]
      : []),
  ];

  const extras = [
    ...(paymentItems?.EQUIPMENT ? paymentItems.EQUIPMENT : []),
    ...(paymentItems?.COVERAGE ? paymentItems.COVERAGE : []),
  ];

  const charges = [
    { title: utils.i18n('vehicle_select_rental_rate'), data: rentalRate },
    { title: utils.i18n('extras_title_counter', [extras.length]), data: extras },
    { title: utils.i18n('receipt_rental_charges_misc'), data: paymentItems.MISCELLANEOUS },
    {
      title: utils.i18n('review_additional_drivers_count', [paymentItems.ADDITIONAL_DRIVER?.length]),
      data: paymentItems.ADDITIONAL_DRIVER,
    },
    { title: utils.i18n('vehicle_select_taxes_fees'), data: paymentItems.FEE },
    { title: utils.i18n('vehicle_select_adjustments'), data: paymentItems.SAVINGS },
  ];

  return (
    <table className='receipt-charges-table'>
      <caption>
        <h3 className='receipt__charges-title'>{utils.i18n('receipt_rental_charges_title')}</h3>
      </caption>
      <tbody>
        {charges.map(
          (charge, index) =>
            charge.data?.length > 0 && (
              <ReceiptBlockPrices
                key={charge.title}
                marginTop={index !== 0}
                title={charge.title}
                list={charge.data.map(item => ({
                  label: item.description,
                  value: utils.getFormattedPrice(item.total_amount_view, { status: item.status }),
                }))}
              />
            )
        )}

        <ReceiptBlockPrices
          className='charges-block-total'
          list={[
            {
              label: utils.i18n('receipt_rental_total_price'),
              value: receipt?.netrate_total_indicator
                ? utils.i18n('third_party_reservation_net_rate')
                : utils.gmi.get(priceSummary, 'estimated_total_view.format'),
            },
          ]}
        />
      </tbody>
    </table>
  );
};

ReceiptRentalCharges.propTypes = {
  receipt: propTypes.object,
};

export default ReceiptRentalCharges;
