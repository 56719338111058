import React, { Component } from 'react';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';
import { accordionTitlePropTypes, accordionTitleDefaultProps } from './AccordionListPropTypes';

class AccordionTitle extends Component {
  static propTypes = accordionTitlePropTypes;

  static defaultProps = accordionTitleDefaultProps;

  handleOnClick = () => {
    // Analytics
    utils.analytics.interaction(
      ANALYTICS.DRAWER,
      this.props.title,
      this.props.isActive ? ANALYTICS.CLOSE : ANALYTICS.OPEN
    );

    this.props.setActiveIndex(this.props.index);
  };

  render() {
    const { isActive, isVertical, title, titleId, contentId, analyticsTitle } = this.props;

    const titleClass = cn('tabs__title', {
      'tabs-horizontal__title--active': !isVertical && isActive,
      'tabs-vertical__title--active': isVertical && isActive,
    });

    const iconClass = cn('tabs__icon', {
      'tabs-vertical__icon': isVertical,
      'tabs-vertical__icon--active': isVertical && isActive,
    });

    return (
      <Button
        plain
        data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, analyticsTitle, title)}
        id={titleId}
        className={titleClass}
        onClick={this.handleOnClick}
        aria-label={title}
        aria-selected={isActive}
        aria-disabled={false}
        aria-controls={contentId}
        aria-expanded={isActive}
      >
        <span>{title}</span>
        <span className={iconClass} />
      </Button>
    );
  }
}

export default AccordionTitle;
