import React, { Component } from 'react';
import cn from 'classnames';
import utils from 'utils';
import Button from 'components/Button';
import FormattedPrice from 'components/FormattedPrice';
import Picture from 'components/Picture';
import { propTypes } from './bundlesCardPropTypes';

export default class BundlesCard extends Component {
  static propTypes = propTypes;

  handleClickCard = evt => {
    evt.preventDefault();
    const { name, onClickBundle, isSelected } = this.props;
    onClickBundle(name, isSelected);
  };

  handleViewDetails = (extras, bundledAdditionalDrivers) => evt => {
    evt.preventDefault();
    const { title, description, showBundlesDetailsModal, additionalDriversPolicy } = this.props;
    showBundlesDetailsModal(title, description, extras, bundledAdditionalDrivers, additionalDriversPolicy);
  };

  renderExtra = item => (
    <li key={item.code} className='bundles-card__extras__item'>
      {item.name}
      {item.selected_quantity && item.max_quantity > 1 ? ` (x${item.selected_quantity})` : null}
    </li>
  );

  render() {
    const {
      name,
      title,
      isSelected,
      image_icon,
      includedBundlesExtras,
      upgrade_bundle_view,
      bundle_value_view,
      savings_view,
      vehicle_rates,
    } = this.props;

    const upgradeBundle = utils.getFormattedPrice(upgrade_bundle_view);
    const savings = utils.getFormattedPrice(savings_view);
    const value = utils.getFormattedPrice(bundle_value_view);
    // AWR-4167 - Savings tag was temporarily disabled
    // const shouldRenderSavingTag = !!savings_view;
    const shouldRenderSavingTag = false;
    const bundledAdditionalDrivers =
      vehicle_rates?.supplemental?.additional_driver?.number_of_free_additional_drivers || null;

    const bundleImage = utils.getImageFromImagesObject({ image_icon }, { type: 'image_icon' });
    const extras = includedBundlesExtras[name];

    return (
      !!title &&
      !!extras.length && (
        <div
          className={cn('bundles-card__container', {
            'bundles-card__container--selected': isSelected,
            'bundles-card__container--no-savings': !shouldRenderSavingTag,
          })}>
          {shouldRenderSavingTag && (
            <div className='bundles-card__banner bundles-card__banner--tag'>
              <span>
                {utils.i18n('bundles_card_save_amount', [<FormattedPrice key='0'>{savings}</FormattedPrice>], {
                  jsx: true,
                })}
                &nbsp;
              </span>
              <span>
                {utils.i18n('bundles_card_value_amount', [<FormattedPrice key='0'>{value}</FormattedPrice>], {
                  jsx: true,
                })}
              </span>
            </div>
          )}
          <div className='bundles-card__title'>
            {!!bundleImage && (
              <Picture
                className='bundles-card__badge'
                src={bundleImage}
                alt={title}
                srcMobile={bundleImage}
                srcTablet={bundleImage}
              />
            )}
            <h3>{title}</h3>
            <Button
              onClick={this.handleViewDetails(extras, bundledAdditionalDrivers)}
              ariaText={`${name} ${utils.i18n('bundles_card_details_cta')}`}
              data-dtm-track={`extras_bundle|${title}:add`}
              linkText>
              {utils.i18n('bundles_card_details_cta')}
            </Button>
          </div>
          <div className='bundles-card__extras'>
            <ul>
              {extras && extras.map(item => this.renderExtra(item))}
              {bundledAdditionalDrivers && (
                <li className='bundles-card__extras__item'>
                  {utils.i18n('bundles_card_additional_drivers', [bundledAdditionalDrivers])}
                </li>
              )}
            </ul>
          </div>
          {isSelected ? (
            <Button
              className='bundles-card__price bundles-card__price--selected'
              data-dtm-track={`extras_bundle|${title}:remove`}
              link
              ariaText={`${utils.i18n('bundles_card_remove_cta')} ${title}`}>
              {utils.i18n('bundles_card_was_added_cta', [<FormattedPrice key='0'>{upgradeBundle}</FormattedPrice>], {
                jsx: true,
              })}
              <span onClick={this.handleClickCard} className='bundles_card__remove_cta'>{utils.i18n('bundles_card_remove_cta')}</span>
            </Button>
          ) : (
            <Button
              className='bundles-card__price formatted-price'
              onClick={this.handleClickCard}
              link
              data-dtm-track={`extras_bundle|${title}:add`}>
              {utils.i18n('bundles_card_add_cta', [<FormattedPrice key='0'>{upgradeBundle}</FormattedPrice>], {
                jsx: true,
              })}
            </Button>
          )}
        </div>
      )
    );
  }
}
