import React from 'react';
import Modal from 'components/Modal';
import { MODAL, MODAL_THEMES } from 'constants';
import { MODAL_BUTTON_TYPES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import { ModalFooter } from 'components/Modal/ModalFooter';
import utils from 'utils';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * CouponDoesNotApplyModal
 *
 * @returns {JSX}
 */
const CouponDoesNotApplyModal = () => {
  const acceptAndClose = (handleClose, continueWithoutCoupon) => () => {
    continueWithoutCoupon(true);
    handleClose({ analyticsValue: ANALYTICS.CONTINUE });
  };

  return (
    <Modal
      modalKey={MODAL.COUPON_DOES_NOT_APPLY}
      header={utils.i18n('coupon_does_not_apply_modal_title')}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose, continueWithoutCoupon }) => (
        <>
          <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
            {utils.i18n('coupon_does_not_apply_modal_description')}
          </p>

          <ModalFooter
            buttons={[
              {
                type: DECLINE,
                label: utils.i18n('common_cancel'),
                analyticsValue: ANALYTICS.CANCEL,
              },
              {
                type: ACCEPT,
                label: utils.i18n('common_continue'),
                handler: acceptAndClose(handleClose, continueWithoutCoupon),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default CouponDoesNotApplyModal;
