import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ANALYTICS from 'constants/analytics';
import { analyticsInteraction, addLoyaltyAuthenticatedInfo } from 'utils/analytics';
import useActions from 'hooks/useActions';
import MODAL from 'constants/modals';
import useOCIProfileInitialValues from 'hooks/useOCIProfileInitialValues';
import CheckInDriverLicenseForm from 'components/AcceleratedCheckInFlow/CheckInProfile/CheckInDriverLicenseForm';
import CheckInContactInformationForm from 'components/AcceleratedCheckInFlow/CheckInProfile/CheckInContactInformationForm';
import EditOciProfileForm from 'components/ReservationFlow/InResAcceleratedCheckin/EditOciProfileForm';
import Button from 'components/Button';
import {
  onlineCheckInProfileSelector,
  onlineCheckInStatusSelector,
  isUnauthUserWithLoyaltyProfileSelector,
} from 'selectors/checkinSelectors';
import { getUiStateEmail, getUiStatePhone } from 'selectors/uiStateSelectors';
import { authenticatedSelector } from 'selectors/profileSelectors';
import { openModal } from 'actions/modal/openModal';
import InResDriverDetailsRow from './InResDriverDetailsRow';

/**
 * InResProfileForm Component
 * In res OCI profile form rendering within In Res OCI wizard modal
 *
 * @param {object} props - React Props
 * @param {func} props.setEditing - handler to notify parent modal that user is editing forms. Used to control footer content
 * @param {func} props.resetSteps - callback passed to CheckInDriverLicenseForm to reset wizard modal steps to edit Drivers License
 * @param {func} props.onSubmitSuccess - callback passed by In Res OCI wizard modal, that runs wizard modal functionality
 * @param {func} props.renderFooter - function used to render Modal footer, takes the onSubmit handler as first param
 * @param {func} props.scrollToModalTop - handler to scroll parent modal, used to show errors if they pop up
 * @param {func} props.setHasConfirmed - function to set state on InResOciWizardModal to validate if should abortOCI on handleModalClose
 * @param {boolean} props.isFoundProfileWithConflictName - if it's an unauth loyalty profile found with different first name
 *
 * @return {JSX} InResProfileForm jsx component
 */
const InResProfileForm = ({
  resetSteps,
  editingLicense,
  editingContactInfo,
  isFoundProfileWithConflictName,
  form,
  setEditingLicenseInfo,
  setEditingContactInfo,
  isResOCIActivated,
  ociEligible,
  isSkippingTheCounter,
  isLastStep,
  isProfileCompleteConfirmed,
  updateInitialState,
  updateDriverProfileState,
}) => {
  const profile = useSelector(onlineCheckInProfileSelector);
  const { driverProfileComplete, contactProfileComplete } = useSelector(onlineCheckInStatusSelector);
  const isAuth = useSelector(authenticatedSelector);
  const commitStateEmail = useSelector(getUiStateEmail);
  const commitStatePhone = useSelector(getUiStatePhone);
  const isUnauthUserWithLoyaltyProfile = useSelector(isUnauthUserWithLoyaltyProfileSelector);
  const foundCompleteProfile = driverProfileComplete && contactProfileComplete;
  const openSignInModal = useActions(() => openModal(MODAL.SIGN_IN_MODAL));

  const toggleEditingLicense = () => setEditingLicenseInfo(!editingLicense);
  const toggleEditingContactInfo = () => setEditingContactInfo(!editingContactInfo);

  const handleEditProfile = (value) => {
    const key = value === 'driver' ? ANALYTICS.EDIT_DRIVER_LICENSE : ANALYTICS.EDIT_CONTACT_INFORMATION;
    analyticsInteraction(ANALYTICS.SUB_PAGE, key, ANALYTICS.OPEN);
    setEditingLicenseInfo(value);
    setEditingContactInfo(value);
  };

  const { first_name, last_name } = profile.basic_profile;

  const foundCompleteProfileButOciInEligible = foundCompleteProfile && !ociEligible && !isResOCIActivated;
  const checkInActivated = isResOCIActivated || ociEligible || foundCompleteProfileButOciInEligible;
  const showEditCheckin = checkInActivated && (!isAuth || !isSkippingTheCounter);

  const expeditedFlow = true;
  const initialValues = useOCIProfileInitialValues(profile, commitStateEmail, commitStatePhone, expeditedFlow);

  useEffect(() => {
    // Analytics
    let key = null;
    if (!foundCompleteProfile) {
      key = ANALYTICS.PROFILE_NOT_FOUND;
    }

    key && analyticsInteraction(ANALYTICS.SUB_PAGE, key, ANALYTICS.OPEN);
    addLoyaltyAuthenticatedInfo();
  }, [isProfileCompleteConfirmed]);

  return (
    <>
      {isFoundProfileWithConflictName && (
        <>
          <div className='check-in-flow__form__section-title'>
            <h4>{utils.i18n('common_name')}</h4>
          </div>
          <p className='check-in-flow__form__info-row'>
            {first_name} {last_name}
          </p>
        </>
      )}

      {editingContactInfo || editingLicense ? (
        <EditOciProfileForm form={form} />
      ) : (
        <div
          className={cn({
            'check-in-flow__inres-view-mode': driverProfileComplete && contactProfileComplete,
          })}
        >
          {((showEditCheckin && !isLastStep) || (isLastStep && foundCompleteProfile)) && (
            <InResDriverDetailsRow handleEditProfile={handleEditProfile} />
          )}
          {!showEditCheckin && (
            <CheckInDriverLicenseForm
              onEditLicense={driverProfileComplete ? toggleEditingLicense : resetSteps}
              updateInitialState={updateInitialState}
              hideEdit={isAuth && !driverProfileComplete}
              form={form}
            />
          )}
          {!showEditCheckin && (
            <CheckInContactInformationForm
              initialValues={initialValues}
              editContactInfo={toggleEditingContactInfo}
              updateInitialState={updateInitialState}
              updateDriverProfileState={updateDriverProfileState}
              form={form}
            />
          )}
        </div>
      )}
      {isUnauthUserWithLoyaltyProfile && (
        <div className='in-res-oci-modal__signin'>
          <h4>{utils.i18n('check_in_update_account_subtitle')}</h4>
          <p>
            {utils.i18n(
              'check_in_sign_in_cta',
              [
                <Button key={0} link linkText onClick={() => openSignInModal()}>
                  {utils.i18n('common_sign_in')}
                </Button>,
              ],
              { jsx: true }
            )}
          </p>
        </div>
      )}
    </>
  );
};

InResProfileForm.propTypes = {
  setEditing: PropTypes.func,
  resetSteps: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  renderFooter: PropTypes.func,
  scrollToModalTop: PropTypes.func,
  setHasConfirmed: PropTypes.func,
  isFoundProfileWithConflictName: PropTypes.bool,
};

export default InResProfileForm;
