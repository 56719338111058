import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles/link-modal-group.scss';
import { config } from 'utils/config';
import { i18n } from 'utils/i18n';
import {
  gmaBrandCaasTagsSelector,
  virginCaasTagsSelector,
  isVirginReservationSelector,
  isRestrictedReservationSelector,
  isCustomPathReservationSelector,
} from 'selectors/customPathCheckInSelectors';
import LinkModal from 'components/LinkModal';

/**
 * LinkModalGroupCustomPath - This component recieves caas tag in order to display link modal in OCI custom path.
 * The links are dependent on the type of reservation. The rules go as follow:
 * • For Retail Reservation - do not show link modals.
 * • For Virgin Restricted Reservation - show link modals, get the caas tags information from virginCaasTagsSelector.
 * • For Non-Virgin Restricted Reservation - show link modals only if gmaBrandCaasTags has the caas tag in the object.
 *
 * The component parses the object returned from gmaBrandCaasTagsSelector or virginCaasTagsSelector and generates one LinkModal per property
 *
 * @return {JSX}
 */
const LinkModalGroupCustomPath = () => {
  const gmaBrandCaasTags = useSelector(gmaBrandCaasTagsSelector);
  const virginBrandCaasTags = useSelector(virginCaasTagsSelector);
  const isVirginReservation = useSelector(isVirginReservationSelector);
  const isRestrictedReservation = useSelector(isRestrictedReservationSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const modals = config.getCaasTags();
  const [faqModal, setFaqModal] = useState(null);
  const [customerSupportModal, setCustomerSupportModal] = useState(null);

  const { virginFaqCaasTag, virginCostumerSupportCaasTag } = virginBrandCaasTags || {};
  const { brandFaqCaasTag, brandCustomerSupportCaasTag } = gmaBrandCaasTags || {};

  useEffect(
    () => {
      Object.keys(modals).forEach(modalKey => {
        if (modalKey.toLowerCase().indexOf('caas') === -1) {
          return;
        }
        if (isVirginReservation) {
          // If reservations is virgin reservation, then get the CaasTag from virginBrandCaasTags and map the tags to config.getCaasTags()
          virginFaqCaasTag === modalKey && setFaqModal({ faqJson: modals[modalKey], modalKey });
          virginCostumerSupportCaasTag === modalKey &&
            setCustomerSupportModal({ customerSupportJson: modals[modalKey], modalKey });
        } else if (isRestrictedReservation) {
          // If reservations is non-virgin restricted reservation, and gma has the caas tags then use gmaBrandCaasTags to map the tags to config.getCaasTags()
          brandFaqCaasTag === modalKey && setFaqModal({ faqJson: modals[modalKey], modalKey });
          brandCustomerSupportCaasTag === modalKey &&
            setCustomerSupportModal({ customerSupportJson: modals[modalKey], modalKey });
        }
      });
    },
    [modals]
  );

  // If is a Non-Virgin Restricted Reservation and there is no gmaBrandCaasTags or is Retail then dont show link modals
  if ((isRestrictedReservation && gmaBrandCaasTags === null) || !isCustomPathReservation) {
    return null;
  }

  return (
    <div className='link-modal-group'>
      <p className='link-modal-group__need-help'>{i18n('common_need_help')}</p>
      {faqModal && <LinkModal key={faqModal.modalKey} caasTag={faqModal.modalKey} modalCaasUrl={faqModal.faqJson} />}
      {customerSupportModal && (
        <LinkModal
          key={customerSupportModal.modalKey}
          caasTag={customerSupportModal.modalKey}
          modalCaasUrl={customerSupportModal.customerSupportJson}
        />
      )}
    </div>
  );
};

export default LinkModalGroupCustomPath;
