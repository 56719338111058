import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import FormattedPrice from 'components/FormattedPrice';
import { RESERVATIONS } from 'constants';

const { TAXES_FEES_INCLUDED_STATUS } = RESERVATIONS;

function getLineItemPriceOrStatus(total_amount_view, status, asterisk) {
  if (status === TAXES_FEES_INCLUDED_STATUS) {
    return utils.i18n('rental_details_status_included');
  }

  return utils.getFormattedPrice(total_amount_view, { asterisk });
}

const Savings = ({ priceSummary, asterisk, isThirdPartyReservation }) => {
  if (priceSummary?.payment_line_items?.SAVINGS) {
    return (
      <div className='rental-details__section rental-details__taxes'>
        <p className='rental-details__section-label'>
          <span>{utils.i18n('vehicle_select_total_savings')}</span>
        </p>
        <table className='rental-details__taxes-drawer print-visible visible'>
          <caption className='vis-hidden'>{utils.i18n('vehicle_select_total_savings')}</caption>
          <tbody>
            {priceSummary?.payment_line_items?.SAVINGS?.map(({ description, total_amount_view, status }, index) => (
              <tr className='rental-details__taxes-line-item-row' key={`${description}_${index}`}>
                <th align='left'>{description}</th>
                <td align='right'>
                  <FormattedPrice classNames='rental-details__line-item-price'>
                    {isThirdPartyReservation && (parseInt(total_amount_view, 10) === 0 || !total_amount_view)
                      ? utils.i18n('third_party_reservation_net_rate')
                      : getLineItemPriceOrStatus(total_amount_view, status, asterisk)}
                  </FormattedPrice>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

Savings.propTypes = {
  priceSummary: PropTypes.object,
  asterisk: PropTypes.bool,
  isThirdPartyReservation: PropTypes.bool,
};

export default Savings;
