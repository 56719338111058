import React, { Component } from 'react';
import utils from 'utils';
import { WINDOW_OBJECT_KEYS } from 'constants';

class RentingAtLocation extends Component {
  render() {
    return (
      <div className='help-suggestion'>
        <span className='help-suggestion__title'>{utils.i18n('help_suggestion_title')}</span>
        <a
          className='help-suggestion__link link--arrow'
          target='_blank'
          rel='noopener noreferrer'
          href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.FAQ_PAGE_URL)}>
          {utils.i18n('view_faqs')}
        </a>
      </div>
    );
  }
}

export default RentingAtLocation;
