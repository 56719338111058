import { connect } from 'react-redux';
import { getCurrentTrips, getPastTrips } from 'actions/trips';
import { breakpointSelector } from 'selectors/breakpoint';
import { pastTripsSelector, upcomingTripsSelector, currentTripsSelector } from 'selectors/tripsSelectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { profileDataSelector } from 'selectors/profileSelectors';
import MODAL from 'constants/modals';
import { openModal } from "actions/modal/openModal";
import MyTrips from './MyTrips';

const mapStateToProps = (state) => ({
  currentTrips: currentTripsSelector(state),
  upcomingTrips: upcomingTripsSelector(state),
  pastTrips: pastTripsSelector(state),
  breakpoint: breakpointSelector(state),
  profileData: profileDataSelector(state, { memberNumber: true }),
  isFetchingCurrentTrip: isFetchingEndpointSelector(state, { endpoint: 'trips/current' }),
  isFetchingPastTrips: isFetchingEndpointSelector(state, { endpoint: 'trips/past' }),
});
const mapDispatchToProps = {
  getCurrentTrips,
  getPastTrips,
  openMissingTripsModal: () => openModal(MODAL.MISSING_TRIPS_MODAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTrips);
