import React from 'react';
import utils from 'utils';
import Button from 'components/Button';
import propTypes from './datesTimesPropTypes';

const FORMAT = 'LLLL';

const renderViewDetailsButton = (showAHViewDetailsModal) =>
  showAHViewDetailsModal && (
    <Button link className='rental-details__after-hours-details-btn' onClick={showAHViewDetailsModal}>
      {utils.i18n('rental_details_after_hours_label_view_details')}
    </Button>
  );

const datesTimesTitle = utils.i18n('rental_details_dates_times');
const rentalDatesTimesTitle = utils.i18n('rental_details_return_location_date_time');
const DatesTimes = ({
  pickupTime,
  returnTime,
  handleEdit,
  labels = true,
  afterHoursPickup,
  afterHoursReturn,
  showAHViewDetailsModal,
  hideEditButtons,
  isTabletOrMobile,
  stepTwo,
  renterDetails = false,
  dateTimeInLowerCase,
}) => {
  let formatedPickupDateTime = utils.gmi.getDateTimeObjFromTs(pickupTime).format(FORMAT);
  let formatedReturnDateTime = utils.gmi.getDateTimeObjFromTs(returnTime).format(FORMAT);

  if (dateTimeInLowerCase) {
    formatedPickupDateTime = utils.gmi.getDateTimeObjFromTs(pickupTime).format(FORMAT).toLowerCase();
    formatedReturnDateTime = utils.gmi.getDateTimeObjFromTs(returnTime).format(FORMAT).toLowerCase();
  }

  return (
    <div className='rental-details__section rental-details__dates-times'>
      {labels && (
        <p className='rental-details__section-label'>
          <span>{renterDetails ? rentalDatesTimesTitle : datesTimesTitle}</span>
          {handleEdit && !stepTwo && (!hideEditButtons || isTabletOrMobile) && (
            <Button
              link
              onClick={handleEdit('start')}
              ariaText={utils.i18n('rental_details_edit_aria_label', [datesTimesTitle])}
              data-dtm-track={`rental_details|${utils.i18n('rental_details_dates_times')}|edit`}
            >
              {utils.i18n('rental_details_edit')}
            </Button>
          )}
        </p>
      )}

      {pickupTime && (
        <div className='rental-details__detail'>
          {labels && <p className='rental-details__key'>{utils.i18n('rental_details_pickup')}</p>}
          <p className='rental-details__value'>{formatedPickupDateTime}</p>
          {afterHoursPickup && (
            <p className='rental-details__after-hours-label rental-details__after-hours-label--pickup'>
              {utils.i18n('rental_details_after_hours_label_pickup')}
              {renderViewDetailsButton(showAHViewDetailsModal)}
            </p>
          )}
        </div>
      )}

      {returnTime && (
        <div className='rental-details__detail'>
          {labels && !renterDetails && <p className='rental-details__key'>{utils.i18n('rental_details_return')}</p>}
          <p className='rental-details__value'>{formatedReturnDateTime}</p>
          {afterHoursReturn && (
            <p className='rental-details__after-hours-label rental-details__after-hours-label--return'>
              {utils.i18n('rental_details_after_hours_label_return')}
              {renderViewDetailsButton(showAHViewDetailsModal)}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

DatesTimes.propTypes = propTypes;

export default DatesTimes;
