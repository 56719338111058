import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { checkinAbort } from 'actions/checkin';
import { hasGmaErrorMessagesSelector } from 'selectors/errorSelectors';
import { breakpointSelector } from 'selectors/breakpoint';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import useActions from 'hooks/useActions';
import useLoadingOverlay from 'hooks/useLoadingOverlay';
import ServiceErrors from 'components/ServiceErrors';
import Button from 'components/Button';
import PostResRentalInfoLine from 'components/PostRes/PostResRentalInfoLine';
import PostResLayout from 'components/PostRes/PostResLayout';
import LinkModalGroupCustomPath from 'components/LinkModalGroupCustomPath';
import CheckInStepInfo from './CheckInStepInfo';

/**
 * Check-in Template Component
 * Wrapper for the accelerated check-in flow SPA pages that share the same layout.
 *
 * @param {object} props - React Props
 * @param {object} props.nextButton - "Next"/"Confirm" button properties
 * @param {string} props.nextButton.label - Button label (defaults to "Next")
 * @param {function} props.nextButton.action - Function called when the button is pressed - usually a form submit
 * @param {string} props.nextButton.dtmTrack - DTM attribute for next/submit button
 * @param {array|node} props.children - JSX Components passed onto this component to be rendered within it
 *
 * @return {JSX} CheckInTemplate jsx component
 */
const CheckInTemplate = ({ nextButton, children }) => {
  const hasServiceErrors = useSelector(hasGmaErrorMessagesSelector);
  const breakpoints = useSelector(breakpointSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const checkinAbortAction = useActions(checkinAbort);

  const { isTabletOrMobile } = breakpoints;

  useLoadingOverlay({ endpoint: 'session/current' });

  return (
    <PostResLayout>
      <h1 className='check-in-template__heading'>{i18n('check_in_title')}</h1>
      <PostResRentalInfoLine />

      <section className='check-in-template__wrapper'>
        <CheckInStepInfo />

        <section className='check-in-template__content-area'>
          {/* the '* Indicates required field' note is hidden when service errors are displayed */}
          {!hasServiceErrors && (
            <span className='check-in-template__required-note' aria-hidden={true}>
              {i18n('check_in_required_fields_note')}
            </span>
          )}
          <ServiceErrors runAllHandlers />
          <article className='check-in-template__content theme--light'>{children}</article>
          <div className='check-in-template__buttons-wrapper'>
            {!!nextButton && (
              <Button
                className='check-in-template__confirm-button'
                onClick={nextButton?.action}
                data-dtm-track={nextButton.dtmTrack || 'check_in|driver_lookup|next'}>
                {nextButton?.label || i18n('common_next')}
              </Button>
            )}
            <Button
              link
              linkText
              className='check-in-template__discard-button'
              onClick={checkinAbortAction}
              data-dtm-track='check_in|driver_lookup|skip'>
              {i18n('common_nevermind')}
            </Button>
          </div>
        </section>
      </section>

      {isCustomPathReservation && isTabletOrMobile && (
        <section>
          <LinkModalGroupCustomPath />
        </section>
      )}
    </PostResLayout>
  );
};

CheckInTemplate.propTypes = {
  nextButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    dtmTrack: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CheckInTemplate;
