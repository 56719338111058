import React, { Component } from 'react';
import cn from 'classnames';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { ModalFooterWithRouter as ModalFooter } from 'components/Modal/ModalFooter';
import PropTypes from 'prop-types';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import utils from 'utils';
import PrintRecommendedAlertBanner from './PrintRecommendedAlertBanner';

/**
 * RecommendedAlertHeader component shows the recommended policies in a banner on the extras page
 * this banner also contains a list of remaining recommended extras.
 * This component is not present if all recommended extras are already included in the bundle
 *
 * @param {object}    props - React Props
 * @param {string}    props.countryCode - the pick up country code, used for getting the correct i18n key for banner text
 * @param {array}     props.recommendedExtras - array of recommended extras
 * @param {function}  props.openModal - opens a modal
 * @return {JSX}      RecommendedAlertHeader component
 */

class RecommendedAlertHeader extends Component {
  state = {
    recommendedDetailedDescription: '',
    recommendedDetailLabel: '',
  };

  printRecommendedCoverage = () => {
    const { recommendedExtras } = this.props;
    return recommendedExtras.map(({ code, name, detailed_description, selected_quantity }) => {
      const individualCoverageClassNames = cn('recommended-alert-header__individual-coverage', {
        'recommended-alert-header__individual-coverage--added': selected_quantity > 0,
      });
      return (
        <div key={code} className={individualCoverageClassNames}>
          <PrintRecommendedAlertBanner code={code} name={name} printRecommendedCoverage />
          <Button linkText onClick={this.handleDetailsClick(name, detailed_description)}>
            {utils.i18n('recommended_alert_header_more_info_button_texts')}
          </Button>
        </div>
      );
    });
  };

  handleDetailsClick = (label, detailedDescription) => () => {
    const { openModal } = this.props;
    //  Setting state with modal content, then opening modal
    this.setState(
      {
        recommendedDetailedDescription: detailedDescription,
        recommendedDetailLabel: label,
      },
      () => {
        openModal(MODAL.RECOMMENDED_EXTRA_DETAILS_MODAL);
      }
    );
  };

  render() {
    const { countryCode, scrollToRef } = this.props;
    const { recommendedDetailLabel, recommendedDetailedDescription } = this.state;
    return (
      <div className='recommended-alert-header__container'>
        <h4 className='recommended-alert-header__title'>{utils.i18n('recommended_alert_header_title')}</h4>
        <PrintRecommendedAlertBanner countryCode={countryCode} scrollToRef={scrollToRef} printAddOnBanner />

        {this.printRecommendedCoverage()}
        <Modal
          modalKey={MODAL.RECOMMENDED_EXTRA_DETAILS_MODAL}
          header={recommendedDetailLabel}
          theme={MODAL_THEMES.WHITE}
        >
          <>
            <div dangerouslySetInnerHTML={utils.sanitize(recommendedDetailedDescription)} />
            <ModalFooter
              buttons={[
                {
                  type: MODAL_BUTTON_TYPES.ACCEPT,
                  label: utils.i18n('common_close'),
                  analyticsValue: utils.i18n('common_close'),
                },
              ]}
            />
          </>
        </Modal>
      </div>
    );
  }
}

RecommendedAlertHeader.propTypes = {
  countryCode: PropTypes.string,
  recommendedExtras: PropTypes.array,
  openModal: PropTypes.func,
  scrollToRef: PropTypes.func,
};

export default RecommendedAlertHeader;
