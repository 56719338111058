import { connect } from 'react-redux';
import utils from 'utils';
import { locationInfoById } from 'actions/location';
import { resolveBranchURL } from 'actions/locationSearch';
import { redirectOrDisplayBookWitPartnerModal } from 'actions/locationFinder';
import { breakpointSelector } from 'selectors';
import { getOneWayGmaRentalFlag } from 'selectors/reservationSelectors';
import MapLocationDetailTile from './MapLocationDetailTile';

const mapStateToProps = state => ({
  breakpoint: breakpointSelector(state),
  reservationData: state.getIn(['gmi', 'session', 'gma', 'initiate_request']),
  locationInfo: state.getIn(['gmi', 'gma', 'location', 'by_id']),
  isOneWayReservation: getOneWayGmaRentalFlag(state),
});

const mapDispatchToProps = (dispatch, { location }) => ({
  locationInfoById: (...args) => dispatch(locationInfoById(...args)),
  resolveBranchURL: (...args) => dispatch(resolveBranchURL(...args)),
  redirectOrDisplayBookWitPartnerModal: goToBookPage =>
    dispatch(redirectOrDisplayBookWitPartnerModal({ location, goToBookPage })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(MapLocationDetailTile));
