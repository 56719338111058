import { connect } from 'react-redux';
import utils from 'utils';
import { recommendedExtrasNotIncludedSelector } from 'selectors';
import { openModal } from "actions/modal/openModal";
import RecommendedAlertHeader from './RecommendedAlertHeader';

const mapStateToProps = state => ({
  countryCode: state.getIn(['gmi', 'session', 'gbo', 'reservation', 'pickup_location', 'address', 'country_code']),
  recommendedExtras: recommendedExtrasNotIncludedSelector(state),
});
const mapDispatchToProps = {
  openModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(RecommendedAlertHeader));
