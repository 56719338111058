import { connect } from 'react-redux';
import utils from 'utils';
import { ANALYTICS, MODAL } from 'constants';
import { includedNotDefaultBundleExtrasSelector, bundlesSelector, additionalDriversPolicySelector } from 'selectors';
import { selectCarClass } from "actions/reservation/selectCarClass";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import Bundles from './Bundles';

const reservationPath = ['gmi', 'session', 'gbo', 'reservation'];
const carClassDetailCursor = ['gmi', 'session', 'gbo', 'reservation', 'car_class_details'];

const mapStateToProps = state => {
  const code = state.getIn([...reservationPath, 'car_class_details', 'code']); // Car class code
  const carClassDetails = state.getIn(carClassDetailCursor);

  return {
    bundles: bundlesSelector(state),
    includedBundlesExtras: includedNotDefaultBundleExtrasSelector(state),
    code,
    vehicleImages: carClassDetails?.get('images'),
    makeModelOrSimilarText: carClassDetails?.get('make_model_or_similar_text'),
    isPayLater: !state.getIn([...reservationPath, 'prepay_selected']),
    additionalDriversPolicy: additionalDriversPolicySelector(state),
  };
};

const mapDispatchToProps = {
  showBundlesDetailsModal: (header, description, extras, bundledAdditionalDrivers, additionalDriversPolicy) =>
    openModalWithProps(
      MODAL.BUNDLES_DETAILS_MODAL,
      { header, description, extras, bundledAdditionalDrivers, additionalDriversPolicy },
      { analyticsKey: ANALYTICS.BUNDLES_DETAILS_MODAL.replace('{0}', header) }
    ),
  selectCarClass,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(Bundles));
