import { connect } from 'react-redux';
import utils from 'utils';
import { getSupportContactInfo } from 'actions/content';
import { invalidateSession } from 'actions/request';
import { MODAL } from 'constants';
import {
  selectedExtras,
  hasLimitedInventoryExtraSelector,
  selectedBundleWithExtrasSelector,
  extrasWithoutBundlesSelector,
  numberOfExtrasSelector,
  breakpointSelector,
  creditPaymentObjectSelector,
  getThirdPartyReservationSupportNumberObjectSelector,
  profileDataSelector,
  vehicleRateSelector,
  isThirdPartyReservationSelector,
  formattedPoliciesSelector,
  modifyNotAllowedReasonsSelector,
  guaranteedResCardRequiredSelector,
  cancelFeeSelector,
  canCancelReservationSelector,
} from 'selectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import {
  reservationCouponsSelector,
  modifyCancelResExternalLinkSelector,
  appliedCancelFeeDetailsSelector,
} from 'selectors/reservationFlowSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import {
  getReservationRenterInfoFirstName,
  getReservationRenterInfoLastName,
  getReservationRenterInfoEmail,
  getReservationRenterInfoPhoneNumber,
  getOneWayRentalFlag,
  getSessionPickupLocation,
  getSessionReturnLocation,
  getReservationPickupTime,
  getReservationReturnTime,
  getContractDetails,
  reservationVehicleDetailsSelector,
  isTourReservationSelector,
  isCurrentReservationSelector,
  isReservationCancelledSelector,
  voucherExcludedSelector,
  isTourVoucherReservationSelector,
  getSelectedPaymentMethod,
  hasNoCancellationDetailsSelector,
} from 'selectors/reservationSelectors';

import { selectedPaymentTypePath } from 'paths/reservationPayment';

import { getSelectedVehiclesFilterSelector, isDestinationCurrencySelected } from 'selectors/vehicleSelectSelectors';
import { updateVehiclePriceSelector } from 'selectors/currencyToggleSelectors';
import { getUnauthReservationStateSelector } from 'selectors/appSelectors';

import { retrieveReservation } from 'actions/reservation/retrieveReservation';
import { getAfterHoursPolicy } from 'actions/reservation/getAfterHoursPolicy';
import { openModal } from 'actions/modal/openModal';
import { openModalWithProps } from 'actions/modal/openModalWithProps';
import { showSessionTimeoutModal } from 'actions/modal/showSessionTimeoutModal';
import Confirmation from './Confirmation';

const mapStateToProps = (state) => {
  const reservation = state.getIn(['gmi', 'session', 'gbo', 'reservation']);
  const carClassDetails = reservation?.get('car_class_details');
  const profileData = profileDataSelector(state, { memberNumber: true, first_name: true });
  const selectedPaymentMethodType = state.getIn(selectedPaymentTypePath);
  const destinationCurrencySelected = isDestinationCurrencySelected(state);
  let updateVehiclePrice = vehicleRateSelector(state)?.get('price_summary');
  if (destinationCurrencySelected && updateVehiclePrice) {
    updateVehiclePrice = updateVehiclePriceSelector(updateVehiclePrice.toJS());
  }

  return {
    reservationReady: !!reservation,
    isFetchingSession: isFetchingEndpointSelector(state, { endpoint: 'session/current' }),
    isCustomPathReservation: isCustomPathReservationSelector(state),
    voucherExcludedValue: voucherExcludedSelector(state),
    isTourVoucherReservation: isTourVoucherReservationSelector(state),
    vehicleSearchFilters: getSelectedVehiclesFilterSelector(state),

    ...(reservation && {
      breakpoint: breakpointSelector(state),
      policies: formattedPoliciesSelector(state),
      confirmationNumber: reservation.get('confirmation_number'),
      ticketNumber: reservation.get('ticket_number'),
      vehicleDetails: reservationVehicleDetailsSelector(state),
      firstName: getReservationRenterInfoFirstName(state),
      lastName: getReservationRenterInfoLastName(state),
      maskedEmail: getReservationRenterInfoEmail(state),
      isOneWayRental: getOneWayRentalFlag(state),
      pickupLocation: getSessionPickupLocation(state),
      returnLocation: getSessionReturnLocation(state),
      pickupTime: getReservationPickupTime(state),
      returnTime: getReservationReturnTime(state),
      vehicleName: carClassDetails.get('name'),
      vehicleSubCategory: carClassDetails.get('sub_category'),
      makeModelOrSimilarText: carClassDetails.get('make_model_or_similar_text'),
      features: carClassDetails.get('features'),
      peopleCapacity: carClassDetails.get('people_capacity'),
      luggageCapacity: carClassDetails.get('luggage_capacity'),
      twoOrFourDoorText: carClassDetails.get('two_or_four_door_text'),
      images: carClassDetails.get('images'),
      vehicleCode: carClassDetails.get('code'),
      flightNumber: reservation.getIn(['airline_info', 'flight_number']),
      airline: reservation.getIn(['airline_info', 'description']),
      priceSummary: updateVehiclePrice,
      extras: selectedExtras(state),
      mileageInfo: carClassDetails.get('mileage_info'),
      prepaySelected: reservation.get('prepay_selected'),
      afterHoursReturn: reservation.get('after_hours_return_selected'),
      afterHoursPickup: reservation.get('after_hours_pickup_selected'),
      blockAssociateProfile: reservation.get('block_associate_profile'),
      additionalInformation: reservation.get('additional_information'),
      contractDetails: getContractDetails(state),
      hasLimitedInventoryExtra: hasLimitedInventoryExtraSelector(state),
      reservationStatus: reservation.get('reservation_status'),
      maskedPhoneNumber: getReservationRenterInfoPhoneNumber(state),
      billingAccount: reservation.get('billing_account'),
      // selectedBundleWithExtrasSelector only returns a bundle if it's selected and not default (e.g. 'Fully')
      isReservationCancelled: isReservationCancelledSelector(state),
      isCurrentTrip: isCurrentReservationSelector(state),
      selectedBundleWithExtras: selectedBundleWithExtrasSelector(state),
      extrasWithoutBundles: extrasWithoutBundlesSelector(state),
      partner_reward_program: reservation.get('partner_reward_program'),
      numberOfExtrasSelected: numberOfExtrasSelector(state),
      isThirdPartyReservation: isThirdPartyReservationSelector(state),
      refundObject: creditPaymentObjectSelector(state),
      countryCode: reservation.getIn(['pickup_location', 'address', 'country_code']),
      thirdPartyReservationSupportNumber: getThirdPartyReservationSupportNumberObjectSelector(state)?.phone_number,
      memberNumber: profileData?.memberNumber,
      modifyNotAllowedReasons: modifyNotAllowedReasonsSelector(state),
      modifyCancelResExternalLink: modifyCancelResExternalLinkSelector(state),
      guaranteedResCardRequired: guaranteedResCardRequiredSelector(state),
      cancelFee: cancelFeeSelector(state),
      coupons: reservationCouponsSelector(state),
      isTourReservation: isTourReservationSelector(state),
      selectedPaymentType: selectedPaymentMethodType,
      canCancelReservation: canCancelReservationSelector(state),
      profileFirstname: profileData?.first_name,
      unauthReservation: getUnauthReservationStateSelector(state),
      isCurrentLanguage: sessionStorage.getItem('com.adobe.reactor.dataElements.AL_language'),
      paymentMethod: getSelectedPaymentMethod(state),
      appliedCancelFeeDetails: appliedCancelFeeDetailsSelector(state),
      isFetchinRetrieveReservation: isFetchingEndpointSelector(state, { endpoint: 'reservations/retrieve' }),
      hasNoCancellationDetails: hasNoCancellationDetailsSelector(state),
    }),
  };
};

const mapDispatchToProps = {
  showViewDetailsModal: () => openModal(MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL),
  showLearnMoreModal: () => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL),
  showAHViewDetailsModal: () => openModal(MODAL.RENTAL_DETAILS_AFTER_HOURS_MODAL),
  showCancelModal: () => openModal(MODAL.CONFIRM_CANCEL_RESERVATION_MODAL),
  showModifyModal: () => openModal(MODAL.MODIFY_RESERVATION),
  handlePrepayTermsModal: () => openModal(MODAL.PREPAY_TERMS_CONDITIONS_MODAL),
  getSupportContactInfo: (country) => getSupportContactInfo(country),
  showGuaranteedResModal: () => openModal(MODAL.GUARANTEED_RES),
  openTermsAndConditionsModal: () => openModal(MODAL.KEY_RENTAL_FACTS_TERMS_AND_CONDITIONS),
  openUpdateFlightInfoModal: () => openModal(MODAL.UPDATE_FLIGHT_INFO_MODAL, { skipAnalytics: true }),
  openFlightDelayModal: () => openModal(MODAL.FLIGHT_DELAYED_MODAL, { skipAnalytics: true }),
  openFlightConfirmedModal: () => openModal(MODAL.FLIGHT_CONFIRMED_MODAL),
  openCancelUpdatesmodal: () => openModal(MODAL.CANCEL_UPDATES_MODAL, { skipAnalytics: true }),
  openFirstNameConflictModal: (handleAccept) =>
    openModalWithProps(MODAL.CHECKIN_FIRST_NAME_CONFLICT_MODAL, { handleAccept, isAssociate: true }),
  showSessionTimeoutModal,
  getAfterHoursPolicy,
  invalidateSession,
  retrieveReservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(Confirmation));
