import React from 'react';
import utils from 'utils';
import Modal from 'components/Modal';
import { MODAL, MODAL_THEMES } from 'constants';
import GuaranteedResIframeModalContentContainer from './GuaranteedResIframeModalContentContainer';

/**
 * GuaranteedResIframeModal renders GuaranteedResIframeModalContent inside Modal wrapper.
 * @return {JSX}
 */

export default function GuaranteedResIframeModal() {
  return (
    <Modal
      modalKey={MODAL.GUARANTEED_RES_IFRAME}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('guaranteed_res_modal_header')}
      customClass='guaranteed-res_iframe-modal-overlay'>
      <GuaranteedResIframeModalContentContainer />
    </Modal>
  );
}
