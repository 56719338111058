import { connect } from 'react-redux';
import utils from 'utils';
import {
  setContractNumber,
  setPartnerRewardProgram,
  setPartnerRewardProgramMemberId,
  setUpdateProfileWithPartnerRewards,
} from 'actions/bookingWidget';
import { profileDataSelector } from 'selectors';
import { initiateReservationFlow } from "actions/reservation/initiateReservationFlow";
import PRPAuthConflictModal from './PRPAuthConflictModal';

const mapStateToProps = state => ({
  partner_rewards_program_name: state.getIn(['gmi', 'ui', 'reservations', 'initiate', 'partner_reward_program_name']),
  profileData: profileDataSelector(state, { partnerRewardsProgram: true }),
});

const mapDispatchToProps = {
  handleInitiate: () => initiateReservationFlow({ freshSession: true }),
  setContractNumber,
  setPartnerRewardProgram,
  setUpdateProfileWithPartnerRewards,
  setPartnerRewardProgramMemberId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(PRPAuthConflictModal));
