import React from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { loadingRedirect } from 'utils/redirect';
import { analyticsInteraction, analyticsPrepareKey } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * CheckInIneligibleModal
 *
 * @return {JSX} ...
 */
const CheckInIneligibleModal = () => {
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);

  return (
    <Modal
      modalKey={MODAL.CHECK_IN_INELIGIBLE_MODAL}
      header={i18n('ineligible_modal_title')}
      theme={MODAL_THEMES.WHITE}>
      {() => (
        <>
          <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
            {i18n('oci_ineligible_modal_description')}
          </p>

          <ModalFooter
            fullWidthButton
            buttons={[
              {
                type: DECLINE,
                label: i18n('common_close'),
              },
              {
                type: ACCEPT,
                label: i18n('common_view_details'),
                handler: () =>
                  loadingRedirect(
                    isCustomPathReservation
                      ? WINDOW_OBJECT_KEYS.CUSTOM_PATH_RENTAL_DETAILS
                      : WINDOW_OBJECT_KEYS.RENTAL_DETAILS
                  ),
                analyticsHandler: () =>
                  analyticsInteraction(
                    ANALYTICS.MODAL,
                    analyticsPrepareKey(MODAL.CHECK_IN_INELIGIBLE_MODAL),
                    ANALYTICS.VIEW_DETAILS
                  ),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default CheckInIneligibleModal;
