import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MODAL from 'constants/modals';
import useActions from 'hooks/useActions';
import { skipTheCounterConfirmSelections } from 'actions/skipTheCounter';
import { pickupLocationIsAirportSelector, reservationAirlineInfoSelector } from 'selectors/reservationSelectors';
import { inPathAdditionalDriversSelector } from 'selectors/reservationFlowSelectors';
import Form from 'components/Form/Form';
import PostResDriverDetails from 'components/PostRes/PostResDriverDetails';
import PostResFlightInfo from 'components/PostRes/PostResFlightInfo';
import PostResAdditionalDrivers from 'components/PostRes/PostResAdditionalDrivers';
import PostResReviewPrice from 'components/PostRes/PostResReviewPrice';
import { modifyReservationStart } from "actions/reservation/modifyReservationStart";
import { openModal } from "actions/modal/openModal";
import SkipTheCounterFlowTemplate from '../SkipTheCounterFlowTemplate';
import AdditionalDriverRequiredModal from '../AdditionalDriverRequiredModal';

/**
 * STCReviewSelections
 *
 * @param {object} props - React Props
 *
 * @return {JSX}
 */
const STCReviewSelections = () => {
  const [submitFailed, setSubmitFailed] = useState(false);

  const confirmSelections = useActions(skipTheCounterConfirmSelections);
  const triggerModifyReservation = useActions(modifyReservationStart);
  const openAdditionalDriverRequiredModal = useActions(() => openModal(MODAL.ADDITIONAL_DRIVER_REQUIRED_MODAL));

  const isAirport = useSelector(pickupLocationIsAirportSelector);
  const airlineInfo = useSelector(reservationAirlineInfoSelector);
  const driversList = useSelector(inPathAdditionalDriversSelector);

  const initialValues = useMemo(
    () => ({
      airline_information: {
        code: airlineInfo.code,
        flight_number: airlineInfo.flight_number,
      },
    }),
    [airlineInfo.code]
  );

  const isDriverInfoComplete = driversList.every(driver => !driver.partial_driver_info);

  useEffect(
    () => {
      isDriverInfoComplete && setSubmitFailed(false);
    },
    [driversList]
  );

  const handleCommit = useCallback(
    formData => {
      // check if a driver has incomplete details
      if (!isDriverInfoComplete) {
        openAdditionalDriverRequiredModal();
        setSubmitFailed(true);
        return;
      }
      confirmSelections(formData);
    },
    [isDriverInfoComplete]
  );

  return (
    <>
      <Form
        onSubmit={handleCommit}
        initialValues={initialValues}
        subscription={{ submitting: true, pristine: true, submitFailed: true }}>
        {({ form, handleSubmit }) => (
          <SkipTheCounterFlowTemplate
            submitAction={handleSubmit}
            submitButtonKey='common_continue'
            submitDTMTrack='continue'>
            <form onSubmit={handleSubmit} noValidate>
              <PostResDriverDetails handleModifyReservation={triggerModifyReservation} />
              <PostResAdditionalDrivers isSTCFlow submitFailed={submitFailed} />
              {isAirport && <PostResFlightInfo />}
            </form>
            <PostResReviewPrice isSTCFlow />
          </SkipTheCounterFlowTemplate>
        )}
      </Form>
      <AdditionalDriverRequiredModal />
    </>
  );
};

export default STCReviewSelections;
