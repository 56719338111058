import React from 'react';
import utils from 'utils';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL_BUTTON_TYPES } from 'constants';
import propTypes from './removePartnerRewardsModalContentPropTypes';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const RemovePartnerRewardsModalContent = ({
  partner_rewards_name,
  handleClose,
  clearDiscountInReservationFlow,
  clearPartnerRewardsState,
  uiCommitState,
  partnerRewards,
  profileData,
  isAuth,
}) => {
  const clearCID = () => {
    // checks for partnerRewards commit state,
    // if exists we are manualy removing the partnerRewards commit state to avoid error while removing partnerRewards for the reservation
    if (uiCommitState?.partner_reward_program_code || uiCommitState?.partner_reward_program_member_id) {
      clearPartnerRewardsState();
    }

    if (isAuth && profileData) {
      partnerRewards({ partner_reward_program_code: '', partner_reward_program_member_id: '' });
    } else {
      clearDiscountInReservationFlow();
    }

    handleClose({ analyticsValue: 'Yes, Remove' });
  };

  return (
    <>
      <p className='modal-themed__copy'>
        {utils.i18n('progress_bar_partner_rewards_modal_content', [partner_rewards_name])}
      </p>
      <ModalFooter
        fullWidthButton
        buttons={[
          {
            type: DECLINE,
            label: utils.i18n('progress_bar_cid_remove_modal_decline'),
            analyticsValue: "No, Don't Remove",
          },
          {
            type: ACCEPT,
            label: utils.i18n('progress_bar_cid_remove_modal_accept'),
            handler: clearCID,
          },
        ]}
      />
    </>
  );
};

RemovePartnerRewardsModalContent.propTypes = propTypes;

export default RemovePartnerRewardsModalContent;
