import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import Button from 'components/Button';

/**
 * Check-in Template Component
 * Wrapper for the accelerated check-in flow SPA pages that share the same layout.
 *
 * @param {object} props - React Props
 * @param {object} props.nextButton - "Next"/"Confirm" button properties
 * @param {string} props.nextButton.label - Button label (defaults to "Next")
 * @param {function} props.nextButton.action - Function called when the button is pressed - usually a form submit
 * @param {string} props.nextButton.dtmTrack - DTM attribute for next/submit button
 * @param {array|node} props.children - JSX Components passed onto this component to be rendered within it
 *
 * @return {JSX} InResOCITemplate jsx component
 */
const InResOCITemplate = ({
  nextButton,
  children,
  handleNeverMind,
  isEditingLicenseInfo,
  isEditingContactInfo,
  hideFooter = false,
  isLoggedIn,
  showInResOCIActivatedProfile,
}) => {
  let buttonLabel = i18n('trip_card_oci_step_form_nevermind');
  if (isEditingContactInfo || isEditingLicenseInfo) {
    buttonLabel = i18n('common_cancel');
  }

  return (
    <section
      className={cn('check-in-template__wrapper', {
        'logged-in-user': isLoggedIn,
      })}>
      <section className={'check-in-template__content-area'}>
        <article
          className={cn('check-in-template__content theme--light', {
            'edit-checkin-authenticated-profile':
              showInResOCIActivatedProfile && (isEditingContactInfo || isEditingLicenseInfo),
          })}>
          {children}
        </article>
        {!hideFooter && (
          <div
            className={cn('check-in-template__buttons-wrapper-inres', {
              'edit-checkin-authenticated-profile':
                showInResOCIActivatedProfile && (isEditingContactInfo || isEditingLicenseInfo),
            })}>
            {!!nextButton && (
              <Button
                className='button--dark-ghosted check-in-template__confirm-button'
                onClick={nextButton?.action}
                data-dtm-track={nextButton.dtmTrack || 'check_in|driver_lookup|next'}>
                {nextButton?.label || i18n('common_next')}
              </Button>
            )}
            <Button
              link
              linkText
              className='check-in-template__discard-button'
              onClick={() => handleNeverMind(buttonLabel)}
              data-dtm-track='check_in|driver_lookup|skip'>
              {buttonLabel}
            </Button>
          </div>
        )}
      </section>
    </section>
  );
};

InResOCITemplate.propTypes = {
  nextButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    dtmTrack: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default InResOCITemplate;
