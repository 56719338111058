import React from 'react';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import utils from 'utils';

/**
 * LearnMoreAboutSkipTheCounterEligibleModal - Show a description of eligible skip the counter
 * @return {JSX} LearnMoreAboutSkipTheCounterEligibleModal component
 */

const LearnMoreAboutSkipTheCounterEligibleModal = () => (
  <Modal
    modalKey={MODAL.LEARN_MORE_ABOUT_SKIP_THE_COUNTER_ELIGIBLE_MODAL}
    customClass='oci-stc-modal'
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('learn_more_about_skip_the_counter_modal_title')}>
    <>
      <p>
        {utils.i18n('trip_card_skip_the_counter_eligible_description')}
        <br />
        {utils.i18n('confirmation_stc_show_more_eligible_info')}
      </p>

      <p>{utils.i18n('confirmation_stc_show_more_list_description')}</p>
      <ul>
        <li>{utils.i18n('confirmation_stc_show_more_item_1')}</li>
        <li>{utils.i18n('confirmation_stc_show_more_item_2')}</li>
        <li>{utils.i18n('confirmation_stc_show_more_item_3')}</li>
      </ul>
    </>
  </Modal>
);

export default LearnMoreAboutSkipTheCounterEligibleModal;
