import React from 'react';
import PropTypes from 'prop-types';

/**
 * Rate Secion component is intened to render a piece of a vehicle detail "table"
 * on the Vehicle Select view of Res Flow
 * @param {node} props.children - any nested elements passed to the react component
 */

const RateRow = ({ title, className, colSpan, align = 'left', children }) => (
  <tr colSpan={colSpan} align={align} className={`${className}`}>
    <th scope='colgroup' className='th-rate-row'>
      <span className='th-row-title'>{title}</span>
    </th>
    {children}
  </tr>
);

RateRow.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  colSpan: PropTypes.string,
  align: PropTypes.string,
};

RateRow.defaultProps = {
  colSpan: 3,
  align: 'left',
};
export default RateRow;
