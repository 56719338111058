import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { RESERVATIONS } from 'constants';
import LoadingWidget from 'components/LoadingWidget';

const { IBM_SUCCESS, IBM_FAILURE } = RESERVATIONS;

/**
 * IbmPaymentIframe - Provides an Iframe that gives us a credit card submission form provided by IBM and a handler
 * to respond to results from that form. cardSubmissionSession is called on mount and returns a url that we use to open
 * this Iframe.
 *
 * @param {object}   props - React Props
 * @param {boolean}  props.isTrueModify
 * @param {function} props.cardSubmissionSession
 * @param {string}   props.cardSubmissionURL
 * @param {string}   props.sessionId
 * @param {function} props.onSubmit
 * @param {function} props.onFailure
 * @return {JSX}
 */

const IbmPaymentIframe = ({
  isTrueModify,
  cardSubmissionSession,
  cardSubmissionURL,
  sessionId,
  onSubmit,
  onFailure,
  firstName,
  lastName,
}) => {
  const acceptableHeight = 650;
  const [loading, setLoading] = useState(true);
  const [iframeHeight, setIframeHeight] = useState(null);

  const handleMessage = ({ data, origin }) => {
    /* handleMessage is used to communicate with the payment Iframe opened below. IBM was given specifications for
     * what data it needs to communicate to us and currently includes the iframeHeight to dynamically tell us how
     * big we need to set the Iframe window depending on the layout of the Iframe content (which may change). It also
     * returns a string for success or failure.
     */
    if (!utils.gmi.types(data).isString) {
      return;
    }

    // When payment has been submitted we get a failure or success message
    if (data === IBM_SUCCESS) {
      onSubmit(data);
      // On successful submissions, we want to fire off a custom analytics event!
      utils.analytics.dispatchEventWithDOM('PaymentSuccessful');
    }

    if (data === IBM_FAILURE) {
      if (onFailure) {
        onFailure(data);
      } else {
        onSubmit(data);
      }
    }

    // ibm iframe sends us the height in the form of 'new_height', we also want to check height is coming from ibm iFrame
    if (data.indexOf('new_height') !== -1 && origin !== window.location.origin) {
      // The Data from the post message comes in as a string like so: 'new_height: XXX.xx'
      const iframeHeightValue = data.split(': ')[1];
      const iframeActualHeight = `${iframeHeightValue}px`;

      // Only set new height if it is different
      if (iframeActualHeight !== iframeHeight) {
        if (iframeHeightValue < acceptableHeight) {
          setIframeHeight(`${acceptableHeight}px`);
        } else {
          setIframeHeight(iframeActualHeight);
        }
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    cardSubmissionSession({
      trueModify: isTrueModify,
      loadingOverlay: false,
      renter_first_name: firstName,
      renter_last_name: lastName,
    }).then((response) => {
      if (response.messages && response?.messages.length > 0) {
        setLoading(false);
      }
    });

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  useEffect(() => {
    utils.analytics.paymentSession(sessionId);
  }, [sessionId]);

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <div style={{ minHeight: `${iframeHeight || 'auto'}` }}>
      {loading && <LoadingWidget />}
      {cardSubmissionURL && (
        <iframe
          style={{
            height: `${iframeHeight}`,
            display: loading ? 'none' : 'block',
          }}
          title='Payment details'
          className='payment-section__iframe'
          src={cardSubmissionURL}
          onLoad={onLoad}
        />
      )}
    </div>
  );
};

IbmPaymentIframe.propTypes = {
  isTrueModify: PropTypes.bool.isRequired,
  cardSubmissionSession: PropTypes.func.isRequired,
  cardSubmissionURL: PropTypes.string,
  sessionId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onFailure: PropTypes.func,
};
export default IbmPaymentIframe;
