import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import cn from 'classnames';
import { numFiltersSelectedFilterSelector } from 'selectors/uiStateSelectors';
import { getNumOfSelectedVehiclesCountSelector } from 'selectors/vehicleSelectSelectors';
import Button from 'components/Button';

const FilterToggle = ({ open, onToggle, storeKey, hasSorting, showVehicleCount }) => {
  // const currentFilters = useSelector(state => currentFiltersSelector(state, storeKey));
  const numFiltersSelected = useSelector((state) => numFiltersSelectedFilterSelector(state, storeKey));
  // const segmentsIsActive = useSelector(state => segmentsIsActiveFilterSelector(state, storeKey));
  // const segmented = useSelector(state => segmentedFilterItemsSelector(state, storeKey));
  // const allItems = useSelector(state => allFilterItemsSelector(state, storeKey));
  const numOfVehicleSelected = useSelector((state) => getNumOfSelectedVehiclesCountSelector(state));

  const getTitleText = () => {
    let titleText = hasSorting ? utils.i18n('sort_and_filter') : utils.i18n('filter_title');

    if (numFiltersSelected > 0 || (showVehicleCount && numOfVehicleSelected > 0)) {
      const showLocationAndVehicleFiltersSelectedCount = showVehicleCount
        ? numFiltersSelected + numOfVehicleSelected
        : numFiltersSelected;
      titleText += ` (${showLocationAndVehicleFiltersSelectedCount})`;
    }

    return titleText;
  };
  // Old logic to disable the button if only 1 type of location. Keeping it for reference
  // const isDisabled = !currentFilters.length || (segmentsIsActive ? segmented.length < 2 : allItems.length < 2);

  return (
    <Button
      plain
      data-dtm-track={utils.analytics.dtm(
        ANALYTICS.UI_BUTTON,
        ANALYTICS.LOCATION_FILTER,
        open ? ANALYTICS.CLOSE : ANALYTICS.OPEN
      )}
      className={cn('filter-toggle link', { open })}
      onClick={() => onToggle?.()}
      aria-label={getTitleText()}
      type='button'
    >
      {getTitleText()}
    </Button>
  );
};

FilterToggle.propTypes = {
  storeKey: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  hasSorting: PropTypes.bool,
  showVehicleCount: PropTypes.bool,
};

FilterToggle.defaultProps = {
  storeKey: [],
  hasSorting: false,
  showVehicleCount: false,
};

export default FilterToggle;
