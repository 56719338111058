import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { RESERVATIONS } from 'constants';

const { RESFLOW_PATHS_CONFIG } = RESERVATIONS;

const ProgressBarHeaderContainer = ({ value, config, match, progressBarConfig }) => {
  const { paths } = config;

  const currentStep = match && paths.indexOf(match.path) !== -1;
  const availableReservationPaths = Object.values(RESFLOW_PATHS_CONFIG).flat();
  const isVehicleSelectOrExtrasSelect = currentStep && availableReservationPaths.includes(match.path);
  return (
    <li
      className={cn('step-wizard-item', {
        'current-item': isVehicleSelectOrExtrasSelect || progressBarConfig.length === value,
      })}>
      <span className='progress-count'>{value}</span>
    </li>
  );
};

ProgressBarHeaderContainer.propTypes = {
  name: PropTypes.number,
};

export default ProgressBarHeaderContainer;
