import React, { forwardRef } from 'react';
import utils from 'utils';
import { isDuplicateEmailError } from 'utils/profile';
import PropTypes from 'prop-types';
import cn from 'classnames';
import GMA_RESPONSE_CODES from 'constants/gmaResponseCodes';
import Anchor from 'components/Anchor';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
/**
 * GenericError Description
 *
 * @param {object} props - React Props
 * @param {array}  props.errors - array of errors
 * @param {string||array} props.description - a string description or array of elements explaining errors
 * @return {JSX}
 */

const GenericError = forwardRef(({ errors, description }, ref) => (
  <div className='service-errors' ref={ref}>
    {errors.map(({ message, priority = 'ERROR', code }, index) => {
      const isEmailInUse = isDuplicateEmailError(code);
      return (
        <div
          key={index}
          className={cn('service-error', {
            'service-errors_duplicate-email': isEmailInUse,
          })}
        >
          <span className={`message-priority--${priority}`}>
            {isEmailInUse
              ? utils.i18n('email_already_in_use_modal_error_heading')
              : utils.i18n(`message_priority_${priority}`)}
          </span>

          <p>
            {message}
            {code === GMA_RESPONSE_CODES.GBO_RESERVATION_STATUS_CLOSED && (
              <Anchor
                className='link--arrow-spacing link--arrow'
                href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RECEIPTS_PAGE)}
              >
                {utils.i18n('past_trips_unauth_page_title')}
              </Anchor>
            )}
          </p>
        </div>
      );
    })}

    {!!description && <p className='service-errors__description'>{description}</p>}
  </div>
));

GenericError.propTypes = {
  errors: PropTypes.array,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default GenericError;
