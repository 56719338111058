import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';
import { BREAKPOINTS } from 'constants';
import { utils } from '@ehi/global-marketing-interface';
import PropTypes from './FirstInvalidFieldSpyPropTypes';

class FirstInvalidFieldSpy extends Component {
  state = {
    previousIndicatedField: null,
  };

  componentDidMount() {
    const { form } = this.props;
    const formState = form.getState();
    this.setIndicatedField(formState);
  }

  getOrderedFieldNames = (formElement) => {
    const collection = utils.get(formElement, 'elements') || [];
    return [...collection].filter((e) => e.name).map((e) => e.name);
  };

  setIndicatedField = (formState) => {
    const {
      breakpoint,
      form: {
        mutators: { setFieldData },
      },
    } = this.props;
    const { previousIndicatedField } = this.state;
    const { errors } = formState;
    const shouldFocusAndActivate = breakpoint !== BREAKPOINTS.SMALL;
    let indicatedField = null;
    // indicate the first invalid field
    const registeredFields = this.getOrderedFieldNames(document.getElementById(this.props.formId));
    const invalidFieldKeys = Object.keys(errors);
    registeredFields.forEach((key) => {
      if (!indicatedField && invalidFieldKeys.indexOf(key) !== -1) {
        //  We need the isGuided Prop here to trigger the guided error
        setFieldData(key, {
          isFocusedActive: shouldFocusAndActivate,
          isGuided: true,
        });
        indicatedField = key;
      } else {
        setFieldData(key, {
          isFocusedActive: false,
        });
      }
    });

    if (indicatedField && indicatedField !== previousIndicatedField) {
      this.setState({ previousIndicatedField: indicatedField });
    }
  };

  handleChange = (formState) => {
    this.setIndicatedField(formState);
  };

  // hack for handleChange to get called  before render, referred from here https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
  render() {
    return (
      <FormSpy
        onChange={(formState) => setTimeout(() => this.handleChange(formState), 0)}
        subscription={{ errors: true, submitFailed: true }}
      />
    );
  }
}

FirstInvalidFieldSpy.propTypes = PropTypes;

export default FirstInvalidFieldSpy;
