import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import MODAL from 'constants/modals';
import { isUnauthUserWithLoyaltyProfileSelector } from 'selectors/checkinSelectors';
import useActions from 'hooks/useActions';
import SignInModal from 'components/modals/SignInModal';
import Button from 'components/Button';
import { openModal } from "actions/modal/openModal";

/**
 * CheckInLoginCTA
 * Shows a small CTA prompting the user to login in case they want to make changes to the current AI profile.
 *
 * @return {JSX} CheckInLoginCTA jsx component
 */
const CheckInLoginCTA = () => {
  const openSignInModal = useActions(() => openModal(MODAL.SIGN_IN_MODAL));
  const isUnauthUserWithLoyaltyProfile = useSelector(isUnauthUserWithLoyaltyProfileSelector);

  return (
    <>
      {isUnauthUserWithLoyaltyProfile ? (
        <div className='check-in-flow__form__section'>
          <h4 className='check-in-flow__form__section-title'>{utils.i18n('check_in_update_account_subtitle')}</h4>
          <p>
            {utils.i18n(
              'check_in_sign_in_cta',
              {
                0: (
                  <Button key={1} link className='check-in-flow__form__sign-in-cta' onClick={openSignInModal}>
                    {utils.i18n('common_sign_in')}
                  </Button>
                ),
              },
              {
                jsx: true,
              }
            )}
          </p>
        </div>
      ) : null}
      <SignInModal />
    </>
  );
};

export default CheckInLoginCTA;
