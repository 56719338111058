import { connect } from 'react-redux';
import utils from 'utils';
import { breakpointSelector } from 'selectors';
import { showDiscardReservationModal } from "actions/modal/showDiscardReservationModal";
import ModifyResBanner from './ModifyResBanner';

const mapStateToProps = state => ({
  breakpoint: breakpointSelector(state),
});

const mapDispatchToProps = {
  handleExitModify: () => showDiscardReservationModal({ backToRentalDetails: true }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(ModifyResBanner));
