import React from 'react';
import { ModalFooterWithRouter as ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL_BUTTON_TYPES } from 'constants';
import utils from 'utils';

const EditVehicleModalContent = ({ linkTo }) => (
  <>
    <p className='modal-themed__section'>{utils.i18n('edit_vehicle_modal_copy_1')}</p>
    <p className='modal-themed__section'>{utils.i18n('edit_vehicle_modal_copy_2')}</p>

    <ModalFooter
      buttons={[
        {
          linkTo,
          type: MODAL_BUTTON_TYPES.LINK,
          label: utils.i18n('edit_vehicle_modal_accept'),
          analyticsValue: utils.i18n('edit_vehicle_modal_accept'),
        },
        {
          type: MODAL_BUTTON_TYPES.DECLINE,
          label: utils.i18n('edit_vehicle_modal_decline'),
          analyticsValue: utils.i18n('edit_vehicle_modal_decline'),
        },
      ]}
    />
  </>
);

export default EditVehicleModalContent;
