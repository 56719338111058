import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import { isNAPrepayModifySelector } from 'selectors/reservationFlowSelectors';
import Button from 'components/Button';
import ToolTip from 'components/ToolTip';
import LocationAddress from 'components/LocationAddress';

const Location = ({
  oneWayRental,
  pickupLocation,
  returnLocation,
  handleEdit,
  children,
  hideEditButtons,
  isTabletOrMobile,
  stepTwo,
  isSTCFlow,
  renterDetails = false,
  isReturnReview,
}) => {
  const isNAPrepayModify = useSelector(isNAPrepayModifySelector);

  const sectionHeading = utils.i18n(
    oneWayRental ? 'rental_details_locations' : 'rental_details_location_pickup_return'
  );

  let rentalDetailsSectionLabel = utils.i18n(
    pickupLocation ? 'rental_details_location_pickup' : 'rental_details_location_return'
  );

  if (renterDetails) {
    rentalDetailsSectionLabel = utils.i18n('rental_details_location_return_label');
  }
  return (
    <div className='rental-details__section rental-details__locations'>
      {pickupLocation && returnLocation ? (
        <p className='rental-details__section-label'>
          <span>{sectionHeading}</span>
          {isNAPrepayModify && (
            <ToolTip placement='top-end'>
              <span>{utils.i18n('rental_details_location_cancel_rebook_tooltip')}</span>
            </ToolTip>
          )}
          {!isNAPrepayModify && !!handleEdit && !stepTwo && (!hideEditButtons || isTabletOrMobile) && (
            <Button
              link
              onClick={handleEdit('start')}
              ariaText={utils.i18n('rental_details_edit_aria_label', [sectionHeading])}
              data-dtm-track={`rental_details|${sectionHeading}|edit`}
            >
              {utils.i18n('rental_details_edit')}
            </Button>
          )}
        </p>
      ) : (
        <p className='rental-details__section-label'>
          <span>{rentalDetailsSectionLabel}</span>
        </p>
      )}

      {oneWayRental || isReturnReview ? (
        <>
          {pickupLocation && (
            <div className='rental-details__detail'>
              {returnLocation && <p className='rental-details__key'>{utils.i18n('rental_details_location_pickup')}</p>}

              <div className='rental-details__value'>
                <LocationAddress location={pickupLocation} isSTCFlow={isSTCFlow} />
                {children}
              </div>
            </div>
          )}

          {returnLocation && (
            <div className='rental-details__detail'>
              {pickupLocation && <p className='rental-details__key'>{utils.i18n('rental_details_location_return')}</p>}

              <div className='rental-details__value'>
                <LocationAddress location={returnLocation} isSTCFlow={isSTCFlow} isReturnReview />
                {children}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='rental-details__detail'>
          <div className='rental-details__value'>
            {pickupLocation && <LocationAddress location={pickupLocation} isSTCFlow={isSTCFlow} />}
            {renterDetails && returnLocation && <LocationAddress location={returnLocation} isSTCFlow={isSTCFlow} />}
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

Location.propTypes = {
  oneWayRental: PropTypes.bool,
  pickupLocation: PropTypes.object,
  returnLocation: PropTypes.object,
  handleEdit: PropTypes.func,
  hideEditButtons: PropTypes.bool,
  isTabletOrMobile: PropTypes.bool,
  stepTwo: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  isSTCFlow: PropTypes.bool,
  renterDetails: PropTypes.bool,
};

export default Location;
