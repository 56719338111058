import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import THEMES from 'constants/themes';
import RESERVATIONS from 'constants/reservations';
import SCROLL from 'constants/scroll';
import useActions from 'hooks/useActions';
import { breakpointSelector } from 'selectors/breakpoint';
import { isEuropeanUnionCountry } from 'selectors/reservationSelectors';
import Checkbox from 'components/Form/Checkbox';
import Button from 'components/Button';
import CreditCardDetails from 'components/CreditCardDetails';
import IbmPaymentIframe from 'components/IbmPaymentIframe';
import HoldCreditCardAlert from 'components/ReservationFlow/Review/HoldCreditCardAlert/HoldCreditCardAlert';
import PaymentSection from 'components/ReservationFlow/Review/PaymentSection/PaymentInformation/PaymentSection';
import FieldGroupCard from 'components/ReservationFlow/Review/FieldGroupCard/FieldGroupCard';
import { showDiscardReservationModal } from 'actions/modal/showDiscardReservationModal';
import PrePaymentSectionReviewReservation from './PrePaymentSectionReviewReservation';

const { EXISTING_PREPAY, CONFIRM_PREPAY, CARD_FORM, CARD_ALERT } = RESERVATIONS.PREPAY_UI_STEPS;

const PrePayPaymentSection = ({
  estimatedTotal,
  estimatedTotalPayment,
  isForeignCurrency,
  showLearnMoreModal,
  oppositeEstimatedTotal,
  prepayTermsLink,
  handleTogglePrepay,
  paymentCardData,
  isTrueModify,
  prepayFailed,
  prepayStep,
  collectNewPaymentCard,
  onIframeSubmit,
  onPaymentFailure,
  form,
  disablePrepayPaylaterButton,
}) => {
  const { isMobile } = useSelector(breakpointSelector);
  const showDiscardCCHoldModal = useActions(() => showDiscardReservationModal({ ccHold: true }));
  const isEuropeanCountry = useSelector(isEuropeanUnionCountry);

  const iframeRef = useRef();

  const pageStatus = document.getElementById('pageStatus');

  useEffect(() => {
    if (prepayStep === CARD_FORM) {
      //  This scrolls to the iframe, the + numbers are *magic* numbers
      const headerOffset = isMobile
        ? SCROLL.HEIGHT_OF_PROGRESS_BAR_MOBILE + 7
        : SCROLL.HEIGHT_OF_PROGRESS_BAR_DESKTOP + 15;
      iframeRef?.current && utils.dom.scrollPage(0, iframeRef.current.offsetTop - headerOffset);
      utils.dom.scrollPage(0, (iframeRef.current?.offsetTop || 0) - headerOffset);
    }

    if ([CARD_ALERT, EXISTING_PREPAY].includes(prepayStep)) {
      form?.change('payment_info.accept_prepay_terms_payment_section', true);
    }
    // To ensure assistive technology users or to notify that the page has been updated, within Reservation flow
    pageStatus.setAttribute('aria-live', 'polite');
    pageStatus.innerText = utils.i18n('review_payment_section_title');

    setTimeout(() => {
      pageStatus.innerText = '';
      pageStatus.setAttribute('aria-live', 'off');
    }, 15000);
  }, [prepayStep]);

  const sectionHeader = (
    <div className='review__section-header'>
      <h2 className='review__section-header__title'>{utils.i18n('review_prepayment_section_title_step_two')}</h2>
    </div>
  );

  const paymentSection = (
    <PaymentSection
      newTotal={utils.getFormattedPrice(estimatedTotal)}
      estimatedTotalPayment={estimatedTotalPayment}
      isForeignCurrency={isForeignCurrency}
      showLearnMoreModal={showLearnMoreModal}
      existingPaymentTotal={paymentCardData && utils.getFormattedPrice(paymentCardData.amount)}
      collectNewPaymentCard={collectNewPaymentCard}
      amountChargedOpposite={utils.getFormattedPrice(oppositeEstimatedTotal, { asterisk: true })}
      handleTogglePrepay={handleTogglePrepay}
      prepayStep={prepayStep}
      prepayFailed={prepayFailed}
    />
  );

  const acceptAndSubmit = (
    <>
      <div className='review__section-card__field-row review__complete-reservation__terms-row'>
        <Checkbox
          initialValue='true'
          className='review__complete-reservation__prepay--terms-and-conditions'
          name='payment_info.accept_prepay_terms_payment_section'
          label={utils.i18n('review_form_prepay_terms_and_conditions', { 0: prepayTermsLink }, { jsx: true })}
          theme={THEMES.LIGHT}
          required={utils.i18n('field_required_dont_forget_to', [
            utils.i18n('review_form_prepay_terms_and_conditions_error'),
          ])}
          validate={utils.fieldValidation.isTrueBool}
        />
      </div>
      <Button
        className='review__complete-reservation__prepay--submit-button'
        type='submit'
        disabled={disablePrepayPaylaterButton}
        data-dtm-track='review|reservation_commit|reserve_now'
      >
        {utils.i18n(isTrueModify ? 'true_modify_review_form_submit_button_label' : 'gbo_error_continue_payment')}
      </Button>
    </>
  );

  const prepayPaymentSectionLayout = {
    [CONFIRM_PREPAY]: (
      <div className='review__section-container'>
        {sectionHeader}
        <div className='review__payment-first-step'>
          <p>{utils.i18n('review_prepayment_section_next_step')}</p>
        </div>
      </div>
    ),

    [CARD_FORM]: (
      <div ref={iframeRef} className='review__section-container'>
        {sectionHeader}
        <div className='component-theme--light review__section-payment-information--active'>
          <FieldGroupCard
            titleContainerClass='review__payment-pay-now-title-container'
            title={utils.i18n('review_payment_section_title')}
          >
            {paymentSection}
            <IbmPaymentIframe onSubmit={onIframeSubmit} onFailure={onPaymentFailure} />
            <p className='review__payment-pay-now-submit-helper'>{utils.i18n('review_payment_submit_helper_text')}</p>
          </FieldGroupCard>
        </div>
      </div>
    ),

    [CARD_ALERT]: (
      <div className='review__section-container review__section-container--hold-alert'>
        <HoldCreditCardAlert showModal={showDiscardCCHoldModal} />
        <div
          className={
            'component-theme--light ' +
            'review__section-payment-information--active ' +
            'review__section-payment-information--gbo-error'
          }
        >
          <FieldGroupCard
            titleContainerClass='review__payment-pay-now-title-container'
            title={utils.i18n('review_payment_section_title')}
            themeClass='review__payment-pay-now-main-container'
          >
            {paymentSection}
            {paymentCardData && <CreditCardDetails cardDetails={paymentCardData.card_details} />}
            {acceptAndSubmit}
          </FieldGroupCard>
        </div>
      </div>
    ),

    [EXISTING_PREPAY]: (
      <div className='review__section-container review__section-container--true-modify-payment'>
        {sectionHeader}
        <div className='component-theme--light review__section-payment-information--active'>
          <FieldGroupCard
            titleContainerClass='review__payment-pay-now-title-container'
            title={utils.i18n('review_payment_section_title')}
          >
            <CreditCardDetails cardDetails={paymentCardData?.card_details} />
            {isEuropeanCountry && oppositeEstimatedTotal && (
              <Button
                className='link--text review__complete-reservation__prepay--toggle formatted-prices'
                data-dtm-track='review|pay_later|select'
                link
                onClick={handleTogglePrepay}
              >
                {utils.i18n('review_complete_reservation_switch_to_pay_later_label', {
                  0: utils.getFormattedPrice(oppositeEstimatedTotal),
                })}
              </Button>
            )}

            <PrePaymentSectionReviewReservation newTotal={utils.getFormattedPrice(estimatedTotal)} />
            {acceptAndSubmit}
          </FieldGroupCard>
        </div>
      </div>
    ),
  };

  return prepayPaymentSectionLayout[prepayStep] || null;
};

PrePayPaymentSection.propTypes = {
  estimatedTotal: PropTypes.object,
  estimatedTotalPayment: PropTypes.object,
  isForeignCurrency: PropTypes.bool,
  showLearnMoreModal: PropTypes.func,
  oppositeEstimatedTotal: PropTypes.object,
  prepayTermsLink: PropTypes.object,
  handleTogglePrepay: PropTypes.func,
  paymentCardData: PropTypes.object,
  isTrueModify: PropTypes.bool,
  prepayFailed: PropTypes.bool,
  prepayStep: PropTypes.string,
  collectNewPaymentCard: PropTypes.bool,
  onIframeSubmit: PropTypes.func.isRequired,
  onPaymentFailure: PropTypes.func,
};

export default PrePayPaymentSection;
