import PropTypes from 'prop-types';

export default {
  // modal contentProps passed through openModalWithProps
  feesArray: PropTypes.array.isRequired,
  taxesFeesTotal: PropTypes.array.isRequired,

  // containerProps
  taxesCopy: PropTypes.string,
  getTaxesPolicy: PropTypes.func.isRequired,
};
