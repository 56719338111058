import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Anchor from 'components/Anchor';
import Button from 'components/Button';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import utils from 'utils';

/**
 * MissingTripsModal - Modal that guides user in case she/he is missing a trip,
 * so they can go to look up upcoming trip, look up past trip or view costumer support
 * @param {object} props - React Props
 * @param {function} props.closeModal - handles the close and redirect function for both upcoming and past trips cta
 * @return {JSX}
 */

const MissingTripsModal = ({ closeModal }) => {
  const lookUpUpcomingTripsCta = () => {
    closeModal({ analyticsValue: utils.i18n('missing_trips_modal_look_upcoming_cta') });
    window.location = utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.FIND_A_RESERVATION);
  };

  const lookUpUpPastTripsCta = () => {
    closeModal({ analyticsValue: utils.i18n('missing_trips_modal_look_past_cta') });
    window.open(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RECEIPTS_PAGE), '_blank');
  };

  const supportCta = (
    <Anchor
      className='link'
      key={utils.i18n('missing_trips_modal_costumer_support_cta')}
      href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)}
    >
      {utils.i18n('missing_trips_modal_costumer_support_cta')}
    </Anchor>
  );

  return (
    <Modal
      modalKey={MODAL.MISSING_TRIPS_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('missing_trips_modal_title')}
    >
      <>
        <div className='padding-top--condensed modal-themed__section'>
          <p>{utils.i18n('missing_trips_modal_description', [supportCta], { jsx: true })}</p>

          <div className='modal-themed__paragraph'>
            <p>
              <Button link onClick={lookUpUpcomingTripsCta} className='link link--arrow'>
                {utils.i18n('missing_trips_modal_look_upcoming_cta')}
              </Button>
            </p>
            <p>
              <Button link onClick={lookUpUpPastTripsCta} className='link link--arrow'>
                {utils.i18n('missing_trips_modal_look_past_cta')}
              </Button>
            </p>
          </div>
        </div>
      </>
    </Modal>
  );
};

MissingTripsModal.propTypes = {
  closeModal: PropTypes.func,
};

export default MissingTripsModal;
