import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import { getDidPrepay } from 'selectors/reservationFlowSelectors';
import FormattedPrice from 'components/FormattedPrice';
import ToolTip from 'components/ToolTip';
import { unpaidRefundReservationPriceDifferenceSelector } from 'selectors/reservationSelectors';

const PrePaymentSectionReviewReservation = ({ newTotal }) => {
  const isPrepay = useSelector(getDidPrepay);
  const priceDifference = useSelector(unpaidRefundReservationPriceDifferenceSelector);
  const { difference_amount_view } = priceDifference;
  const isRefund = utils.isPriceDifferenceRefund(difference_amount_view);

  return (
    <>
      {isPrepay && (
        <div className='payment-section-review__modified-price-container'>
          <div className='payment-section-review__price-container'>
            <p className='payment-section-review__new-total'>{utils.i18n('stc_payment_information_trip_total')}:</p>
            <FormattedPrice classNames='payment-section-review__new-total-amount'>{newTotal}</FormattedPrice>
          </div>
          {difference_amount_view && (
            <div className='payment-section-review__price-container'>
              <div>
                <p className='payment-section-review__diff-total-amount-label'>
                  {utils.i18n('stc_payment_price_changes_from_updates')}:
                </p>
                <ToolTip placement='top-start'>
                  <span>{utils.i18n('review_payment_modification_difference_amount_total_tool_tip_text')}</span>
                </ToolTip>
                <p className='payment-section-review__diff-trip-total-sublabel'>
                  ({utils.i18n(isRefund ? 'addr_refund_updates_copy' : 'addr_payment_unpaid_due_copy')})
                </p>
              </div>
              <FormattedPrice classNames='payment-section-review__diff-total-amount'>
                {utils.getFormattedPrice(difference_amount_view)}
              </FormattedPrice>
            </div>
          )}
        </div>
      )}
    </>
  );
};

PrePaymentSectionReviewReservation.propTypes = {
  newTotal: PropTypes.string,
};

export default PrePaymentSectionReviewReservation;
