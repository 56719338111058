import PropTypes from 'prop-types';

export const propTypes = {
  /**
   * Regions array
   *
   * @type {Array}
   */
  regions: PropTypes.array.isRequired,
};

export const defaultProps = [];
