import React from 'react';
import { useSelector } from 'react-redux';
import THEMES from 'constants/themes';
import { showBundlesSectionSelector } from 'selectors/extrasSelectors';
import useVehicleUpgradeDetails from 'hooks/useVehicleUpgradeDetails';
import useActions from 'hooks/useActions';
import LoadingWidget from 'components/LoadingWidget';
import { upgradeCarClass } from "actions/reservation/upgradeCarClass";
import UpgradeBannerCompactLayout from './UpgradeBannerCompactLayout';

/**
 * InPathVehicleUpgradeRentalDetails - ReviewRentalDetails vehicle upgrade banner
 *
 * @return {JSX}  InPathVehicleUpgradeRentalDetails component
 */
const InPathVehicleUpgradeRentalDetails = () => {
  // InPathVehicleUpgradeRentalDetails banner should only be visible when bundles are not shown
  const visible = !useSelector(showBundlesSectionSelector);

  const { inPathUpgradeAvailable, inPathUpgradeDetails, isFechingRates } = useVehicleUpgradeDetails({
    shouldGetDetailsConditional: visible,
    // This banner doesn't need to get details if hidden, because the InPathVehicleUpgradeReview banner will handle it
  });
  const upgradeHandler = useActions(upgradeCarClass);

  const { upgradeName, upgradeMakeModelOrSimilarText, priceDifference, total, upgradeImagePath, upgradeClass } =
    inPathUpgradeDetails || {};

  return inPathUpgradeAvailable && visible ? (
    <>
      {isFechingRates && (
        <div className='in-path-upgrade in-path-upgrade-rental-details in-path-upgrade__arrow'>
          <LoadingWidget />
        </div>
      )}
      {inPathUpgradeDetails && (
        <div className='in-path-upgrade in-path-upgrade-rental-details in-path-upgrade__arrow'>
          <UpgradeBannerCompactLayout
            upgradeHandler={() => upgradeHandler(upgradeClass)}
            priceDifference={priceDifference}
            name={upgradeName}
            imagePath={upgradeImagePath}
            makeModelOrSimilarText={upgradeMakeModelOrSimilarText}
            total={total}
            buttonTheme={THEMES.DARK}
            upgradeClass={upgradeClass}
          />
        </div>
      )}
    </>
  ) : null;
};

export default InPathVehicleUpgradeRentalDetails;
