import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'components/Button';

const PolicyTab = ({ expanded, title, titleId, contentId, index, setActiveIndex, customClass }) => {
  const handleOnClick = () => {
    setActiveIndex(index, title);
  };
  const titleClass = cn(`${customClass}__toggler`, {
    'policies-link-tabs__toggler-selected': expanded,
  });

  return (
    <Button
      plain
      id={titleId}
      className={titleClass}
      onClick={handleOnClick}
      aria-label={title}
      aria-selected={expanded}
      aria-disabled={false}
      aria-controls={contentId}
      aria-expanded={expanded}
    >
      <span className='policies-link-tabs__title'>{title}</span>
    </Button>
  );
};

export default PolicyTab;

PolicyTab.propTypes = {
  isActive: PropTypes.bool,
  titleId: PropTypes.string,
  contentId: PropTypes.string,
  isVertical: PropTypes.bool,
  title: PropTypes.string,
  analyticsTitle: PropTypes.string,
  index: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

PolicyTab.defaultProps = {
  isActive: false,
  titleId: '',
  contentId: '',
  isVertical: false,
  title: '',
  index: 0,
  setActiveIndex: () => {},
};
