import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { actions as gmiActions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import GMI_SERVICE_PATHS from 'constants/gmiServicePaths';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import { modifyProfile } from 'actions/profile';
import { profileDataSelector } from 'selectors/profileSelectors';
import { breakpointSelector } from 'selectors/breakpoint';
import Form from 'components/Form/Form';
import Button from 'components/Button';
import TextField from 'components/Form/TextField';
import ServiceErrors from 'components/ServiceErrors';
import SelectField from 'components/Form/SelectField';
import PhoneNumberField from 'components/Form/PhoneNumberField';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';

/**
 * EditContactInformationForm
 *
 * @param {object} props - React Props
 * @param {boolean} props.onSubmitSuccess - Handler function for a successful form submit
 * @param {object} props.previousStepData - Data carried over from the previous step - in this case, DL country/state info
 * @param {function} props.footer - Render Props function to display the modal's footer
 * @param {array<function>} props.decorators - Array of decorator function to be passed into the final form instance
 * @param {function} props.scrollToModalTop - Passed from the Modal wrapper - handles scrolling to top when form renders
 *
 * @return {JSX} EditContactInformationForm jsx component
 */
const EditContactInformationForm = ({ onSubmitSuccess, previousStepData, footer, decorators, scrollToModalTop }) => {
  const isCopyPhoneAvailable = previousStepData?.copy_phones;
  const isCopyAddressAvailable = previousStepData?.copy_address_profile;

  const [isCopyingPhones, setCopyPhones] = useState(isCopyPhoneAvailable);
  const [isCopyingAddressProfile, setCopyAddressProfile] = useState(isCopyAddressAvailable);

  const { isMobile } = useSelector(breakpointSelector);
  const profileData = useSelector((state) =>
    profileDataSelector(state, { last_name: true, address_profile: true, phones: true })
  );

  const cleanMessagesAction = useActions(gmiActions.cleanMessages);
  const modifyProfileAction = useActions(modifyProfile);

  const { address_profile, phones, last_name } = profileData || {};
  const { street_addresses, city } = address_profile || {};

  // if there is DL data coming from the previous wizard modal step, it overwrites enroll info
  const defaultCountry = previousStepData?.drivers_license?.country_code || address_profile?.country_code;
  const defaultStateProvince =
    previousStepData?.drivers_license?.country_subdivision_code || address_profile?.country_subdivision_code;

  const { selectedCountry, setSelectedCountry, countryConfig, countries, statesOrProvinces } =
    useCountryFieldsConfig(defaultCountry);

  useEffect(() => {
    scrollToModalTop();

    // Clean GMI error messages on form unmount
    return cleanMessagesAction;
  }, []);

  const initialValues = useRef({
    phone_set: phones,
    address_line_1: street_addresses?.[0],
    address_line_2: street_addresses?.[1],
    country_code: defaultCountry,
    country_subdivision_code: defaultStateProvince,
    city,
    postal: address_profile?.postal,
  });

  const [addressExpanded, setAddressExpanded] = useState(!!initialValues.current.address_line_2);
  const { shouldShowSubdivisionField } = countryConfig;

  const setCountry = (event) => {
    const countryObject = countries.find(({ country_code }) => country_code === event?.target?.value);
    setSelectedCountry(countryObject);
  };

  const toggleAddressLine2 = () => {
    setAddressExpanded(!addressExpanded);
  };

  const toggleCopyPhones = () => {
    setCopyPhones(!isCopyingPhones);
  };

  const toggleCopyAddressProfile = () => {
    setCopyAddressProfile(!isCopyingAddressProfile);
  };

  const renderPhoneCopy = () => (
    <p className='my-profile__modal-form__field-copy'>{utils.i18n('my_profile_edit_contact_information_phone_copy')}</p>
  );

  const onSubmit = (formData) => {
    const { address_line_1, address_line_2, ...otherData } = formData;

    const payload = {
      copy_from_individual_id: previousStepData?.copy_from_individual_id,
      last_name,
    };

    if (isCopyingPhones) {
      payload.copy_phones = true;
    } else {
      const phoneData = utils.profile.contactInfoPayloadParser(formData);
      payload.contact = {
        phones: phoneData.phones,
      };
    }

    if (isCopyingAddressProfile) {
      payload.copy_address_profile = true;
    } else {
      const streetAddresses = [address_line_1];
      if (address_line_2) {
        streetAddresses.push(address_line_2);
      }
      payload.address = {
        street_addresses: streetAddresses,
        ...otherData,
      };
    }

    modifyProfileAction(payload).then((res) => {
      // if the response contains no error messages, execute the onSubmitSuccess
      // error messages will already be printed inside the component at ServiceErrors
      if (!res?.messages) {
        onSubmitSuccess();
      }
    });
  };

  const shouldShowRequiredNote = !isCopyingPhones || !isCopyingAddressProfile;
  const matchedProfileAddress = previousStepData?.address?.street_addresses?.join(' ') || '';
  const matchedProfilePhone = utils.profile.getPhoneByPriority(previousStepData?.phones, 1)?.phone_number;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues.current}
      decorators={decorators}
      subscription={{ submitting: true, pristine: true, submitFailed: true }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className='my-profile__modal-form component-theme--light' noValidate>
          {shouldShowRequiredNote && (
            <p className='my-profile__modal-form__required-note'>
              {utils.i18n('my_profile_edit_contact_information_modal_required_note')}
            </p>
          )}
          <ServiceErrors statePath={GMI_SERVICE_PATHS.MODIFY_PROFILE} />

          {isCopyPhoneAvailable && (
            <div className='my-profile__modal-form__field-row my-profile__modal-form__display-section'>
              <div className='my-profile__modal-form__display-label'>
                {isCopyingPhones
                  ? utils.i18n('my_profile_edit_contact_information_phone_label')
                  : utils.i18n('my_profile_edit_contact_information_update_phone_label')}

                <Button
                  onClick={toggleCopyPhones}
                  className='my-profile-section-header__edit-cta'
                  link
                  linkText
                  data-dtm-track={utils.analytics.dtm(
                    ANALYTICS.PROFILE,
                    isCopyingPhones ? 'phone_number' : 'update_phone_number',
                    isCopyingPhones ? ANALYTICS.UPDATE : ANALYTICS.CANCEL
                  )}
                >
                  {utils.i18n(isCopyingPhones ? 'common_update' : 'common_cancel')}
                </Button>
              </div>
              {isCopyingPhones && <p>{matchedProfilePhone}</p>}
            </div>
          )}
          {!isCopyingPhones && (
            <div className='my-profile__modal-form__field-row'>
              <PhoneNumberField
                name={`phone_set[0].phone_number`}
                label={utils.i18n('my_profile_edit_contact_information_phone_label')}
                required
                fill
                initialValueButton={initialValues.current.phone_set?.[0]?.phone_number}
                validations={[utils.fieldValidation.phone, utils.fieldValidation.checkPhoneCode]}
              />
              {!isCopyPhoneAvailable && isMobile && renderPhoneCopy()}
              <PhoneNumberField
                name={`phone_set[1].phone_number`}
                label={utils.i18n('my_profile_edit_contact_information_alt_phone_label')}
                fill
                initialValueButton={initialValues.current.phone_set?.[1]?.phone_number}
                validations={[utils.fieldValidation.altPhoneNumberValidation]}
              />
            </div>
          )}

          {!isCopyPhoneAvailable && !isMobile && renderPhoneCopy()}

          {isCopyAddressAvailable && (
            <div className='my-profile__modal-form__field-row my-profile__modal-form__display-section'>
              <div className='my-profile__modal-form__display-label'>
                {utils.i18n('common_address')}

                <Button
                  onClick={toggleCopyAddressProfile}
                  className='my-profile-section-header__edit-cta'
                  link
                  linkText
                  data-dtm-track={utils.analytics.dtm(
                    ANALYTICS.PROFILE,
                    isCopyingAddressProfile ? 'address' : 'update_address',
                    isCopyingAddressProfile ? ANALYTICS.UPDATE : ANALYTICS.CANCEL
                  )}
                >
                  {utils.i18n(isCopyingAddressProfile ? 'common_update' : 'common_cancel')}
                </Button>
              </div>
              {isCopyingAddressProfile && <p>{matchedProfileAddress}</p>}
            </div>
          )}

          {!isCopyingAddressProfile && (
            <>
              <TextField
                name='address_line_1'
                label={utils.i18n('my_profile_edit_contact_information_address_line_1_label')}
                className='my-profile__modal-form__full-width-field'
                required
                fill
                initialValueButton={initialValues.current.address_line_1}
              />

              {addressExpanded ? (
                <TextField
                  name='address_line_2'
                  label={utils.i18n('my_profile_edit_contact_information_address_line_2_label')}
                  className='my-profile__modal-form__full-width-field'
                  fill
                  initialValueButton={initialValues.current.address_line_2}
                />
              ) : (
                <Button className='my-profile__modal-form__expand-button' onClick={toggleAddressLine2} link>
                  {utils.i18n('my_profile_edit_contact_information_address_line_2_button')}
                </Button>
              )}

              <div className='my-profile__modal-form__field-row'>
                {!!countries?.length && (
                  <SelectField
                    id='country_code'
                    name='country_code'
                    label={utils.i18n('my_profile_edit_contact_information_country_label')}
                    onChange={setCountry}
                    options={countries}
                    valueKey='country_code'
                    labelKey='country_name'
                    disabled={!countries?.length}
                    required
                    includeHiddenOption
                  />
                )}
              </div>

              <div className='my-profile__modal-form__field-row'>
                <TextField
                  name='city'
                  label={utils.i18n('my_profile_edit_contact_information_city_label')}
                  required
                  fill
                  initialValueButton={initialValues.current.city}
                />

                {shouldShowSubdivisionField && (
                  <SelectField
                    id='country_subdivision_code'
                    name='country_subdivision_code'
                    label={utils.i18n('my_profile_edit_contact_information_state_province_label')}
                    options={statesOrProvinces[selectedCountry.country_code]}
                    valueKey='country_subdivision_code'
                    labelKey='country_subdivision_name'
                    required
                    includeHiddenOption={!defaultStateProvince}
                    maskedOption={defaultStateProvince}
                  />
                )}
              </div>

              <div className='my-profile__modal-form__field-row'>
                <TextField
                  name='postal'
                  label={utils.i18n('my_profile_edit_contact_information_postal_code_label')}
                  required
                  fill
                  initialValueButton={initialValues.current.postal}
                />
              </div>
            </>
          )}

          {footer(handleSubmit)}
        </form>
      )}
    </Form>
  );
};

EditContactInformationForm.defaultProps = {
  decorators: [],
  scrollToModalTop: () => {},
};

EditContactInformationForm.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  previousStepData: PropTypes.object,
  footer: PropTypes.func.isRequired,
  decorators: PropTypes.arrayOf(PropTypes.func),
  scrollToModalTop: PropTypes.func,
};

export default EditContactInformationForm;
