import React from 'react';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const EligibilityLossModal = () => (
  <Modal
    modalKey={MODAL.STC_ELIGIBILITY_LOSS}
    header={utils.i18n('stc_eligibility_loss_title')}
    theme={MODAL_THEMES.WHITE}>
    {({ handleClose }) => (
      <>
        <p className='modal-themed__paragraph'>{utils.i18n('stc_eligibility_loss_text')}</p>
        <ModalFooter
          buttons={[
            {
              type: MODAL_BUTTON_TYPES.ACCEPT,
              label: utils.i18n('common_continue'),
              handler: () => handleClose({ analyticsValue: ANALYTICS.CONTINUE }),
              largeButton: true,
            },
          ]}
        />
      </>
    )}
  </Modal>
);

export default EligibilityLossModal;
