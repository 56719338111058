import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { capitalize } from 'utils/string';
import {
  getReservationRenterInfoFirstName,
  getReservationRenterInfoLastName,
  getReservationRenterInfoEmail,
  getReservationRenterInfoPhoneNumber,
} from 'selectors/reservationSelectors';

/**
 * STCConfirmationRenterInfo - Skip The Counter Confirmation Print Page sub component (bottom left of print page)
 * @param {object} props - React Props
 * @param {function} props.driversList - List of optional Additional Drivers
 * @return {JSX}
 */

const STCConfirmationRenterInfo = ({ driversList }) => {
  const reservationfirstName = useSelector(state => getReservationRenterInfoFirstName(state));
  const reservationLastName = useSelector(state => getReservationRenterInfoLastName(state));
  const reservationEmail = useSelector(state => getReservationRenterInfoEmail(state));
  const reservationPhone = useSelector(state => getReservationRenterInfoPhoneNumber(state));

  const renderDetailLine = (labelKey, value) => (
    <li>
      {i18n(labelKey)}: {value}
    </li>
  );

  return (
    <>
      <span className='skip-the-counter-confirmation__print-info-location-item-title'>
        {i18n('check_in_step_profile_renter_info')}
      </span>

      <ul className='skip-the-counter-confirmation__print-info-location-item-list'>
        {reservationfirstName && reservationLastName && (
          <li className='check-in-review__paragraph'>{`${capitalize(reservationfirstName)} ${capitalize(
            reservationLastName
          )}`}</li>
        )}
        {reservationEmail && renderDetailLine('common_email', reservationEmail)}
        {reservationPhone && renderDetailLine('common_phone_number', reservationPhone)}
      </ul>
      {!!driversList?.length && (
        <div>
          <span className='skip-the-counter-confirmation__print-info-location-item-subtitle'>
            {i18n('common_additional_drivers')}:
          </span>

          <ul className='skip-the-counter-confirmation__print-info-location-item-list'>
            {driversList.map(driver => (
              <li key={driver.first_name}>{`${capitalize(driver.first_name)} ${capitalize(driver.last_name)}`}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

STCConfirmationRenterInfo.propTypes = {
  driversList: PropTypes.array,
};

export default STCConfirmationRenterInfo;
