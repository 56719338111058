import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';

export const propTypes = {
  totalBranches: PropTypes.number.isRequired,
  countries: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  mapImage: PropTypes.string,
};

export const defaultProps = setDefaultProps();
