import { connect } from 'react-redux';
import { logout } from 'actions/authentication';
import { MODAL } from 'constants';
import { breakpointSelector } from 'selectors';
import { openModal } from "actions/modal/openModal";
import AuthenticatedFlyout from './AuthenticatedFlyout';

const mapStateToProps = state => ({
  breakpoint: breakpointSelector(state),
});

const mapDispatchToProps = {
  logout,
  openLogoutModal: () => openModal(MODAL.RESFLOW_LOGOUT_MODAL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedFlyout);
