import React from 'react';
import GenericNotificationTag from 'components/GenericNotificationTag';

const SoldOutTileBanner = () => (
  <div className='map-location-detail-tile__sold-out-banner-wrapper'>
    <GenericNotificationTag
      bannerClass='map-location-detail-tile__sold-out-banner'
      messageClass='generic-notification-tag__sold-out-icon'
      message='Sold out - Requires travel adjustments'
    />
  </div>
);

export default SoldOutTileBanner;
