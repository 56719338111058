import PropTypes from 'prop-types';
import { ListPropTypes, setDefaultProps } from 'data/sharedProps';

export const propTypes = Object.assign(ListPropTypes, {
  /**
   * Items to render based on array
   *
   * @type {Array}
   */
  listItems: PropTypes.array,
});

export const defaultProps = setDefaultProps({
  listItems: [],
});
