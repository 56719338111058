import { connect } from 'react-redux';
import utils from 'utils';
import { sessionReservationObjectSelector, getModifyFlag } from 'selectors/reservationSelectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import ReservationFlowBookingWidget from './ReservationFlowBookingWidget';

const mapStateToProps = state => ({
  isTrueModify: getModifyFlag(state),
  reservationObject: sessionReservationObjectSelector(state),
  isFetchingSession: isFetchingEndpointSelector(state, { endpoint: 'session/current' }),
});

export default connect(mapStateToProps)(utils.toJS(ReservationFlowBookingWidget));
