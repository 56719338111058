import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import utils from 'utils';
import Button from 'components/Button';
import ServiceErrors from 'components/ServiceErrors';
import { useHistory } from 'react-router-dom';
import { VIEW_MODIFY_CANCEL_VIEWS, LOOK_UP_BY_TYPE } from 'components/ViewModifyCancelApp/data/constants';
import { analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import circleEmail from 'images/circle-email.svg';
import circlePhone from 'images/circle-phone.svg';
import { getMaskedPhoneNumber, getMaskedEmail } from 'utils/phoneInput';

const ConfirmationLookUpBy = (props) => {
  const {
    lookupTypeState,
    formatedPhone,
    email,
    userData,
    searchAction,
    verifyByConfirmationNumberAction,
    isConfirmNumberLookupType,
    lookupType,
  } = props;
  const history = useHistory();

  const [isSendAnotherActionDisable, setIsSendAnotherActionDisable] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [lastAttemptTime, setLastAttemptTime] = useState(null);

  const getPhoneValue = (phoneNum) => {
    const isMasked = utils.fieldValidation.isMasked(phoneNum);
    if (isMasked) {
      return phoneNum;
    }
    return getMaskedPhoneNumber(phoneNum);
  };

  const getEmailValue = (emailId) => {
    const isMasked = utils.fieldValidation.isMasked(emailId);
    if (isMasked) {
      return emailId;
    }
    return getMaskedEmail(emailId);
  };

  let titleText = 'check_text_messages';
  let mainText = 'match_rental_details';
  let cardTitleText = 'didnt_get_text';
  let cardMainText = 'cant_receive_sms';
  let sendAnotherText = 'send_another_text';
  let tryAnotherMethodText = 'try_another_lookup';
  let sentMessage = 'message_sent';

  let phoneOrEmailValue =
    lookupType === LOOK_UP_BY_TYPE.CONF_NUMBER && lookupTypeState === LOOK_UP_BY_TYPE.EMAIL
      ? getEmailValue(email)
      : getPhoneValue(formatedPhone);

  if (lookupType !== LOOK_UP_BY_TYPE.CONF_NUMBER) {
    phoneOrEmailValue = lookupTypeState === LOOK_UP_BY_TYPE.EMAIL ? email : formatedPhone;
  }

  if (lookupTypeState === LOOK_UP_BY_TYPE.EMAIL) {
    titleText = 'check_email';
    mainText = 'match_rental_details_email';
    cardTitleText = 'didnt_get_email';
    cardMainText = 'check_spam';
    sendAnotherText = 'send_another_email';
    tryAnotherMethodText = 'try_another_lookup';
    sentMessage = 'email_sent';
  }

  const handleSendAnotherAction = () => {
    const now = Date.now();
    const fiveMinutesAgo = now - 1000 * 60 * 5;

    // If the last attempt was more than 5 minutes ago, reset the attempts count
    if (lastAttemptTime < fiveMinutesAgo) {
      setAttempts(0);
    }

    // const lookupTypeState = isEmail ? LOOK_UP_BY_TYPE.EMAIL : LOOK_UP_BY_TYPE.PHONE;

    if (userData && lookupTypeState === LOOK_UP_BY_TYPE.EMAIL) {
      userData.email = email;
      analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.SEND_ANOTHER_EMAIL);
    }
    if (lookupTypeState === LOOK_UP_BY_TYPE.PHONE) {
      analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.SEND_ANOTHER_TEXT_MESSAGE);
    }

    const lookupAction = isConfirmNumberLookupType
      ? verifyByConfirmationNumberAction(lookupTypeState, userData)
      : searchAction(lookupTypeState, userData);
    const searchActionPromise = Promise.resolve(lookupAction);
    searchActionPromise
      .then((response) => {
        if (!response.messages) {
          setIsSendAnotherActionDisable(true);

          setTimeout(() => {
            setIsSendAnotherActionDisable(false);
          }, 1000 * 20); // Enable the button after 20 seconds
        }
      })
      .finally(() => {
        setAttempts(attempts + 1);
        setLastAttemptTime(now);
      });
  };

  const handleRedirect = () => {
    if (lookupTypeState === LOOK_UP_BY_TYPE.EMAIL) {
      return history.push(VIEW_MODIFY_CANCEL_VIEWS.LOOKUP_BY.RENTAL_BY_EMAIL);
    }
    return history.push(VIEW_MODIFY_CANCEL_VIEWS.LOOKUP_BY.RENTAL_BY_PHONE);
  };

  const handleTryAnotherLookupMethod = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.TRY_ANOTHER_LOOKUP);
    setTimeout(() => handleRedirect(), 0);
  };

  const handleGotItCTA = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.BUTTON_TEXT_GOT_IT);
    setTimeout(() => handleRedirect(), 0);
  };

  return (
    <div className='email-phone-lookup theme--dark-blue'>
      <div className='row'>
        <div className='email-phone-lookup__main-text'>
          <ServiceErrors />

          <h1>{i18n(titleText)}</h1>
          <p>{i18n(mainText)}</p>
          <p className='email-phone-lookup__phone'>{phoneOrEmailValue}</p>
        </div>
        <div className='email-phone-lookup__card'>
          <div className='email-phone-lookup__card-container'>
            <h3 className='email-phone-lookup__card-title'>{i18n(cardTitleText)}</h3>
            <p>{i18n(cardMainText)}</p>
            <ul className='email-phone-lookup__card-list'>
              <li>
                <Button onClick={handleSendAnotherAction} disabled={isSendAnotherActionDisable} link>
                  {!isSendAnotherActionDisable ? i18n(sendAnotherText) : i18n(sentMessage)}
                </Button>
              </li>
              <li>
                <Button onClick={handleTryAnotherLookupMethod} link>
                  {i18n(tryAnotherMethodText)}
                </Button>
              </li>
            </ul>

            <Button onClick={handleGotItCTA}>{i18n('got_it')}</Button>
          </div>
          {lookupTypeState === LOOK_UP_BY_TYPE.PHONE && (
            <img className='email-phone-lookup__card-image' src={circlePhone} />
          )}
          {lookupTypeState === LOOK_UP_BY_TYPE.EMAIL && (
            <img className='email-phone-lookup__card-image' src={circleEmail} />
          )}
        </div>
      </div>
    </div>
  );
};

ConfirmationLookUpBy.propTypes = {
  lookupTypeState: PropTypes.string,
  searchAction: PropTypes.func,
  verifyByConfirmationNumberAction: PropTypes.func,
  isConfirmNumberLookupType: PropTypes.bool,
};

export default ConfirmationLookUpBy;
