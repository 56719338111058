import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { loadingRedirect } from 'utils/redirect';
import utils from 'utils';
import { i18n } from 'utils/i18n';
import { config } from 'utils/config';
import { dtm } from 'utils/analytics';
import cn from 'classnames';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import CUSTOM_PATH from 'constants/customPathCheckIn';
import CHECKIN from 'constants/checkin';
import ANALYTICS from 'constants/analytics';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import {
  onlineCheckinExternalLinkSelector,
  counterByPassExternalLinkSelector,
} from 'selectors/reservationFlowSelectors';
import ToolTip from 'components/ToolTip';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import LearnMoreAboutSkipTheCounterModal from 'components/modals/LearnMoreAboutSkipTheCounterModal';
import LearnMoreAboutOCIActivatedModal from 'components/modals/LearnMoreAboutOCIActivatedModal';
import LearnMoreAboutOCIInactiveModal from 'components/modals/LearnMoreAboutOCIInactiveModal';
import SignInModal from 'components/modals/SignInModal';
import CheckInLoyaltyConflictModal from 'components/modals/CheckInLoyaltyConflictModal';
import WayfindingsModal from 'components/modals/WayfindingsModal';

const { STC_ORIGIN, STC_PATH_CONFIG } = SKIP_THE_COUNTER;
const { CUSTOM_PATH_CONFIG } = CUSTOM_PATH;
const { CHECKIN_ORIGIN } = CHECKIN;
/**
 * Confirmation and Rental Details OCI status and actions for the reservation in session
 *
 * @param {object} props - the React props passed into this component
 * @param {boolean} props.isCheckedIn - Is OCI already activated for this reservation?
 * @param {boolean} props.isPickupStatusAddedAutomatically - Is OCI auto activated by profile data?
 * @param {boolean} props.additionalDriverDataNotProvided - Is Additional Driver Data not provided? (future work)
 * @param {boolean} props.isSkipTheCounterEligible - Is this reservation eligible for Skip the Counter?
 * @param {boolean} props.isSkipTheCounterCompleted - Is this reservation already Skipping the Counter?
 * @param {boolean} props.isFoundRental - Is this reservation looked up or was it just confirmed?
 * @param {function} props.openLearnMoreAboutSkipTheCounterModal - Open the Learn More modal about Skip the Counter
 * @param {function} props.openLearnMoreAboutOCIInactiveModal - Open the Learn More modal about Accelerated Check in with inactive status
 * @param {function} props.openLearnMoreAboutOCIActivatedModal - Open the Learn More modal about Accelerated Check in with activated status
 * @param {function} props.checkinStart - Start OCI flow for this reservation
 * @param {function} props.skipTheCounterStart - Start STC flow for this reservation
 *
 * @return {JSX}
 */
const ConfirmationHeaderCheckInStatus = ({
  isCheckedIn,
  isPickupStatusAddedAutomatically,
  // additionalDriverDataNotProvided,
  isSkipTheCounterEligible,
  isSkipTheCounterCompleted,
  isFoundRental,
  openLearnMoreAboutSkipTheCounterModal,
  openLearnMoreAboutOCIInactiveModal,
  openLearnMoreAboutOCIActivatedModal,
  checkinStart,
  skipTheCounterStart,
  isOnlineCheckInEligible,
}) => {
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const onlineCheckinExternalLink = useSelector(onlineCheckinExternalLinkSelector);
  const counterByPassExternalLink = useSelector(counterByPassExternalLinkSelector);

  const isCustomPathEnabled = config.getIsCustomPathEnabled();

  const titleText = i18n(
    isSkipTheCounterCompleted ? 'trip_card_title_skip_the_counter' : 'trip_card_title_accelerated_checkin'
  );
  const titleLabel = i18n(isCheckedIn ? 'trip_card_activated_label' : 'trip_card_not_activated_label');

  const checkinOrigin = isFoundRental ? CHECKIN_ORIGIN.RESERVATION_DETAILS : CHECKIN_ORIGIN.CONFIRMATION;

  const skipTheCounterOrigin = isFoundRental ? STC_ORIGIN.RESERVATION_DETAILS : STC_ORIGIN.CONFIRMATION;

  const stcPrintTicket = () =>
    loadingRedirect(
      WINDOW_OBJECT_KEYS[isCustomPathReservation ? 'CUSTOM_PATH_FLOW' : 'SKIP_THE_COUNTER_FLOW'],
      true,
      isCustomPathReservation ? CUSTOM_PATH_CONFIG.confirmation[0] : STC_PATH_CONFIG.confirmation[0]
    );

  const stcActionPrintTicket = () => {
    loadingRedirect(
      WINDOW_OBJECT_KEYS[isCustomPathReservation ? 'CUSTOM_PATH_FLOW' : 'SKIP_THE_COUNTER_FLOW'],
      true,
      isCustomPathReservation ? CUSTOM_PATH_CONFIG.confirmation[0] : STC_PATH_CONFIG.confirmation[0]
    );
    // analytics interaction for stc view ticket
    utils.analytics.interaction(ANALYTICS.UI_BUTTON, 'stc', 'view ticket');
  };

  const renderOCILearnMoreButton = () => (
    <Button
      onClick={isCheckedIn ? openLearnMoreAboutOCIActivatedModal : openLearnMoreAboutOCIInactiveModal}
      link
      className='confirmation-oci-status__learn-more'>
      {i18n('trip_card_learn_more')}
    </Button>
  );

  return (
    <section className='confirmation-oci-status'>
      <div className='confirmation-oci-status__header'>
        <p className='confirmation-oci-status__header-label'>
          {i18n('trip_card_pickup_status')}
          {isPickupStatusAddedAutomatically && (
            <span>
              {i18n('trip_card_added_automatically')}
              <ToolTip>
                <label>{i18n('trip_card_added_automatically_tooltip')}</label>
              </ToolTip>
            </span>
          )}
        </p>
      </div>

      <div
        className={cn('confirmation-oci-status__title', {
          'confirmation-oci-status__title--activated': isCheckedIn && !isSkipTheCounterCompleted,
          'confirmation-oci-status__title--skip-counter': isSkipTheCounterCompleted,
        })}>
        <h4 className='confirmation-oci-status__title-text' aria-label={`${titleText} ${titleLabel}`}>
          {titleText}
        </h4>
        {!isSkipTheCounterCompleted && (
          <span
            className={cn('confirmation-oci-status__label', {
              'confirmation-oci-status__label--activated': isCheckedIn,
            })}
            aria-hidden='true'>
            {titleLabel}
          </span>
        )}
        {isCheckedIn && !isSkipTheCounterCompleted && renderOCILearnMoreButton()}
      </div>

      {/* Save Time and Activate OCI */}
      {!isCheckedIn && !isSkipTheCounterCompleted && isOnlineCheckInEligible && (
        <div className='confirmation-oci-status__info'>
          <div className='confirmation-oci-status__info-inline'>
            <p className='confirmation-oci-status__text'>{i18n('trip_card_driver_license_description')}&nbsp;</p>
            {renderOCILearnMoreButton()}
          </div>

          {isCustomPathReservation && !isCustomPathEnabled ? ( // If custom path feature flag is off, send to WNG
            <Anchor
              className='button confirmation-oci-status__action-external-link'
              href={onlineCheckinExternalLink.link}
              isExternal={true}>
              {i18n('trip_card_save_time_activate_button')}
            </Anchor>
          ) : (
            <Button
              onClick={() => checkinStart(checkinOrigin)}
              className='confirmation-oci-status__action-button '
              // TODO: Update this analytics below
              data-dtm-track='button|confirmation|check_in'>
              {i18n('trip_card_save_time_activate_button')}
            </Button>
          )}
        </div>
      )}

      {/* Skip the Counter available */}
      {!isSkipTheCounterCompleted && isSkipTheCounterEligible && isCheckedIn && (
        <div className='confirmation-oci-status__skip-counter'>
          {isCustomPathReservation && !isCustomPathEnabled ? ( // If custom path feature flag is off, send to WNG
            <Anchor
              className='link confirmation-oci-status__skip-counter-external-link'
              href={counterByPassExternalLink.link}
              isExternal={true}>
              {i18n('trip_card_skip_the_counter')}
            </Anchor>
          ) : (
            <Button
              className='confirmation-oci-status__skip-counter-button'
              onClick={() => skipTheCounterStart(skipTheCounterOrigin)}
              data-dtm-track='check_in|complete|skip_counter'>
              {i18n('trip_card_skip_the_counter')}
            </Button>
          )}
          <p className='confirmation-oci-status__text'>{i18n('trip_card_skip_the_counter_description')}&nbsp;</p>
          <Button
            link
            className='confirmation-oci-status__learn-more'
            // TODO: Update this analytics below
            // data-dtm-track={utils.analytics.dtm(ANALYTICS.MY_TRIPS, ANALYTICS.UPCOMING_TRIPS, 'view_ticket')}
            onClick={openLearnMoreAboutSkipTheCounterModal}>
            {i18n('trip_card_learn_more')}
          </Button>
        </div>
      )}

      {/* Skip the Counter not eligible and checked in */}
      {!isSkipTheCounterCompleted && !isSkipTheCounterEligible && isCheckedIn && (
        <div className='confirmation-oci-status__info'>
          <p className='confirmation-oci-status__text'>{i18n('trip_card_skip_the_counter_not_eligible_description')}</p>
        </div>
      )}

      {/* Skipping the Counter */}
      {isSkipTheCounterCompleted && !isCheckedIn && (
        <div className='confirmation-oci-status__info'>
          <div className='confirmation-oci-status__info-inline'>
            <p className='confirmation-oci-status__text'>
              {i18n('trip_card_skip_the_counter_instructions')}
              &nbsp;
              {/* Let user go to STC confirmation if the flag is on */}
              {(!isCustomPathReservation || (isCustomPathReservation && isCustomPathEnabled)) && (
                <Anchor
                  link
                  className='confirmation-oci-status__learn-more'
                  onClick={stcPrintTicket}
                  data-dtm-track={dtm(
                    isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.RETAIL,
                    ANALYTICS.CONFIRMATION,
                    'view_pickup_instructions'
                  )}>
                  {i18n('trip_card_skip_the_counter_read_instructions')}
                </Anchor>
              )}
            </p>
          </div>
          {/* Let user go to STC confirmation if the flag is on */}
          {(!isCustomPathReservation || (isCustomPathReservation && isCustomPathEnabled)) && (
            <Button
              onClick={stcActionPrintTicket}
              className='confirmation-oci-status__action-button'
              data-dtm-track={dtm(
                isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.RETAIL,
                ANALYTICS.CONFIRMATION,
                'view_pickup_instructions'
              )}>
              {i18n('stc_view_ticket')}
            </Button>
          )}
        </div>
      )}

      <LearnMoreAboutSkipTheCounterModal />
      <LearnMoreAboutOCIInactiveModal />
      <LearnMoreAboutOCIActivatedModal />
      <SignInModal />
      <CheckInLoyaltyConflictModal />
      <WayfindingsModal />
    </section>
  );
};

ConfirmationHeaderCheckInStatus.propTypes = {
  isCheckedIn: PropTypes.bool,
  isPickupStatusAddedAutomatically: PropTypes.bool,
  additionalDriverDataNotProvided: PropTypes.bool,
  isSkipTheCounterEligible: PropTypes.bool,
  isSkipTheCounterCompleted: PropTypes.bool,
  isFoundRental: PropTypes.bool,
  openLearnMoreAboutSkipTheCounterModal: PropTypes.func,
  openLearnMoreAboutOCIInactiveModal: PropTypes.func,
  openLearnMoreAboutOCIActivatedModal: PropTypes.func,
  checkinStart: PropTypes.func,
  skipTheCounterStart: PropTypes.func,
  isOnlineCheckInEligible: PropTypes.bool,
};

export default ConfirmationHeaderCheckInStatus;
