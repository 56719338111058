import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import useAdditionalDriversList from 'hooks/useAdditionalDriversList';
import { isSkipTheCounterCompletedReservationSelector } from 'selectors/reservationFlowSelectors';
import FieldGroupCard from 'components/ReservationFlow/Review/FieldGroupCard';
import RemoveDriverModal from 'components/modals/AdditionalDriverModals/RemoveDriverModal';
import AdditionalDriverDetailsModal from 'components/modals/AdditionalDriverModals/AdditionalDriverDetailsModal';
import AddAdditionalDriverModal from 'components/modals/AdditionalDriverModals/AddAdditionalDriverModal';
import TOOLTIP_THEMES from 'constants/tooltipThemes';
import ToolTip from 'components/ToolTip';
import AddAdditionalDriverButton from './AddAdditionalDriverButton';
import DriverListItem from './DriverListItem';
import LearnMoreCta from './LearnMoreCta';
import OtherDriverCost from './OtherDriverCost';
import SpouseDriverList from './SpouseDriverList';

/**
 * Additional Driver
 * This section displays information about adding additional drivers to the reservation
 * It allows adding, deleting, editing additional drivers
 *
 * @param {object} props - react props
 * @param {bool} props.isTrueModify - boolean flag indicating we are in true modify flow
 * @return {JSX}
 */
const AdditionalDrivers = ({ isTrueModify }) => {
  const {
    driversList,
    allowMoreDrivers,
    maxAdditionalDrivers,
    numberOfFreeDrivers,
    otherDriverCost,
    fees,
    rateType,
    totalPrice,
  } = useAdditionalDriversList();

  const isSkippingTheCounter = useSelector(isSkipTheCounterCompletedReservationSelector);

  if (!maxAdditionalDrivers) {
    return null;
  }

  return (
    <FieldGroupCard title={utils.i18n('common_additional_drivers')} optionalLabel>
      <LearnMoreCta />
      <SpouseDriverList />
      <OtherDriverCost />

      {driversList.length > 0 && (
        <div className='additional-drivers__drivers-list'>
          <p className='additional-drivers__drivers-count'>
            {utils.i18n('review_additional_drivers_count', [driversList.length])}

            <span className='additional-drivers__other-driver-tooltip'>
              <ToolTip theme={TOOLTIP_THEMES.REGULAR_SLATE} placement='top-start'>
                <label>{utils.i18n('additional_driver_tooltip')}</label>
              </ToolTip>
            </span>
          </p>

          {isTrueModify && (
            <p className='additional-drivers__driver-list-description'>
              {utils.i18n('additional_driver_true_modify_description')}
            </p>
          )}

          {driversList.map((driver, idx) => (
            <DriverListItem
              key={`${driver.first_name}-${driver.last_name}`}
              driver={driver}
              cost={otherDriverCost}
              rateType={rateType}
              totalPrice={totalPrice}
              included={numberOfFreeDrivers - idx > 0}
              addDriverDetails={isTrueModify}
              fees={fees}
            />
          ))}
        </div>
      )}

      {allowMoreDrivers ? (
        <AddAdditionalDriverButton
          numDriversRemaining={Math.max(numberOfFreeDrivers - driversList.length, 0)}
          isSTCFlow={isSkippingTheCounter}
        />
      ) : (
        <p className='additional-drivers__max-added'>
          {utils.pluralHandler(
            maxAdditionalDrivers,
            'review_additional_drivers_reached_max_drivers_singular',
            'review_additional_drivers_reached_max_drivers_plural',
            false,
            maxAdditionalDrivers
          )}
        </p>
      )}
      <RemoveDriverModal />
      <AddAdditionalDriverModal />
      <AdditionalDriverDetailsModal />
    </FieldGroupCard>
  );
};

AdditionalDrivers.propTypes = {
  isTrueModify: PropTypes.bool,
};

export default AdditionalDrivers;
