import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { i18n } from 'utils/i18n';
import useActions from 'hooks/useActions';
import { forgotUsernameProfile } from 'actions/profile';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import ANALYTICS from 'constants/analytics';
import GMI_SERVICE_PATHS from 'constants/gmiServicePaths';
import TextField from 'components/Form/TextField';
import Button from 'components/Button';
import FormWrapper from '../FormWrapper/FormWrapper';

const ForgotUsernameForm = () => {
  const forgotUserNameAction = useActions(forgotUsernameProfile);
  const isFetchingForgotUsername = useSelector((state) =>
    isFetchingEndpointSelector(state, { endpoint: 'profile/forgotUsername' })
  );

  const handleFormSubmit = (formData) => {
    forgotUserNameAction(formData);
    utils.analytics.interaction(ANALYTICS.UI_BUTTON, ANALYTICS.FORGOT_USERNAME, ANALYTICS.SUBMIT);
  };

  return (
    <FormWrapper
      handleFormSubmit={handleFormSubmit}
      formTitle={i18n('enrollment_form_account_information')}
      statePath={GMI_SERVICE_PATHS.FORGOT_USERNAME}
    >
      <div className='form-wrapper__field-row'>
        <TextField
          name='first_name'
          label={i18n('common_first_name')}
          fill
          required
          disabled={isFetchingForgotUsername}
          autoComplete='first_name'
        />
        <TextField
          name='last_name'
          label={i18n('common_last_name')}
          fill
          required
          disabled={isFetchingForgotUsername}
          autoComplete='last_name'
        />
      </div>
      <div className='form-wrapper__field-row'>
        <TextField
          name='email'
          label={i18n('enrollment_form_email_address')}
          fill
          required
          disabled={isFetchingForgotUsername}
          autoComplete='email'
        />
      </div>

      <Button type='submit' className='form-wrapper__submit-button' disabled={isFetchingForgotUsername}>
        {i18n('common_submit')}
      </Button>
    </FormWrapper>
  );
};

export default ForgotUsernameForm;
