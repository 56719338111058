import React from 'react';
import PropTypes from 'prop-types';

const VisHiddenText = ({ message }) => <span className='vis-hidden'>{message}</span>;

VisHiddenText.propTypes = {
  /**
   * Error Message
   */
  message: PropTypes.string,
  ariaHidden: PropTypes.bool,
  hidden: PropTypes.string,
};

export default VisHiddenText;
