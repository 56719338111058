import React from 'react';
import Anchor from 'components/Anchor';
import { actions } from '@ehi/global-marketing-interface';
import useActions from 'hooks/useActions';
import Button from 'components/Button';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS, WINDOW_OBJECT_KEYS, RESERVATIONS } from 'constants';
import THEMES from 'constants/themes';
import propTypes from './ModifyCancelReservationPropTypes';

const { GUARANTEED_RES_MODIFY_REASON, GBO_REM_WNG_PREPAY, GBO_REM_PICK_UP_TIME_PASSED } = RESERVATIONS;

function ModifyCancelReservation(props) {
  const {
    displayFlightDelayLink,
    showModifyModal,
    modifyCancelResExternalLink,
    thirdPartyReservationSupportNumber,
    showCancelModal,
    openFlightDelayModal,
    isEligible,
    isThirdPartyReservation,
    modifyNotAllowedReasons,
    isTourReservation,
    canCancelReservation,
  } = props;

  const cleanMessagesAction = useActions(actions.cleanMessages);

  const handleFlightModal = () => {
    utils.analytics.interaction(
      ANALYTICS.MODAL,
      isEligible ? 'flight modification eligible' : 'flight delayed faq ineligible',
      ANALYTICS.OPEN
    );
    openFlightDelayModal();
  };

  const handleCancelModal = () => {
    cleanMessagesAction();
    showCancelModal();
  };

  const customerSupportNumberBtn = customerSupportNumber => (
    <Anchor key='0' className='link' href={`tel:${customerSupportNumber}`} aria-label={customerSupportNumber}>
      {customerSupportNumber}
    </Anchor>
  );

  return (
    <section className='theme--midnight-blue modify-cancel-reservation'>
      {!isThirdPartyReservation && canCancelReservation && <div className='modify-cancel-reservation__edit-icon' />}

      <p className='modify-cancel-reservation__description'>
        {utils.i18n('confirmation_reservation_modify_cancel_description')}
      </p>

      {!isThirdPartyReservation &&
        (canCancelReservation && (
          <>
            <div
              className={cn('modify-cancel-reservation__buttons-container', {
                'single-button': modifyNotAllowedReasons,
              })}>
              {!modifyNotAllowedReasons && (
                <Button
                  disabled={modifyNotAllowedReasons?.includes(GBO_REM_PICK_UP_TIME_PASSED)}
                  data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRMATION, ANALYTICS.MODIFY)}
                  onClick={showModifyModal}
                  theme={THEMES.MEDIUM}
                  ghosted>
                  {utils.i18n('confirmation_reservation_modify_button')}
                </Button>
              )}
              <Button
                disabled={modifyNotAllowedReasons?.includes(GBO_REM_PICK_UP_TIME_PASSED)}
                data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRMATION, ANALYTICS.CANCEL)}
                onClick={handleCancelModal}
                theme={THEMES.MEDIUM}
                ghosted>
                {utils.i18n('confirmation_reservation_cancel_button')}
              </Button>
            </div>
          </>
        ))}
      {modifyNotAllowedReasons?.includes(GBO_REM_WNG_PREPAY) && ( // Check to see if reservation is a WNG prepaid reservation.
        <div className='modify-cancel-reservation__WNG-prepay'>
          <p>
            {utils.i18n('confirmation_reservation_modify_cancel_WNG_prepay')}
            <Anchor
              className='link modify-cancel-reservation__external-link'
              href={modifyCancelResExternalLink.link}
              isExternal={true}>
              {utils.i18n('common_click_here')}
            </Anchor>
          </p>
          <p>
            {utils.i18n('confirmation_reservation_costumer_service_WNG_prepay')}
            <Anchor
              className='link modify-cancel-reservation__external-link'
              href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)}
              isExternal={true}>
              {utils.i18n('profile_account_customer_support')}
            </Anchor>
          </p>
        </div>
      )}
      {(isThirdPartyReservation || isTourReservation) && (
        <div className='modify-cancel-reservation__third-party-copy'>
          <p>{utils.i18n('confirmation_reservation_modify_cancel_3rd_party_copy_1')}</p>
          <p>
            {utils.i18n(
              'confirmation_reservation_modify_cancel_3rd_party_copy_2',
              { 0: customerSupportNumberBtn(thirdPartyReservationSupportNumber) },
              { jsx: true }
            )}
          </p>
        </div>
      )}

      {modifyNotAllowedReasons?.includes(GUARANTEED_RES_MODIFY_REASON) && (
        <p className='cancel-modify-restriction-copy'>{utils.i18n('guaranteed_res_modify_24_hour_copy')}</p>
      )}

      {modifyNotAllowedReasons &&
        !isThirdPartyReservation &&
        !modifyNotAllowedReasons?.includes(GUARANTEED_RES_MODIFY_REASON) && (
          <p className='modify-cancel-reservation__not-allowed-copy'>
            {utils.i18n(
              'confirmation_reservation_modify_cancel_not_allowed',
              { 0: customerSupportNumberBtn(utils.i18n('customer_support_number')) },
              { jsx: true }
            )}
          </p>
        )}
      {utils &&
        utils.config &&
        utils.config.getIsEnableFlightDelay &&
        utils.config.getIsEnableFlightDelay() &&
        displayFlightDelayLink && (
          <Button key={0} className='modify-cancel-reservation__flight-delay' onClick={handleFlightModal} link>
            <span>{utils.i18n('flight_delayed_changed_title')}</span>
          </Button>
        )}
    </section>
  );
}

ModifyCancelReservation.propTypes = propTypes;

export default ModifyCancelReservation;
