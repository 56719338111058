import React from 'react';
import utils from 'utils';
import propTypes from './totalPriceFormattedPropTypes';

const TotalPriceFormatted = ({ estimatedTotalView, isThirdPartyReservation, isDestinationCurrency }) => (
  <span className='total-price section__value formatted-price'>
    {/* TODO: replace with secret_rate flag */}
    {isThirdPartyReservation && (parseInt(estimatedTotalView?.amount, 10) === 0 || !estimatedTotalView)
      ? utils.i18n('third_party_reservation_net_rate')
      : utils.getFormattedPrice(estimatedTotalView, { doubleAsterisk: !isDestinationCurrency })}
  </span>
);

TotalPriceFormatted.propTypes = propTypes;

export default TotalPriceFormatted;
