import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';

export const propTypes = {
  /**
   * Tab name text
   *
   * @type {String}
   */
  name: PropTypes.string.isRequired,

  /**
   * Tab content text
   *
   * @type {String}
   */
  content: PropTypes.string.isRequired,

  /**
   * Tab disabled boolean
   *
   * @type {Boolean}
   */
  disabled: PropTypes.bool,

  /**
   * Tab expanded state boolean
   *
   * @type {Boolean}
   */
  expanded: PropTypes.bool,

  /**
   * Tab toggle callback function
   *
   * @type {Function}
   */
  onToggle: PropTypes.func,
};

export const defaultProps = setDefaultProps();
