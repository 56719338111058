import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import ProfileAccountMemberBadge from './ProfileAccountMemberBadge';

/**
 * ProfileAccountHeader
 *
 * @param {object} props - React Props
 * @param {string} props.first_name - Authenticated user first name
 * @param {string} props.memberNumber - Authenticated user Alamo Insider member number
 * @param {object} props.breakpoint - Object with viewport breakpoints checks
 *
 * @return {JSX} ProfileAccountHeader component
 */
const ProfileAccountHeader = ({ first_name, memberNumber, breakpoint }) => (
  <header className='profile-account-header row'>
    <h1 className='profile-account-header__title'>{utils.i18n('profile_account_welcome_back', [first_name])}</h1>
    {!breakpoint.isTablet && <ProfileAccountMemberBadge memberNumber={memberNumber} />}
  </header>
);

ProfileAccountHeader.propTypes = {
  first_name: PropTypes.string,
  memberNumber: PropTypes.string,
  breakpoint: PropTypes.object,
};

export default ProfileAccountHeader;
