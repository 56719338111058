import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import utils from 'utils';
import CHECKIN from 'constants/checkin';
import { hasExtrasSelector, hasAddedExtrasSelector } from 'selectors/extrasSelectors';
import Button from 'components/Button';
import PostResRentalInfoLine from 'components/PostRes/PostResRentalInfoLine';

/**
 * Check-in Extras Header component
 * Displays the header row on Check-in Extras, with Rental Info + Save and Continue button.
 *
 * @return {JSX} CheckInExtrasHeader jsx component
 */
const CheckInExtrasHeader = () => {
  const hasExtras = useSelector(hasExtrasSelector);
  const hasAddedExtras = useSelector(hasAddedExtrasSelector);

  return (
    <div className='check-in-template__review-header'>
      <div className='check-in-template__review-header-content'>
        <h1 className='check-in-template__heading'>{utils.i18n('check_in_title')}</h1>
        <PostResRentalInfoLine />
      </div>
      <div className='check-in-template__review-header-action'>
        <Link to={CHECKIN.CHECKIN_PATH_CONFIG.checkInReview[0]}>
          <Button className={cn({ pill: hasExtras && !hasAddedExtras })}>
            {utils.i18n('common_save_and_continue')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CheckInExtrasHeader;
