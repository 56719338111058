import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import cn from 'classnames';
import Button from 'components/Button';
import FormattedPrice from 'components/FormattedPrice';
import TotalPriceFormatted from 'components/RentalDetails/RentalDetailsSharedComponents/TotalPriceFormatted';
import RentalTotalPriceFormatted from 'components/RentalDetails/RentalDetailsSharedComponents/RentalTotalPriceFormatted';
import Anchor from 'components/Anchor';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';

/**
 * Total Price - displays the total price
 * @param {object} props                          - React Props
 * @param {boolean} props.displayPrePayFlop       - flag for displaying the pre pay flip flop
 * @param {boolean}   props.isReview              - flag for if we are in review or not
 * @param {object} props.estimatedTotalView       - price object for the estimated total
 * @param {string} props.estimatedTotalPayment    - price object for the total payment
 * @param {function} props.showLearnMoreModal     - shows the learn more modal
 * @param {boolean} props.asterisk                - if we want to use a asterisk when displaying the total price
 * @param {boolean} props.isPayLater              - flag for if something is pay later
 * @param {function} props.handlePrepayFlop       - flips the paying mode to and from prepay/ pay later
 * @param {object} props.oppositeEstimatedTotal   - estimated total of the opposite mode (prepay/paylater)
 * @param {array} props.adjustments               - array of adjustments, like contract discounts
 * @param {boolean} props.isThirdPartyReservation - if this is a third party res
 * @param {string} props.freeUpgradeApplied       - bool that indicates free upgrade available
 * @param {boolean} props.isLight                 - if it's in light background
 * @return {JSX} ShipmentTracker component
 */

const TotalPrice = ({
  displayPrePayFlop,
  isReview,
  estimatedTotalView,
  estimatedTotalPayment,
  showLearnMoreModal,
  asterisk,
  isPayLater,
  handlePrepayFlop,
  oppositeEstimatedTotal,
  adjustments,
  isThirdPartyReservation,
  freeUpgradeApplied,
  isLight,
  stepTwo,
  doubleAsteriskTotalView,
  isRental,
  contractDetails,
}) => {
  const supportNumber = useSupportPhoneNumber();
  const showAdjustments = !!adjustments?.length || freeUpgradeApplied;
  const isConverted = estimatedTotalPayment?.code !== estimatedTotalView?.code;
  const paymentTypeKey = isPayLater
    ? 'rental_details_switch_to_prepay_label'
    : 'rental_details_switch_to_pay_later_label';
  const chargesKey = isPayLater
    ? 'rental_details_you_will_be_charged_pay_later'
    : 'rental_details_you_will_be_charged_prepay';
  const wasChargedKey = isPayLater ? 'rental_details_charge_pay_later' : 'rental_details_charged_prepay';

  const renderCurrencyConversionCopy = useCallback(
    (key) =>
      utils.i18n(
        key,
        [
          <Fragment key='total-price-charged-0'>{estimatedTotalPayment?.code}</Fragment>,
          <FormattedPrice key='total-price-charged-1'>
            {utils.getFormattedPrice(estimatedTotalPayment, { asterisk })}
          </FormattedPrice>,
        ],
        { jsx: true }
      ),
    [estimatedTotalPayment]
  );

  return (
    <div className='rental-details__section rental-details__total-price'>
      {showAdjustments && (
        <div className='rental-details__adjustments'>
          <div className='rental-details__section-label adjustments-label'>
            {utils.i18n('rental_details_taxes_fees_adjustments')}
          </div>

          {freeUpgradeApplied && (
            <div className='adjustments-item'>
              <span className='adjustments-item-name'>{utils.i18n('coupon_upgrade_adjustment_name')}</span>
              <FormattedPrice classNames='adjustments-item-value'>
                {utils.i18n('coupon_upgrade_adjustment_value')}
              </FormattedPrice>
            </div>
          )}

          {adjustments.map(({ description, total_amount_view }, index) => (
            <div key={`adjustment_${index}`} className='adjustments-item'>
              <span className='adjustments-item-name'>{description}</span>
              <FormattedPrice classNames='adjustments-item-value'>
                {utils.getFormattedPrice(total_amount_view, { asterisk })}
              </FormattedPrice>
            </div>
          ))}
        </div>
      )}

      <p className='rental-details__section-label total-label'>
        <span>
          {!isRental &&
            !contractDetails &&
            utils.i18n(isConverted && isReview ? 'rental_details_estimated_total' : 'rental_details_total')}
          {isRental && !contractDetails && utils.i18n('your_estimated_total')}
          {contractDetails && `${contractDetails.contract_name} ${utils.i18n('rental_details_estimated_total')}`}
        </span>
        <span className='rental-details__section-label-total'>
          {!isRental && <TotalPriceFormatted isThirdPartyReservation={isThirdPartyReservation} />}

          {isRental && (
            <RentalTotalPriceFormatted
              estimatedTotalView={estimatedTotalView}
              isThirdPartyReservation={isThirdPartyReservation}
            />
          )}
        </span>
      </p>
      {displayPrePayFlop && isReview && oppositeEstimatedTotal?.format && !isRental && (
        <p className='rental-details__total-price-prepay-flop'>
          <Button link className='rental-details__total-price-prepay-flop--btn' onClick={handlePrepayFlop}>
            {utils.i18n(
              paymentTypeKey,
              {
                0: (
                  <FormattedPrice key='total-price-prepay-flop-0'>
                    {utils.getFormattedPrice(oppositeEstimatedTotal)}
                  </FormattedPrice>
                ),
              },
              { jsx: true }
            )}
          </Button>
        </p>
      )}

      <div className='rental-details__total-price-details'>
        {isConverted && !isThirdPartyReservation && !stepTwo && !contractDetails && !isRental && (
          <div
            className={cn('rental-details__total-price-charge', {
              'rental-details__total-price-charge--light-bg': isLight,
            })}
          >
            {isReview ? (
              <>
                {renderCurrencyConversionCopy(chargesKey)} {utils.i18n('rental_details_estimated_total_converted')}
              </>
            ) : (
              <>
                {renderCurrencyConversionCopy(wasChargedKey)}
                <br />
              </>
            )}{' '}
          </div>
        )}

        {!contractDetails && (
          <p className='rental-details__total-disclaimer'>
            {utils.i18n(!isRental ? 'rental_details_price_disclaimer' : 'charges_shown_are_estimates_text')}
          </p>
        )}

        {contractDetails && isRental && (
          <p className='rental-details__total-disclaimer'>
            {utils.i18n(
              'estimate_based_cid',
              {
                0: (
                  <Anchor
                    key='total-price-support-number-0'
                    className='link modify-return-confirmation__link'
                    href={`tel: ${supportNumber}`}
                  >
                    {supportNumber}
                  </Anchor>
                ),
              },
              { jsx: true }
            )}
          </p>
        )}

        {!isReview && isConverted && !isRental && (
          <div className='rental-details__converted-prices-disclaimer'>
            <p>
              {utils.i18n('rental_details_total_converted_disclaimer')}
              <Button
                link
                onClick={showLearnMoreModal}
                className='rental-details__toggle-learn-more-modal'
                aria-label={utils.i18n('rental_details_view_currency_conversion')}
              >
                {utils.i18n('rental_details_view_currency_conversion')}
              </Button>
            </p>
          </div>
        )}

        {isConverted && doubleAsteriskTotalView && !isRental && (
          <div
            className={cn('rental-details__total-price-charge total-converted-disclaimer', {
              'rental-details__total-price-charge--light-bg': isLight,
            })}
          >
            {utils.i18n('rental_details_total_converted_disclaimer')}{' '}
            <span
              className={cn('rental-details__toggle-learn-more-modal rental-details__toggle-about-currency-modal', {
                'rental-details__toggle-about-currency-modal--gray': !isReview || isLight,
              })}
              onClick={showLearnMoreModal}
            >
              {utils.i18n('rental_details_view_currency_conversion')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

TotalPrice.propTypes = {
  isReview: PropTypes.bool,
  estimatedTotalView: PropTypes.object,
  estimatedTotalPayment: PropTypes.object,
  showLearnMoreModal: PropTypes.func,
  asterisk: PropTypes.bool,
  displayPrePayFlop: PropTypes.bool,
  handlePrepayFlop: PropTypes.func,
  isPayLater: PropTypes.bool,
  oppositeEstimatedTotal: PropTypes.object,
  adjustments: PropTypes.array,
  isThirdPartyReservation: PropTypes.bool,
  freeUpgradeApplied: PropTypes.bool,
  isLight: PropTypes.bool,
  stepTwo: PropTypes.bool,
  doubleAsteriskTotalView: PropTypes.bool,
};

TotalPrice.defaultProps = {
  isLight: false,
  displayPrePayFlop: false,
  estimatedTotalPayment: {},
};

export default TotalPrice;
