import React from 'react';
import { sanitize } from 'utils/validation';
import { i18n } from 'utils/i18n';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import Modal from 'components/Modal';

const FrequentTravelerSurchargeModal = () => (
  <Modal
    modalKey={MODAL.FREQUENT_TRAVELER_SURCHARGE_MODAL}
    header={i18n('review_frequent_travel_surcharge_modal_title')}
    theme={MODAL_THEMES.WHITE}>
    {({ surchargeContent }) => (
      <div
        className='modal-themed__section modal-themed__gbo-content'
        dangerouslySetInnerHTML={sanitize(surchargeContent)}
      />
    )}
  </Modal>
);

export default FrequentTravelerSurchargeModal;
