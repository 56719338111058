import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS, ASSETS, MODAL, MODAL_THEMES } from 'constants';
import Modal from 'components/Modal';
import { AccordionList, createComponentArray } from 'components/AccordionList';

const { KEY_RENTAL_POLICIES_1, KEY_RENTAL_POLICIES_2, KEY_RENTAL_POLICIES_3 } = ASSETS;

const keyRentalPoliciesHeaderIcons = {
  src: KEY_RENTAL_POLICIES_3,
  srcTablet: KEY_RENTAL_POLICIES_2,
  srcMobile: KEY_RENTAL_POLICIES_1,
  alt: '',
  className: 'key-rental-facts__image',
};

/**
 * RentalPoliciesModal
 *
 * @param {object} props - React Props
 * @param {array} props.rentalPolicies
 * @param {object} props.breakpoint - Breakpoint selector data
 * @return {JSX} - RentalPoliciesModal react component
 */

const RentalPoliciesModal = props => {
  const { rentalPolicies = [], breakpoint } = props;
  const rentalPoliciesComponents = createComponentArray({
    items: rentalPolicies,
    titleKey: 'name',
    contentKey: 'content',
    analyticsTitle: ANALYTICS.RENTAL_POLICIES,
    options: { print: true },
  });
  return (
    <Modal
      customClass='key-facts-modal key-facts-modal__rental-policies'
      modalKey={MODAL.KEY_RENTAL_FACTS_RENTAL_POLICIES}
      header={utils.i18n('key_facts_modal_rental_policies_title')}
      theme={MODAL_THEMES.WHITE}
      showHeaderIcon={!breakpoint.isMobile}
      headerIcons={keyRentalPoliciesHeaderIcons}>
      {!!rentalPolicies.length && (
        <AccordionList print isVertical customClass='key-facts-policy__items'>
          {rentalPoliciesComponents}
        </AccordionList>
      )}
    </Modal>
  );
};

RentalPoliciesModal.propTypes = {
  rentalPolicies: PropTypes.array,
  breakpoint: PropTypes.object,
};

export default RentalPoliciesModal;
