import { connect } from 'react-redux';
import { breakpointSelector, isFetchingEndpointSelector } from 'selectors';
import { loginRememberMe } from 'actions/authentication';
import UnauthenticatedFlyout from './UnauthenticatedFlyout';

const mapStateToProps = state => ({
  breakpoint: breakpointSelector(state),
  isFetching: isFetchingEndpointSelector(state, { endpoint: 'profile/login/rememberme' }),
  isRememberMeEligible: state.getIn(['gmi', 'session', 'gma', 'remember_me_eligible']),
});

const mapDispatchToProps = {
  loginRememberMe,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthenticatedFlyout);
