import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Anchor from 'components/Anchor';

/**
 * ExtendRentalInformationModalContent - Modal that displays a phone number to user, so she/he/they can call if they want to extend the rental
 *
 * @param {object} props - React Props
 * @param {object} props.phoneNumber - object created by guaranteedResPolicySelector that contains any data components may need from this policy in the session res object
 * @return {JSX}
 */

const ExtendRentalInformationModalContent = ({ phoneNumber }) => (
  <>
    <div className='padding-top--condensed'>
      <p className='modal-themed__section'>{utils.i18n('extend_rental_modal_description')}</p>
      <p className='modal-themed__section'>
        {utils.i18n(
          'extend_rental_modal_extend_phone_number',
          {
            0: (
              <Anchor
                className='link link--text'
                key='phoneNumber_0'
                isExternal
                hideExternalIcon
                href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </Anchor>
            ),
          },
          { jsx: true }
        )}
      </p>
    </div>
  </>
);

ExtendRentalInformationModalContent.propTypes = {
  phoneNumber: PropTypes.string,
};

export default ExtendRentalInformationModalContent;
