import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import PropTypes from 'prop-types';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import { MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES, PROFILE } from 'constants';
import { reservationAirlineInfoSelector } from 'selectors/reservationSelectors';
import Form from 'components/Form/Form';
import Modal from 'components/Modal';
import PostResFlightInfo from 'components/PostRes/PostResFlightInfo';
import ServiceErrors from 'components/ServiceErrors';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { uiStateReservationCommitPath } from 'paths/uiState';
import { skipModifyReservationStartRedirect } from "actions/reservation/skipModifyReservationStartRedirect";
import { updateFlightInfoDetails } from "actions/reservation/updateFlightInfoDetails";

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const UpdateFlightInfoModal = ({ openCancelUpdatesmodal, savedValues }) => {
  const handleCommit = useActions(updateFlightInfoDetails);
  const modifyReservationStart = useActions(skipModifyReservationStartRedirect);
  const airlineInfo = useSelector(reservationAirlineInfoSelector);
  const commitState = useSelector((state) => utils.safeToJS(state?.getIn(uiStateReservationCommitPath)));

  /*  handling flight update ,before updating flight info we are calling an modify start api call
  to enter into modify reservation state ,after success of that modify call we are calling an updating 
  the flight information. without calling modify start we will not able to modify or update the reservation state */
  const initialValues = useMemo(
    () => ({
      airline_information: {
        code: commitState.airline_information?.code || airlineInfo.code,
        flight_number: commitState.airline_information?.flight_number || '',
      },
    }),
    [
      airlineInfo.code,
      airlineInfo.flight_number,
      commitState.airline_information?.code,
      commitState.airline_information?.flight_number,
    ]
  );
  const handleFormSubmit = () => {
    const sourceCode = PROFILE.PREFERENCE_SOURCE_CODE.RES_PATH;

    // updating flight info
    const handleFlightInfoSubmit = () => {
      handleCommit(sourceCode, { redirect: false });
    };
    // modifyStart handler ,on successfull response handling updating flight info
    modifyReservationStart({ redirect: false })
      .then((response) => {
        !response.messages ? handleFlightInfoSubmit() : utils.dom.loadingOverlay(false);
      })
      .catch(() => utils.dom.loadingOverlay(false));
  };

  const cancelModalWithAnalytics = () => {
    utils.analytics.interaction(ANALYTICS.MODAL, 'cancel flight update', ANALYTICS.OPEN);
    openCancelUpdatesmodal();
  };

  const handleGoBackOnClose = () => {
    if (openCancelUpdatesmodal)
      // if the mothod was passed in on props...
      setTimeout(() => {
        cancelModalWithAnalytics();
      }, 0);
  };
  const handleGoBack = (handleClose) => () => {
    handleGoBackOnClose();
    handleClose({ skipAnalytics: true });
  };

  return (
    <Modal
      modalKey={MODAL.UPDATE_FLIGHT_INFO_MODAL}
      header={utils.i18n('update_flight_info_title')}
      theme={MODAL_THEMES.WHITE}
      customClass={'flight-delayed-changed-modal update-flight-info-modal'}
      showHeaderTextIcon
      customActionOnClose={handleGoBackOnClose}
      headerTextIcons={'plane'}
    >
      {({ handleClose }) => (
        <Form
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          subscription={{ submitting: true, pristine: true, submitFailed: true }}
        >
          {({ handleSubmit }) => (
            <div className='modal-themed__update-flight-info-modal'>
              <ServiceErrors />
              <form onSubmit={handleSubmit}>
                <PostResFlightInfo fieldsOnly savedValues={savedValues} />
                <div className='update-flight-info-modal-footer flight-delayed-modal-footer column-reverse-footer'>
                  <ModalFooter
                    fullWidthButton
                    buttons={[
                      {
                        type: DECLINE,
                        label: utils.i18n('common_go_back'),
                        handler: handleGoBack(handleClose),
                      },
                      {
                        type: ACCEPT,
                        label: utils.i18n('common_save'),
                        handler: handleSubmit,
                      },
                    ]}
                  />
                </div>
              </form>
            </div>
          )}
        </Form>
      )}
    </Modal>
  );
};

UpdateFlightInfoModal.propTypes = {
  openCancelUpdatesmodal: PropTypes.func,
  handleClose: PropTypes.func,
  savedValues: PropTypes.object,
};

export default UpdateFlightInfoModal;
