import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { getPastTripReceipts, getPastTrips } from 'actions/trips';
import { pastTripsSelector, pastTripReceiptsSelector } from 'selectors/tripsSelectors';
import { profileDataSelector } from 'selectors/profileSelectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { unauthPastTripsSelector } from 'selectors/reservationSelectors';
import utils from 'utils';
import { getDistanceLabel } from 'utils/locations';
import { getVehicleMakeModel } from 'utils/vehicles';
import Picture from 'components/Picture';
import ReceiptHeader from './ReceiptHeader';
import ReceiptRentalCharges from './ReceiptRentalCharges';
import ReceiptRentalSummary from './ReceiptRentalSummary';
import ReceiptRentalSideBlock from './ReceiptRentalSideBlock';
import ReceiptRentalSideBlockList from './ReceiptRentalSideBlockList';
import ReceiptRentalSideBlockListItem from './ReceiptRentalSideBlockListItem';

/**
 * Receipt page
 *
 * @param {object} props - React Props
 * @return {JSX}
 */
const Receipt = props => {
  const isFetchingSession = useSelector(state => isFetchingEndpointSelector(state, { endpoint: 'session/current' }));
  const isFetchingInvoice = useSelector(state => isFetchingEndpointSelector(state, { endpoint: 'trips/invoice' }));
  const isFetchingPastTrips = useSelector(state => isFetchingEndpointSelector(state, { endpoint: 'trips/past' }));
  /**
   * If user is coming from My Trips page it's handled with react router
   * If user is coming from Past Trips lookup form it's handled as url params
   */
  const params = props?.match?.params || utils.url.queryObject();
  const invoiceNumber = params.invoice;

  /**
   * isFromMyTrips - is used to decide how to handle the trips information.
   * If isFromMyTrips === true then it gets trips from GMA (as auth receipt / associated to the user trips)
   * If isFromMyTrips === false then it gets trips from GBO (as unauth receipt)
   */
  const isFromMyTrips = params.isFromMyTrips === 'true';
  const DATE_FORMAT = utils.config.getDateTimeLongFormat('ll');

  const retrievePastTrips = useActions(getPastTrips);
  const retrievePastTripReceipts = useActions(getPastTripReceipts);

  const pastTrips = useSelector(!isFromMyTrips ? unauthPastTripsSelector : pastTripsSelector);
  const profileData = useSelector(state => profileDataSelector(state, { memberNumber: true, email: true }));
  const receipt = useSelector(state => pastTripReceiptsSelector(state, invoiceNumber));

  const customerName = `${receipt?.customer_first_name?.toLowerCase()} ${receipt?.customer_last_name?.toLowerCase()}`;
  const isReceiptNotFound = !receipt && !isFetchingSession && !isFetchingInvoice && !isFetchingPastTrips;
  const genericTitle = `${utils.i18n('profile_page_title')} - ${utils.i18n('reservation_flow_page_title')}`;

  useEffect(
    () => {
      /**
       * On page refresh, the receipts is not on GMA object anymore. Needs to fetch past trips again.
       * This is only for isFromMyTrips === true scenario
       */
      if (!receipt && profileData?.memberNumber && !pastTrips?.length && isFromMyTrips) {
        const payload = { membership_number: profileData.memberNumber };
        retrievePastTrips(payload, true);
      }
    },
    [pastTrips?.length, profileData?.memberNumber]
  );

  useEffect(
    () => {
      const trip = pastTrips.find(item => item.invoice_number === invoiceNumber);
      document.title = `${utils.i18n('profile_page_title_rental_receipt')} - ${genericTitle}`;

      if (trip && !isFetchingInvoice && !receipt) {
        retrievePastTripReceipts(invoiceNumber, trip.customer_last_name, true);
      }
    },
    [isFetchingSession, isFetchingInvoice, pastTrips]
  );

  const discountCode = [
    { label: utils.i18n('receipt_rental_contract_label'), value: receipt?.contract_name },
    { label: utils.i18n('coupon_confirmation_section_label'), value: '' },
    { label: utils.i18n('receipt_rental_promotion_label'), value: '' },
  ].filter(item => item.value);

  const renterNameObj = {
    label: utils.i18n('edit_account_modal_label_name'),
    value: <span className='receipt__side-block__customer-name'>{customerName}</span>,
  };

  const renterAuthInformation = [
    {
      label: utils.i18n('enrollment_confirmation_header_member_number'),
      value: receipt?.membership_number,
    },
    { label: utils.i18n('my_profile_email_label'), value: profileData?.email || '' },
  ];

  // AWR-6308 - If is from My Trips, then display member number and email otherwise just display the name.
  const renterInformationData = isFromMyTrips ? [renterNameObj, ...renterAuthInformation] : [renterNameObj];

  const vehicleDetailsData = [
    {
      label: utils.i18n('receipt_rental_driven_class'),
      value: receipt?.vehicle_details?.vehicle_class_driven,
    },
    {
      label: utils.i18n('receipt_rental_charged_class'),
      value: receipt?.vehicle_details?.vehicle_class_charged,
    },
    {
      label: utils.i18n('receipt_rental_make_model'),
      value: getVehicleMakeModel(receipt?.vehicle_details),
    },
    {
      label: utils.i18n('receipt_rental_license_plate'),
      value: receipt?.vehicle_details?.license_plate,
    },
  ];

  const rentalDistanceData = [
    {
      label: utils.i18n('receipt_rental_odometer_start'),
      value: getDistanceLabel(receipt?.vehicle_details?.starting_odometer, receipt?.vehicle_details?.distance_unit),
    },
    {
      label: utils.i18n('receipt_rental_odometer_end'),
      value: getDistanceLabel(receipt?.vehicle_details?.ending_odometer, receipt?.vehicle_details?.distance_unit),
    },
    {
      label: utils.i18n('receipt_rental_total_distance'),
      value: getDistanceLabel(receipt?.vehicle_details?.distance_traveled, receipt?.vehicle_details?.distance_unit),
    },
  ];

  return (
    <div className='aem-Grid aem-Grid--12 aem-Grid--default--12'>
      <div className='receipt'>
        <ReceiptHeader isUnathReceipt={!isFromMyTrips} />

        <div className='receipt__container'>
          {receipt && (
            <div className='receipt__details'>
              <div className='receipt__details-alamo-logo'>
                <Picture
                  className='header__custom-path__alamo-logo'
                  src={utils.config.getSiteLogoImage()}
                  alt={utils.i18n('progress_bar_alamo_image_alt')}
                />
              </div>
              <h1 className='receipt__details-title'>
                {utils.i18n('receipt_date', [utils.gmi.getDateTimeObjFromTs(receipt.pickup_time).format(DATE_FORMAT)])}
              </h1>

              <ReceiptRentalSummary receipt={receipt} customerName={customerName} />

              <div className='receipt__charges'>
                <div className='receipt__charges-column'>
                  <ReceiptRentalCharges receipt={receipt} />
                </div>

                <div className='receipt__charges-column'>
                  {discountCode.length > 0 && (
                    <ReceiptRentalSideBlock title={utils.i18n('discount_code')}>
                      {discountCode.map(item => (
                        <ReceiptRentalSideBlockList key={`${item.label}_${item.value}`}>
                          <ReceiptRentalSideBlockListItem label={item.label} value={item.value} />
                        </ReceiptRentalSideBlockList>
                      ))}
                    </ReceiptRentalSideBlock>
                  )}

                  <ReceiptRentalSideBlock title={utils.i18n('receipt_rental_renter_information')}>
                    {renterInformationData.map(item => (
                      <ReceiptRentalSideBlockList key={`${item.label}_${item.value}`}>
                        <ReceiptRentalSideBlockListItem label={item.label} value={item.value} />
                      </ReceiptRentalSideBlockList>
                    ))}
                  </ReceiptRentalSideBlock>

                  <ReceiptRentalSideBlock title={utils.i18n('receipt_rental_vehicle_details')}>
                    {vehicleDetailsData.map(item => (
                      <ReceiptRentalSideBlockList key={`${item.label}_${item.value}`}>
                        <ReceiptRentalSideBlockListItem label={item.label} value={item.value} />
                      </ReceiptRentalSideBlockList>
                    ))}
                  </ReceiptRentalSideBlock>

                  <ReceiptRentalSideBlock title={utils.i18n('receipt_rental_distance')}>
                    {rentalDistanceData.map(item => (
                      <ReceiptRentalSideBlockList key={`${item.label}_${item.value}`}>
                        <ReceiptRentalSideBlockListItem label={item.label} value={item.value} />
                      </ReceiptRentalSideBlockList>
                    ))}
                  </ReceiptRentalSideBlock>
                </div>
              </div>

              <div className='receipt__footer'>
                <span className='receipt__footer-invoice'>
                  {utils.i18n(
                    'receipt_drive_away_invoice',
                    [
                      <span key='0' className='receipt__footer-invoice-number'>
                        {receipt.invoice_number}
                      </span>,
                    ],
                    { jsx: true }
                  )}
                </span>
              </div>
            </div>
          )}

          {isReceiptNotFound && <span>{utils.i18n('receipt_not_found')}</span>}
        </div>
      </div>
    </div>
  );
};

export default Receipt;
