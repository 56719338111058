import { connect } from 'react-redux';
import { actions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import LocationDateTime from './LocationDateTimeFieldGroup';

const { initiateState } = actions.reservations;

const mapDispatchToProps = {
  clearDate: exchangeType => initiateState.unset('date', { exchangeType }),
  clearTime: exchangeType => initiateState.unset('time', { exchangeType }),
  setDate: (timestamp, exchangeType) => initiateState.set('date', { timestamp, exchangeType }),
  setTime: (timestamp, exchangeType) => initiateState.set('time', { timestamp, exchangeType }),
};

export default connect(
  null,
  mapDispatchToProps
)(utils.toJS(LocationDateTime));
