import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ASSETS } from 'constants';
import utils from 'utils';
import { wayfindingPolicySelector } from 'selectors/policiesSelectors';
import { isVirginReservationSelector } from 'selectors/customPathCheckInSelectors';
import Button from 'components/Button';
import './styles/helpful-tips-pickup.scss';

const HelpfulTipsPickup = ({
  setIndexOfPolicyComponentViaPolicyCode,
  prepaySelected,
  hidePolicies,
  openTermsAndConditionsModal,
  policies,
}) => {
  const wayFinding = useSelector(wayfindingPolicySelector);
  const isVirginReservation = useSelector(isVirginReservationSelector);
  const isNADomain = utils.config.isNADomain();
  const checkForReq1 = policies?.some((policie) => policie.code === 'REQ1');
  const policyComponentViaPolicyCode = isNADomain && checkForReq1 ? 'REQ1' : 'PYMT';

  return (
    <div className='theme--midnight-blue helpful-tips-pick-up__container'>
      <div className='helpful-tips-pick-up__title-section'>
        <img className='helpful-tips-pick-up__image' src={ASSETS.HELPFUL_TIPS_ICON} alt='' />
        <h2 className='helpful-tips-pick-up__title'>{utils.i18n('helpful_tips_pickup_main_title')}</h2>
      </div>
      <ol className='helpful-tips-pick-up__list-items'>
        <li>{utils.i18n('helpful_tips_pickup_list_one')}</li>
        <li>
          <p>{utils.i18n(prepaySelected && 'helpful_tips_pickup_list_two_prepay')}</p>
          <p>
            {utils.i18n(
              !prepaySelected && isNADomain ? 'helpful_tips_pickup_list_two_na' : 'helpful_tips_pickup_list_two'
            )}
          </p>
          {!hidePolicies && (
            <a
              href='#policies-component'
              className='link link--text'
              onClick={setIndexOfPolicyComponentViaPolicyCode(policyComponentViaPolicyCode)}
            >
              {utils.i18n('helpful_tips_pickup_forms_payment')}
            </a>
          )}
        </li>
        <li>
          <p>{utils.i18n('helpful_tips_pickup_list_three')}</p>
          {hidePolicies ? (
            <Button link onClick={openTermsAndConditionsModal} className='helpful-tips-pick-up__link'>
              {utils.i18n('stc_view_rental_agreement_terms')}
            </Button>
          ) : (
            <a className='link link--text' href='#policies-component'>
              {utils.i18n('helpful_tips_pickup_rental_policy_and_terms')}
            </a>
          )}
        </li>
      </ol>
      {/* Only show arrival instructions to Virgin reservation (seems that GBO is validation which locaiton should have that so we just check if it's Virgin) */}
      {isVirginReservation && wayFinding && (
        <>
          <h2 className='helpful-tips-pick-up__arrival-instructions'>
            {utils.i18n('rental_details_arrival_instructions')}
          </h2>
          <div
            className='helpful-tips-pick-up__arrival-instructions--text'
            dangerouslySetInnerHTML={utils.sanitize(wayFinding.text)}
          />
        </>
      )}
    </div>
  );
};

HelpfulTipsPickup.propTypes = {
  setIndexOfPolicyComponentViaPolicyCode: PropTypes.func,
  prepaySelected: PropTypes.bool,
  hidePolicies: PropTypes.bool,
  openTermsAndConditionsModal: PropTypes.func,
  policies: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default HelpfulTipsPickup;
