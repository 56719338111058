import { safeToJS } from 'utils/immutable';
import { createSelector } from 'reselect';
import { countrySelectedPath } from 'paths/app';
import {
  appViewModifyCancelLookupRentalsPath,
  rentalSearchCriteriaPath,
  appViewModifyCancelRentalAgreementPath,
  deeplinkDeliveryOptionPath,
} from './paths';

export const getLookupRentals = (state) => safeToJS(state.getIn(appViewModifyCancelLookupRentalsPath, {}));

export const getRentalAgreement = (state) => safeToJS(state.getIn(appViewModifyCancelRentalAgreementPath, {}));

export const getUserData = (state) => safeToJS(state.getIn(rentalSearchCriteriaPath, {}));

export const getDeeplinkDeliveryOptionSelector = (state) => state.getIn(deeplinkDeliveryOptionPath);

export const getCountrySelectedPath = (state) => state.getIn([...countrySelectedPath]);

export const getSelectedCountry = createSelector([getCountrySelectedPath], (country) => ({
  currentDialingCode: country?.currentDialingCode,
  previouslySelectedDialingCode: country?.previouslySelectedDialingCode,
  countryCodesList: country?.countryCodesList,
  countryCode: country?.countryCode,
}));
