import { connect } from 'react-redux';
import utils from 'utils';
import {
  numberOfExtrasSelector,
  includedOrAddedExtrasSelector,
  includedExtrasAndDriversSelector,
  selectedBundleWithExtrasSelector,
  bundledAdditionalDriversSelector,
  numberOfAddedExtrasSelector,
} from 'selectors';

const mapStateToProps = state => {
  const { addedExtras } = includedOrAddedExtrasSelector(state);

  return {
    numberOfExtrasSelected: numberOfExtrasSelector(state),
    includedExtrasAndDriversSelector: includedExtrasAndDriversSelector(state),
    addedExtras,
    selectedBundle: selectedBundleWithExtrasSelector(state),
    bundledAdditionalDrivers: bundledAdditionalDriversSelector(state),
    addedExtrasAmount: numberOfAddedExtrasSelector(state),
  };
};

const connectExtras = WrappedComponent => connect(mapStateToProps)(utils.toJS(WrappedComponent));

export default connectExtras;
