import React from 'react';
import { MODAL, MODAL_THEMES } from 'constants';
import utils from 'utils';
import Modal from 'components/Modal';
import LearnAboutPrepayAndPayLaterContentContainer from './LearnAboutPrepayAndPayLaterContentContainer';

/**
 * LearnAboutPrepayAndPayLaterModal component shows payment-options-info modal when user triggers openModal(MODAL.LEARN_ABOUT_PREPAY_AND_PAY_LATER)
 *
 * @return {JSX}      LearnAboutPrepayAndPayLaterModal component
 */
const LearnAboutPrepayAndPayLaterModal = () => (
  <Modal
    modalKey={MODAL.LEARN_ABOUT_PREPAY_AND_PAY_LATER}
    header={utils.i18n('pre_pay_terms_sub_header')}
    theme={MODAL_THEMES.WHITE}>
    <LearnAboutPrepayAndPayLaterContentContainer />
  </Modal>
);

export default LearnAboutPrepayAndPayLaterModal;
