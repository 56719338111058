import { connect } from 'react-redux';

import { getSupportContactInfo } from 'actions/content';
import { getAlamoRoadsideAssistanceSupportPhoneNumber } from 'selectors/supportSelectors';
import LearnMoreAboutRoadsideAssistanceModal from './LearnMoreAboutRoadsideAssistanceModal';

const mapStateToProps = state => ({
  roadsideAssistancePhoneNumber: getAlamoRoadsideAssistanceSupportPhoneNumber(state, window.ehi.aem.domain_country),
});

const mapDispatchToProps = {
  getSupportContactInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearnMoreAboutRoadsideAssistanceModal);
