import { connect } from 'react-redux';
import {
  getModifyFlag,
  getCardSubmissionUrl,
  getSessionId,
  getReservationRenterInfoFirstName,
  getReservationRenterInfoLastName,
} from 'selectors/reservationSelectors';
import { getUiStateLastName, getUiStateFirstName } from 'selectors/uiStateSelectors';
import { cardSubmissionSession } from 'actions/reservation/cardSubmissionSession';
import IbmPaymentIframe from './IbmPaymentIframe';

const mapStateToProps = (state) => ({
  cardSubmissionURL: getCardSubmissionUrl(state),
  sessionId: getSessionId(state),
  isTrueModify: getModifyFlag(state),
  firstName: getUiStateFirstName(state) || getReservationRenterInfoFirstName(state),
  lastName: getUiStateLastName(state) || getReservationRenterInfoLastName(state),
});

const mapDispatchToProps = {
  cardSubmissionSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(IbmPaymentIframe);
