import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom-v5-compat';
import utils from 'utils';
import { scrollPage } from 'utils/dom';
import { config } from 'utils/config';
import { initAnalytics } from 'utils/analytics';
import RentalFlowTemplate from 'components/PostRes/RentalFlowTemplate';
import RENTAL from 'constants/rental';
import ConfirmModifyCommit from './ModifyReturn/ConfirmModifyCommit';
import ModifyReturn from './ModifyReturn/ModifyReturnScreen';
import ConfirmedRental from './ConfirmedRental';
import ConfirmReturn from './ConfirmReturn';
import { withRouter } from '../../utils/hoc';

import { handledHeaderForRentalModifyFlow } from './Global';

const { RENTAL_VIEWS } = RENTAL;

const RentalFlow = ({ router }) => {
  const [hideNavBar, setHideNavBar] = useState(false);
  const pathname = router?.location?.pathname;

  const handleShowOrHideHeaderNavBar = (routePath) => {
    const updatedRoutePath = routePath === '/' ? RENTAL_VIEWS.MODIFY_RENTAL[0] : routePath;
    const hideNav = [RENTAL_VIEWS.DATE_TIME[0], RENTAL_VIEWS.COMMIT[0]].includes(pathname);
    setHideNavBar(hideNav);
    handledHeaderForRentalModifyFlow(updatedRoutePath);
  };

  const handleRouteUpdate = (routePath) => {
    if (!window.pageXOffset) {
      scrollPage();
    }
    initAnalytics(config.getPageInfo());
    handleShowOrHideHeaderNavBar(routePath);
  };

  useEffect(() => {
    handleShowOrHideHeaderNavBar(pathname);
  }, []);

  return (
    <>
      <RentalFlowTemplate hideNavBar={hideNavBar}>
        <utils.OnUpdateWrapper pathname={pathname} onUpdate={() => handleRouteUpdate(pathname)}>
          <Routes>
            <Route path={RENTAL_VIEWS.CONFIRM_MODIFY[0]} exact element={<ConfirmReturn />} />
            <Route path={RENTAL_VIEWS.MODIFY_RENTAL[0]} exact element={<ConfirmReturn />} />
            <Route path={RENTAL_VIEWS.DATE_TIME[0]} exact element={<ModifyReturn />} />
            <Route path={RENTAL_VIEWS.COMMIT[0]} exact element={<ConfirmModifyCommit />} />
            <Route path={RENTAL_VIEWS.UPDATED[0]} exact element={<ConfirmedRental isReturnUpdated />} />
            <Route path={RENTAL_VIEWS.CONFIRMED[0]} exact element={<ConfirmedRental pathname />} />
            <Route path={RENTAL_VIEWS.DETAILS[0]} element={<ConfirmedRental pathname details />} />
            <Route path='*' replace element={<ConfirmReturn />} />
          </Routes>
        </utils.OnUpdateWrapper>
      </RentalFlowTemplate>
    </>
  );
};

export default withRouter(RentalFlow);
