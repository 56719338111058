import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Title from 'components/Title';
import BookingWidget from 'components/BookingWidget';
import PreSelectedMessages from 'components/PreSelectedMessages';

class ReservationFlowBookingWidget extends Component {
  render() {
    const { reservationObject, isTrueModify, isFetchingSession } = this.props;

    return (
      <div className='row'>
        <div className='reservation-flow__booking-widget'>
          <Title
            type='h2'
            text={utils.i18n(
              isTrueModify ? 'true_modify_location_date_time_header' : 'resflow_booking_widget_title_inflight'
            )}
            titleSuffix={utils.i18n('resflow_booking_widget_required_indication')}
          />
          <PreSelectedMessages />
          {!isFetchingSession && (
            <BookingWidget
              isTrueModify={isTrueModify}
              contractCollapsed
              reservationObject={reservationObject}
              returnLocationCollapsed
              preserveBundleProfile
              isInResBookingWidget
            />
          )}
        </div>
      </div>
    );
  }
}

ReservationFlowBookingWidget.propTypes = {
  reservationObject: PropTypes.object,
  isTrueModify: PropTypes.bool,
  isFetchingSession: PropTypes.bool,
};

export default ReservationFlowBookingWidget;
