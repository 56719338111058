import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import useActions from 'hooks/useActions';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';
import RESERVATIONS from 'constants/reservations';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import ERRORS from 'constants/errors';
import Modal from 'components/Modal';
import SecureConnectionDisplay from 'components/SecureConnectionDisplay';
import IbmPaymentIframe from 'components/IbmPaymentIframe';
import GenericError from 'components/GenericError';
import Anchor from 'components/Anchor';
import CardHolderDisclaimer from 'components/CardHolderDisclaimer';
import { getInflightPaymentDetails } from "actions/reservation/getInflightPaymentDetails";
import { removeInflightPaymentDetails } from "actions/reservation/removeInflightPaymentDetails";

const { IBM_SUCCESS, IBM_FAILURE } = RESERVATIONS;
const { PRIORITY_ERROR } = ERRORS;

/**
 * STCAddCreditCardModal
 *
 * @return {JSX}
 */
const STCAddCreditCardModal = () => {
  const [showError, setShowError] = useState(false);
  const supportPhone = useSupportPhoneNumber();
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const handleGetInflightPaymentDetails = useActions(getInflightPaymentDetails);
  const handleRemoveInflightPaymentDetails = useActions(() =>
    removeInflightPaymentDetails({ cardSubmissionUrl: true })
  );

  const onSubmit = (handleClose, scrollToModalTop) => (data) => {
    if (data === IBM_SUCCESS) {
      setShowError(false);
      utils.dom.loadingOverlay(true);
      handleGetInflightPaymentDetails().then(() => {
        utils.dom.loadingOverlay(false);
        handleClose({ skipAnalytics: true });
      });
    }
    if (data === IBM_FAILURE) {
      setShowError(true);
      scrollToModalTop();
      handleRemoveInflightPaymentDetails();
    }
  };

  const renderError = () => (
    <>
      <GenericError
        errors={[{ priority: PRIORITY_ERROR, message: utils.i18n('stc_add_card_error_message_1') }]}
        description={utils.i18n(
          'stc_add_card_error_message_2',
          [
            <Anchor
              className='link link--text'
              key='add_card_support_phone_0'
              isExternal
              hideExternalIcon
              href={`tel:${supportPhone}`}
            >
              {supportPhone}
            </Anchor>,
          ],
          { jsx: true }
        )}
      />
      <p className='modal-themed__paragraph'>{utils.i18n('stc_add_card_error_info')}</p>
    </>
  );

  const renderInfoList = () => (
    <ul className='skip-the-counter-flow-add-card-modal__info-list'>
      {!isCustomPathReservation && (
        <li className='skip-the-counter-flow-add-card-modal__info-list-item'>
          {utils.i18n('stc_add_card_info_list_item_1')}
        </li>
      )}
      <li className='skip-the-counter-flow-add-card-modal__info-list-item'>
        {utils.i18n('stc_add_card_info_list_item_2')}
      </li>
    </ul>
  );

  return (
    <Modal
      modalKey={MODAL.STC_ADD_CREDIT_CARD_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n(isCustomPathReservation ? 'post_res_add_method_of_payment' : 'stc_add_credit_card_title')}
    >
      {({ handleClose, scrollToModalTop }) => (
        <>
          {showError ? renderError() : renderInfoList()}

          <hr className='skip-the-counter-flow-add-card-modal__divider' />

          <CardHolderDisclaimer />

          <p className='skip-the-counter-flow-add-card-modal__required-note'>
            {utils.i18n('header_note_required_field')}
          </p>

          <SecureConnectionDisplay />

          <IbmPaymentIframe onSubmit={onSubmit(handleClose, scrollToModalTop)} />
        </>
      )}
    </Modal>
  );
};

STCAddCreditCardModal.propTypes = {};

export default STCAddCreditCardModal;
