import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import Modal from 'components/Modal';
import useActions from 'hooks/useActions';
import { clearReservationProfile } from 'actions/checkin';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * CheckInFirstNameConflictModal
 * Modal that notifies the user that it's first name will be updated to match existing profile in res
 * On Confirm first name will be updated
 * On Cancel will clear the profile found
 *
 * @return {JSX} CheckInFirstNameConflictModal component
 */
const CheckInFirstNameConflictModal = ({ closeAssociateFnameConflictModal }) => {
  const clearProfileAction = useActions(clearReservationProfile);

  const onCancelHandler = (handleClose, isAssociate) => () => {
    // if modal requested from associate banner and user pressed cancel cta, simply close the modal
    if (isAssociate) {
      handleClose();
    } else {
      clearProfileAction({ loadingOverlay: true }).then(() => {
        handleClose();
      });
    }
  };

  const onAcceptHandler = (handleClose, handleAccept) => () => {
    handleClose();
    // calling handleAccept function , if requested from associate banner and user pressed accept cta.
    handleAccept?.();
  };

  const handleGoBackOnClose = () => {
    // Closing the modal if closeAssociateFnameConflictModal props exists
    if (!closeAssociateFnameConflictModal) {
      clearProfileAction();
    }
  };
  return (
    <Modal
      modalKey={MODAL.CHECKIN_FIRST_NAME_CONFLICT_MODAL}
      customActionOnClose={handleGoBackOnClose}
      header={utils.i18n('check_in_first_name_conflict_modal_title')}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose, isAssociate, handleAccept }) => (
        <>
          <p className='modal-themed__paragraph'>{utils.i18n('check_in_first_name_conflict_modal_info')}</p>

          <ModalFooter
            buttons={[
              {
                type: DECLINE,
                label: utils.i18n('common_cancel'),
                handler: onCancelHandler(handleClose, isAssociate),
                largeButton: true,
              },
              {
                type: ACCEPT,
                label: utils.i18n('common_ok'),
                largeButton: true,
                handler: onAcceptHandler(handleClose, handleAccept),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default CheckInFirstNameConflictModal;
