import PropTypes, { bool } from 'prop-types';

export default {
  pickupTime: PropTypes.string,
  returnTime: PropTypes.string,
  handleEdit: PropTypes.func,
  labels: PropTypes.bool,
  afterHoursReturn: PropTypes.bool,
  afterHoursPickup: PropTypes.bool,
  showAHViewDetailsModal: PropTypes.func,
  hideEditButtons: bool,
};
