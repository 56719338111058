import React from 'react';
import utils from 'utils';
import { ASSETS } from 'constants';

const AboutYourPayment = ({ contractDetails, paymentMethod, billingAccount }) => {
  const modifyPaymentMethod = {
    ...paymentMethod,
    account_name: contractDetails?.contract_name,
    masked_billing_account_number: billingAccount?.billing_account_number,
  };

  const payment = utils.preparePaymentMethodsToDisplay(modifyPaymentMethod);

  return (
    <div className='about-pay-later about-payment__container theme--midnight-blue'>
      <div className='about-payment__title-container'>
        {/* Add image from dam + style */}
        <img
          className='about-payment__image'
          src={ASSETS.ABOUT_PAYMENT_CARD_ICON}
          alt={utils.i18n('about_pay_later_image_alt')}
        />
        <h2 className='about-payment__main-title'>{utils.i18n('about_your_payment_title')}</h2>
      </div>
      <h3 className='about-payment__sub-title'>{utils.i18n('about_your_payment_subtitle_one')}</h3>
      <p className='about-payment__sub-title-text'>{payment?.label}</p>
      <h3 className='about-payment__sub-title'>{utils.i18n('about_your_payment_subtitle_two')}</h3>
      <p className='about-payment__sub-title-text'>{utils.i18n('about_your_payment_subtitle_two_content')}</p>
    </div>
  );
};

export default AboutYourPayment;
