import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';
import { i18n } from 'utils/i18n';

const { DECLINE, EXTERNAL_LINK } = MODAL_BUTTON_TYPES;

const CanadaFrenchRedirectionModal = () => {
  const caDomain = window.ehi?.site?.globalGateway?.country_to_domain?.CA;
  if (!caDomain) {
    // eslint-disable-next-line: no-console
    console.log('Unable to get CA domain configuration. This should not happen.');
    return null;
  }

  const canadianFrenchLink = `https://${caDomain}/fr/`;
  return (
    <Modal
      modalKey={MODAL.CANADA_FRENCH_REDIRECTION_MODAL}
      header={i18n('french_modal_title_always_ca_fr')}
      theme={MODAL_THEMES.WHITE}
      customClass='modify-return__french-modal'
    >
      {() => (
        <div className='modal-themed__flight-change-delayed-modal'>
          <p className='modal-themed__paragraph'>{i18n('french_modal_content_always_ca_fr')}</p>
          <ModalFooter
            fullWidthButton
            buttons={[
              {
                type: DECLINE,
                label: i18n('french_modal_cancel_btn_always_ca_fr'),
                analyticsKey: utils.analytics.prepareKey(MODAL.CANADA_FRENCH_REDIRECTION_MODAL),
                analyticsValue: ANALYTICS.ANNULER,
              },
              {
                type: EXTERNAL_LINK,
                label: i18n('french_modal_continue_btn_always_ca_fr'),
                linkTo: canadianFrenchLink,
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(MODAL.CANADA_FRENCH_REDIRECTION_MODAL),
                    ANALYTICS.CONTINUER
                  ),
              },
            ]}
          />
        </div>
      )}
    </Modal>
  );
};

CanadaFrenchRedirectionModal.propTypes = {
  handleClose: PropTypes.func,
  openUpdateFlightInfoModal: PropTypes.func,
};

export default CanadaFrenchRedirectionModal;
