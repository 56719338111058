import React from 'react';
import PropTypes from 'prop-types';

/**
 * ReceiptRentalSideBlock - Receipt rental side block
 *
 * @param {object} props - React Props
 * @param {object} props.title - Block title
 * @param {element} props.children - Receipt element details
 * @return {JSX}
 */
const ReceiptRentalSideBlock = ({ children, title }) => (
  <table className='receipt-side-block'>
    <caption>
      <h3 className='receipt__side-block-title'>{title}</h3>
    </caption>
    <tbody>{children}</tbody>
  </table>
);

ReceiptRentalSideBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default ReceiptRentalSideBlock;
