import { connect } from 'react-redux';
import utils from 'utils';
import { deeplinkIntoReservationFlow } from "actions/reservation/deeplinkIntoReservationFlow";
import Deeplink from './Deeplink';

const mapDispatchToProps = {
  deeplinkIntoReservationFlow,
};

export default connect(
  null,
  mapDispatchToProps
)(utils.toJS(Deeplink));
