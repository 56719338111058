import React from 'react';
import utils from 'utils';
import GoogleMapHOC from './GoogleMapHOC';
import { propTypes, defaultProps } from './MapPropTypes';

class Map extends React.Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  componentDidMount() {
    const { map } = this.props;
    if (map) {
      this.setMarkers();
    }
  }

  componentDidUpdate(prevProps) {
    const { fetching, map } = this.props;

    const conditions = {
      didFetch: prevProps.fetching && !fetching,
      didLoadMap: !prevProps.map && !!map,
    };

    if (conditions.didFetch || conditions.didLoadMap) {
      this.setMarkers();
    }
  }

  setMarkers = () => {
    const { markers, setMarkers } = this.props;
    if (utils.gmi.isArrayNotEmpty(markers)) {
      utils.safeExecute(setMarkers);
    }
  };

  render() {
    const { css } = this.props;
    return <div className={css.MapContainer}>{this.props.children}</div>;
  }
}

export default GoogleMapHOC(Map);
