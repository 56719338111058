import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { MODAL_BUTTON_TYPES } from 'constants/modals';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { deeplinkToPartnerLocation } from 'actions/locationFinder';
import useActions from 'hooks/useActions';
import ANALYTICS from 'constants/analytics';
/**
 * Informs users that by going to a partner website the current
 * reservation will lose its product code and coupon.
 *
 * @param {object} config - Needed configuration we need to use in order to cross-sell accordingly
 * @param {string} config.location - The selected partner location
 * @param {boolean} config.goToBookPage - Should users be taken to the `book` page?
 * @return {JSX}
 */
const BookWithPartnerModalContent = config => {
  const goToPartnerWebsite = useActions(() => deeplinkToPartnerLocation(config));
  return (
    <>
      <p className='modal-themed__paragraph'>{utils.i18n('book_with_partner_modal_content')}</p>
      <ModalFooter
        fullWidthButton
        buttons={[
          {
            type: MODAL_BUTTON_TYPES.DECLINE,
            label: utils.i18n('book_with_partner_stay_and_keep'),
            analyticsValue: ANALYTICS.STAY,
          },
          {
            type: MODAL_BUTTON_TYPES.ACCEPT,
            label: utils.i18n('book_with_partner_continue'),
            handler: goToPartnerWebsite,
            customClass: 'link--external',
            analyticsValue: ANALYTICS.CONTINUE,
          },
        ]}
      />
    </>
  );
};

BookWithPartnerModalContent.propTypes = {
  brand: PropTypes.string,
  goToBookPage: PropTypes.bool,
};

export default BookWithPartnerModalContent;
