import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import { dispatchEventWithDOM, dtm } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import Title from 'components/Title';
import CurrentTrip from 'components/CurrentTrip';
import UpcomingTrips from 'components/UpcomingTrips';
import PastTrips from 'components/PastTrips';
import MissingTripsModal from 'components/modals/MissingTripsModal';
import Tabs from 'components/Tabs';
import Button from 'components/Button';
import { loadingOverlay } from 'utils/dom';

/**
 * MyTrips Component - Show the session of Current Trip in My Trips page
 *
 * @param {object} props - React Props
 * @param {function} props.getCurrentTrips - Dispatch call to get current trips
 * @param {object} props.currentTrips - Result of getCurrentTripDetails
 * @param {object} props.upcomingTrips - Result of upcoming trips selector
 * @param {object} props.pastTrips - Result of past trips selector
 * @param {object} props.breakpoint - Breakpoint selector data
 * @param {Function} props.getPastTrips - Function to load past trips
 * @param {object} props.profileData - Object containing user's member number, necessary to call past trips
 * @param {Function} props.openMissingTripsModal - function to open missing trips modal for empty state
 * @param {boolean} props.isFetchingCurrentTrip - true while fetching "trips/current"
 * @return {JSX} - MyTrips React Component
 */

const MyTrips = ({
  getCurrentTrips,
  currentTrips,
  pastTrips,
  upcomingTrips,
  breakpoint,
  profileData,
  getPastTrips,
  openMissingTripsModal,
  isFetchingCurrentTrip,
  isFetchingPastTrips,
}) => {
  const location = useLocation();
  const { fromReceipt } = location?.state || {};

  const [activeTab, setActiveTab] = useState(fromReceipt ? 1 : 0);
  const [fetched, setFetched] = useState(false);
  const genericTitle = `${i18n('profile_page_title')} - ${i18n('reservation_flow_page_title')}`;

  const tabsArray = [
    {
      buttonLabel: i18n('my_trips_current_title_with_paramenter', [currentTrips.length]),
      component: <CurrentTrip currentTrips={currentTrips} />,
      dtmAttribute: dtm(ANALYTICS.MY_TRIPS, 'current', ANALYTICS.VIEW),
      ref: useRef(null),
      isEmpty: currentTrips.length,
    },
    {
      buttonLabel: i18n('my_trips_upcoming_title', [upcomingTrips.length]),
      component: <UpcomingTrips upcomingTrips={upcomingTrips} />,
      dtmAttribute: dtm(ANALYTICS.MY_TRIPS, 'upcoming', ANALYTICS.VIEW),
      ref: useRef(null),
    },
    {
      buttonLabel: i18n('my_trips_past_title', [pastTrips.length]),
      component: <PastTrips pastTrips={pastTrips} />,
      dtmAttribute: dtm(ANALYTICS.MY_TRIPS, 'past', ANALYTICS.VIEW),
      ref: useRef(null),
    },
  ];

  useEffect(() => {
    let activeTabIndex = 0;

    if (fetched && !isFetchingCurrentTrip) {
      if (currentTrips.length === 0 && upcomingTrips.length >= 0) {
        activeTabIndex = 1;
      }

      if (pastTrips.length > 0 && currentTrips.length === 0 && upcomingTrips.length === 0) {
        activeTabIndex = 2;
      }
    }

    return setActiveTab(activeTabIndex);
  }, [currentTrips, upcomingTrips, pastTrips, fetched, isFetchingCurrentTrip]);

  useEffect(() => {
    getCurrentTrips(true);
    setFetched(true);
    document.title = `${i18n('my_trips_title')} - ${genericTitle}`;

    // Safari uses [back-forward cache] that shows the cached page when the user clicks the back button
    // so, we need to hide the loading overlay when the page is loaded from the cache
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        loadingOverlay(false);
      }
    });
  }, []);

  // This is for dispatching an event when all info in MyTrips loaded
  useEffect(() => {
    fetched && !isFetchingCurrentTrip && dispatchEventWithDOM('secondary-load');
  }, [isFetchingCurrentTrip]);

  useEffect(() => {
    // This is to avoid that getPastTrips is called before profileData is loaded
    // We need to call getPastTrips here so we can know for sure the lenght of it
    if (profileData?.memberNumber && !isFetchingPastTrips) {
      const payload = { membership_number: profileData.memberNumber };
      getPastTrips(payload);
    }
  }, [profileData]);

  const tabsRightContent = !breakpoint.isMobile && (
    <Button
      link
      className='my-trips__missing-cta link'
      onClick={openMissingTripsModal}
      data-dtm-track={dtm(ANALYTICS.MY_TRIPS, 'missing_trip', ANALYTICS.SELECT)}
    >
      <span>{i18n('my_trips_missing_a_trip_cta')}</span>
    </Button>
  );

  return (
    <div className='my-trips'>
      <div className='row'>
        <div className='my-trips__header'>
          <Title type='h1' text={i18n('my_trips_title')} />
          {breakpoint.isMobile && (
            <Button
              link
              className='my-trips__missing-cta link'
              onClick={openMissingTripsModal}
              data-dtm-track={dtm(ANALYTICS.MY_TRIPS, 'missing_trip', ANALYTICS.SELECT)}
            >
              <span>{i18n('my_trips_missing_a_trip_cta')}</span>
            </Button>
          )}
        </div>
      </div>
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsArray={tabsArray}
        tabsRightContent={tabsRightContent}
      />
      <MissingTripsModal />
    </div>
  );
};

MyTrips.propTypes = {
  getCurrentTrips: PropTypes.func,
  currentTrips: PropTypes.arrayOf(PropTypes.object),
  pastTrips: PropTypes.arrayOf(PropTypes.object),
  upcomingTrips: PropTypes.arrayOf(PropTypes.object),
  breakpoint: PropTypes.object,
  getPastTrips: PropTypes.func,
  profileData: PropTypes.object,
  openMissingTripsModal: PropTypes.func,
  isFetchingCurrentTrip: PropTypes.bool,
};

export default MyTrips;
