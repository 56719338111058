import React from 'react';
import { useSelector } from 'react-redux';
import { trimSpaceFromStringValueOfObject } from 'utils/object';
import { i18n } from 'utils/i18n';
import useActions from 'hooks/useActions';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import RESERVATION_LOOKUP from 'constants/reservationLookup';
import { reservationLookup } from 'actions/reservationLookup';
import { profileDataSelector } from 'selectors/profileSelectors';
import { preSelectedReservationRetrieveDataSelector } from 'selectors/reservationSelectors';
import ServiceErrors from 'components/ServiceErrors';
import PreSelectedMessages from 'components/PreSelectedMessages';
import ReservationLookupForm from 'components/ReservationLookupForm';
import STCNotCheckedInModal from 'components/modals/STCNotCheckedInModal';
import STCIneligibleModal from 'components/modals/STCIneligibleModal';
import STCAlreadyActivatedModal from 'components/modals/STCAlreadyActivatedModal';
import CancelledReservationModal from 'components/modals/CancelledReservationModal';
import CheckInIneligibleModal from 'components/modals/CheckInIneligibleModal';
import SignInModal from 'components/modals/SignInModal';
import CheckInLoyaltyConflictModal from 'components/modals/CheckInLoyaltyConflictModal';

const { STC_ORIGIN } = SKIP_THE_COUNTER;
const { LOOK_UP_TYPE } = RESERVATION_LOOKUP;

/**
 * Skip-The-Counter Lookup Form Component
 *
 * @return {JSX}
 */
const SkipTheCounterLookup = () => {
  const profile = useSelector(state => profileDataSelector(state, { first_name: true, last_name: true }));
  const preSelectedReservation = useSelector(preSelectedReservationRetrieveDataSelector);
  const reservationLookupHandler = useActions(reservationLookup);

  const onFormSubmit = ({ reservationData }) => {
    const trimStringValuesObj = trimSpaceFromStringValueOfObject(reservationData);
    reservationLookupHandler({
      reservationData: trimStringValuesObj,
      origin: STC_ORIGIN.LOOK_UP,
      lookupType: LOOK_UP_TYPE.STC_LOOK_UP,
    });
  };

  return (
    <section className='post-res-lookup theme--light-blue'>
      <span className='post-res-lookup__legend' aria-hidden={true}>
        {i18n('review_required_indication')}
      </span>
      <ServiceErrors />
      <PreSelectedMessages />
      <ReservationLookupForm
        className='post-res-lookup__form'
        onFormSubmit={onFormSubmit}
        submitButtonText={i18n('common_start')}
        loyaltyProfile={profile}
        submitDTMTrack='check_in|counter|start'
        preSelectedReservation={preSelectedReservation}
      />
      <CancelledReservationModal />
      <STCNotCheckedInModal />
      <STCIneligibleModal />
      <STCAlreadyActivatedModal />

      {/* Since Custom Path Reservation can be looked up on this page,
       we need to add the modals being dispatch under reservationLookup/customPathCheckInLookup */}
      <CheckInIneligibleModal />
      <SignInModal />
      <CheckInLoyaltyConflictModal />
    </section>
  );
};

export default SkipTheCounterLookup;
