import React from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { breakpointSelector } from 'selectors/breakpoint';
import { stepConfigSelector } from 'selectors/checkinSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import LinkModalGroupCustomPath from 'components/LinkModalGroupCustomPath';

/**
 * Check-in Step Info Component
 * Sidebar that displays the current step info during the Accelerated Check-in Flow.
 *
 * @return {JSX} CheckInStepInfo jsx component
 */
const CheckInStepInfo = () => {
  const { title, description, stepCounter } = useSelector(stepConfigSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const breakpoints = useSelector(breakpointSelector);

  const { isTabletOrMobile } = breakpoints;

  // If there's only a single total step, no need to show the counter
  const stepCounterLine =
    stepCounter.total > 1 ? i18n('check_in_step_count', [stepCounter.current, stepCounter.total]) : '';
  const stepCounterARIALabel = stepCounterLine ? `${stepCounterLine}, ${title}` : title;

  return (
    <section className='check-in-template__sidebar'>
      <h2 className='check-in-template__step-title' aria-label={stepCounterARIALabel}>
        {!isCustomPathReservation && !!stepCounterLine && (
          <span className='check-in-template__step-counter'>{stepCounterLine}</span>
        )}
        {title}
      </h2>
      <div>
        {description?.map((paragraph, index) => (
          <p key={`step-paragraph-${index}`} className='check-in-template__description'>
            {paragraph}
          </p>
        ))}
      </div>
      {isCustomPathReservation && !isTabletOrMobile && <LinkModalGroupCustomPath />}
    </section>
  );
};

export default CheckInStepInfo;
