import { baseSessionPath } from 'paths/session.js';

export const rentalSessionPath = [...baseSessionPath, 'rental'];

/**
 * Un-auth ViewModifyRental user data
 * @example ['gmi', 'session', 'rental', 'rental_agreement']
 */
export const appViewModifyCancelRentalAgreementPath = [...rentalSessionPath, 'rental_agreement'];

/**
 * Un-auth ViewModifyRental Lookup Rentals
 * @example ['gmi', 'session', 'rental', 'lookup_rentals']
 */
export const appViewModifyCancelLookupRentalsPath = [...rentalSessionPath, 'lookup_rentals'];
/**
 * Un-auth ViewModifyRental user data
 * @example ['gmi', 'session', 'rental', 'rental_search_criteria']
 */
export const rentalSearchCriteriaPath = [...rentalSessionPath, 'rental_search_criteria'];

/**
 * Un-auth ViewModifyRental user data
 * @example ['gmi', 'session', 'rental', 'rental_search_criteria']
 */
export const deeplinkDeliveryOptionPath = [...rentalSearchCriteriaPath, 'deeplink_delivery_option'];
