import React, { Component } from 'react';
import Button from 'components/Button';
import utils from 'utils';
import { propTypes } from './LocationTilePropTypes';

class LocationTile extends Component {
  static propTypes = propTypes;

  handleBookNow = () => false; // TODO: need booking url

  render() {
    const { name, locationType, url, addressLines, formattedCityStateZip, countryCode } = this.props;

    return (
      <div className='location-tile component-theme--light'>
        <div className={`location-tile__content location-tile__content--${locationType}`}>
          <div className='location-tile__info'>
            <h4 className='location-tile__name' aria-label={name}>
              {name}
            </h4>

            <p className='location-tile__address'>
              {addressLines &&
                addressLines.map((line, index) => (
                  <span key={index} className='location-tile__address__line'>
                    {line}
                  </span>
                ))}
              <span className='location-tile__address__line'>
                {formattedCityStateZip} {countryCode}
              </span>
            </p>

            <a
              className='location-tile__details-link link link--arrow'
              href={url}
              title={utils.i18n('location_tile_location_details_aria', { 0: name })}>
              {utils.i18n('location_tile_location_details')}
            </a>
          </div>
        </div>

        <Button
          className='location-tile__book-now-button'
          ariaText={utils.i18n('location_tile_book_now_aria', { 0: name })}
          onClick={this.handleBookNow}>
          {utils.i18n('location_tile_book_now')}
        </Button>
      </div>
    );
  }
}

export default LocationTile;
