import React from 'react';
import { i18n } from 'utils/i18n';

const ImplicitSMSConsent = ({ privacyPolicyLink }) => (
        <p className='review__section-sms__opt-in-context'>
            {i18n('eu_sms_opt_in_consent', [privacyPolicyLink], { jsx: true })}
        </p>
    )

export default ImplicitSMSConsent;
