import PropTypes from 'prop-types';

export const propTypes = {
  /**
   * set age in gmi.ui.reservations.initiate
   *
   * @type {Function}
   */
  setAge: PropTypes.func.isRequired,
  /**
   * default age value
   */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * field label
   * @type {String}
   * */
  label: PropTypes.string,
  /**
   * Age Options
   * @type {Array}
   * */
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  driversAge: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isDriverAgeUpdated: PropTypes.bool,
};

export const defaultProps = {};
