import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import print from 'utils/print';
import { dtm } from 'utils/analytics';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import PostResLayout from 'components/PostRes/PostResLayout';
import Button from 'components/Button';
import useActions from 'hooks/useActions';
import MODAL from 'constants/modals';
import ASSETS from 'constants/assets';
import THEMES from 'constants/themes';
import ANALYTICS from 'constants/analytics';
import WayfindingsModal from 'components/modals/WayfindingsModal';
import STCTicketModal from 'components/modals/STCTicketModal';
import LoadingWidget from 'components/LoadingWidget';
import Picture from 'components/Picture';
import {
  vehicleRateSelector,
  selectedBundleWithExtrasSelector,
  numberOfExtrasSelector,
  extrasWithoutBundlesSelector,
} from 'selectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { selectedExtras } from 'selectors/extrasSelectors';
import { breakpointSelector } from 'selectors/breakpoint';
// TO DO: *TECH DEBT* Potentially can remove all other selectors aside from the last one `getSessionGboReservation` as it gets the full reservation object.
import {
  reservationDriverInfoSelector,
  carClassDetailsSelector,
  getReservationPickupLocation,
  getReservationReturnLocation,
  getReservationPickupTime,
  getReservationReturnTime,
  getReservationSkipTheCounterBarcode,
  rentalTermsAndConditions,
  getReservationConfirmationNumber,
  pickupLocationSTCWayfindingsSelector,
  selectedPaymentMethodSelector,
  getSessionGboReservation,
  isTourVoucherReservationSelector,
} from 'selectors/reservationSelectors';
import { isDestinationCurrencySelected } from 'selectors/vehicleSelectSelectors';
import { formattedPoliciesSelector, wayfindingPolicySelector } from 'selectors/policiesSelectors';
import { inPathAdditionalDriversSelector, selectedCarClassCoupons } from 'selectors/reservationFlowSelectors';
import { branchLocalAddendaSelector } from 'selectors/skipTheCounterSelectors';
import { isCustomPathReservationSelector, isVirginReservationSelector } from 'selectors/customPathCheckInSelectors';
import PoliciesComponent from 'components/PoliciesComponent';
import LinkModalGroupCustomPath from 'components/LinkModalGroupCustomPath';
import Anchor from 'components/Anchor';
import { updateVehicleRatesSelectors } from 'selectors/currencyToggleSelectors';
import ticket from 'images/icons/ticket.svg';
import { getRentalTermsAndConditions } from "actions/reservation/getRentalTermsAndConditions";
import { openModal } from "actions/modal/openModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import TermsAndPolicies from './TermsAndPolicies';
import STCConfirmationPickupInstructions from './STCConfirmationPickupInstructions';
import STCConfirmationArrivalInstruction from './STCConfirmationArrivalInstruction';
import STCConfirmationStepsList from './STCConfirmationStepsList';
import STCConfirmationPrint from './STCConfirmationPrint';
import STCViewTicket from './STCViewTicket';

/**
 * STCConfirmation - Skip the Counter confirmation
 * @param {object} props - React Props
 * @return {JSX}
 */
const STCConfirmation = () => {
  // Selectors
  const { isMobile, isDesktop, isTabletOrMobile } = useSelector(breakpointSelector);
  const pickupLocation = useSelector(getReservationPickupLocation);
  const returnLocation = useSelector(getReservationReturnLocation);
  const pickupTime = useSelector(getReservationPickupTime);
  const returnTime = useSelector(getReservationReturnTime);
  const carClassDetails = useSelector(carClassDetailsSelector);
  const driverInfo = useSelector(reservationDriverInfoSelector);
  const selectedPayment = useSelector(selectedPaymentMethodSelector);
  const barcode = useSelector(getReservationSkipTheCounterBarcode);
  const reservationConfirmationNumber = useSelector(getReservationConfirmationNumber);
  const directionsToLot = useSelector(pickupLocationSTCWayfindingsSelector);
  const driversList = useSelector(inPathAdditionalDriversSelector);
  const termsAndConditions = useSelector(rentalTermsAndConditions);
  const isFetchingSession = useSelector((state) => isFetchingEndpointSelector(state, { endpoint: 'session/current' }));
  const reservationPolicies = useSelector(formattedPoliciesSelector);
  const branchLocalAddenda = useSelector(branchLocalAddendaSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const wayfindingPolicy = useSelector(wayfindingPolicySelector);
  const isVirginReservation = useSelector(isVirginReservationSelector);
  const isDestinationCurrency = useSelector(isDestinationCurrencySelected);
  const fullSessionReservation = useSelector(getSessionGboReservation)?.toJS();
  let vehicleRate = useSelector((state) => vehicleRateSelector(state))?.toJS();
  if (isDestinationCurrency) {
    vehicleRate = updateVehicleRatesSelectors(vehicleRate);
  }
  const selectedBundleWithExtras = useSelector((state) => selectedBundleWithExtrasSelector(state));
  const extras = useSelector((state) => selectedExtras(state));
  const numberOfExtrasSelected = useSelector((state) => numberOfExtrasSelector(state));
  const extrasWithoutBundles = useSelector((state) => extrasWithoutBundlesSelector(state));
  const coupons = useSelector((state) => selectedCarClassCoupons(state));
  const isTourVoucherReservation = useSelector((state) => isTourVoucherReservationSelector(state));

  // Actions
  const openViewDirectionsLotModal = useActions(
    () => openModalWithProps(MODAL.STC_VIEW_DIRECTIONS_LOT_MODAL, { wayfindings: directionsToLot }),
    [directionsToLot]
  );
  const openViewTicketModal = useActions(() => openModal(MODAL.STC_VIEW_TICKET_MODAL));
  const getTermsAndConditions = useActions(getRentalTermsAndConditions);

  const paymentCardType = selectedPayment?.card_type;
  const paymentCardNumber = selectedPayment?.number;
  const isSameLocation = pickupLocation.id === returnLocation.id;
  const barcodeImage = utils.url.replaceUrlParamsInBrackets(barcode?.path, { width: 480, quality: 'high' });

  const termsAndConditionsRef = useRef(null);

  const scrollToLocationTermsAndConditions = () =>
    termsAndConditionsRef?.current && utils.dom.scrollPage(0, termsAndConditionsRef.current.offsetTop);

  const onPrintConfirmation = () => {
    print(['.skip-the-counter-confirmation__print']);
    utils.analytics.interaction(ANALYTICS.UI_BUTTON, 'stc', 'view ticket');
  };

  useEffect(() => {
    if (!termsAndConditions) {
      getTermsAndConditions();
    }
  }, [termsAndConditions]);

  return (
    <>
      <PostResLayout>
        {isFetchingSession ? (
          <LoadingWidget />
        ) : (
          <>
            <div className='skip-the-counter-confirmation'>
              <h2 className='skip-the-counter-confirmation__title-subheading'>
                {utils.i18n('stc_pickup_all_set')}
                <span className='skip-the-counter-confirmation__title-subheading-icon' />
              </h2>
              <h1 className='skip-the-counter-confirmation__title'>{utils.i18n('stc_pickup_use_your_pickup')}</h1>
              <p className='skip-the-counter-confirmation__paragraph'>
                {utils.i18n('stc_email_sent_instructions', [driverInfo?.email_address])}
              </p>
              <div className='skip-the-counter-confirmation__section'>
                <div className='skip-the-counter-confirmation__section-item'>
                  {!isMobile && (
                    <div className='skip-the-counter-confirmation__section-item-print'>
                      <div className='skip-the-counter-confirmation__section-title pick-up-icon'>
                        <img src={ticket} alt='' />
                        <h3 className='skip-the-counter-confirmation__section-title--pick-up-header'>
                          {utils.i18n('stc_pickup_ticket_label')}
                        </h3>
                      </div>
                      <div className='skip-the-counter-confirmation__ticket-modal-desktop'>
                        <Picture src={barcodeImage} className='skip-the-counter-confirmation__ticket-modal-image' />
                        <span className='skip-the-counter-confirmation__view-ticket-modal-confirmation'>
                          {utils.i18n('confirmation_number', [reservationConfirmationNumber])}
                        </span>
                      </div>
                      <ul className='skip-the-counter-confirmation__section-list'>
                        <li>{utils.i18n('stc_print_out')}</li>
                        <li>
                          {utils.i18n('stc_access_ticket_offline_1')}{' '}
                          <Button
                            link
                            className='skip-the-counter-confirmation__link'
                            onClick={isDesktop ? onPrintConfirmation : openViewTicketModal}
                          >
                            {utils.i18n('confirmation_print')}
                          </Button>{' '}
                          {utils.i18n('stc_access_ticket_offline_2')}
                        </li>
                        <li>{utils.i18n('stc_looking_email_up_rental')}</li>
                      </ul>

                      <Button className='skip-the-counter-confirmation__btn-print' onClick={onPrintConfirmation}>
                        {utils.i18n('stc_view_ticket')}
                      </Button>
                    </div>
                  )}
                  {isMobile && (
                    <div className='skip-the-counter-confirmation__section-item-print-mobile'>
                      <STCViewTicket
                        barcodeImage={barcodeImage}
                        carClassDetails={carClassDetails}
                        driverInfo={driverInfo}
                      />
                      <p className='skip-the-counter-confirmation__section-text'>
                        {utils.i18n('stc_looking_up_rental')}&nbsp;
                        {utils.i18n('stc_access_ticket_offline_1')}
                        &nbsp;
                        <Anchor className='skip-the-counter-confirmation__link' onClick={openViewTicketModal}>
                          {utils.i18n('stc_print_ticket')}
                        </Anchor>
                      </p>
                    </div>
                  )}

                  {isDesktop && !isCustomPathReservation && (
                    <TermsAndPolicies scrollToLocationTermsAndConditions={scrollToLocationTermsAndConditions} />
                  )}
                  {isCustomPathReservation && !isTabletOrMobile && <LinkModalGroupCustomPath />}
                </div>

                <div className='skip-the-counter-confirmation__section-item'>
                  <div className='skip-the-counter-confirmation__section-item-info'>
                    <span className='skip-the-counter-confirmation__section-label'>{utils.i18n('stc_next_steps')}</span>
                    {wayfindingPolicy && isVirginReservation && (
                      <div>
                        <h3 className='skip-the-counter-confirmation__section-title skip-the-counter-confirmation__section-pickup-instructions'>
                          {utils.i18n('rental_details_arrival_instructions')}
                        </h3>

                        <STCConfirmationArrivalInstruction wayfindingPolicy={wayfindingPolicy} />
                      </div>
                    )}
                    <div>
                      <h3 className='skip-the-counter-confirmation__section-title skip-the-counter-confirmation__section-pickup-instructions'>
                        {utils.i18n('stc_pickup_instructions')}
                      </h3>

                      <STCConfirmationPickupInstructions
                        {...{
                          openViewTicketModal,
                          paymentCardType,
                          paymentCardNumber,
                          onPrintConfirmation,
                          scrollToLocationTermsAndConditions,
                          isTourVoucherReservation,
                        }}
                      />
                    </div>
                    <STCConfirmationStepsList {...{ openViewDirectionsLotModal, carClassDetails }} />
                  </div>

                  <div className='skip-the-counter-confirmation__view-reservation-block'>
                    <Button
                      className='skip-the-counter-confirmation__view-reservation'
                      onClick={() =>
                        utils.loadingRedirect(
                          isCustomPathReservation
                            ? WINDOW_OBJECT_KEYS.CUSTOM_PATH_RENTAL_DETAILS
                            : WINDOW_OBJECT_KEYS.RENTAL_DETAILS
                        )
                      }
                      data-dtm-track={dtm(
                        isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.CHECK_IN,
                        ANALYTICS.CONFIRMATION,
                        'view_reservation'
                      )}
                    >
                      {utils.i18n('stc_view_reservation')}
                    </Button>
                  </div>
                </div>
              </div>

              {!isDesktop && !isCustomPathReservation && (
                <TermsAndPolicies scrollToLocationTermsAndConditions={scrollToLocationTermsAndConditions} />
              )}
              {isCustomPathReservation && isTabletOrMobile && <LinkModalGroupCustomPath />}
            </div>

            <WayfindingsModal />
            <STCTicketModal
              barcodeImage={barcodeImage}
              carClassDetails={carClassDetails}
              isTourVoucherReservation={isTourVoucherReservation}
            />
            <STCConfirmationPrint
              {...{
                isSameLocation,
                openViewTicketModal,
                paymentCardType,
                paymentCardNumber,
                openViewDirectionsLotModal,
                carClassDetails,
                pickupLocation,
                returnLocation,
                pickupTime,
                returnTime,
                driversList,
                driverInfo,
                branchLocalAddenda,
                reservationConfirmationNumber,
                barcodeImage,
                wayfindingPolicy,
                isVirginReservation,
                fullSessionReservation,
                vehicleRate,
                selectedBundleWithExtras,
                extras,
                numberOfExtrasSelected,
                extrasWithoutBundles,
                coupons,
              }}
            />
          </>
        )}
      </PostResLayout>
      {!!reservationPolicies.length && (
        <div ref={termsAndConditionsRef}>
          <PostResLayout theme={THEMES.LIGHT_BLUE} noMinHeight>
            <PoliciesComponent
              title={utils.i18n('stc_confirmation_your_rentals_policies')}
              policies={reservationPolicies}
              backgroundArtURL={ASSETS.RENTAL_POLICIES_PLACEHOLDER}
              backgroundArtAlt={utils.i18n('policy_background_image_alt')}
              hideTermsConditionsCta
            />
          </PostResLayout>
        </div>
      )}
    </>
  );
};

export default STCConfirmation;
