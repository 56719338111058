import PropTypes from 'prop-types';

export const propTypes = {
  // Container Props -
  breakpoint: PropTypes.object,

  // Parent Props:
  /**
   * Label
   *
   * @type {String}
   */
  label: PropTypes.string,

  // Parent Props:
  /**
   * Full label
   *
   * @type {String}
   */
  fullLabel: PropTypes.string,

  /**
   * Label for Mobile Header
   *
   * @type {String}
   */
  mobileLabel: PropTypes.string,

  /**
   * Sets the asterik in label
   *
   * @type {Boolean}
   */
  required: PropTypes.bool,

  /**
   * Default selected value
   *
   * @type {String, Boolean}
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),

  /**
   * Array of options
   *  const ages = [
   *    { label: '25+ years old', value: '25+' },
   *    { label: '35+ years old', value: '35+' },
   *    { label: '45+ years old', value: '45+' },
   *    { label: '45+ years old', value: '45+' },
   *    { label: '55+ years old', value: '55+' },
   *  ];
   * @type {Array}
   */
  options: PropTypes.array,

  /**
   * Array of options
   *  const groups = [
   *    {
   *      name: 'Group Name' (optional),
   *      tooltip 'Tooltip HTML' (optional),
   *      options: ages
   *    }
   *  ];
   * @type {Array}
   */
  optionsGroups: PropTypes.array,

  /**
   * Change Callback
   *
   * @type {Function}
   */
  onChange: PropTypes.func,

  /**
   * Auto scrolls to suggested option by value or label
   *
   * @type {String}
   */
  suggestedOption: PropTypes.string,

  /**
   * Forces the tablet breakpoint to match mobile behavior (opt-in, default is desktop-like)
   *
   * @type {Boolean}
   */
  useMobileVersionForTablet: PropTypes.bool,
  timeAndDatePickerFullHeight: PropTypes.bool,

  /**
   * displaying the country selector combobox style if it true
   *
   * @type {Boolean}
   */
  countrySelector: PropTypes.bool,
  currentPageTitle: PropTypes.string,

  /**
   * updating the driversAge selector if driverAgeUpdated value is true and  driverAge has a value
   *
   * @type {String, Boolean}
   */

  driversAge: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isDriverAgeUpdated: PropTypes.bool,
};

export const defaultProps = [];
