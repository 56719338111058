import React from 'react';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { checkinCommit } from 'actions/checkin';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const WarnDriverDetailsMissingOciRequestModal = ({ formData }) => {
  const handleCommit = useActions(checkinCommit);

  const handleAccept = handleClose => () => {
    handleClose();
  };

  const handleConfirmCheckIn = handleClose => () => {
    handleCommit(formData, { redirect: false, isFromOCICustomPathFlow: true });
    handleClose();
  };

  return (
    <Modal
      modalKey={MODAL.DRIVER_DETAILS_MISSING_OCI_REQUEST_MODAL}
      header={utils.i18n('driver_details_missing_header')}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose }) => (
        <>
          <p className='modal-themed__copy'>{utils.i18n('driver_details_missing_oci_request_copy_1')}</p>
          <p className='modal-themed__copy'>{utils.i18n('driver_details_missing_oci_request_copy_2')}</p>
          <ModalFooter
            fullWidthButton
            buttons={[
              {
                type: MODAL_BUTTON_TYPES.DECLINE,
                label: utils.i18n('yes_confirm_check_in_decline'),
                handler: handleConfirmCheckIn(handleClose),
              },
              {
                type: MODAL_BUTTON_TYPES.ACCEPT,
                label: utils.i18n('no_add_driver_details_missing_accept'),
                handler: handleAccept(handleClose),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default WarnDriverDetailsMissingOciRequestModal;
