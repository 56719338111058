import React from 'react';
import { WINDOW_OBJECT_KEYS } from 'constants';
import utils from 'utils';
import Anchor from 'components/Anchor';

/**
 * ProfileAccountSupportCTA
 * text block with Customer Support for MyProfile page.
 *
 * @return {JSX} ProfileAccountSupportCTA
 */
const ProfileAccountSupportCTA = () => (
  <p className='profile-account__support-cta'>
    {utils.i18n(
      'profile_account_customer_support_copy',
      [
        <Anchor
          className='link link--text'
          href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)}
          key={0}>
          {utils.i18n('profile_account_customer_support')}
        </Anchor>,
      ],
      {
        jsx: true,
      }
    )}
  </p>
);

export default ProfileAccountSupportCTA;
