import React from 'react';
import { useSelector } from 'react-redux';
import { config } from 'utils/config';
import { i18n } from 'utils/i18n';
import { loadingOverlay } from 'utils/dom';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import MODAL, { MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants/modals';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import { ANALYTICS } from 'constants';
import utils from 'utils';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * CancelledReservationModal
 *
 * @return {JSX} ...
 */
const CancelledReservationModal = () => {
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);

  const redirectToDetails = () => {
    loadingOverlay(true);
    window.location.replace(
      config.getRedirectUrl(
        isCustomPathReservation ? WINDOW_OBJECT_KEYS.CUSTOM_PATH_RENTAL_DETAILS : WINDOW_OBJECT_KEYS.RENTAL_DETAILS
      )
    );
  };

  return (
    <Modal
      modalKey={MODAL.CANCELLED_RESERVATION_MODAL}
      header={i18n('cancelled_reservation_modal_header')}
      theme={MODAL_THEMES.WHITE}
    >
      {() => (
        <>
          <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
            {i18n('cancelled_reservation_modal_copy')}
          </p>

          <ModalFooter
            buttons={[
              {
                type: DECLINE,
                label: i18n('common_close'),
              },
              {
                type: ACCEPT,
                label: i18n('common_view_details'),
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(MODAL.CANCELLED_RESERVATION_MODAL),
                    ANALYTICS.VIEW_DETAILS
                  ),
                handler: redirectToDetails,
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default CancelledReservationModal;
