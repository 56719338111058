import React from 'react';
import PropTypes from 'prop-types';

/**
 * ReceiptRentalSideBlockListItem - List items structure
 * @param {object} props - React Props
 * @param {string} props.label - List item label
 * @param {string} props.label - List item label
 * @return {JSX} ReceiptRentalSideBlockList rendered
 */
const ReceiptRentalSideBlockListItem = ({ label, value }) =>
  !!value && (
    <>
      <th scope='row' align='left'>{`${label}:`}</th>
      <td>{value}</td>
    </>
  );

ReceiptRentalSideBlockListItem.propTypes = {
  label: PropTypes.string,
};

export default ReceiptRentalSideBlockListItem;
