import { connect } from 'react-redux';
import utils from 'utils';
import { guaranteedResPolicySelector } from 'selectors';
import GuaranteedReservationModal from './GuaranteedReservationModal';

const mapStateToProps = state => ({
  guaranteedResPolicy: guaranteedResPolicySelector(state),
});

export default connect(mapStateToProps)(utils.toJS(GuaranteedReservationModal));
