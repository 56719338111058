import { MapTo, withComponentMappingContext } from '@adobe/aem-react-editable-components';
import StoreFinder from './StoreFinder';

const StoreFinderEditableConfig = {
  emptyLabel: 'Store Finder Component',
  isEmpty: props => !props,
};
export default MapTo('aweb/components/content/store-finder')(
  withComponentMappingContext(StoreFinder),
  StoreFinderEditableConfig
);
