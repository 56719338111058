import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import utils from 'utils';
import { KEYS, MODAL_THEMES, BREAKPOINTS, MODAL } from 'constants';
import Picture from 'components/Picture';
import ClearAllButton from 'components/SortFilterWrapper/ClearAllButton';
import Button from 'components/Button';
import { ModalPropTypes, ModalDefaultProps } from './modalPropTypes';

const themeClasses = {
  [MODAL_THEMES.BLUE]: 'theme--light-blue',
  [MODAL_THEMES.WHITE]: 'theme--light',
};

/**
 * Modal
 * @module
 * @author yauheni.mukavozchyk@isobar.com
 * Usage:
 *    <Modal
 *      modalKey='my_unique_modal_key'
 *      title='My Modal'
 *      {'my modal content'}
 *    </Modal>
 */

class Modal extends Component {
  static propTypes = ModalPropTypes;

  static defaultProps = ModalDefaultProps;

  state = {
    stickyHeader: false,
    stickyTriggerElementStartingPosition: null,
  };

  modalContainerRef = createRef();

  modalFocus = createRef();

  headerText = createRef();

  headerCloseButton = createRef();

  modalContentRef = createRef();

  // any LCM will be fired since modal is always "mounted"
  // explicit conditions lock down execution and prevent perf issues
  componentDidUpdate(prevProps) {
    const { isOpen, breakpoint, finalAction, clearModalQueue, onClose, modalKey } = this.props;
    const { stickyHeader } = this.state;
    const didOpen = !prevProps.isOpen && isOpen;
    const didClose = prevProps.isOpen && !isOpen;
    const didOpenOrClose = isOpen !== prevProps.isOpen;
    const breakpointSmallTransition =
      (breakpoint === BREAKPOINTS.SMALL || prevProps.breakpoint === BREAKPOINTS.SMALL) &&
      breakpoint !== prevProps.breakpoint;

    if (modalKey !== MODAL.SESSION_TIMEOUT_MODAL && didOpen) {
      // on open
      // - store last focused element
      // - focus modal body
      this.lastFocusedEl = document.activeElement;

      const modalEl = this.modalContentRef.current;
      modalEl && utils.accessibility.findTabbableDescendants(modalEl)[0].focus();

      this.headerCloseButton?.current?.focus();
    } else if (didClose) {
      // - on close
      // - return focus
      this.lastFocusedEl && this.lastFocusedEl.focus();

      if (onClose) {
        onClose();
      }

      if (finalAction) {
        finalAction();
        clearModalQueue({ skipAnalytics: true });
      }

      if (stickyHeader) {
        this.setState({ stickyHeader: false });
      }
    }

    if (didOpenOrClose) {
      // TODO: Need to QA if any modals have mobile software keyboards and if this causes issues inside the modal
      utils.scrollLock({ toggleValue: isOpen });
      isOpen
        ? document.body.classList.add('modal-open-print-styles')
        : document.body.classList.remove('modal-open-print-styles');
    }

    if (breakpointSmallTransition) {
      this.setState({ stickyHeader: false, stickyTriggerElementStartingPosition: null });
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === KEYS.TAB) {
      const modalEl = this.modalContentRef.current;
      modalEl && utils.accessibility.scopeTab(modalEl, e);
    } else if (this.props.shouldCloseOnEsc && e.keyCode === KEYS.ESCAPE) {
      e.stopPropagation();
      this.props.clearModalQueue();
    }
  };

  // handleClose and handleClearModalQueue are designed to prevent dom events from making it through to the redux
  // actions if Modal children pass the actions directly to domElements. This is because we should avoid passing all
  // those props from the event objects to the reducers and middleware for fear of potentially weird naming collisions
  handleClose = (args) => this.props.closeModal(args?.nativeEvent ? null : args);

  // Checking for prop 'handleModalQueue' with the `skipAnalytics` flag is set to `true` // handleModalQueue={{skipAnalytics: true}}
  handleClearModalQueue = (args) => {
    if (this.props.handleModalQueue?.skipAnalytics) {
      this.props.clearModalQueue({ skipAnalytics: true });
    } else {
      this.props.clearModalQueue(args?.nativeEvent ? null : args);
    }
  };

  handleScroll = (e) => {
    const { breakpoint } = this.props;
    const { stickyHeader, stickyTriggerElementStartingPosition } = this.state;
    //  If the user is scrolling in the date picker or the time selector
    //  we don't want to change the modal.  We need the isScrollingOnDatePicker var due to changes on scrolling
    // in mobile
    const isScrollingOnDatePickerDesktop = e.target?.classList?.contains('react-datepicker');
    const isScrollingInTimeSelector = e.target.dataset.isTimeSelector;
    const stickyTriggerElement =
      breakpoint !== BREAKPOINTS.SMALL
        ? this.headerCloseButton.current || this.headerText.current
        : this.headerText.current;
    if (stickyTriggerElement && !(isScrollingInTimeSelector || isScrollingOnDatePickerDesktop)) {
      const scrollPosition = e.target.scrollTop;
      let startingPosition = stickyTriggerElementStartingPosition;

      if (!startingPosition) {
        const { top } = stickyTriggerElement.getBoundingClientRect();
        startingPosition = top + scrollPosition;
        this.setState({ stickyTriggerElementStartingPosition: startingPosition });
        // Saving the starting position of the stickyTriggerElement allows us to be more flexible with the Modal layout
        // styling, as opposed to having to keep a hardcoded stickyTriggerScrollPosition value in sync with Modal layout
      }

      const stickyTriggerScrollPosition =
        breakpoint !== BREAKPOINTS.SMALL
          ? startingPosition - stickyTriggerElement.offsetTop
          : Math.abs(startingPosition - 46);
      // This subtracted value is a magic number based on trial & error of what looks good on mobile, so need to re-evaluate if styles change
      // Math.abs will ensure the figure is always positive, especially in unique cases where the startingPosition is less than the magic number
      if (scrollPosition >= stickyTriggerScrollPosition && !stickyHeader) {
        this.setState({ stickyHeader: true });
      } else if (scrollPosition < stickyTriggerScrollPosition && stickyHeader) {
        this.setState({ stickyHeader: false });
      }
    }
  };

  // scrollToModalTop scrolls to top of modal which is helpful in cases such as if a modal includes an error or
  // notification at the top that we want to highlight but the modal may have scrolled out of sight of the notification.
  scrollToModalTop = () => {
    const modalScrollContainer = this.modalContainerRef?.current;
    const scrollAnimation = () => {
      if (modalScrollContainer && modalScrollContainer.scrollTop !== 0) {
        modalScrollContainer.scrollTop -= modalScrollContainer.scrollTop / 8;
        // The 8 above is a magic number from trial and error for getting a smooth scroll. This recursive
        // requestAnimationFrame method is best option when the scroll animation may be interrupted by component
        // re-renders.
        requestAnimationFrame(scrollAnimation);
      }
    };
    requestAnimationFrame(scrollAnimation);
  };

  handleClearAll = () => {
    this.props.handleClearAll();
  };

  renderHeader = ({ hidden } = {}) => {
    // Passing {hidden: true} option renders an invisible header used to preserve layout when main header becomes fixed
    const {
      contentProps,
      header,
      shouldRenderHeaderCloseButton,
      headerCloseAriaLabel,
      breakpoint,
      showHeaderIcon,
      showHeaderImg,
      headerImg = {},
      headerIcons = {},
      dataDtmTrackOpen,
      dataDtmTrackClose,
      customActionOnClose,
      modalKey,
      clearFilter,
      headerRowWithClearButton,
      showHeaderTextIcon,
      headerTextIcons = {},
      headerIconBgColor,
      secondIcon,
      analyticsKey = modalKey,
      showHeaderCTA,
      isExpanded,
      handleHeaderCTA,
      headerCtaTitle,
    } = this.props;
    const { stickyHeader } = this.state;
    const headerText = contentProps.header || header; // Header text passed in through openModalWithProps overrides
    const clearFilterText = clearFilter;
    const onMobile = breakpoint === BREAKPOINTS.SMALL;
    const headerAttrs =
      (dataDtmTrackOpen && {
        'data-dtm-track': dataDtmTrackOpen,
      }) ||
      {};
    const closeBtnAttrs =
      (dataDtmTrackClose && {
        'data-dtm-track': dataDtmTrackClose,
      }) ||
      {};

    const headerCloseButton = !hidden && shouldRenderHeaderCloseButton && (
      <Button
        plain
        className={cn('modal__close-btn', { 'modal__close-btn--sticky': stickyHeader })}
        aria-label={headerCloseAriaLabel}
        onClick={() => {
          customActionOnClose?.();

          /*
           * This allows the analyticsKey to be different from the modalKey. If none is provided, it defaults to the
           * modalKey (see destructuring of props above).
           */
          const args = {
            analyticsKey,
          };

          this.handleClearModalQueue(args);
        }}
        buttonRef={this.headerCloseButton}
        type='button'
        {...closeBtnAttrs}
      />
    );

    const headerIcon = showHeaderIcon && (
      <Picture
        className={headerIcons.className}
        src={headerIcons.src}
        srcTablet={headerIcons.srcTablet}
        srcMobile={headerIcons.srcMobile}
        alt={headerIcons.alt}
      />
    );

    const headerTextIcon = showHeaderTextIcon && (
      <div className={`modal__header-text-icon ${headerIconBgColor} ${headerTextIcons}`} />
    );

    // Conditional second icon specifically for flight update cancellation modal
    const secondHeaderIcon = showHeaderTextIcon && secondIcon && (
      <div className={'modal__header-text-icon-error-icon'} />
    );

    const modalHeaderImg = showHeaderImg && (
      <img className={headerImg.className} src={headerImg.src} alt={headerImg.alt} />
    );

    return (
      <>
        <div
          className={cn('modal__header', {
            'modal__header--sticky': !hidden && stickyHeader,
            'modal__header--layout-placeholder': hidden,
          })}
          {...headerAttrs}
        >
          {!onMobile && headerCloseButton}

          {headerIcon}

          {modalHeaderImg}

          {headerTextIcon}
          {secondHeaderIcon}

          {onMobile && !stickyHeader && <div className='modal__header__mobile-scroll-blocker' />}
          <div className={cn({ modal__header_row: headerRowWithClearButton })}>
            {headerText && (
              <div>
                <h2
                  id={`${modalKey}_label`}
                  ref={!hidden ? this.headerText : null}
                  className={cn('modal-themed__header', {
                    'no-margin-top': headerTextIcon,
                  })}
                  aria-hidden={!!hidden}
                >
                  {headerText}
                </h2>
              </div>
            )}
            {showHeaderCTA && (
              <Button
                plain
                onClick={handleHeaderCTA}
                aria-label={headerCtaTitle}
                aria-expanded={isExpanded}
                className={cn('modal-themed__header-cta', 'modal-themed__header-cta-draw-icon', {
                  'modal-themed__header-cta-draw-icon--open': isExpanded,
                })}
              >
                {headerCtaTitle}
              </Button>
            )}

            {clearFilterText && (
              <div className='modal-themed__left_header'>
                <ClearAllButton handleClearAll={this.handleClearAll} customClass='custom-filter-clear-all' />
              </div>
            )}
          </div>
        </div>
        {onMobile && headerCloseButton}
        {/* close button needs to be outside header in mobile to avoid weird bug with sticky header */}
      </>
    );
  };

  renderChildren = (children, modalFunctions, contentProps) => {
    if (utils.gmi.types(children).isFunction) {
      return children({ ...contentProps, ...modalFunctions });
    }

    // if children.type is a string, it is referencing a raw Dom element so passing props to it would cause
    // a react warning
    if (utils.gmi.types(children.type).isString) {
      return children;
    }

    return React.cloneElement(children, Object.assign(modalFunctions, contentProps));
  };

  render() {
    const { isOpen, contentProps, role, children, customClass, theme, modalKey, descId } = this.props;
    const { stickyHeader } = this.state;

    if (!isOpen) {
      // modal is always "mounted" but returning "null" would not affect rendering perf since there is no actual node to mount/unmount
      return null;
    }
    const themeClass = themeClasses[theme];
    const modalClasses = cn('modal-overlay', {
      [customClass]: customClass,
    });

    const modalFocusEl = <div ref={this.modalFocus} tabIndex={-1} onKeyDown={this.handleKeyDown} aria-hidden={true} />;
    // when modal extends below screen, initial focus would scroll down to top of modal. Using this element to keep focus at top

    const modalFunctions = {
      handleClose: this.handleClose,
      clearModalQueue: this.handleClearModalQueue,
      modalKey,
      scrollToModalTop: this.scrollToModalTop,
    };

    const modal = (
      <div className={modalClasses} onScroll={this.handleScroll} ref={this.modalContainerRef}>
        {modalFocusEl}
        <div
          className='modal'
          aria-modal={true}
          id={modalKey}
          tabIndex={-1}
          role={role}
          aria-labelledby={`${modalKey}_label`}
          aria-describedby={descId || `${modalKey}_desc`}
          ref={this.modalContentRef}
          onKeyDown={this.handleKeyDown}
        >
          <div className={cn('modal__body', { [themeClass]: themeClass })}>
            {this.renderHeader()}
            {stickyHeader && this.renderHeader({ hidden: true })}

            <div className='modal__content'>{this.renderChildren(children, modalFunctions, contentProps)}</div>
          </div>
        </div>
      </div>
    );
    return ReactDOM.createPortal(modal, document.body);
  }
}

export default Modal;
