import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { getFormattedZeroPrice } from 'utils/prices';
import useLoadingOverlay from 'hooks/useLoadingOverlay';
import { estimatedTotalViewSelector, getDidPrepay } from 'selectors/reservationFlowSelectors';
import { breakpointSelector } from 'selectors/breakpoint';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import Button from 'components/Button';
import ServiceErrors from 'components/ServiceErrors';
import PostResLayout from 'components/PostRes/PostResLayout';
import PostResRentalInfoLine from 'components/PostRes/PostResRentalInfoLine';
import LinkModalGroupCustomPath from 'components/LinkModalGroupCustomPath';
import CheckInStepInfo from '../CheckInTemplate/CheckInStepInfo';

/**
 * Check-in Review Template Component
 * Wrapper for the accelerated check-in review page.
 *
 * @param {object} props - React Props
 * @param {function} props.confirmAction - Function called when the confirm button is pressed - usually a form submit
 * @param {function} props.exitCheckin - Action to open Exit Check In modal
 * @param {boolean}  props.havePriceDifference - Used to check if it has a price difference
 * @param {array|node} props.children - JSX Components passed onto this component to be rendered within it
 *
 * @return {JSX} CheckInReviewTemplate jsx component
 */
const CheckInReviewTemplate = ({ confirmAction, exitCheckin, havePriceDifference, children }) => {
  useLoadingOverlay({ endpoint: 'session/current' });
  const estimatedTotal = useSelector(estimatedTotalViewSelector);
  const zeroChargeValue = getFormattedZeroPrice(estimatedTotal);
  const isPrepay = useSelector(getDidPrepay);
  const breakpoints = useSelector(breakpointSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);

  const { isTabletOrMobile } = breakpoints;

  return (
    <PostResLayout>
      <div className='check-in-template__review-header'>
        <div className='check-in-template__review-header-content'>
          <h1 className='check-in-template__heading'>{i18n('check_in_title')}</h1>
          <PostResRentalInfoLine />
        </div>
        <div className='check-in-template__review-header-action'>
          <Button onClick={confirmAction} className='pill' data-dtm-track='check_in|review|confirm'>
            {i18n('check_in_looks_good')}
          </Button>
        </div>
      </div>

      <section className='check-in-template__wrapper check-in-template__wrapper--review'>
        <CheckInStepInfo />

        <section className='check-in-template__content-area'>
          <ServiceErrors />
          <article>{children}</article>
        </section>
      </section>
      <div className='check-in-template__buttons-wrapper check-in-template__buttons-wrapper--tablet-center'>
        <div>
          <Button
            className='check-in-template__confirm-button'
            onClick={confirmAction}
            data-dtm-track='check_in|review|confirm'
          >
            {i18n('common_confirm')}
          </Button>
          {isPrepay && havePriceDifference && (
            <div className='check-in-template__total-cahrged-now'>
              ({i18n('stc_payment_information_total_charged_now')}: {zeroChargeValue})
            </div>
          )}
        </div>
        <Button
          link
          linkText
          className='check-in-template__discard-button'
          onClick={exitCheckin}
          data-dtm-track='check_in|review|skip'
        >
          {i18n('common_nevermind')}
        </Button>
      </div>

      {isCustomPathReservation && isTabletOrMobile && (
        <section>
          <LinkModalGroupCustomPath />
        </section>
      )}
    </PostResLayout>
  );
};

CheckInReviewTemplate.propTypes = {
  confirmAction: PropTypes.func,
  exitCheckin: PropTypes.func,
  havePriceDifference: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CheckInReviewTemplate;
