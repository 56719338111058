import React from 'react';
import { useSelector } from 'react-redux';
import { breakpointSelector } from 'selectors/breakpoint';
import { showBundlesSectionSelector } from 'selectors/extrasSelectors';
import utils from 'utils';
import useVehicleUpgradeDetails from 'hooks/useVehicleUpgradeDetails';
import useActions from 'hooks/useActions';
import Picture from 'components/Picture';
import LoadingWidget from 'components/LoadingWidget';
import { upgradeCarClass } from "actions/reservation/upgradeCarClass";
import UpgradeCta from './UpgradeCta';
import UpgradeTotalCopy from './UpgradeTotalCopy';
import UpgradeDescription from './UpgradeDescription';

/**
 * InPathVehicleUpgradeExtras - Extras page vehicle upgrade banner
 *
 * @return {JSX}  InPathVehicleUpgradeExtras component
 */
const InPathVehicleUpgradeExtras = () => {
  // InPathVehicleUpgradeExtras banner should only be visible when bundles are not shown
  const visible = !useSelector(showBundlesSectionSelector);

  const { inPathUpgradeAvailable, inPathUpgradeDetails, isFechingRates } = useVehicleUpgradeDetails();
  const upgradeHandler = useActions(upgradeCarClass);
  const { isMobile } = useSelector(breakpointSelector);

  const {
    upgradeName,
    upgradeMakeModelOrSimilarText,
    priceDifference,
    total,
    upgradeImagePath,
    currentName,
    currentMakeModelOrSimilarText,
    currentImagePath,
    upgradeClass,
  } = inPathUpgradeDetails || {};

  return inPathUpgradeAvailable && visible ? (
    <>
      {isFechingRates && (
        <div className='in-path-upgrade in-path-upgrade-extras'>
          <LoadingWidget />
        </div>
      )}
      {inPathUpgradeDetails && (
        <div className='in-path-upgrade in-path-upgrade-extras'>
          {isMobile ? (
            <>
              <UpgradeDescription name={upgradeName} priceDifference={priceDifference} />
              <div className='in-path-upgrade__car-details'>
                <div className='in-path-upgrade__vehicle'>
                  <Picture src={currentImagePath} alt={currentMakeModelOrSimilarText} />
                  <div>
                    <p className='in-path-upgrade__car-details-name'>{currentName}</p>
                    <p className='in-path-upgrade__car-details-make'>
                      ({utils.i18n('in_path_vehicle_upgrade_current_selection')})
                    </p>
                  </div>
                </div>
                <div className='in-path-upgrade__arrow' />
                <div className='in-path-upgrade__vehicle'>
                  <Picture
                    src={upgradeImagePath}
                    alt={upgradeMakeModelOrSimilarText}
                    pictureClassName='in-path-upgrade__picture'
                  />
                  <div>
                    <p className='in-path-upgrade__car-details-name'>{upgradeName}</p>
                    <p className='in-path-upgrade__car-details-make'>
                      ({utils.i18n('vehicle_details_model_or_similar', [upgradeMakeModelOrSimilarText])})
                    </p>
                  </div>
                </div>
              </div>
              <div className='in-path-upgrade__upgrade-cta'>
                <UpgradeCta
                  upgradeHandler={() => upgradeHandler(upgradeClass)}
                  page='extras'
                  upgradeClass={upgradeClass}
                />
                <UpgradeTotalCopy total={total} />
              </div>
            </>
          ) : (
            <>
              <div className='in-path-upgrade__car-details'>
                <Picture src={currentImagePath} alt={currentMakeModelOrSimilarText} />
                <p className='in-path-upgrade__car-details-name'>{currentName}</p>
                <p className='in-path-upgrade__car-details-make'>
                  ({utils.i18n('in_path_vehicle_upgrade_current_selection')})
                </p>
              </div>

              <div className='in-path-upgrade__car-details'>
                <Picture
                  src={upgradeImagePath}
                  alt={upgradeMakeModelOrSimilarText}
                  pictureClassName='in-path-upgrade__picture'
                />
                <p className='in-path-upgrade__car-details-name'>{upgradeName}</p>
                <p className='in-path-upgrade__car-details-make'>
                  ({utils.i18n('vehicle_details_model_or_similar', [upgradeMakeModelOrSimilarText])})
                </p>
              </div>

              <div className='in-path-upgrade__upgrade-cta'>
                <UpgradeDescription name={upgradeName} priceDifference={priceDifference} total={total} />
                <UpgradeCta
                  upgradeHandler={() => upgradeHandler(upgradeClass)}
                  page='extras'
                  upgradeClass={upgradeClass}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  ) : null;
};

export default InPathVehicleUpgradeExtras;
