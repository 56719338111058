import { connect } from 'react-redux';
import utils from 'utils';
import { isFetchingEndpointSelector, getSessionGboReservationExists } from 'selectors';
import {
  licenseeNamePath,
  legalNamePath,
  pickupWithDetailLicenseePath,
  rentalTermsAndConditionsPath,
} from 'paths/reservationLocation';
import { getRentalTermsAndConditions } from "actions/reservation/getRentalTermsAndConditions";
import RentalTermsAndConditionsModal from './RentalTermsAndConditionsModal';

const mapStateToProps = (state) => ({
  rentalTermsAndConditions: state.getIn(rentalTermsAndConditionsPath),
  franchiseeName: state.getIn(licenseeNamePath) || state.getIn(legalNamePath),
  isFranchisee: state.getIn(pickupWithDetailLicenseePath),
  isFetchingSession: isFetchingEndpointSelector(state, { endpoint: 'session/current' }),
  gboReservationExists: getSessionGboReservationExists(state),
});

const mapDispatchToProps = {
  getRentalTermsAndConditions,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(RentalTermsAndConditionsModal));
