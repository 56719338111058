import { MapTo } from '@adobe/aem-react-editable-components';
import BookingWidget from './BookingWidgetContainer';

/* This component exists purely to map to AEM. It is exactly the same, from a
 * react standpoint, as the normal Booking Widget. AEM has a branch-bookingwidget
 * so this mapping must be created to correctly map the model.json props
 * to this component. Basically aligning the mapping with the data-cq-type
 */

const BookingWidgetEditableConfig = {
  emptyLabel: 'Booking Widget',
  isEmpty: props => !props || !props.title,
};

export default MapTo('aweb/components/content/vehicles/vehicle-bookingwidget')(
  BookingWidget,
  BookingWidgetEditableConfig
);
