import { connect } from 'react-redux';
import { logout } from 'actions/authentication';
import ResflowLogoutModal from './ResflowLogoutModal';

const mapDispatchToProps = {
  logout,
};

export default connect(
  null,
  mapDispatchToProps
)(ResflowLogoutModal);
