import { RESERVATIONS, MODAL } from 'constants';
import TotalPriceFormatted from 'components/RentalDetails/RentalDetailsSharedComponents/TotalPriceFormatted';
import ProgressBarLocation from './ProgressBar/ProgressBarLocation';
import ProgressBarDatesTimes from './ProgressBar/ProgressBarDatesTimes';
import ProgressBarVehicle from './ProgressBar/ProgressBarVehicle';
import ProgressBarTotal from './ProgressBar/ProgressBarTotal';
import ProgressBarDatesTimesDrawer from './ProgressBar/ProgressBarDatesTimesDrawer';
import ProgressBarLocationDrawer from './ProgressBar/ProgressBarLocationDrawer';
import ProgressBarVehicleDrawer from './ProgressBar/ProgressBarVehicleDrawer';
import { ProgressBarExtras, ProgressBarExtrasMobile } from './ProgressBar/ProgressBarExtras';
import ProgressBarExtrasDrawer from './ProgressBar/ProgressBarExtrasDrawer';

const { RESFLOW_PATHS_CONFIG } = RESERVATIONS;
const GMI_SESSION_GBO_RESERVATION_CURSOR = ['gmi', 'session', 'gbo', 'reservation'];
const GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR = ['gmi', 'session', 'gma', 'initiate_request'];

// -- available properties --
// id: unique static ID,
// paths: routes that will match in ReactRouter and mount the corresponding view component,
// progressBarLabel: i18n key,
// ProgressBarLabelComponent: this component only represents the collapsed tab portion,
// ProgressBarExpandedMobileComponent: overrides Label component if mobile tabs have to be unique,
// ProgressBarDrawerComponent: this component represents the expanded, dropdown drawer portion
// hiddenBeforeAllowed: if `true`, will not show until its path appears in "allowed_res_screens"
// mobileEditIsLink: determines if the Edit button on mobile tabs is a link to paths[0] value
// dataCursors: is any of these redux cursors return data, we show the label component, if all return data, we show completed

// this is the order that tabs shall appear in the progress bar
export const PROGRESS_BAR_CONFIG = [
  {
    id: 'datesTimes',
    paths: RESFLOW_PATHS_CONFIG.datesTimes,
    disabledOnPaths: RESFLOW_PATHS_CONFIG.start,
    dataCursors: [
      [...GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR, 'pickup_time'],
      [...GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR, 'return_time'],
    ],
    progressBarLabel: 'progress_bar_datestimes_title',
    ProgressBarLabelComponent: ProgressBarDatesTimes,
    ProgressBarDrawerComponent: ProgressBarDatesTimesDrawer,
  },
  {
    id: 'location',
    paths: RESFLOW_PATHS_CONFIG.location,
    disabledOnPaths: RESFLOW_PATHS_CONFIG.start,
    enabledOnOwnPaths: true,
    dataCursors: [
      [...GMI_SESSION_GBO_RESERVATION_CURSOR, 'pickup_location'],
      [...GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR, 'pickup_location'],
      [...GMI_SESSION_GBO_RESERVATION_CURSOR, 'return_location'],
      [...GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR, 'return_location'],
    ],
    progressBarLabel: 'progress_bar_location_title',
    ProgressBarLabelComponent: ProgressBarLocation,
    ProgressBarDrawerComponent: ProgressBarLocationDrawer,
  },
  {
    id: 'vehicle',
    paths: RESFLOW_PATHS_CONFIG.vehicle,
    dataCursors: [[...GMI_SESSION_GBO_RESERVATION_CURSOR, 'car_class_details']],
    progressBarLabel: 'progress_bar_vehicle_title',
    ProgressBarLabelComponent: ProgressBarVehicle,
    ProgressBarDrawerComponent: ProgressBarVehicleDrawer,
    mobileEditOpensModal: {
      modalKey: MODAL.EDIT_VEHICLE,
      props: {
        linkTo: RESFLOW_PATHS_CONFIG.vehicle[0],
      },
    },
  },
  {
    id: 'extras',
    paths: RESFLOW_PATHS_CONFIG.extras,
    dataCursors: [[...GMI_SESSION_GBO_RESERVATION_CURSOR, 'car_class_details']],
    progressBarLabel: 'progress_bar_extras_title',
    ProgressBarLabelComponent: ProgressBarExtras,
    ProgressBarDrawerComponent: ProgressBarExtrasDrawer,
    ProgressBarExpandedMobileComponent: ProgressBarExtrasMobile,
    mobileEditIsLink: true,
  },
  {
    id: 'total',
    paths: RESFLOW_PATHS_CONFIG.total,
    dataCursors: [[...GMI_SESSION_GBO_RESERVATION_CURSOR, 'car_class_details', 'vehicle_rates']],
    progressBarLabel: 'progress_bar_total_title',
    ProgressBarLabelComponent: TotalPriceFormatted,
    ProgressBarDrawerComponent: ProgressBarTotal,
    hiddenBeforeAllowed: true,
    isNeverCompleted: true,
  },
];

export const MOBILE_PROGRESS_BAR_CONFIG = [
  {
    id: 'datesTimes',
    paths: RESFLOW_PATHS_CONFIG.datesTimes,
    disabledOnPaths: RESFLOW_PATHS_CONFIG.start,
    dataCursors: [
      [...GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR, 'pickup_time'],
      [...GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR, 'return_time'],
    ],
    progressBarLabel: 'progress_bar_datestimes_title',
    ProgressBarLabelComponent: ProgressBarDatesTimes,
    ProgressBarDrawerComponent: ProgressBarDatesTimesDrawer,
  },
  {
    id: 'location',
    paths: RESFLOW_PATHS_CONFIG.location,
    disabledOnPaths: RESFLOW_PATHS_CONFIG.start,
    enabledOnOwnPaths: true,
    dataCursors: [
      [...GMI_SESSION_GBO_RESERVATION_CURSOR, 'pickup_location'],
      [...GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR, 'pickup_location'],
      [...GMI_SESSION_GBO_RESERVATION_CURSOR, 'return_location'],
      [...GMI_SESSION_GMA_INITIATE_REQUEST_CURSOR, 'return_location'],
    ],
    progressBarLabel: 'progress_bar_location_title',
    ProgressBarLabelComponent: ProgressBarLocation,
    ProgressBarDrawerComponent: ProgressBarLocationDrawer,
  },
  {
    id: 'vehicle',
    paths: RESFLOW_PATHS_CONFIG.vehicle,
    dataCursors: [[...GMI_SESSION_GBO_RESERVATION_CURSOR, 'car_class_details']],
    progressBarLabel: 'progress_bar_vehicle_title',
    ProgressBarLabelComponent: ProgressBarVehicle,
    ProgressBarDrawerComponent: ProgressBarVehicleDrawer,
    mobileEditOpensModal: {
      modalKey: MODAL.EDIT_VEHICLE,
      props: {
        linkTo: RESFLOW_PATHS_CONFIG.vehicle[0],
      },
    },
  },
  {
    id: 'extras',
    paths: RESFLOW_PATHS_CONFIG.extras,
    dataCursors: [[...GMI_SESSION_GBO_RESERVATION_CURSOR, 'car_class_details']],
    progressBarLabel: 'progress_bar_extras_title',
    ProgressBarLabelComponent: ProgressBarExtras,
    ProgressBarDrawerComponent: ProgressBarExtrasDrawer,
    ProgressBarExpandedMobileComponent: ProgressBarExtrasMobile,
    mobileEditIsLink: true,
  },
  {
    id: 'review',
    paths: RESFLOW_PATHS_CONFIG.review,
    dataCursors: [[...GMI_SESSION_GBO_RESERVATION_CURSOR, 'car_class_details', 'vehicle_rates']],
    progressBarLabel: 'review_page_title',
    ProgressBarLabelComponent: TotalPriceFormatted,
    ProgressBarDrawerComponent: ProgressBarTotal,
    hiddenBeforeAllowed: false,
    isNeverCompleted: true,
  },
];
