import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import TOOLTIP_THEMES from 'constants/tooltipThemes';
import ToolTip from 'components/ToolTip';

/**
 * STCFormCard
 * Reusable STC Card for forms with Title, optional tooltip and complete indicator.
 *
 * @param {object} props
 * @param {string} props.title - Card title
 * @param {string} props.tooltipText - Text for tooltip in the header. If unavailable, no tooltip will be rendered
 * @param {boolean} props.isComplete - Flag to show/hide "Complete" indicator in the header
 * @param {array|node} props.children - SX Components passed onto this component to be rendered within it
 *
 * @return {JSX}
 */
const STCFormCard = ({ title, tooltipText, isComplete, children }) => (
  <div className='skip-the-counter-template__content component-theme--light'>
    <div className='skip-the-counter-flow__section-header skip-the-counter-flow__section-header--justified'>
      <h3 className='skip-the-counter-flow__section-title'>
        {title}
        {tooltipText && (
          <ToolTip theme={TOOLTIP_THEMES.REGULAR_SLATE}>
            <label>{tooltipText}</label>
          </ToolTip>
        )}
      </h3>
      {isComplete && (
        <span className='skip-the-counter-flow__section-complete-indicator'>
          {utils.i18n('review_section_card_complete_indicator')}
        </span>
      )}
    </div>

    {children}
  </div>
);

STCFormCard.propTypes = {
  title: PropTypes.string,
  tooltipText: PropTypes.string,
  isComplete: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default STCFormCard;
