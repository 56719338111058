import PropTypes from 'prop-types';

export const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isOtherVehicle: PropTypes.bool,
};

export const defaultProps = {
  isOtherVehicle: false,
};
