import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import FieldControl from 'components/Form/FieldControl';

/**
 * TextField Description:
 *
 * @param {object} props - React Props
 * @param {string} props.name: unique field name
 * @param {string} props.id - unique field id
 * @param {string} props.label - a descriptive label for the user to read
 * @param {boolean} props.full - will make the field fill the width of its container
 * @param {boolean} props.error - Show Error State
 * @param {boolean} props.attention - Show Attention State
 * @param {Array | boolean} props.validations
 * @param {string | boolean} props.initialValueButton
 * @param {function} props.initialValueButtonHandler - Handler for remove the initial value button
 * @param {string} props.inputType - Input type passed into FieldControl (i.e. 'text', 'number', 'tel')
 * @return {JSX} ...
 */
const TextField = ({ inputType, ...props }) => {
  const uuid = utils.dom.uuid();
  let autoCompleteValue;
  if (props.initialValueButton) {
    autoCompleteValue = `new-${props.name}`;
  } else if (props.autoComplete) {
    autoCompleteValue = props.autoComplete;
  } else {
    autoCompleteValue = 'on';
  }

  return (
    <FieldControl {...props} id={props.id || uuid} type={inputType} autoComplete={autoCompleteValue}>
      <input />
    </FieldControl>
  );
};

TextField.defaultProps = {
  inputType: 'text',
};

TextField.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  full: PropTypes.bool,
  fill_50: PropTypes.bool,
  error: PropTypes.bool,
  attention: PropTypes.bool,
  validations: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  initialValueButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  initialValueButtonHandler: PropTypes.func,
  inputType: PropTypes.string,
};

export default TextField;
