import { actions } from '@ehi/global-marketing-interface';
import { connect } from 'react-redux';
import { MODAL_PATH } from 'reducers/modal';
import utils from 'utils';
import { breakpointSelector, sessionReservationObjectSelector, sessionInitiateRequest } from 'selectors';
import {
  reservationCouponsSelector,
  getProgressBarExpandedTab,
  getProgressBarMobileExpanded,
  getProgressBarTotalDropDownExpandedTab,
} from 'selectors/reservationFlowSelectors';
import { getReservationPickupLocation, getReservationReturnLocation } from 'selectors/reservationSelectors';
import {
  getInitialPickupLocation,
  getInitialReturnLocation,
  getProgressBarLocationInitiateSelector,
} from 'selectors/progressBarSelectors';
import { getAppLastValidSearchedLocation, getAdjustLocationId } from 'selectors/appSelectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { setExpandedTab, setMobileExpanded, setTotalDropdownMobileExpanded } from 'actions/progressBar';
import { setAdjustLocationId } from 'actions/locationFinder';
import { clearVehiclesFilterData, setSelectedCurrencyToggleData } from 'actions/vehicleClass';
import { getVehiclesList } from 'actions/vehicles';
import { initiateReservationFlow } from "actions/reservation/initiateReservationFlow";
import ProgressBar from './ProgressBar';

const mapStateToProps = (state) => ({
  breakpoint: breakpointSelector(state),
  gmiReservationReady: !!sessionReservationObjectSelector(state),
  haveInitiateRequest: !!sessionInitiateRequest(state),
  openModals: state.getIn(MODAL_PATH.MODAL_QUEUE),
  coupons: reservationCouponsSelector(state),
  expandedTab: getProgressBarExpandedTab(state),
  mobileExpanded: getProgressBarMobileExpanded(state),
  gmaPickupLocation: getInitialPickupLocation(state),
  gmaReturnLocation: getInitialReturnLocation(state),
  gboPickupLocation: getReservationPickupLocation(state),
  gboReturnLocation: getReservationReturnLocation(state),
  isFetchingSession: isFetchingEndpointSelector(state, { endpoint: 'reservations/initiate' }),
  lastValidSearchedLocationId: getAppLastValidSearchedLocation(state),
  adjustLocationId: getAdjustLocationId(state),
  expandedTotalDropdown: getProgressBarTotalDropDownExpandedTab(state),
  didProgressBarLocationInitiate: getProgressBarLocationInitiateSelector(state),
});

const mapDispatchToProps = {
  setLocation: (location, exchangeType) =>
    actions.reservations.initiateState.set('location', { location, exchangeType }),
  dispatchExpandedTab: (id) => setExpandedTab(id),
  dispatchMobileExpanded: (expanded) => setMobileExpanded(expanded),
  initiateReservation: () => initiateReservationFlow({ saveLatestSelections: true, preserveBundleProfile: true }),
  dispatchSetAdjustLocation: (id) => setAdjustLocationId(id),
  clearVehiclesFilterData,
  getVehiclesList,
  setSelectedCurrencyToggleData,
  dispatchTotalDropdownExpandedTab: (id) => setTotalDropdownMobileExpanded(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ProgressBar));
