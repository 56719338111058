import React from 'react';
import usePersistedState from 'hooks/usePersistedState';
import useVehicleUpgradeDetails from 'hooks/useVehicleUpgradeDetails';
import useActions from 'hooks/useActions';
import Picture from 'components/Picture';
import Button from 'components/Button';
import LoadingWidget from 'components/LoadingWidget';
import { upgradeCarClass } from "actions/reservation/upgradeCarClass";
import UpgradeCta from './UpgradeCta';
import UpgradeTotalCopy from './UpgradeTotalCopy';
import UpgradeDescription from './UpgradeDescription';

/**
 * InPathVehicleUpgradeProgressBar - Progress bar vehicle upgrade banner.
 *
 * @return {JSX}  InPathVehicleUpgradeProgressBar component
 */
const InPathVehicleUpgradeProgressBar = () => {
  // hidden state has to be persisted through remount, as the parent ProgressBar drawer can be toggled so regular
  // state would reset if user closed and re-opened the drawer.
  const [hidden, setHidden] = usePersistedState('InPathVehicleUpgradeProgressBar_hidden', false);

  const { inPathUpgradeAvailable, inPathUpgradeDetails, isFechingRates } = useVehicleUpgradeDetails();
  const upgradeHandler = useActions(upgradeCarClass);

  const { upgradeName, upgradeMakeModelOrSimilarText, priceDifference, total, upgradeImagePath, upgradeClass } =
    inPathUpgradeDetails || {};

  return inPathUpgradeAvailable && !hidden ? (
    <>
      {isFechingRates && (
        <div className='in-path-upgrade in-path-upgrade-progress-bar in-path-upgrade__arrow'>
          <LoadingWidget />
        </div>
      )}
      {inPathUpgradeDetails && (
        <div className='in-path-upgrade in-path-upgrade-progress-bar in-path-upgrade__arrow'>
          <Picture
            src={upgradeImagePath}
            alt={upgradeMakeModelOrSimilarText}
            pictureClassName='in-path-upgrade__picture'
          />
          <UpgradeDescription
            name={upgradeName}
            makeModelOrSimilarText={upgradeMakeModelOrSimilarText}
            priceDifference={priceDifference}
          />
          <div className='in-path-upgrade__upgrade-cta'>
            <UpgradeCta upgradeHandler={() => upgradeHandler(upgradeClass)} page='review' upgradeClass={upgradeClass} />
            <UpgradeTotalCopy total={total} />
          </div>

          <Button className='in-path-upgrade__close-button' button={false} onClick={() => setHidden(true)} />
        </div>
      )}
    </>
  ) : null;
};

export default InPathVehicleUpgradeProgressBar;
