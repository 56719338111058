import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS, MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES, THEMES } from 'constants';
import Form from 'components/Form/Form';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import Checkbox from 'components/Form/Checkbox';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * PRPAuthAddProgramModal
 *
 * @param {object} props - React Props
 * @param {strin} props.partner_rewards_program_name - Booking Widget selected PRP complete name
 * @param {function} props.handleInitiat - Function to initiate the reservation
 * @param {function} props.setUpdateProfileWithPartnerRewards - Function to set a flag to update the profile with the selected program from the Booking Widget
 * @param {function} props.handleClose - Default modal close function
 * @return {JSX} PRPAuthAddProgramModal JXS component
 */
class PRPAuthAddProgramModal extends Component {
  state = {
    addProgramToProfile: false,
  };

  handleSubmit = handleClose => () => {
    const { handleInitiate, setUpdateProfileWithPartnerRewards } = this.props;
    const { addProgramToProfile } = this.state;
    if (addProgramToProfile) {
      setUpdateProfileWithPartnerRewards(addProgramToProfile);
    }
    // This modal should be closed regardless of whether initiate succeeds (in which case a redirect happens)
    // or fails (in which case it closes to display the error above the BW).
    handleInitiate().then(() => handleClose({ analyticsValue: utils.i18n('prp_auth_add_program_accept') }));
  };

  handleCheckbox = () => {
    const { addProgramToProfile } = this.state;
    this.setState({ addProgramToProfile: !addProgramToProfile });
  };

  // As ReactFinalForm needs a onSubmit prop, we load it with this dummy submit
  dummySubmit = () => false;

  render() {
    const { partner_rewards_program_name } = this.props;
    const { addProgramToProfile } = this.state;

    return (
      <Modal
        modalKey={MODAL.PRP_AUTH_ADD_PROGRAM_MODAL}
        header={utils.i18n('prp_auth_add_program_title', [partner_rewards_program_name])}
        theme={MODAL_THEMES.WHITE}>
        {({ handleClose }) => (
          <>
            <p className='partner-rewards-modal-content__copy'>{utils.i18n('prp_auth_add_program_copy')}</p>

            <Form onSubmit={this.dummySubmit}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className='partner-rewards-modal-content__form'>
                  <Checkbox
                    checked={addProgramToProfile}
                    label={utils.i18n('prp_auth_add_program_checkbox_label', [partner_rewards_program_name])}
                    theme={THEMES.LIGHT}
                    name='addProgramToProfile'
                    onChange={this.handleCheckbox}
                  />
                </form>
              )}
            </Form>

            <ModalFooter
              buttons={[
                {
                  type: DECLINE,
                  label: utils.i18n('prp_auth_add_program_cancel'),
                  analyticsValue: ANALYTICS.CANCEL,
                },
                {
                  type: ACCEPT,
                  label: utils.i18n('prp_auth_add_program_accept'),
                  handler: this.handleSubmit(handleClose),
                },
              ]}
            />
          </>
        )}
      </Modal>
    );
  }
}

PRPAuthAddProgramModal.propTypes = {
  partner_rewards_program_name: PropTypes.string,
  handleInitiate: PropTypes.func,
  setUpdateProfileWithPartnerRewards: PropTypes.func,
  handleClose: PropTypes.func,
};

export default PRPAuthAddProgramModal;
