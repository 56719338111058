import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES } from 'constants/modals';

/**
 * CurrencyConversionModal - View Currency Conversion Details
 * @param {object} props - React Props
 * @return {JSX}
 */
const CurrencyConversionModal = ({ closeCurrencyConversionModal }) => {
  const isNADomain = utils.config.isNADomain();
  // update currency conversion modal data based on domain
  const currencyModalContent = isNADomain
    ? utils.i18n('currency_conversion_copy_na')
    : utils.i18n('currency_conversion_copy_eu');

  const handleGoBackOnClose = () => {
    if (closeCurrencyConversionModal)
      setTimeout(() => {
        closeCurrencyConversionModal();
      }, 0);
  };
  return (
    <Modal
      modalKey={MODAL.CURRENCY_CONVERSION_DETAILS_MODAL}
      theme={MODAL_THEMES.WHITE}
      customActionOnClose={handleGoBackOnClose}
      header={utils.i18n('vehicle_select_currency_conversion_details')}>
      {() => <p className='modal-themed__copy'>{currencyModalContent}</p>}
    </Modal>
  );
};

export default CurrencyConversionModal;

CurrencyConversionModal.propTypes = {
  closeCurrencyConversionModal: PropTypes.func,
};
