import { connect } from 'react-redux';
import MODAL from 'constants/modals';
import { breakpointSelector } from 'selectors/breakpoint';
import { openModal } from "actions/modal/openModal";
import LookUpByUI from './LookUpByUI';

const mapStateToProps = (state) => ({
  breakpoint: breakpointSelector(state),
});

const mapDispatchToProps = {
  showNoCurrentRentalModal: () => openModal(MODAL.NO_CURRENT_RENTAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(LookUpByUI);
