import React, { useMemo, useState } from 'react';
import utils from 'utils';
import DiscardReservationModal from 'components/ReservationFlow/SharedModals/DiscardReservationModal';
import ProgressBarModifyReturn from '../ProgressBar';
import ModifyReturnHeader from './ModifyReturnHeader/ModifyReturnHeader';
import ModifyReturnWidget from './ModifyReturnWidget/index';

const ModifyReturn = (props) => {
  const { returnLocation, returnDateAndTime, lastReturnDate } = props;
  const [rentalError, setRentalError] = useState();

  const dateTimeFormat = utils.config.getDateTimeShortFormat('lll_no_year');
  const returnDate = utils.gmi.getDateTimeObjFromTs(returnDateAndTime).format(dateTimeFormat);

  const onRentalError = (error) => {
    setRentalError(error);
  };

  const memoizedModifyReturnWidget = useMemo(() => <ModifyReturnWidget onRentalError={onRentalError} />, []);

  const steps = [
    {
      title: utils.i18n('return_date_time'),
      value: returnDate,
      active: true,
    },
    {
      title: utils.i18n('charges_payment'),
      value: '',
      active: false,
    },
    {
      title: utils.i18n('return_updated'),
      active: false,
    },
  ];

  return (
    <div>
      <ProgressBarModifyReturn steps={steps} />
      <ModifyReturnHeader returnLocation={returnLocation} rentalError={rentalError} />
      {lastReturnDate && memoizedModifyReturnWidget}
      <DiscardReservationModal />
    </div>
  );
};

export default ModifyReturn;
