import React from 'react';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';
import Anchor from 'components/Anchor';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import utils from 'utils';

const ModifyReturnHeader = (props) => {
  const supportNumber = useSupportPhoneNumber();
  const { returnLocation, rentalError } = props;
  const { name, station_id, address } = returnLocation || {};
  return (
    <div className='theme--dark-blue modify-return-header'>
      <div className='row'>
        <div>
          {rentalError &&
            rentalError.length > 0 &&
            rentalError.map((errorMessage, index) => (
              <ErrorBanner key={`error_banner_${index}`} errorDescription={errorMessage?.message} />
            ))}
          <h1 className='modify-return-header__title'>{utils.i18n('modify_return')}</h1>
          {returnLocation && (
            <>
              <p className='modify-return-header__subtitle'>{utils.i18n('bw_return_location_label')}</p>
              <p className='modify-return-header__airport-name'>
                {name} ({station_id})
              </p>
              <p className='modify-return-header__address'>
                {address?.street_addresses[0]}
                <br />
                {address?.city}, {address?.country_subdivision_code} {address?.postal}
              </p>
              <p className='modify-return-header__help'>
                {utils.i18n('need_to_change_location')}{' '}
                <Anchor className='link' href={`tel: ${supportNumber}`}>
                  {supportNumber}
                </Anchor>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModifyReturnHeader;
