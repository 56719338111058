import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import TOOLTIP_THEMES from 'constants/tooltipThemes';
import {
  unpaidRefundReservationPriceDifferenceSelector,
  isTourVoucherReservationSelector,
  voucherExcludedSelector,
} from 'selectors/reservationSelectors';
import { estimatedTotalViewSelector } from 'selectors/reservationFlowSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import ToolTip from 'components/ToolTip';
import STCPaymentCardInfo from './STCPaymentCardInfo';

/**
 * STCPaymentInformationNAPrepay
 *
 * @param {object} props - React Props
 *
 * @return {JSX}
 */
const STCPaymentInformationNAPrepay = () => {
  const isTourVoucherReservation = useSelector(isTourVoucherReservationSelector);
  const voucherExcludedValue = useSelector(voucherExcludedSelector);
  const estimatedTotalView = useSelector(estimatedTotalViewSelector);
  const priceDifference = useSelector(unpaidRefundReservationPriceDifferenceSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const { difference_amount_view } = priceDifference;
  const isRefund = utils.isPriceDifferenceRefund(difference_amount_view);

  return (
    <>
      <STCPaymentCardInfo />

      <dl className='skip-the-counter-flow-payment-list'>
        <dt className='skip-the-counter-flow-payment-list__item-title skip-the-counter-flow-payment-list__item-title--header'>
          {isCustomPathReservation
            ? `${utils.i18n('stc_payment_info_trip_total_custom_path')}:`
            : `${utils.i18n('stc_payment_information_trip_total')}:`}
        </dt>
        <dd className='skip-the-counter-flow-payment-list__item-data skip-the-counter-flow-payment-list__item-data--header skip-the-counter-flow-payment-list__price-details'>
          {isTourVoucherReservation
            ? utils.getFormattedPrice(voucherExcludedValue.view)
            : utils.getFormattedPrice(estimatedTotalView)}
        </dd>
        {difference_amount_view && (
          <>
            <dt className='skip-the-counter-flow-payment-list__item-title skip-the-counter-flow-payment-list__item-title--bold'>
              {utils.i18n('stc_payment_price_changes_from_updates')}:
              {!isCustomPathReservation && (
                <ToolTip theme={TOOLTIP_THEMES.REGULAR_SLATE}>
                  <label>{utils.i18n('stc_payment_information_due_time_of_rental_tooltip')}</label>
                </ToolTip>
              )}
              <span className='skip-the-counter-flow-payment-list__item-detail'>
                ({utils.i18n(isRefund ? 'addr_refund_updates_copy' : 'addr_payment_unpaid_due_copy')})
              </span>
            </dt>
            <dd className='skip-the-counter-flow-payment-list__item-data skip-the-counter-flow-payment-list__item-data--bold'>
              {utils.getFormattedPrice(difference_amount_view)}
            </dd>
          </>
        )}
      </dl>
    </>
  );
};

export default STCPaymentInformationNAPrepay;
