import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import utils from 'utils';
import { MODAL } from 'constants';
import cn from 'classnames';
import { getModifyFlag } from 'selectors/reservationSelectors';
import Button from 'components/Button';
import { openModal } from "actions/modal/openModal";

/**
 * AddAdditionalDriverButton
 *
 * @param {object} props - React Props
 * @param {number} numDriversRemaining - Number of drivers remaining
 * @param {string} dtmTrackValue - Analytics data dtm track
 * @param {boolean} isSTCOrCustomPathFlow - STC or Custom path Flow flag
 *
 * @return {JSX}
 */
const AddAdditionalDriverButton = ({ numDriversRemaining, dtmTrackValue, isSTCOrCustomPathFlow }) => {
  const openAddDriverModal = useActions(() => openModal(MODAL.ADDITIONAL_DRIVER_ADD_MODAL));
  const openDriverDetailsModal = useActions(() => openModal(MODAL.ADDITIONAL_DRIVER_DETAILS_MODAL));

  const isTrueModify = useSelector(getModifyFlag);

  return (
    <Button
      link
      className={cn('add-button additional-drivers__add-driver', {
        'call-out': numDriversRemaining,
      })}
      onClick={isTrueModify && isSTCOrCustomPathFlow ? openDriverDetailsModal : openAddDriverModal}
      data-dtm-track={dtmTrackValue}>
      <span>
        {utils.i18n(
          numDriversRemaining ? 'review_additional_drivers_add_included_driver' : 'review_additional_drivers_add_driver'
        )}
      </span>
      {!!numDriversRemaining && (
        <span className='additional-drivers__drivers-remaining'>
          {utils.pluralHandler(
            numDriversRemaining,
            'review_additional_drivers_num_included_drivers_available_singular',
            'review_additional_drivers_num_included_drivers_available_plural',
            false,
            numDriversRemaining
          )}
        </span>
      )}
    </Button>
  );
};

AddAdditionalDriverButton.propTypes = {
  numDriversRemaining: PropTypes.number,
  dtmTrackValue: PropTypes.string,
  isSTCOrCustomPathFlow: PropTypes.bool,
};

AddAdditionalDriverButton.defaultProps = {
  numDriversRemaining: 0,
  isSTCOrCustomPathFlow: false,
};

export default AddAdditionalDriverButton;
