import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { branchLocalAddendaSelector } from 'selectors/skipTheCounterSelectors';
import MODAL from 'constants/modals';
import Button from 'components/Button';
import RentalTermsAndConditionsModal from 'components/modals/RentalTermsAndConditionsModal';
import STCLocalAddendumModal from 'components/modals/STCLocalAddendumModal';
import { openModal } from "actions/modal/openModal";
/**
 * TermsAndPolicies - Terms and policies links
 * @param {object} props - React Props
 * @param {function} props.scrollToLocationTermsAndConditions - action to scroll down to next section (Location Terms and Conditions)
 * @return {JSX}
 */
const TermsAndPolicies = ({ scrollToLocationTermsAndConditions }) => {
  const openTermsAndConditionsModal = useActions(() => openModal(MODAL.KEY_RENTAL_FACTS_TERMS_AND_CONDITIONS));
  const openLocalAddendumModal = useActions(() => openModal(MODAL.LOCAL_ADDENDUM_MODAL));
  const branchLocalAddenda = useSelector(branchLocalAddendaSelector);

  return (
    <div className='skip-the-counter-confirmation__terms-policies'>
      <i className='skip-the-counter-confirmation__terms-policies-icon' />
      <div>
        <span className='skip-the-counter-confirmation__terms-policies-title'>
          {utils.i18n('stc_terms_policies_title')}
        </span>
        <ul className='skip-the-counter-confirmation__terms-policies-list'>
          <li>
            <Button link onClick={openTermsAndConditionsModal} className='skip-the-counter-confirmation__link'>
              {utils.i18n('stc_view_additional_terms')}
            </Button>
          </li>
          {!!branchLocalAddenda?.length && (
            <li>
              <Button link onClick={openLocalAddendumModal} className='skip-the-counter-confirmation__link'>
                {utils.i18n('stc_view_local_addendum')}
              </Button>
            </li>
          )}
          <li>
            <Button link onClick={scrollToLocationTermsAndConditions} className='skip-the-counter-confirmation__link'>
              {utils.i18n('helpful_tips_pickup_rental_policy')}
            </Button>
          </li>
        </ul>
      </div>

      <RentalTermsAndConditionsModal />
      <STCLocalAddendumModal />
    </div>
  );
};

TermsAndPolicies.propTypes = {
  scrollToLocationTermsAndConditions: PropTypes.func,
};

export default TermsAndPolicies;
