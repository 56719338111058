import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';

export const propTypes = {
  /**
   * Drawer title
   *
   * @type {String}
   */
  title: PropTypes.string,
  /**
   * Drawer title icon
   *
   * @type {String}
   */
  titleIcon: PropTypes.string,

  /**
   * Drawer title suffix
   *
   * @type {String}
   */
  titleSuffix: PropTypes.string,

  /**
   * Drawer open CTA
   *
   * @type {String}
   */
  openText: PropTypes.string,

  /**
   * Drawer close CTA
   *
   * @type {String}
   */
  closeText: PropTypes.string,
};

export const defaultProps = setDefaultProps();
