import React from 'react';
import utils from 'utils';
import { WINDOW_OBJECT_KEYS } from 'constants';
import GoogleMapStyles from './GoogleMapStyles';
import { defaultProps, propTypes } from './GoogleMapHOCPropTypes';

/**
 * Google Maps Wrapper for react components
 */
const GoogleMapsHOC = WrappedComponent => {
  class GoogleMapsWrapper extends React.Component {
    static propTypes = propTypes;

    static defaultProps = defaultProps;

    constructor(props) {
      super(props);

      const { zoom, minZoom, maxZoom, center } = props;

      this.state = {
        map: null,
        isCenter: false,
        currentPosition: null,
        mapOptions: {
          center,
          zoom,
          minZoom,
          maxZoom,
          disableDefaultUI: true,
          zoomControl: true,
          styles: GoogleMapStyles,
        },
      };

      this.mapRef = React.createRef();
      this.markers = [];
      this.googleMaps = null;
    }

    componentDidMount() {
      if (window.googleMapsHasLoaded) {
        this.initGoogleMap();
      } else {
        if (!document.getElementById('googleMapsScript')) {
          // TODO: maps url should be in configuration object
          const googleMapsApiUrl = `//maps.googleapis.com/maps/api/js?key=${utils.config.getUrl(
            WINDOW_OBJECT_KEYS.GOOGLE_MAPS_API_KEY
          )}&libraries=geometry&callback=googleMapsLoaded`;

          const script = document.createElement('script');
          script.src = googleMapsApiUrl;
          script.setAttribute('id', 'googleMapsScript');
          document.getElementsByTagName('head')[0].appendChild(script);
        }

        window.googleMapsLoaded = () => {
          window.googleMapsHasLoaded = true;
          this.initGoogleMap();
        };
      }
    }

    componentWillUnmount() {
      window.document.removeEventListener('googleMapsLoaded', this.initGoogleMap);
    }

    initGoogleMap = () => {
      const { maps } = window.google;
      this.googleMaps = maps;
      const map = new this.googleMaps.Map(this.mapRef.current, { ...this.state.mapOptions });

      this.setState({ map });
    };

    render() {
      const { mapContainerClassName } = this.props;

      return (
        <WrappedComponent {...this.props} map={this.state.map} googleMaps={this.googleMaps}>
          <div ref={this.mapRef} className={mapContainerClassName} />
        </WrappedComponent>
      );
    }
  }
  return GoogleMapsWrapper;
};

export default GoogleMapsHOC;
