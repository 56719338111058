import React from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { getShowFRBannerCommit } from 'selectors/rentalTicketSelectors';
import MODAL from 'constants/modals';
import { openModal } from "actions/modal/openModal";
import CanadaFrenchRedirectionBanner from './CanadaFrenchRedirectionBanner';

const CanadaFrenchRedirectionBannerContainer = (props) => {
  const openModalAction = useActions(() => openModal(MODAL.CANADA_FRENCH_REDIRECTION_MODAL));
  const showCAfrBannerCommit = useSelector(getShowFRBannerCommit);

  const combinedProps = {
    showCAfrBannerCommit,
    openModalAction,
    ...props,
  };

  return <CanadaFrenchRedirectionBanner {...combinedProps} />;
};

export default CanadaFrenchRedirectionBannerContainer;
