import React from 'react';
import PropTypes from 'prop-types';
import FormattedPrice from 'components/FormattedPrice';

/**
 * Line item of a Time & Distance info shown on the Vehicle section of the Review Screen
 * @param {object} props        - React Props
 * @param {node} props.label    - Label shown on the left side of the line item
 * @param {string} props.price  - Price value shown on the right side of the line item
 * @return {JSX} TimeAndDistanceLineItem component
 */
const TimeAndDistanceLineItem = ({ label, total }) => (
  <div className='rental-details__time-distance-daily'>
    <p className='rental-details__time-distance-daily__rate'>{label}</p>
    <FormattedPrice classNames='rental-details__time-distance-daily__amount'>{total}</FormattedPrice>
  </div>
);

TimeAndDistanceLineItem.propTypes = {
  label: PropTypes.node.isRequired,
  total: PropTypes.string.isRequired,
};

export default TimeAndDistanceLineItem;
