import React from 'react';
import utils from 'utils';
import cn from 'classnames';
import PropTypes from 'prop-types';
import RESERVATIONS from 'constants/reservations';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';
/**
 * ReceiptRentalSummary - Render the rental summary details
 * @param {object} receipt - Receipt details object
 * @param {string} customerName - Customer name
 * @return {JSX}
 */
const ReceiptRentalSummary = ({ receipt, customerName }) => {
  const DATE_FORMAT = utils.config.getDateTimeLongFormat('ll');
  const TIME_FORMAT = utils.config.getDateTimeLongFormat('lt');
  const supportPhone = useSupportPhoneNumber();
  const {
    payment_details = [],
    rental_agreement_number = '',
    pickup_time,
    pickup_location,
    return_time,
    return_location,
    price_summary = {},
    netrate_total_indicator,
  } = receipt;

  const getPaymentMethodLabel = payment => {
    let label = '';
    if (payment?.masked_credit_card_number) {
      label = utils.i18n('receipt_charged_card', [
        RESERVATIONS.PAYMENT_METHOD.CARD[payment.payment_method],
        payment.masked_credit_card_number,
      ]);
    } else if (payment.payment_method === RESERVATIONS.PAYMENT_METHOD.CASH) {
      label = utils.i18n('receipt_rental_payment_cash');
    }

    return label;
  };

  const details = [
    { key: 'receipt_rental_agreement', label: rental_agreement_number },
    {
      key: 'receipt_rental_renter',
      label: customerName,
    },
    { key: 'receipt_rental_customer_support', label: supportPhone },
  ];

  const summary = [
    { label: utils.i18n('rental_details_pickup'), time: pickup_time, location: pickup_location },
    { label: utils.i18n('rental_details_return'), time: return_time, location: return_location },
  ];

  return (
    <div className='receipt__rental-summary'>
      <ul className='receipt__rental-summary__details'>
        {details.map(item => (
          <li key={item.key} className='receipt__rental-summary__details__item'>
            {utils.i18n(
              item.key,
              [
                <span key='0' className='receipt__rental-summary__details__item-info'>
                  {item.label}
                </span>,
              ],
              { jsx: true }
            )}
          </li>
        ))}
      </ul>

      <div className='receipt__rental-summary-info'>
        {summary.map(item => (
          <div key={item.time} className='receipt__rental-summary-location'>
            <span className='receipt__rental-summary-location-type'>{item.label}</span>
            <ul className='receipt__rental-summary-location-details'>
              <li className='receipt__rental-summary-location-details-item'>
                {item.location.name} {item.location?.airport_code && `(${item.location?.airport_code})`}
                <br />
                {[
                  item.location?.address?.city,
                  item.location?.address?.country_subdivision_code,
                  item.location?.address?.country_code,
                  item.location?.address?.postal,
                ]
                  .filter(string => !!string)
                  .join(', ')}
              </li>

              <li className='receipt__rental-summary-location-details-item'>
                <span>
                  <span className='receipt__rental-summary-location-details-item-date'>
                    {utils.gmi.getDateTimeObjFromTs(item.time).format(DATE_FORMAT)}
                  </span>
                  <span className='receipt__rental-summary-location-details-time'>
                    {utils.gmi.getDateTimeObjFromTs(item.time).format(TIME_FORMAT)}
                  </span>
                </span>
              </li>
            </ul>
          </div>
        ))}

        <div className='receipt__rental-summary-total'>
          <div className='receipt__rental-summary-total-item'>
            <ul className='receipt__rental-summary-total-charge-type'>
              <li
                key='receipt_final_total_label'
                className={cn(
                  'receipt__rental-summary-total-charge-type-item',
                  'receipt__rental-summary-total-charge-type-item-total',
                  {
                    'receipt__rental-summary-total-charge-type-item-total--single-charge': payment_details.length === 1,
                  }
                )}>
                {utils.i18n('receipt_final_total')}
              </li>
              <li
                key='receipt_final_total_price'
                className='receipt__rental-summary-total-charge-type-item receipt__rental-summary-total-charge-type-item-total'>
                {netrate_total_indicator ? (
                  utils.i18n('third_party_reservation_net_rate')
                ) : (
                  <>
                    {price_summary?.estimated_total_view?.format}
                    <span className='receipt__rental-summary-total-charge-type-item-total-currency'>
                      {price_summary?.currency_code}
                    </span>
                  </>
                )}

                {payment_details.length === 1 && (
                  <span className='receipt__rental-summary-total-single-charge'>
                    {getPaymentMethodLabel(payment_details[0])}
                  </span>
                )}
              </li>
            </ul>

            {payment_details.length > 1 &&
              payment_details.map(payment => (
                <ul key={payment.payment_date} className='receipt__rental-summary-total-charge-type'>
                  <li className='receipt__rental-summary-total-charge-type-item'>{getPaymentMethodLabel(payment)}</li>
                  <li className='receipt__rental-summary-total-charge-type-item'>{payment?.charged_amount?.format}</li>
                </ul>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ReceiptRentalSummary.propTypes = {
  receipt: PropTypes.object,
  supportPhone: PropTypes.string,
  customerName: PropTypes.string,
};

export default ReceiptRentalSummary;
