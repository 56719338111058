import React, { Component } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom-v5-compat';
import utils from 'utils';
import { LOCATIONS, RESERVATIONS, MODAL, MODAL_THEMES, WINDOW_OBJECT_KEYS, SESSION_STORAGE } from 'constants';
import LocationFinder from 'components/LocationFinder';
import Modal from 'components/Modal';
import TaxesModalContent from 'components/TaxesModalContent';
import GuaranteedResModal from 'components/modals/GuaranteedReservationModal';
import RemoveCouponModal from 'components/modals/RemoveCouponModal';
import RemoveProductCodeModal from 'components/modals/RemoveProductCodeModal';
import ConfirmCancelReservationModal from 'components/modals/ConfirmCancelReservationModal';
import EligibilityLossModal from 'components/modals/EligibilityLossModal';
import CouponDoesNotApplyModal from 'components/modals/CouponDoesNotApplyModal/CouponDoesNotApplyModal';
import Header from 'components/Header';
import SessionTimeoutModal from 'components/modals/SessionTimeoutModal';
import CurrencyConversionModal from 'components/modals/CurrencyConversionModal';
import CondensedFooter from 'components/CondensedFooter';
import cn from 'classnames';
import RemoveCIDModalContent from './SharedModals/RemoveCIDModalContent';
import VehicleSelect from './VehicleSelect';
import Extras from './Extras';
import Review from './Review';
import ReservationFlowBookingWidget from './ReservationFlowBookingWidget';
import { propTypes, defaultProps } from './reservationFlowPropTypes';
import ProgressBar from './ProgressBar';
import EditVehicleModalContent from './SharedModals/EditVehicleModalContent';
import DiscardReservationModal from './SharedModals/DiscardReservationModal';
import ModifyResBanner from './ModifyResBanner';
import RemovePartnerRewardsModalContent from './SharedModals/RemovePartnerRewardsModalContent';
import { withRouter } from '../../utils/hoc';

const { RESFLOW_PATHS_CONFIG } = RESERVATIONS;

/**
  This is a ReservationFlow component
*/
//  ToDO: move state outside of Rez flow - to be handled with ticket putting modal state
//  in redux
const defaultState = {
  initialPickupLocation: null,
  showReturnLocation: false,
  locationName: null,
  locationId: null,
};
class ReservationFlow extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  // keep state separated by res flow section: location, Vehicle, etc.
  state = defaultState;

  pageStatusText = document.getElementById('pageStatus');

  componentDidMount() {
    const {
      pickup_location,
      hydrateUiReservationsState,
      gboReservationExists,
      isTrueModify,
      setPartnerRewardProgram,
      setPartnerRewardProgramMemberId,
      initiateUIPartnerRewards,
    } = this.props;

    if (pickup_location) {
      this.initializeLocations();
      // hydrate UI from GMA if the GBO res is missing, such as when we haven't chosen a branch yet
      hydrateUiReservationsState({ initiateFromGma: !gboReservationExists || isTrueModify });
    }
    // When user logs in and we have PRP data we check users profile PRP:
    if (initiateUIPartnerRewards) {
      setPartnerRewardProgram(initiateUIPartnerRewards);
      setPartnerRewardProgramMemberId(initiateUIPartnerRewards?.member_id);
    }

    // when entering res-flow at any view, show the overlay if we're fetching the session
    this.props.isFetchingSession && utils.dom.loadingOverlay();
  }

  componentDidUpdate(prevProps) {
    const {
      pickup_location,
      hydrateUiReservationsState,
      isFetchingSession,
      gboReservationExists,
      isTrueModify,
      isCounterBypassIneligible,
      openCounterBypassIneligibleModal,
      isReservationFlowNotAllowed,
      setPartnerRewardProgram,
      setPartnerRewardProgramMemberId,
      initiateUIPartnerRewards,
    } = this.props;

    const initatedResFlowInTrueModify = utils.storageManager.SessionStorage.get(
      SESSION_STORAGE.INITIATE_RES_FLOW_TRUE_MODIFY
    );

    // initiate call was clearing gma and other car class details after we make changes to pickup date/time and pick/return location.
    // storing the boolean value when it is initiated and clearing the resflow immediatedly as sometimes it might create a odd situations.
    utils.storageManager.SessionStorage.remove(SESSION_STORAGE.INITIATE_RES_FLOW_TRUE_MODIFY);

    if ((!prevProps.pickup_location && pickup_location) || initatedResFlowInTrueModify) {
      this.initializeLocations();
      // hydrate UI from GMA if the GBO res is missing, such as when we haven't chosen a branch yet
      hydrateUiReservationsState({ initiateFromGma: !gboReservationExists || isTrueModify });
    }

    if (
      prevProps.pickup_location &&
      prevProps.pickup_location !== pickup_location &&
      !isFetchingSession &&
      prevProps.isFetchingSession &&
      gboReservationExists
    ) {
      this.props.getRentalTermsAndConditions();
    }

    // Validate isReservationFlowNotAllowed only after current session was fetched
    if (prevProps.isFetchingSession !== isFetchingSession) {
      // If reservation flow pages are not allowed, should redirect to VMC details -- redirect to home page
      isReservationFlowNotAllowed && utils.loadingRedirect(WINDOW_OBJECT_KEYS.HOME_PAGE_URL);
    }

    // when entering res-flow at any view, show the overlay if we're fetching the session
    !prevProps.isFetchingSession && isFetchingSession && utils.dom.loadingOverlay();
    // if and when fetching ends, remove the overlay
    prevProps.isFetchingSession && !isFetchingSession && utils.dom.loadingOverlay(false);

    if (isTrueModify && !prevProps.isCounterBypassIneligible && isCounterBypassIneligible) {
      openCounterBypassIneligibleModal();
    }

    // When user logs in and we have PRP data we check users profile PRP:
    if (initiateUIPartnerRewards) {
      setPartnerRewardProgram(initiateUIPartnerRewards);
      setPartnerRewardProgramMemberId(initiateUIPartnerRewards?.member_id);
    }
  }

  initializeLocations = () => {
    // decides which location to show first in a one_way_rental situation
    const { one_way_rental, pickup_location, return_location } = this.props;
    let showReturnLocation = false;

    if (
      one_way_rental &&
      return_location.type !== LOCATIONS.TYPE_BRANCH &&
      pickup_location.type === LOCATIONS.TYPE_BRANCH
    ) {
      showReturnLocation = true;
    }

    this.setState({
      enableChoosePickupCta: pickup_location.type !== LOCATIONS.TYPE_BRANCH,
      initialPickupLocation: pickup_location,
      showReturnLocation,
    });
  };

  toggleShowReturnLocation = () => {
    this.setState(({ showReturnLocation }) => ({ showReturnLocation: !showReturnLocation }));
    this.props.router.navigate(RESFLOW_PATHS_CONFIG.location[0].toLowerCase());
  };

  handleRouteUpdate = (pathname) => {
    const { isGmiReservationStateEmpty, showSoldOutNearbyLocations, restoreGMIUIToInitiateState } = this.props;
    // Scroll user back to the top of the page when route changes
    if (!window.pageXOffset) {
      utils.dom.scrollPage();
    }
    const isAtStartOfReservationFlow = window.location.hash.indexOf(RESERVATIONS.RESFLOW_PATHS_CONFIG.start[0]) !== -1;
    //  We need this check, there was a bug going on where the handleRouteUpdate would fire when we do a non-hard refresh and it wouldn't go through spa.js
    if (isGmiReservationStateEmpty) {
      !isAtStartOfReservationFlow &&
        location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESERVATION_INFLOW));
    } else {
      const locationSelectPage = RESERVATIONS.RESFLOW_PATHS_CONFIG.location[0];
      // Only not call this if it's on select location page where user is coming from in res with a sold out location ("See nearby locaitons")
      if (!(locationSelectPage === pathname && showSoldOutNearbyLocations)) {
        this.props.hydrateUiReservationsState();
      }
    }
    // restore  if GMA > UI state and user is on location select page , hydrate initiate state to match with GMA pickup locaiton.
    restoreGMIUIToInitiateState();
    // Dispatch the analytics ready event to indicate a "new page load" (since this is a SPA)
    if (pathname !== '/start') {
      // To prevent firing analytics "page view" event twice on redirected #/start page
      utils.analytics.init(utils.config.getPageInfo());
    }
  };

  genericTitle = `${utils.i18n('reservation_flow_reservation')} - ${utils.i18n('reservation_flow_page_title')}`;

  // Adds text to loading-overlay when user select/update location selection
  overlayText = (textValue) => {
    setTimeout(() => {
      const overlay = document.querySelector('.loading-overlay');
      if (overlay !== null) {
        overlay.innerHTML = `<p>${textValue}</p>`;
      }
    }, '100');
  };

  renderLocationFinder = () => {
    const { one_way_rental, pickup_location, return_location, router } = this.props;
    const { enableChoosePickupCta, initialPickupLocation } = this.state;
    const pathname = router?.location?.pathname;
    const showReturnLocation = pathname === RESFLOW_PATHS_CONFIG.location[1];
    document.title = `${utils.i18n('reservation_flow_title_select_location')} - ${this.genericTitle}`;
    this.overlayText(utils.i18n('finding_your_locations'));
    return (
      <LocationFinder
        enableChoosePickupCta={enableChoosePickupCta}
        initialPickupLocation={initialPickupLocation}
        one_way_rental={one_way_rental}
        reservationFlow
        selectedLocation={showReturnLocation ? return_location : pickup_location}
        showReturnLocation={showReturnLocation}
        toggleShowReturnLocation={this.toggleShowReturnLocation}
        overlayText={this.overlayText}
      />
    );
  };

  renderVehicleSelect = () => {
    document.title = `${utils.i18n('reservation_flow_title_select_vehicle')} - ${this.genericTitle}`;
    this.overlayText(utils.i18n('loading_your_vehicles'));
    return <VehicleSelect />;
  };

  renderExtras = () => {
    document.title = `${utils.i18n('extras_main_title')} - ${this.genericTitle}`;
    return <Extras />;
  };

  renderReview = () => {
    document.title = `${utils.i18n('review_page_title')} - ${this.genericTitle}`;
    return <Review />;
  };

  renderStart = () => <ReservationFlowBookingWidget />;

  render() {
    const { isTrueModify, prepaySelected, router } = this.props;
    const isAuthorMode = utils.config.getIsAuthorModeEnabled();
    const pathname = router?.location?.pathname;

    return (
      <>
        <section
          className={cn('reservation-flow theme--dark-blue', {
            'on-author': isAuthorMode,
          })}
        >
          <Header showDiscountElements showAlamoInsidersIfEnabled />
          {isTrueModify && <ModifyResBanner />}
          <ProgressBar isTrueModify={isTrueModify} />
          <utils.OnUpdateWrapper pathname={pathname} onUpdate={() => this.handleRouteUpdate(pathname)}>
            <Routes>
              <Route path={RESFLOW_PATHS_CONFIG.start[0]} exact element={<this.renderStart />} />
              {RESFLOW_PATHS_CONFIG.location.map((path) => (
                <Route key={path} path={path} exact element={<this.renderLocationFinder />} />
              ))}
              <Route path={RESFLOW_PATHS_CONFIG.vehicle[0]} exact element={<this.renderVehicleSelect />} />
              <Route path={RESFLOW_PATHS_CONFIG.extras[0]} exact element={<this.renderExtras />} />
              <Route path={RESFLOW_PATHS_CONFIG.review[0]} exact element={<this.renderReview />} />
              <Route path='*' replace element={<Navigate to={RESFLOW_PATHS_CONFIG.start[0]} />} />
            </Routes>
          </utils.OnUpdateWrapper>
          <CondensedFooter />
        </section>

        <Modal
          modalKey={MODAL.EDIT_VEHICLE}
          header={utils.i18n('edit_vehicle_modal_header')}
          theme={MODAL_THEMES.WHITE}
        >
          <EditVehicleModalContent />
        </Modal>
        <Modal
          modalKey={MODAL.REMOVE_CID_MODAL}
          header={utils.i18n('progress_bar_cid_remove_modal_title')}
          theme={MODAL_THEMES.WHITE}
        >
          <RemoveCIDModalContent />
        </Modal>
        <Modal
          modalKey={MODAL.REMOVE_PARTNER_REWARDS_MODAL}
          header={utils.i18n('progress_bar_partner_rewards_modal_header')}
          theme={MODAL_THEMES.WHITE}
        >
          <RemovePartnerRewardsModalContent />
        </Modal>
        <Modal
          modalKey={MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL}
          theme={MODAL_THEMES.WHITE}
          header={utils.i18n('rental_details_taxes_fees_modal_header')}
        >
          <TaxesModalContent />
        </Modal>
        <Modal
          modalKey={MODAL.RENTAL_DETAILS_LEARN_MORE_MODAL}
          header={utils.i18n('rental_details_about_currency_conversion')}
          theme={MODAL_THEMES.WHITE}
        >
          <p className='modal-themed__copy'>{utils.i18n('converted_rates_modal_copy')}</p>
        </Modal>
        <CurrencyConversionModal />
        <DiscardReservationModal />
        <GuaranteedResModal />
        <RemoveCouponModal />
        <RemoveProductCodeModal />
        <ConfirmCancelReservationModal />
        {isTrueModify && <EligibilityLossModal />}
        {!prepaySelected && <CouponDoesNotApplyModal />}
        <SessionTimeoutModal />
      </>
    );
  }
}

export default withRouter(ReservationFlow);
