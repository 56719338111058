import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import LoadingWidget from 'components/LoadingWidget';
import Anchor from 'components/Anchor';
import MyProfileSectionTitle from '../MyProfileSectionTitle';

/**
 * AccountAndPassword
 * block to show users full name and masked email with edit button and prompt about special offers email subscription
 *
 * @param {object} props - React Props
 * @param {object} props.profileData - Authenticated user profile data
 * @param {string} props.profileData.first_name - Authenticated user first name
 * @param {string} props.profileData.last_name - Authenticated user last name
 * @param {string} props.profileData.email - Authenticated user Alamo Insider email
 * @param {object} props.profileData.email_preference - Authenticated user email preferences
 * @param {function} props.openEditModal - Function to open modal with edit form
 *
 * @return {JSX}
 */
const AccountAndPassword = ({ profileData, openEditModal }) => {
  const { first_name, last_name, email, email_update_required } = profileData || {};
  const fullName = `${first_name} ${last_name}`;

  const anchorOpenModal = (e) => {
    e.preventDefault();
    openEditModal();
  };

  const renderSubscriptionBlock = () => {
    const { email_preference } = profileData || {};

    return email_preference?.special_offers ? (
      <>
        <p className='my-profile-subscription-banner__copy'>{utils.i18n('my_profile_offers_subscribed')}</p>
        <p className='my-profile-subscription-banner__copy'>
          <Anchor href='#' onClick={anchorOpenModal} className='link link--text'>
            {utils.i18n('my_profile_offers_subscribed_cta')}
          </Anchor>
        </p>
      </>
    ) : (
      <>
        <p className='my-profile-subscription-banner__copy'>{utils.i18n('my_profile_offers_not_subscribed_copy_1')}</p>
        <p className='my-profile-subscription-banner__copy'>
          {utils.i18n(
            'my_profile_offers_not_subscribed_copy_2',
            [
              <Anchor href='#' onClick={anchorOpenModal} key={0} className='link link--text'>
                {utils.i18n('my_profile_offers_not_subscribed_cta')}
              </Anchor>,
            ],
            {
              jsx: true,
            }
          )}
        </p>
      </>
    );
  };

  const handleEmailText = () => {
    if (email_update_required) {
      return (
        <span className='my-profile__email-required-warning'>{utils.i18n('my_profile_unique_email_required')}</span>
      );
    }
    return email;
  };

  return (
    <div className='my-profile__section'>
      <MyProfileSectionTitle
        title={utils.i18n('my_profile_account_and_password')}
        handleEdit={openEditModal}
        isCompleted
      />
      {profileData ? (
        <>
          <p className='my-profile__info-row'>
            {utils.i18n('my_profile_name_label')}: {fullName}
          </p>
          <p className='my-profile__info-row'>
            {utils.i18n('my_profile_email_label')} :{handleEmailText()}
          </p>
          <div className='my-profile-subscription-banner'>{renderSubscriptionBlock()}</div>
        </>
      ) : (
        <LoadingWidget />
      )}
    </div>
  );
};

AccountAndPassword.propTypes = {
  profileData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    email_preference: PropTypes.object,
  }),
  openEditModal: PropTypes.func,
};

export default AccountAndPassword;
