import { connect } from 'react-redux';
import { recommendedExtrasNotAddedSelector } from 'selectors';
import RecommendedExtrasAlertContent from './RecommendedExtrasAlertContent';

const mapStateToProps = state => ({
  countryCode: state.getIn(['gmi', 'session', 'gbo', 'reservation', 'pickup_location', 'address', 'country_code']),
  recommendedExtrasNonAdded: recommendedExtrasNotAddedSelector(state),
});

export default connect(mapStateToProps)(RecommendedExtrasAlertContent);
