import { connect } from 'react-redux';
import utils from 'utils';
import { locationInfoById } from 'actions/location';
import LocationServices from './LocationServices';

const mapStateToProps = state => ({
  locationInfo: state.getIn(['gmi', 'gma', 'location', 'by_id']),
});

const mapDispatchToProps = {
  locationInfoById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(LocationServices));
