import { connect } from 'react-redux';
import utils from 'utils';
import { sessionReservationObjectSelector, getUiCommitStateSelector, profileDataSelector } from 'selectors';
import { authenticatedSelector } from 'selectors/profileSelectors';
import { clearPartnerRewardsState } from "actions/reservation/clearPartnerRewardsState";
import { partnerRewards } from "actions/reservation/partnerRewards";
import { clearDiscountInReservationFlow } from "actions/reservation/clearDiscountInReservationFlow";
import RemovePartnerRewardsModalContent from './RemovePartnerRewardsModalContent';

const mapStateToProps = (state) => {
  const gmiReservationState = sessionReservationObjectSelector(state);
  return {
    partner_rewards_name: gmiReservationState?.getIn(['partner_reward_program', 'program_name']),
    uiCommitState: getUiCommitStateSelector(state),
    profileData: profileDataSelector(state, { partnerRewardsProgram: true }),
    isAuth: authenticatedSelector(state),
  };
};

const mapDispatchToProps = {
  clearDiscountInReservationFlow,
  clearPartnerRewardsState,
  partnerRewards,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(RemovePartnerRewardsModalContent));
