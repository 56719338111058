import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import THEMES from 'constants/themes';

/**
 * Button Description:
 * Our base button component to be used in almost any situation
 * can be styled as link as well because anchors should only be used for href
 *
 * @param {object} props - React Props
 * @param {boolean} [props.button=true] - applies the button className for styling purposes
 * @param {node} props.children - whatever you wish to appear within th button
 * @param {function} props.onClick - click event handler
 * @param {boolean} props.disabled - applies the disabled attribut to the dom element
 * @param {string} [props.type='button'] -
 * @param {boolean} props.loading - will show the loading animation and disable the button
 * @param {string} props.ariaText - text for aria-label
 * @param {string} [props.role='button'] - value for the role attribute
 * @param {string} props.className
 * @param {boolean} props.link - applies the link className for styling, will negate the 'button' styles
 * @param {boolean} props.linkText - applies 'link--text' className
 * @param {boolean} props.alternative - 'button--alternative' className
 * @param {boolean} props.inverse - 'button--inverse' className
 * @param {string}  props.theme - introducing new styling pattern for buttons after consulting with UX. Currently this
 *                  feature is additive, we are leaving the old styling pattern intact but creating a
 *                  TODO: to refactor Buttons to use new pattern (may need review of authoring)
 *                  New pattern takes a string ie: 'dark', 'medium', 'light. Theme property will override old inverse and
 *                  alternative props, so only apply it to new Button unless working on the refactor. Available theme and
 *                  variation styles can be found:
 *                  @see scss/_settings.scss $btn-colors
 * @param {boolean} props.ghosted - sets ghosted variation on theme property
 * @param {boolean} prop.removeAriaLabel - sets removeAriaLabel to null when aria label is not necessary
 * * @param {boolean} props.plain - keeps the button in it's original state & style
 * @return {JSX}
 */
class Button extends Component {
  handleClick = (e) => utils.safeExecute(this.props.onClick, { e });

  render() {
    const {
      button,
      buttonRef,
      className,
      disabled,
      type,
      children,
      alternative,
      inverse,
      link,
      linkText,
      plain,
      loading,
      ariaText,
      ariaExpanded,
      role,
      theme,
      ghosted,
      ariaSelected,
      removeAriaLabel,
      ...customProps
    } = this.props;
    const buttonClasses = cn(
      {
        button: !(link || linkText || plain) && button,
        'button--alternative': !theme && alternative,
        'button--inverse': !theme && inverse,
        link: !!link,
        'link--text': !!linkText,
        'animated-loader__before': loading,
        'button--loading': loading,
        [`button--${theme}`]: theme && !ghosted,
        [`button--${theme}-ghosted`]: theme && ghosted,
      },
      className
    );

    let ariaLabelFallback = utils.gmi.types(children).isString ? children : '';
    if (removeAriaLabel) {
      ariaLabelFallback = null;
    }
    return (
      <button
        className={buttonClasses}
        onClick={this.handleClick}
        disabled={disabled || loading}
        type={type}
        aria-label={ariaText || ariaLabelFallback}
        aria-expanded={ariaExpanded || null}
        aria-selected={ariaSelected || null}
        role={role || null}
        ref={buttonRef}
        {...customProps}
      >
        {children}
      </button>
    );
  }
}

Button.defaultProps = {
  button: true,
  disabled: false,
  role: null,
  type: 'button',
  ariaExpanded: null,
  removeAriaLabel: false,
};

Button.propTypes = {
  button: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool,
  ariaText: PropTypes.string,
  ariaExpanded: PropTypes.string,
  role: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.bool,
  linkText: PropTypes.bool,
  plain: PropTypes.bool,
  alternative: PropTypes.bool,
  inverse: PropTypes.bool,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT, THEMES.MEDIUM]),
  ghosted: PropTypes.bool,
  removeAriaLabel: PropTypes.bool,
};

export default Button;
