import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import utils from 'utils';
import { ANALYTICS, GLOBAL, RESERVATIONS } from 'constants';
import Picture from 'components/Picture';
import Button from 'components/Button';
import VehicleDetailsIconList from 'components/VehicleDetailsIconList';
import TotalPriceVehicleProgressBarDrawer from 'components/ReservationFlow/ProgressBar/ProgressBarVehicleDrawer/TotalPriceVehicleProgressBarDrawer';
import InPathVehicleUpgradeProgressBar from 'components/ReservationFlow/InPathVehicleUpgrade/InPathVehicleUpgradeProgressBar';
import { propTypes } from './progressBarVehicleDrawerPropTypes';
import ProgressBarDrawerWrapper from '../ProgressBarDrawerWrapper';

class ProgressBarVehicleDrawer extends Component {
  static propTypes = propTypes;

  renderEditExtrasButton = () => {
    const { numberOfExtrasSelected, breakpoint } = this.props;
    const selectVehiclePath = RESERVATIONS?.RESFLOW_PATHS_CONFIG?.vehicle[0];
    return (
      <div className='progress-bar-vehicle-drawer__vehicle-edit'>
        {breakpoint.isTablet && <TotalPriceVehicleProgressBarDrawer />}
        <Link to={selectVehiclePath}>
          <Button data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.PROGRESS_BAR, 'edit_vehicle')}>
            {utils.i18n('progress_bar_vehicle_drawer_edit_vehicle_btn')}
          </Button>
        </Link>
        {!!numberOfExtrasSelected && <p>{utils.i18n('progress_bar_vehicle_drawer_edit_vehicle_disclaimer')}</p>}
      </div>
    );
  };

  render() {
    const {
      vehicleName,
      code,
      makeModelOrSimilarText,
      features,
      peopleCapacity,
      luggageCapacity,
      twoOrFourDoorText,
      images,
      breakpoint,
      coupons,
    } = this.props;
    const transmission = utils.getTransmissionDescriptionFromFeaturesArray(features);
    const carImage = utils.getImageFromImagesObject(images);
    const isDriveHappyDeal = GLOBAL.HAPPY_DEAL_CODE === code;
    const freeUpgradeApplied = utils.isFreeUpgradeAvailable(coupons);

    return (
      !!vehicleName && (
        <ProgressBarDrawerWrapper shouldScroll>
          <div className='progress-bar-vehicle-drawer'>
            <div className='progress-bar-vehicle-drawer__vehicle-info'>
              <h3>{utils.i18n('progress_bar_vehicle_drawer_title')}</h3>
              <div className='rental-details__section rental-details__vehicle'>
                <div className='rental-details__vehicle-information'>
                  <div className='rental-details__vehicle-stats'>
                    {freeUpgradeApplied && (
                      <div className='rental-details__free-upgrade'>
                        {utils.i18n('coupon_name_applied', [utils.i18n('coupon_upgrade_adjustment_name')])}
                      </div>
                    )}

                    <p className='rental-details__vehicle-name'>{vehicleName}</p>
                    <p className='rental-details__similar-make'>
                      {isDriveHappyDeal
                        ? makeModelOrSimilarText // Display without '* or similar'
                        : utils.i18n('vehicle_details_model_or_similar', [makeModelOrSimilarText])}
                    </p>

                    <VehicleDetailsIconList
                      transmission={transmission}
                      peopleCapacity={peopleCapacity}
                      luggageCapacity={luggageCapacity}
                      twoOrFourDoorText={twoOrFourDoorText}
                      isDriveHappyDeal={isDriveHappyDeal}
                      features={features}
                    />
                  </div>
                  {!breakpoint.isTablet && <TotalPriceVehicleProgressBarDrawer />}
                  {breakpoint.isTablet && this.renderEditExtrasButton()}
                </div>
              </div>
            </div>
            <div className='progress-bar-vehicle-drawer__vehicle-display'>
              <img className='rental-details__vehicle-display--background' />
              <Picture
                className='rental-details__vehicle-display--car'
                src={carImage}
                alt={makeModelOrSimilarText}
                srcMobile={carImage}
                srcTablet={carImage}
              />
            </div>
            {!breakpoint.isTablet && this.renderEditExtrasButton()}
          </div>

          {breakpoint.isDesktop && <InPathVehicleUpgradeProgressBar />}
        </ProgressBarDrawerWrapper>
      )
    );
  }
}

export default ProgressBarVehicleDrawer;
