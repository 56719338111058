import React from 'react';
import utils from 'utils';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES } from 'constants/modals';

/**
 * WayfindingsModal - View directions to lot modal
 * @param {object} props - React Props
 * @return {JSX}
 */
const WayfindingsModal = () => (
  <Modal
    modalKey={MODAL.STC_VIEW_DIRECTIONS_LOT_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('stc_directions_lot_modal_title')}>
    {({ wayfindings = [] }) => (
      <div className='skip-the-counter-confirmation__wayfinding'>
        {wayfindings?.map(item => (
          <p className='modal-themed__paragraph' key={item.text} dangerouslySetInnerHTML={utils.sanitize(item.text)} />
        ))}
      </div>
    )}
  </Modal>
);

export default WayfindingsModal;
