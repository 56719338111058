import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import cn from 'classnames';

/**
 * Rate Secion component is intened to render a piece of a vehicle detail "table"
 * on the Vehicle Select view of Res Flow
 *
 * @param {object} props - react props
 * @param {node} props.children - any nested elements passed to the react component
 * @param {string|array} props.payLaterAsterisks - The markup to render within the tabel "cell"
 * @param {boolean} props.payLaterSegment - this flag indicates if the "cell" should have a `**` apended to it
 * @param {boolean} props.borderless - this flag indicates if the section should remove its' bottom border
 * @param {boolean} props.hidePayLaterSegment - this flag indicates that paylater section should be hidden
 *
 * @return {jsx} the DOM for a Rate Section
 */
const RateSection = ({
  payLaterSegment,
  payLaterAsterisks = true,
  borderless = false,
  hidePayLaterSegment,
  button,
  buttonHandler,
  item,
  value,
  withBorder,
  border,
  asterisks,
  tdColumnSpan,
  convertedPrices,
  title,
}) => {
  let payLaterAsterisksCondition;
  if (payLaterAsterisks) {
    payLaterAsterisksCondition = '**';
  }
  if (payLaterAsterisks && convertedPrices) {
    payLaterAsterisksCondition = '*';
  }

  return button || item || value ? (
    <tr className={cn({ borderless, withBorder, border })}>
      {button && (
        <th className='th-rate-row'>
          {title && <span className='th-row-title'>{title}</span>}
          <Button button={false} onClick={buttonHandler}>
            {button}
          </Button>
        </th>
      )}
      {item && (
        <th scope='row' colSpan={tdColumnSpan} className='th-rate-row'>
          {title && <span className='th-row-title'>{title}</span>}
          {item && <span>{item}</span>}
        </th>
      )}

      {value && (
        <td align='right'>
          <span>
            {value}
            {asterisks && '**'}
          </span>
        </td>
      )}

      {payLaterSegment && !hidePayLaterSegment && (
        <td align='right'>
          {payLaterSegment}
          {payLaterAsterisksCondition}
        </td>
      )}
    </tr>
  ) : null;
};

RateSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  payLaterSegment: PropTypes.node,
  payLaterAsterisks: PropTypes.bool,
  borderless: PropTypes.bool,
  hidePayLaterSegment: PropTypes.bool,
  title: PropTypes.string,
  button: PropTypes.string,
  buttonHandler: PropTypes.func,
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  asterisks: PropTypes.bool,
  isEuropeanUnionCountry: PropTypes.bool,
  tdColumnSpan: PropTypes.number,
  convertedPrices: PropTypes.bool,
};

export default RateSection;
