import { connect } from 'react-redux';
import { breakpointSelector } from 'selectors/breakpoint';
import UpcomingRentalUI from './UpcomingRentalUI';

const mapStateToProps = (state) => ({
  breakpoint: breakpointSelector(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingRentalUI);
