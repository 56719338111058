import { connect } from 'react-redux';
import { profileDataSelector, keepMeLoggedInSelector, isLoggedInSelector } from 'selectors/profileSelectors';
import Authenticated from './Authenticated';

const mapStateToProps = (state, { options }) => ({
  logged_in: isLoggedInSelector(state),
  profileData: profileDataSelector(state, { ...options, email_update_required: true }),
  keepMeLoggedIn: keepMeLoggedInSelector(state),
});

export default connect(mapStateToProps)(Authenticated);
