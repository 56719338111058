import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { THEMES } from 'constants';
import Form from 'components/Form/Form';
import TextField from 'components/Form/TextField';
import Button from 'components/Button';

/**
 * Reservation Lookup Form Component
 * A form on which the user inputs their first/last name + reservation confirmation number to perform a lookup.
 *
 * @param {object} props - React Props
 * @param {function} props.onFormSubmit - Function that handles the form submit action
 * @param {string} props.className - Custom style class for the form
 * @param {string} props.submitButtonText - Display text for the form submit button
 * @param {object} props.loyaltyProfile - (optional) Authenticated user data; when existing and passed as props,
 *                                        will pre-fill first and last name fields and lock the last name.
 * @param {object} props.preSelectedReservation - (optional) Pre selected reservation data, normally coming from
 *                                                a deeplink scenario. This data have precedence over profile.
 * @param {string} props.submitDTMTrack - DTM track value for submit button
 * @param {object} props.labels - object of string for the labels in the form
 *
 * @return {JSX}
 */
const ReservationLookupForm = ({
  onFormSubmit,
  className,
  submitButtonText,
  submitButtonTextAriaLabel,
  loyaltyProfile,
  preSelectedReservation,
  submitDTMTrack,
  labels,
}) => {
  const isUserAuthenticated = !!loyaltyProfile?.first_name;
  const { firstName, lastName, confirmationNumber } = preSelectedReservation || {};

  const initialData = useMemo(
    () => ({
      reservationData: {
        firstName: firstName || loyaltyProfile?.first_name,
        lastName: lastName || loyaltyProfile?.last_name,
        confirmationNumber,
      },
    }),
    [loyaltyProfile?.first_name, firstName, lastName, confirmationNumber]
  );

  const isLastNameReadOnly = isUserAuthenticated && !preSelectedReservation?.lastName;

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialData}>
      {({ handleSubmit }) => (
        <form
          className={cn(
            className,
            'rental-lookup-form rental-lookup-form__container condensed-padding theme--dark-blue'
          )}
          onSubmit={handleSubmit}
          autoComplete='off'
          noValidate
        >
          <div className='rental-lookup-form__row'>
            <TextField
              name='reservationData.firstName'
              label={labels?.firstNameLabel || utils.i18n('review_drive_details_first_name_input_label')}
              fill
              required
              errorTheme={THEMES.DARK_BLUE}
              autoComplete='given-name'
            />
            <TextField
              name='reservationData.lastName'
              label={labels?.lastNameLabel || utils.i18n('review_drive_details_last_name_input_label')}
              fill
              required
              errorTheme={THEMES.DARK_BLUE}
              readOnly={isLastNameReadOnly}
              tooltip={isLastNameReadOnly ? utils.i18n('check_in_lookup_auth_user_tooltip') : ''}
              autoComplete='family-name'
            />
          </div>
          <div className='rental-lookup-form__row'>
            <TextField
              name='reservationData.confirmationNumber'
              label={labels?.confirmationNumberLabel || utils.i18n('reservation_lookup_confirmation_number')}
              fill
              required
              errorTheme={THEMES.DARK_BLUE}
            />

            <Button
              type='submit'
              className='rental-lookup-form__search-button'
              data-dtm-track={submitDTMTrack}
              ariaText={submitButtonTextAriaLabel}
            >
              {submitButtonText || utils.i18n('reservation_lookup_search_button')}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

ReservationLookupForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  submitButtonText: PropTypes.string,
  submitButtonTextAriaLabel: PropTypes.string,
  loyaltyProfile: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  submitDTMTrack: PropTypes.string,
  preSelectedReservation: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    confirmationNumber: PropTypes.string,
  }),
  labels: PropTypes.shape({
    firstNameLabel: PropTypes.string,
    lastNameLabel: PropTypes.string,
    confirmationNumberLabel: PropTypes.string,
  }),
};

export default ReservationLookupForm;
