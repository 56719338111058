import React from 'react';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import utils from 'utils';
import cn from 'classnames';
import { PROFILE } from 'constants';
import MyTrips from 'components/MyTrips';
import ProfileAccount from 'components/ProfileAccount';
import Receipt from 'components/Receipt';

const { PROFILE_PATHS_CONFIG } = PROFILE;

const ProfileSPA = () => {
  const handleRouteUpdate = () => {
    if (!window.pageXOffset) {
      utils.dom.autoPageTop();
    }

    // Dispatch the analytics ready event to indicate a "new page load" (since this is a SPA)
    utils.analytics.init(utils.config.getPageInfo());
  };

  const className = cn('profile', { 'theme--dark-blue': !PROFILE_PATHS_CONFIG.myTrips });

  return (
    <Router basename={'/'}>
      <section className={className}>
        <Route>
          {({ location }) => (
            <utils.OnUpdateWrapper pathname={location.pathname} onUpdate={handleRouteUpdate}>
              <Switch>
                <Route path={PROFILE_PATHS_CONFIG.myProfile} exact component={ProfileAccount} />
                <Route path={PROFILE_PATHS_CONFIG.myTrips} exact component={MyTrips} />
                <Route path={PROFILE_PATHS_CONFIG.receipt} exact component={Receipt} />
                <Redirect to={PROFILE_PATHS_CONFIG.myProfile[0]} from={'/'} />
              </Switch>
            </utils.OnUpdateWrapper>
          )}
        </Route>
      </section>
    </Router>
  );
};

export default ProfileSPA;
