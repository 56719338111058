import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import PROFILE from 'constants/profile';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import Anchor from 'components/Anchor';

/**
 * PastTripCard
 *
 * @param {object} props - React Props
 * @param {object} props.trip - Trip object with reservation details
 *
 * @return {JSX}
 */
const PastTripCard = ({ trip = {}, isMyTripsPage }) => {
  const { rental_agreement_number, pickup_location, pickup_time, return_location, return_time, invoice_number } = trip;
  // Past trip object don't have a property 'one_way_rental so we compare
  // pickup and return locations ids check if it's a one way rental
  const isOneWayRental = pickup_location?.id !== return_location?.id;

  const dateFormat = dateOjb => utils.gmi.getDateTimeObjFromTs(dateOjb).format('MMM D, H:mm A');

  const receiptButtonHref = isMyTripsPage
    ? utils.url.createUrl({
        base: utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.PROFILE_PAGE),
        hash: utils.url.setParams({
          path: PROFILE.PROFILE_PATHS_CONFIG.receipt[0],
          params: {
            invoice: invoice_number,
            isFromMyTrips: true,
          },
        }),
      })
    : `${utils.config.getRedirectUrl(
        WINDOW_OBJECT_KEYS.UNAUTH_RECEIPT_PAGE
      )}?invoice=${invoice_number}&isFromMyTrips=false`;

  const dataDtmTrack = isMyTripsPage
    ? utils.analytics.dtm(ANALYTICS.MY_TRIPS, ANALYTICS.PAST_TRIPS, ANALYTICS.VIEW_RECEIPT)
    : utils.analytics.dtm(ANALYTICS.PAST_TRIPS, ANALYTICS.TRIP_DETAILS, ANALYTICS.VIEW_RECEIPT);

  return (
    <div className='past-trips__card' key={rental_agreement_number}>
      <div className='past-trips__card-info'>
        <h3 className='past-trips__card-location'>
          {isOneWayRental
            ? utils.i18n('past_trips_one_way_rental', {
                0: pickup_location?.name,
                1: return_location?.name,
              })
            : pickup_location?.name}
        </h3>
        <p className='past-trips__card-agreement-number'>
          {utils.i18n('past_trips_rental_agreement')}
          {rental_agreement_number}
        </p>
        <p className='past-trips__card-rental-dates'>{`${dateFormat(pickup_time)} - ${dateFormat(return_time)}`}</p>
      </div>
      <Anchor
        className='past-trips__cta button button--alternative'
        data-dtm-track={dataDtmTrack}
        href={receiptButtonHref}>
        {utils.i18n('past_trips_cta_view_receipt')}
      </Anchor>
    </div>
  );
};

PastTripCard.propTypes = {
  trip: PropTypes.shape({
    rental_agreement_number: PropTypes.string,
    pickup_location: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    pickup_time: PropTypes.string,
    return_location: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    return_time: PropTypes.string,
    invoice_number: PropTypes.string,
    customer_last_name: PropTypes.string,
  }),
  isMyTripsPage: PropTypes.bool,
};

export default PastTripCard;
