import { connect } from 'react-redux';
import { checkinStart } from 'actions/checkin';
import { skipTheCounterStart } from 'actions/skipTheCounter';
import MODAL from 'constants/modals';
import {
  getOnlineCheckInEligibleFlag,
  isSkipTheCounterCompletedReservationSelector,
  isCheckedInReservationSelector,
  getCounterBypassEligibleFlag,
} from 'selectors/reservationFlowSelectors';
import { getAutoOnlineCheckInFlag } from 'selectors/reservationSelectors';
import { openModal } from "actions/modal/openModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import ConfirmationHeaderCheckInStatus from './ConfirmationHeaderCheckInStatus';

const mapStateToProps = state => ({
  isPickupStatusAddedAutomatically: getAutoOnlineCheckInFlag(state),
  isOnlineCheckInEligible: getOnlineCheckInEligibleFlag(state),
  isCheckedIn: isCheckedInReservationSelector(state),
  isSkipTheCounterEligible: getCounterBypassEligibleFlag(state),
  isSkipTheCounterCompleted: isSkipTheCounterCompletedReservationSelector(state),
  additionalDriverDataNotProvided: false, // TBD in ticket that takes care of only this piece
});

const mapDispatchToProps = {
  checkinStart: origin => checkinStart({ origin }),
  skipTheCounterStart: origin => skipTheCounterStart({ origin }),
  openLearnMoreAboutSkipTheCounterModal: () => openModal(MODAL.LEARN_MORE_ABOUT_SKIP_THE_COUNTER_MODAL),
  openLearnMoreAboutOCIInactiveModal: () => openModal(MODAL.LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_INACTIVE_MODAL),
  openLearnMoreAboutOCIActivatedModal: () => openModal(MODAL.LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_ACTIVATED_MODAL),
  openViewDirectionsLotModal: wayfindings => openModalWithProps(MODAL.STC_VIEW_DIRECTIONS_LOT_MODAL, { wayfindings }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationHeaderCheckInStatus);
