import { connect } from 'react-redux';
import { closeModal } from "actions/modal/closeModal";
import { clearModalQueue } from "actions/modal/clearModalQueue";
import * as ModalFooterTypes from './ModalFooter';

const mapDispatchToProps = {
  closeModal,
  clearModalQueue,
};

export const ModalFooter = connect(
  null,
  mapDispatchToProps
)(ModalFooterTypes.ModalFooter);

export const ModalFooterWithRouter = connect(
  null,
  mapDispatchToProps
)(ModalFooterTypes.ModalFooterWithRouter);
