import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import cn from 'classnames';
import { ANALYTICS, LOCATIONS, RESERVATIONS, PARTNER_URL } from 'constants';
import useActions from 'hooks/useActions';
import { setAdjustLocationId } from 'actions/locationFinder';
import Button from 'components/Button';
import MapLocationDetailTileExpandedState from '../MapLocationDetailTileExpandedState';
import SoldOutTileBanner from '../../SoldOutTileBanner';

const MapLocationDetailTile = (props) => {
  const {
    gps,
    calculateDistance,
    locationInfo,
    locationInfoById,
    id,
    onSelectLocation,
    onExpandLocation,
    expandedLocationId,
    onMouseEnterLocation,
    name,
    brand,
    address,
    linkURL,
    searchNum,
    showX,
    breakpoint,
    locationInfoExpanded,
    hoveredLocationId,
    location_type,
    formatted_city_state_zip,
    isGeoLocationSearch,
    selectedLocationId,
    location_availability,
    handleSelectedLocation,
    onMouseLeaveLocation,
    redirectOrDisplayBookWitPartnerModal,
    isOneWayReservation,
    reservationFlow,
    changeDateTime,
  } = props;
  const [isInExpandedState, setIsInExpandedState] = useState(false);
  const [locationOptions, setLocationOptions] = useState(null);

  const dispatchSetAdjustLocationId = useActions(setAdjustLocationId);

  const isSoldOutVehicle =
    reservationFlow &&
    utils.config.getIsSoldOutTrueAvailability() &&
    location_availability === LOCATIONS.UNAVAILABLE_LOCATIONS;

  useEffect(() => {
    setIsInExpandedState(expandedLocationId === id);
  }, [expandedLocationId, id]);

  useEffect(() => {
    // If someone goes from mobile view expanded to desktop view expanded
    // without clicking the close button - we don't want continue to lock it
    if (breakpoint && isInExpandedState) {
      utils.scrollLock({ toggleValue: breakpoint.isMobile });
    }
  }, [breakpoint, isInExpandedState]);

  const renderCalculatedDistance = () => {
    const distanceText = calculateDistance(gps);
    return <div className='map-location-detail-tile__distance'>{distanceText}</div>;
  };

  const getAdditionalInfo = () => {
    if (!locationInfo[id]) {
      locationInfoById(id);
    }
  };

  const printLocationServices = () => {
    if (locationInfo && locationInfo[id]) {
      const locationServices = locationInfo[id].location.service_indicators;
      //  List of booleans for check or X for a location service
      const arrayToPrint = Object.keys(locationServices).reduce((arr, locService) => {
        if (!(RESERVATIONS.SERVICE_INDICATORS[locService] === true)) {
          arr.push({
            label: RESERVATIONS.SERVICE_INDICATORS[locService],
            has: locationServices[locService],
          });
        }
        return arr;
      }, []);
      //  For after hours, two booleans for one check/ X mark
      const hasAfterHoursPU = locationServices.afterHoursPickup;
      const hasAfterHoursDO = locationServices.offersAfterHoursDropoff;

      if (hasAfterHoursDO && !hasAfterHoursPU) {
        arrayToPrint.push({ label: 'location_service_after_hours_dropoff', has: true });
      } else if (!hasAfterHoursDO && hasAfterHoursPU) {
        arrayToPrint.push({ label: 'location_service_after_hours_pickup', has: true });
      } else {
        arrayToPrint.push({
          label: 'location_service_after_hours_services',
          has: hasAfterHoursDO && hasAfterHoursPU,
        });
      }

      setLocationOptions(arrayToPrint);
    }
  };

  useEffect(() => {
    printLocationServices();
  }, [locationInfo[id]]);

  const handleSelectLocation = () => {
    onSelectLocation(id);
  };

  const handleAdjustLocation = () => {
    changeDateTime();

    // If user clicks on adjust
    // add id to state and remove it from state if user clicks outside progress bar without changing DateTime

    dispatchSetAdjustLocationId(id);
  };

  const handleExpandTile = () => {
    if (expandedLocationId === id) {
      onExpandLocation(null);
    } else {
      onExpandLocation(id);
    }
  };

  const handleMouseEnter = () => {
    onMouseEnterLocation && onMouseEnterLocation(id);
  };

  const handleMouseLeave = () => {
    onMouseLeaveLocation && onMouseLeaveLocation();
  };

  const handleClick = () => {
    handleSelectedLocation(id);
  };

  const handlePartnerLinkClick = (event) => {
    // For one way reservation, just take user to the home page
    if (isOneWayReservation) {
      return;
    }
    event.preventDefault();
    // if it's post res, then add stop=book
    redirectOrDisplayBookWitPartnerModal(!reservationFlow);
  };

  const isPartner = brand !== LOCATIONS.BRAND_ALAMO;
  const isSelected = selectedLocationId === id;
  const isInHoveredState = hoveredLocationId === id;
  const isOnMobileAndExpanded = breakpoint.isMobile && isInExpandedState;

  const partnerLocationHashMap = {
    [LOCATIONS.BRAND_ENTERPRISE]: utils.i18n('common_enterprise_long_name'),
    [LOCATIONS.BRAND_NATIONAL]: utils.i18n('common_national_long_name'),
  };

  const buttonClassName = cn('map-location-detail-tile__link', {
    'map-location-detail-tile--expanded-mobile-view': isOnMobileAndExpanded,
    'map-location-detail-tile__link--sold-out': isSoldOutVehicle,
  });

  const locationTileLink = isPartner ? PARTNER_URL[brand] : linkURL;

  return (
    <div
      className={cn('map-location-detail-tile component-theme--light', {
        'map-location-detail-tile--partner': isPartner,
        'map-location-detail-tile--main-container-expanded': isOnMobileAndExpanded,
        'map-location-detail-tile--expanded-state-focused': (isInHoveredState && !selectedLocationId) || isSelected,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      id={`location-${id}`}
    >
      <div
        className={cn('map-location-detail-tile__container', {
          'map-location-detail-tile__container--sold-out': isSoldOutVehicle,
        })}
      >
        {showX && !isOnMobileAndExpanded && (
          <Button
            plain
            className='map-location-detail-tile__close-button'
            title={utils.i18n('no_results_partner_close')}
            onClick={handleExpandTile}
            aria-label={utils.i18n('no_results_partner_close')}
          />
        )}
        {isOnMobileAndExpanded && (
          <Button
            plain
            className='map-location-detail-tile__close-button'
            title={utils.i18n('no_results_partner_close')}
            onClick={handleExpandTile}
            aria-label={utils.i18n('no_results_partner_close')}
          />
        )}
        {!breakpoint.isMobile && <span className='map-location-detail-tile__index-number'>{searchNum}</span>}
        <div
          className={cn('map-location-detail-tile__info-section', {
            'map-location-detail-tile__info-section--sold-out': isSoldOutVehicle,
          })}
        >
          <div className='map-location-detail-tile__name-info'>
            {breakpoint.isMobile && <span className='map-location-detail-tile__index-number'>{searchNum}</span>}
            <h4
              className={cn('map-location-detail-tile__main-title', {
                'map-location-detail-tile__no-margin': isGeoLocationSearch,
              })}
            >
              {name}
            </h4>
          </div>
          {isPartner && <p className='map-location-detail-tile__sub-title'>{partnerLocationHashMap[brand]}</p>}
          <div className='map-location-detail-tile__location-section'>
            {isGeoLocationSearch && renderCalculatedDistance()}
            <div className='map-location-detail-tile__address-distance-container'>
              <span
                className={cn(
                  'map-location-detail-tile__icon',
                  `map-location-detail-tile__icon--${isPartner ? brand : location_type}`,
                  {
                    'map-location-detail-tile__icon--sold-out': isSoldOutVehicle,
                  }
                )}
              />
              <div className='map-location-detail-tile__address'>
                <p>{address}</p>
                <p>{formatted_city_state_zip}</p>
              </div>
            </div>
          </div>
        </div>
        {isSoldOutVehicle && breakpoint.isMobile && <SoldOutTileBanner />}
        {!isOnMobileAndExpanded && (
          <div
            className={cn({
              'button-wrapper': isOnMobileAndExpanded,
              'map-location-detail-tile__actions-section': !isOnMobileAndExpanded,
              'map-location-detail-tile__show-line': !isOnMobileAndExpanded,
            })}
          >
            {!isPartner ? (
              <Button
                data-dtm-track={utils.analytics.dtm(
                  ANALYTICS.UI_BUTTON,
                  ANALYTICS.LOCATION_ID(id),
                  isSoldOutVehicle ? ANALYTICS.ADJUST : ANALYTICS.SELECT
                )}
                className={buttonClassName}
                onClick={isSoldOutVehicle ? handleAdjustLocation : handleSelectLocation}
                inverse={isSoldOutVehicle}
                onFocus={handleMouseEnter}
                onBlur={handleMouseLeave}
                ariaText={`${
                  isSoldOutVehicle ? utils.i18n('common_adjust') : utils.i18n('map_locations_tile_link_text')
                } ${name}`}
              >
                {isSoldOutVehicle ? utils.i18n('common_adjust') : utils.i18n('map_locations_tile_link_text')}
              </Button>
            ) : (
              <a
                href={locationTileLink || '#'}
                data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.LOCATION_ID(id), 'view_location')}
                className={cn('button', 'map-location-detail-tile__link map-location-detail-tile__partner-button', {
                  'map-location-detail-tile--expanded-mobile-view': isOnMobileAndExpanded,
                })}
                onClick={handlePartnerLinkClick}
              >
                {utils.i18n('map_locations_tile_link_text')}
              </a>
            )}
            {!isOnMobileAndExpanded && (
              <Button
                plain
                data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.LOCATION_ID(id), 'details')}
                onClick={handleExpandTile}
                type='button'
                aria-label={utils.i18n('map_locations_tiles_expand_button')}
                className={cn('link', {
                  'map-location-detail-tile__expand-details--expanded': isInExpandedState,
                  'map-location-detail-tile__expand-details--not-expanded': !isInExpandedState,
                })}
              >
                {utils.i18n('map_locations_tiles_expand_button')}
              </Button>
            )}
          </div>
        )}
      </div>
      {isSoldOutVehicle && !breakpoint.isMobile && <SoldOutTileBanner />}
      {isInExpandedState && (
        <React.Fragment>
          <MapLocationDetailTileExpandedState
            {...props}
            locationOptions={locationOptions}
            getAdditionalInfo={getAdditionalInfo}
            {...locationInfoExpanded}
            isPartner={isPartner}
            locationTileLink={locationTileLink}
          />
          {/* For Accessibility to navigate through the Map Step using a keyboard - to Select CTA Anchored Once Details Section Dropped Down */}
          {isOnMobileAndExpanded && (
            <div className='button-wrapper'>
              {!isPartner ? (
                <Button
                  data-dtm-track={utils.analytics.dtm(
                    ANALYTICS.UI_BUTTON,
                    ANALYTICS.LOCATION_ID(id),
                    isSoldOutVehicle ? ANALYTICS.ADJUST : ANALYTICS.SELECT
                  )}
                  className={buttonClassName}
                  onClick={isSoldOutVehicle ? handleAdjustLocation : handleSelectLocation}
                  inverse={isSoldOutVehicle}
                  ariaText={`${
                    isSoldOutVehicle ? utils.i18n('common_adjust') : utils.i18n('map_locations_tile_link_text')
                  } ${name}`}
                >
                  {isSoldOutVehicle ? utils.i18n('common_adjust') : utils.i18n('map_locations_tile_link_text')}
                </Button>
              ) : (
                <a
                  href={locationTileLink || '#'}
                  data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.LOCATION_ID(id), 'view_location')}
                  className={cn('button', 'map-location-detail-tile__link map-location-detail-tile__partner-button', {
                    'map-location-detail-tile--expanded-mobile-view': isOnMobileAndExpanded,
                  })}
                  onClick={handlePartnerLinkClick}
                >
                  {utils.i18n('map_locations_tile_link_text')}
                </a>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

MapLocationDetailTile.propTypes = {
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  imgSRC: PropTypes.string,
  address: PropTypes.string,
  linkURL: PropTypes.string,
  searchNum: PropTypes.string,
  showX: PropTypes.bool,
  imgAlt: PropTypes.string,
  isPartner: PropTypes.bool,
  breakpoint: PropTypes.object,
  locationInfoById: PropTypes.func,
  locationInfo: PropTypes.object,
  resolveBranchURL: PropTypes.func,
  selectedLocationId: PropTypes.string,
  reservationFlow: PropTypes.bool,
  changeDateTime: PropTypes.func,
};

export default MapLocationDetailTile;
