import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';

const CancellationPolicyInformation = ({ numberOfHoursFullRefund }) => {
  const mainText = numberOfHoursFullRefund
    ? utils.i18n('cancellation_policy_information_body', [numberOfHoursFullRefund])
    : utils.i18n('rental_details_cancellation_description');
  return (
    <div className='rental-details__section rental-details__cancellation-info'>
      <p className='rental-details__section-label'>
        <span>{utils.i18n('rental_details_cancellation_info')}</span>
      </p>
      <p className='rental-details__cancellation-description'>
        <span>{mainText}</span>
      </p>
    </div>
  );
};

CancellationPolicyInformation.propTypes = {
  numberOfHoursFullRefund: PropTypes.number,
};

export default CancellationPolicyInformation;
