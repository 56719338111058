import React from 'react';
import { Routes, Route } from 'react-router-dom-v5-compat';
import utils from 'utils';
import { scrollPage } from 'utils/dom';
import { config } from 'utils/config';
import { initAnalytics } from 'utils/analytics';
import { withRouter } from 'utils/hoc';
import { VIEW_MODIFY_CANCEL_VIEWS, LOOK_UP_BY_TYPE } from 'components/ViewModifyCancelApp/data/constants';
import LookUpByContainer from 'components/ViewModifyCancelApp/components/RentalLookUp/LookUpByContainer';
import UpcomingRentalContainer from 'components/ViewModifyCancelApp/components/UpcomingRental/UpcomingRentalContainer';
import VerifyContainer from 'components/ViewModifyCancelApp/components/Verify/VerifyContainer';
import ManageRentals from 'components/ViewModifyCancelApp/components/ManageRentals/ManageRentalsContainer';
import ConfirmationLookUpByContainer from 'components/ViewModifyCancelApp/components/ConfirmationLookUpBy/ConfirmationLookUpByContainer';
import SessionTimeoutModal from 'components/modals/SessionTimeoutModal';


const ViewModifyCancelFlow = ({ router }) => {
  const pathname = router?.location?.pathname;

  const handleRouteUpdate = () => {
    if (!window.pageXOffset) {
      scrollPage();
    }
    initAnalytics(config.getPageInfo());
  };

  return (
    <utils.OnUpdateWrapper pathname={pathname} onUpdate={() => handleRouteUpdate(pathname)}>
      <Routes>
        <Route
          path={VIEW_MODIFY_CANCEL_VIEWS.PHONE_CONFIRMATION}
          exact
          element={<ConfirmationLookUpByContainer lookupType={LOOK_UP_BY_TYPE.PHONE} />}
        />
        <Route
          path={VIEW_MODIFY_CANCEL_VIEWS.EMAIL_CONFIRMATION}
          exact
          element={<ConfirmationLookUpByContainer isEmail lookupType={LOOK_UP_BY_TYPE.EMAIL} />}
        />
        <Route
          path={VIEW_MODIFY_CANCEL_VIEWS.LOOKUP_BY.RENTAL_BY_PHONE}
          exact
          element={<LookUpByContainer lookupType={LOOK_UP_BY_TYPE.PHONE} />}
        />
        <Route
          path={VIEW_MODIFY_CANCEL_VIEWS.LOOKUP_BY.RENTAL_BY_EMAIL}
          exact
          element={<LookUpByContainer lookupType={LOOK_UP_BY_TYPE.EMAIL} />}
        />
        <Route
          path={VIEW_MODIFY_CANCEL_VIEWS.LOOKUP_BY.RENTAL_BY_CONF_NUMBER}
          exact
          element={<LookUpByContainer lookupType={LOOK_UP_BY_TYPE.CONF_NUMBER} />}
        />
        <Route path={VIEW_MODIFY_CANCEL_VIEWS.UPCOMING} exact element={<UpcomingRentalContainer />} />
        <Route path={VIEW_MODIFY_CANCEL_VIEWS.VERIFY} exact element={<VerifyContainer />} />
        <Route path={VIEW_MODIFY_CANCEL_VIEWS.MY_RENTALS} exact element={<ManageRentals />} />
        <Route
          path={VIEW_MODIFY_CANCEL_VIEWS.CONFIRMED}
          exact
          element={<ConfirmationLookUpByContainer lookupType={LOOK_UP_BY_TYPE.CONF_NUMBER} />}
        />

        <Route path='*' replace element={<UpcomingRentalContainer />} />
      </Routes>
      <SessionTimeoutModal />
    </utils.OnUpdateWrapper>
  );
};

export default withRouter(ViewModifyCancelFlow);
