import PropTypes from 'prop-types';

const propTypes = {
  handleInitiate: PropTypes.func,
  handleClose: PropTypes.func,
  setContractNumber: PropTypes.func,
  setPartnerRewardProgram: PropTypes.func,
  setPartnerRewardProgramMemberId: PropTypes.func,
  modalKey: PropTypes.string,
};

export default propTypes;
