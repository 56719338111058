import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * TabindexLoop Description:
 * creates a loop so that tabbing forward or back locks you in a loop.
 * useful for modals, flyouts, where we don't want the user to accidentally tab into the background
 * if you're going to use this, give the user a way out like a close button or the escape key
 * you should also expect to manually apply focus onto one of the children, such as calling .focus() on an input field within
 *
 * @param {object} props - React Props
 * @param {*} props.children
 * @param {string} props.className - any custom className
 * @param {boolean} props.disabled - will prevent any looping if true
 * @return {JSX}
 */

// targets for our query selector, elements that should receive focus
const focusableElementsSelector =
  '[tabindex], a, button, input, select, textarea, iframe, [contentEditable=true], area';

// remove disabled and negative tabIndex
const focusableFilter = node => !node.disabled && node.tabIndex !== -1;

class TabindexLoop extends Component {
  tabindexLoopRef = React.createRef();

  // query all focusable elements
  // trim first and last because they are the focus guards
  getTabChildren = () =>
    this.tabindexLoopRef.current &&
    Array.prototype.slice
      .call(this.tabindexLoopRef.current.querySelectorAll(focusableElementsSelector), 1, -1)
      .filter(focusableFilter);

  // if the first guard element receives focus, apply focus to the last child
  handleFocusStart = () => {
    const tabChildren = this.getTabChildren();
    tabChildren && tabChildren.length > 1 && tabChildren[tabChildren.length - 1].focus();
  };

  // if the last guard element receives focus, apply focus to the first child
  handleFocusEnd = () => {
    const tabChildren = this.getTabChildren();
    tabChildren && tabChildren.length > 1 && tabChildren[0].focus();
  };

  render() {
    const { children, className, disabled } = this.props;

    return (
      <div
        className={`tabindex-loop tabindex-loop--${disabled ? 'disabled' : 'enabled'} ${className}`}
        ref={this.tabindexLoopRef}>
        {!disabled && <div className='tabindex-loop__start' tabIndex='0' onFocus={this.handleFocusStart} />}
        {children}
        {!disabled && <div className='tabindex-loop__end' tabIndex='0' onFocus={this.handleFocusEnd} />}
      </div>
    );
  }
}

TabindexLoop.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TabindexLoop;
