import React, { useEffect } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { MODAL, MODAL_THEMES } from 'constants';
import ANALYTICS from 'constants/analytics';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { breakpointSelector } from 'selectors/breakpoint';
import RecommendedAlertHeader from 'components/RecommendedAlertHeader';
import GenericNotification from 'components/GenericNotification';
import PropTypes from 'prop-types';
import BundlesDetailsModalContent from '../Modals/BundlesDetailsModalContent';

/**
 * Extras Header:
 * This is the header on the extras page
 *
 * @param {object}  props - React Props
 * @param {object}  props.selectedBundle - the selected bundle that is not default, always returns {} when empty
 * @param {number}  props.numberOfExtrasSelected - number of extras currently selected
 * @param {string}  props.numberOfIncludedExtras - number of included extras
 * @param {boolean} props.isTrueModify - flag for if we are in true modify
 * @param {boolean} props.individualExtraLoading - flag for when extras are loading after adding
 * @param {boolean} props.extrasInvalid - if extras are invalid
 * @param {array}   props.recommendedExtras - array of recommended extras
 * @param {func}    props.onClickContinue - function for going to review page
 * @return {JSX}
 */

const ExtrasHeader = ({
  onClickContinue,
  selectedBundle,
  numberOfExtrasSelected,
  numberOfIncludedExtras,
  hasExtras,
  individualExtraLoading,
  isTrueModify,
  extrasInvalid,
  showRecommendedExtrasBanner,
  scrollToRef,
  isForeignCurrency,
  showConvertedTotalsModal,
}) => {
  const { isMobile } = useSelector(breakpointSelector);
  const handleClick = () => {
    onClickContinue();
  };
  const haveNoAddedExtras = numberOfExtrasSelected === 0 || numberOfExtrasSelected === numberOfIncludedExtras;
  const isSkipExtrasPillBtn = hasExtras && haveNoAddedExtras && !selectedBundle?.name;
  const buttonClass = cn(
    {
      pill: isSkipExtrasPillBtn,
    },
    'button'
  );

  // To ensure assistive technology users or to notify that the page has been updated, within Reservation flow
  const pageStatus = document.getElementById('pageStatus');
  useEffect(() => {
    if (pageStatus) {
      pageStatus.setAttribute('aria-live', 'polite');
      pageStatus.innerText = `${utils.i18n('common_select')} ${utils.i18n('progress_bar_extras_title')}`;
    }

    setTimeout(() => {
      pageStatus.innerText = '';
      pageStatus.setAttribute('aria-live', 'off');
    }, 15000);
  }, [pageStatus]);

  const dataDtmTrack = utils.analytics.dtm(
    ANALYTICS.UI_BUTTON,
    ANALYTICS.EXTRAS,
    isSkipExtrasPillBtn ? ANALYTICS.SKIP : ANALYTICS.CONTINUE
  );

  const i18nButtonText = isSkipExtrasPillBtn
    ? 'extras_continue_review_cta_no_extras_added'
    : 'extras_continue_review_cta_extras_selected';
  return (
    <div className='extras-header__container'>
      <div className='extras-header__row'>
        <div>
          <h1 className='extras-header__main-title'>
            {utils.i18n(isTrueModify ? 'true_modify_extras' : 'extras_main_title')}
          </h1>
          {isForeignCurrency && (
            <p className='extras-header__currency-conversion-message'>
              {utils.i18n('extras_price_disclaimer')}
              <a
                className='extras-header__currency-conversion-message__button link link--text'
                onClick={showConvertedTotalsModal}
              >
                {utils.i18n('extras_view_currency_conversion')}
              </a>
            </p>
          )}
        </div>
        {isMobile && isSkipExtrasPillBtn ? (
          <div className='extras-header__cta'>
            <Button className='extras-header__mobile-btn' linkText data-dtm-track={dataDtmTrack} onClick={handleClick}>
              {utils.i18n(i18nButtonText)}
            </Button>
          </div>
        ) : (
          <Button
            className={buttonClass}
            data-dtm-track={dataDtmTrack}
            disabled={individualExtraLoading}
            loading={individualExtraLoading}
            onClick={handleClick}
          >
            {utils.i18n(i18nButtonText)}
          </Button>
        )}
      </div>

      {extrasInvalid && (
        <GenericNotification
          title={utils.i18n('true_modify_extras_unavailable_title')}
          message={utils.i18n('true_modify_extras_unavailable_message')}
        />
      )}
      {showRecommendedExtrasBanner && <RecommendedAlertHeader scrollToRef={scrollToRef} />}
      <Modal modalKey={MODAL.BUNDLES_DETAILS_MODAL} theme={MODAL_THEMES.WHITE}>
        <BundlesDetailsModalContent />
      </Modal>
    </div>
  );
};

ExtrasHeader.propTypes = {
  selectedBundle: PropTypes.object.isRequired,
  numberOfExtrasSelected: PropTypes.number,
  numberOfIncludedExtras: PropTypes.number,
  hasExtras: PropTypes.bool,
  extrasInvalid: PropTypes.bool,
  showRecommendedExtrasBanner: PropTypes.bool,
  individualExtraLoading: PropTypes.bool,
  isTrueModify: PropTypes.bool,
  onClickContinue: PropTypes.func,
  openRecommendationExtrasModal: PropTypes.func,
  scrollToRef: PropTypes.func,
  vehicleRate: PropTypes.object,
  isForeignCurrency: PropTypes.bool,
  showConvertedTotalsModal: PropTypes.func,
};

export default ExtrasHeader;
