import React from 'react';
import PropTypes from 'prop-types';
import FormattedPrice from 'components/FormattedPrice';

/**
 * Line item of a Rental Rate shown on the Reservation Details section of the Confirmation Screen
 * @param {object} props        - React Props
 * @param {node} props.label    - Label shown on the left side of the line item
 * @param {string} props.price  - Price value shown on the right side of the line item
 * @return {JSX} RentalRateLineItem component
 */
const RentalRateLineItem = ({ label, total }) => (
  <tr className='rental-details__line-item-row'>
    <th align='left' className='rental-details__extra-name'>
      {label}
    </th>
    <td align='right'>
      <FormattedPrice classNames='rental-details__line-item-price'>{total}</FormattedPrice>
    </td>
  </tr>
);

RentalRateLineItem.propTypes = {
  label: PropTypes.node.isRequired,
  total: PropTypes.string.isRequired,
};

export default RentalRateLineItem;
