import { connect } from 'react-redux';
import { closeModal } from "actions/modal/closeModal";
import MissingTripsModal from './MissingTripsModal';

const mapDispatchToProps = {
  closeModal,
};

export default connect(
  null,
  mapDispatchToProps
)(MissingTripsModal);
