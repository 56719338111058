import { connect } from 'react-redux';
import { prepayTerms } from 'selectors/reviewSelectors';
import { getPrepayTerms } from "actions/reservation/getPrepayTerms";
import PrepayTerms from './PrepayTerms';

const mapStateToProps = (state) => ({
  prepayTerms: prepayTerms(state),
});

const mapDispatchToProps = {
  getPrepayTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrepayTerms);
