import { connect } from 'react-redux';
import { actions, utils } from '@ehi/global-marketing-interface';
import { setCarClassFiltersByValue } from 'actions/vehicleGrid';
import appUtils from 'utils';
import { VEHICLE_PATH } from 'reducers/vehicleGrid';
import { numFiltersSelectedFilterSelector, filteredFilterItemsSelector } from 'selectors/uiStateSelectors';
import { filtersSelector } from './vehicleGridViewSelectors';
import VehicleGridView from './VehicleGridView';

const storeKey = 'vehicle-page__grid';

const mapStateToProps = (state) => {
  const breakpoint = state.getIn(['app', 'breakpoint']);
  const numFiltersSelected = numFiltersSelectedFilterSelector(state, storeKey);
  const vehicles = filteredFilterItemsSelector(state, storeKey);
  const gridData = state.getIn(VEHICLE_PATH);
  const categories = gridData.get('categories');
  const ctaLink = gridData.get('ctaLink');

  return {
    breakpoint,
    numFiltersSelected,
    filters: filtersSelector(state) || [],
    vehicles,
    categories,
    isVehicleLandingPage: gridData.get('isVehicleLandingPage'),
    categoryName: utils.get(categories, '0.name') || '',
    ctaLink,
    storeKey,
  };
};

const mapDispatchToProps = {
  setCarClassFiltersByValue,
  clearFiltersUi: () => actions.setFiltersUiByKey(storeKey, []),
};

export default connect(mapStateToProps, mapDispatchToProps)(appUtils.toJS(VehicleGridView));
