import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { setTotalDropdownMobileExpanded } from 'actions/progressBar';
import { MOBILE_PROGRESS_BAR_CONFIG } from 'components/ReservationFlow/reservationFlowRouteUtils';
import ANALYTICS from 'constants/analytics';
import Button from 'components/Button';

const TotalDropDown = ({ match }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatchTotalDropdownExpandedTab = useActions(setTotalDropdownMobileExpanded);
  const reservationFlowRoutes = MOBILE_PROGRESS_BAR_CONFIG.find((item) => item.paths.includes('/review'));

  const handleTotalDrawerToggle = () => {
    // handle analytics for total dropdown
    utils.analytics.interaction(ANALYTICS.DRAWER, 'total price', !isExpanded ? ANALYTICS.OPEN : ANALYTICS.CLOSE);

    setIsExpanded((prevState) => !prevState);
    dispatchTotalDropdownExpandedTab(!isExpanded ? 'total' : null);
  };

  const renderTotalPriceUI = () => {
    if (reservationFlowRoutes) {
      const { ProgressBarLabelComponent, paths } = reservationFlowRoutes;
      const currentStep = match && paths.indexOf(match.path) !== -1;
      return (
        <ProgressBarLabelComponent config={reservationFlowRoutes} currentStep={currentStep} match={match} mobile />
      );
    }
    return null;
  };

  return (
    <div className='header-total-price__container'>
      <Button
        plain
        onClick={handleTotalDrawerToggle}
        aria-label={utils.i18n('progress_bar_total_title')}
        aria-expanded={isExpanded}
        className={cn('header-total-price__title', 'header-total-price__title-draw-icon', {
          'header-total-price__title-draw-icon--open': isExpanded,
        })}
      >
        {`${utils.i18n('progress_bar_total_title').toUpperCase()}:`}
        {reservationFlowRoutes && renderTotalPriceUI()}
      </Button>
    </div>
  );
};

export default TotalDropDown;
TotalDropDown.prototypes = {
  dispatchExpandedTab: PropTypes.func,
  match: PropTypes.object.isRequired,
};
