import React from 'react';
import { useSelector } from 'react-redux';
import { preSelectedMessagesSelector } from 'selectors/reservationSelectors';
import { hasGmaErrorMessagesSelector } from 'selectors/errorSelectors';

/**
 * PreSelectedMessages
 *
 * @return {JSX}
 */

const PreSelectedMessages = () => {
  const preSelectedMessages = useSelector(preSelectedMessagesSelector);
  const hasGmaErrorMessages = useSelector(hasGmaErrorMessagesSelector);

  return (
    !!preSelectedMessages &&
    !hasGmaErrorMessages && (
      <div className='service-errors'>
        {preSelectedMessages?.map((message, index) => (
          <div key={`${message?.code}_${index}`} className='service-error'>
            <p>{message?.message}</p>
          </div>
        ))}
      </div>
    )
  );
};

export default PreSelectedMessages;
