import React from 'react';
import utils from 'utils';

const GenericDriverDetailsRow = ({ driverDetails, fromPostRes }) => {
  const { issuedBy, license_profile, phoneData, address_profile } = driverDetails;

  const { license_expiration_date, license_issue_date, license_number, birth_date } = license_profile;

  const renderDetailLine = (labelKey, value) => (
    <p className='check-in-review__paragraph'>
      {utils.i18n(labelKey)}: {value}
    </p>
  );

  return (
    <>
      <div className='post-res-trip-summary__drawer-section'>
        <p className='post-res__subheading'>{utils.i18n('common_drivers_license_details')}</p>

        {renderDetailLine('common_drivers_license_issued_by', `${issuedBy}`)}
        {license_number && renderDetailLine('common_drivers_license_number', license_number)}
        {license_expiration_date && renderDetailLine('common_drivers_license_expiration_date', license_expiration_date)}
        {license_issue_date && renderDetailLine('my_profile_drivers_license_issue_date', license_issue_date)}
        {birth_date && renderDetailLine('common_date_of_birth', birth_date)}
      </div>

      {!fromPostRes && (
        <div className='post-res-trip-summary__drawer-section'>
          <p className='post-res__subheading'>{utils.i18n('common_contact_details')}</p>

          {renderDetailLine('common_phone_number', phoneData?.phone_number)}
          {renderDetailLine('common_address', address_profile?.street_addresses?.join(' '))}
          {renderDetailLine('common_country_of_residence', address_profile?.country_name)}
        </div>
      )}
    </>
  );
};

export default GenericDriverDetailsRow;
