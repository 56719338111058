import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import utils from 'utils';

/**
 * CouponNavigationBanner Description
 *
 * @param {object}   props - React Props
 * @param {array}    props.coupons - array of coupons from reservation object
 * @param {func}     props.openRemoveCouponModal - opens REMOVE_COUPON_MODAL
 * @param {boolean}  props.isProductCode - Indicates when the CouponNavigationBanner is being used to show the product code
 * @param {function} props.setProductCode - Set the product code into the redux state
 * @param {function} props.clearDiscountInReservationFlow - dispatch function to clear discount using deeplinks
 * @param {function} props.closeModal - dispatch method for closing currently opened modal
 * @return {JSX}
 */

const CouponNavigationBanner = ({
  coupons,
  openRemoveCouponModal,
  openRemoveProductCodeModal,
  isProductCode,
  setProductCode,
  clearDiscountInReservationFlow,
  closeModal,
}) => {
  const coupon = coupons[0].description;
  const label = isProductCode ? `${utils.i18n('product_code_label')} (${coupon})` : coupon;

  const onConfirmRemoveProductCode = () => {
    setProductCode('');
    closeModal({ analyticsValue: utils.i18n('progress_bar_cid_remove_modal_accept') });
    clearDiscountInReservationFlow({ product_code: true });
  };

  const openModalWithDescription = () =>
    isProductCode ? openRemoveProductCodeModal(coupon, onConfirmRemoveProductCode) : openRemoveCouponModal(coupon);

  return (
    <div className='progress-bar-cid__container'>
      <div className='progress-bar-cid__label-container'>
        <p className='progress-bar-cid__contract-label discount-notification--price-tag'>
          <span className='progress-bar-cid__ellipsis-wrapper'>{label}</span>
        </p>

        <Button
          className='progress-bar-cid__remove-cid'
          ariaText={utils.i18n('progress_bar_remove_coupon_aria')}
          onClick={openModalWithDescription}
          link
        />
      </div>
    </div>
  );
};

CouponNavigationBanner.propTypes = {
  coupons: PropTypes.array.isRequired,
  openRemoveCouponModal: PropTypes.func.isRequired,
  isProductCode: PropTypes.bool,
  openRemoveProductCodeModal: PropTypes.func,
  setProductCode: PropTypes.func,
  clearDiscountInReservationFlow: PropTypes.func,
  closeModal: PropTypes.func,
};

export default CouponNavigationBanner;
