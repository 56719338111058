import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';

/**
 * UpgradeTotalCopy - Reused total copy across InPathUpgrade banners
 *
 * @param {object}    props - React Props
 * @param {string}    props.total - total formatted cost after upgrade
 * @return {JSX}      p jsx element with InPathUpgrade styles and copy
 */
const UpgradeTotalCopy = ({ total }) => (
  <p className='in-path-upgrade__new-total'>{utils.i18n('in_path_vehicle_upgrade_total_copy', [total])}</p>
);

UpgradeTotalCopy.propTypes = {
  total: PropTypes.string,
};

export default UpgradeTotalCopy;
