import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getCurrentSpaPageTitle } from 'utils/url';
import Anchor from 'components/Anchor';

/**
 * Breadcrumb
 *
 * This component is intended to mirror the GMC core HTL `breacrumb.html` component. The use case is for
 * page's that want react (via model.json) to control the breadcrumb's rendering... like a SPA page. The
 * enhancement here is that the last item in the breadcrumb (the current/active page) will be replaced
 * by the current page's hash (React Router Route), if present.
 *
 * @param {object} props - react props
 * @param {object[]} props.items - this array of objects is passed down to the component by AEM's MapTo
 *                                 function, feeding dynamic content from the page's model.json. The shape is like this:
 *                                 {
 *                                   active: {boolean} - Does the current link represent the current page
 *                                   title: {string} - The current link's breadcrumb title
 *                                   url: {string} - The current link's URL
 *                                 }
 *
 * @return {JSX} the JSX for the breadcrumb or false if no data is found.
 */
const Breadcrumb = ({ items }) => {
  const [activePageTitleFromHash, setActivePageTitleFromHash] = useState(getCurrentSpaPageTitle());

  // "On Mount" add a hashchange handler to update the slug when the hash changes.
  useEffect(() => {
    window.addEventListener('hashchange', () => setActivePageTitleFromHash(getCurrentSpaPageTitle()), false);
  }, []);

  // Loop over the items to render the breadcrumbs.
  return (
    <ul>
      {items?.map(({ active, title, url }) => (
        <li
          key={title}
          className={cn('breadcrumb__item', {
            breadcrumb__separator: !active,
            'breadcrumb__active-item': active,
          })}>
          {active ? (
            // the "current/active" page uses the hash/spa path for tile, but falls back to breadcrumb data
            activePageTitleFromHash || title
          ) : (
            <Anchor href={url}>{title}</Anchor>
          )}
        </li>
      ))}
    </ul>
  );
};

Breadcrumb.propTypes = {
  // This comes from AEM's `MapTo`...
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default Breadcrumb;
