import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import Button from 'components/Button';
import Anchor from 'components/Anchor';

/**
 * EmailUnsubscribeConfirmation
 *
 * @return {JSX}
 */
const EmailUnsubscribeConfirmation = ({ email, onUnsubscribe }) => (
  <>
    {email && (
      <p className='email-unsubscribe__paragraph'>
        {utils.i18n('email_unsub_email_copy', [<strong key='email_unsub'>{email}</strong>], {
          jsx: true,
        })}
      </p>
    )}

    <div className='email-unsubscribe__button-holder'>
      <Button onClick={onUnsubscribe} disabled={!email} data-track-dtm='email_unsubscribe|start|unsubscribe'>
        {utils.i18n('email_unsub_confirm_cta')}
      </Button>
    </div>

    <p className='email-unsubscribe__paragraph'>
      {utils.i18n(
        'email_unsub_close_copy',
        [
          <Anchor
            href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.HOME_PAGE_URL)}
            className='email-unsubscribe__link'
            key='email_unsub_home_link'
            data-track-dtm='email_unsubscribe|start|go_home'>
            {utils.i18n('email_unsub_go_home_link')}
          </Anchor>,
        ],
        {
          jsx: true,
        }
      )}
    </p>
  </>
);

EmailUnsubscribeConfirmation.propTypes = {
  email: PropTypes.string,
  onUnsubscribe: PropTypes.func,
};

export default EmailUnsubscribeConfirmation;
