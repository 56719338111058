import React from 'react';
import Button from 'components/Button';
import { i18n } from 'utils/i18n';
import { dtm } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import cn from 'classnames';
import keyFactsOptionPropTypes from './keyFactsOptionPropTypes';

const KeyFactsOption = ({ clickHandler, title, modalKey }) => (
  <Button
    plain
    className={cn('key-facts-option__container', 'component-theme--light')}
    aria-label={`${!!title && i18n(title)}`}
    onClick={clickHandler(modalKey)}
    data-dtm-track={dtm(ANALYTICS.CHECK_IN, ANALYTICS.UI_HEADER, `${title}`)}
  >
    <span className='key-facts-option__main-content'>
      {!!title && <span className='key-facts-option__title'>{i18n(title)}</span>}
    </span>
  </Button>
);

KeyFactsOption.propTypes = keyFactsOptionPropTypes;

export default KeyFactsOption;
