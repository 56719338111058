import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { isObject } from 'utils/object';
import { analyticsClearErrors, analyticsError, analyticsDispatchErrorEvent, analyticsFormKey } from 'utils/analytics';
import { ANALYTICS } from 'constants';

const focusOnError = createDecorator();

/**
 * This component is a wrapper/abstraction of React Final Form's (RFF) `<Form>` component. This abstraction
 * provides a method through which we can intercept any of the values passed to `<Form>` OR its render
 * prop (etc) to inject our own logic.
 *
 * One example/use case for this is around analytics. Front-end errors should fire _every time_ the
 * user hits the submit button. In order to do this this we need to bind to the `<form>`
 * (lowercase `f` there) `onSubmit` handler. While RFF offers FromSpys, there is no way to track
 * events/interactions, so this is a great use case to utilize a wrapper to augment the`handleSubmit`
 * passed to the render prop by <Form>.
 *
 * The use of this component is intended to be _exactly_ the same as RFF's <Form>! So instead of importing
 * `from 'react-final-form'` you just use the same code but import form `components/Form/Form`. This
 * still supports all RFF <Form> props and functionality but layers on a number of additional props that
 * can be used for things like analytics (outlined above)
 *
 * Analytics Notes:
 * It is worth noting that the analytics code will only work if the <form> being implemented passes `handleSubmit`
 * to the `<form>` `onSubmit` prop.
 *
 * @param {object} props -
 * @param {boolean} props.analyticsDisable - If you want to disable the analytics logic for some reason
 *                                           set this flag to true, and _dont_ pass in an analyticsErrorCallback
 * @param {function} props.analyticsErrorCallback - A custom callback function to call instead of the default
 *                                                  analytics error code. This is called when the normal
 *                                                  analytics function would have been called. It gets passed
 *                                                  the current `formState`.
 * @param {string} props.children - the render prop passed to the component
 * #param {func}   props.onEveryInvalidSubmit - handler to run every time user submits and form is invalid
 * @param {func}   props.onNewInvalidSubmit - handler to run anytime user submits with new values and form is invalid
 *
 * @return {JSX} A React Final Form form
 */
const FormWrapper = forwardRef(
  ({ analyticsDisable, analyticsErrorCallback, children, onEveryInvalidSubmit, onNewInvalidSubmit, ...props }) => {
    const [valueChangedSinceLastSubmit, setValueChangedSinceLastSubmit] = useState(false);
    const [inputsState, setInputsState] = useState({
      initialValues: null,
      dirtyInputs: [],
    });

    // This function wraps the `handleSubmit` that gets passed to the render prop and subsequently the `<form>`
    // `onSubimt` handler.
    const onSubmitHandlerWrapper =
      (handleSubmit, form) =>
      (...args) => {
        const formState = form.getState();
        const { invalid } = formState;
        // Code to run if the form is invalid when submitted
        if (invalid && onEveryInvalidSubmit) {
          onEveryInvalidSubmit(formState);
        }

        // Code to run if the form is invalid and there was a change since the last time the form was attempted to
        // be submitted.
        if (invalid && valueChangedSinceLastSubmit) {
          if (onNewInvalidSubmit) {
            onNewInvalidSubmit();
          }

          // Custom callback to override default behavior
          if (analyticsErrorCallback) {
            analyticsErrorCallback(formState);
          }
          // Otherwise fire off a generic error
          else if (!analyticsDisable) {
            // This method is meant to be run on invalid form submits to update analytics about form field errors
            const { errors } = formState;
            // Clears errors from any previous submit
            analyticsClearErrors();
            // Loop over each form section and each error inside and save to analytics error object.
            Object.entries(errors).forEach(([sectionErrorKey, sectionErrorValue]) => {
              /**
               * object example:
               * sectionErrorKey = "driver_info"
               * sectionErrorValue = {
               *    "first_name": "Required",
               *    "last_name": "Required",
               *    "email_address": "Required"
               * }
               *
               * string value example:
               * sectionErrorKey = "returnDate"
               * sectionErrorValue = "Choose a return date."
               */

              const handleSimpleError = (errorKey, errorValue) => {
                analyticsError(window.location.href, ANALYTICS.FE_FORM_ERROR(analyticsFormKey(errorKey)), errorValue, {
                  skipCustomEvent: true,
                });
              };

              const handleErrorsObject = (errorsObject, keyPrefix) => {
                Object.entries(errorsObject).forEach(([formErrorKey, formErrorValue]) => {
                  handleSimpleError(`${keyPrefix}_${formErrorKey}`, formErrorValue);
                });
              };

              const handleErrorsArray = (errorsArray, keyPrefix) => {
                errorsArray.forEach((formErrorValue, idx) => {
                  if (isObject(formErrorValue)) {
                    handleErrorsObject(formErrorValue, `${keyPrefix}[_${idx}]`);
                  } else if (formErrorValue !== undefined) {
                    handleSimpleError(`${keyPrefix}[_${idx}]`, formErrorValue);
                  }
                });
              };

              if (isObject(sectionErrorValue)) {
                handleErrorsObject(sectionErrorValue, sectionErrorKey);
              } else if (Array.isArray(sectionErrorValue)) {
                // Handles loyalty sign up, because it puts the values into an array, so we take the value
                handleErrorsArray(sectionErrorValue, sectionErrorKey);
              } else {
                handleSimpleError(sectionErrorKey, sectionErrorValue);
              }
            });

            setTimeout(() => {
              analyticsDispatchErrorEvent();
            }, 500);
          }

          // This is the magic that allows the _real_ "onDirtySumbit" to work. We use the FormSpy to track when
          // the form values change (we dont really care what it changes to!) and then clear that value out when a
          // submission was attempted. Thus a new change will indicate the form is "dirty since last submission".
          setValueChangedSinceLastSubmit(false);
        }

        // This is required to maintain RFF's default behavior. This is what would happen if we did not "interfere"
        handleSubmit(...args);
      };

    // This is the function we want the `values` FormSpy to run whenever the values change... so basically this
    // means that the form's values have changed and the form state is "dirty" since the last submission!
    const handleValuesChange = (data) => {
      try {
        const { getDirtyInputs } = props;
        if (!inputsState.initialValues) {
          setInputsState({ ...inputsState, initialValues: data?.values || {} });
        }

        if (inputsState.initialValues && data.values && getDirtyInputs) {
          const newInitialValues = { ...inputsState.initialValues };
          const newDirtyInputs = [...inputsState.dirtyInputs];
          Object.entries(data.values).forEach(([key, value]) => {
            const isDirty = newDirtyInputs.includes(key);
            if (value !== newInitialValues[key] && !isDirty) {
              newDirtyInputs.push(key);
              delete newInitialValues[key];
            }
          });

          setInputsState({ initialValues: newInitialValues, dirtyInputs: newDirtyInputs });
          getDirtyInputs(newDirtyInputs);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setValueChangedSinceLastSubmit(true);
      }
    };

    return (
      <Form {...props} decorators={props.decorators || [focusOnError]}>
        {({ handleSubmit, ...args }) => (
          <>
            {children({
              handleSubmit: onSubmitHandlerWrapper(handleSubmit, args.form),
              ...args,
            })}
            {/*  hack for handleChange to get called  before render, referred from here https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
             */}
            <FormSpy
              onChange={(data) => setTimeout(() => handleValuesChange(data), 0)}
              subscription={{ values: true }}
            />
          </>
        )}
      </Form>
    );
  }
);

FormWrapper.propTypes = {
  analyticsDisable: PropTypes.bool,
  analyticsErrorCallback: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onEveryInvalidSubmit: PropTypes.func,
  onNewInvalidSubmit: PropTypes.func,
};

export default FormWrapper;
