import { connect } from 'react-redux';
import utils from 'utils';
import { breakpointSelector, sessionReservationObjectSelector, getModifyFlag } from 'selectors';
import { reservationCouponsSelector } from 'selectors/reservationFlowSelectors';
import {
  isLastMinuteSpecialsReservationSelector,
  isPlanAheadSpecialsReservationSelector,
} from 'selectors/reservationSelectors';
import { profileDataSelector } from 'selectors/profileSelectors';
import { getUiInitiateState } from 'selectors/uiStateSelectors';
import {
  gmaBrandInformationSelector,
  isRestrictedReservationSelector,
  isCustomPathReservationSelector,
  virginBrandInformationSelector,
  isVirginReservationSelector,
} from 'selectors/customPathCheckInSelectors';
import { showDiscardReservationModal } from "actions/modal/showDiscardReservationModal";
import Header from './Header';

const mapStateToProps = (state) => {
  const reservationObj = sessionReservationObjectSelector(state);
  const profileData = profileDataSelector(state, { partnerRewardsProgram: true });

  const confirmationNumber = reservationObj?.get('confirmation_number');
  const reservationStatus = reservationObj?.get('reservation_status');
  const contractDetails = reservationObj?.get('contract_details');
  const partner_reward_program = reservationObj?.get('partner_reward_program');
  // CID should not be shown in nav bar if it is attached to Alamo insider account
  const showContractDetails = contractDetails?.get('default_loyalty_contract') === false;
  const showPartnerRewards = partner_reward_program?.get('code') !== profileData?.partnerRewardsProgram?.code;
  const initiate = getUiInitiateState(state);

  return {
    productCode: initiate?.product_code,
    contract_details: showContractDetails ? contractDetails : null,
    partner_reward_program: showPartnerRewards ? partner_reward_program : null,
    breakpoint: breakpointSelector(state),
    isTrueModify: getModifyFlag(state),
    coupons: reservationCouponsSelector(state),
    confirmationNumber,
    isLastMinuteSpecials: isLastMinuteSpecialsReservationSelector(state),
    isPlanAheadSpecials: isPlanAheadSpecialsReservationSelector(state),
    gmaBrandInformation: gmaBrandInformationSelector(state),
    isRestrictedReservation: isRestrictedReservationSelector(state),
    isReservationCustomPath: isCustomPathReservationSelector(state),
    virginBrandInformation: virginBrandInformationSelector(state),
    isVirginReservation: isVirginReservationSelector(state),
    reservationStatus,
  };
};

const mapDispatchToProps = {
  showDiscardReservationModal: () => showDiscardReservationModal({ headerRedirect: true }),
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(Header));
