import { connect } from 'react-redux';
import { breakpointSelector } from 'selectors';
import DatePicker from './DatePicker';

const mapStateToProps = state => ({
  breakpoint: breakpointSelector(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePicker);
