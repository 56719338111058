import { connect } from 'react-redux';
import utils from 'utils';
import { VEHICLE_LIST, setVehiclesFilterData } from 'actions/vehicleClass';
import { getAllVehiclesListSelector } from 'selectors/vehicleSelectSelectors';
import VehicleClassFilterModal from './VehicleClassFilterModal';

const mapStateToProps = state => {
  const vehicleCategoriesInitialValues = getAllVehiclesListSelector(state);
  return {
    vehicle_categories: vehicleCategoriesInitialValues || [],
  };
};

const mapDispatchToProps = dispatch => ({
  searchbyVehicleFilters: vehicleData => {
    dispatch(setVehiclesFilterData(VEHICLE_LIST.SET_VEHICLE_CLASS_FILTER_DATA, vehicleData));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(VehicleClassFilterModal));
