import React, { useState } from 'react';
import utils from 'utils';
import cn from 'classnames';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import CUSTOM_PATH from 'constants/customPathCheckIn';
import ANALYTICS from 'constants/analytics';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import noCounter from 'images/no-counter.svg';
import exitGate from 'images/exit-gate.svg';
import ConfirmationAnimation from 'components/ConfirmationAnimation';

const { STC_ORIGIN, STC_PATH_CONFIG } = SKIP_THE_COUNTER;
const { CUSTOM_PATH_CONFIG } = CUSTOM_PATH;

const SkipTheCounterPickupHeaderStatus = ({
  isCustomPathReservation,
  isCustomPathEnabled,
  isCheckedIn,
  counterByPassExternalLink,
  isStcBridgePage = false,
  skipTheCounterOrigin,
  skipTheCounterStart,
  isSkipTheCounterCompleted,
  isSkipTheCounterEligible,
  isNonVirginTourReservation,
  breakpoint,
  customPathResWithPendingConfirmation,
}) => {
  const openDrawerByDefault = (isNonVirginTourReservation || isStcBridgePage) && !breakpoint.isTabletOrMobile;
  const [showMoreDrawerExpanded, setShowMoreDrawerExpanded] = useState(openDrawerByDefault);

  const handleShowmoreToggleExpanded = () => {
    setShowMoreDrawerExpanded(!showMoreDrawerExpanded);
    utils.analytics.interaction(ANALYTICS.DRAWER, 'stc', showMoreDrawerExpanded ? 'show less' : 'show more');
  };

  const maybeLaterHandler = () => {
    window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RENTAL_DETAILS));
  };

  const handleSkipTheCounterStart = () => {
    if (isStcBridgePage) {
      skipTheCounterStart(STC_ORIGIN.ONLINE_CHECK_IN_FLOW);
    } else {
      skipTheCounterStart(skipTheCounterOrigin);
    }
  };

  const stcActionPrintTicket = () => {
    utils.loadingRedirect(
      WINDOW_OBJECT_KEYS[isCustomPathReservation ? 'CUSTOM_PATH_FLOW' : 'SKIP_THE_COUNTER_FLOW'],
      true,
      isCustomPathReservation ? CUSTOM_PATH_CONFIG.confirmation[0] : STC_PATH_CONFIG.confirmation[0]
    );
    // analytics interaction for stc view ticket
    utils.analytics.interaction(ANALYTICS.UI_BUTTON, 'stc', 'view ticket');
  };

  let titleText;
  let stcTitleDescription;

  if (isCheckedIn && isSkipTheCounterEligible) {
    if (customPathResWithPendingConfirmation) {
      titleText = utils.i18n('trip_card_skip_the_counter_not_eligible_description');
    } else {
      titleText = utils.i18n('trip_card_skip_the_counter_eligible_description');
      stcTitleDescription = utils.i18n('confirmation_stc_show_more_eligible_info');
    }
  } else if (isCheckedIn && !isSkipTheCounterEligible) {
    titleText = utils.i18n('trip_card_skip_the_counter_not_eligible_description');
  } else {
    titleText = utils.i18n('trip_card_skip_the_counter_description');
    stcTitleDescription = utils.i18n('confirmation_stc_show_more_info');
  }

  return (
    <div
      className={cn('confirmation-oci-status__section-stc-pickup-status-block', {
        'stc-only': isNonVirginTourReservation && breakpoint.isDesktop,
        'confirmation-oci-status__no-stc':
          (!isSkipTheCounterEligible || customPathResWithPendingConfirmation) && isCheckedIn,
      })}
    >
      <div className={cn({ 'confirmation-oci-status__oci-image-wrapper': !isSkipTheCounterEligible && isCheckedIn })}>
        <ConfirmationAnimation
          className={'confirmation-oci-status__oci-image'}
          srcImage={isSkipTheCounterCompleted ? exitGate : noCounter}
          disabledAnimationEffect={!isSkipTheCounterCompleted}
        />
      </div>
      <div className='confirmation-oci-status__title'>
        {!isNonVirginTourReservation && !isSkipTheCounterCompleted && (
          <p className='confirmation-oci-status__title--step-indicator'>{2}</p>
        )}
        <span className='confirmation-oci-status__title-text'>{utils.i18n('skip_the_counter_flow_title')}</span>
        {/* Skipping the counter  not Completed but OCI done */}
        {!isSkipTheCounterCompleted && (
          <div
            className={cn('confirmation-oci-status__info', {
              'non-tour-oci-stc-flow': isNonVirginTourReservation && breakpoint.isDesktop,
            })}
          >
            <div
              className={cn({
                'confirmation-oci-status__info-inline-container': isNonVirginTourReservation && breakpoint.isDesktop,
              })}
            >
              <div className='confirmation-oci-status__info-inline'>
                <p className='confirmation-oci-status__text'>{titleText}</p>
              </div>

              {(!isSkipTheCounterEligible || customPathResWithPendingConfirmation) && isCheckedIn ? (
                <div className={cn('confirmation-oci-status__show-more', { visible: showMoreDrawerExpanded })}>
                  <div className='confirmation-oci-status__info-inline'>
                    <p className='confirmation-oci-status__text'>
                      {utils.i18n('confirmation_stc_show_more_ineligible_list_description')}
                    </p>
                  </div>
                  <ul className='confirmation-oci-status__show-more-info-list'>
                    <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_1')}</li>
                    <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_2')}</li>
                    <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_3')}</li>
                    <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_4')}</li>
                    <li>{utils.i18n('confirmation_stc_show_more_ineligible_item_5')}</li>
                  </ul>
                </div>
              ) : (
                <div className={cn('confirmation-oci-status__show-more', { visible: showMoreDrawerExpanded })}>
                  <div className='confirmation-oci-status__info-inline'>
                    <p className='confirmation-oci-status__text'>{stcTitleDescription}</p>
                    <p className='confirmation-oci-status__show-more__disclaimer'>
                      {utils.i18n('check_in_stc_bridge_instructions_title')}
                    </p>
                  </div>
                  <ul className='confirmation-oci-status__show-more-info-list'>
                    <li>{utils.i18n('confirmation_stc_show_more_item_1')}</li>
                    <li>{utils.i18n('confirmation_stc_show_more_item_2')}</li>
                    <li>{utils.i18n('confirmation_stc_show_more_item_3')}</li>
                  </ul>
                </div>
              )}

              <Button
                plain
                type='button'
                onClick={handleShowmoreToggleExpanded}
                className={cn('link confirmation-oci-status__drawer-label', { expanded: showMoreDrawerExpanded })}
              >
                {!showMoreDrawerExpanded ? utils.i18n('common_show_more') : utils.i18n('common_show_less')}
              </Button>
            </div>

            {/* Skip the Counter eligible and checked in */}
            {isSkipTheCounterEligible && !customPathResWithPendingConfirmation && isCheckedIn && (
              <div
                className={cn({
                  'confirmation-oci-status__non-tour-oci-stc-container': isNonVirginTourReservation,
                })}
              >
                {isCustomPathReservation && !isCustomPathEnabled ? ( // If custom path feature flag is off, send to WNG
                  <Anchor
                    className='button confirmation-oci-status__skip-counter-external-link'
                    href={counterByPassExternalLink.link}
                    isExternal={true}
                  >
                    {utils.i18n('trip_card_skip_the_counter')}
                  </Anchor>
                ) : (
                  <Button
                    className='confirmation-oci-status__skip-counter-button'
                    onClick={handleSkipTheCounterStart}
                    data-dtm-track='check_in|complete|skip_counter'
                  >
                    {utils.i18n('trip_card_skip_the_counter')}
                  </Button>
                )}
                {isStcBridgePage && (
                  <Button
                    link
                    linkText
                    className='confirmation-oci-status__skip-counter-no-thanks-button'
                    onClick={maybeLaterHandler}
                    data-dtm-track='check_in|complete|skip_counter_later'
                  >
                    {utils.i18n('common_no_thanks')}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}

        {/* Skipping the Counter  Completed */}
        {isSkipTheCounterCompleted && !isCheckedIn && (
          <div className='confirmation-oci-status__info'>
            <div className='confirmation-oci-status__info-inline'>
              <p className='confirmation-oci-status__text'>{utils.i18n('trip_card_skip_the_counter_instructions')}</p>
            </div>
            {/* Let user go to STC confirmation if the flag is on */}
            {(!isCustomPathReservation || (isCustomPathReservation && isCustomPathEnabled)) && (
              <Button
                onClick={stcActionPrintTicket}
                className='confirmation-oci-status__action-button'
                data-dtm-track={utils.analytics.dtm(
                  isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.RETAIL,
                  ANALYTICS.CONFIRMATION,
                  'view_pickup_instructions'
                )}
              >
                {utils.i18n('stc_view_ticket')}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SkipTheCounterPickupHeaderStatus;
