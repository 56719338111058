import React, { Component, Fragment } from 'react';
import utils from 'utils';
import cn from 'classnames';
import Button from 'components/Button';
import propTypes from './bundlesDetailsModalContentPropTypes';

export default class BundlesDetailsModalContent extends Component {
  static propTypes = propTypes;

  state = {
    expanded: null,
  };

  handleExpandDrawer = code => () => {
    this.setState(state => {
      const expanded = state.expanded === code ? null : code;
      return { expanded };
    });
  };

  renderItem = ({ code, name, detailed_description }) => {
    const { expanded } = this.state;

    return (
      <Fragment key={code}>
        <Button
          button={false}
          type='button'
          ariaExpanded={expanded === code ? 'true' : 'false'}
          className={cn('modal-themed__drawer bundles-details-modal__drawer', {
            'modal-themed__drawer--expanded': expanded === code,
          })}
          onClick={this.handleExpandDrawer(code)}>
          {name}
        </Button>
        {expanded === code ? (
          <div
            className='modal-themed__section modal-themed__gbo-content'
            dangerouslySetInnerHTML={utils.sanitize(detailed_description)}
          />
        ) : null}
      </Fragment>
    );
  };

  render() {
    const { description, extras, bundledAdditionalDrivers, additionalDriversPolicy } = this.props;

    return (
      <div className='modal-themed__section bundles-details-modal'>
        {description && (
          <div className='bundles-details-modal__description' dangerouslySetInnerHTML={utils.sanitize(description)} />
        )}
        <div className='modal-themed__drawer-container'>
          {extras && extras.map(this.renderItem)}
          {bundledAdditionalDrivers &&
            this.renderItem({
              code: 'addt_drv',
              name: utils.i18n('bundles_card_additional_drivers', [bundledAdditionalDrivers]),
              detailed_description: additionalDriversPolicy?.policy_text,
            })}
        </div>
      </div>
    );
  }
}
