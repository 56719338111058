import React from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import ASSETS from 'constants/assets';
import ANALYTICS from 'constants/analytics';
import Button from 'components/Button/Button';
import Picture from 'components/Picture';

/**
 * PastTripsEmpty
 * will be displayed when user has no past trips
 * @param {object} props - React Props
 * @param {array} props.helpDisclaimer - Text/Link element for Customer Support CTA disclaimer
 * @param {function} props.openMissingTripsModal - opens MISSING_TRIPS_MODAL
 * @return {JSX} PastTripsEmpty component
 */
const PastTripsEmpty = ({ helpDisclaimer, openMissingTripsModal }) => (
  <div className='past-trips-empty'>
    <Picture
      srcMobile={ASSETS.NO_PAST_TRIPS_1}
      srcTablet={ASSETS.NO_PAST_TRIPS_2}
      src={ASSETS.NO_PAST_TRIPS_3}
      pictureClassName={'past-trips-empty__image'}
      isDecorative
    />
    <div className='past-trips-empty__content'>
      <h3 className='past-trips-empty__title'>{utils.i18n('past_trips_no_trips')}</h3>
      <p className='past-trips-empty__disclaimer past-trips-empty__disclaimer--wider'>
        {utils.i18n('past_trips_disclaimer_1')}
      </p>

      <Button
        onClick={openMissingTripsModal}
        button
        data-dtm-track={utils.analytics.dtm(ANALYTICS.MY_TRIPS, 'missing_trip', ANALYTICS.SELECT)}>
        {utils.i18n('my_trips_missing_a_trip_cta')}
      </Button>

      <hr />

      <p className='past-trips-empty__disclaimer'>{helpDisclaimer}</p>
    </div>
  </div>
);

PastTripsEmpty.propTypes = {
  helpDisclaimer: PropTypes.array,
  openMissingTripsModal: PropTypes.func,
};

export default PastTripsEmpty;
