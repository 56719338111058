import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import utils from 'utils';
import { MODAL } from 'constants';
import {
  selectedBundleWithExtrasSelector,
  extrasWithoutBundlesSelector,
  numberOfExtrasSelector,
  vehicleRateSelector,
  cancelFeePrePayTypeSelector,
  isDestinationCurrencySelected,
} from 'selectors';
import { selectedCarClassCoupons } from 'selectors/reservationFlowSelectors';
import { updateVehicleRatesSelectors } from 'selectors/currencyToggleSelectors';
import { couponContractDetailsSelector } from 'selectors/reservationSelectors';
import { setProgressBarLocationInitiateState } from 'actions/progressBar';
import { breakpointSelector } from 'selectors/breakpoint';
import { fetchCurrentSession } from 'actions/session';
import { getAfterHoursPolicy } from "actions/reservation/getAfterHoursPolicy";
import { openModal } from "actions/modal/openModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import ReviewRentalDetails from './ReviewRentalDetails';

const mapStateToProps = (state) => {
  let modifyVehicleRates = vehicleRateSelector(state);
  const isDestinationCurrency = isDestinationCurrencySelected(state);
  if (isDestinationCurrency && modifyVehicleRates) {
    modifyVehicleRates = updateVehicleRatesSelectors(modifyVehicleRates.toJS());
  }

  return {
    // TODO: Inefficient to pass reservation state along with extras and vehicleRate. Come up with selector to deal with this
    reservation: state.getIn(['gmi', 'session', 'gbo', 'reservation']),
    vehicleRate: modifyVehicleRates,
    prepayReservationsError: state.getIn(['gmi', 'messages', 'gma_services', 'reservations/commit']) || null,
    cancellation_details: cancelFeePrePayTypeSelector(state),
    // selectedBundleWithExtrasSelector only returns a bundle if it's selected and not default (e.g. 'Fully')
    selectedBundleWithExtras: selectedBundleWithExtrasSelector(state),
    extrasWithoutBundles: extrasWithoutBundlesSelector(state),
    numberOfExtrasSelected: numberOfExtrasSelector(state),
    coupons: selectedCarClassCoupons(state),
    breakpoint: breakpointSelector(state),
    reservationContractAndCoupons: couponContractDetailsSelector(state),
  };
};

const mapDispatchToProps = {
  showViewDetailsModal: () => openModal(MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL),
  showLearnMoreModal: () => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL),
  showAHViewDetailsModal: () => openModal(MODAL.RENTAL_DETAILS_AFTER_HOURS_MODAL),
  showModifyModal: () => openModal(MODAL.MODIFY_RESERVATION),
  showEditModal: (sectionPath) => openModalWithProps(MODAL.PREPAY_EDIT_MODAL, { sectionPath }),
  getAfterHoursPolicy,
  fetchCurrentSession,
  setProgressBarLocationInitiateState,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(utils.toJS(ReviewRentalDetails)));
