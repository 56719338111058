import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import { analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import RadioGroup from 'components/Form/RadioGroup';
import './styles/sort.scss';

const Sort = ({
  buttons,
  updateSort,
  hideTheme,
  customTitle,
  isRequired,
  analyticsInteractionKey,
  analyticsInteractionSkipFor,
  name,
}) => {
  const handleSort = (target) => {
    const sortValue = buttons.filter(({ value }) => value === target.value)[0];
    updateSort(target.value, sortValue);

    if (analyticsInteractionSkipFor) {
      analyticsInteractionSkipFor === target.value
        ? null
        : analyticsInteraction(
            ANALYTICS.FILTER,
            !analyticsInteractionKey ? ANALYTICS.SORT_BY : analyticsInteractionKey,
            target.value
          );
    } else {
      analyticsInteraction(
        ANALYTICS.FILTER,
        !analyticsInteractionKey ? ANALYTICS.SORT_BY : analyticsInteractionKey,
        target.value
      );
    }
  };

  return (
    <fieldset key='sorty_by' className='filter__fieldset filter__fieldset--radio'>
      <legend className='filter__fieldset-heading filter__legend'>{customTitle || i18n('common_sort_by')}</legend>
      <div className='filter__options sort-wrapper'>
        <RadioGroup
          isRequired={isRequired}
          name={name || 'sortBy'}
          onChange={({ target }) => handleSort(target)}
          buttons={buttons}
          theme={hideTheme ? '' : 'darkest-blue'}
        />
      </div>
    </fieldset>
  );
};

Sort.propTypes = {
  buttons: PropTypes.array.isRequired,
  updateSort: PropTypes.func.isRequired,
  hideTheme: PropTypes.bool,
  customTitle: PropTypes.string,
  isRequired: PropTypes.bool,
  analyticsInteractionKey: PropTypes.string,
  analyticsInteractionSkipFor: PropTypes.string,
  name: PropTypes.string,
};

export default Sort;
