import { connect } from 'react-redux';
import { MODAL_PATH } from 'reducers/modal';
import { closeModal } from "actions/modal/closeModal";
import { clearModalQueue } from "actions/modal/clearModalQueue";
import Modal from './Modal';

const mapStateToProps = (state, props) => {
  const { modalKey } = props;
  const modalQueue = state.getIn(MODAL_PATH.MODAL_QUEUE);
  const activeModalState = modalQueue.get(0);
  const isOpen = modalKey ? activeModalState?.get('modalKey') === modalKey : false;
  const contentProps = isOpen ? activeModalState?.get('props') : null;
  return {
    isOpen,
    contentProps,
    finalAction: modalQueue.size === 0 ? state.getIn(MODAL_PATH.FINAL_ACTION) : null,
    breakpoint: state.getIn(['app', 'breakpoint']),
  };
};

const mapDispatchToProps = {
  closeModal,
  clearModalQueue,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
