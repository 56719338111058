import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import ReservationFlow from './ReservationFlow';

const ReservationFlowWrapper = (props) => (
  <Router basename='/'>
    <CompatRouter>
      <ReservationFlow {...props} />
    </CompatRouter>
  </Router>
);

export default ReservationFlowWrapper;
