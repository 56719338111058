import { connect } from 'react-redux';
import { MODAL } from 'constants';
import { breakpointSelector, selectedBundleWithExtrasSelector, hashTableOfRecommendedExtrasSelector } from 'selectors';
import { exclusionsSelector } from 'selectors/reservationSelectors';
import { extrasUpdate } from "actions/reservation/extrasUpdate";
import { closeModal } from "actions/modal/closeModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import Extra from './Extra';

const mapStateToProps = (state, props) => {
  const bundleExtras = selectedBundleWithExtrasSelector(state)?.bundleExtras || [];
  const extraInBundle = bundleExtras?.find(ext => ext.code === props.extra.code);
  const isBundleIncluded = !!extraInBundle;
  const selectedMoreThanBundled = isBundleIncluded && extraInBundle.selected_quantity < props.extra.selected_quantity;
  return {
    breakpoint: breakpointSelector(state),
    exclusions: exclusionsSelector(state, props.extra.lrd_policy_code),
    isBundleIncluded,
    selectedMoreThanBundled,
    hashTableOfRecommendedExtras: hashTableOfRecommendedExtrasSelector(state),
  };
};

const mapDispatchToProps = {
  extrasUpdate,
  showExclusionsModal: (header, gboCopy) =>
    openModalWithProps(MODAL.EXTRAS_EXCLUSION_MODAL, { header, gboCopy, modalKey: MODAL.EXTRAS_EXCLUSION_MODAL }),
  openLimitedInventoryModal: handleAccept => openModalWithProps(MODAL.LIMITED_INVENTORY_MODAL, { handleAccept }),
  closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Extra);
