import { connect } from 'react-redux';
import { profileDataSelector } from 'selectors';
import ProfileProgressIndicator from './ProfileProgressIndicator';

const mapStateToProps = state => ({
  profileData: profileDataSelector(state, { profile_completion: true }),
});

// To-do in future ticket: open the modal responsible to update profile's data
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileProgressIndicator);
