import React, { useState } from 'react';
import utils from 'utils';
import { dispatchEventWithDOM } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import useActions from 'hooks/useActions';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import LoadingWidget from 'components/LoadingWidget';
import { removeAdditionalDriver } from "actions/reservation/removeAdditionalDriver";
import { closeModal } from "actions/modal/closeModal";

/**
 * RemoveDriverModal
 * will remove a driver from the additional drivers list
 * you must call this modal with the driver prop like so: `openModalWithProps(MODAL.ADDITONAL_DRIVER_REMOVE_MODAL, { driver })`
 * @return {JSX}
 */

const RemoveDriverModal = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [handleRemove, closeRemoveModal] = useActions([removeAdditionalDriver, closeModal]);

  const handleAccept = (driver) => {
    setIsLoading(true);

    return handleRemove(driver).then(() => {
      closeRemoveModal({ skipAnalytics: true });
      setIsLoading(false);
      // Analytics event
      dispatchEventWithDOM('AdditionalDriverRemoved');
    });
  };

  return (
    <Modal
      modalKey={MODAL.ADDITONAL_DRIVER_REMOVE_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('remove_additional_driver_modal_header')}
    >
      {(
        { driver } // TODO - Leo - switch out renderProps function pattern for a modal props hook
      ) =>
        isLoading ? (
          <LoadingWidget />
        ) : (
          <div>
            <p>{utils.i18n('remove_additional_driver_modal_description')}</p>

            <ModalFooter
              buttons={[
                {
                  type: MODAL_BUTTON_TYPES.DECLINE,
                  label: utils.i18n('remove_additional_driver_modal_decline'),
                  analyticsKey: utils.analytics.prepareKey(MODAL.ADDITONAL_DRIVER_REMOVE_MODAL),
                  analyticsValue: 'keep',
                },
                {
                  type: MODAL_BUTTON_TYPES.ACCEPT,
                  label: utils.i18n('remove_additional_driver_modal_accept'),
                  handler: () => handleAccept(driver),
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      ANALYTICS.MODAL,
                      utils.analytics.prepareKey(MODAL.ADDITONAL_DRIVER_REMOVE_MODAL),
                      ANALYTICS.REMOVE
                    ),
                },
              ]}
            />
          </div>
        )
      }
    </Modal>
  );
};

export default RemoveDriverModal;
