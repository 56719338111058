import React, { Component } from 'react';
import RegionTile from './RegionTile';
import { propTypes } from './RegionMosaicPropTypes';

class RegionMosaic extends Component {
  static propTypes = propTypes;

  state = {
    expandedIndex: null,
  };

  handleExpand = state => {
    this.setState({ expandedIndex: this.state.expandedIndex !== state.index && state.index });
  };

  render() {
    const { regions } = this.props;

    return (
      // https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
      <section className='region-mosaic'>
        <ul className='region-mosaic__list'>
          {regions &&
            regions.map((region, index) => (
              <li key={index}>
                <RegionTile
                  {...region}
                  index={index}
                  expanded={this.state.expandedIndex === index}
                  onToggle={!region.disabled && this.handleExpand}
                />
              </li>
            ))}
        </ul>
      </section>
    );
  }
}

export default RegionMosaic;
