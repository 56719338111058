import React from 'react';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { MODAL } from 'constants';
import Button from 'components/Button';
import LearnMoreAboutAdditionalDriverModal from 'components/modals/AdditionalDriverModals/LearnMoreAboutAdditionalDriverModal';
import { openModal } from "actions/modal/openModal";

const LearnMoreCta = () => {
  const openLearnMore = useActions(() => openModal(MODAL.LEARN_MORE_ABOUT_ADDITIONAL_DRIVER));

  return (
    <p className='additional-drivers__description additional-drivers__description--bold'>
      {utils.i18n(
        'review_additional_drivers_description_1',
        [
          <Button key={0} link onClick={openLearnMore} className='additional-drivers__learn-more'>
            {utils.i18n('review_additional_drivers_description_learn_more')}
          </Button>,
        ],
        { jsx: true }
      )}
      <LearnMoreAboutAdditionalDriverModal />
    </p>
  );
};

export default LearnMoreCta;
