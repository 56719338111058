import React from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { capitalize } from 'utils/string';
import PropTypes from 'prop-types';
import {
  getReservationRenterInfoFirstName,
  getReservationRenterInfoLastName,
  getReservationRenterInfoEmail,
  getReservationRenterInfoPhoneNumber,
} from 'selectors/reservationSelectors';

const PostResRenterInformation = ({ leftSideSTC }) => {
  const reservationfirstName = useSelector(state => getReservationRenterInfoFirstName(state));
  const reservationLastName = useSelector(state => getReservationRenterInfoLastName(state));
  const reservationEmail = useSelector(state => getReservationRenterInfoEmail(state));
  const reservationPhone = useSelector(state => getReservationRenterInfoPhoneNumber(state));

  const renderDetailLine = (labelKey, value) => (
    <p className='check-in-review__paragraph'>
      {i18n(labelKey)}: {value}
    </p>
  );

  return (
    <div className='post-res-driver-details__drawer-section'>
      <p className={leftSideSTC ? 'rental-details__section-label' : 'post-res__subheading'}>
        {i18n('check_in_step_profile_renter_info')}
      </p>
      {reservationfirstName && reservationLastName && (
        <p className='check-in-review__paragraph'>{`${capitalize(reservationfirstName)} ${capitalize(
          reservationLastName
        )}`}</p>
      )}
      {reservationEmail && renderDetailLine('common_email', reservationEmail)}
      {reservationPhone && renderDetailLine('common_phone_number', reservationPhone)}
    </div>
  );
};

PostResRenterInformation.defaultProps = {
  leftSideSTC: false,
};

PostResRenterInformation.propTypes = {
  leftSideSTC: PropTypes.bool,
};

export default PostResRenterInformation;
