import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import MyProfileSectionTitle from '../MyProfileSectionTitle';
import EditContactInformationModal from '../EditContactInformationModal';

/**
 * ContactInformation
 * block to show users contact information (phones and adress)
 *
 * @param {object} props - React Props
 * @param {object} props.profileData - Authenticated user profile data
 * @param {object} props.profileData.phones - Array of phones with details from authenticated user profile
 * @param {object} props.profileData.address_profile - Authenticated user complete address object
 * @param {object} props.profileData.oci_individual_status - Alamo Insiders individual status for Online Check In
 * @param {function} props.openEditModal - Function to open modal with edit form
 *
 * @return {JSX}
 */
const ContactInformation = ({ profileData, openEditModal }) => {
  const { address_profile, oci_individual_status, phones } = profileData || {};
  const { street_addresses } = address_profile || {};
  const address = street_addresses?.join(' ') || '';
  const phone = utils.profile.getPhoneByPriority(phones, 1)?.phone_number;
  const alt_phone = utils.profile.getPhoneByPriority(phones, 2).phone_number;

  return (
    <div className='my-profile__section my-profile__section--top-border my-profile__section--desktop-half-width'>
      <MyProfileSectionTitle
        title={utils.i18n('my_profile_contact_information')}
        handleEdit={openEditModal}
        isCompleted={oci_individual_status?.contact_details_present || oci_individual_status?.address_details_present}
      />
      {!!phone && (
        <p className='my-profile__info-row'>
          {utils.i18n('my_profile_contact_information_phone')}: {phone}
        </p>
      )}
      {!!alt_phone && (
        <p className='my-profile__info-row'>
          {utils.i18n('my_profile_contact_information_alternate_phone')}: {alt_phone}
        </p>
      )}
      {!!address && (
        <p className='my-profile__info-row'>
          {utils.i18n('my_profile_contact_information_address')}: {address}
        </p>
      )}
      <EditContactInformationModal />
    </div>
  );
};

ContactInformation.propTypes = {
  profileData: PropTypes.shape({
    phones: PropTypes.array,
    address_profile: PropTypes.object,
    oci_individual_status: PropTypes.object,
  }),
  openEditModal: PropTypes.func,
};

export default ContactInformation;
