import PropTypes from 'prop-types';

export const propTypes = {
  /**
   * Id
   *
   * @type {String}
   */
  id: PropTypes.string,

  /**
   * Name
   *
   * @type {String}
   */
  name: PropTypes.string,

  /**
   * Label
   *
   * @type {String}
   */
  label: PropTypes.any,

  /**
   * Value
   *
   * @type {String}
   */
  value: PropTypes.string,

  /**
   * Theme
   *
   * @type {String} [light | dark]
   */
  theme: PropTypes.string,

  /**
   * DTM Analytics String
   *
   * @type {String} the string that will be use by analytics team to query the `select` element
   */
  dataDtmAnalytics: PropTypes.string,

  /**
   * Derived State Enables Checkbox to
   * Derive State from Props
   *
   * @type {Boolean}
   */
  derivedState: PropTypes.bool,

  /**
   * Required Field
   */
  required: PropTypes.oneOfType([
    PropTypes.string, // Custom Error Message
    PropTypes.bool, // Defaults to Generic Message
  ]),

  /**
   * For setting initialValue in final-form. Not dynamic, only calculated on Mount.
   */
  initialValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const defaultProps = {
  theme: 'darkest-blue',
};
