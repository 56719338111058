import React, { Component } from 'react';
import Title from 'components/Title';
import LocationTile from './LocationTile';
import { propTypes, defaultProps } from './LocationsTilesWrapperPropTypes';
import MagicTile from './MagicTile';

class LocationsTilesWrapper extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  render() {
    const {
      locations,
      title: { cta, linkURL, openInTab, text, type },
      magicTile,
    } = this.props;

    return (
      <section className='locations-tiles'>
        <Title type={type} text={text} openInTab={openInTab} linkURL={linkURL} cta={cta} />

        <div className='locations-tiles__tiles'>
          {locations &&
            locations.map((location, index) => <LocationTile {...location} key={`${index}-${location.url}`} />)}
          <MagicTile {...magicTile} />
        </div>
      </section>
    );
  }
}

export default LocationsTilesWrapper;
