import React, { Component } from 'react';
import utils from 'utils';
import cn from 'classnames';
import Anchor from 'components/Anchor';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { WINDOW_OBJECT_KEYS, PROFILE } from 'constants';
import ResflowLogoutModal from 'components/modals/ResflowLogoutModal';

/**
 * AuthenticatedFlyout Description:
 *
 * @param {object} props - React Props
 * @param {object} props.breakpoint
 * @param {function} props.logout - will fire gmi logout service
 * @param {function} props.openLogoutModal - will fire logout modal for In Res Path
 * @param {boolean} props.isResPath - Flag to differentiate the general and the resflow flyout
 * @param {boolean} props.disableNavigation - Flag to disable My Profile and My Trips links (coming from AuthenticatedFlyoutSwitch)
 *
 * from {...profileData} argument, provided by AuthenticatedFlyoutSwitch
 * @param {string} props.first_name
 * @param {string} props.last_name
 *
 * @return {JSX} ...
 */
class AuthenticatedFlyout extends Component {
  state = {
    expanded: false,
  };

  buttonRef = React.createRef();

  navRef = React.createRef();

  handleToggleExpand = () => {
    this.state.expanded ? this.handleCollapse() : this.handleExpand();
  };

  handleExpand = () => {
    this.setState({ expanded: true });
    utils.analytics.interaction('menu', 'nav', 'user');
  };

  handleCollapse = noAnalytics => {
    this.setState({ expanded: false }, () => {
      const btn = this.buttonRef.current;
      btn && btn.focus();
    });
    !noAnalytics && utils.analytics.interaction('menu', 'nav', 'user');
  };

  handleClickOutside = e => {
    this.state.expanded &&
      e?.target?.classList.contains('header-flyout__list-item__link') &&
      utils.analytics.interaction('menu', 'nav', e?.target?.innerText?.toLowerCase(), 'user');
    this.setState({ expanded: false });
  };

  handleLogout = e => {
    this.handleClickOutside();
    this.props.logout();
    utils.analytics.interaction('menu', 'nav', e?.target?.innerText?.toLowerCase(), 'user');
  };

  navItems = [
    {
      ItemComponent: Anchor,
      props: {
        href: `${utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.PROFILE_PAGE)}#${
          PROFILE.PROFILE_PATHS_CONFIG.myProfile
        }`,
        className: 'header-flyout__list-item__link link',
        onClick: this.handleClickOutside,
        role: 'menuItem',
        title: utils.i18n('logged_in_flyout_my_profile'),
      },
      itemText: utils.i18n('logged_in_flyout_my_profile'),
    },
    {
      ItemComponent: Anchor,
      props: {
        href: `${utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.PROFILE_PAGE)}#${PROFILE.PROFILE_PATHS_CONFIG.myTrips}`,
        className: 'header-flyout__list-item__link link',
        onClick: this.handleClickOutside,
        role: 'menuItem',
        title: utils.i18n('logged_in_flyout_my_trips'),
      },
      itemText: utils.i18n('logged_in_flyout_my_trips'),
    },
  ];

  signOutNavItem = [
    {
      ItemComponent: Button,
      props: {
        onClick: this.handleLogout,
        link: true,
        className: 'header-flyout__list-item__link navItem',
      },
      itemText: utils.i18n('logged_in_flyout_sign_out'),
    },
  ];

  resFlowNavItems = [
    {
      ItemComponent: Button,
      props: {
        onClick: this.props.openLogoutModal,
        link: true,
        className: 'header-flyout__list-item__link navItem',
      },
      itemText: utils.i18n('logged_in_flyout_sign_out'),
    },
  ];

  getFirstName = () => this.props.first_name;

  getNameInitials = () => `${this.props.first_name.charAt(0)}${this.props.last_name.charAt(0)}`;

  handleKeyDown = e => {
    const key = utils.accessibility.formatKeys(e);
    const noAnalytics = true;
    // close the flyout on "Escape"
    key.escape && this.handleCollapse(noAnalytics);
  };

  handleBlur = () => {
    // blur and focus events bubble up in React,
    // setTimeout gives a chance for the activeElement to update
    // if the activeElement is outside of navRef, close the dropdown
    // returns focus to the toggle button
    setTimeout(() => {
      const noAnalytics = true;
      !this.navRef?.current?.contains(document.activeElement) && this.handleCollapse(noAnalytics);
    });
  };

  getNavigationItems = () => {
    const { isResPath, disableNavigation } = this.props;
    if (isResPath) {
      return this.resFlowNavItems;
    }
    if (disableNavigation) {
      return this.signOutNavItem;
    }
    return [...this.navItems, ...this.signOutNavItem];
  };

  render() {
    const { breakpoint, isResPath } = this.props;
    const { expanded } = this.state;

    return (
      <section
        className={cn('header-flyout header-flyout--authenticated', { 'header-flyout--in-resflow': isResPath })}
        ref={this.clickOutsideRef}>
        <div className='header-flyout__top'>
          <div className='header-flyout__top__text'>
            <div className='header-flyout__top__links'>
              <Button
                link
                className='header-flyout__top__toggle navItem'
                onClick={this.handleToggleExpand}
                buttonRef={this.buttonRef}
                ariaExpanded={expanded.toString()}
                ariaText={this.getFirstName()}>
                <div className='header-flyout__top__toggle__content'>
                  <span
                    className={cn('header-flyout__top__name', {
                      expanded,
                    })}>
                    {breakpoint.isMobile ? this.getNameInitials() : this.getFirstName()}
                  </span>
                </div>
              </Button>
            </div>
          </div>
        </div>

        <nav
          role='menu'
          className={cn('header-flyout__shadow-clipper', {
            expanded,
          })}
          aria-hidden={!expanded.toString()}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          ref={this.navRef}>
          <ul className='header-flyout__content header-flyout__list component-theme--light'>
            {this.getNavigationItems().map(({ ItemComponent, props, itemText }, idx) => (
              <li key={idx} className='header-flyout__list-item'>
                <ItemComponent {...props}>{itemText}</ItemComponent>
              </li>
            ))}
          </ul>
        </nav>

        {isResPath && <ResflowLogoutModal />}
      </section>
    );
  }
}

// AuthenticatedFlyout.defaultProps = {};

AuthenticatedFlyout.propTypes = {
  breakpoint: PropTypes.object,
  logout: PropTypes.func.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  openLogoutModal: PropTypes.func,
  isResPath: PropTypes.bool,
};

export default utils.withClickOutside(AuthenticatedFlyout);
