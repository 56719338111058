import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import Button from 'components/Button';
import TextField from 'components/Form/TextField';
import SelectField from 'components/Form/SelectField';
import { isEditableCheckInProfileSelector, onlineCheckInStatusSelector } from 'selectors/checkinSelectors';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import PhoneNumberField from 'components/Form/PhoneNumberField';

/**
 * CheckInContactInformationForm
 * Form section for Contact info during the check-in profile step
 *
 * @param {object} props - React Props
 * @param {function} props.editContactInfo - callback from parent to call when editing, allowing parent container
 *                                           to display custom container UX if necessary
 * @param {object} props.initialValues - data used to pre-populate form
 * @param {object} props.form - FinalForm instance - used to validate date fields
 * @param {function} props.updateDriverProfileState - function to update form contact profile info
 * @return {JSX} CheckInContactInformationForm jsx component
 */
const CheckInContactInformationForm = ({
  editContactInfo,
  initialValues,
  form,
  updateInitialState,
  updateDriverProfileState,
}) => {
  const { contactProfileComplete } = useSelector(onlineCheckInStatusSelector);
  const isProfileEditable = useSelector(isEditableCheckInProfileSelector);

  const initialCountryCode = initialValues.address_info.country_code;
  const { selectedCountry, setSelectedCountry, countryConfig, countries, statesOrProvinces } =
    useCountryFieldsConfig(initialCountryCode);

  const [isViewMode, setIsViewMode] = useState(contactProfileComplete);
  const [addressExpanded, setAddressExpanded] = useState(false);

  const [phone, setPhone] = useState();

  useEffect(() => {
    setIsViewMode(contactProfileComplete);
    setAddressExpanded(!!initialValues.address_info.address_line_2);
    updateInitialState?.();
    // This is for forcing the form reset when clearProfile is called
    if (!contactProfileComplete && form) {
      form.reset();
    }
  }, [contactProfileComplete]);

  const toggleAddressLine2 = () => {
    setAddressExpanded(!addressExpanded);
  };

  const setCountry = (countryCode) => {
    const countryObject = utils.locations.findCountryCode(countries, countryCode);
    setSelectedCountry(countryObject);
  };

  const setSelectCountry = (event) => {
    setCountry(event?.target?.value);
  };

  const setPhoneNumber = (data) => {
    if (data.phoneNumber) {
      setPhone(data.phoneNumber);
    }
  };

  const toggleViewMode = () => {
    setIsViewMode(!isViewMode);
    updateInitialState?.();
    editContactInfo?.();
  };

  const { shouldShowSubdivisionField } = countryConfig;

  const { address_line_1, address_line_2, city, postal, country_subdivision_code } = initialValues.address_info;
  const { phone_set, email } = initialValues.contact_info;

  // This is for updating the form values with default country_subdivision_code if the user doesn't change the SubdivisionField
  // checking for formState values, because initialValues?.address_info.country_subdivision_code was getting resetted user confirms the details.
  useEffect(() => {
    const { values } = form.getState();
    if (
      !initialValues?.address_info.country_subdivision_code &&
      !values?.address_info?.country_subdivision_code &&
      selectedCountry &&
      statesOrProvinces[selectedCountry.country_code] &&
      !isViewMode
    ) {
      form.change(
        'address_info.country_subdivision_code',
        statesOrProvinces[selectedCountry.country_code][0]?.country_subdivision_code
      );
    }
  }, [statesOrProvinces[selectedCountry?.country_code]]);

  useEffect(() => {
    if (phone && !isViewMode && form.getState().active === 'contact_info.phone_set[0].phone_number') {
      updateDriverProfileState?.('phones')(phone);
    }
  }, [form]);

  return (
    <div className='check-in-flow__form__section'>
      <div className='check-in-flow__form__section-title-wrapper'>
        <h4 className='in-res-oci-review-tile__title'>
          {utils.i18n(
            !contactProfileComplete || isViewMode
              ? 'check_in_contact_info_form_title'
              : 'in_res_oci_update_contact_info'
          )}
        </h4>

        {contactProfileComplete && isProfileEditable ? (
          <Button
            className='check-in-flow__form__edit-cta'
            onClick={toggleViewMode}
            data-dtm-track='check_in|edit|contact'
            link
          >
            {isViewMode ? utils.i18n('common_edit') : utils.i18n('common_cancel')}
          </Button>
        ) : null}
      </div>

      {isViewMode ? (
        <>
          <p className='check-in-flow__form__info-row'>
            {utils.i18n('check_in_contact_info_phone_number')}: {phone_set?.[0]?.phone_number}
          </p>
          <p className='check-in-flow__form__info-row'>
            {utils.i18n('check_in_contact_info_address')}: {address_line_1}
          </p>
        </>
      ) : (
        <>
          <div className='check-in-flow__form__field-row'>
            <TextField
              name='contact_info.email'
              label={utils.i18n('common_email')}
              required
              fill
              initialValueButton={email}
              validations={[utils.fieldValidation.email]}
            />
          </div>
          <div className='check-in-flow__form__field-row'>
            <PhoneNumberField
              name='contact_info.phone_set[0].phone_number'
              label={utils.i18n('my_profile_contact_information_phone')}
              required
              onChange={setPhoneNumber}
              fill
              autoComplete='tel'
              initialValueButton={phone_set?.[0]?.phone_number}
              inputType='phone'
              validations={[utils.fieldValidation.phone, utils.fieldValidation.checkPhoneCode]}
            />
          </div>
          <p className='check-in-flow__form__helper-text'>{utils.i18n('check_in_contact_info_form_phone_helper')}</p>

          <TextField
            name='address_info.address_line_1'
            label={utils.i18n('my_profile_edit_contact_information_address_line_1_label')}
            className='check-in-flow__form__full-width-field'
            required
            fill
            initialValueButton={address_line_1}
          />

          {addressExpanded ? (
            <TextField
              name='address_info.address_line_2'
              label={utils.i18n('my_profile_edit_contact_information_address_line_2_label')}
              className='check-in-flow__form__full-width-field'
              fill
              initialValueButton={address_line_2}
            />
          ) : (
            <Button className='check-in-flow__form__expand-button' onClick={toggleAddressLine2} link>
              {utils.i18n('my_profile_edit_contact_information_address_line_2_button')}
            </Button>
          )}

          <div className='check-in-flow__form__field-row'>
            {!!countries?.length && (
              <SelectField
                id='address_info.country_code'
                name='address_info.country_code'
                label={utils.i18n('my_profile_edit_contact_information_country_label')}
                onChange={setSelectCountry}
                options={countries}
                valueKey='country_code'
                labelKey='country_name'
                disabled={!countries?.length}
                required
                includeHiddenOption
              />
            )}
          </div>

          <div className='check-in-flow__form__field-row'>
            <TextField
              name='address_info.city'
              label={utils.i18n('my_profile_edit_contact_information_city_label')}
              required
              fill
              initialValueButton={city}
            />

            {shouldShowSubdivisionField && (
              <SelectField
                id='address_info.country_subdivision_code'
                name='address_info.country_subdivision_code'
                label={utils.i18n('my_profile_edit_contact_information_state_province_label')}
                options={statesOrProvinces[selectedCountry.country_code]}
                valueKey='country_subdivision_code'
                labelKey='country_subdivision_name'
                required
                includeHiddenOption
                maskedOption={country_subdivision_code}
              />
            )}
          </div>

          <div className='check-in-flow__form__field-row'>
            <TextField
              name='address_info.postal'
              label={utils.i18n('my_profile_edit_contact_information_postal_code_label')}
              required
              fill
              initialValueButton={postal}
            />
          </div>
        </>
      )}
    </div>
  );
};

CheckInContactInformationForm.propTypes = {
  editContactInfo: PropTypes.func,
  form: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
};

export default CheckInContactInformationForm;
