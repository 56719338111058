import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import MODAL from 'constants/modals';
import LOCATIONS from 'constants/locations';
import useActions from 'hooks/useActions';
import { isLocationWithNoVehiclesAvailableSelector } from 'selectors/errorSelectors';
import Form from 'components/Form/Form';
import SoldOutModal from 'components/modals/SoldOutModal';
import { modalQueueSelector } from 'selectors';
import { openModal } from 'actions/modal/openModal';
import { getCurrentPageHash } from 'utils/url';
import LocationSearch from './LocationSearch';

/**
 * LocationSearchWrapper - Wrapper with forking logic choosing between standalone version of location search and the one used in booking widget
 *
 * @param {type} props props pass through
 */
const dummySubmit = () => false;

const LocationSearchWrapper = (props) => {
  const { resolveBranchURL, setLocation, redirectToLocationFinder, isFromBW } = props;
  const isLocationWithNoVehiclesAvailable = useSelector(isLocationWithNoVehiclesAvailableSelector);
  const openSoldOutModal = useActions(() => openModal(MODAL.SOLD_OUT_MODAL));
  const [shouldShowModal, setShouldShowModal] = useState(!isLocationWithNoVehiclesAvailable);
  const modalQueue = useSelector((state) => utils.safeToJS(modalQueueSelector(state)));

  const currentStepHash = getCurrentPageHash();

  // setting the show soldout modal state value to true if isLocationWithNoVehiclesAvailable is true value
  useEffect(() => {
    !isLocationWithNoVehiclesAvailable && setShouldShowModal(true);
  }, [shouldShowModal, isLocationWithNoVehiclesAvailable]);

  useEffect(() => {
    // Check if it's used on the booking widget (isFromBW)
    if (isFromBW && isLocationWithNoVehiclesAvailable && modalQueue?.modalKey !== MODAL.SOLD_OUT_MODAL) {
      openSoldOutModal();
    }
  }, [isFromBW, shouldShowModal, isLocationWithNoVehiclesAvailable, openSoldOutModal]);

  /**
   * handleSelectLocationBW - Specific to the instance used in the booking widget
   */
  const handleSelectLocationBW = (location, input) => {
    utils.safeExecute(setLocation, location);
    input.onChange(location);
    utils.scrollLock({ toggleValue: false });
  };

  /**
   * handleSelectLocationLS - Specific to the standalone instance of the location search
   */
  const handleSelectLocationLS = (location) => {
    const { type, id } = location;
    utils.dom.loadingOverlay(true);
    if (type === LOCATIONS.TYPE_CITY || type === LOCATIONS.TYPE_GEOLOCATION) {
      // dispatch location finder redirect after storing location info in session
      redirectToLocationFinder(location);
    } else {
      // dispatch servlet call to map branch id to an AEM Page
      resolveBranchURL(id);
    }
    utils.scrollLock({ toggleValue: false });
  };

  /**
   * handleSelectLocationSF - Specific to the instance used in the store finder component
   * If user selects a branch location, user is taken to branch location page
   * If user chooses to use My Current Location we need to perform a radial search and update the map and list components
   * If user selects a city, we need to perform a radial search on city and update List and Map view
   */
  const handleSelectLocationSF = (location) => {
    const { type, id } = location;
    if (type !== LOCATIONS.TYPE_CITY && type !== LOCATIONS.TYPE_GEOLOCATION) {
      resolveBranchURL(id);
    } else {
      // dispatch servlet call to map branch id to an AEM Page
      setLocation(location);
    }
    utils.scrollLock({ toggleValue: false });
  };

  return (
    <>
      {props.type === LOCATIONS.LOCATION_SEARCH_TYPES.BOOKING_WIDGET ? (
        <LocationSearch id='location-seach' {...props} onSelectLocation={handleSelectLocationBW} selectOnBlur />
      ) : (
        <Form onSubmit={dummySubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className='locationSearchWrapper' autoComplete='off'>
              <LocationSearch
                id='location-seach'
                label={utils.i18n('location_search_placeholder')}
                {...props}
                attention
                onSelectLocation={
                  props.type === LOCATIONS.LOCATION_SEARCH_TYPES.LOCATION_FINDER
                    ? handleSelectLocationSF
                    : handleSelectLocationLS
                }
                selectOnBlur={false}
              />
            </form>
          )}
        </Form>
      )}
      {isFromBW && <SoldOutModal currentPage={currentStepHash} />}
    </>
  );
};

export default LocationSearchWrapper;
