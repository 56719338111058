import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import FormattedPrice from 'components/FormattedPrice';
import RentalRateLineItem from './RentalRateLineItem';

/**
 * Rental Rate section of Reservation Details displayed on the Confirmation screen
 * @param {object} props                              - React Props
 * @param {object} props.estimatedTotalVehicleView    - Total value charged for the chosen vehicle class
 * @param {array} props.chargedRate                   - Array of vehicle rates charged for the chosen vehicle class
 * @param {boolean} props.isThirdPartyReservation     - Indicates whether reservation is third party or not
 * @param {boolean} props.asterisk                    - Indicates whether values displayed should show an asterisk or not
 * @return {JSX} RentalRate component
 */
const RentalRate = ({ estimatedTotalVehicleView, chargedRate, isThirdPartyReservation, asterisk }) => {
  // TODO: replace with secret_rate flag
  const isThirdPartyNetRate =
    isThirdPartyReservation && (parseInt(estimatedTotalVehicleView?.amount, 10) === 0 || !estimatedTotalVehicleView);

  return (
    <div className='rental-details__section rental-details__taxes'>
      <table>
        <caption className='rental-details__table-section-caption rental-details__section-label'>
          {utils.i18n('rental_details_rental_rate')}
        </caption>
        <tbody>
          {!isThirdPartyNetRate && utils.config.getIsIncrementalPricingEnabled() && chargedRate?.length ? (
            chargedRate.map(({ rate_type, rate_quantity, rate_amount_view, total_amount_view }) => (
              <RentalRateLineItem
                label={utils.i18n(
                  `rental_details_time_distance_${rate_type}`,
                  [
                    <Fragment key='0'>{rate_quantity}</Fragment>,
                    <FormattedPrice key='1'>{utils.getFormattedPrice(rate_amount_view)}</FormattedPrice>,
                  ],
                  { jsx: true }
                )}
                total={utils.getFormattedPrice(total_amount_view, { asterisk })}
                key={rate_type}
              />
            ))
          ) : (
            <RentalRateLineItem
              label={utils.i18n('rental_details_time_distance')}
              total={
                isThirdPartyNetRate
                  ? utils.i18n('third_party_reservation_net_rate')
                  : utils.getFormattedPrice(estimatedTotalVehicleView, { asterisk })
              }
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

RentalRate.propTypes = {
  estimatedTotalVehicleView: PropTypes.object,
  chargedRate: PropTypes.array,
  isThirdPartyReservation: PropTypes.bool,
  asterisk: PropTypes.bool,
};

export default RentalRate;
