import React from 'react';
import PropTypes from 'prop-types';
import Picture from 'components/Picture';
import UpgradeDescription from './UpgradeDescription';
import UpgradeCta from './UpgradeCta';
import UpgradeTotalCopy from './UpgradeTotalCopy';

/**
 * UpgradeBannerCompactLayout - a common, reused layout arrangement of InPathUpgrade components
 *
 * @param {object}    props - React Props
 * @param {string}    props.name - upgrade vehicle name
 * @param {string}    props.makeModelOrSimilarText - upgrade vehicle make model or similar text
 * @param {string}    props.priceDifference - formatted price indicating difference of upgrade, can be incremental or total
 * @param {string}    props.imagePath - path provided by GBO for upgrade car image
 * @param {func}      props.upgradeHandler - action that calls upgrade service with relevant upgrade car class
 * @param {string}    props.total - total formatted cost after upgrade
 * @param {string}    props.buttonTheme - passes this value as theme prop to UpgradeCta Button
 * @param {string}    props.upgradeClass - car upgradeClass from inPathUpgradeDetails
 * @return {JSX}      Arrangement of InPathUpgrade components wrapped in jsx Fragment
 */
const UpgradeBannerCompactLayout = ({
  name,
  makeModelOrSimilarText,
  priceDifference,
  imagePath,
  upgradeHandler,
  total,
  buttonTheme,
  upgradeClass,
}) => (
  <>
    <div className='in-path-upgrade__car-details'>
      <UpgradeDescription
        name={name}
        makeModelOrSimilarText={makeModelOrSimilarText}
        priceDifference={priceDifference}
      />
      <Picture src={imagePath} alt={makeModelOrSimilarText} pictureClassName='in-path-upgrade__picture' />
    </div>
    <UpgradeCta upgradeHandler={upgradeHandler} theme={buttonTheme} page='review' upgradeClass={upgradeClass} />
    <UpgradeTotalCopy total={total} />
  </>
);

UpgradeBannerCompactLayout.proptypes = {
  name: PropTypes.string,
  makeModelOrSimilarText: PropTypes.string,
  priceDifference: PropTypes.string,
  imagePath: PropTypes.string,
  upgradeHandler: PropTypes.func,
  total: PropTypes.string,
  buttonTheme: PropTypes.string,
  upgradeClass: PropTypes.string.isRequired,
};

export default UpgradeBannerCompactLayout;
