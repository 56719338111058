import { connect } from 'react-redux';
import { inflightPaymentSelector } from 'selectors';
import { selectedPaymentMethodSelector } from 'selectors/reservationSelectors';
import { MODAL } from 'constants';
import { removeInflightPaymentDetails } from "actions/reservation/removeInflightPaymentDetails";
import { openModal } from "actions/modal/openModal";
import GuaranteedReservationAddCard from './GuaranteedReservationAddCard';

const mapStateToProps = state => ({
  cardDetails: inflightPaymentSelector(state),
  selectedPaymentMethod: selectedPaymentMethodSelector(state),
});

const mapDispatchToProps = {
  showGuaranteedResModal: () => openModal(MODAL.GUARANTEED_RES),
  showGuaranteedResIframeModal: () => openModal(MODAL.GUARANTEED_RES_IFRAME),
  removeInflightPaymentDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuaranteedReservationAddCard);
