import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const LoadingWidget = ({ darkTheme = false }) => (
  <div className={cn('loading-widget', { 'loading-widget--dark-theme': darkTheme })} />
);

LoadingWidget.propTypes = {
  darkTheme: PropTypes.bool,
};

export default LoadingWidget;
