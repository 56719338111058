import React from 'react';
import utils from 'utils';
import AccountAndPassword from './AccountAndPassword';
import ContactInformation from './ContactInformation';
import DriversLicense from './DriversLicense';
import ProfilePRP from './ProfilePRP';

/**
 * MyProfile
 * Block with user profile infos (Account and Password, Driver's License, Contact and PRP)
 *
 * @return {JSX}
 */
const MyProfile = () => (
  <div className='my-profile'>
    <div className='my-profile__tile component-theme--light'>
      <AccountAndPassword />
      <DriversLicense />
      <ContactInformation />
      <ProfilePRP />
      <p className='my-profile__privacy-message'>{utils.i18n('my_profile_privacy_copy')}</p>
    </div>
  </div>
);

export default MyProfile;
