import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { skipTheCounterAbort } from 'actions/skipTheCounter';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ANALYTICS, MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * ModifyReservationModal - confirms user wants to initiate modify flow
 *
 * @param {object} props - React Props
 * @param {object} props.modifyReservationStart - dispatch modifyReservationStart action
 * @return {JSX}
 */

const ModifyReservationModal = ({ modifyReservationStart, isSTCFlow }) => {
  const skipTheCounterAbortAction = useActions(skipTheCounterAbort);

  // aborting the skip the counter flow if modify reservation is selected from stc before making a modify call for true modify and exit from stc flow
  const modfiyHandler = () => {
    isSTCFlow
      ? skipTheCounterAbortAction({ redirect: false }).then((resp) => {
          if (!resp.messages) {
            setTimeout(() => {
              modifyReservationStart();
            }, 0);
          }
        })
      : modifyReservationStart();
  };

  return (
    <Modal
      modalKey={MODAL.MODIFY_RESERVATION}
      header={utils.i18n('confirmation_reservation_modify_modal_header')}
      theme={MODAL_THEMES.WHITE}
      customClass='modify-reservation-modal modal-themed__expanded-content'
    >
      <>
        <p className='modal-themed__paragraph'>{utils.i18n('confirmation_reservation_modify_modal_copy')}</p>

        <div className='column-reverse-footer'>
          <ModalFooter
            buttons={[
              {
                type: DECLINE,
                label: utils.i18n('confirmation_reservation_modify_modal_decline'),
                analyticsValue: ANALYTICS.NO_KEEP_RESERVATION,
                analyticsKey: ANALYTICS.MODIFY_RESERVATION_KEY,
              },
              {
                type: ACCEPT,
                label: utils.i18n('confirmation_reservation_modify_modal_accept'),
                handler: () => modfiyHandler(),
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    ANALYTICS.MODIFY_RESERVATION_KEY,
                    utils.i18n('confirmation_reservation_modify_modal_accept')
                  ),
              },
            ]}
          />
        </div>
      </>
    </Modal>
  );
};

ModifyReservationModal.propTypes = {
  modifyReservationStart: PropTypes.func.isRequired,
};

export default ModifyReservationModal;
