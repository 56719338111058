import React, { useEffect } from 'react';
import Button from 'components/Button';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import CanadaFrenchRedirectionModal from '../CanadaFrenchRedirectionModal';

const { i18n } = utils;

const CanadaFrenchRedirectionBanner = (props) => {
  const { openModalAction, showCAfrBannerCommit } = props;

  useEffect(() => {
    if (showCAfrBannerCommit) {
      utils.analytics.interaction(ANALYTICS.UI_BANNER, ANALYTICS.VIEW_PAGE_IN_FRENCH, ANALYTICS.OPEN);
    }
  }, [showCAfrBannerCommit]);

  if (showCAfrBannerCommit) {
    return (
      <>
        <div className='modify-return__banner'>
          {i18n('would_you_like_view_page_french')}{' '}
          <Button link onClick={openModalAction}>
            {i18n('common_continue')}
          </Button>
        </div>

        <CanadaFrenchRedirectionModal />
      </>
    );
  }

  return null;
};

export default CanadaFrenchRedirectionBanner;
