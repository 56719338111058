import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { analyticsClearErrors } from 'utils/analytics';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { isDuplicateEmailError } from 'utils/profile';
import { ANALYTICS, THEMES, PROFILE, WINDOW_OBJECT_KEYS, GMI_SERVICE_PATHS, IDS } from 'constants';
import Form from 'components/Form/Form';
import ServiceErrors from 'components/ServiceErrors';
import FormErrors from 'components/FormErrors';
import TextField from 'components/Form/TextField';
import SelectField from 'components/Form/SelectField';
import PasswordField from 'components/Form/PasswordField';
import PasswordRequirements from 'components/Form/PasswordRequirements';
import Checkbox from 'components/Form/Checkbox';
import EmailAlreadyInUseModal from 'components/modals/EmailAlreadyInUseModal';
import LoyaltyTermsConditionsLink from 'components/LoyaltyTermsConditionsLink';
import Button from 'components/Button';
import PhoneNumberField from 'components/Form/PhoneNumberField';
import useRecaptchaEnterprise from 'hooks/useRecaptchaEnterprise';
import { driverInfoPhonePayloadParser } from 'utils/driverProfilePayloadParser';

const { PREFERENCE_SOURCE_CODE, ENROLLMENT_REQUEST_ORIGIN_CHANNEL } = PROFILE;

const domainCountryCode = utils.config.getDomainCountry();

/**
 * Enrollment Component
 * A form for users to input data in order to create a new Alamo Insiders profile
 *
 * @param {object} props - React Props
 * @param {array} props.countries - Country Data sourced by GBO and formatted into options
 * @param {boolean} props.isFetching - Boolean indicating whether a loading state should be displayed
 * @param {function} props.createProfile - Function for profile creation; expects user's profile data
 * @param {function} props.getCountries - Function to fetch country data from GBO
 * @return {JSX}
 */
const Enrollment = (props) => {
  const { isFetching, countries, showEmailInUseModal, isOpenShowEmailInUseModal, hasServiceError } = props;
  const [enableCountryField, setEnableCountryField] = useState(false);
  const [domainCountryName, setDomainCountryName] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [emailSignUpChecked, setEmailSignUpChecked] = useState(domainCountryCode === 'US'); // should default to true in US domain
  const [dirtyInputs, setDirtyInputs] = useState([]);

  const recaptchaLoaded = useRecaptchaEnterprise();

  useEffect(() => {
    props.getCountries();
  }, []);

  const getCountryName = (code) => countries?.find(({ country_code }) => country_code === code)?.country_name;

  useEffect(() => {
    setDomainCountryName(getCountryName(domainCountryCode));
  }, [countries]);

  /**
   * This function checks if password validation state from the password requirements
   * is met (password is valid) in order to show an error on the field or not.
   * This is different from showing a 'required' error on the password field.
   */
  const customPasswordValidation = () => {
    const errors = {};

    if (!isPasswordValid) {
      errors.password = true;
    }
    return errors;
  };

  const parseFormData = (formData) => {
    const { first_name, last_name, email, password, email_sign_up, accept_terms_and_conditions, country_of_residence } =
      formData;
    // if country_of_residence has not been filled by user, default to domain country
    const country_code = country_of_residence || domainCountryCode;
    const country_name = getCountryName(country_code);
    const currentDate = utils.gmi.getCurrentDateTimeObj().format('YYYY-MM-DD');
    const phoneData = utils.profile.contactInfoPayloadParser(formData);

    const data = {
      address: {
        country_code,
        country_name,
      },
      first_name,
      last_name,
      password,
      email,
      user_name: email,
      terms_and_conditions: {
        accept_decline: accept_terms_and_conditions,
        accept_decline_date: currentDate,
      },
      request_origin_channel: ENROLLMENT_REQUEST_ORIGIN_CHANNEL,
      preference: {
        email_preference: {
          rental_receipts: email_sign_up,
          special_offers: email_sign_up,
        },
        source_code: PREFERENCE_SOURCE_CODE.AI_PROFILE,
      },
      phones: phoneData.phones,
      driver_info: driverInfoPhonePayloadParser(phoneData.phones?.[0]),
    };

    if (!dirtyInputs.includes('email_sign_up') && !email_sign_up) {
      delete data.preference.email_preference;
    }

    return data;
  };

  const handleFormSubmit = (formData) => {
    analyticsClearErrors();
    if (recaptchaLoaded) {
      const requestPayload = parseFormData(formData);

      // make the enroll call and then redirect to the enroll confirmation page
      return props.createProfile(requestPayload).then((res) => {
        // if the response contains no error messages, redirect
        // error messages will already be printed inside the component at ServiceErrors
        if (!res?.messages) {
          window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.ENROLL_CONFIRMATION_PAGE));
        } else {
          const isEmailAlreadyInUse = res.messages.some((msg) => isDuplicateEmailError(msg.code));
          if (isEmailAlreadyInUse) {
            showEmailInUseModal();
          }
        }
      });
    }
    return false;
  };

  const handleSignUpChange = (e, input, checked) => {
    setEmailSignUpChecked(checked);
  };

  const getPolicyAnchor = (key, href, text) => (
    <a key={key} className='link link--external' target='_blank' rel='noopener noreferrer' href={href}>
      {text}
    </a>
  );

  const privacyPolicyLink = getPolicyAnchor(
    'privacyPolicyLink',
    utils.config.getUrl(WINDOW_OBJECT_KEYS.PRIVACY_POLICY),
    utils.i18n('enrollment_form_signup_legal_privacy_policy')
  );

  const cookiesPolicyLink = getPolicyAnchor(
    'cookiesPolicyLink',
    utils.config.getUrl(WINDOW_OBJECT_KEYS.COOKIE_POLICY),
    utils.i18n('enrollment_form_signup_legal_cookies_policy')
  );

  return (
    <React.Fragment>
      <Form
        onSubmit={handleFormSubmit}
        validate={customPasswordValidation}
        subscription={{ submitting: true, pristine: true, submitFailed: true }}
        getDirtyInputs={(dirtyInputsValues) => setDirtyInputs(dirtyInputsValues)}
      >
        {({ form, handleSubmit, submitFailed }) => (
          <form onSubmit={handleSubmit} className='enrollment__form component-theme--light' noValidate>
            {!isOpenShowEmailInUseModal && <ServiceErrors statePath={GMI_SERVICE_PATHS.CREATE_PROFILE} />}
            {submitFailed && !gmiUtils.isObjectEmpty(form.getState().errors) && (
              <>
                <FormErrors />
              </>
            )}
            <div className='enrollment__form-body'>
              <div className='enrollment__section-header'>
                <h3 className='enrollment__section-title'>{utils.i18n('enrollment_form_account_information')}</h3>
                <span className='enrollment__required-note'>{utils.i18n('enrollment_form_required_note')}</span>
              </div>
              <div className='enrollment__field-row'>
                <TextField
                  name='first_name'
                  label={utils.i18n('enrollment_form_first_name')}
                  fill
                  required
                  disabled={isFetching}
                  autoComplete='given-name'
                />
                <TextField
                  name='last_name'
                  label={utils.i18n('enrollment_form_last_name')}
                  fill
                  required
                  disabled={isFetching}
                  autoComplete='family-name'
                />
              </div>
              <div className='enrollment__field-row'>
                <TextField
                  name='email'
                  label={utils.i18n('enrollment_form_email_address')}
                  fill
                  required
                  disabled={isFetching}
                  autoComplete='email'
                  validations={[utils.fieldValidation.email]}
                  forceError={!isOpenShowEmailInUseModal && hasServiceError}
                />
                <PhoneNumberField
                  name='phones[0].phone_number'
                  label={utils.i18n('common_phone_number')}
                  fill
                  required
                  autoComplete='tel'
                  disabled={isFetching}
                  validations={[utils.fieldValidation.phone, utils.fieldValidation.checkPhoneCode]}
                  inputType='phone'
                  values={form.getState()}
                />
              </div>
              <div
                className={cn('enrollment__field-row', {
                  'enrollment__field-row--inline-mobile': !enableCountryField,
                })}
              >
                {enableCountryField ? (
                  <SelectField
                    id={'country_of_residence'}
                    name={'country_of_residence'}
                    label={utils.i18n('enrollment_form_country_label')}
                    options={countries}
                    disabled={isFetching}
                    labelKey={'country_name'}
                    valueKey={'country_code'}
                    required
                    includeHiddenOption
                    dataDtmTrack={utils.analytics.dtm(ANALYTICS.ENROLL, 'change_country', 'update')}
                  />
                ) : (
                  <>
                    <p className='enrollment__country-display'>
                      {utils.i18n('enrollment_form_country_display', [domainCountryName])}
                    </p>
                    <Button link className='link--text' onClick={() => setEnableCountryField(true)}>
                      {utils.i18n('enrollment_form_country_change')}
                    </Button>
                  </>
                )}
              </div>

              {/* Password Section */}
              <div className='enrollment__section-header'>
                <h3 className='enrollment__section-title'>{utils.i18n('enrollment_form_create_password')}</h3>
              </div>
              <div className='enrollment__field-row'>
                <PasswordField
                  name='password'
                  label={utils.i18n('enrollment_form_password')}
                  fill
                  required
                  disabled={isFetching}
                  autoComplete='current-password'
                  descriptionValue={IDS.passwordRequirementsList}
                />
              </div>
              <div id={IDS.passwordRequirementsList} className='enrollment__field-row'>
                <PasswordRequirements
                  password='password'
                  firstName='first_name'
                  lastName='last_name'
                  setPasswordValid={setIsPasswordValid}
                />
              </div>

              {/* Email Sign Up */}
              <div className='enrollment__sign-up'>
                <Checkbox
                  initialValue={emailSignUpChecked}
                  name='email_sign_up'
                  label={utils.i18n('enrollment_form_signup_label')}
                  disabled={isFetching}
                  theme={THEMES.LIGHT}
                  onChange={handleSignUpChange}
                  dataDtmAnalytics={utils.analytics.dtm(
                    ANALYTICS.ENROLL,
                    'email_sign_up',
                    emailSignUpChecked ? 'checked' : 'unchecked'
                  )}
                />
                {emailSignUpChecked && (
                  <span className='enrollment__sign-up__success'>{utils.i18n('enrollment_form_signup_checked')}</span>
                )}

                <p className='enrollment__sign-up__legal'>
                  {utils.i18n(
                    'enrollment_form_signup_legal',
                    {
                      0: privacyPolicyLink,
                      1: cookiesPolicyLink,
                    },
                    { jsx: true }
                  )}
                </p>
              </div>

              {/* Terms and Conditions + ReCaptcha + Join button */}
              <div className='enrollment__footer'>
                <Checkbox
                  initialValue={false}
                  name='accept_terms_and_conditions'
                  label={utils.i18n(
                    'enrollment_form_terms_and_conditions_label',
                    {
                      0: <LoyaltyTermsConditionsLink />,
                    },
                    { jsx: true }
                  )}
                  theme={THEMES.LIGHT}
                  required={utils.i18n('field_required_dont_forget_to', [
                    utils.i18n('enrollment_form_terms_and_conditions_error'),
                  ])}
                  disabled={isFetching}
                  validate={utils.fieldValidation.isTrueBool}
                />
                <Button
                  type='submit'
                  className='enrollment__join-button'
                  disabled={isFetching}
                  data-dtm-track={utils.analytics.dtm(
                    ANALYTICS.ENROLL,
                    'main_cta',
                    utils.i18n('enrollment_form_join_button')
                  )}
                >
                  {utils.i18n('enrollment_form_join_button')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
      <EmailAlreadyInUseModal />
    </React.Fragment>
  );
};

Enrollment.defaultProps = {
  countries: [],
  isFetching: false,
};

Enrollment.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  createProfile: PropTypes.func,
  getCountries: PropTypes.func,
  showEmailInUseModal: PropTypes.func,
  isOpenShowEmailInUseModal: PropTypes.bool,
};

export default Enrollment;
