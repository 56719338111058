import React from 'react';
import utils from 'utils';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import THEMES from 'constants/themes';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import { skipTheCounterStart } from 'actions/skipTheCounter';
import useActions from 'hooks/useActions';
import Button from 'components/Button';

const { STC_ORIGIN } = SKIP_THE_COUNTER;

const maybeLaterHandler = () => {
  window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RENTAL_DETAILS));
};

/**
 * SkipTheCounterBridgeInfo
 * Skip-the-Counter Bridge Page section that contains useful information about STC along
 *  with a CTA that prompts the user to either start the STC flow or exit to Rental Details.
 *
 * @return {JSX} CheckInSkipTheCounterBridge jsx component
 */
const SkipTheCounterBridgeInfo = () => {
  const skipTheCounterAction = useActions(skipTheCounterStart);

  const startSkipTheCounterHandler = () => {
    skipTheCounterAction({ origin: STC_ORIGIN.ONLINE_CHECK_IN_FLOW });
  };

  return (
    <article className='skip-the-counter-bridge__content'>
      <h3>{utils.i18n('check_in_stc_bridge_trip_eligible')}</h3>

      <h4 className='skip-the-counter-bridge__content__about-title'>{utils.i18n('check_in_stc_bridge_about')}</h4>
      <section className='skip-the-counter-bridge__content__about'>
        <p>{utils.i18n('check_in_stc_bridge_about_description')}</p>

        <p className='skip-the-counter-bridge__content__list-title'>
          {utils.i18n('check_in_stc_bridge_instructions_title')}
        </p>
        <ul className='skip-the-counter-bridge__content__list'>
          <li className='skip-the-counter-bridge__content__list-item'>
            {utils.i18n('check_in_stc_bridge_instructions_1')}
          </li>
          <li className='skip-the-counter-bridge__content__list-item'>
            {utils.i18n('check_in_stc_bridge_instructions_2')}
          </li>
        </ul>
      </section>

      <h4 className='skip-the-counter-bridge__content__cta'>{utils.i18n('check_in_stc_bridge_cta')}</h4>
      <div className='skip-the-counter-bridge__content__buttons-row'>
        <Button
          theme={THEMES.MEDIUM}
          onClick={startSkipTheCounterHandler}
          data-dtm-track='check_in|complete|skip_counter'>
          {utils.i18n('check_in_stc_bridge_confirm')}
        </Button>
        <Button
          theme={THEMES.MEDIUM}
          ghosted
          onClick={maybeLaterHandler}
          data-dtm-track='check_in|complete|skip_counter_later'>
          {utils.i18n('check_in_stc_bridge_maybe_later')}
        </Button>
      </div>
    </article>
  );
};

export default SkipTheCounterBridgeInfo;
