import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import FormattedPrice from 'components/FormattedPrice';
import { RESERVATIONS } from 'constants';
import Button from 'components/Button';

const { TAXES_FEES_INCLUDED_STATUS } = RESERVATIONS;

function getLineItemPriceOrStatus(total_amount_view, status, asterisk) {
  if (status === TAXES_FEES_INCLUDED_STATUS) {
    return utils.i18n('rental_details_status_included');
  }

  return utils.getFormattedPrice(total_amount_view, { asterisk });
}

function renderViewDetailsButton(handleViewDetailsModal) {
  return (
    utils.gmi.types(handleViewDetailsModal).isFunction && (
      <Button
        plain
        type='button'
        onClick={handleViewDetailsModal}
        className='link rental-details__toggle-details-modal'
        aria-label={utils.i18n('rental_details_view_details')}
      >
        {utils.i18n('rental_details_view_details')}
      </Button>
    )
  );
}

const TaxesFees = ({
  isReview,
  taxesDrawerExpanded = true,
  priceSummary,
  asterisk,
  handleViewDetailsModal,
  handleToggleTaxesDrawer,
  isThirdPartyReservation,
  itemList,
}) => (
  <div className='rental-details__section rental-details__taxes'>
    <p className='rental-details__section-label'>
      {isReview ? (
        <>
          <Button
            plain
            type='button'
            aria-expanded={taxesDrawerExpanded}
            onClick={handleToggleTaxesDrawer}
            className={cn('rental-details__drawer-label', { expanded: taxesDrawerExpanded })}
          >
            {utils.i18n('rental_details_taxes')}
          </Button>
          {!taxesDrawerExpanded && (
            <FormattedPrice classNames='total'>
              {/* TODO: replace with secret_rate flag */}
              {isThirdPartyReservation && (parseInt(priceSummary?.amount, 10) === 0 || !priceSummary)
                ? utils.i18n('third_party_reservation_net_rate')
                : utils.getTaxesFeesTotal(priceSummary, asterisk)}
            </FormattedPrice>
          )}
        </>
      ) : (
        <>
          <span>{utils.i18n('rental_details_taxes')}</span>
          {renderViewDetailsButton(handleViewDetailsModal)}
        </>
      )}
    </p>
    <table className={cn('rental-details__taxes-drawer print-visible', { visible: taxesDrawerExpanded })}>
      <caption className='vis-hidden'>{utils.i18n('rental_details_taxes')}</caption>
      <tbody>
        {priceSummary?.payment_line_items?.FEE?.map(({ description, total_amount_view, status }, index) => (
          <tr className='rental-details__taxes-line-item-row' key={`${description}_${index}`}>
            <th align='left'>{description}</th>
            <td align='right'>
              <FormattedPrice classNames='rental-details__line-item-price'>
                {/* TODO: replace with secret_rate flag */}
                {isThirdPartyReservation && (parseInt(total_amount_view, 10) === 0 || !total_amount_view)
                  ? utils.i18n('third_party_reservation_net_rate')
                  : getLineItemPriceOrStatus(total_amount_view, status, asterisk)}
              </FormattedPrice>
            </td>
          </tr>
        ))}
        {isReview && (
          <tr className='rental-details__taxes-line-item-row'>
            <th align='left'>{renderViewDetailsButton(handleViewDetailsModal)}</th>
            <td align='right' />
          </tr>
        )}
        {priceSummary?.payment_line_items?.TAX?.map(({ description, total_amount_view, status }, index) => (
          <tr className='rental-details__taxes-line-item-row' key={`${description}_${index}`}>
            <th align='left'>{description}</th>
            <td align='right'>
              <FormattedPrice classNames='rental-details__line-item-price'>
                {isThirdPartyReservation && (parseInt(total_amount_view, 10) === 0 || !total_amount_view)
                  ? utils.i18n('third_party_reservation_net_rate')
                  : getLineItemPriceOrStatus(total_amount_view, status, asterisk)}
              </FormattedPrice>
            </td>
          </tr>
        ))}

        {itemList?.map(({ description, total_amount_view, status }, index) => (
          <tr className='rental-details__taxes-line-item-row' key={`${description}_${index}`}>
            <th align='left'>{description}</th>
            <td align='right'>
              <FormattedPrice classNames='rental-details__line-item-price'>
                {isThirdPartyReservation && (parseInt(total_amount_view, 10) === 0 || !total_amount_view)
                  ? utils.i18n('third_party_reservation_net_rate')
                  : getLineItemPriceOrStatus(total_amount_view, status, asterisk)}
              </FormattedPrice>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

TaxesFees.propTypes = {
  isReview: PropTypes.bool,
  taxesDrawerExpanded: PropTypes.bool,
  priceSummary: PropTypes.object,
  asterisk: PropTypes.bool,
  handleViewDetailsModal: PropTypes.func,
  handleToggleTaxesDrawer: PropTypes.func,
  isThirdPartyReservation: PropTypes.bool,
  itemList: PropTypes.array,
};

export default TaxesFees;
