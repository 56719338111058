import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';
import cn from 'classnames';
import utils from 'utils';
import { safeExecute } from 'utils/validation';
import { LOCATIONS, RESERVATIONS, MODAL, MODAL_THEMES, ANALYTICS, GMI_SERVICE_PATHS } from 'constants';
import Button from 'components/Button';
import FilterToggle from 'components/SortFilterWrapper/FilterToggle';
import StoreFinderNoResults from 'components/StoreFinderNoResults/StoreFinderNoResults';
import NoResultsPartnerDisplay from 'components/NoResultsPartnerDisplay/NoResultsPartnerDisplay';
import LocationMap from 'components/LocationFinder/LocationMap';
import LocationSearch from 'components/LocationSearch';
import Modal from 'components/Modal';
import PrerateAdditionalInfoModalContent from 'components/PrerateAdditionalInfoModalContent';
import BookWithPartnerModal from 'components/modals/BookWithPartnerModal';
import UnavailableLocationModalContent from 'components/UnavailableLocationModalContent';
import VehicleClassFilterModal from 'components/modals/VehicleClassFilterModal';
import ServiceErrors from 'components/ServiceErrors';
import LocationTypeFilterModal from 'components/modals/LocationTypeFilterModal';
import GenericNotification from 'components/GenericNotification';
import ClearAllButton from 'components/SortFilterWrapper/ClearAllButton';
import LocationFilters from './LocationFilters';
import MapLocationDetail from './MapLocationDetail';

const { EXCHANGE_TYPE_PICKUP, EXCHANGE_TYPE_RETURN, EXCHANGE_TYPE_ACCESSOR_OBJECT } = RESERVATIONS;

/* *
 *  This component has two main parts the store list (<MapLocationDetail/>) and a map part (<LocationMap/>).
 *  The stores showing in the map are listed in the store list
 *  We have three main states - a selected location, hovered, and expanded state.
 *  A location is selected by clicking either the pin on the map or a store in the store list
 *  This disables the hover state.  To expand a store you must click the details arrow to expand
 *  a store on the store list.  We keep the ID's of the store, which are from GBO/solr in state
 *  here to know which one is hovered, selected, and expanded.
 */

const defaultState = {
  hasAlamoLocations: false,
  hasPartnerLocations: false,
  expandedLocationId: null,
  selectedLocationId: null,
  hoveredLocationId: null,
  filtersExpanded: false,
  autoShowPartnerResults: false,
  showPartnerResultsToggle: false,
  isMapCentered: true,
  isUserDraggedOrZoom: false,
  mobileMapViewFocused: false,
  mobileShowLocationDetails: false,
  allAlamoLocationsSoldOut: false,
  allLocationsSoldOut: false,
  showPartnerResultsAndLocations: false,
};

class LocationFinder extends Component {
  state = defaultState;

  _markerEventInstanceObjKey = null; // Google Map specific marker mouse interaction object key

  pageStatus = document.getElementById('pageStatus');

  componentDidMount() {
    const { selectedLocation, setLocation, uiPickupLocation, showSoldOutNearbyLocations } = this.props;
    const queryParams = utils.gmi.getObjFromStrPairs(window.location.search);

    // assume if we have a longCode query param we should be expecting location data in the query string
    if (!utils.gmi.isObjectEmpty(queryParams) && queryParams.longCode) {
      const { longCode, latitude, longitude } = queryParams;
      const locationData = { name: decodeURIComponent(longCode), gps: { latitude, longitude } };

      // dispatch action to set this as the selected location
      setLocation(locationData);

      // In the case a user directly hits the Store Finder page _with_ a location in the query string
      // AND the location is already in session storage (which happens after the first load @see setLocation),
      // we want to fire the `getBranches` function to properly populate the map. This is needed since the initial
      // redux store is hydrated with any data from session storage (@see reducers/locationFinder.js)
      this.getBranches(latitude && longitude ? locationData.gps : selectedLocation.gps);
    } else if (selectedLocation) {
      // showSoldOutNearbyLocations would be true if it's coming from sold out modal in res
      if (uiPickupLocation && showSoldOutNearbyLocations) {
        this.getBranches(uiPickupLocation.gps);
      } else {
        this.getBranches(selectedLocation.gps);
      }
    }

    // To ensure assistive technology users or to notify that the page has been updated, within Reservation flow
    if (this.pageStatus) {
      this.pageStatus.setAttribute('aria-live', 'polite');
      this.pageStatus.innerText = utils.i18n('location_finder_title');
    }

    setTimeout(() => {
      if (this.pageStatus) {
        this.pageStatus.innerText = '';
        this.pageStatus.setAttribute('aria-live', 'off');
      }
    }, 15000);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedLocation,
      isLocationWithNoVehiclesAvailable,
      openSoldOutModal,
      showSoldOutNearbyLocations,
      uiPickupLocation,
      locations,
      reservationFlow,
      updatedSoldOutDateTime,
      adjustLocationId,
      isPickupTimeInThePast,
      isProgressBarExpanded,
      dispatchMobileExpanded,
      dispatchExpandedTab,
      breakpoint,
      isAvailableLocations,
      overlayText,
    } = this.props;

    if (uiPickupLocation && !prevProps.showSoldOutNearbyLocations && showSoldOutNearbyLocations) {
      this.getBranches(uiPickupLocation.gps);
    } else {
      // compare locations by ID primitive value, not reference to reference
      selectedLocation &&
        !isLocationWithNoVehiclesAvailable &&
        (!prevProps.selectedLocation || selectedLocation.id !== prevProps.selectedLocation.id) &&
        this.getBranches(selectedLocation.gps);
    }

    if (!prevProps.isLocationWithNoVehiclesAvailable && isLocationWithNoVehiclesAvailable) {
      openSoldOutModal();
    }

    if (locations.length !== prevProps.locations.length) {
      const soldOutEnabled = reservationFlow && utils.config.getIsSoldOutTrueAvailability();

      const allLocationsSoldOut = this.getAllLocationsSoldOut(soldOutEnabled, locations);

      const allAlamoLocationsSoldOut = this.getAllLocationsSoldOut(
        soldOutEnabled,
        locations.filter(({ brand }) => brand === LOCATIONS.BRAND_ALAMO)
      );

      const updatedLocationStillSoldOut =
        updatedSoldOutDateTime &&
        locations.filter(({ id }) => adjustLocationId === id)[0]?.location_availability ===
          LOCATIONS.UNAVAILABLE_LOCATIONS;

      this.setState({ allAlamoLocationsSoldOut, allLocationsSoldOut, updatedLocationStillSoldOut });
    }

    // On reservation/initiate if the response returns an error with code INVALID_PICK_UP_TIME_IN_THE_PAST, then open Progress Bar.
    if (isPickupTimeInThePast && prevProps.isPickupTimeInThePast !== isPickupTimeInThePast && !isProgressBarExpanded) {
      const { isMobile } = breakpoint;
      dispatchExpandedTab('datesTimes');
      isMobile && dispatchMobileExpanded(isMobile);
    }

    // Adds text to loading-overlay "Finding your locations", while fetching the available location data
    if (!isAvailableLocations) {
      overlayText?.(utils.i18n('finding_your_locations'));
    }
  }

  //  When the user clicks a map pin or a location detail tile it is considered selected, we use that function for that.
  //  We pass the location's ID, which is the ID of the store coming from Solr/GBO
  handleSelectedLocation = (selectedLocationId) => {
    this.setState({
      selectedLocationId,
    });
  };

  getBranches = (gps, isUserDraggedOrZoom = false, radius = 36) => {
    const {
      fetchLocations,
      storeKey,
      reservationInfo = [],
      showingPartnerLocations,
      reservationFlow,
      setCurrentTimestamp,
      numOfVehicleSelected,
      selectedLocation,
    } = this.props;
    const { latitude, longitude } = gps;
    const { pickupDate, pickupTime, dropoffDate, dropoffTime } = reservationInfo;
    const postalCode = selectedLocation?.address?.postal;
    //  reservationInfo includes dates and times of pickup + return
    fetchLocations(
      gps,
      radius,
      storeKey,
      pickupDate,
      pickupTime,
      dropoffDate,
      dropoffTime,
      reservationFlow,
      postalCode
    ).then((response) => {
      const { brands_in_result = [] } = response;
      const hasAlamoLocations = brands_in_result.indexOf(LOCATIONS.BRAND_ALAMO) !== -1;
      const hasPartnerLocations = brands_in_result.length > 1;
      const autoShowPartnerResults = !hasAlamoLocations;
      const showPartnerResultsToggle =
        (isUserDraggedOrZoom && !hasAlamoLocations) || (autoShowPartnerResults && !showingPartnerLocations);
      const showPartnerResultsAndLocations = autoShowPartnerResults && showingPartnerLocations;

      if (autoShowPartnerResults) {
        // Update redux state when location search results in only partner locations
        this.props.showPartnerResults();
      }

      this.setState(
        {
          expandedLocationId: defaultState.expandedLocationId,
          hoveredLocationId: defaultState.hoveredLocationId,
          filtersExpanded: numOfVehicleSelected > 0,
          showPartnerResults: defaultState.showPartnerResults,
          autoShowPartnerResults,
          showPartnerResultsToggle,
          center: { lat: latitude, lng: longitude },
          isUserDraggedOrZoom,
          hasAlamoLocations,
          hasPartnerLocations,
          showPartnerResultsAndLocations,
        },
        () => {
          setCurrentTimestamp();
        }
      );
    });
  };

  setSelectedLocationData = (location, exchangeType) => {
    this.setState({
      currentLocation: location,
      exchangeType,
    });
  };

  /**
   * @private
   * Gets the MouseEvent instance from the gmap marker event object
   */
  _getMarkerMouseEventInstanceObjKey = (e) => {
    const findMouseOrTouchEvent = (key) => key && (utils.dom.isMouseEvent(e[key]) || utils.dom.isTouchEvent(e[key]));
    const [mouseEventKey] = Object.keys(e).filter(findMouseOrTouchEvent);
    return mouseEventKey;
  };

  handleSelectLocation = (location) => () => {
    const {
      reservationFlow,
      showReturnLocation,
      updateReservationFlowLocation,
      openModal,
      setLocationDataUnavailableLocationModal,
      reservationInfo,
      resolveBranchURL,
      one_way_rental,
      isInitiateDateTimeisChanged,
      isDateTimeInitiateRequestValues,
      onDateChange,
      onTimeChange,
    } = this.props;
    if (reservationFlow) {
      const exchangeType = showReturnLocation ? EXCHANGE_TYPE_RETURN : EXCHANGE_TYPE_PICKUP;
      //  default errors for modal to false

      const pickupDateAndHoursMessage =
        location.additional_data[EXCHANGE_TYPE_ACCESSOR_OBJECT[EXCHANGE_TYPE_PICKUP]].validityType;
      const returnDateAndHoursMessage =
        location.additional_data[EXCHANGE_TYPE_ACCESSOR_OBJECT[EXCHANGE_TYPE_RETURN]].validityType;

      let pickupDateHoursInvalid = false;
      let returnDateHoursInvalid = false;

      if (!one_way_rental || (one_way_rental && exchangeType === EXCHANGE_TYPE_PICKUP)) {
        pickupDateHoursInvalid = !utils.fieldValidation.hasError(pickupDateAndHoursMessage);
      }

      if (!one_way_rental || (one_way_rental && exchangeType === EXCHANGE_TYPE_RETURN)) {
        returnDateHoursInvalid = !utils.fieldValidation.hasError(returnDateAndHoursMessage);
      }

      //  checking if we have an error or can just update reservation
      if (pickupDateHoursInvalid || returnDateHoursInvalid) {
        //  Setting state on reservation flow comp, to show in modal
        const { pickupDate, pickupTime, dropoffDate, dropoffTime } = reservationInfo;
        setLocationDataUnavailableLocationModal({
          location,
          pickupDate,
          pickupTime,
          dropoffDate,
          dropoffTime,
        });
        openModal(MODAL.INVALID_DATE_FOR_RES_FLOW);
        this.setSelectedLocationData(location, exchangeType);
      } else {
        if (isInitiateDateTimeisChanged && isDateTimeInitiateRequestValues) {
          const { pickupDate, pickupTime, returnDate, returnTime } = isDateTimeInitiateRequestValues;
          onDateChange(pickupDate, EXCHANGE_TYPE_PICKUP);
          onTimeChange(pickupTime, EXCHANGE_TYPE_PICKUP);
          onDateChange(returnDate, EXCHANGE_TYPE_RETURN);
          onTimeChange(returnTime, EXCHANGE_TYPE_RETURN);
        }

        updateReservationFlowLocation(location, exchangeType);
      }
    } else {
      const { type, id } = location;
      if (type !== LOCATIONS.TYPE_CITY && type !== LOCATIONS.TYPE_GEOLOCATION) {
        resolveBranchURL(id);
        utils.scrollLock({ toggleValue: false });
      }
    }
  };

  handleExpandLocation = (expandedLocationId) => {
    this.setState({
      expandedLocationId,
      mobileMapViewFocused: false,
    });
    this.props.breakpoint.isMobile && utils.scrollLock({ toggleValue: !!expandedLocationId });
  };

  // applied to cards and map icons
  handleMouseEnterLocation = (id) => {
    this.setState({ hoveredLocationId: id });
  };

  // applied to cards and map icons
  handleMouseLeaveLocation = () => {
    this.setState({ hoveredLocationId: defaultState.hoveredLocationId });
  };

  handleMarkerEvents = (e, locationId) => {
    // If this._markerEventInstanceObjKey is initially null, find the key and retain it in instance
    !this._markerEventInstanceObjKey && (this._markerEventInstanceObjKey = this._getMarkerMouseEventInstanceObjKey(e));
    // Accessibility - To navigate map with a keyboard for screen reader users
    e.domEvent.type === 'keydown' ? this.setState({ selectedLocationId: locationId }) : null;
    switch (
      e.domEvent.type === 'keydown' ? e.domEvent.type : e[this._markerEventInstanceObjKey].type // Accessibility - to handle KeyboardEvent //e.domEvent.type === 'keydown' ? e.type
    ) {
      case 'mouseover':
      case 'keypress':
        this.setState({ hoveredLocationId: locationId });
        break;
      case 'mouseout':
        this.setState({ hoveredLocationId: null });
        break;
      case 'click':
      case 'touchend':
      case 'pointerup':
      case 'keydown': // Accessibility - to make selection on KeyboardEvent
        this.setState({ selectedLocationId: locationId });
        break;
      default:
    }
  };

  handleToggleFilterExpanded = () => {
    this.setState(({ filtersExpanded }) => ({ filtersExpanded: !filtersExpanded }));
  };

  resetTimeStamp = () => {
    this.props.setCurrentTimestamp();
  };

  // by default, only showing ALAMO brand locations
  handleToggleShowPartnerLocations = () => {
    const { showPartnerResults, setCurrentTimestamp, composeFilters, reservationFlow, storeKey } = this.props;
    showPartnerResults();
    composeFilters(storeKey, reservationFlow);
    setCurrentTimestamp();
    this.setState({ showPartnerResultsToggle: false });
  };

  handleHidePartnerResultsToggle = () => {
    this.setState({ showPartnerResultsToggle: false, showPartnerResultsAndLocations: false });
  };

  handleMapPanZoomChange = utils.gmi.debounce((bounds) => {
    const { center } = bounds;
    const gps = { latitude: center.lat(), longitude: center.lng() };
    this.getBranches(gps, true); // radius * 0.001 * 0.7 Convert radius meters to kilometers and to 70% of radius
  }, 1200);

  handleToggleMapViewFocused = () => {
    this.setState(({ mobileMapViewFocused }) => ({
      mobileMapViewFocused: !mobileMapViewFocused,
    }));
  };

  handleFitBounds = () => {
    const { selectedLocation } = this.props;
    selectedLocation && this.getBranches(selectedLocation.gps);
  };

  handleClearAll = (locationTypeFilter) => (e) => {
    const { storeKey, currentFilters, handleClearAllFilter } = this.props;
    handleClearAllFilter(storeKey, locationTypeFilter);
    safeExecute(this.resetTimeStamp, currentFilters, e);
  };

  /**
   * This is a callback hook for when users toggle the
   * close button of the location details tiles on the map in mobile view
   */
  handleDetailsTileClose = () => {
    this.setState({ expandedLocationId: null });
  };

  calculateDistance = (storeLocation) => {
    const { selectedLocation, countryOfResidence } = this.props;
    const currentLocation = selectedLocation?.gps;
    let distance = '';
    // Miles conversion 1km = 0.62137119mi
    const measurement = countryOfResidence === 'US' ? 'miles' : 'kilometers';
    if (currentLocation && storeLocation) {
      // Determine miles conversion
      distance = utils.gmi.getKilometersBetweenPoints(currentLocation, storeLocation);
      if (measurement === 'miles') {
        distance = utils.locations.convertKilometersToMiles(distance);
      }
      // Determine rounding and display rules
      // additional number coercion to avoid situations like 3.0
      // https://stackoverflow.com/a/18358056/3352956
      distance = distance < 10 ? +distance.toFixed(1) : +distance.toFixed(0);
    }
    return utils.i18n('map_location_detail_tile_distance_label', [distance, measurement]);
  };

  renderControls = (allLocationsSoldOut, showAdjustedSoldOut) => {
    const {
      breakpoint,
      locations,
      selectedLocation,
      showLocationSearch,
      storeKey,
      setLocation,
      unsetLocation,
      showReturnLocation,
      one_way_rental,
      toggleShowReturnLocation,
      reservationFlow,
      showingPartnerLocations,
      locationFilters,
      isAvailableLocations,
      dispatchMobileExpanded,
      dispatchExpandedTab,
      isProgressBarExpanded,
      numFiltersSelected,
      numOfVehicleSelected,
    } = this.props;
    const {
      filtersExpanded,
      hasAlamoLocations,
      isUserDraggedOrZoom,
      autoShowPartnerResults,
      showPartnerResultsToggle,
      hasPartnerLocations,
      showPartnerResultsAndLocations,
    } = this.state;
    const isSmall = breakpoint.isMobile;
    const onHandleToggleShowPartnerLocations = this.handleToggleShowPartnerLocations;
    const onHandleHidePartnerResultsToggle = this.handleHidePartnerResultsToggle;
    // On clicking the `Recenter Map` pill button (located on the map),
    // `showPartnerResultsToggle` reset to false hence user are not able see `NoResultsPartnerDisplay/StoreFinderNoResults` component,
    // creating state `showPartnerResultsAndLocations` to display `NoResultsPartnerDisplay/StoreFinderNoResults` component
    // if `autoShowPartnerResults && showingPartnerLocations` are true
    const filterTitle = `${locations.length} ${utils.pluralHandler(
      locations.length,
      'location_tiles_header_singular',
      'location_tiles_header_plural',
      true
    )}`;
    let title = 'location_finder_title';

    if (reservationFlow && one_way_rental) {
      title = showReturnLocation ? 'location_finder_choose_return_location' : 'location_finder_choose_pickup_location';
    }

    return (
      <>
        <div className='location-store-finder__controls location-store-finder__list-width'>
          {showReturnLocation && (
            <Button
              link
              data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, 'back_to_pickup', ANALYTICS.BACK)}
              className='link--arrow-reverse'
              onClick={toggleShowReturnLocation}
            >
              {utils.i18n('location_finder_back_to_pickup_location')}
            </Button>
          )}
          <div className='location-store-finder__heading'>
            {!showLocationSearch && (
              <ServiceErrors statePath={GMI_SERVICE_PATHS.RESERVATIONS_INITIATE} runAllHandlers />
            )}
            <h2 className='location-store-finder__title'>{utils.i18n(title)}</h2>
            {!isSmall && (
              <div className='location-store-finder__filterToggle'>
                {(numFiltersSelected > 0 || numOfVehicleSelected > 0) && (
                  <ClearAllButton hideDesktopHeader handleClearAll={this.handleClearAll('locationTypeFilter')} />
                )}
                <FilterToggle
                  showVehicleCount={true}
                  storeKey={storeKey}
                  open={filtersExpanded}
                  onToggle={this.handleToggleFilterExpanded}
                />
              </div>
            )}
          </div>
          {!isSmall && (
            <LocationFilters
              open={filtersExpanded}
              filterConfig={locationFilters}
              resultsCount={locations.length}
              storeKey={storeKey}
              onClose={this.handleToggleFilterExpanded}
              onChange={this.resetTimeStamp}
              locations={locations}
            />
          )}
          {showLocationSearch && (
            <LocationSearch
              name='location-search'
              selectedLocation={selectedLocation}
              setLocation={setLocation}
              unsetLocation={unsetLocation}
              type={LOCATIONS.LOCATION_SEARCH_TYPES.LOCATION_FINDER}
            />
          )}

          <LocationTypeFilterModal
            filterConfig={locationFilters}
            resultsCount={locations.length}
            storeKey={storeKey}
            onChange={this.resetTimeStamp}
          />
          <VehicleClassFilterModal onChange={this.resetTimeStamp} />
        </div>

        {!breakpoint.isTabletOrMobile && allLocationsSoldOut && !showAdjustedSoldOut && (
          <GenericNotification
            messageClass='generic-notification__sold-out-icon'
            bannerClass='generic-notification__sold-out'
            title={utils.i18n('common_sold_out').toUpperCase()}
            dtmTrack={utils.analytics.dtm(ANALYTICS.LOCATION_PAGE, ANALYTICS.SOLD_OUT, ANALYTICS.UI_BANNER)}
            message={hasPartnerLocations ? utils.i18n('sold_out_area_partner_locations') : utils.i18n('sold_out_area')}
          />
        )}

        {!breakpoint.isTabletOrMobile && showAdjustedSoldOut && (
          <GenericNotification
            messageClass='generic-notification__no-icon'
            bannerClass='generic-notification__sold-out'
            message={utils.i18n('adjusted_location_sold_out')}
          />
        )}

        {!hasAlamoLocations && (showPartnerResultsToggle || showPartnerResultsAndLocations) && (
          <div className='location-store-finder__list-width'>
            {locations.length ? (
              <NoResultsPartnerDisplay
                shouldShowCloseButton={showingPartnerLocations}
                handleToggleShowPartnerLocations={onHandleToggleShowPartnerLocations}
                handleHidePartnerResultsToggle={onHandleHidePartnerResultsToggle}
              />
            ) : (
              <StoreFinderNoResults
                isUserDraggedOrZoom={isUserDraggedOrZoom}
                isReservationFlow={reservationFlow}
                setMobileExpanded={dispatchMobileExpanded}
                setExpandedTab={dispatchExpandedTab}
                isProgressBarExpanded={isProgressBarExpanded}
                breakpoint={breakpoint}
              />
            )}
          </div>
        )}

        {isSmall &&
          isAvailableLocations &&
          (hasAlamoLocations || showingPartnerLocations || autoShowPartnerResults) && (
            <>
              <div className='map-locations__heading location-store-finder__list-width'>
                <span>{!!locations.length && filterTitle}</span>
                <div className='location-store-finder__filterToggle'>
                  {(numFiltersSelected > 0 || numOfVehicleSelected > 0) && (
                    <ClearAllButton hideDesktopHeader handleClearAll={this.handleClearAll('locationTypeFilter')} />
                  )}
                  <FilterToggle
                    showVehicleCount={true}
                    storeKey={storeKey}
                    open={filtersExpanded}
                    onToggle={this.handleToggleFilterExpanded}
                  />
                </div>
              </div>
              <LocationFilters
                open={filtersExpanded}
                filterConfig={locationFilters}
                resultsCount={locations.length}
                storeKey={storeKey}
                onClose={this.handleToggleFilterExpanded}
                onChange={this.resetTimeStamp}
                locations={locations}
              />
            </>
          )}

        {!isSmall && !!locations.length && (hasAlamoLocations || showingPartnerLocations || autoShowPartnerResults) && (
          <div className='map-locations__heading location-store-finder__list-width'>
            <span>{filterTitle}</span>
          </div>
        )}
      </>
    );
  };

  getAllLocationsSoldOut = (soldOutEnabled, locations) =>
    soldOutEnabled &&
    locations.length > 0 &&
    locations.every(({ location_availability }) => location_availability === LOCATIONS.UNAVAILABLE_LOCATIONS);

  render() {
    const {
      brands_in_result,
      breakpoint,
      locations,
      selectedLocation,
      reservationFlow,
      showReturnLocation,
      one_way_rental,
      updateReservationFlowLocation,
      showingPartnerLocations,
      defaultCountry,
      timestamp,
      dispatchExpandedTab,
      dispatchMobileExpanded,
    } = this.props;
    const {
      autoShowPartnerResults,
      expandedLocationId,
      hoveredLocationId,
      mobileMapViewFocused,
      mobileShowLocationDetails,
      isUserDraggedOrZoom,
      center,
      hasAlamoLocations,
      hasPartnerLocations,
      exchangeType,
      currentLocation,
      selectedLocationId,
      allAlamoLocationsSoldOut,
      allLocationsSoldOut,
      updatedLocationStillSoldOut,
    } = this.state;
    const isMediumDown = breakpoint.isTabletOrMobile;
    //  Decides when a map tile should be show, on the map view - only happens when it is on a tablet screen or down + has been selected
    //  to deselect we click the map (not the map pins, that would select one)
    const showLocationDetailTile = isMediumDown && selectedLocationId !== null;
    const mapLocationsToDisplay =
      hasAlamoLocations || (isUserDraggedOrZoom && showingPartnerLocations) || autoShowPartnerResults ? locations : [];

    const soldOutEnabled = reservationFlow && utils.config.getIsSoldOutTrueAvailability();

    const showAdjustedSoldOut = soldOutEnabled && updatedLocationStillSoldOut;

    return (
      <div className='location-store-finder'>
        <Modal
          modalKey={MODAL.INVALID_DATE_FOR_RES_FLOW}
          theme={MODAL_THEMES.BLUE}
          header={utils.i18n('unavailable_location_modal_main_header')}
        >
          <UnavailableLocationModalContent
            showReturnLocation={showReturnLocation}
            updateReservationFlowLocation={updateReservationFlowLocation}
            locationName={currentLocation && currentLocation.name}
            locationIdSelectedInUnavailableLocationModal={currentLocation && currentLocation.id}
            location={currentLocation}
            exchangeType={exchangeType}
            one_way_rental={one_way_rental}
          />
        </Modal>
        {isMediumDown && this.renderControls(allLocationsSoldOut, showAdjustedSoldOut)}
        <div
          className={cn('location-store-finder__content', {
            'map-focused': mobileMapViewFocused,
            'show-details': mobileShowLocationDetails,
          })}
        >
          <div className='location-store-finder__location-display-section'>
            {!isMediumDown && this.renderControls(allLocationsSoldOut, showAdjustedSoldOut)}
            <MapLocationDetail
              locations={locations}
              brandsInResult={brands_in_result}
              hasAlamoLocations={hasAlamoLocations}
              hasPartnerLocations={hasPartnerLocations}
              expandedLocationId={expandedLocationId}
              handleSelectedLocation={this.handleSelectedLocation}
              selectedLocationId={selectedLocationId}
              hoveredLocationId={hoveredLocationId}
              showReturnLocation={showReturnLocation}
              reservationFlow={reservationFlow}
              onSelectLocation={this.handleSelectLocation}
              onExpandLocation={this.handleExpandLocation}
              onMouseEnterLocation={this.handleMouseEnterLocation}
              onMouseLeaveLocation={this.handleMouseLeaveLocation}
              onHandleToggleShowPartnerLocations={this.handleToggleShowPartnerLocations}
              showPartnerResults={showingPartnerLocations}
              autoShowPartnerResults={autoShowPartnerResults}
              mobileMapViewFocused={mobileMapViewFocused}
              selectedLocation={selectedLocation}
              calculateDistance={this.calculateDistance}
              resetTimeStamp={this.resetTimeStamp}
              allLocationsSoldOut={allLocationsSoldOut}
              allAlamoLocationsSoldOut={allAlamoLocationsSoldOut}
              breakpoint={breakpoint}
              showAdjustedSoldOut={showAdjustedSoldOut}
            />
          </div>
          <div className='location-store-finder__map-display-section'>
            <LocationMap
              resetTimeStamp={this.resetTimeStamp}
              breakpoint={breakpoint}
              locations={mapLocationsToDisplay}
              selectedLocation={selectedLocation}
              handleSelectedLocation={this.handleSelectedLocation}
              selectedLocationId={selectedLocationId}
              hoveredLocationId={hoveredLocationId}
              showCurrentLocation
              isUserDraggedOrZoom={isUserDraggedOrZoom}
              zoom={10}
              center={center}
              defaultCountry={defaultCountry}
              showLocationDetailTile={showLocationDetailTile}
              onSelectLocation={this.handleSelectLocation}
              onExpandLocation={this.handleExpandLocation}
              onMarkerEvents={this.handleMarkerEvents}
              onDragEnd={this.handleMapPanZoomChange}
              onZoomChanged={this.handleMapPanZoomChange}
              onFitBounds={this.handleFitBounds}
              onDetailsTileClose={this.handleDetailsTileClose}
              timestamp={timestamp}
              isVisible={isMediumDown ? mobileMapViewFocused : true}
              isReservationFlow={reservationFlow}
              setMobileExpanded={dispatchMobileExpanded}
              setExpandedTab={dispatchExpandedTab}
            />
          </div>
        </div>

        {isMediumDown && (
          <Button
            data-dtm-track={utils.analytics.dtm(
              ANALYTICS.UI_BUTTON,
              'location',
              mobileMapViewFocused ? 'listicon' : 'mapicon'
            )}
            className={cn('location-store-finder__map-toggle pill', { 'map-focused': mobileMapViewFocused })}
            onClick={this.handleToggleMapViewFocused}
          >
            {mobileMapViewFocused ? utils.i18n('list') : utils.i18n('map')}
          </Button>
        )}

        <Modal
          modalKey={MODAL.PRE_RATE_ADDITIONAL_INFO_MODAL}
          header={utils.i18n('prerate_add_additional_info_main_title')}
          theme={MODAL_THEMES.WHITE}
        >
          <PrerateAdditionalInfoModalContent />
        </Modal>
        <BookWithPartnerModal />
      </div>
    );
  }
}

LocationFinder.propTypes = {
  locations: PropTypes.array,
  showLocationSearch: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  one_way_rental: PropTypes.bool,
  setReservationError: PropTypes.func,
  setSelectedLocationData: PropTypes.func,
  breakpoint: PropTypes.object,
  isLocationWithNoVehiclesAvailable: PropTypes.bool,
  openSoldOutModal: PropTypes.func,
  showSoldOutNearbyLocations: PropTypes.bool,
  uiPickupLocation: PropTypes.object,
  uiReturnLocation: PropTypes.object,
  dispatchShowSoldOutNearbyLocations: PropTypes.func,
  updateReservationFlowLocation: PropTypes.func.isRequired,
  dispatchMobileExpanded: PropTypes.func,
  dispatchExpandedTab: PropTypes.func,
  adjustLocationId: PropTypes.string,
  composeFilters: PropTypes.func,
  updatedSoldOutDateTime: PropTypes.bool,
  showVehicleClassFilterModal: PropTypes.func,
  showLocationTypeFilterModal: PropTypes.func,
  overlayText: PropTypes.func,
  isAvailableLocations: PropTypes.bool,
  handleClearAllFn: PropTypes.func,
};

LocationFinder.defaultProps = setDefaultProps({
  locations: {},
  showLocationSearch: false,
});

export default LocationFinder;
