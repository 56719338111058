import React, { useState, useEffect } from 'react';
import useActions from 'hooks/useActions';
import Modal from 'components/Modal';
import { MODAL_THEMES } from 'constants/modals';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { openModal } from "actions/modal/openModal";

/**
 * LinkModal - This component recieves content from AEM. First, it gets linkText and modalCaasUrl then it uses modalCaasUrl
 * to retrieve the html content for modal body and modal title (modal header).
 *
 * @param {object} props - React Props
 * @param {}
 * @return {JSX}
 */

const LinkModal = props => {
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [modalLinkText, setModalLinkText] = useState(props.linkText);

  const open = useActions(key => openModal(key));

  useEffect(
    () => {
      if (typeof props.modalCaasUrl === 'undefined') {
        return;
      }
      const url = `/api/assets${props.modalCaasUrl}`;
      fetch(url)
        .then(data => data.json())
        .then(res => {
          setTitle(res.properties.elements['modal-heading'].value);
          if (typeof modalLinkText === 'undefined') {
            setModalLinkText(res.properties.elements['modal-heading'].value);
          }
          setValue(res.properties.elements['modal-text'].value);
        });
    },
    [props.modalCaasUrl, modalLinkText]
  );

  return props.modalCaasUrl ? (
    <>
      <Button onClick={() => open(title)} link linkText className='link-modal-group__button'>
        {modalLinkText}
      </Button>
      <Modal modalKey={title} header={title} theme={MODAL_THEMES.WHITE}>
        <div className='modal-themed__paragraph' dangerouslySetInnerHTML={{ __html: value }} />
      </Modal>
    </>
  ) : null;
};

LinkModal.defaultProps = {};
LinkModal.propTypes = {
  modalCaasUrl: PropTypes.string,
  linkText: PropTypes.string,
  caasTag: PropTypes.string,
};
export default LinkModal;
