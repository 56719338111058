import React from 'react';
import utils from 'utils';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import ANALYTICS from 'constants/analytics';
import { MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';
import useActions from 'hooks/useActions';

import { addAdditionalDriverByName } from "actions/reservation/addAdditionalDriverByName";
import { closeModal } from "actions/modal/closeModal";

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const CidConflictModal = () => {
  const [handleAccept, handleCloseModal] = useActions([addAdditionalDriverByName, closeModal]);

  return (
    <Modal
      modalKey={MODAL.CID_CONFLICT_MODAL}
      header={utils.i18n('cid_conflict_modal_title')}
      theme={MODAL_THEMES.WHITE}>
      {() => (
        <div className='modal-themed__cid-conflict-modal'>
          <div className='modal-themed__horizontal-separator' />
          <p className='modal-themed__paragraph'>{utils.i18n('cid_conflict_modal_description')}</p>
          <div className='modal-themed__cid-conflict-modal-footer'>
            <ModalFooter
              fullWidthButton
              buttons={[
                {
                  type: DECLINE,
                  label: utils.i18n('common_remove_contract'),
                  handler: handleCloseModal,
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      ANALYTICS.MODAL,
                      utils.analytics.prepareKey(MODAL.CID_CONFLICT_MODAL),
                      ANALYTICS.CANCEL
                    ),
                },
                {
                  type: ACCEPT,
                  label: utils.i18n('common_continue'),
                  handler: handleAccept,
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      ANALYTICS.MODAL,
                      utils.analytics.prepareKey(MODAL.CID_CONFLICT_MODAL),
                      ANALYTICS.CANCEL
                    ),
                },
              ]}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CidConflictModal;
