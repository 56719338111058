import React from 'react';
import PropTypes from 'prop-types';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL_BUTTON_TYPES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import utils from 'utils';

/**
 * ReturnDirectionsModal - shows the return location address
 *
 * @param {object} contentProps - Modal content props
 * @param {string} contentProps.return_directions - GBO return location object
 * @return {JSX}
 */

const ReturnDirectionsModalContent = ({ return_directions }) => (
  <>
    <div className='padding-top--condensed'>
      <p>{utils.i18n('return_directions_modal_description')}</p>
      <p className='modal-themed__paragraph'>
        {return_directions?.street_addresses?.map((line, idx) => <span key={idx}>{line}</span>)}
        <br />
        {return_directions?.city}, {return_directions?.country_subdivision_code} {return_directions?.postal}
      </p>
    </div>
    {return_directions?.street_addresses && (
      <ModalFooter
        buttons={[
          {
            type: MODAL_BUTTON_TYPES.EXTERNAL_LINK,
            label: utils.i18n('modal_view_map'),
            linkTo: utils.locations.getGoogleMapsURL(utils.locations.addressObjToSingleLine(return_directions)),
            analyticsValue: ANALYTICS.VIEW_MAP,
          },
        ]}
      />
    )}
  </>
);

ReturnDirectionsModalContent.propTypes = {
  return_directions: PropTypes.object,
};

export default ReturnDirectionsModalContent;
