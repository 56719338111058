import React from 'react';
import { trimSpaceFromStringValueOfObject } from 'utils/object';
import { dtm } from 'utils/analytics';
import { i18n } from 'utils/i18n';
import ANALYTICS from 'constants/analytics';
import MODAL from 'constants/modals';
import useActions from 'hooks/useActions';
import Button from 'components/Button';
import ServiceErrors from 'components/ServiceErrors';
import ReservationLookupForm from 'components/ReservationLookupForm';
import FindingReservationTipsModal from 'components/modals/FindingReservationTipsModal';
import { retrieveReservation } from "actions/reservation/retrieveReservation";
import { openModal } from "actions/modal/openModal";

/**
 * Reservation Lookup Generic React Component
 *
 * @return {JSX}
 */
const SearchReservation = () => {
  const retrieveAction = useActions(retrieveReservation);
  const showFindingReservationTipsModal = useActions(() => openModal(MODAL.FINDING_RESERVATION_TIPS_MODAL));

  const onFormSubmit = ({ reservationData }) => {
    const trimStringValuesObj = trimSpaceFromStringValueOfObject(reservationData);
    retrieveAction(trimStringValuesObj);
  };

  return (
    <section className='rental-lookup theme--light-blue'>
      <span className='rental-lookup__legend' aria-hidden={true}>
        {i18n('review_required_indication')}
      </span>
      <ServiceErrors />
      <ReservationLookupForm
        onFormSubmit={onFormSubmit}
        submitDTMTrack={dtm(ANALYTICS.FIND_RESERVATION, ANALYTICS.VIEW_MODIFY_CANCEL, ANALYTICS.SEARCH)}
      />

      <Button
        link
        data-dtm-track={dtm(ANALYTICS.UI_TOOLTIP, i18n('reservation_lookup_tips_modal_button'), ANALYTICS.OPEN)}
        className='rental-lookup__tips-button'
        onClick={showFindingReservationTipsModal}
        ariaText={i18n('reservation_lookup_tips_modal_button')}
      >
        <span>{i18n('reservation_lookup_tips_modal_button')}</span>
      </Button>

      <FindingReservationTipsModal />
    </section>
  );
};

export default SearchReservation;
