import React from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { breakpointSelector } from 'selectors/breakpoint';
import { stepConfigSelector } from 'selectors/skipTheCounterSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import LinkModalGroupCustomPath from 'components/LinkModalGroupCustomPath';

/**
 * Skip The Counter Info Component
 * Sidebar that displays the current step info during Skip The Counter Flow.
 *
 * @return {JSX} SkipTheCounterStepInfo jsx component
 */
const SkipTheCounterSubHeader = () => {
  const { title, description, currentStepCounter } = useSelector(stepConfigSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const breakpoints = useSelector(breakpointSelector);

  const { isTabletOrMobile } = breakpoints;

  const stepCounterLine = i18n('check_in_step_count', [currentStepCounter, 2]);
  const stepCounterARIALabel = currentStepCounter ? `${stepCounterLine}, ${title}` : title;

  return (
    <section className='skip-the-counter-template__sidebar'>
      <h2 className='skip-the-counter-template__step-title' aria-label={stepCounterARIALabel}>
        {title}
      </h2>
      <div>
        <p className='skip-the-counter-template__description'>{description}</p>
      </div>
      {isCustomPathReservation && !isTabletOrMobile && <LinkModalGroupCustomPath />}
    </section>
  );
};

export default SkipTheCounterSubHeader;
