import React from 'react';
import PropTypes from 'prop-types';

/**
 * ReceiptRentalSideBlockList - List structure
 * @param {object} props - React Props
 * @param {element} props.children - Side block list items
 * @return {JSX} ReceiptRentalSideBlockList rendered
 */
const ReceiptRentalSideBlockList = ({ children }) => <tr className='receipt-side-block-list'>{children}</tr>;

ReceiptRentalSideBlockList.propTypes = {
  children: PropTypes.element,
};

export default ReceiptRentalSideBlockList;
