import PropTypes from 'prop-types';

export default {
  reservation: PropTypes.object,
  vehicleRate: PropTypes.object,
  extras: PropTypes.array,
  showLearnMoreModal: PropTypes.func,
  showViewDetailsModal: PropTypes.func,
  prepayFlopCurrentSession: PropTypes.func,
  selectedBundleWithExtras: PropTypes.object,
  extrasWithoutBundles: PropTypes.array,
  numberOfExtrasSelected: PropTypes.number,
  coupons: PropTypes.array.isRequired,
  showCouponDoesNotApplyModal: PropTypes.func,
};
