import React from 'react';
import utils from 'utils';
import Picture from 'components/Picture';
import propTypes from './VehicleDetailsSummaryPropTypes';

const VehicleDetailsSummary = props => {
  const {
    carClass,
    carModel,
    carAsset,
    carAssetMobile,
    carAssetTablet,
    carAssetAlt,
    electric,
    transmissionType,
    numOfPassengers,
    numOfBags,
    twoOrFourDoorText,
    includes,
  } = props;
  return (
    <section className='vehicle-details-summary'>
      <div className='vehicle-details-summary__header'>
        <section>
          <h2 className='vehicle-details-summary__title vehicle-details-summary__title--main'>
            {utils.i18n('vehicle_details', [carClass])}
          </h2>
          <span>{utils.i18n('vehicle_details_model_or_similar', [carModel])}</span>
        </section>

        <div className='vehicle-details-summary__image-container'>
          <img className='vehicle-details-summary__image--background' alt={carAssetAlt} />
          <Picture
            className='vehicle-details-summary__image--car'
            src={carAsset}
            alt={carAssetAlt}
            srcMobile={carAssetMobile}
            srcTablet={carAssetTablet}
          />
        </div>
      </div>

      <div className='vehicle-details-summary__content'>
        <section className='vehicle-details-summary__content-section vehicle-details-summary__specifications'>
          <h3 className='vehicle-details-summary__title vehicle-details-summary__title--border'>
            {utils.i18n('vehicle_details_specifications')}
          </h3>
          <ul>
            {electric && (
              <li
                vehicle-details-icon-list__icon--sr-only
                title={utils.i18n('vehicle_details_electric')}
                className='vehicle-details-summary__icon vehicle-details-summary__icon--electric'>
                {electric}
              </li>
            )}
            {transmissionType && (
              <li
                title={utils.i18n('vehicle_details_transmission')}
                className='vehicle-details-summary__icon vehicle-details-summary__icon--transmission'>
                {transmissionType}
              </li>
            )}
            {numOfPassengers && (
              <li
                title={utils.i18n('vehicle_details_passenger_capacity')}
                className='vehicle-details-summary__icon vehicle-details-summary__icon--passenger'>
                {numOfPassengers}
                {utils.pluralHandler(numOfPassengers, 'vehicle_details_passenger', 'vehicle_details_passengers')}
              </li>
            )}
            {numOfBags && (
              <li
                title={utils.i18n('vehicle_details_baggage_capacity')}
                className='vehicle-details-summary__icon vehicle-details-summary__icon--suitcase'>
                {numOfBags} {utils.pluralHandler(numOfBags, 'vehicle_details_bag', 'vehicle_details_bags')}
              </li>
            )}
            {twoOrFourDoorText && (
              <li
                title={utils.i18n('vehicle_details_number_of_doors')}
                className='vehicle-details-summary__icon vehicle-details-summary__icon--car-door'>
                {twoOrFourDoorText} {utils.i18n('vehicle_details_doors')}
              </li>
            )}
          </ul>
        </section>

        <section className='vehicle-details-summary__content-section vehicle-details-summary__includes'>
          <h3 className='vehicle-details-summary__title vehicle-details-summary__title--border'>
            {utils.i18n('vehicle_details_also_includes')}
          </h3>
          <ul>
            {includes &&
              includes.map((item, index) => (
                <li
                  key={`includes_${index}`}
                  className='vehicle-details-summary__icon vehicle-details-summary__icon--checkmark'>
                  {item}
                </li>
              ))}
          </ul>
        </section>
      </div>
    </section>
  );
};

VehicleDetailsSummary.propTypes = propTypes;

export default VehicleDetailsSummary;
