import { connect } from 'react-redux';
import MODAL from 'constants/modals';
import RESERVATION_LOOKUP from 'constants/reservationLookup';
import CHECKIN from 'constants/checkin';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import { reservationLookup } from 'actions/reservationLookup';
import { breakpointSelector } from 'selectors/breakpoint';
import { retrieveReservation } from "actions/reservation/retrieveReservation";
import { openModal } from "actions/modal/openModal";
import TripCardAcceleratedCheckin from './TripCardAcceleratedCheckin';

const { STC_ORIGIN } = SKIP_THE_COUNTER;
const { CHECKIN_ORIGIN } = CHECKIN;
const { LOOK_UP_TYPE } = RESERVATION_LOOKUP;

const mapStateToProps = (state, ownProps) => ({
  isAcceleratedCheckinInactive: !ownProps.userAlreadyCheckedIn,
  additionalDriverDataNotProvided: false, // TBD in ticket that takes care of only this piece
  breakpoint: breakpointSelector(state),
});

const mapDispatchToProps = {
  activateCheckIn: reservationData =>
    reservationLookup({
      reservationData,
      origin: CHECKIN_ORIGIN.RESERVATION_TILE,
      lookupType: LOOK_UP_TYPE.CHECKIN_LOOK_UP,
    }),
  activateSkipTheCounter: reservationData =>
    reservationLookup({
      reservationData,
      origin: STC_ORIGIN.RESERVATION_TILE,
      lookupType: LOOK_UP_TYPE.STC_LOOK_UP,
    }),
  openLearnMoreAboutSkipTheCounterModal: () => openModal(MODAL.LEARN_MORE_ABOUT_SKIP_THE_COUNTER_MODAL),
  openLearnMoreAboutSkipTheCounterEligibleModal: () =>
    openModal(MODAL.LEARN_MORE_ABOUT_SKIP_THE_COUNTER_ELIGIBLE_MODAL),
  openLearnMoreAboutSkipTheCounterIneligibleModal: () =>
    openModal(MODAL.LEARN_MORE_ABOUT_SKIP_THE_COUNTER_INELIGIBLE_MODAL),
  openLearnMoreAboutOCIInactiveModal: () => openModal(MODAL.LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_INACTIVE_MODAL),
  retrieveReservation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripCardAcceleratedCheckin);
