import { connect } from 'react-redux';
import { actions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import { MODAL, GLOBAL, LOCATIONS } from 'constants';
import {
  handleInitiate,
  setContractNumber,
  setAbandonedRes,
  setLocation,
  setPartnerRewardProgram,
  setPartnerRewardProgramMemberId,
  getAgeRangeDefaults,
  getAgeRangeDefaultsByLocation,
  setDiscountCodeExpanded,
} from 'actions/bookingWidget';
import { locationDateTimeSelectors } from 'components/BookingWidget/bookingWidgetDateTimeFieldGroupSelectors';
import { driversAgeInitialValueSelector, breakpointSelector, profileDataSelector } from 'selectors';
import { getProgressBarLocationInitiateSelector } from 'selectors/progressBarSelectors';
import {
  getInitiateRequest,
  preSelectedCouponsSelector,
  preSelectedContractNumberSelector,
  getIsDeeplink,
  getReservationRentalState,
  reservationSessionLDT,
} from 'selectors/reservationSelectors';
import { getReservationInitiateError } from 'selectors/errorSelectors';
import { getDiscountCodesExpandedTab } from 'selectors/reservationFlowSelectors';
import { getUiInitiateState } from 'selectors/uiStateSelectors';
import { getSelectedVehiclesFilterSelector } from 'selectors/vehicleSelectSelectors';
import { clearVehiclesFilterData, setSelectedCurrencyToggleData } from 'actions/vehicleClass';
import { setCoupon } from 'actions/reservation/setCoupon';
import { removeInitiateResObject } from 'actions/reservation/removeInitiateResObject';
import { openModal } from 'actions/modal/openModal';
import { openModalWithProps } from 'actions/modal/openModalWithProps';
import { setIsGmiUIOneWayRental } from 'actions/reservation/setIsGmiUIOneWayRental';
import BookingWidget from './BookingWidget';

const { initiateState } = actions.reservations;

const mapStateToProps = (state, ownProps) => {
  const isBranchWidget = ownProps.componentProperties?.pickupLocationDetails?.type === LOCATIONS.TYPE_BRANCH;
  const initiate = getUiInitiateState(state);
  const locationDateTimes = locationDateTimeSelectors(state, ownProps);
  const initiateRequest = getInitiateRequest(state);
  const preSelectedCoupons = preSelectedCouponsSelector(state);
  const preSelectedContractNumber = preSelectedContractNumberSelector(state);
  const rentalState = getReservationRentalState(state);
  const reservationSessionInitialState = reservationSessionLDT(state);

  return {
    isDeeplink: getIsDeeplink(state),
    productCode: initiate?.product_code,
    showProductCode: initiateRequest?.show_product_code,
    contract_number: initiate?.contract_number || preSelectedContractNumber,
    coupons: initiate?.coupons || preSelectedCoupons,
    one_way_rental: initiate?.one_way_rental,
    pickup_location: initiate?.pickup_location,
    pickup_location_id: initiate?.pickup_location_id,
    pickupLocationFromProps: ownProps.pickupLocationDetails,
    renter_age: initiate?.renter_age,
    return_location: initiate?.return_location,
    return_location_id: initiate?.return_location_id,
    ...locationDateTimes,
    driversAgeInitialValue: driversAgeInitialValueSelector(state),
    breakpoint: breakpointSelector(state),
    rentalStateConfirmed: rentalState === GLOBAL.UPCOMING,
    profileData: profileDataSelector(state, { age: true, partnerRewardsProgram: true, email_update_required: true }),
    vehicleSearchFilters: getSelectedVehiclesFilterSelector(state),
    discountCodesExpanded: getDiscountCodesExpandedTab(state),
    reservationInitiateError: getReservationInitiateError(state),
    initiateRequestDetails: { ...initiateRequest },
    reservationObjectInitialValues: reservationSessionInitialState,
    uiInitiateRequestalues: initiate,
    isBranchWidget,
    didProgressBarLocationInitiate: getProgressBarLocationInitiateSelector(state),
  };
};

const mapDispatchToProps = {
  handleInitiate,
  clearDate: (exchangeType) => initiateState.unset('date', { exchangeType }),
  clearTime: (exchangeType) => initiateState.unset('time', { exchangeType }),
  setDate: (timestamp, exchangeType) => initiateState.set('date', { timestamp, exchangeType }),
  setTime: (timestamp, exchangeType) => initiateState.set('time', { timestamp, exchangeType }),
  setCoupon,
  setContractNumber,
  setBundleProfile: (bundle_profile) => initiateState.set('bundle_profile', { bundle_profile }),
  setLocation,
  unsetLocation: (exchangeType) => initiateState.unset('location', { exchangeType }),
  setAbandonedRes,
  setPartnerRewardProgram,
  setPartnerRewardProgramMemberId,
  showFrequentTravelerRecoveryFeeModal: () => openModal(MODAL.FREQUENT_TRAVELER_RECOVERY_FEE_MODAL),
  showUpdateProfileWithPRPModal: () => openModal(MODAL.PRP_AUTH_ADD_PROGRAM_MODAL),
  showAuthConflictPRPModal: () => openModal(MODAL.PRP_AUTH_CONFLICT_MODAL),
  showCidConflictModal: () => openModal(MODAL.CID_CONFLICT_MODAL),
  getAgeRangeDefaults,
  getAgeRangeDefaultsByLocation,
  removeInitiateResObject,
  openRemoveProductCodeModal: (productCode, onConfirm) =>
    openModalWithProps(MODAL.REMOVE_PRODUCT_CODE_MODAL, {
      productCode,
      onConfirm,
    }),
  clearInitialVehicleClassFilterData: () => clearVehiclesFilterData(),
  setSelectedCurrencyToggleData,
  setDiscountCodeExpanded,
  setGmiUIRenterAge: (renter_age) => initiateState.set('renter_age', { renter_age: parseInt(renter_age, 10) }),
  setIsGmiUIOneWayRental,
};

const enhance = utils.compose(connect(mapStateToProps, mapDispatchToProps), utils.toJS, utils.withClickOutside);

export default enhance(BookingWidget);
