import { MapTo, withComponentMappingContext } from '@adobe/aem-react-editable-components';
import Parsys from './Parsys';

const ParsysEditableConfig = {
  emptyLabel: 'Parsys Container',
  isEmpty: props => !props[':itemsOrder'] || props[':itemsOrder'].length < 1,
};
export default MapTo('aweb/components/content/responsivegrid')(
  withComponentMappingContext(Parsys),
  ParsysEditableConfig
);
