import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { loadingOverlay } from 'utils/dom';
import { i18n } from 'utils/i18n';
import { loadingRedirect } from 'utils/redirect';
import { runOnSuccessResponse } from 'utils/validation';
import { dtm, analyticsError } from 'utils/analytics';
import { config } from 'utils/config';
import { removeApostrophe } from 'utils/string';
import THEMES from 'constants/themes';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import { getUnauthPastTrips } from 'actions/trips';
import { unauthPastTripsSelector } from 'selectors/reservationSelectors';
import Form from 'components/Form/Form';
import ServiceErrors from 'components/ServiceErrors';
import TextField from 'components/Form/TextField';
import Button from 'components/Button';
import Anchor from 'components/Anchor';

const customerServiceUrl = config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT);

/**
 * PastTripsLookup
 *
 * @return {JSX}
 */
const PastTripsLookup = () => {
  const getPastTripsHandler = useActions(getUnauthPastTrips);
  const unauthTrips = useSelector(unauthPastTripsSelector);
  const [noTripsFound, setNoTripsFound] = useState(false);
  const [formHasSubmited, setFormHasSubmited] = useState(false);

  useEffect(
    () => {
      // Only run this check after form submit
      if (!formHasSubmited) {
        return;
      }

      // As GMA will not return an error message if no trips were found,
      // front-end should check to show a custom error message.
      if (gmiUtils.isArrayNotEmpty(unauthTrips)) {
        setNoTripsFound(false);
        loadingRedirect(WINDOW_OBJECT_KEYS.UNAUTH_PAST_TRIPS_PAGE);
      } else {
        setNoTripsFound(true);
        analyticsError(window.location.href, ANALYTICS.FE_PAST_TRIPS_LOOKUP, ANALYTICS.NOT_FOUND);
        loadingOverlay(false);
      }
    },
    [unauthTrips, formHasSubmited]
  );

  const onFormSubmit = ({ driverData }) => {
    const driverLastName = driverData.last_name;
    driverData.last_name = removeApostrophe(driverLastName);

    getPastTripsHandler(driverData).then(runOnSuccessResponse(() => setFormHasSubmited(true)));
  };

  const customerSupportLink = (
    <Anchor className='link--text' key='customer-service-link_0' href={customerServiceUrl} isExternal hideExternalIcon>
      {i18n('common_visit_customer_support')}
    </Anchor>
  );

  return (
    <section className='rental-lookup theme--light-blue'>
      <span className='rental-lookup__legend' aria-hidden={true}>
        {i18n('review_required_indication')}
      </span>
      <ServiceErrors />

      {/* Custom Trips Not Found error message */}
      {noTripsFound && (
        <div className='service-errors'>
          <div className='service-error'>
            <span className={'message-priority--ERROR'} />
            <p>
              <strong>{i18n('unauth_past_trips_not_found_title')}</strong>{' '}
              {i18n('unauth_past_trips_not_found_copy', [customerSupportLink], {
                jsx: true,
              })}
            </p>
          </div>
        </div>
      )}

      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit }) => (
          <form
            className={'rental-lookup-form rental-lookup-form__container theme--dark-blue'}
            onSubmit={handleSubmit}
            autoComplete='off'
            noValidate>
            <div className='rental-lookup-form__row'>
              <TextField
                name='driverData.last_name'
                label={i18n('review_drive_details_last_name_input_label')}
                fill
                required
                errorTheme={THEMES.DARK_BLUE}
              />
            </div>
            <div className='rental-lookup-form__row'>
              <TextField
                name='driverData.driver_license'
                label={i18n('check_in_driver_license_drivers_license_number')}
                fill
                required
                errorTheme={THEMES.DARK_BLUE}
              />

              <Button
                type='submit'
                className='rental-lookup-form__search-button'
                data-dtm-track={dtm(ANALYTICS.FIND_RESERVATION, ANALYTICS.PAST_TRIPS, ANALYTICS.SEARCH)}>
                {i18n('reservation_lookup_search_button')}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </section>
  );
};

export default PastTripsLookup;
