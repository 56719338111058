import React from 'react';
import utils from 'utils';
import { ANALYTICS, MODAL_BUTTON_TYPES } from 'constants';
import { ModalFooter } from 'components/Modal/ModalFooter';
import propTypes from './limitedInventoryVehicleModalContentPropTypes';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const LimitedInventoryVehicleModalContent = ({ handleClose }) => (
  <>
    <p>{utils.i18n('limited_inventory_vehicle_modal_content')}</p>
    <ModalFooter
      buttons={[
        {
          type: DECLINE,
          label: utils.i18n('limited_inventory_modal_decline'),
          analyticsValue: ANALYTICS.NO_THANKS,
        },
        {
          type: ACCEPT,
          label: utils.i18n('limited_inventory_modal_accept'),
          handler: () => {
            // TODO: This is weird, there should be a better way to do analytics for a simple Continue
            handleClose({
              analyticsValue: ANALYTICS.CONTINUE,
            });
          },
        },
      ]}
    />
  </>
);

LimitedInventoryVehicleModalContent.propTypes = propTypes;

export default LimitedInventoryVehicleModalContent;
