import React from 'react';
import utils from 'utils';
import MODAL from 'constants/modals';
import useActions from 'hooks/useActions';
import Button from 'components/Button';
import AlreadySkippingTheCounterHelpModal from 'components/modals/AlreadySkippingTheCounterHelpModal';
import { openModal } from "actions/modal/openModal";

/**
 * AlreadySkippingTheCounterLink
 * Link for "Already Skipping The Counter?" with modal opening to be used in
 * "Need Help" section for Skip The Counter lookup
 *
 * @return {JSX}
 */
const AlreadySkippingTheCounterLink = () => {
  const openTipsModal = useActions(() => openModal(MODAL.ALREADY_SKIPPING_THE_COUNTER_HELP_MODAL));

  return (
    <div>
      <Button onClick={openTipsModal} link linkText>
        {utils.i18n('already_skipping_the_counter_help_title')}
      </Button>
      <AlreadySkippingTheCounterHelpModal />
    </div>
  );
};

export default AlreadySkippingTheCounterLink;
