import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import { getReservationProfileLoyaltyNumber } from 'selectors/reservationSelectors';
import { isLoggedInSelector, profileDataSelector, authenticatedSelector } from 'selectors/profileSelectors';
import { setAssociateReservationData } from 'actions/associate';
import {
  onlineCheckInProfileSelector,
  isReservationWithEqualLastNameAndDifferentFirstName,
} from 'selectors/checkinSelectors';
import Button from 'components/Button';
import THEMES from 'constants/themes';
import { associateReservation } from "actions/reservation/associateReservation";
import { retrieveReservation } from "actions/reservation/retrieveReservation";
import { skipModifyReservationStartRedirect } from "actions/reservation/skipModifyReservationStartRedirect";

const AssociateReservationBanner = props => {
  const {
    confirmationNumber,
    firstName,
    lastName,
    maskedEmail,
    blockAssociateProfile,
    openFirstNameConflictModal,
    profileFirstname,
    unauthReservation,
  } = props;

  // State
  const [checkinDataRetrieved, setCheckinDataRetrieved] = useState(false);
  const [isReservationAssociated, setIsReservationAssociated] = useState(false);
  const [showAssociateBanner, setShowAssociateBanner] = useState(false);

  // Selectors
  const reservationLoyaltyNumber = useSelector(getReservationProfileLoyaltyNumber);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isAuth = useSelector(authenticatedSelector);
  const isResFirstNameDifferentThanOciProfile = useSelector(isReservationWithEqualLastNameAndDifferentFirstName);
  const { license_profile: checkInLicenseProfile } = useSelector(onlineCheckInProfileSelector);
  const { last_name: profileLastName, email: profileEmail, memberNumber, memberProgram, license_profile } = useSelector(
    state =>
      profileDataSelector(state, {
        last_name: true,
        email: true,
        memberNumber: true,
        memberProgram: true,
        license_profile: true,
      }) || {}
  );

  // Action calls
  const modifyReservationStart = useActions(skipModifyReservationStartRedirect);
  const retrieveReservationAction = useActions(retrieveReservation);
  const addAssociateReservation = useActions(associateReservation);
  const setAssociateReservationAction = useActions(setAssociateReservationData);

  const sameDriverLicense = () => {
    const { country_code, license_number } = checkInLicenseProfile || {};
    const { country_code: userCountryCode, license_number: userLicenseNumber } = license_profile || {};
    const equalDL = country_code === userCountryCode && license_number === userLicenseNumber;

    if (!equalDL && !checkinDataRetrieved) {
      setCheckinDataRetrieved(true);
      modifyReservationStart({ redirect: false }).finally(() => utils.dom.loadingOverlay(false));
      return false;
    }

    return equalDL;
  };

  const isAuthLoyaltyProfile = isLoggedIn && memberProgram && memberProgram !== 'Non-Loyalty';
  const sameLastName = () => lastName?.toLowerCase() === profileLastName?.toLowerCase();
  const sameLastNameAndEmail = () => sameLastName() && maskedEmail === profileEmail;
  const sameLastNameAndDL = () => sameLastName() && (reservationLoyaltyNumber === memberNumber || sameDriverLicense());

  /**
   * When the component mounts, we set the status of showing banner based on the conditions
   * i.e isAuthLoyaltyProfile ,  sameLastNameAndEmail , sameLastNameAndDL
   */

  useEffect(
    () => {
      // show banner if below condition matches
      const showBanner =
        (isAuthLoyaltyProfile && (sameLastNameAndEmail() || sameLastNameAndDL()) && !blockAssociateProfile) ||
        (isResFirstNameDifferentThanOciProfile && blockAssociateProfile) ||
        (sameLastName() && !sameDriverLicense && blockAssociateProfile);

      showBanner && setAssociateReservationAction({ confirmation: confirmationNumber, associated: false });
      setShowAssociateBanner(showBanner);
    },
    [isLoggedIn, showAssociateBanner, isResFirstNameDifferentThanOciProfile]
  );

  useEffect(() => {
    const associatedReservationConfirmationNumber = unauthReservation.data?.confirmationNumber;
    associatedReservationConfirmationNumber === confirmationNumber && setIsReservationAssociated(true);
  }, []);

  const handleAcceptAssociateProfile = () => {
    const payload = {
      confirmationNumbers: [confirmationNumber],
      firstNames: firstName,
      lastNames: lastName,
    };

    addAssociateReservation(payload).then(res => {
      if (!res?.messages) {
        /* Push new confirmation number or reset to session storage
           if user refresh the page, check if the account is already associated to profile or not
        */

        setAssociateReservationAction({ confirmationNumber, associated: true });
        setIsReservationAssociated(true);

        analyticsInteraction(ANALYTICS.UI_BANNER, ANALYTICS.ASSOCIATE_ALAMO_ACCOUNT, ANALYTICS.ACCOUNT_ASSOCIATED);
        /* for some reason driverInfo is not being updated from the gbo current payload until we make an retrieve reservation service call
        on making an retrieve reservation everything works fine, even mobile team is following the same.
      */

        retrieveReservationAction(
          {
            firstName: profileFirstname,
            lastName,
            confirmationNumber,
          },
          false
        ).finally(() => utils.dom.loadingOverlay(false));
      }
    });
  };

  const addAssociateReservationToProfile = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.ASSOCIATE_ALAMO_ACCOUNT, ANALYTICS.ASSOCIATE.toLowerCase());

    /* Modal that notifies the user that it's first name will be updated to match existing profile in res.
       Upon Confirng first name will be updated else if user clicks Cancel will close the modal and take no action 
    */
    if (isAuth && isResFirstNameDifferentThanOciProfile) {
      openFirstNameConflictModal?.(handleAcceptAssociateProfile);
    } else {
      handleAcceptAssociateProfile();
    }
  };

  const showAssociateSuccessMessage =
    isLoggedIn && JSON.parse(sessionStorage.getItem(utils.config.reservationAssociatedToProfile));

  if (!showAssociateBanner && !showAssociateSuccessMessage) return null;

  return (
    <div className='confirmation__header__associate-wrapper'>
      {!isReservationAssociated && (
        <>
          <div>
            <h4 className='confirmation__header__associate-title'>{utils.i18n('add_reservation_account')}</h4>
            <p className='confirmation__header__associate-text'>{utils.i18n('add_reservation_account_description')}</p>
          </div>
          <Button
            className='confirmation__header__associate-button'
            theme={THEMES.MEDIUM}
            ghosted
            onClick={addAssociateReservationToProfile}>
            {utils.i18n('associate')}
          </Button>
        </>
      )}

      {isReservationAssociated && showAssociateSuccessMessage && (
        <div>
          <h4 className='confirmation__header__associate-title-success'>{utils.i18n('account_associated')}</h4>
          <p className='confirmation__header__associate-text associate-text-success'>
            {utils.i18n('account_associated_success_saved_to_account')}
          </p>
        </div>
      )}
    </div>
  );
};

AssociateReservationBanner.propTypes = {
  lastName: PropTypes.string,
  maskedEmail: PropTypes.string,
  confirmationNumber: PropTypes.string,
};

export default AssociateReservationBanner;
