import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import MessageBanner from 'components/MessageBanner';
import LimitedInventoryModal from 'components/modals/LimitedInventoryModal';
import Extra from 'components/Extra';

class IndividualExtras extends Component {
  state = {
    hasWarnedAboutLimitedInventoryItem: false,
  };

  setHasWarnedAboutLimitedInventoryItemToTrue = () => {
    this.setState({
      hasWarnedAboutLimitedInventoryItem: true,
    });
  };

  render() {
    const { individualExtras, shouldShowTitle, customTitle, scrollRef, isCheckIn, toggleExtrasLoading } = this.props;
    const { hasWarnedAboutLimitedInventoryItem } = this.state;

    const SubSectionTitleTag = shouldShowTitle ? 'p' : 'h2';
    const { protectionProducts, tripEnhancements } = individualExtras;

    return (
      <>
        <section ref={scrollRef}>
          {shouldShowTitle && <h2>{customTitle || utils.i18n('extras_add_individual_extras')}</h2>}

          <section className='individual-extras individual-extras--protection-products'>
            <SubSectionTitleTag className='individual-extras-subsection-title'>
              {utils.i18n('extras_protection_products')}
            </SubSectionTitleTag>

            {protectionProducts && !!protectionProducts.length ? (
              protectionProducts.map(extra => (
                <Extra key={extra.code} toggleExtrasLoading={toggleExtrasLoading} extra={extra} />
              ))
            ) : (
              <MessageBanner
                title={utils.i18n('extras_no_protection_products_available_title')}
                message={utils.i18n('extras_no_protection_products_available_message')}
              />
            )}
          </section>

          <section className='individual-extras individual-extras--trip-enhancements'>
            <SubSectionTitleTag className='individual-extras-subsection-title'>
              {utils.i18n('extras_trip_enhancements')}
            </SubSectionTitleTag>

            {tripEnhancements && !!tripEnhancements.length ? (
              tripEnhancements.map(extra => (
                <Extra
                  key={extra.code}
                  toggleExtrasLoading={toggleExtrasLoading}
                  hasWarnedAboutLimitedInventoryItem={hasWarnedAboutLimitedInventoryItem}
                  setHasWarnedAboutLimitedInventoryItemToTrue={this.setHasWarnedAboutLimitedInventoryItemToTrue}
                  extra={extra}
                  isCheckIn={isCheckIn}
                />
              ))
            ) : (
              <MessageBanner
                title={utils.i18n('extras_no_trip_enhancements_available_title')}
                message={utils.i18n('extras_no_trip_enhancements_available_message')}
              />
            )}
          </section>
        </section>
        <LimitedInventoryModal />
      </>
    );
  }
}

IndividualExtras.propTypes = {
  // Container Props
  breakpoint: PropTypes.object,
  individualExtras: PropTypes.object,

  // Parent props
  shouldShowTitle: PropTypes.bool,
  customTitle: PropTypes.string,
  scrollRef: PropTypes.object,
  isCheckIn: PropTypes.bool,
  toggleExtrasLoading: PropTypes.func,
};

export default IndividualExtras;
