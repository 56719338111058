import { connect } from 'react-redux';
import utils from 'utils';
import { formattedPoliciesSelector, breakpointSelector } from 'selectors';
import RentalPoliciesModal from './RentalPoliciesModal';

const mapStateToProps = state => ({
  rentalPolicies: formattedPoliciesSelector(state),
  breakpoint: breakpointSelector(state),
});

export default connect(mapStateToProps)(utils.toJS(RentalPoliciesModal));
