import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { actions } from '@ehi/global-marketing-interface';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { i18n } from 'utils/i18n';
import { safeExecute } from 'utils/validation';
import { transformKeyPathIntoObjectStructure, mergeDeep } from 'utils/object';
import useActions from 'hooks/useActions';
import { breakpointSelector } from 'selectors/breakpoint';
import {
  currentFiltersSelector,
  numFiltersSelectedFilterSelector,
  filterSortInfoSelector,
  filteredFilterItemsSelector,
  getUiStateNumberOfPassengersFiltersSelector,
} from 'selectors/uiStateSelectors';
import { locationFinderAnalyticsStateSelector } from 'selectors/appSelectors';
import { skipAnalyticsForLocationTypeFilter } from 'actions/locationFinder';
import { clearVehiclesFilterData } from 'actions/vehicleClass';
import { getNumOfSelectedVehiclesCountSelector } from 'selectors/vehicleSelectSelectors';
import Form from 'components/Form/Form';
import Button from 'components/Button';
import LocationStateDrawer from 'components/LocationStateDrawer';
import { handleClearAllFilter } from "actions/filter/handleClearAllFilter";
import ClearAllButton from './ClearAllButton';
import Filter from './Filter';
import Sort from './Sort/Sort';

const SortFilterWrapper = forwardRef(
  (
    {
      className,
      open,
      theme,
      hideDesktopHeader,
      filterFormRef,
      vertical,
      onClose,
      closeButtonLabel,
      resultsCount,
      onClearAll,
      onChange,
      storeKey,
      filterConfig,
      sortConfig,
      lowestCarFilterPrices,
      hasSorting,
      hasFilters,
      locations,
      innerRef,
      destinationCurrencySelected,
    },
    ref
  ) => {
    const breakpoint = useSelector(breakpointSelector);
    const sortInfo = useSelector((state) => filterSortInfoSelector(state, storeKey));
    const currentFilters = useSelector((state) => currentFiltersSelector(state, storeKey));
    const filteredItems = useSelector((state) => filteredFilterItemsSelector(state, storeKey));
    const numFiltersSelected = useSelector((state) => numFiltersSelectedFilterSelector(state, storeKey));
    const clearFiltersUi = useActions((key) => actions.setFiltersUiByKey(key, []));
    const [currentSortValue, setCurrentSortValue] = useState(sortInfo ? sortInfo.inputValue : sortConfig?.initialValue);
    const [initialFormValues, setInitialFormValues] = useState({});
    const numOfVehicleSelected = useSelector((state) => getNumOfSelectedVehiclesCountSelector(state));
    const clearVehiclesFiltersUi = useActions(() => clearVehiclesFilterData());
    const skipAnalyticsLocationFinder = useActions((bool) => skipAnalyticsForLocationTypeFilter(bool));
    const isSkipAnalyticsForLocationFinder = useSelector((state) => locationFinderAnalyticsStateSelector(state));
    const setSortByValue = useActions(({ sortKey, isDesc, isNumber, value }) =>
      actions.setSortFilteredItemsByValue(storeKey, sortKey, isDesc, isNumber, value)
    );
    const passengersCheckboxesSelected = useSelector(getUiStateNumberOfPassengersFiltersSelector);
    const handleClearAllFilterAction = useActions(handleClearAllFilter);

    const checkFilterInitialValues = (recentValue) => {
      let merged;
      //  Keep default checked values for filters
      currentFilters.forEach((filter) => {
        const [checkedItem] = filter && filter.available.filter((item) => item?.checked === true);
        // Set checked value if there's a checked item, otherwise change it to false
        const value = transformKeyPathIntoObjectStructure(
          filter.keyPath,
          checkedItem?.value ? checkedItem.value : false
        );
        merged = recentValue ? mergeDeep(recentValue, value) : mergeDeep(initialFormValues, value);
      });
      setInitialFormValues(merged);
    };

    useEffect(() => {
      if (hasSorting && hasFilters) {
        const { buttons, name } = sortConfig;
        const initialSorting = { ...initialFormValues, [name]: currentSortValue };
        setInitialFormValues(initialSorting);
        checkFilterInitialValues(initialSorting);
        const initialSortValue = buttons.filter(({ value }) => value === currentSortValue)[0];
        setSortByValue(initialSortValue);
      }
      isSkipAnalyticsForLocationFinder && skipAnalyticsLocationFinder(false);
    }, [hasFilters, currentSortValue, filteredItems.length, numFiltersSelected]);

    const handleSortUpdate = (inputValue, sortValue) => {
      setCurrentSortValue(inputValue);
      setSortByValue(sortValue);
      checkFilterInitialValues();
    };

    const onFormReset = (form) => {
      // reset with sorting initial value
      if (hasSorting) {
        const { initialValue, name } = sortConfig;
        setCurrentSortValue(initialValue);
        setInitialFormValues({ [name]: initialValue });
        form?.reset && form.reset({ [name]: initialValue });
      } else {
        form.reset();
      }
    };

    const handleClearAll = (form, locationTypeFilter = false) => (e) => {
      onFormReset(form);
      handleClearAllFilterAction(storeKey, locationTypeFilter);
      safeExecute(onChange, currentFilters, e);
    };

    useImperativeHandle(ref, () => ({
      clearAllFilter(form) {
        onFormReset(form);
        clearFiltersUi(storeKey);
        safeExecute(onChange, currentFilters);
      },
    }));

    const getButtonText = (defaultButtonText, form) => {
      let buttonText = i18n(defaultButtonText || 'common_close');
      let handleClick = () => onClose?.();

      if (numFiltersSelected > 0) {
        if (!resultsCount) {
          buttonText = i18n('filter_clear_filters_results');
          handleClick = handleClearAll(form);
        } else if (closeButtonLabel && typeof closeButtonLabel === 'string') {
          buttonText = closeButtonLabel.replace('{0}', resultsCount);
        }
      }

      return { buttonText, handleClick };
    };

    const renderCloseButton = (form) => {
      const { buttonText, handleClick } = getButtonText('common_close', form);
      return (
        <div className='filter__close-button-container component-theme--light-blue'>
          <Button className='filter__close-button' theme='light' onClick={handleClick}>
            {buttonText}
          </Button>
        </div>
      );
    };

    const renderDoneButton = (form) => {
      const { buttonText, handleClick } = getButtonText('filter_cta_done', form);
      return (
        <div className='filter__done-button-container'>
          <Button className='filter__done-button' onClick={handleClick}>
            {buttonText}
          </Button>
        </div>
      );
    };

    const getTitleText = () => {
      let titleText = hasSorting ? i18n('sort_and_filter') : i18n('filter_title');

      if (numFiltersSelected > 0) {
        titleText += ` (${numFiltersSelected + passengersCheckboxesSelected})`;
      }

      return titleText;
    };

    return (
      open && (
        <Form
          initialValues={initialFormValues}
          subscription={{ pristine: true }}
          onSubmit={() => false}
        >
          {({ form }) => (
            <div ref={innerRef}>
              {storeKey !== 'location_finder' && (
                <form
                  className={cn('filter', `component-theme--${theme}`, { 'filter--vertical': vertical }, className)}
                >
                  {(breakpoint.isMobile || !hideDesktopHeader) && (
                    <div className='filter__heading'>
                      <h3>{getTitleText()}</h3>
                      {numFiltersSelected > 0 && (
                        <ClearAllButton hideDesktopHeader={hideDesktopHeader} handleClearAll={handleClearAll(form)} />
                      )}
                    </div>
                  )}
                  <React.Fragment>
                    {hasSorting && (
                      <Sort
                        buttons={sortConfig.buttons}
                        updateSort={handleSortUpdate}
                        setSortByValue={setSortByValue}
                        setCurrentValue={setCurrentSortValue}
                        isRequired={false}
                      />
                    )}
                    <Filter
                      ref={filterFormRef}
                      storeKey={storeKey}
                      onChange={onChange}
                      breakpoint={breakpoint}
                      config={filterConfig}
                      hideDesktopHeader={hideDesktopHeader}
                      currentFilters={currentFilters}
                      handleClearAll={handleClearAll(form)}
                      lowestCarFilterPrices={lowestCarFilterPrices}
                      numFiltersSelected={numFiltersSelected}
                      destinationCurrencySelected={destinationCurrencySelected}
                    />
                  </React.Fragment>
                  {breakpoint.isTablet && renderDoneButton(form)}
                  {breakpoint.isMobile && renderCloseButton(form)}
                </form>
              )}
              {storeKey === 'location_finder' && (
                <form className='filter--state-drawer'>
                  <div className='filter__groups'>
                    {hasSorting && (
                      <Sort
                        buttons={sortConfig.buttons}
                        updateSort={handleSortUpdate}
                        setSortByValue={setSortByValue}
                        setCurrentValue={setCurrentSortValue}
                        handleClearAll={handleClearAll(form)}
                        isRequired={false}
                      />
                    )}
                    <LocationStateDrawer
                      open={open}
                      storeKey={storeKey}
                      onChange={onChange}
                      config={filterConfig}
                      currentFilters={currentFilters}
                      locations={locations}
                    />
                  </div>
                </form>
              )}
            </div>
          )}
        </Form>
      )
    );
  }
);

SortFilterWrapper.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  hideDesktopHeader: PropTypes.bool,
  filterFormRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  vertical: PropTypes.bool,
  onClose: PropTypes.func,
  closeButtonLabel: PropTypes.string,
  resultsCount: PropTypes.number,
  onClearAll: PropTypes.func,
  onChange: PropTypes.func,
  storeKey: PropTypes.string.isRequired,
  filterConfig: PropTypes.array.isRequired,
  sortConfig: PropTypes.object,
  lowestCarFilterPrices: PropTypes.object,
  hasSorting: PropTypes.bool,
};

SortFilterWrapper.defaultProps = {
  theme: 'dark-blue',
  hideDesktopHeader: false,
  hasSorting: false,
};

export default SortFilterWrapper;
