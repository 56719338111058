import RENTAL from 'constants/rental';

const { RENTAL_VIEWS } = RENTAL;

export const handledHeaderForRentalModifyFlow = (hashPath) => {
  const mfnTopHeader = document.querySelector('.headerWrapper');

  mfnTopHeader?.classList?.add('hide');

  switch (hashPath) {
    case RENTAL_VIEWS.CONFIRM_MODIFY[0]:
      mfnTopHeader?.classList?.remove('hide');
      break;

    case RENTAL_VIEWS.MODIFY_RENTAL[0]:
      mfnTopHeader?.classList?.remove('hide');
      break;

    case RENTAL_VIEWS.DATE_TIME[0]:
      mfnTopHeader?.classList?.add('hide');
      break;

    case RENTAL_VIEWS.DETAILS[0]:
      mfnTopHeader?.classList?.remove('hide');
      break;

    case RENTAL_VIEWS.CONFIRMED[0]:
      mfnTopHeader?.classList?.remove('hide');
      break;

    case RENTAL_VIEWS.UPDATED[0]:
      mfnTopHeader?.classList?.remove('hide');
      break;

    case RENTAL_VIEWS.COMMIT[0]:
      mfnTopHeader?.classList?.add('hide');
      break;

    default:
      break;
  }
};
