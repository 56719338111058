import React from 'react';
import utils from 'utils';
import Button from 'components/Button';
import { propTypes } from './NoResultsVehicleGridPropTypes';

const NoResultsVehicleGrid = ({ clearFilters }) => (
  <div className='no-results-vehicle-grid__container'>
    <h3 className='no-results-vehicle-grid__main-heading'>{utils.i18n('vehicle_filters_no_results')}</h3>
    <Button
      onClick={clearFilters}
      className='no-results-vehicle-grid__button'
      data-dtm-track='button|car_filters|clear_all'>
      {utils.i18n('vehicle_filters_clear_filters_button')}
    </Button>
  </div>
);

NoResultsVehicleGrid.propTypes = propTypes;

export default NoResultsVehicleGrid;
