import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';

export const propTypes = {// Parent props
  isTrueModify: PropTypes.bool,

  // Container Props
  breakpoint: PropTypes.object.isRequired,
  gmiReservationReady: PropTypes.bool,
  showReturnLocation: PropTypes.bool,
  haveOpenModal: PropTypes.array,
  coupons: PropTypes.array.isRequired,
  expandedTab: PropTypes.string,
  mobileExpanded: PropTypes.bool,
  gmaPickupLocation: PropTypes.object,
  gmaReturnLocation: PropTypes.object,
  gboPickupLocation: PropTypes.object,
  gboReturnLocation: PropTypes.object,
  isFetchingSession: PropTypes.bool,
  lastValidSearchedLocationId: PropTypes.string,};

export const defaultProps = setDefaultProps({});
