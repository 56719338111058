import React from 'react';
import utils from 'utils';
import { useSelector } from 'react-redux';
import { reservationHasAdditionalDriverFreeSpouseSelector } from 'selectors/reservationFlowSelectors';
import { getShowAdditionalDriverIsSpouseSelector } from 'selectors/reservationSelectors';

function SpouseDriverList() {
  const hasAdditionalDriverFreeSpouse = useSelector(reservationHasAdditionalDriverFreeSpouseSelector);
  const isSpouse = useSelector(getShowAdditionalDriverIsSpouseSelector);

  if (!hasAdditionalDriverFreeSpouse) return null;

  return (
    <>
      {hasAdditionalDriverFreeSpouse &&
        ((isSpouse && (
          <div className='additional-drivers__spouse-driver-list'>
            {utils.i18n('added_additional_driver_spouse_description')}
            <span className='additional-drivers__included'>{utils.i18n('rental_details_status_included')}</span>
          </div>
        )) || (
          <p className='additional-drivers__description'>{utils.i18n('add_additional_driver_spouse_description')}</p>
        ))}
    </>
  );
}

export default SpouseDriverList;
