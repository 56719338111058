import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useActions from 'hooks/useActions';
import { locationInfoById } from 'actions/location';
import {
  getReturnDateAndTime,
  getRentalPickupLocation,
  getRentalReturnLocation,
  getRentalPriceSummary,
  selectedPaymentMethodSelector,
  rentalCouponsSelector,
  rentalFormattedPoliciesSelector,
  selectedExtras,
  selectedBundleWithExtrasSelector,
  extrasWithoutBundlesSelector,
  numberOfExtrasSelector,
  selectTotalSummaryList,
  getContractDetails,
  getRentalPayments,
  getRentalMileageInfo,
  getRentalPaymentLineItems,
  getShowCorporateCoverage,
  getRentalBillToSubTotals,
  getLocationInfo,
} from 'selectors/rentalTicketSelectors';
import { breakpointSelector } from 'selectors/breakpoint';
import { getPaymentLines } from 'utils/prices';
import { commit } from 'actions/modifyRental';
import ConfirmModifyCommit from './ConfirmModifyCommit';

const ConfirmModifyCommitContainer = (props) => {
  const locationInfoByIdAction = useActions((locationId) => locationInfoById(locationId));

  const policies = useSelector(rentalFormattedPoliciesSelector);
  const locationInfo = useSelector(getLocationInfo);
  const selectedPaymentMethod = useSelector(selectedPaymentMethodSelector);
  const shouldShowPolicyComponent = policies.length !== 0;
  const returnDateAndTime = useSelector(getReturnDateAndTime);
  const returnLocation = useSelector(getRentalReturnLocation);
  const pickupLocation = useSelector(getRentalPickupLocation);
  const priceSummary = useSelector(getRentalPriceSummary);
  const rentalBillToSubTotals = useSelector(getRentalBillToSubTotals);
  const totalSummaryItems = useSelector(selectTotalSummaryList);
  const extras = useSelector(selectedExtras);
  const selectedBundleWithExtras = useSelector(selectedBundleWithExtrasSelector);
  const extrasWithoutBundles = useSelector(extrasWithoutBundlesSelector);
  const numberOfExtrasSelected = useSelector(numberOfExtrasSelector);
  const coupons = useSelector(rentalCouponsSelector);
  const commitRentalAction = useActions(commit);
  const contractDetails = useSelector(getContractDetails);
  const breakpoint = useSelector(breakpointSelector);
  const payments = useSelector(getRentalPayments);
  const mileageInfo = useSelector(getRentalMileageInfo);
  const paymentLineItems = useSelector(getRentalPaymentLineItems);
  const showCorporateCoverage = useSelector(getShowCorporateCoverage);

  const [policyReady, setPolicyReady] = useState(shouldShowPolicyComponent);

  const { id } = pickupLocation;

  // Action to get rental policies list using locationInfo
  useEffect(() => {
    if (!locationInfo[id] && id && !policyReady) {
      locationInfoByIdAction(id).then((res) => {
        if (!res.messages) {
          setPolicyReady(true);
        }
      });
    }
  }, [id]);

  const renterPaymentLines = useMemo(
    () => getPaymentLines(paymentLineItems, mileageInfo, priceSummary?.renter_subtotal_view, 'RENTER'),
    [JSON.stringify(paymentLineItems), JSON.stringify(payments)]
  );

  const contractNumber = contractDetails?.contract_number;
  const totalViewContract = rentalBillToSubTotals?.find((item) => item.customer_number === contractNumber);

  const estimatedBillToTotalView = totalViewContract?.total_currency || rentalBillToSubTotals?.[0]?.total_currency;
  const billToPaymentLines = useMemo(
    () => getPaymentLines(paymentLineItems, mileageInfo, estimatedBillToTotalView, 'BILLTO'),
    [JSON.stringify(paymentLineItems), JSON.stringify(payments)]
  );

  const history = useHistory();

  const combinedProps = {
    ...props,
    policies,
    selectedPaymentMethod,
    shouldShowPolicyComponent,
    returnTime: returnDateAndTime,
    returnLocation,
    priceSummary,
    extras,
    selectedBundleWithExtras,
    extrasWithoutBundles,
    numberOfExtrasSelected,
    coupons,
    commitRentalAction,
    history,
    totalSummaryItems,
    showCorporateCoverage,
    contractDetails,
    breakpoint,
    renterPaymentLines,
    billToPaymentLines,
    pickupLocation,
    locationInfo,
    locationInfoByIdAction,
  };
  return <ConfirmModifyCommit {...combinedProps} />;
};

export default ConfirmModifyCommitContainer;
