import React from 'react';
import utils from 'utils';
import { ANALYTICS, MODAL_BUTTON_TYPES } from 'constants';
import { ModalFooter } from 'components/Modal/ModalFooter';
import Button from 'components/Button';
import propTypes from './FrequentTravelerRecoveryFeeModalContentPropTypes';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const FrequentTravelerRecoveryFeeModalContent = ({
  handleInitiate,
  handleClose,
  setContractNumber,
  setPartnerRewardProgram,
  setPartnerRewardProgramMemberId,
}) => {
  const handleSubmit = clearPartnerRewardsProgram => {
    if (clearPartnerRewardsProgram) {
      // By calling this action without parameters, this states will be unset and cleared.
      setContractNumber();
      setPartnerRewardProgram();
      setPartnerRewardProgramMemberId();
    }

    handleInitiate().then(() => handleClose({ skipAnalytics: true }));
  };

  return (
    <div className='partner-rewards-modal-content'>
      <p>{utils.i18n('partner_rewards_modal_paragraph_1')}</p>
      <p>{utils.i18n('partner_rewards_modal_paragraph_2')}</p>
      <p>
        <Button onClick={handleClose} button={false} link>
          {utils.i18n('partner_rewards_modal_link')}
        </Button>
      </p>
      <ModalFooter
        fullWidthButton
        buttons={[
          {
            type: DECLINE,
            label: utils.i18n('partner_rewards_modal_cancel_button'),
            handler: () => handleSubmit(true),
            analyticsKey: ANALYTICS.FREQUENT_TRAVELER_RECOVERY_FEE,
            analyticsHandler: () =>
              utils.analytics.interaction(ANALYTICS.MODAL, ANALYTICS.FREQUENT_TRAVELER_RECOVERY_FEE, ANALYTICS.CANCEL),
          },
          {
            type: ACCEPT,
            label: utils.i18n('partner_rewards_modal_accept_button'),
            handler: () => handleSubmit(false),
            analyticsKey: ANALYTICS.FREQUENT_TRAVELER_RECOVERY_FEE,
            analyticsHandler: () =>
              utils.analytics.interaction(
                ANALYTICS.MODAL,
                ANALYTICS.FREQUENT_TRAVELER_RECOVERY_FEE,
                utils.i18n('partner_rewards_modal_accept_button')
              ),
          },
        ]}
      />
    </div>
  );
};

FrequentTravelerRecoveryFeeModalContent.propTypes = propTypes;

export default FrequentTravelerRecoveryFeeModalContent;
