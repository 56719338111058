import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { clearProfile } from 'actions/profile';
import { onlineCheckInProfileSelector, onlineCheckInStatusSelector } from 'selectors/checkinSelectors';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import Form from 'components/Form/Form';
import SignIn from 'components/SignIn';
import { closeModal as closeModalAction } from "actions/modal/closeModal";

const domainCountryCode = utils.config.getDomainCountry();

/**
 * SignInPartialProfile Component
 * In res OCI profile form rendering within In Res OCI wizard modal
 *
 * @param {object} props - React Props
 * @param {func} props.onSubmitSuccess - callback passed by In Res OCI wizard modal, that runs wizard modal functionality
 * @param {func} props.setHasConfirmed - function to set state on InResOciWizardModal to validate if should abortOCI on handleModalClose
 * @param {func} props.setSignedIn - function to set state on InResOciWizardModal to validate if user signed in
 * @param {boolean} props.isFoundProfileWithConflictName - if it's a profile found with different first name
 *
 * @return {JSX} SignInPartialProfile jsx component
 */
const SignInPartialProfile = ({ onSubmitSuccess, setHasConfirmed, setSignedIn, isFoundProfileWithConflictName }) => {
  const clearProfileAction = useActions(clearProfile);
  const closeModal = useActions(closeModalAction);
  const profile = useSelector(onlineCheckInProfileSelector);
  const { driverProfileComplete, contactProfileComplete } = useSelector(onlineCheckInStatusSelector);
  const foundCompleteProfile = driverProfileComplete && contactProfileComplete;
  const { first_name, last_name } = profile.basic_profile;
  const { license_number, country_subdivision_code } = profile.license_profile;

  const driversLicenseCountryCode = profile.license_profile.country_code || domainCountryCode;
  const { selectedCountry } = useCountryFieldsConfig(driversLicenseCountryCode);

  const onSuccessfulSignIn = handleSubmit => () => {
    // TODO: add analytics
    setHasConfirmed(true);
    setSignedIn(true);
    // Really edge case but if user found a partial profile and signed in with complete profile,
    // on sign in needs to close the modal
    foundCompleteProfile ? closeModal() : handleSubmit();
  };

  // user should go to the next step to fill out profile information
  const onCancelSignIn = handleSubmit => () => {
    // TODO: add analytics
    clearProfileAction().then(() => {
      handleSubmit();
    });
  };

  return (
    <Form onSubmit={onSubmitSuccess}>
      {({ handleSubmit }) => (
        <div className='check-in-flow__form component-theme--light'>
          {isFoundProfileWithConflictName && (
            <>
              <div className='check-in-flow__form__section-title'>
                <h4>{utils.i18n('common_name')}</h4>
              </div>
              <p className='check-in-flow__form__info-row'>
                {first_name} {last_name}
              </p>
            </>
          )}
          <div className='check-in-flow__form__partial-profile-driver-details'>
            <div className='check-in-flow__form__section-title'>
              <h4>{utils.i18n('common_drivers_license_details')}</h4>
            </div>
            <p className='check-in-flow__form__info-row'>
              {utils.i18n('check_in_driver_license_issued_by')}: {selectedCountry?.country_name}
              {country_subdivision_code && !utils.fieldValidation.isMasked(country_subdivision_code)
                ? `, ${country_subdivision_code}`
                : ''}
            </p>
            <p className='check-in-flow__form__info-row'>
              {utils.i18n('my_profile_drivers_license_number')}: {license_number}
            </p>
          </div>
          <div className='in-res-oci-modal__signin in-res-oci-modal__signin'>
            <div className='in-res-oci-modal__signin-header'>
              <h4>{utils.i18n('signin_submit')}</h4>
            </div>
            <SignIn onSuccess={onSuccessfulSignIn(handleSubmit)} onCancel={onCancelSignIn(handleSubmit)} />
          </div>
        </div>
      )}
    </Form>
  );
};

SignInPartialProfile.propTypes = {
  onSubmitSuccess: PropTypes.func,
  setHasConfirmed: PropTypes.func,
  setSignedIn: PropTypes.func,
  isFoundProfileWithConflictName: PropTypes.bool,
};

export default SignInPartialProfile;
