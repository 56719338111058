import React, { useEffect } from 'react';
import utils from 'utils';
import { ANALYTICS, MODAL, MODAL_BUTTON_TYPES } from 'constants';
import { ModalFooterWithRouter as ModalFooter } from 'components/Modal/ModalFooter';
import { propTypes } from './editModalContentPropTypes';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const EditModalContent = ({ handleEdit, sectionPath }) => {
  useEffect(() => {
    utils.analytics.interaction(ANALYTICS.MODAL, utils.analytics.prepareKey(MODAL.PREPAY_EDIT_MODAL), ANALYTICS.OPEN);
  }, []);

  return (
    <div className='prepayment-edit-modal__container'>
      <p>{utils.i18n('prepayment_edit_modal_copy_1')}</p>
      <p>{utils.i18n('prepayment_edit_modal_copy_2')}</p>
      <p>{utils.i18n('prepayment_edit_modal_copy_3')}</p>

      <ModalFooter
        buttons={[
          {
            type: DECLINE,
            label: utils.i18n('prepayment_edit_modal_decline_no_edit'),
            analyticsValue: utils.i18n('prepayment_edit_modal_decline_no_edit'),
            analyticsHandler: () =>
              utils.analytics.interaction(
                ANALYTICS.MODAL,
                utils.analytics.prepareKey(MODAL.PREPAY_EDIT_MODAL),
                utils.i18n('prepayment_edit_modal_decline_no_edit').toLowerCase()
              ),
          },
          {
            type: ACCEPT,
            label: utils.i18n('prepayment_edit_modal_accept_edit'),
            analyticsValue: utils.i18n('prepayment_edit_modal_accept_edit'),
            handler: handleEdit(sectionPath),
            analyticsHandler: () =>
              utils.analytics.interaction(
                ANALYTICS.MODAL,
                utils.analytics.prepareKey(MODAL.PREPAY_EDIT_MODAL),
                utils.i18n('prepayment_edit_modal_accept_edit').toLowerCase()
              ),
          },
        ]}
      />
    </div>
  );
};

EditModalContent.propTypes = propTypes;

export default EditModalContent;
