import { connect } from 'react-redux';
import utils from 'utils';
import { RESERVATIONS, CURSORS } from 'constants';
import {
  fetchTimesForUnavailableLocationModal,
  unSetLocationDataUnavailableLocationModal,
} from 'actions/locationFinder';
import { updatePickupAndReturnTimes } from "actions/reservation/updatePickupAndReturnTimes";
import { closeModal } from "actions/modal/closeModal";
import UnavailableLocationModalContent from './UnavailableLocationModalContent';

const { EXCHANGE_TYPE_PICKUP, EXCHANGE_TYPE_RETURN, EXCHANGE_TYPE_ACCESSOR_OBJECT } = RESERVATIONS;

const mapStateToProps = (state, props) => {
  const initiateRequestPath = ['gmi', 'session', 'gma', 'reservation', 'initiate_request'];

  const pickup_location_id = state.getIn([...initiateRequestPath, 'pickup_location_id']);
  const return_location_id = state.getIn([...initiateRequestPath, 'return_location_id']);

  //  Getting pick up day + drop off day and parsing it to a readable format
  const reservationTimesAndDates = state.getIn(['app', 'location_finder', 'unavailable_location_modal']);
  let pickupEligibility = state.getIn(CURSORS.locationHours(pickup_location_id), false);
  let returnEligibility = state.getIn(CURSORS.locationHours(return_location_id), false);
  const { pickupDate, dropoffTime, pickupTime, dropoffDate } = reservationTimesAndDates;
  const { location } = reservationTimesAndDates;
  const { showReturnLocation, one_way_rental } = props;
  const exchangeType = showReturnLocation ? EXCHANGE_TYPE_RETURN : EXCHANGE_TYPE_PICKUP;
  const pickupDateAndHoursMessage =
    location?.additional_data[EXCHANGE_TYPE_ACCESSOR_OBJECT[EXCHANGE_TYPE_PICKUP]].validityType;
  const returnDateAndHoursMessage =
    location?.additional_data[EXCHANGE_TYPE_ACCESSOR_OBJECT[EXCHANGE_TYPE_RETURN]].validityType;
  let pickupDateError = false;
  let returnDateError = false;
  let pickupTimeError = false;
  let returnTimeError = false;
  if (exchangeType === EXCHANGE_TYPE_RETURN || !one_way_rental) {
    //  Returns
    if (utils.fieldValidation.hasInvalidDay(returnDateAndHoursMessage)) {
      returnDateError = true;
      returnEligibility = {};
    }
    if (!utils.fieldValidation.hasValidHours(returnDateAndHoursMessage)) {
      returnTimeError = true;
    }
  }
  if (exchangeType === EXCHANGE_TYPE_PICKUP || !one_way_rental) {
    //  Pick up
    if (utils.fieldValidation.hasInvalidDay(pickupDateAndHoursMessage)) {
      pickupDateError = true;
      pickupEligibility = {};
    }
    if (!utils.fieldValidation.hasValidHours(pickupDateAndHoursMessage)) {
      pickupTimeError = true;
    }
  }

  const isOneWayReservation = state.getIn([...initiateRequestPath, 'one_way_rental']);
  const returnLocationType = state.getIn([...initiateRequestPath, 'return_location', 'type']);
  const pickUpLocationType = state.getIn([...initiateRequestPath, 'pickup_location', 'type']);

  return {
    pickupDate,
    returnDate: dropoffDate,
    pickupTime,
    returnTime: dropoffTime,
    pickupDateError,
    returnDateError,
    pickupTimeError,
    returnTimeError,
    location,
    pickupEligibility,
    returnEligibility,
    pickupLocationId: pickup_location_id,
    returnLocationId: return_location_id,
    isOneWayReservation,
    returnLocationType,
    pickUpLocationType,
  };
};

const mapDispatchToProps = {
  closeModal,
  fetchTimesForUnavailableLocationModal,
  unSetLocationDataUnavailableLocationModal,
  updatePickupAndReturnTimes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(UnavailableLocationModalContent));
