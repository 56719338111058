import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import { getDidPrepay, isNAPrepaySelector, isEUPrepaySelector } from 'selectors/reservationFlowSelectors';
import { inflightPaymentSelector } from 'selectors/reservationSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import Checkbox from 'components/Form/Checkbox';
import STCFormCard from '../STCFormCard';
import STCPaymentInformationPayLater from './STCPaymentInformationPayLater';
import STCPaymentInformationEUPrepay from './STCPaymentInformationEUPrepay';
import STCPaymentInformationNAPrepay from './STCPaymentInformationNAPrepay';

/**
 * STCPaymentInformation
 *
 * @param {object} props - React Props
 * @param {boolean} props.submitFailed - Did submit failed? (Used to show error message only after any submit)
 * @param {object} props.form - FinalForm instance - used to set field data when payment is added/removed
 *
 * @return {JSX}
 */
const STCPaymentInformation = ({ submitFailed, form }) => {
  const isPrepay = useSelector(getDidPrepay);
  const isNAPrepay = useSelector(isNAPrepaySelector);
  const isEUPrepay = useSelector(isEUPrepaySelector);
  const paymentDetails = useSelector(inflightPaymentSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  // State to control if the payment was added (default true only for NA Prepay)
  const [isPaymentAdded, setIsPaymentAdded] = useState(isNAPrepay);

  useEffect(
    () => {
      const havePayment = !!paymentDetails || isNAPrepay;
      setIsPaymentAdded(havePayment);
      form.change('payment-added', havePayment);
    },
    [paymentDetails, isNAPrepay]
  );

  const getTitle = () => {
    if (isPrepay) {
      return 'stc_payment_information_title';
    }
    if (isCustomPathReservation) {
      return 'post_res_add_method_of_payment';
    }
    return 'stc_add_credit_card_title';
  };

  return (
    <STCFormCard title={utils.i18n(getTitle())} isComplete={isPaymentAdded}>
      {submitFailed && !isPaymentAdded && (
        <p className='skip-the-counter-flow__paragraph skip-the-counter-flow__paragraph--error'>
          {utils.i18n('stc_payment_information_error_message')}
        </p>
      )}

      {!isNAPrepay && (
        <div className='skip-the-counter-flow-payment__hidden-checkbox' aria-hidden='true'>
          {/* Fake hidden input to use form validation for required payment card */}
          <Checkbox name='payment-added' initialValue={isPaymentAdded} validate={utils.fieldValidation.isTrueBool} />
        </div>
      )}

      <hr className='skip-the-counter-flow-payment__divider' />

      {!isPrepay && <STCPaymentInformationPayLater />}
      {isEUPrepay && <STCPaymentInformationEUPrepay />}
      {isNAPrepay && <STCPaymentInformationNAPrepay />}
    </STCFormCard>
  );
};

STCPaymentInformation.propTypes = {
  submitFailed: PropTypes.bool,
  form: PropTypes.object,
};

export default STCPaymentInformation;
