import { connect } from 'react-redux';
import utils from 'utils';
import { getPartnerRewardsPrograms, getPRPSurchargeContent } from 'actions/partnerRewardsProgram';
import { modifyProfile } from 'actions/profile';
import { profileDataSelector } from 'selectors/profileSelectors';
import { getAvailablePartnerRewardsPrograms, getSurchargeContent } from 'selectors/partnerRewardsSelectors';
import EditProfilePRPForm from './EditProfilePRPForm';

const mapStateToProps = state => ({
  profileData: profileDataSelector(state, { partnerRewardsProgram: true }),
  partnerRewardsPrograms: getAvailablePartnerRewardsPrograms(state),
  surchargeContent: getSurchargeContent(state),
});

const mapDispatchToProps = {
  getPartnerRewardsPrograms,
  getPRPSurchargeContent,
  modifyProfile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(EditProfilePRPForm));
