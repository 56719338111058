import PropTypes from 'prop-types';

export const propTypes = {
  /**
   * Action toggling visibility of filters on mobile/tablet
   * @type {Function}
   */
  onClick: PropTypes.func,

  /**
   * Optional number of applied filters
   * @type {Number}
   */
  numFiltersSelected: PropTypes.number,
};

export const defaultProps = {};
