import React from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { locationInfoById } from 'actions/location';
import ManageRentalsUI from './ManageRentalsUI';
import { getLookupRentals } from '../../redux/selectors';

const ManageRentalsContainer = (props) => {
  const lookupRentals = useSelector(getLookupRentals);
  const getLocationInfoById = useActions(locationInfoById);

  return (
    <ManageRentalsUI
      {...{
        getLocationInfoById,
        lookupRentals,
        ...props,
      }}
    />
  );
};

export default ManageRentalsContainer;
