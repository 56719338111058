import React from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { trimSpaceFromStringValueOfObject } from 'utils/object';
import { i18n } from 'utils/i18n';
import CHECKIN from 'constants/checkin';
import RESERVATION_LOOKUP from 'constants/reservationLookup';
import { reservationLookup } from 'actions/reservationLookup';
import { profileDataSelector } from 'selectors/profileSelectors';
import { preSelectedReservationRetrieveDataSelector } from 'selectors/reservationSelectors';
import ServiceErrors from 'components/ServiceErrors';
import PreSelectedMessages from 'components/PreSelectedMessages';
import ReservationLookupForm from 'components/ReservationLookupForm';
import CheckInAlreadyActivatedModal from 'components/modals/CheckInAlreadyActivatedModal';
import CancelledReservationModal from 'components/modals/CancelledReservationModal';
import SignInModal from 'components/modals/SignInModal';
import CheckInLoyaltyConflictModal from 'components/modals/CheckInLoyaltyConflictModal';
import CheckInIneligibleModal from 'components/modals/CheckInIneligibleModal';
import STCAlreadyActivatedModal from 'components/modals/STCAlreadyActivatedModal';
import STCIneligibleModal from 'components/modals/STCIneligibleModal';

const { CHECKIN_ORIGIN } = CHECKIN;
const { LOOK_UP_TYPE } = RESERVATION_LOOKUP;

/**
 * Accelerated Check-in Lookup Component
 *
 * @return {JSX}
 */
const AcceleratedCheckInLookup = () => {
  const profile = useSelector(state => profileDataSelector(state, { first_name: true, last_name: true }));
  const preSelectedReservation = useSelector(preSelectedReservationRetrieveDataSelector);
  const reservationLookupHandler = useActions(reservationLookup);

  const formSubmitHandler = ({ reservationData }) => {
    const trimStringValuesObj = trimSpaceFromStringValueOfObject(reservationData);
    reservationLookupHandler({
      reservationData: trimStringValuesObj,
      origin: CHECKIN_ORIGIN.LOOK_UP,
      lookupType: LOOK_UP_TYPE.CHECKIN_LOOK_UP,
    });
  };

  return (
    <section className='post-res-lookup theme--light-blue'>
      <span className='post-res-lookup__legend' aria-hidden={true}>
        {i18n('review_required_indication')}
      </span>
      <ServiceErrors />
      <PreSelectedMessages />
      <ReservationLookupForm
        className='post-res-lookup__form'
        onFormSubmit={formSubmitHandler}
        submitButtonText={i18n('common_start')}
        submitButtonTextAriaLabel={i18n('start_accelerated_checkin')}
        loyaltyProfile={profile}
        submitDTMTrack='check_in|look_up|start'
        preSelectedReservation={preSelectedReservation}
      />
      <CheckInAlreadyActivatedModal />
      <CancelledReservationModal />
      <SignInModal />
      <CheckInLoyaltyConflictModal />
      <CheckInIneligibleModal />
      <STCAlreadyActivatedModal />

      {/* Since Custom Path Reservation can be looked up on this page,
       we need to add the modals being dispatch under reservationLookup/customPathCheckInLookup */}
      <STCIneligibleModal />
    </section>
  );
};

export default AcceleratedCheckInLookup;
