import React from 'react';
import utils from 'utils';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import Modal from 'components/Modal';
import Anchor from 'components/Anchor';

const ChangeReturnLocationModal = () => {
  const supportPhone = useSupportPhoneNumber();

  const supportCta = (
    <Anchor
      className='link link--text'
      key='add_card_support_phone_0'
      isExternal
      hideExternalIcon
      href={`tel:${supportPhone}`}
    >
      {supportPhone}
    </Anchor>
  );

  return (
    <Modal
      modalKey={MODAL.CHANGE_RETURN_LOCATION_MODAL}
      header={utils.i18n('change_return_location_modal_title')}
      theme={MODAL_THEMES.WHITE}
      handleModalQueue={{ skipAnalytics: true }}
    >
      {() => (
        <>
          <p className='modal-themed__paragraph'>
            {utils.i18n('change_return_location_modal_description', [supportCta], { jsx: true })}
          </p>
        </>
      )}
    </Modal>
  );
};

export default ChangeReturnLocationModal;
