import React from 'react';
import utils from 'utils';
import MODAL from 'constants/modals';
import useActions from 'hooks/useActions';
import Button from 'components/Button';
import AlreadyCheckedInHelpModal from 'components/modals/AlreadyCheckedInHelpModal';
import { openModal } from "actions/modal/openModal";

/**
 * AlreadyCheckedInHelpLink
 * Link for "Already Checked In?" with modal opening to be used in the
 *  "Need Help" section on Online Check-in lookup.
 *
 * @return {JSX}
 */
const AlreadyCheckedInHelpLink = () => {
  const openTipsModal = useActions(() => openModal(MODAL.ALREADY_CHECKED_IN_HELP_MODAL));

  return (
    <div>
      <Button onClick={openTipsModal} link linkText>
        {utils.i18n('already_checked_in_help_title')}
      </Button>
      <AlreadyCheckedInHelpModal />
    </div>
  );
};

export default AlreadyCheckedInHelpLink;
