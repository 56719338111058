import React, { useState, useMemo, useRef } from 'react';
import { i18n } from 'utils/i18n';
import utils from 'utils';
import { ASSETS } from 'constants';
import COUNTRY_CODE from 'constants/countryCode';
import PoliciesComponent from 'components/PoliciesComponent';
import Button from 'components/Button';
import CreditCardDetails from 'components/CreditCardDetails';
import DiscardReservationModal from 'components/ReservationFlow/SharedModals/DiscardReservationModal';
import ErrorBanner from 'components/ErrorBanner/ErrorBanner';
import ConfirmModifyCommitSidebar from './ConfirmModifyCommitSidebar';
import ProgressBarModifyReturn from '../ProgressBar';
import ModifyReturnWidget from '../ModifyReturnScreen/ModifyReturnWidget';
import ConfirmModifySummaryTable from './ConfirmModifySummaryTable';
import CanadaFrenchRedirectionBanner from './CanadaFrenchRedirectionBanner';

const ConfirmModifyCommit = (props) => {
  const {
    policies,
    selectedPaymentMethod,
    shouldShowPolicyComponent,
    returnTime,
    returnLocation,
    priceSummary,
    extras,
    selectedBundleWithExtras,
    extrasWithoutBundles,
    numberOfExtrasSelected,
    coupons,
    commitRentalAction,
    history,
    totalSummaryItems,
    contractDetails,
    showCorporateCoverage,
    breakpoint,
    renterPaymentLines,
    billToPaymentLines,
  } = props;

  const { rental_total_view } = priceSummary;
  const [formErrors, setFormErrors] = useState([]);
  const isNADomain = utils.config.isNADomain();

  const isFRRentalLocation = returnLocation?.address?.country_code === COUNTRY_CODE.FR;

  const totalSummaryOfChargesAmount = totalSummaryItems?.totalBreakdownAmount;

  const progressBarRef = useRef();

  const isDebitCard = selectedPaymentMethod ? selectedPaymentMethod?.payment_type === 'DEBIT_CARD' : false;

  const chargedText = () => {
    // if it is debitcard  and isNADomain show charged text.
    // credit card show authorized text.
    // if it not a NADomain show charged, except for france booking country.
    let returnChargedText = (isDebitCard && isNADomain) || !isNADomain ? 'charged' : 'authorized';

    // irrespective of any domain, if the booking country code is french show authroized text
    if (isFRRentalLocation) {
      returnChargedText = 'authorized';
    }

    return i18n(returnChargedText);
  };

  const dateTimeFormat = utils.config.getDateTimeShortFormat('lll_no_year');
  const returnDate = utils.gmi.getDateTimeObjFromTs(returnTime).format(dateTimeFormat);

  const onRentalError = (errors) => {
    setFormErrors(errors);
  };

  const memoizedModifyReturnWidget = useMemo(
    () => <ModifyReturnWidget onProgressBar onRentalError={onRentalError} />,
    [progressBarRef]
  );

  const steps = [
    {
      title: utils.i18n('return_date_time'),
      value: returnDate,
      active: false,
      child: true,
    },
    {
      title: utils.i18n('charges_payment'),
      value: utils.i18n('progress_bar_current_step'),
      active: true,
    },
    {
      title: utils.i18n('return_updated'),
      active: false,
    },
  ];

  const handleSubmitReview = () => {
    setFormErrors([]);
    commitRentalAction().then((response) => {
      if (response?.messages?.length) {
        setFormErrors(response.messages);
      }
    });
  };

  const getTotalChargedTodayText = () => {
    const paymentState = chargedText();
    if (isNADomain) {
      return utils.i18n('total_to_be_charged_today_text', [paymentState]);
    }
    // EU domain is able to view
    return utils.i18n('total_to_be_charged_today_text_eu', [paymentState]);
  };

  const getUponSubmittingChangesText = () => {
    const paymentState = chargedText();

    const totalSummaryOfChargesAmountText =
      totalSummaryOfChargesAmount && utils.getFormattedPrice(totalSummaryOfChargesAmount);

    return `${utils.i18n('upon_submitting_changes', [paymentState])} ${totalSummaryOfChargesAmountText}`;
  };

  const getSubmitChangesText = () => {
    if (isNADomain || (!isNADomain && isFRRentalLocation)) {
      return utils.i18n('submit_changes');
    }
    // EU domain is able to view
    return utils.i18n('submit_changes_eu');
  };

  if (rental_total_view) {
    return (
      <>
        <ProgressBarModifyReturn innerRef={progressBarRef} steps={steps}>
          {memoizedModifyReturnWidget}
        </ProgressBarModifyReturn>
        <CanadaFrenchRedirectionBanner />
        <div className='theme--dark-blue'>
          <div className='review'>
            <h1 className='title__container modify-return-confirmation__title'>
              {utils.i18n('please_confirm_your_modifications')}
            </h1>
            <div className='modify-return-confirmation__content'>
              <ConfirmModifyCommitSidebar
                {...{
                  returnTime,
                  returnLocation,
                  priceSummary,
                  extras,
                  selectedBundleWithExtras,
                  extrasWithoutBundles,
                  numberOfExtrasSelected,
                  coupons,
                  contractDetails,
                  showCorporateCoverage,
                  history,
                  breakpoint,
                  renterPaymentLines,
                  billToPaymentLines,
                }}
              />
              <section className='review__main'>
                {formErrors &&
                  formErrors.length > 0 &&
                  formErrors.map((errorMessage, index) => (
                    <ErrorBanner key={`error_banner_${index}`} errorDescription={errorMessage?.message} />
                  ))}

                <section className='review__section-card theme--light component-theme--light'>
                  <div className='review__section-card__header'>
                    <h3 className='review__section-card__title'>{utils.i18n('summary_charges')}</h3>
                  </div>

                  {!showCorporateCoverage && breakpoint.isMobile && (
                    <h4 className='modify-return-confirmation__subtitle'>{utils.i18n('your_estimated_charges')}</h4>
                  )}

                  <ConfirmModifySummaryTable sectionItems={totalSummaryItems?.modificationBreakdown?.sectionItems} />

                  {showCorporateCoverage && (
                    <ConfirmModifySummaryTable sectionItems={totalSummaryItems?.adjustmentBreakdown?.sectionItems} />
                  )}

                  <div className='modify-return-confirmation__summary-item modify-return-confirmation__summary-item--bold modify-return-confirmation__total-text'>
                    <div>{utils.i18n('total_to_be_charged_today', [chargedText()])}</div>
                    <div>{totalSummaryOfChargesAmount && utils.getFormattedPrice(totalSummaryOfChargesAmount)}</div>
                  </div>
                  <p className='modify-return-confirmation__small'>{getTotalChargedTodayText()}</p>
                  {selectedPaymentMethod && <CreditCardDetails cardDetails={selectedPaymentMethod} />}
                </section>
              </section>
              <section className='modify-return-confirmation__submit-container'>
                <p className='modify-return-confirmation__submitting-changes'>{getUponSubmittingChangesText()}</p>

                <div>
                  <Button onClick={handleSubmitReview}>{getSubmitChangesText()}</Button>
                </div>
              </section>
            </div>
          </div>
        </div>

        {shouldShowPolicyComponent && (
          <section className='review__policies-background'>
            <div className='review'>
              <PoliciesComponent
                title={i18n('stc_confirmation_your_rentals_policies')}
                policies={policies}
                backgroundArtURL={ASSETS.RENTAL_POLICIES_PLACEHOLDER}
                backgroundArtAlt={i18n('policy_background_image_alt')}
                hideTermsConditionsCta
                isRental
              />
            </div>
          </section>
        )}
        <DiscardReservationModal />
      </>
    );
  }

  return null;
};

export default ConfirmModifyCommit;
