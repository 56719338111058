import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import MODAL from 'constants/modals';
import RESERVATIONS from 'constants/reservations';
import { isLocationWithNoVehiclesAvailableSelector } from 'selectors/errorSelectors';
import { openModal } from "actions/modal/openModal";
import { PROGRESS_BAR_CONFIG } from '../../reservationFlowRouteUtils';

/**
 * This component has the logic for Sold Out Modal across all Progress Bar Drawers
 *
 * @param {object} props - React props
 * @param {string} props.expandedTab - Name of the tab that is opened
 * @param {string} props.currentPage - location.pathname coming from ProgressBar component
 * @param {boolean} props.isFetchingReservation - Flag to check if it's calling reservations/initiate endpoint
 * @param {function} props.onCloseDrawer - Function coming from ProgressBar to close the drawer
 * @param {function} props.handleCollapseProgressBar - Function coming from ProgressBar to collapse the progress bar
 *
 * @return {JSX}
 */
const ProgressBarDrawerContainer = ({
  expandedTab,
  currentPage,
  isFetchingReservation,
  onCloseDrawer,
  handleCollapseProgressBar,
  expandedTotalDropdown,
}) => {
  const isLocationWithNoVehiclesAvailable = useSelector(isLocationWithNoVehiclesAvailableSelector);
  const openSoldOutModal = useActions(() => openModal(MODAL.SOLD_OUT_MODAL));
  const [shouldShowModal, setShouldShowModal] = useState(!isLocationWithNoVehiclesAvailable);
  const { ProgressBarDrawerComponent, progressBarLabel } = PROGRESS_BAR_CONFIG.find(
    ({ id }) => id === (expandedTab || expandedTotalDropdown)
  );

  useEffect(
    () => {
      // Check if location search is not used on /map_pickup since this page has LocationFinder with soldOutModal logic
      if (
        currentPage !== RESERVATIONS.RESFLOW_PATHS_CONFIG.location[0] &&
        shouldShowModal &&
        isLocationWithNoVehiclesAvailable &&
        !isFetchingReservation
      ) {
        openSoldOutModal();
        setShouldShowModal(true);
      }
    },
    [currentPage, isLocationWithNoVehiclesAvailable, shouldShowModal, openSoldOutModal, isFetchingReservation]
  );

  return (
    <ProgressBarDrawerComponent
      label={progressBarLabel}
      onCloseDrawer={onCloseDrawer}
      handleCollapseProgressBar={handleCollapseProgressBar}
    />
  );
};

ProgressBarDrawerContainer.propTypes = {
  expandedTab: PropTypes.string,
  isFetchingSession: PropTypes.bool,
  currentPage: PropTypes.string,
  isFetchingReservation: PropTypes.bool,
  onCloseDrawer: PropTypes.func,
  handleCollapseProgressBar: PropTypes.func,
};

export default ProgressBarDrawerContainer;
