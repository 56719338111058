import { connect } from 'react-redux';
import utils from 'utils';
import { sessionReservationObjectSelector, getModifyFlag } from 'selectors';
import { vehicleRateSelector } from 'selectors/reservationFlowSelectors';
import { openModalWithProps } from "actions/modal/openModalWithProps";
import { hasSomeDataSelector, hasEveryDataSelector } from './progressBarTabSelectors';

const mapStateToProps = (state, ownProps) => ({
  isAllowed: hasSomeDataSelector(state, ownProps),
  isCompleted: hasEveryDataSelector(state, ownProps),
  contract_details: sessionReservationObjectSelector(state)?.get('contract_details'),
  partner_reward_program: sessionReservationObjectSelector(state)?.get('partner_reward_program'),
  isTrueModify: getModifyFlag(state),
  vehicleRate: vehicleRateSelector(state),
});

const mapDispatchToProps = {
  openModalWithProps,
};

const connectProgressBarTab = WrappedComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(utils.toJS(WrappedComponent));

export default connectProgressBarTab;
