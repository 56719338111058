import React, { useState } from 'react';
import utils from 'utils';
import cn from 'classnames';
import ANALYTICS from 'constants/analytics';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import checkInDocuments from 'images/check-in-documents.svg';
import checkInDoneImage from 'images/check-in-done.svg';
import ConfirmationAnimation from 'components/ConfirmationAnimation';

const AcceleratedCheckinPickupHeaderStatus = ({
  isCustomPathReservation,
  isCustomPathEnabled,
  onlineCheckinExternalLink,
  checkinOrigin,
  checkinStart,
  isCheckedIn,
  isSkipTheCounterCompleted,
  isNonVirginTourReservation,
  breakpoint,
}) => {
  const openDrawerByDefault = isNonVirginTourReservation && !breakpoint.isTabletOrMobile;
  const [showMoreDrawerExpanded, setShowMoreDrawerExpanded] = useState(openDrawerByDefault);

  const handleShowmoreToggleExpanded = () => {
    setShowMoreDrawerExpanded(!showMoreDrawerExpanded);
    utils.analytics.interaction(ANALYTICS.DRAWER, 'oci', showMoreDrawerExpanded ? 'show less' : 'show more');
  };

  return (
    <div
      className={cn('confirmation-oci-status__section-oci-pickup-status-block', {
        'oci-only': isNonVirginTourReservation && breakpoint.isDesktop,
      })}
    >
      <div
        className={
          isCheckedIn ? 'confirmation-oci-status__acc-oci-image-wrapper' : 'confirmation-oci-status__oci-image-wrapper'
        }
      >
        <ConfirmationAnimation
          className={'confirmation-oci-status__oci-image'}
          srcImage={isCheckedIn ? checkInDoneImage : checkInDocuments}
          disabledAnimationEffect={!isCheckedIn || isSkipTheCounterCompleted}
        />
      </div>
      <div className='confirmation-oci-status__title'>
        {/* show step if check-in is not completed */}
        {!isNonVirginTourReservation && !isCheckedIn && (
          <p className='confirmation-oci-status__title--step-indicator'>{1}</p>
        )}
        <span className='confirmation-oci-status__title-text'>{utils.i18n('check_in_title')}</span>
        <div
          className={cn('confirmation-oci-status__info', {
            'non-tour-oci-stc-flow': isNonVirginTourReservation && breakpoint.isDesktop,
          })}
        >
          <div
            className={cn({
              'confirmation-oci-status__info-inline-container': isNonVirginTourReservation && breakpoint.isDesktop,
            })}
          >
            <div className='confirmation-oci-status__info-inline'>
              <p className='confirmation-oci-status__text'>
                {isCheckedIn ? utils.i18n('check_in_great_thanks') : utils.i18n('check_in_title_info')}
              </p>
            </div>

            {/* show more expanded/ collapsable event block */}
            {!isCheckedIn && (
              <React.Fragment>
                <div className={cn('confirmation-oci-status__show-more', { visible: showMoreDrawerExpanded })}>
                  <div className='confirmation-oci-status__info-inline'>
                    <p className='confirmation-oci-status__text'>
                      {utils.i18n('confirmation_oci_check_in_show_more_info')}
                    </p>
                  </div>
                  <ul className='confirmation-oci-status__show-more-info-list'>
                    <li>{utils.i18n('confirmation_oci_check_in_show_more_item_1')}</li>
                    <li>{utils.i18n('confirmation_oci_check_in_show_more_item_2')}</li>
                    <li>{utils.i18n('confirmation_oci_check_in_show_more_item_3')}</li>
                  </ul>
                </div>
                <Button
                  plain
                  type='button'
                  onClick={handleShowmoreToggleExpanded}
                  className={cn('link confirmation-oci-status__drawer-label', { expanded: showMoreDrawerExpanded })}
                >
                  {!showMoreDrawerExpanded ? utils.i18n('common_show_more') : utils.i18n('common_show_less')}
                </Button>
              </React.Fragment>
            )}
          </div>
          {!isCheckedIn && (
            <div>
              {isCustomPathReservation && !isCustomPathEnabled ? ( // If custom path feature flag is off, send to WNG
                <Anchor
                  className='button confirmation-oci-status__action-external-link'
                  href={onlineCheckinExternalLink?.link}
                  isExternal={true}
                >
                  {utils.i18n('trip_card_save_time_activate_button')}
                </Anchor>
              ) : (
                <Button
                  onClick={() => checkinStart(checkinOrigin)}
                  className='confirmation-oci-status__action-button'
                  data-dtm-track='button|confirmation|check_in'
                >
                  {utils.i18n('trip_card_save_time_activate_button')}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcceleratedCheckinPickupHeaderStatus;
