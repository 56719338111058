import React, { useRef, useEffect } from 'react';
import utils from 'utils';
import SCROLL from 'constants/scroll';

const FormErrors = () => {
  const formErrorsRef = useRef(null);

  useEffect(
    () => {
      const { current } = formErrorsRef;
      setTimeout(() => utils.dom.scrollPage(0, current.offsetTop - SCROLL.HEADER_CLEARANCE));
    },
    [formErrorsRef.current]
  );

  return (
    <div className='service-errors' ref={formErrorsRef}>
      <div className='service-error'>
        <span className='message-priority--ERROR'>{utils.i18n('message_priority_ERROR')}</span>
        <p>{utils.i18n('review_form_error_message')}</p>
      </div>
    </div>
  );
};

export default FormErrors;
