import React from 'react';
import PropTypes from 'prop-types';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import Form from 'components/Form/Form';
import ServiceErrors from 'components/ServiceErrors';
import FormErrors from 'components/FormErrors';
import './styles/form-wrapper.scss';

/**
 * FormWrapper
 *
 * This component has the common form structure with a header and a children for the form fields and buttons
 *
 * @param {HTMLElement[]} children Array with child nodes of the parent node
 * @returns jsx
 */
const FormWrapper = ({ children, handleFormSubmit, validate, formTitle, statePath }) => (
  <Form
    onSubmit={handleFormSubmit}
    subscription={{ submitting: true, pristine: true, submitFailed: true }}
    validate={validate}>
    {({ form, handleSubmit, submitFailed }) => (
      <form onSubmit={handleSubmit} className='form-wrapper__form component-theme--light' noValidate>
        {statePath && <ServiceErrors statePath={statePath} />}
        {submitFailed && !gmiUtils.isObjectEmpty(form.getState().errors) && <FormErrors />}
        <div className='form-wrapper__section-header'>
          <h3 className='form-wrapper__section-title'>{formTitle}</h3>
          <span className='form-wrapper__required-note'>{i18n('header_note_required_field')}</span>
        </div>
        {children}
      </form>
    )}
  </Form>
);

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  formTitle: PropTypes.string.isRequired,
  statePath: PropTypes.string,
};

export default FormWrapper;
