import PropTypes from 'prop-types';
import { setDefaultProps, GoogleMapsPropTypes } from 'data/sharedProps/index';

export const propTypes = {
  /**
     * boolean for spatial query in progress
     * @type {Boolean}
     */
    fetching: PropTypes.bool,
    /**
     * Google Maps Map Object
     * @type {Object}
     */
    map: PropTypes.object,
  /**
   * HOC Wrapper
   */
  ...GoogleMapsPropTypes
};

export const defaultProps = setDefaultProps();
