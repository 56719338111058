import React from 'react';
import PropTypes from 'prop-types';

/**
 * ProgressBarVehicle - displays the vehicle name in the progress bar
 * @param {object} props             - React Props
 * @param {string} props.vehicleName - the vehicle name
 * @return {JSX}
 */

const ProgressBarVehicle = ({ vehicleName }) => (
  <span className='section__value progress-bar-mobile__vehicle-name'>{vehicleName}</span>
);

ProgressBarVehicle.propTypes = {
  vehicleName: PropTypes.string,
};

export default ProgressBarVehicle;
