import React from 'react';
import utils from 'utils';
import { propTypes, defaultProps } from './VehicleGridHeaderPropTypes';

const VehicleGridHeader = ({ className, vehiclesCount, isVehicleLandingPage, categoryName }) => (
  <h3 className={className}>
    {isVehicleLandingPage
      ? utils.i18n('vehicle_grid_landing_options_label', [vehiclesCount])
      : utils.i18n('vehicle_grid_options_in_label', [vehiclesCount, categoryName])}
  </h3>
);
VehicleGridHeader.defaultProps = defaultProps;
VehicleGridHeader.propTypes = propTypes;

export default VehicleGridHeader;
