import React from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import ANALYTICS from 'constants/analytics';
import ASSETS from 'constants/assets';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import Anchor from 'components/Anchor';
import Picture from 'components/Picture';
import Button from 'components/Button/Button';

/**
 * UpcomingTripsEmpty Description
 * will be displayed when user has no upcoming trips
 * @param {object} props - React Props
 * @param {Boolean} props.showProfileEmptyState - if true, will show UI for profile page. If false, will show My Trips UI
 * @param {function} props.openMissingTripsModal - opens MISSING_TRIPS_MODAL
 * @return {JSX} UpcomingTripsEmpty component
 */
const UpcomingTripsEmpty = ({ showProfileEmptyState, openMissingTripsModal }) => {
  const pictureSrcProps = {
    srcMobile: showProfileEmptyState ? ASSETS.PROFILE_NO_UPCOMING_TRIPS_1 : ASSETS.NO_UPCOMING_TRIPS_1,
    srcTablet: showProfileEmptyState ? ASSETS.PROFILE_NO_UPCOMING_TRIPS_2 : ASSETS.NO_UPCOMING_TRIPS_2,
    src: showProfileEmptyState ? ASSETS.PROFILE_NO_UPCOMING_TRIPS_3 : ASSETS.NO_UPCOMING_TRIPS_3,
  };

  return (
    <div className='upcoming-trips__empty'>
      <Picture {...pictureSrcProps} pictureClassName={'upcoming-trips__empty__image'} isDecorative />
      <div className='upcoming-trips__empty__content'>
        {showProfileEmptyState ? (
          <>
            <h3>{utils.i18n('upcoming_trips_no_trips')}</h3>
            <Anchor className='button' href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESERVATION_INFLOW)}>
              {utils.i18n('upcoming_trips_start_a_reservation')}
            </Anchor>
            <Anchor
              className='link link--arrow'
              href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.FIND_A_RESERVATION)}>
              {utils.i18n('upcoming_trips_find_a_reservation')}
            </Anchor>
          </>
        ) : (
          <>
            <h3>{utils.i18n('my_trips_upcoming_trips_no_trips_title')}</h3>
            <p>{utils.i18n('my_trips_upcoming_trips_no_trips_description')}</p>
            <Button
              onClick={openMissingTripsModal}
              button
              data-dtm-track={utils.analytics.dtm(ANALYTICS.MY_TRIPS, 'missing_trip', ANALYTICS.SELECT)}>
              {utils.i18n('my_trips_missing_a_trip_cta')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

UpcomingTripsEmpty.propTypes = {
  showProfileEmptyState: PropTypes.bool,
  openMissingTripsModal: PropTypes.func,
};

export default UpcomingTripsEmpty;
