import React from 'react';
import cn from 'classnames';
import utils from 'utils';

/**
 * RequiredFieldsIndicator
 *
 * @return {JSX} - displays a string wrapped in a standardized HTML element
 */
const RequiredFieldsIndicator = ({ customClass }) => (
  <div className={cn('required-field-indicator', customClass)}>{utils.i18n('common_required_indicator')}</div>
);

export default RequiredFieldsIndicator;
