import React, { Component } from 'react';
import LocationFinder from 'components/LocationFinder';

class StoreFinder extends Component {
  render() {
    return <LocationFinder {...this.props} showLocationSearch />;
  }
}

export default StoreFinder;
