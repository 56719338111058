import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import numberPin from 'images/number-pin.svg';
import numberPinSoldOut from 'images/number-pin-sold-out.svg';
import nationalNumberPin from 'images/national-number-pin.svg';
import enterpriseNumberPin from 'images/enterprise-number-pin.svg';
import nationalPin from 'images/national-pin.svg';
import enterprisePin from 'images/enterprise-pin.svg';
import branchPin from 'images/branch-pin.svg';
import branchPinSoldOut from 'images/branch-pin-sold-out.svg';
import airportPin from 'images/airport-pin.svg';
import airportPinSoldOut from 'images/airport-pin-sold-out.svg';
import railPin from 'images/rail-pin.svg';
import railPinSoldOut from 'images/rail-pin-sold-out.svg';
import cruiseportPin from 'images/cruiseport-pin.svg';
import cruiseportPinSoldOut from 'images/cruiseport-pin-sold-out.svg';
import ANALYTICS from 'constants/analytics';
import LOCATIONS from 'constants/locations';
import Button from 'components/Button';
import GoogleMapHOC from '../../Maps/GoogleMapHOC';
import SoldOutTileBanner from '../SoldOutTileBanner';

const GOOGLE_MAPS_PADDING_TOP = 75;
const GOOGLE_MAPS_PADDING_BOTTOM = 200;

class LocationMap extends Component {
  state = {
    showRecenterButton: false,
    showSoldOutAreaBanner: true,
    removedBanner: false,
    googleMapsPaddingTop: this.props.breakpoint.isMobile ? GOOGLE_MAPS_PADDING_TOP : 0,
    googleMapsPaddingBottom: this.props.breakpoint.isTabletOrMobile ? GOOGLE_MAPS_PADDING_BOTTOM : 0,
  };

  map = null;

  googleMaps = null;

  selectedLocation = this.props.selectedLocation || null;

  locationMakers = {};

  activeMarker = null;

  currentLocationMarker = null;

  currentPosition = null;

  timestamp = null;

  currentBounds = null; // Store Markers Bounds

  _preventDragZoomChanges = false;

  componentDidUpdate(prevProps) {
    const { breakpoint } = this.props;
    if (
      breakpoint.isMobile !== prevProps.breakpoint.isMobile ||
      breakpoint.isTabletOrMobile !== prevProps.breakpoint.isTabletOrMobile
    ) {
      this.setState(
        {
          googleMapsPaddingTop: breakpoint.isMobile ? GOOGLE_MAPS_PADDING_TOP : 0,
          googleMapsPaddingBottom: breakpoint.isTabletOrMobile ? GOOGLE_MAPS_PADDING_BOTTOM : 0,
        },
        () => {
          this.handleRecenterMap();
        }
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      map,
      googleMaps,
      locations,
      selectedLocation,
      selectedLocationId,
      hoveredLocationId,
      timestamp,
      isUserDraggedOrZoom,
      showLocationDetailTile,
      isVisible,
    } = nextProps;

    const { googleMapsPaddingTop, googleMapsPaddingBottom } = this.state;

    // Store Google Maps Refs
    if (!this.map && map && googleMaps) {
      this.map = map;
      this.googleMaps = googleMaps;
      this._addMapListeners(map); // Add Map Event Listeners
    }

    if (this.googleMaps) {
      const isMapVisible = isVisible && !this.props.isVisible;

      // Centers Map to new inputed searched location
      this._updateSelectedLocation(selectedLocation, locations);

      // Clears and Redraws new Markers based on new location results
      this._updateMarkers(locations, selectedLocation, timestamp, isUserDraggedOrZoom);

      // Expand Location Tile details
      selectedLocationId &&
        selectedLocationId !== this.props.selectedLocationId &&
        this._updateSelectedInternalLocation(selectedLocationId);

      // Set Location Tile hover state
      !selectedLocationId &&
        hoveredLocationId !== this.props.hoveredLocationId &&
        this._updateHoveredLocation(hoveredLocationId);

      // If map is visible in mobile and tablet breakpoints, re-render map
      isMapVisible &&
        this.googleMaps.event.addListenerOnce(map, 'idle', () =>
          this.fitBounds(locations, { bottom: googleMapsPaddingBottom, top: googleMapsPaddingTop })
        );

      // Disable Google Maps interaction on tile shown
      const isOptionDisabled = !showLocationDetailTile;
      this.map.setOptions({
        draggable: isOptionDisabled,
      });
    }

    // @dev: This component should only rerender when Location Tiles expand/collapse
    // Or when timestamp is updated to force it to rerender
    // Or when Recenter button is toggled
    // Or when Location Tile on Mobile/Tablet map is toggled
    // Any map related updates should not cause it to rerender. Gmap handles those states on its own
    return (
      this.timestamp !== timestamp ||
      this.state.showRecenterButton !== nextState.showRecenterButton ||
      showLocationDetailTile !== this.props.showLocationDetailTile ||
      (showLocationDetailTile && selectedLocationId) ||
      this.state.showSoldOutAreaBanner !== nextState.showSoldOutAreaBanner
    );
  }

  /**
   * @private
   * Updates map to new selected location
   * Show Recenter button if is user dragged or zoomed
   * Hide Recenter button if new location is not user interacted or if new location is same as previous location
   */
  _updateSelectedLocation = (selectedLocation, locations) => {
    const { googleMapsPaddingTop, googleMapsPaddingBottom } = this.state;
    const isDifferentLocation =
      (selectedLocation && this.selectedLocation && this.selectedLocation.id !== selectedLocation.id) ||
      (!this.selectedLocation && selectedLocation) ||
      (this.selectedLocation && !selectedLocation);

    if (isDifferentLocation) {
      this.selectedLocation = selectedLocation;
      this.fitBounds(locations, { bottom: googleMapsPaddingBottom, top: googleMapsPaddingTop });
    }
  };

  /**
   * @private
   * Updates map with new markers based on new locations results. Update is triggered with a new timestamp as it
   * is more effiecient than doing a deep comparison of locations array
   */
  _updateMarkers = (locations, selectedLocation, timestamp, isUserDraggedOrZoom) => {
    const { selectedLocationId, handleSelectedLocation } = this.props;
    const { googleMapsPaddingTop, googleMapsPaddingBottom } = this.state;
    const hasLocations = utils.gmi.isArrayNotEmpty(locations);

    !hasLocations && (this.currentBounds = null);
    // If new timestamp is set, it's an indication that there are new locations results or no locations found
    if (this.timestamp !== timestamp) {
      this.timestamp = timestamp;
      this.clearLocationMarkers(); // Clear any existing location markers to update map with new results

      if (hasLocations) {
        this.addLocationMarkers(locations); // If there are new results, generate new markers on the map
        // If the current location was searched via Input, then center the map around this new location
        // Do nothing if the location was searched through panning the map
        !isUserDraggedOrZoom &&
          this.fitBounds(locations, { bottom: googleMapsPaddingBottom, top: googleMapsPaddingTop });
        const marker = this.locationMakers[selectedLocationId];
        if (marker) {
          this.activeMarker = this._setLargeIcon(marker, marker.label.text);
        } else {
          //  If the selected location is not in the new batch of locations, we should clear the selected location
          handleSelectedLocation(null);
        }
      } else {
        //  If we have no locations we should clear the selected locaiton
        handleSelectedLocation(null);
      }

      // Centers Map to current selected location if there are no branches and not user dragged
      if (selectedLocation && !utils.gmi.isArrayNotEmpty(locations) && !isUserDraggedOrZoom) {
        const { latitude, longitude } = selectedLocation.gps;
        this.map.setCenter({ lat: Number(latitude), lng: Number(longitude) });
      }

      this._updateRecenterButtonVisibility(selectedLocation, isUserDraggedOrZoom, hasLocations);
    }

    // If no location selected get default country
    if (!selectedLocation) {
      // @dev TODO: If no location is selected, we should center them to their default country defaultCountry
      // This task is being tracked in ticket https://jira.ehi.com/browse/AWR-1139
    }
  };

  /**
   * @private
   * Handles the visibility of the recenter button when new locations results are updated
   */
  _updateRecenterButtonVisibility = (selectedLocation, isUserDraggedOrZoom, hasLocations) => {
    // Don't show Recenter button if the searched location was entered via the input field (vs. panning the map to another location)
    // Or if partner locations have been rendered after a user has dragged & zoomed
    const partnerLocationsRendered = isUserDraggedOrZoom && hasLocations;
    if (this.state.showRecenterButton && (!isUserDraggedOrZoom || partnerLocationsRendered)) {
      this.setState({ showRecenterButton: false });
    } else if (selectedLocation && !this.state.showRecenterButton && isUserDraggedOrZoom) {
      // Show Recenter button if user panned or zoomed on the map to a new location
      this.setState({ showRecenterButton: true });
    }
  };

  /**
   * @private
   * Handles the Expanding of Location Details tile through clicking the markers of on the map
   */
  _updateSelectedInternalLocation = (locationId) => {
    if (locationId) {
      const marker = this.locationMakers[locationId];
      this._resetActiveMarker();
      this.activeMarker = marker && this._setLargeIcon(marker, marker.label.text);
    }
  };

  /**
   * @private
   * Handles the highlight state of the Location Details tile through hovering over the markers on the map
   */
  _updateHoveredLocation = (locationId) => {
    if (locationId) {
      const marker = this.locationMakers[locationId];
      this._resetActiveMarker();
      this.activeMarker = marker && this._setLargeIcon(marker, marker.label.text);
    } else {
      this._resetActiveMarker();
    }
  };

  /**
   * @private
   * Reset current Active Marker
   */
  _resetActiveMarker = () => {
    if (this.activeMarker) {
      this._setStandardIcon(this.activeMarker, this.activeMarker.label.text);
      this.activeMarker = null;
    }
  };

  /**
   * Bind Event Listeners
   * @private
   */
  _addMapListeners = (map) => {
    const { handleSelectedLocation } = this.props;
    map.addListener('click', () => {
      handleSelectedLocation(null);
      this._resetActiveMarker();
    });

    map.addListener('dragstart', () => {
      if (!this._preventDragZoomChanges) {
        this.handleMapDragStarted();
      }
    });

    map.addListener('dragend', () => {
      if (!this._preventDragZoomChanges) {
        const bounds = this.getMapBounds();
        this.handleMapDragged(bounds);
      }
    });

    map.addListener('zoom_changed', () => {
      if (!this._preventDragZoomChanges) {
        const bounds = this.getMapBounds();
        this.handleZoomChanged(bounds);
      }
    });

    map.addListener('bounds_changed', () => {
      const { isUserDraggedOrZoom } = this.props;
      // Ensure that fitBounds does not zoom too far in to 1 pin
      this._preventDragZoomChanges &&
        !isUserDraggedOrZoom &&
        map.getZoom() > this.props.zoom &&
        map.setZoom(this.props.zoom);
    });
  };

  handleMapDragStarted = () => {
    !this.state.removedBanner && this.setState({ showSoldOutAreaBanner: false });
  };

  /**
   * Debounces any panning of the map
   */
  handleMapDragged = utils.gmi.debounce((bounds) => {
    !this.state.removedBanner && this.setState({ showSoldOutAreaBanner: true });
    utils.safeExecute(this.props.onDragEnd, bounds);
  }, 250);

  /**
   * Debounces any map zoom interaction
   */
  handleZoomChanged = utils.gmi.debounce((bounds) => {
    utils.safeExecute(this.props.onZoomChanged, bounds);
  }, 250);

  /**
   * Centers Map around all Markers
   */
  handleRecenterMap = (e) => {
    //  If e is not defined it is getting called by changing breakpoints, we want to update the padding on GM to keep
    //  the pins in view.  But Comp will not update unless the timestamp is changed.  For the other uses of `handleRecenterMap`
    //  this is already accounted for
    if (e) {
      e.preventDefault();
    } else {
      this.props.resetTimeStamp();
    }

    this.setState({ showRecenterButton: false });
    utils.safeExecute(this.props.onFitBounds, this.currentBounds);
  };

  /**
   * Handle Marker Interactions
   */
  handleMarkerEvents = (locationId) => (e) => {
    utils.safeExecute(this.props.onMarkerEvents, e, locationId);
  };

  /**
   * handles click marker events, will make the tiles scroll into view, and 'selects them'
   */

  handleClickMarkerEvents = (locationId) => (e) => {
    const element = document.getElementById(locationId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    // Accessibility - to make selection on KeyboardEvent - To navigate map with a keyboard for screen reader users
    if (locationId) {
      const marker = this.locationMakers[locationId];
      this._resetActiveMarker();
      this.activeMarker = marker && this._setLargeIcon(marker, marker.label.text);
    } else {
      this._resetActiveMarker();
    }
    document.querySelector(`#location-${locationId} .map-location-detail-tile__link`).click();
    utils.safeExecute(this.props.onMarkerEvents, e, locationId);
  };

  getMapBounds = () => {
    const { map } = this;
    const center = map.getCenter();
    const northEastCorner = map.getBounds().getNorthEast();

    return {
      center,
      northEastCorner,
      radius: this.googleMaps.geometry.spherical.computeDistanceBetween(center, northEastCorner), // Radius in Meters
    };
  };

  /**
   * Center Map Around all Store Markers
   */
  fitBounds = (locations) => {
    const { map, selectedLocation } = this;
    const { googleMapsPaddingTop, googleMapsPaddingBottom } = this.state;
    this._preventDragZoomChanges = true;

    if (selectedLocation && selectedLocation.gps) {
      const {
        gps: { latitude, longitude },
      } = selectedLocation;
      const position = { lat: Number(latitude), lng: Number(longitude) };

      if (selectedLocation.type === LOCATIONS.TYPE_GEOLOCATION && this.props.showCurrentLocation) {
        this.addCurrentLocationMarker(map, position);
      }
      if (utils.gmi.isArrayNotEmpty(locations)) {
        this.currentBounds &&
          map.fitBounds(this.currentBounds, { bottom: googleMapsPaddingBottom, top: googleMapsPaddingTop });
      } else {
        map.setCenter(position);
      }
    } else {
      map.setCenter(this.props.center);
    }

    this.googleMaps.event.addListenerOnce(map, 'idle', () =>
      this.setState({ showRecenterButton: false }, () => {
        this._preventDragZoomChanges = false;
      })
    );
  };

  /**
   * Adds Current Location Marker
   * @param {Object} Google Map
   * @param {Object} Google Map LatLng Position
   */
  addCurrentLocationMarker = (map, position) => {
    this.currentLocationMarker = new this.googleMaps.Marker({
      position,
      map,
      icon: {
        path: this.googleMaps.SymbolPath.CIRCLE,
        scale: 9,
        fillColor: '#8c4c9e',
        fillOpacity: 1,
        strokeColor: '#cd94ff',
        strokeOpacity: 0.73,
        strokeWeight: 8,
      },
      zIndex: 0,
    });
  };

  /**
   * Add a List of Store Markers
   * @param {Array} Marker data object
   */
  addLocationMarkers = (locations) => {
    const markers = locations.map((location, index) => this._addLocationMarker(location, index + 1));
    this.currentBounds = new this.googleMaps.LatLngBounds();
    this.locationMakers = utils.array.arrayToObjectByKey(markers, 'id');
    markers.forEach((marker) => this.currentBounds.extend(marker.position));
  };

  /**
   * Remove All Location Markers from Map
   */
  clearLocationMarkers = () => {
    Object.keys(this.locationMakers).forEach((key) => this.locationMakers[key].setMap(null));
  };

  /**
   * Set Small Icon Store Marker
   * @private
   */
  _setStandardIcon = (marker, label) => {
    marker.setLabel({
      text: `${label}`,
      color: marker.isSoldOut ? '#414a5f' : '#fff',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: '22px',
    });
    marker.setIcon({
      url: this._getPin(marker.brand, marker.location_type, marker.isSoldOut, 'small'),
      scale: 1,
      labelOrigin: new this.googleMaps.Point(45 / 2, 24),
      scaledSize: new this.googleMaps.Size(45, 56),
      anchor: new this.googleMaps.Point(45 / 2, 56),
    });
    marker.setZIndex(100 - utils.number.getIntegerNumber(label));
    return marker;
  };

  /**
   * Set Large Icon Store Marker
   * @private
   */
  _setLargeIcon = (marker, label) => {
    marker.setLabel({
      text: `${label}`,
      color: marker.isSoldOut ? '#414a5f' : '#fff',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      fontSize: '14px',
    });
    marker.setIcon({
      url: this._getPin(marker.brand, marker.location_type, marker.isSoldOut, 'large'),
      scale: 1,
      labelOrigin: new this.googleMaps.Point(12, 12),
      scaledSize: new this.googleMaps.Size(73, 91),
      anchor: new this.googleMaps.Point(73 / 2, 91),
    });
    marker.setZIndex(101);
    return marker;
  };

  /**
   * Returns the large icon associated by type
   * @private
   * @returns {String} SVG Pin
   */
  _getPin = (brand, locationType, isSoldOut, size) => {
    if (brand === LOCATIONS.BRAND_NATIONAL) {
      return size === 'small' ? nationalNumberPin : nationalPin;
    }

    if (brand === LOCATIONS.BRAND_ENTERPRISE) {
      return size === 'small' ? enterpriseNumberPin : enterprisePin;
    }

    if (brand === LOCATIONS.BRAND_ALAMO && size === 'large') {
      switch (locationType) {
        case LOCATIONS.TYPE_AIRPORT:
          return isSoldOut ? airportPinSoldOut : airportPin;
        case LOCATIONS.TYPE_RAIL:
          return isSoldOut ? railPinSoldOut : railPin;
        case LOCATIONS.TYPE_PORT:
          return isSoldOut ? cruiseportPinSoldOut : cruiseportPin;
        case LOCATIONS.TYPE_BRANCH:
        default:
          return isSoldOut ? branchPinSoldOut : branchPin;
      }
    }

    if (isSoldOut) {
      return numberPinSoldOut;
    }

    return numberPin;
  };

  /**
   * Add a Store Marker
   * @private
   * @returns {Object} Google Map Marker
   */
  _addLocationMarker = (location, label) => {
    const { map } = this;
    const {
      brand,
      id,
      name,
      gps: { latitude, longitude },
      additional_data,
    } = location;

    const marker = Object.assign(new this.googleMaps.Marker({ map, position: { lat: latitude, lng: longitude } }), {
      brand,
      id: location.id,
      location_type: location.location_type,
      name,
      address: location.address,
      additional_data,
      locationData: location,
      isSoldOut:
        utils.config.getIsSoldOutTrueAvailability() &&
        location.location_availability === LOCATIONS.UNAVAILABLE_LOCATIONS,
    });

    marker.setTitle(name);
    this._setStandardIcon(marker, label);

    marker.addListener('click', this.handleClickMarkerEvents(id));
    marker.addListener('mouseover', this.handleMarkerEvents(id));
    marker.addListener('mouseout', this.handleMarkerEvents(id));

    return marker;
  };

  handleExpandLocation = () => {
    this.props.onExpandLocation(this.props.selectedLocationId);
  };

  handleDeselectOfBranch = () => {
    const { handleSelectedLocation } = this.props;
    handleSelectedLocation(null);
  };

  handleAdjustDateTime = () => {
    const { setExpandedTab, setMobileExpanded, breakpoint } = this.props;
    const { isMobile } = breakpoint;
    this.handleDeselectOfBranch();
    setExpandedTab('datesTimes');
    isMobile && setMobileExpanded(isMobile);
  };

  hideSoldOutAreaBanner = () => {
    this.setState({ removedBanner: true, showSoldOutAreaBanner: false });
  };

  _renderLocationDetailTile = () => {
    const { onSelectLocation, isReservationFlow } = this.props;
    const {
      brand,
      location_type,
      name,
      label,
      address: { street_addresses },
      additional_data: { formatted_city_state_zip },
      locationData,
      isSoldOut,
    } = this.activeMarker;

    const address = street_addresses.join(', ');
    const isPartner = brand !== LOCATIONS.BRAND_ALAMO;
    const partnerLocationHashMap = {
      [LOCATIONS.BRAND_ENTERPRISE]: utils.i18n('common_enterprise_long_name'),
      [LOCATIONS.BRAND_NATIONAL]: utils.i18n('common_national_long_name'),
    };

    return (
      <div className='map-location-detail-tile component-theme--light'>
        <div
          className={cn('map-location-detail-tile__container', {
            'map-location-detail-tile__container--sold-out': isSoldOut,
          })}
        >
          <Button
            plain
            className='map-location-detail-tile__close-button'
            title={utils.i18n('no_results_partner_close')}
            onClick={this.handleDeselectOfBranch}
            aria-label={utils.i18n('no_results_partner_close')}
          ></Button>
          <div
            className={cn('map-location-detail-tile__info-section', {
              'map-location-detail-tile__info-section--sold-out': isSoldOut,
            })}
          >
            <div className='map-location-detail-tile__name-info'>
              <span className='map-location-detail-tile__index-number'>{label.text}</span>
              <h4 className='map-location-detail-tile__main-title'>{name}</h4>
            </div>
            {isPartner && <p className='map-location-detail-tile__sub-title'>{partnerLocationHashMap[brand]}</p>}
            <div className='map-location-detail-tile__location-section'>
              <span
                className={cn(
                  'map-location-detail-tile__icon',
                  `map-location-detail-tile__icon--${isPartner ? brand : location_type}`,
                  {
                    'map-location-detail-tile__icon--sold-out': isSoldOut,
                  }
                )}
              />
              <div className='map-location-detail-tile__address'>
                <p>{address}</p>
                <p>{formatted_city_state_zip}</p>
              </div>
            </div>
          </div>
          {isSoldOut && <SoldOutTileBanner />}
          <div className={cn('map-location-detail-tile__actions-section map-location-detail-tile__show-line')}>
            {isReservationFlow && !isPartner ? (
              <Button
                className={cn('map-location-detail-tile__link', {
                  'map-location-detail-tile__link--sold-out': isSoldOut,
                })}
                onClick={isSoldOut ? this.handleAdjustDateTime : onSelectLocation(locationData)}
                data-dtm-track={utils.analytics.dtm(
                  ANALYTICS.UI_BUTTON,
                  ANALYTICS.LOCATION_ID(locationData.id),
                  isSoldOut ? ANALYTICS.ADJUST : ANALYTICS.SELECT
                )}
                inverse={isSoldOut}
                ariaText={`${
                  isSoldOut ? utils.i18n('common_adjust') : utils.i18n('map_locations_tile_link_text')
                } ${name}`}
              >
                {isSoldOut ? utils.i18n('common_adjust') : utils.i18n('map_locations_tile_link_text')}
              </Button>
            ) : (
              <a
                href={'#'}
                onClick={onSelectLocation(locationData)}
                className={cn('button', 'map-location-detail-tile__link', {
                  'map-location-detail-tile__partner-button': isPartner,
                })}
                rel='noopener noreferrer'
                target={`${isPartner ? '_blank' : ''}`}
                data-dtm-track={utils.analytics.dtm(
                  ANALYTICS.UI_BUTTON,
                  ANALYTICS.LOCATION_ID(locationData.id),
                  ANALYTICS.SELECT
                )}
              >
                {utils.i18n(!isPartner ? 'map_locations_tile_link_text' : 'no_results_partner_tile_link_text')}
              </a>
            )}
            {!isPartner && (
              <Button
                plain
                type='button'
                className={cn('link map-location-detail-tile__expand-details--expanded')}
                aria-label={utils.i18n('map_locations_tiles_expand_button')}
                onClick={this.handleExpandLocation}
                data-dtm-track={`button|location_${locationData.id}|details`}
              >
                {utils.i18n('map_locations_tiles_expand_button')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isVisible, showLocationDetailTile } = this.props;
    const { showRecenterButton, showSoldOutAreaBanner } = this.state;
    return (
      <div className='location-map' role='application'>
        {this.props.children}

        <div className='map-banner-area'>
          {showSoldOutAreaBanner && (
            <div className='move-map-banner'>
              <Button
                plain
                className='modal__close-btn move-map-banner__close-btn'
                aria-label='close'
                data-dtm-track={utils.analytics.dtm(ANALYTICS.LOCATION_PAGE, ANALYTICS.MAP, ANALYTICS.CLOSE)}
                onClick={this.hideSoldOutAreaBanner}
              />

              {utils.i18n('move_the_map')}
            </div>
          )}
          {showRecenterButton && (
            <Button
              plain
              type='button'
              className='button pill google-map__button-recenter'
              onClick={this.handleRecenterMap}
              aria-label={utils.i18n('recenter_map')}
            >
              {utils.i18n('recenter_map')}
            </Button>
          )}
        </div>

        {isVisible && showLocationDetailTile && this._renderLocationDetailTile()}
      </div>
    );
  }
}

LocationMap.propTypes = {
  /**
   * Google Map Container Class Name
   *
   * @type {String}
   */
  mapContainerClassName: PropTypes.string,

  /**
   * List of locations
   *
   * @type {Array}
   */
  locations: PropTypes.array,

  /**
   * Selected Location Object
   *
   * @type {Object} Center LatLng { lat: 0.0, lng: 0.0 }
   */
  selectedLocation: PropTypes.object,

  /**
   * Hovered Location Object
   *
   * @type {Object} Center LatLng { lat: 0.0, lng: 0.0 }
   */
  hoveredLocation: PropTypes.object,

  /**
   * Show Current Device Location
   *
   * @type {Boolean} Show Current Device Location
   */
  showCurrentLocation: PropTypes.bool,

  /**
   * Center
   *
   * @type {Object} Center LatLng { lat: 0.0, lng: 0.0 }
   */
  center: PropTypes.object,
  /**
   * selectedLocationId
   *
   * @type {String} the Selected Location ID
   */
  selectedLocationId: PropTypes.string,
  /**
   * resetTimeStamp
   *
   * @type {Object} function that resets the timestamp, which controls when GM reners
   */
  resetTimeStamp: PropTypes.func,
  /**
   * breakpoint
   *
   * @type {Object} Breakpoint obj
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onDragEnd: PropTypes.func,
  onZoomChanged: PropTypes.func,
  onMarkerEvents: PropTypes.func,
  onFitBounds: PropTypes.func,
  breakpoint: PropTypes.object,
  onExpandLocation: PropTypes.func,
  isUserDraggedOrZoom: PropTypes.bool,
  isReservationFlow: PropTypes.bool,
  setExpandedTab: PropTypes.func,
  setMobileExpanded: PropTypes.func,
  onSelectLocation: PropTypes.func,
  handleSelectedLocation: PropTypes.func,
  hoveredLocationId: PropTypes.string,
  isVisible: PropTypes.bool,
  showLocationDetailTile: PropTypes.bool,
  zoom: PropTypes.number,
};

LocationMap.defaultProps = {
  mapContainerClassName: 'google-map__container',
  showCurrentLocation: true,
};

export default GoogleMapHOC(LocationMap);
