import React from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import utils from 'utils';
import { havePriceDifferenceSelector } from 'selectors/reservationSelectors';
import PostResLayout from 'components/PostRes/PostResLayout';
import PostResRentalInfoLine from 'components/PostRes/PostResRentalInfoLine';
import Button from 'components/Button';
import { estimatedTotalViewSelector, getDidPrepay } from 'selectors/reservationFlowSelectors';
import PostResReviewPrice from 'components/PostRes/PostResReviewPrice';
import { additionalDriverCommit } from "actions/reservation/additionalDriverCommit";
import { modifyAbort } from "actions/reservation/modifyAbort";
import AdditionalDriverListCard from './AdditionalDriverListCard';

// TODO -  rename check-in-template styles to something more generic? or just copy styles to additional driver stylesheet?
// TODO -  add confirm button action
const AdditionalDriverEdit = () => {
  const commitAdditionalDriver = useActions(additionalDriverCommit);
  const abortAdditionalDriverFlow = useActions(modifyAbort);
  const havePriceDifference = useSelector(havePriceDifferenceSelector);
  const estimatedTotal = useSelector(estimatedTotalViewSelector);
  const zeroChargeValue = utils.getFormattedZeroPrice(estimatedTotal);
  const isPrepay = useSelector(getDidPrepay);

  return (
    <PostResLayout>
      <h1 className='check-in-template__heading'>{utils.i18n('common_additional_drivers')}</h1>
      <PostResRentalInfoLine />

      <section className='check-in-template__wrapper'>
        <section className='check-in-template__sidebar'>
          <h2 className='check-in-template__step-title' aria-label={utils.i18n('additional_driver_flow_sidebar_title')}>
            {utils.i18n('additional_driver_flow_sidebar_title')}
          </h2>
          <p className='check-in-template__description'>{utils.i18n('additional_driver_flow_sidebar_description')}</p>
        </section>

        <AdditionalDriverListCard />
      </section>

      <PostResReviewPrice />

      <div className='check-in-template__buttons-wrapper'>
        <Button
          className='check-in-template__confirm-button'
          onClick={commitAdditionalDriver}
          data-dtm-track='check_in|additional_driver|confirm'>
          {utils.i18n('common_confirm')}
        </Button>
        <Button
          link
          linkText
          className='check-in-template__discard-button'
          onClick={abortAdditionalDriverFlow}
          data-dtm-track='check_in|additional_driver|exit'>
          {utils.i18n('common_nevermind')}
        </Button>

        {isPrepay && havePriceDifference && (
          <span className='check-in-template__buttons-wrapper-footnote'>
            ({`${utils.i18n('stc_payment_information_total_charged_now')}: ${zeroChargeValue}`})
          </span>
        )}
      </div>
    </PostResLayout>
  );
};

export default AdditionalDriverEdit;
