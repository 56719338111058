import React from 'react';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import utils from 'utils';
import AfterHoursReturnInstructionsModalContent from './AfterHoursReturnInstructionsModalContent';

/**
 * AfterHoursReturnInstructionsModal - wrapper for ReturnDirectionsModalContent
 * @return {JSX}
 */

const AfterHoursReturnInstructionsModal = () => (
  <Modal
    modalKey={MODAL.AFTER_HOURS_RETURN_INSTRUCTIONS_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('after_hours_return_instructions_title')}>
    <AfterHoursReturnInstructionsModalContent />
  </Modal>
);

export default AfterHoursReturnInstructionsModal;
