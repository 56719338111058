import React from 'react';
import PropTypes from 'prop-types';
import Anchor from 'components/Anchor';

/**
 * STCEligibleLocationsBranches
 *
 * @param {object} props - React Props
 * @param {array} props.branches - Array of branches with title, url and othe details
 *
 * @return {JSX}
 */
const STCEligibleLocationsBranches = ({ branches = [] }) =>
  branches.map(branch => (
    <Anchor key={branch.peopleSoftId} href={branch.url} className='stc-eligible-locations__link link--arrow'>
      {branch.name}
    </Anchor>
  ));

STCEligibleLocationsBranches.propTypes = {
  branches: PropTypes.array,
};

export default STCEligibleLocationsBranches;
