import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import useActions from 'hooks/useActions';
import ANALYTICS from 'constants/analytics';
import { useSelector } from 'react-redux';
import googleWallet from 'images/add-to-google-wallet-badge.svg';
import appleWallet from 'images/add-to-apple-wallet-badge.svg';
import Picture from 'components/Picture';
import {
  getReservationConfirmationNumber,
  getReservationRenterInfoFirstName,
  getReservationRenterInfoLastName,
} from 'selectors/reservationSelectors';
import { platformSelector } from 'selectors/platform';
import { getSkipTheCounterCardPass } from 'actions/skipTheCounter';
import STCAddToWallet from './STCAddToWallet';

const STCViewTicket = ({ barcodeImage, carClassDetails }) => {
  const confirmationNumber = useSelector(getReservationConfirmationNumber);
  const reservationfirstName = useSelector(getReservationRenterInfoFirstName);
  const reservationLastName = useSelector(getReservationRenterInfoLastName);
  const platform = useSelector(platformSelector);

  const getSkipTheCounterCardPassCard = useActions(getSkipTheCounterCardPass);
  const mobileWalletImage = platform.isIOS ? appleWallet : googleWallet;
  const showAddToWallet = utils.config.getIsEnabledMobileWalletIntegration() && (platform.isIOS || platform.isAndroid);

  const handleClick = () => {
    utils.analytics.interaction(ANALYTICS.UI_BUTTON, ANALYTICS.ADD_TO_MOBILE_WALLET, ANALYTICS.PICKUP_TICKET);
    const payload = {
      firstName: reservationfirstName,
      lastName: reservationLastName,
      confirmationNumber,
      policiesFormattedText: true,
    };

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const pkPassDownload = (response) => {
      const blob = b64toBlob(response.save_to_wallet_file, 'application/vnd.apple.pkpass');
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'pass.pkpass'); // or any other extension
      document.body.appendChild(link);
      link.click();
    };

    getSkipTheCounterCardPassCard({ ...payload }).then((response) => {
      if (!response.messages) {
        if (platform.isIOS) {
          pkPassDownload(response);
        }
        if (platform.isAndroid) {
          window.open(response.save_to_wallet_url, '_blank');
        }
      }
    });
  };

  return (
    <div className='skip-the-counter-confirmation__ticket-modal-mobile'>
      <i className='skip-the-counter-confirmation__ticket-modal-icon' />
      <h3 className='skip-the-counter-confirmation__ticket-modal-title'>{utils.i18n('stc_pickup_ticket_label')}</h3>
      <p className='skip-the-counter-confirmation__ticket-modal-subheader'>
        {utils.i18n('stc_skip_the_counter_mobile_subheader')}
      </p>
      <p className='skip-the-counter-confirmation__ticket-modal-subheader'>
        {utils.i18n('stc_vehicle_type', [carClassDetails?.name])}
      </p>
      <Picture src={barcodeImage} className='skip-the-counter-confirmation__ticket-modal-image' />
      <STCAddToWallet srcImage={mobileWalletImage} show={showAddToWallet} handleClick={handleClick} />
      <span className='skip-the-counter-confirmation__view-ticket-modal-confirmation'>
        {utils.i18n('confirmation_number', [confirmationNumber])}
      </span>
    </div>
  );
};

STCViewTicket.propTypes = {
  carClassDetails: PropTypes.object,
};

export default STCViewTicket;
