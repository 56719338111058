import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';
import utils from 'utils';
import cn from 'classnames';
import MODAL from 'constants/modals';
import Button from 'components/Button';
import RentalTermsAndConditionsModal from 'components/modals/RentalTermsAndConditionsModal';
import PolicyTab from './PolicyTab';
import HelpSuggestion from './HelpSuggestion';

class PoliciesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedIndex: null,
      expandedTabHTML: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { expandedIndex } = this.state;
    const { selectedPolicyIndex } = this.props;
    //  If we have a policy index set it
    if (prevProps.selectedPolicyIndex !== selectedPolicyIndex && selectedPolicyIndex !== expandedIndex) {
      this.handleExpand({ index: selectedPolicyIndex });
    }
  }

  handleExpand = (state) => {
    const { setIndexOfPolicyComponentViaIndex, selectedPolicyIndex } = this.props;
    const isCurrent = this.state.expandedIndex === state.index;
    this.setState({ expandedIndex: !isCurrent && state.index });
    this.handleRenderSideContent(!isCurrent ? this.props.policies[state.index].content : '');
    if (setIndexOfPolicyComponentViaIndex && selectedPolicyIndex !== state.index) {
      setIndexOfPolicyComponentViaIndex(state.index);
    }
  };

  handleRenderSideContent = (html) => {
    this.setState({
      expandedTabHTML: html,
    });
  };

  openTermsAndConditionsModal = () => {
    const { openModal } = this.props;
    openModal(MODAL.KEY_RENTAL_FACTS_TERMS_AND_CONDITIONS);
  };

  render() {
    const { policies, backgroundArtURL, title, hideTermsConditionsCta, isRental } = this.props;
    const { expandedTabHTML } = this.state;
    const sectionTitle = title || utils.i18n('renting_at_this_location');

    return (
      // @dev fieldset and 0 opacity legend element is for accessibility compliance
      <section id='policies-component' className='policies-component component-theme--light-blue'>
        <h2
          className={cn('policies-component__title', {
            'rental-title': isRental,
          })}
        >
          {sectionTitle}
        </h2>
        <div className='policies-component__group'>
          <div className='policies-component__list'>
            {!hideTermsConditionsCta && (
              <Button link onClick={this.openTermsAndConditionsModal} className='policies-component__link'>
                {utils.i18n('review_page_rental_policies_terms_and_conditions')}
              </Button>
            )}
            <ul>
              {policies &&
                policies.map((policy, index) => (
                  <li key={index}>
                    <PolicyTab
                      {...policy}
                      index={index}
                      expanded={this.state.expandedIndex === index}
                      onToggle={this.handleExpand}
                    />
                  </li>
                ))}
            </ul>
            <HelpSuggestion />
          </div>
          <div className='policies-component__side-content'>
            {expandedTabHTML && (
              <article
                className='policies-component__article'
                dangerouslySetInnerHTML={utils.sanitize(expandedTabHTML)}
              />
            )}
            {backgroundArtURL && (
              <div className='policies-component__background-art'>
                <img src={backgroundArtURL} alt='' />
              </div>
            )}
          </div>
        </div>
        {!hideTermsConditionsCta && <RentalTermsAndConditionsModal />}
      </section>
    );
  }
}

PoliciesComponent.propTypes = {
  policies: PropTypes.array.isRequired,
  backgroundArtURL: PropTypes.string,
  backgroundArtAlt: PropTypes.string,
  setIndexOfPolicyComponentViaIndex: PropTypes.func,
  selectedPolicyIndex: PropTypes.number,
  title: PropTypes.string,
  openModal: PropTypes.func,
  termsConditions: PropTypes.string,
  hideTermsConditionsCta: PropTypes.bool,
};

PoliciesComponent.defaultProps = setDefaultProps();

export default PoliciesComponent;
