import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

/**
 * Anchor Description:
 * Generic anchor component that handles some of the same defaults
 * and best practices for external links
 *
 * @param {object} props - React Props
 * @param {string} props.className - custom classes
 * @param {boolean} props.hideExternalIcon - hide the external icon when isExternal is true
 * @param {string} props.href - string href value
 * @param {boolean} props.isExternal - will apply various enhancements for links pointing outside of the domain
 * @return {JSX}
 */
const Anchor = ({ className, disabled, hideExternalIcon, href, isExternal, fromFooter, children, ...props }) => (
  <a
    href={disabled ? null : href}
    className={cn(className, { 'link--external': !fromFooter && isExternal && !hideExternalIcon, disabled })}
    rel={isExternal ? 'noopener noreferrer' : null}
    target={isExternal ? '_blank' : null}
    {...props}>
    {children}
  </a>
);

Anchor.defaultProps = {};

Anchor.propTypes = {
  className: PropTypes.string,
  hideExternalIcon: PropTypes.bool,
  href: PropTypes.string,
  isExternal: PropTypes.bool,
};

export default Anchor;
