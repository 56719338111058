import { connect } from 'react-redux';
import utils from 'utils';
import { createSelector } from 'reselect';
import {
  locationsTextQuery,
  redirectToLocationFinder,
  resolveBranchURL,
  clearRecentSearches,
  fetchRecentSearches,
  clearTextQueryLocations,
} from 'actions/locationSearch';
import { getModifyFlag } from 'selectors/reservationSelectors';
import { LOCATION_SEARCH_PATH } from 'reducers/locationSearch';
import { breakpointSelector } from 'selectors/breakpoint';
import LocationSearchWrapper from './LocationSearchWrapper';

const getLocationsTextQueryResults = state => state.getIn(['gmi', 'solr', 'text']);
const getSolrFetchingStatus = state => state.getIn(['gmi', 'fetching', 'solr']);
const getRecentSearches = state => state.getIn([...LOCATION_SEARCH_PATH, 'recent_search_items']);
/**
 *  Filtering out empty categories from result set
 * * */
export const locationsTextQueryResultsSelector = createSelector(
  [getLocationsTextQueryResults],
  textQueryResults => {
    const textQueryResultsJS = textQueryResults.toJS();
    return Object.keys(textQueryResultsJS)
      .filter(key => utils.gmi.isArrayNotEmpty(textQueryResultsJS[key]))
      .reduce((acc, key) => ({ ...acc, [key]: textQueryResultsJS[key] }), {});
  }
);

const mapStateToProps = state => ({
  locationsTextQueryResults: locationsTextQueryResultsSelector(state) || {},
  breakpoint: breakpointSelector(state),
  isFetching: getSolrFetchingStatus(state) || false,
  recentSearchItems: getRecentSearches(state) || [],
  isTrueModify: getModifyFlag(state),
});

const mapDispatchToProps = {
  locationsTextQuery,
  redirectToLocationFinder,
  resolveBranchURL,
  clearRecentSearches,
  fetchRecentSearches,
  clearTextQueryLocations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(LocationSearchWrapper));
