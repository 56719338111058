import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import { RESERVATIONS } from 'constants';
import TextField from 'components/Form/TextField';
import RadioGroup from 'components/Form/RadioGroup';
import FieldGroupCard from '../FieldGroupCard';

class BillingAccountCard extends Component {
  state = {
    showBillingNumberInput: false,
    billingAccount: null,
  };

  componentDidMount() {
    const {
      contractBillingAccount,
      updateBillingAccount,
      updateBillingAccountType,
      billingAccountInitialValue,
      isDeeplink,
      clearBillingAccount,
      form,
    } = this.props;

    const { billingAccount, resetBillingAccount } = this.getBillingAccountType();

    if (billingAccount) {
      updateBillingAccountType(billingAccount);
    }

    const hasBillingValue = contractBillingAccount || billingAccountInitialValue;

    if (contractBillingAccount || billingAccountInitialValue) {
      updateBillingAccount(contractBillingAccount || billingAccountInitialValue);
    }

    if (resetBillingAccount || !hasBillingValue) {
      if (!hasBillingValue) {
        clearBillingAccount();
      }
      // contract_accepts_billing && !contract_billing_account
      // set default value to be pay-at-pickup
      form.change('billing.account', '');
      form.change('billing.selection', 'pay-at-pickup');
    }

    this.setState({
      billingAccount,
      showBillingNumberInput: !isDeeplink && billingAccountInitialValue,
    });
  }

  // getBillingAccountType returns type of billing_account based on the GBO response
  getBillingAccountType = () => {
    const {
      contractBillingAccount,
      billingAccountType,
      isDeeplinkWithBilling,
      contractAcceptsBilling,
      isGuaranteedReservation,
    } = this.props;

    // setting default return value based on billingAccountType, instead of setting of null or undefined values;
    let billingAccount = billingAccountType;
    let resetBillingAccount = false;
    // Checks for a Deeplink Reservation with billing number and not with a contract-billing account
    const hasOnlyDeeplinkBilling =
      contractAcceptsBilling && isDeeplinkWithBilling && !contractBillingAccount && !isGuaranteedReservation;

    // ** USE DEEPLINK BILLING **
    // For a deeplink reservation with billing number, we need to make sure to show it as preferred payment by pushing it to top
    if (hasOnlyDeeplinkBilling) {
      billingAccount = RESERVATIONS.BILLING_TYPE_CUSTOM;
    }
    // ** USE EXISTING BILLING **
    // Available when the reservation has a contract and the contract has a linked billing account. (i.e.: SNGLBN)
    else if (
      (!isGuaranteedReservation && contractBillingAccount) ||
      billingAccountType === RESERVATIONS.BILLING_TYPE_EXISTING
    ) {
      billingAccount = RESERVATIONS.BILLING_TYPE_EXISTING;
    }

    // ** USE OTHER BILLING / CUSTOM **
    // Available when contract accepts billing information but does not have a linked billing account (i.e.: MARLOW3)
    else if (
      (!isGuaranteedReservation && contractAcceptsBilling && !hasOnlyDeeplinkBilling && !contractBillingAccount) ||
      billingAccountType === RESERVATIONS.BILLING_TYPE_CUSTOM
    ) {
      billingAccount = RESERVATIONS.BILLING_TYPE_CUSTOM;
    } else {
      resetBillingAccount = true;
    }

    return {
      billingAccount,
      resetBillingAccount,
    };
  };

  onSelect = (e, input) => {
    const {
      contractBillingAccount,
      updateBillingAccountType,
      updateBillingAccount,
      clearBillingAccount,
      updatePaymentIds,
      billingAccountInitialValue,
      commitState,
      resetPaymentIDs,
      isTrueModify,
      setSelectedBillingDetails,
    } = this.props;
    const { billingAccount } = this.state;
    if (input.value === 'billing-number') {
      updateBillingAccountType(billingAccount);
      updateBillingAccount(contractBillingAccount || billingAccountInitialValue);

      if (billingAccount === RESERVATIONS.BILLING_TYPE_CUSTOM) {
        this.setState({
          showBillingNumberInput: true,
        });
      }

      if (isTrueModify && commitState?.payment_ids?.[0] === '') {
        // we shouldn't pass empty  payment_ids array , as we are setting the empty payments below when we select pay_at_counter option  we should undo the changes back when switching back to billing_number.
        resetPaymentIDs();
      }
      if (isTrueModify) {
        setSelectedBillingDetails(billingAccount);
      }
    } else {
      clearBillingAccount();
      updatePaymentIds?.(''); // only for true modify otherwise updatePaymentIds is a boolean not a function
      if (isTrueModify) {
        setSelectedBillingDetails(RESERVATIONS.PAY_AT_COUNTER);
      }
      if (!contractBillingAccount) {
        this.setState({ showBillingNumberInput: false });
      }
    }
  };

  render() {
    const { contractBillingAccount, updateBillingAccount, billingAccountInitialValue, isDeeplink } = this.props;
    const { showBillingNumberInput } = this.state;

    return (
      <FieldGroupCard title={i18n('review_billing_account_card_title')}>
        <p className='review__section-card__description'>{i18n('review_billing_account_card_description')}</p>

        <RadioGroup
          name='billing.selection'
          onChange={this.onSelect}
          errorMessage={i18n('review_billing_account_required_error')}
          buttons={[
            {
              label: i18n('review_billing_account_pay_at_pickup_label'),
              value: 'pay-at-pickup',
              description: i18n('review_billing_account_pay_at_pickup_description'),
            },
            {
              label: i18n('review_billing_account_billing_number_label'),
              value: 'billing-number',
              description: i18n(
                contractBillingAccount
                  ? 'review_billing_account_single_billing_number_description'
                  : 'review_billing_account_billing_number_description'
              ),
            },
          ]}
        />

        {(contractBillingAccount || (isDeeplink && billingAccountInitialValue)) && (
          <span className='review__section-card__account-number'>
            <b>{i18n('review_billing_account_account_number')}</b>{' '}
            {contractBillingAccount || billingAccountInitialValue}
          </span>
        )}

        {showBillingNumberInput && (!contractBillingAccount || !billingAccountInitialValue) && (
          <TextField
            name='billing.account'
            label={i18n('review_billing_account_billing_number_input_label')}
            onChange={updateBillingAccount}
            fill
            required
            initialValueButton={billingAccountInitialValue}
          />
        )}
      </FieldGroupCard>
    );
  }
}

BillingAccountCard.propTypes = {
  contractBillingAccount: PropTypes.string,
  billingAccountType: PropTypes.string,
  updateBillingAccount: PropTypes.func.isRequired,
  updateBillingAccountType: PropTypes.func.isRequired,
  clearBillingAccount: PropTypes.func.isRequired,
  updatePaymentIds: PropTypes.func,
  billingAccountInitialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  form: PropTypes.object,
  resetpaymentIDs: PropTypes.func,
};

export default BillingAccountCard;
