import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import { loadingOverlay } from 'utils/dom';
import { analyticsInteraction } from 'utils/analytics';
import Button from 'components/Button';
import Title from 'components/Title';
import carSuv from 'images/car-suv.svg';
import { ANALYTICS, SESSION_STORAGE } from 'constants';

/**
 * RentalTripCard Component - Responsible to show a card containing the basic information for a return confirm trip
 *
 * @param {object} props - React Props
 * @param {boolean} props.confirmReturnTripCard - true if this tripCard component is being loaded on current Trips loop
 * @param {boolean} props.showAfterHoursCta - If true, user will be able to see after hours info CTA
 * @param {string} props.remainingDaysCopy - Copy that should be used for remaining days. This is a result of formatRemainingDays function, which returns a i18n key with all the rules already applied
 * @param {string} props.confirmation_number - Confirmation number of the trip, if this is empty, check Agreement number
 * @param {string} props.agreement_number - Agreement number of the trip, if this is empty, check Confirmation number
 * @param {string} props.location - Location that should show in the card. Pickup location for upcoming trips and Return location for current trips
 * @param {string} props.dateOfRentInteraction - Already parsed Pickup Date or Return Date to use the following pattern (Thur, Dec 5, 9:00am)
 * @param {object} props.breakpoint - Identifies mobile/tablet/desktop breakpoint status
 * @param {object} props.return_location - return address to be used on google maps location and return after hours modal
 *
 * @return {JSX} Returns TripCard component
 */

const RentalTripCard = ({
  returnLocation,
  breakpoint,
  dateOfRentInteraction,
  rentalRetrieveAndConfirmAction,
  modifyRentalAction,
  showRentalNotEligibleModal,
}) => {
  const { name, station_id, address } = returnLocation || {};

  const ctaMakeChange = () => {
    loadingOverlay(true);
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.MODIFY_RETURN);
    sessionStorage.removeItem(SESSION_STORAGE.MODIFY_RENTAL_ORIGINAL_DATE);
    modifyRentalAction().then((response) => {
      if (response.messages) {
        showRentalNotEligibleModal();
      } else {
        sessionStorage.setItem(SESSION_STORAGE.MODIFY_RENTAL_ORIGINAL_DATE, dateOfRentInteraction);
      }
      loadingOverlay(false);
    });
  };

  const ctaAccept = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.CONFIRM_RETURN);
    rentalRetrieveAndConfirmAction();
  };

  return (
    <div className='trip-card trip-card--current'>
      <div className='trip-card__details trip-card--details-current'>
        <span className='trip-card__remaining-time'>{i18n('trip_card_return_details')}</span>
        <Title type='h3' text={dateOfRentInteraction} className='trip-card__pickup-return-date' />
        <span className='trip-card__location'>
          {name} ({station_id})
        </span>
        <span className='trip-card__location-address'>
          {address?.street_addresses[0]}
          <br />
          {address?.city}, {address?.country_subdivision_code} {address?.postal}
        </span>

        <div className='trip-card__cta-area'>
          <Button className='trip-card__cta-area-button reverse-button' onClick={ctaMakeChange}>
            {i18n('confirm_return_make_change')}
          </Button>
          <Button className='trip-card__cta-area-button trip-card__cta' onClick={ctaAccept}>
            {i18n('confirm_return_this_is_correct')}
          </Button>
        </div>
      </div>
      {breakpoint.isDesktop && (
        <div className='icon-aside'>
          <div className='trip-card__suv-icon'>
            <img src={carSuv} alt='' />
          </div>
        </div>
      )}
    </div>
  );
};

RentalTripCard.propTypes = {
  currentTripCard: PropTypes.bool,
  showAfterHoursCta: PropTypes.bool,
  remainingDaysCopy: PropTypes.string,
  agreement_number: PropTypes.string,
  location: PropTypes.string,
  breakpoint: PropTypes.object.isRequired,
  dateOfRentInteraction: PropTypes.string,
  ticketNumber: PropTypes.string,
};

export default RentalTripCard;
