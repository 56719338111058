import { connect } from 'react-redux';
import utils from 'utils';
import { getTaxesPolicy } from "actions/reservation/getTaxesPolicy";
import TaxesFeesModalContent from './TaxesFeesModalContent';

const mapStateToProps = state => ({
  taxesCopy: state.getIn(['gmi', 'gma', 'content', 'taxes', 'taxes_fees_and_surcharges']),
});

const mapDispatchToProps = {
  getTaxesPolicy,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(TaxesFeesModalContent));
