import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Header from 'components/Header';
import CondensedFooter from 'components/CondensedFooter';
import { isCustomPathFlowSPASelector } from 'selectors/customPathCheckInSelectors';

/**
 * PostResFlowTemplate component
 * This wrapper component provides post-res SPA flows with the overall layout of
 *  a true modify flow - including the navigation bar and condensed footer.
 *
 * Hide footer when is Custom Path Check In Flow SPA
 *
 * @param {object} props
 * @param {object} props.children - React Components/Nodes to be rendered at the "main section" main section of the layout
 *
 * @return {JSX} PostResFlowTemplate jsx component
 */
const PostResFlowTemplate = ({ children }) => {
  const isCustomPathFlowSPA = useSelector(isCustomPathFlowSPASelector);

  return (
    <section
      className={cn('post-res', {
        'post-res__custom-path-flow': isCustomPathFlowSPA,
      })}
    >
      <Header alwaysShowKeyFactsDropdown showDiscountElements={isCustomPathFlowSPA} />
      {children}
      {!isCustomPathFlowSPA && <CondensedFooter />}
    </section>
  );
};

PostResFlowTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default PostResFlowTemplate;
