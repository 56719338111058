import React from 'react';
import utils from 'utils';
import { useSelector } from 'react-redux';
import { branchLocalAddendaSelector } from 'selectors/skipTheCounterSelectors';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES } from 'constants/modals';

/**
 * STCLocalAddendumModal - View directions to lot modal
 * @param {object} props - React Props
 * @return {JSX}
 */
const STCLocalAddendumModal = () => {
  const branchLocalAddenda = useSelector(branchLocalAddendaSelector);

  return (
    <Modal
      modalKey={MODAL.LOCAL_ADDENDUM_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('stc_local_addendum_modal_title')}>
      <>
        {branchLocalAddenda?.map(addenda => (
          <p className='modal-themed__paragraph' key={`local-addenda-${addenda.code}`}>
            {addenda.display_text}
          </p>
        ))}
      </>
    </Modal>
  );
};

export default STCLocalAddendumModal;
