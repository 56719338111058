import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';
import Anchor from 'components/Anchor';

const LocationAddress = ({ className, location, isSTCFlow, isReturnReview }) => {
  const supportNumber = useSupportPhoneNumber();
  const { address, airport_code, name, legal_name } = location;
  const { city, country_code, country_subdivision_code, postal, street_addresses } = address;
  const regionLine = [city, country_subdivision_code, country_code, postal].filter((string) => !!string).join(', ');
  return (
    <div className={cn('location-address', className)}>
      <p className='location-address__name'>
        {name}
        {airport_code && ` (${airport_code})`}
      </p>
      {street_addresses.map((line, idx) => (
        <p key={idx} className='location-address__street'>
          {line}
        </p>
      ))}
      <p
        className={cn('location-address__region', {
          'no-margin': (isSTCFlow && legal_name) || isReturnReview,
        })}
      >
        {regionLine}
      </p>
      {isSTCFlow && legal_name && <p className='location-address__legal-name'>{legal_name}</p>}
      {isReturnReview && (
        <Anchor className='link modify-return-confirmation__link' href={`tel: ${supportNumber}`}>
          {supportNumber}
        </Anchor>
      )}
    </div>
  );
};

LocationAddress.propTypes = {
  isSTCFlow: PropTypes.bool,
};

export default LocationAddress;
