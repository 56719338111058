import { connect } from 'react-redux';
import utils from 'utils';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import KeyRentalFacts from './KeyRentalFacts';

const mapStateToProps = (state) => ({
  hasKeyFactsPolicies: !!state.getIn(['gmi', 'session', 'gbo', 'reservation', 'key_facts_policies']),
});

const mapDispatchToProps = {
  openKeyRentalFactsModal: () => openModal(MODAL.KEY_RENTAL_FACTS),
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(KeyRentalFacts));
