import React from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { email as emailValidation } from 'utils/validation';
import { actions } from '@ehi/global-marketing-interface';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES, SESSION_STORAGE } from 'constants';
import useActions from 'hooks/useActions';
import { modifyProfile } from 'actions/profile';
import { breakpointSelector } from 'selectors/breakpoint';
import { hasInvalidEmailCode, hasGmaErrorMessagesSelector, hasDuplicateEmailCode } from 'selectors/errorSelectors';
import Form from 'components/Form/Form';
import { ModalFooter } from 'components/Modal/ModalFooter';
import TextField from 'components/Form/TextField';
import Modal from 'components/Modal';
import ServiceErrors from 'components/ServiceErrors';
import Storage from 'utils/storageManager';
import { openModal } from "actions/modal/openModal";
import { closeModal } from "actions/modal/closeModal";

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const UpdateEmailAddressModal = () => {
  const openEmailUpdatedSuccessModal = useActions(() => openModal(MODAL.EMAIL_UPDATE_SUCCESS, { skipAnalytics: true }));
  const cleanMessagesAction = useActions(actions.cleanMessages);
  const closeModalAction = useActions(closeModal);
  const modifyProfileAction = useActions(modifyProfile);
  const hasServiceError = useSelector(hasGmaErrorMessagesSelector);
  const breakpoints = useSelector(breakpointSelector);

  const { isMobile } = breakpoints;

  const handleOnSubmit = (values) => {
    const promise = modifyProfileAction;

    const payload = {};
    if (values.email) {
      const { email } = values;
      payload.contact = { email };
    }

    return new Promise((resolve) => {
      promise(payload)
        .then((response) => {
          if (response?.messages?.length) {
            let errorMessage = '';

            if (response.messages.some(hasInvalidEmailCode)) {
              errorMessage = i18n('field_must_be_valid_email');
            } else if (response.messages.some(hasDuplicateEmailCode)) {
              errorMessage = i18n('email_already_in_use_modal_error_heading');
            }
            throw new Error(errorMessage);
          } else {
            closeModalAction({ analyticsValue: i18n('common_save_and_continue').toLowerCase() }); // on success close modal
            cleanMessagesAction(); // clears gma service errors from redux
            Storage.SessionStorage.remove(SESSION_STORAGE.SHOW_EMAIL_UPDATE_MODAL);
            openEmailUpdatedSuccessModal();
          }
          resolve(true);
        })
        .catch((error) => {
          if (error) {
            const submissionErrors = {
              email: error?.message,
            };
            resolve({ ...submissionErrors });
          }
        });
    });
  };

  return (
    <Modal
      modalKey={MODAL.UPDATE_EMAIL_ADDRESS_MODAL}
      header={i18n('update_email_address_header_title')}
      theme={MODAL_THEMES.WHITE}
      customClass='update-email-address-modal'
      customActionOnClose={() => {
        Storage.SessionStorage.remove(SESSION_STORAGE.SHOW_EMAIL_UPDATE_MODAL);
      }}
    >
      {({ handleClose }) => (
        <Form
          id={`${MODAL.UPDATE_EMAIL_ADDRESS_MODAL}_desc`}
          onSubmit={handleOnSubmit}
          subscription={{ submitting: true, pristine: true, submitFailed: true, values: true }}
        >
          {({ handleSubmit }) => (
            <form className='update-email-address-modal' onSubmit={handleSubmit} autoComplete='off' noValidate>
              <ServiceErrors />
              <div className='update-email-address-modal__container'>
                <p className='update-email-address-modal__container--title-copy-1'>
                  {i18n('update_email_address_header_title_info_copy_1')}
                </p>
                <p className='update-email-address-modal__container--title-copy-2'>
                  {i18n('update_email_address_header_title_info_copy_2')}
                </p>
                <div className='update-email-address-modal__container-row'>
                  <TextField
                    name='email'
                    label={i18n('enrollment_form_email_address')}
                    required
                    fill_50
                    autoComplete='email'
                    validations={[emailValidation]}
                    forceError={hasServiceError}
                  />
                </div>
              </div>
              <ModalFooter
                fullWidthButton
                stickyFooterCTA={isMobile}
                buttons={[
                  {
                    type: DECLINE,
                    label: i18n('common_remind_me_next_time'),
                    extraLargeButton: true,
                    handler: () => {
                      handleClose({ analyticsValue: i18n('common_remind_me_next_time').toLowerCase() });
                      Storage.SessionStorage.remove(SESSION_STORAGE.SHOW_EMAIL_UPDATE_MODAL);
                    },
                  },
                  {
                    extraLargeButton: true,
                    type: ACCEPT,
                    label: i18n('common_save_and_continue'),
                    handler: handleSubmit,
                  },
                ]}
              />
            </form>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default UpdateEmailAddressModal;
