import { connect } from 'react-redux';
import utils from 'utils';
import { profileDataSelector } from 'selectors/profileSelectors';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import DriversLicense from './DriversLicense';

const mapStateToProps = state => ({
  profileData: profileDataSelector(state, { license_profile: true, oci_individual_status: true }),
});

const mapDispatchToProps = {
  openEditModal: () => openModal(MODAL.EDIT_DRIVERS_LICENSE_MODAL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(DriversLicense));
