import React from 'react';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import ProfileForm from 'components/ReservationFlow/InResAcceleratedCheckin/ProfileForm';
import utils from 'utils';

const InResAcceleratedCheckinEditModal = () => {
  const renderFooter = formSubmit => {
    const buttons = [
      {
        type: MODAL_BUTTON_TYPES.DECLINE,
        label: utils.i18n('common_cancel'),
        analyticsValue: ANALYTICS.CANCEL,
      },
      {
        type: MODAL_BUTTON_TYPES.ACCEPT,
        label: utils.i18n('common_save'),
        handler: formSubmit,
        analyticsHandler: () =>
          utils.analytics.interaction(
            ANALYTICS.MODAL,
            utils.analytics.prepareKey(MODAL.IN_RES_OCI_EDIT_PROFILE_MODAL),
            ANALYTICS.SIGN_IN
          ),
      },
    ];

    return <ModalFooter buttons={buttons} disableStickyFooter />;
  };

  return (
    <Modal
      modalKey={MODAL.IN_RES_OCI_PARTIAL_PROFILE_MODAL}
      header={utils.i18n('accelerated_check_in_modal_title')}
      customClass='in-res-oci-modal'
      theme={MODAL_THEMES.WHITE}>
      {({ scrollToModalTop, handleClose }) => (
        <>
          <p className='in-res-oci-modal__description'>{utils.i18n('in_res_oci_auth_partial_profile')}</p>
          <ProfileForm onSubmitSuccess={handleClose} scrollToModalTop={scrollToModalTop} renderFooter={renderFooter} />
        </>
      )}
    </Modal>
  );
};

export default InResAcceleratedCheckinEditModal;
