import React from 'react';
import PropTypes from 'prop-types';
import STCEligibleLocationsBranches from './STCEligibleLocationsBranches';

/**
 * STCEligibleLocationsBytState
 *
 * @param {object} props - React Props
 * @param {object} props.branchesByState - States with array of branches/locations
 *
 * @return {JSX}
 */
const STCEligibleLocationsBytState = ({ branchesByState }) =>
  Object.entries(branchesByState).map(([stateName, branches]) => (
    <div key={`stc_state_eligible_locations_${stateName}`} className='stc-eligible-locations__state-block'>
      <p className='stc-eligible-locations__state-name'>{stateName}</p>
      <STCEligibleLocationsBranches branches={branches} />
    </div>
  ));

STCEligibleLocationsBytState.propTypes = {
  branchesByState: PropTypes.object,
};

export default STCEligibleLocationsBytState;
