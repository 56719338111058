import React from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { loadingRedirect } from 'utils/redirect';
import { analyticsInteraction, analyticsPrepareKey } from 'utils/analytics';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { isCustomPathReservationSelector, isRestrictedReservationSelector } from 'selectors/customPathCheckInSelectors';
import { isThirdPartyReservationSelector } from 'selectors';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * STCAlreadyActivatedModal - Modal shown to the user trying to start Skip The Counter
 * flow with a Reservation that is already skipping the counter.
 * @return {JSX}
 */
const STCAlreadyActivatedModal = () => {
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const isRestrictedReservation = useSelector(isRestrictedReservationSelector);
  const isThirdPartyReservation = useSelector(state => isThirdPartyReservationSelector(state));

  return (
    <Modal
      modalKey={MODAL.STC_ALREADY_ACTIVATED_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={i18n('stc_already_activated_modal_title')}>
      <>
        <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
          {i18n('stc_already_activated_modal_copy')}
        </p>

        <ModalFooter
          buttons={[
            {
              type: DECLINE,
              label: i18n('common_cancel'),
            },
            {
              type: ACCEPT,
              label: i18n('common_view_details'),
              handler: () =>
                loadingRedirect(
                  isCustomPathReservation || isRestrictedReservation || isThirdPartyReservation
                    ? WINDOW_OBJECT_KEYS.CUSTOM_PATH_RENTAL_DETAILS
                    : WINDOW_OBJECT_KEYS.RENTAL_DETAILS
                ),
              analyticsHandler: () =>
                analyticsInteraction(
                  ANALYTICS.MODAL,
                  analyticsPrepareKey(MODAL.STC_ALREADY_ACTIVATED_MODAL),
                  ANALYTICS.VIEW_DETAILS
                ),
            },
          ]}
        />
      </>
    </Modal>
  );
};
export default STCAlreadyActivatedModal;
