import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import MyProfileSectionTitle from '../MyProfileSectionTitle';
import EditProfilePRPModal from '../EditProfilePRPModal';

/**
 * ProfilePRP
 * block to show users profile with Partner Rewards Program infos
 *
 * @param {object} props - React Props
 * @param {object} props.profileData - Authenticated user profile data
 * @param {object} props.profileData.partnerRewardsProgram - Authenticated user attached Partner Rewards Program infos
 * @param {function} props.openEditModal - Function to open modal with edit form
 *
 * @return {JSX}
 */
const ProfilePRP = ({ profileData, openEditModal }) => {
  const { partnerRewardsProgram } = profileData || {};
  const { name, number } = partnerRewardsProgram || {};

  return (
    <div className='my-profile__section my-profile__section--top-border'>
      <MyProfileSectionTitle
        title={utils.i18n('my_profile_prp')}
        handleEdit={openEditModal}
        isCompleted={!!partnerRewardsProgram}
      />
      {partnerRewardsProgram && (
        <>
          <p className='my-profile__info-row'>
            {utils.i18n('my_profile_prp_program_name')}: {name}
          </p>
          <p className='my-profile__info-row'>
            {utils.i18n('my_profile_prp_member_id')}: {number}
          </p>
        </>
      )}

      <EditProfilePRPModal />
    </div>
  );
};

ProfilePRP.propTypes = {
  profileData: PropTypes.shape({
    partnerRewardsProgram: PropTypes.object,
  }),
  openEditModal: PropTypes.func,
};

export default ProfilePRP;
