import PropTypes from 'prop-types';

/**
 * Authenticated Description:
 * Reusable render props wrapper that provides authentication info from redux
 * options will subscribe to profileData and return the profileData object property
 * usage example where profileData might be { age: 39, memberNumber: 123456 }
 * <Authenticated options={{ age: true, memberNumber: true }}>{({ logged_in, profileData }) => (logged_in ? <LoggedInComponent {...profileData} /> : null}</Authenticated>;
 *
 * @param {object} props - React Props
 * @param {boolean} props.logged_in - is the user logged in
 * @param {boolean} props.keepMeLoggedIn - if user should keep logged in
 * @param {object} props.options - options to subscribe to in profileData
 * @param {function(props)} props.children - render props, children should be a function
 * @return {JSX}
 */
const Authenticated = ({ children, ...props }) => (typeof children === 'function' ? children(props) : null);

Authenticated.propTypes = {
  children: PropTypes.func.isRequired,
  logged_in: PropTypes.bool,
  keepMeLoggedIn: PropTypes.bool,
  options: PropTypes.object,
};

export default Authenticated;
