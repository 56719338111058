import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { utils as ehiUtils } from '@ehi/global-marketing-interface';
import cn from 'classnames';
import utils from 'utils';
import useActions from 'hooks/useActions';
import LOCATIONS from 'constants/locations';
import ANALYTICS from 'constants/analytics';
import { getUiStateShowPartnersLocations } from 'selectors/uiStateSelectors';
import { getOneWayGmaRentalFlag, getModifyFlag } from 'selectors/reservationSelectors';
import { setExpandedTab, setMobileExpanded } from 'actions/progressBar';
import Button from 'components/Button';
import GenericNotification from 'components/GenericNotification';
import MapLocationDetailTile from './MapLocationDetailTile';
import TogglePartnerLocations from './TogglePartnerLocations';

/**
 * This component renders the list of available locations being displayed in the map.
 * It allows users to display and hide partner locations if there are available ones.
 *
 * @param {object} props - React props
 * @param {boolean} props.autoShowPartnerResults - Should partner results be automatically displayed?
 * @param {boolean} props.hasAlamoLocations - Are there Alamo locations available?
 * @param {boolean} props.hasPartnerLocations - Are there partner locations available?
 * @param {boolean} props.showPartnerResults - Should partner results be displayed?
 * @param {function} props.calculateDistance - Finds the distance to a store based on GPS locations
 * @param {function} props.handleSelectedLocation - Callback function that runs when a location card is clicked
 * @param {function} props.onExpandLocation - Callback function that runs when a location is expanded
 * @param {function} props.onMouseEnterLocation - Callback function that runs when users hover a location
 * @param {function} props.onMouseLeaveLocation - Callback function that runs when users unhover a location
 * @param {function} props.onSelectLocation - Callback function that runs when a location is selected
 * @param {function} props.resetTimeStamp - Callback that resets the map timestamp and forces a re-render
 * @param {object[]} props.locations - List of locations to be displayed
 * @param {boolean} props.reservationFlow - Flag to check if it's in res
 * @param {boolean} props.allLocationsSoldOut - Flag to check if it's sold out area
 * @param {object} props.selectedLocation - Currently selected location
 * @param {string} props.expandedLocationId - The ID of the expanded location
 * @param {string} props.hoveredLocationId - The ID of the hovered location
 * @param {string} props.selectedLocationId - The ID of the selected location
 * @param {boolean} props.isMobile - Is it a mobile viewport?
 *
 * @return {JSX}
 */
const MapLocationDetail = ({
  autoShowPartnerResults,
  showPartnerResults,
  locations,
  hasAlamoLocations,
  hasPartnerLocations,
  hoveredLocationId,
  expandedLocationId,
  onSelectLocation,
  onExpandLocation,
  onMouseEnterLocation,
  onMouseLeaveLocation,
  calculateDistance,
  selectedLocation,
  handleSelectedLocation,
  selectedLocationId,
  resetTimeStamp,
  reservationFlow,
  allLocationsSoldOut,
  allAlamoLocationsSoldOut,
  breakpoint,
  showAdjustedSoldOut,
}) => {
  const isTrueModify = useSelector(getModifyFlag);
  const showCrossSellPartnersLocations = useSelector(getUiStateShowPartnersLocations);
  const isOneWayRental = useSelector(getOneWayGmaRentalFlag);

  const dispatchSetExpandedTab = useActions(setExpandedTab);
  const dispatchSetMobileExpanded = useActions(setMobileExpanded);

  const isGeoLocationSearch = selectedLocation?.type === LOCATIONS.TYPE_GEOLOCATION;
  const shouldDisplayList =
    hasAlamoLocations || showPartnerResults || autoShowPartnerResults || showCrossSellPartnersLocations;
  const shouldDisplayToggle =
    hasPartnerLocations &&
    !showCrossSellPartnersLocations &&
    !showPartnerResults &&
    !autoShowPartnerResults &&
    !isOneWayRental &&
    !isTrueModify;

  const changeDateTime = () => {
    dispatchSetExpandedTab('datesTimes');
    breakpoint.isMobile && dispatchSetMobileExpanded(true);
  };

  return (
    <div className='map-locations location-store-finder__list-width'>
      {shouldDisplayList && (
        <>
          {breakpoint.isTabletOrMobile && allLocationsSoldOut && !showAdjustedSoldOut && (
            <GenericNotification
              messageClass='generic-notification__sold-out-icon'
              bannerClass='generic-notification__sold-out'
              title={utils.i18n('common_sold_out').toUpperCase()}
              dtmTrack={utils.analytics.dtm(ANALYTICS.LOCATION_PAGE, ANALYTICS.SOLD_OUT, ANALYTICS.UI_BANNER)}
              message={
                hasPartnerLocations ? utils.i18n('sold_out_area_partner_locations') : utils.i18n('sold_out_area')
              }
            />
          )}

          {!ehiUtils.isArrayNotEmpty(locations) && (
            <GenericNotification
              messageClass='generic-notification__sold-out-icon'
              bannerClass='generic-notification__sold-out'
              message={utils.i18n('location_finder_vehicle_class_no_results')}
            />
          )}

          {breakpoint.isTabletOrMobile && showAdjustedSoldOut && (
            <GenericNotification
              messageClass='generic-notification__no-icon'
              bannerClass='generic-notification__sold-out'
              message={utils.i18n('adjusted_location_sold_out')}
            />
          )}

          {hasAlamoLocations && showCrossSellPartnersLocations && (
            <TogglePartnerLocations resetTimeStamp={resetTimeStamp} isShowingPartners isResFlow={reservationFlow} />
          )}
          {allLocationsSoldOut && shouldDisplayToggle && (
            <TogglePartnerLocations
              isMinimalist
              resetTimeStamp={resetTimeStamp}
              isShowingPartners={false}
              isResFlow={reservationFlow}
            />
          )}
          {allAlamoLocationsSoldOut && (
            <div className='location-store-finder__date-time-button'>
              <Button
                link
                data-track-dtm={utils.analytics.dtm(
                  ANALYTICS.LOCATION_PAGE,
                  ANALYTICS.SOLD_OUT,
                  ANALYTICS.ADJUST_DATE_TIME
                )}
                onClick={changeDateTime}
                className='location-store-finder__date-time-button__action'>
                {utils.i18n('sold_out_adjust_date_time')}
              </Button>
            </div>
          )}
          <div
            className={cn('map-locations__tiles-list', {
              'expanded-child': expandedLocationId,
            })}>
            {locations?.map((location, idx) => {
              const { address, additional_data } = location;
              const addressFormated = address?.street_addresses?.join(', ');
              const indexNumberOfLocationTile = (idx + 1).toString();
              return (
                <MapLocationDetailTile
                  key={`${idx}--${location.mainTitle}`}
                  {...{
                    key: `${idx}--${location.mainTitle}`,
                    ...location,
                    ...additional_data,
                    location,
                    hoveredLocationId,
                    expandedLocationId,
                    handleSelectedLocation,
                    selectedLocationId,
                    onExpandLocation,
                    onMouseEnterLocation,
                    onMouseLeaveLocation,
                    calculateDistance,
                    isGeoLocationSearch,
                    address: addressFormated,
                    onSelectLocation: onSelectLocation?.(location),
                    searchNum: indexNumberOfLocationTile,
                    reservationFlow,
                    changeDateTime,
                  }}
                />
              );
            })}
          </div>
          {shouldDisplayToggle && !allLocationsSoldOut && (
            <TogglePartnerLocations resetTimeStamp={resetTimeStamp} isResFlow={reservationFlow} />
          )}
        </>
      )}
    </div>
  );
};

MapLocationDetail.propTypes = {
  autoShowPartnerResults: PropTypes.bool,
  showPartnerResults: PropTypes.bool,
  locations: PropTypes.array,
  hasAlamoLocations: PropTypes.bool,
  hasPartnerLocations: PropTypes.bool,
  hoveredLocationId: PropTypes.string,
  expandedLocationId: PropTypes.string,
  onSelectLocation: PropTypes.func,
  onExpandLocation: PropTypes.func,
  onMouseEnterLocation: PropTypes.func,
  onMouseLeaveLocation: PropTypes.func,
  reservationFlow: PropTypes.bool,
  showReturnLocation: PropTypes.bool,
  calculateDistance: PropTypes.func,
  selectedLocation: PropTypes.object,
  mobileMapViewFocused: PropTypes.bool,
  handleSelectedLocation: PropTypes.func,
  selectedLocationId: PropTypes.string,
  resetTimeStamp: PropTypes.func,
  allLocationsSoldOut: PropTypes.bool,
  allAlamoLocationsSoldOut: PropTypes.bool,
  breakpoint: PropTypes.object,
  showAdjustedSoldOut: PropTypes.bool,
};

export default MapLocationDetail;
