import PropTypes from 'prop-types';

export default {
  carClass: PropTypes.string.isRequired,
  carModel: PropTypes.string.isRequired,
  carAsset: PropTypes.string.isRequired,
  carAssetMobile: PropTypes.string,
  carAssetTablet: PropTypes.string,
  carAssetAlt: PropTypes.string.isRequired,
  transmissionType: PropTypes.string.isRequired,
  electric: PropTypes.string,
  numOfPassengers: PropTypes.number.isRequired,
  numOfBags: PropTypes.number.isRequired,
  includes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  twoOrFourDoorText: PropTypes.string.isRequired,
};
