import React from 'react';
import { Container } from '@adobe/aem-react-editable-components';
import cn from 'classnames';

/**
  This is a Parsys component - mapps to Section in AEM
*/
// TODO: once AEM model is ready, this component needs to be hooked up correctly (DA)
class Parsys extends Container {
  get containerProps() {
    return {
      ...super.containerProps,
      className: cn(super.containerProps.className, 'cq-Editable-dom cq-Editable-dom--container'),
    };
  }

  get placeholderProps() {
    return {
      ...super.placeholderProps,
      placeholderClassNames: `${super.placeholderProps.placeholderClassNames} aem-Grid-newComponent cq-Editable-dom`,
    };
  }

  render() {
    return (
      <div {...this.containerProps}>
        {this.childComponents}
        {super.placeholderComponent}
      </div>
    );
  }
}

export default Parsys;
