import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import utils from 'utils';
import useActions from 'hooks/useActions';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import { reservationVehicleRateSelector, getDidPrepay, isNAPrepaySelector } from 'selectors/reservationFlowSelectors';
import {
  unpaidRefundReservationPriceDifferenceSelector,
  havePriceDifferenceSelector,
  isTourVoucherReservationSelector,
  voucherExcludedSelector,
} from 'selectors/reservationSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import Button from 'components/Button';
import Modal from 'components/Modal';
import PriceBreakdownModal from 'components/modals/PriceBreakdownModal';
import ToolTip from 'components/ToolTip';
import TOOLTIP_THEMES from 'constants/tooltipThemes';
import CurrencyConversionModal from 'components/modals/CurrencyConversionModal';
import { openModal } from "actions/modal/openModal";

/**
 * PostResReviewPrice
 *
 * @param {object} props - React Props
 *
 * @return {JSX} ...
 */
const PostResReviewPrice = ({ isSTCFlow }) => {
  const [priceDropdownModal, setPriceDropdownModal] = useState(false);

  const vehicleRate = useSelector(reservationVehicleRateSelector);
  const estimatedTotalPayment = vehicleRate?.price_summary?.estimated_total_payment;
  const estimatedTotalView = vehicleRate?.price_summary?.estimated_total_view;
  const isConverted = estimatedTotalPayment?.code !== estimatedTotalView?.code;

  const openDetailsModal = useActions(() => openModal(MODAL.PRICE_BREAKDOWN_MODAL));
  const aboutCurrencyConversion = useActions(() => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL));

  const isPrepay = useSelector(getDidPrepay);
  const isNAPrepay = useSelector(isNAPrepaySelector);
  const isTourVoucherReservation = useSelector(isTourVoucherReservationSelector);
  const voucherExcludedValue = useSelector(voucherExcludedSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const priceDifferenceUnpaidRefundPrepay = useSelector(unpaidRefundReservationPriceDifferenceSelector);
  const { difference_amount_view } = priceDifferenceUnpaidRefundPrepay;
  const isRefund = utils.isPriceDifferenceRefund(difference_amount_view);

  const havePriceDifference = useSelector(havePriceDifferenceSelector);

  const totalCopy = useCallback(
    () => {
      if (isConverted) {
        return 'check_in_new_estimated_total';
      }
      if (isCustomPathReservation) {
        return havePriceDifference ? 'post_res_new_estimated_total' : 'stc_payment_info_trip_total_custom_path';
      }
      return 'check_in_new_total';
    },
    [isConverted, isCustomPathReservation, havePriceDifference]
  );

  const handleOpenLearnMoreModal = () => {
    setPriceDropdownModal(false);
    aboutCurrencyConversion();
  };
  const handleshowLearnMoreModal = () => {
    setPriceDropdownModal(true);
    aboutCurrencyConversion();
  };

  const handleCloseCurrencyConversionModal = () => {
    priceDropdownModal && openDetailsModal();
  };
  return estimatedTotalPayment ? (
    <>
      <div
        className={cn('check-in-template-price', {
          'check-in-template-price--no-border': isCustomPathReservation,
        })}>
        <div className='check-in-template-price__header'>
          <p className='check-in-template-price__label'>{utils.i18n(totalCopy())}:</p>

          {isConverted && (
            <div className='check-in-template-price__converted'>
              <p className='check-in-template-price__converted-copy'>
                ({utils.i18n('check_in_new_total_converted_copy', [utils.getFormattedPrice(estimatedTotalPayment)])})
              </p>
              <Button onClick={() => handleOpenLearnMoreModal()} linkText>
                {utils.i18n('extras_view_currency_conversion')}
              </Button>
            </div>
          )}
        </div>
        <div className='check-in-template-price__details'>
          <p className='check-in-template-price__value'>
            {isTourVoucherReservation
              ? utils.getFormattedPrice(voucherExcludedValue.view)
              : utils.getFormattedPrice(estimatedTotalView)}
          </p>
          {!isSTCFlow && (
            <Button onClick={openDetailsModal} linkText>
              {utils.i18n('common_view_details')}
            </Button>
          )}
        </div>
        <PriceBreakdownModal showLearnMoreModal={handleshowLearnMoreModal} />
        <CurrencyConversionModal closeCurrencyConversionModal={handleCloseCurrencyConversionModal} />

        <Modal
          modalKey={MODAL.CONVERTED_PRICES_MODAL}
          header={utils.i18n('rental_details_about_currency_conversion')}
          theme={MODAL_THEMES.WHITE}>
          {() => <p className='modal-themed__copy'>{utils.i18n('converted_rates_modal_copy')}</p>}
        </Modal>
      </div>

      {isPrepay && !isCustomPathReservation && (
        <div className='check-in-template-prepaid-unpaid'>
          {isNAPrepay && !isRefund && difference_amount_view && (
            <div className='check-in-template-prepaid-unpaid__item'>
              <span className='check-in-template-prepaid-unpaid__item-text'>
                <span className='check-in-template-prepaid-unpaid__item--bold'>
                  {utils.i18n('stc_payment_price_changes_from_updates')}
                </span>
                :{' '}
                <ToolTip theme={TOOLTIP_THEMES.REGULAR_SLATE}>
                  <label>{utils.i18n('addr_prepay_tooltip_text')}</label>
                </ToolTip>
                <br />({utils.i18n('addr_payment_unpaid_due_copy')})
              </span>
              <span className='check-in-template-prepaid-unpaid__item-price check-in-template-prepaid-unpaid__item--bold'>
                {utils.getFormattedPrice(difference_amount_view)}
              </span>
            </div>
          )}

          {isRefund && (
            <div className='check-in-template-prepaid-unpaid__item'>
              <span className='check-in-template-prepaid-unpaid__item-text'>
                <span className='check-in-template-prepaid-unpaid__item--bold'>
                  {utils.i18n('addr_refund_updates_copy')}
                </span>
                :{' '}
                <ToolTip theme={TOOLTIP_THEMES.REGULAR_SLATE}>
                  <label>{utils.i18n('addr_prepay_tooltip_text')}</label>
                </ToolTip>
                <br />({utils.i18n('addr_refund_pickup_copy')})
              </span>
              <span className='check-in-template-prepaid-unpaid__item-price check-in-template-prepaid-unpaid__item--bold'>
                {utils.getFormattedPrice(difference_amount_view)}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  ) : null;
};

PostResReviewPrice.propTypes = {
  isSTCFlow: PropTypes.bool,
};

export default PostResReviewPrice;
