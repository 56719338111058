import React from 'react';
import { useSelector } from 'react-redux';
import { ASSETS } from 'constants';
import { breakpointSelector } from 'selectors/breakpoint';
import utils from 'utils';
import Picture from 'components/Picture';
import Button from 'components/Button';
import { propTypes } from './keyRentalFactsPropTypes';

const { KEY_RENTAL_FACTS_1, KEY_RENTAL_FACTS_2, KEY_RENTAL_FACTS_3 } = ASSETS;

const RentalFactsPicture = () => (
  <Picture
    className='key-rental-facts__image'
    src={KEY_RENTAL_FACTS_3}
    srcTablet={KEY_RENTAL_FACTS_2}
    srcMobile={KEY_RENTAL_FACTS_1}
    alt=''
  />
);

const KeyRentalFacts = ({ hasKeyFactsPolicies, openKeyRentalFactsModal }) => {
  const { isMobile } = useSelector(breakpointSelector);
  const isNADomain = utils.config.isNADomain();

  return (
    hasKeyFactsPolicies &&
    !isNADomain && (
      <div className='key-rental-facts'>
        {isMobile ? (
          <div className='key-rental-facts__heading-wrapper'>
            <RentalFactsPicture />
            <h2 className='key-rental-facts__heading'>{utils.i18n('key_rental_facts_heading')}</h2>
          </div>
        ) : (
          <RentalFactsPicture />
        )}
        <div className='key-rental-facts__text'>
          {!isMobile && <h2 className='key-rental-facts__heading'>{utils.i18n('key_rental_facts_heading')}</h2>}
          <p className='key-rental-facts__copy'>{utils.i18n('key_rental_facts_copy')}</p>
          <Button link className='link--arrow key-rental-facts__link' onClick={openKeyRentalFactsModal}>
            {utils.i18n('key_rental_facts_link')}
          </Button>
        </div>
      </div>
    )
  );
};

KeyRentalFacts.propTypes = propTypes;

export default KeyRentalFacts;
