import React, { useCallback, useMemo, useState } from 'react';
import utils from 'utils';

import Button from 'components/Button';
import RENTAL from 'constants/rental';
import propTypes from './ProgressBarPropTypes';

const { RENTAL_VIEWS } = RENTAL;

const ProgressBarModifyReturn = React.forwardRef((props) => {
  const { steps, breakPoint, history, children } = props;

  const { isMobile } = breakPoint || {};
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobileExpanded, setIsMobileExpanded] = useState(false);
  const activeIndex = steps.findIndex((step) => step.active);
  const childIndex = steps.findIndex((step) => step.child);

  const handleMobileExpand = () => {
    utils.scrollLock({ toggleValue: !isMobileExpanded });
    setIsMobileExpanded(!isMobileExpanded);
  };

  const handleEditReturn = () => {
    const path = RENTAL_VIEWS.DATE_TIME[0];
    if (path) {
      history.push(path);
    }
  };

  const hideExpanded = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const memoizedChildren = useMemo(
    () => React.Children.map(children, (child) => React.cloneElement(child, { afterSubmit: hideExpanded })),
    [children, hideExpanded]
  );

  if (isMobile) {
    return (
      <div className='progress-bar-mobile modify-return-progress-bar-mobile'>
        <div className='progress-bar-mobile__header-wrapper' onClick={children ? handleMobileExpand : null}>
          <div className='mobile-progress-bar__header-row-text-conent'>
            {!children && <>{steps[activeIndex].title}</>}
            {children && (
              <Button
                button={false}
                className={`progress-bar-mobile__header-expand-button ${isMobileExpanded ? 'expanded' : ''}`}
              >
                <span className='mobile-progress-bar__button-label'>{steps[activeIndex].title}</span>
              </Button>
            )}
          </div>
          <ol className='mobile-progress-bar__header-row'>
            {steps.map((step, index) => (
              <li key={step.title} className={`step-wizard-item ${step.active ? 'current-item' : ''}`}>
                <span className='progress-count'>{index + 1}</span>
              </li>
            ))}
          </ol>
        </div>

        {isMobileExpanded && (
          <div className='progress-bar-mobile__expanded-drop-down-overlay'>
            <div className='progress-bar-mobile__expanded-drop-down'>
              {steps.map((step, index) => (
                <section
                  className={`progress-bar__section component-theme--light-blue enabled ${
                    activeIndex === index ? 'current-step' : ''
                  } ${activeIndex > index ? 'completed' : ''}`}
                  key={`mobile_expanded_items_${index}`}
                >
                  <span className='section__label'>
                    {activeIndex <= index ? `${index + 1}. ` : ''}
                    {step.title}
                    {step.child && (
                      <Button onClick={handleEditReturn} button={false} class='section__edit-button'>
                        {utils.i18n('common_edit')}
                      </Button>
                      //
                    )}
                  </span>
                  <span className='section__values'>{step.value}</span>
                </section>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='progress-bar modify-return-progress-bar'>
      <div className='progress-bar__container'>
        <div className='progress-bar__tabs'>
          {steps.map((step, index) => (
            <section
              className={`progress-bar__section component-theme--light-blue ${step.active ? 'current-step' : ''} ${
                index < activeIndex ? 'completed' : 'disabled'
              }
              } ${step.child ? 'enabled' : ''} ${isExpanded && index === childIndex ? 'expanded' : ''}`}
              key={step.title}
              onClick={index === childIndex ? () => setIsExpanded(!isExpanded) : null}
            >
              <Button
                className='section__top section__button'
                button={false}
                aria-label={step.title}
                aria-expanded={isExpanded}
              >
                <span className='section__label'>{step.title}</span>
                <span className='section__values dates-times'>
                  <span className='section__value return-time'>{step.value}</span>
                </span>
              </Button>
            </section>
          ))}
          {isExpanded && children && (
            <div className='progress-bar__drawer-wrapper theme--light-blue'>
              <div className='progress-bar__drawer-content'>{memoizedChildren}</div>
            </div>
          )}
        </div>
      </div>
      {isExpanded && <div className='progress-bar__overlay visible' onClick={() => setIsExpanded(!isExpanded)}></div>}
    </div>
  );
});

ProgressBarModifyReturn.propTypes = propTypes;

export default ProgressBarModifyReturn;
