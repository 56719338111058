import React from 'react';
import { i18n } from 'utils/i18n';
import SortFilterWrapper from 'components/SortFilterWrapper';
import './styles/location-filters.scss';

const LocationFilters = props => (
  <SortFilterWrapper {...props} hideDesktopHeader closeButtonLabel={i18n('filter_view_results')} />
);

export default LocationFilters;
