import PropTypes from 'prop-types';

export const propTypes = {
  /**
   * Vehicles all available vehicles
   * @type {Array}
   */
  vehicles: PropTypes.array,

  /**
   * Currently available categories
   * @type {Array}
   */
  categories: PropTypes.array,

  /**
   * Indicator for vehicle landing page
   * @type {Boolean}
   */
  isVehicleLandingPage: PropTypes.bool,

  /**
   * Currently active breakpoint
   * @type {Array}
   */
  breakpoint: PropTypes.string,
};

export const defaultProps = { categories: [], isVehicleLandingPage: false };
