import React from 'react';
import { i18n } from 'utils/i18n';
import { isFreeUpgradeAvailable } from 'utils/vehicles';
import { GLOBAL } from 'constants';
import {
  Location,
  DatesTimes,
  Vehicle,
  Extras,
  AdditionalInformation,
  RenterInfoSection,
  RenterInfoExpandCTA,
} from 'components/RentalDetails';
import ModifyMyReturn from '../../ModifyMyReturn/ModifyMyReturn';

const RenterInformation = ({
  oneWayRental,
  returnLocation,
  returnTime,
  afterHoursReturn,
  fullName,
  memberNumber,
  maskedEmail,
  maskedPhoneNumber,
  isFoundRental,
  isCancelled,
  flightInfo,
  contractDetails,
  partner_reward_program,
  coupons,
  additionalInformation,
  priceSummary,
  breakpoint,
  isThirdPartyReservation,
  vehicleName,
  extras,
  numberOfExtrasSelected,
  selectedBundleWithExtras,
  extrasWithoutBundles,
  makeModelOrSimilarText,
  vehicleCode,
  guaranteedResCardRequired,
  isTourVoucherReservation,
  voucherExcludedValue,
  prepaySelected,
  showViewDetailsModal,
  showLearnMoreModal,
  showAHViewDetailsModal,
  showChangeReturnLocationDetailsModal,
  isRental,
}) => {
  const isDriveHappyDeal = GLOBAL.HAPPY_DEAL_CODE === vehicleCode;

  const { rental_total_view, estimated_total_view, estimated_total_vehicle_view, payment_line_items } =
    priceSummary || {};

  const savings = payment_line_items?.SAVINGS || [];
  const chargedRate = payment_line_items?.VEHICLE_RATE || [];

  let expandButtonKey = 'confirmation_reservation_details_expand_button';

  const getTotalCopy = (type) => {
    const isMobile = type === 'mobile';

    if (isTourVoucherReservation) {
      return isMobile ? 'amount_due_at_pickup_value' : 'amount_due_at_pickup';
    }
    if (!isTourVoucherReservation && prepaySelected) {
      return isMobile
        ? 'confirmation_reservation_details_pre_pay_total'
        : 'confirmation_reservation_details_pre_pay_total_desktop';
    }

    return isMobile
      ? 'confirmation_reservation_details_pay_later_total'
      : 'confirmation_reservation_details_pay_later_total_desktop';
  };

  if (breakpoint.isMobile) {
    expandButtonKey = getTotalCopy('mobile');
  }
  const freeUpgradeApplied = isFreeUpgradeAvailable(coupons);
  const showTotalPriceAdjustments = !!savings?.length || freeUpgradeApplied;

  const hasAdditionalInfo = additionalInformation?.some((info) => info.value);

  return (
    <section className='confirmation__reservation-details'>
      {!breakpoint.isDesktop && !isCancelled && (
        <ModifyMyReturn
          showChangeReturnLocationDetailsModal={showChangeReturnLocationDetailsModal}
          returnTime={returnTime}
        />
      )}

      <div className='row'>
        <h2 className='confirmation__reservation-details__header'>{i18n('rental_details_title')}</h2>

        <div className='confirmation__reservation-details__section-row'>
          <div className='confirmation__reservation-details__content component-theme--light'>
            <div className='confirmation__reservation-details__content__details-row location-date-time-row'>
              <Location oneWayRental={oneWayRental} returnLocation={returnLocation} renterDetails={true} />
              <DatesTimes
                returnTime={returnTime}
                afterHoursReturn={afterHoursReturn}
                showAHViewDetailsModal={showAHViewDetailsModal}
                renterDetails={true}
              />
            </div>
            <RenterInfoSection
              fullName={fullName}
              memberNumber={memberNumber}
              maskedEmail={maskedEmail}
              maskedPhoneNumber={maskedPhoneNumber}
              isFoundRental={isFoundRental}
              isCancelled={isCancelled}
              flightInfo={flightInfo}
              partner_reward_program={partner_reward_program}
              contractDetails={contractDetails}
            />
            {hasAdditionalInfo && (
              <div className='confirmation__reservation-details__content__details-row hasAdditionalInfo vehicle-row'>
                <AdditionalInformation additionalInformation={additionalInformation} />
              </div>
            )}

            {coupons[0] && (
              <div className='confirmation__reservation-details__content__details-row coupons vehicle-row'>
                <p className='rental-details__section-label rental-details__section-label--coupon'>
                  <span>{i18n('coupon_confirmation_section_label')}</span>
                </p>
                <p className='rental-details__section-row'>{coupons[0].description}</p>
              </div>
            )}

            <div className='confirmation__reservation-details__content__details-row vehicle-row'>
              <Vehicle
                name={vehicleName}
                makeModelOrSimilarText={makeModelOrSimilarText}
                isDriveHappyDeal={isDriveHappyDeal}
                isGuaranteedRes={guaranteedResCardRequired}
                freeUpgradeApplied={freeUpgradeApplied}
              />
            </div>

            {!!extras.length && (
              <div className='confirmation__reservation-details__content__details-row vehicle-row'>
                <Extras
                  extras={extras}
                  numberOfExtrasSelected={numberOfExtrasSelected}
                  showPrice={false}
                  selectedBundleWithExtras={selectedBundleWithExtras}
                  extrasWithoutBundles={extrasWithoutBundles}
                  displayAsList={true}
                />
              </div>
            )}

            <RenterInfoExpandCTA
              breakpoint={breakpoint}
              expandButtonKey={expandButtonKey}
              isTourVoucherReservation={isTourVoucherReservation}
              voucherExcludedValue={voucherExcludedValue}
              estimated_total_view={estimated_total_view}
              freeUpgradeApplied={freeUpgradeApplied}
              priceSummary={priceSummary}
              estimated_total_payment={rental_total_view}
              estimated_total_vehicle_view={estimated_total_vehicle_view}
              savings={savings}
              prepaySelected={prepaySelected}
              showLearnMoreModal={showLearnMoreModal}
              isThirdPartyReservation={isThirdPartyReservation}
              showViewDetailsModal={showViewDetailsModal}
              extrasWithoutBundles={extrasWithoutBundles}
              numberOfExtrasSelected={numberOfExtrasSelected}
              extras={extras}
              selectedBundleWithExtras={selectedBundleWithExtras}
              chargedRate={chargedRate}
              showTotalPriceAdjustments={showTotalPriceAdjustments}
              isRental={isRental}
            />
          </div>

          {breakpoint.isDesktop && !isCancelled && (
            <div className='confirmation__reservation-details__side-bar'>
              <ModifyMyReturn
                showChangeReturnLocationDetailsModal={showChangeReturnLocationDetailsModal}
                returnTime={returnTime}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default RenterInformation;
