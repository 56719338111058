import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';

const AdditionalInformation = ({ additionalInformation }) => (
  <div className='rental-details__section rental-details__additiona-information'>
    <p className='rental-details__section-label'>
      {utils.i18n('confirmation_reservation_additional_information_label')}
    </p>
    {additionalInformation.map(
      ({ id, name, value }) =>
        !!value && (
          <p key={id} className='rental-details__question-answer'>
            <span className='rental-details__question'>{name}:</span>
            <span className='rental-details__answer'>{value}</span>
          </p>
        )
    )}
  </div>
);

AdditionalInformation.propTypes = {
  additionalInformation: PropTypes.array,
};

export default AdditionalInformation;
