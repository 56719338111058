import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import THEMES from 'constants/themes';
import Button from 'components/Button';

/**
 * UpgradeCta - Reused upgrade button across InPathUpgrade banners
 *
 * @param {object}    props - React Props
 * @param {func}      props.upgradeHandler - action that calls upgrade service with relevant upgrade car class
 * @param {string}    props.theme - passes this value onto Button to set button color theme,
 *                    defaults to 'medium' since most IPVU banners use this theme
 * @param {string}    props.page - page type that is using the component
 * @param {string}    props.upgradeClass - car upgradeClass from inPathUpgradeDetails
 * @return {JSX}      button jsx element with common button and InPathUpgrade styles and functionality
 */
const UpgradeCta = ({ upgradeHandler, theme = THEMES.MEDIUM, page, upgradeClass }) => (
  <Button
    data-dtm-track={utils.analytics.dtm(page, 'upgrade', upgradeClass)}
    className='in-path-upgrade__cta-button'
    theme={theme}
    ghosted
    onClick={upgradeHandler}>
    {utils.i18n('in_path_vehicle_upgrade_button')}
  </Button>
);

UpgradeCta.propTypes = {
  upgradeHandler: PropTypes.func,
  theme: PropTypes.string,
  page: PropTypes.string.isRequired,
  upgradeClass: PropTypes.string.isRequired,
};

export default UpgradeCta;
