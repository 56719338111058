import { connect } from 'react-redux';
import { createProfile } from 'actions/profile';
import { getCountries } from 'actions/location';
import { MODAL, GMI_SERVICE_PATHS } from 'constants';
import { MODAL_PATH } from 'reducers/modal';
import { countriesSelector, isFetchingEndpointSelector, hasGmaErrorMessagesSelector } from 'selectors';
import { openModal } from "actions/modal/openModal";
import Enrollment from './Enrollment';

const mapStateToProps = (state) => {
  const modalQueue = state.getIn(MODAL_PATH.MODAL_QUEUE);
  const activeModalState = modalQueue.get(0);
  const isOpenShowEmailInUseModal = activeModalState?.get('modalKey') === MODAL.EMAIL_ALREADY_IN_USE_MODAL;

  return {
    countries: countriesSelector(state),
    isFetching: isFetchingEndpointSelector(state, { endpoint: GMI_SERVICE_PATHS.CREATE_PROFILE }),
    isOpenShowEmailInUseModal,
    hasServiceError: hasGmaErrorMessagesSelector(state),
  };
};

const mapDispatchToProps = {
  createProfile,
  getCountries,
  showEmailInUseModal: () => openModal(MODAL.EMAIL_ALREADY_IN_USE_MODAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);
