import React from 'react';
import cn from 'classnames';
import { propTypes } from './formattedPricePropTypes';

const FormattedPrice = ({ classNames, children }) => (
  <span className={cn('formatted-price', classNames)} data-testid={classNames}>
    {children}
  </span>
);

FormattedPrice.propTypes = propTypes;

export default FormattedPrice;
