import React from 'react';
import utils from 'utils';

const GenericGboCopyModalContent = ({ gboCopy, modalKey = '' }) => (
  <div
    id={`${modalKey}_desc`}
    className='modal-themed__gbo-content'
    dangerouslySetInnerHTML={utils.sanitize(gboCopy)}
  />
);

export default GenericGboCopyModalContent;
