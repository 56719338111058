import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import MODAL_KEYS, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

/**
 * ResflowLogoutModal
 *
 * @param {object} props - React Props
 * @param {func} props.logout - dispatch function to logout the authenticated user
 * @return {JSX}
 */

const ResflowLogoutModal = ({ logout }) => {
  const logoutHandler = (handleClose) => () => {
    logout().then(() => {
      // Close modal after logout
      handleClose({ skipAnalytics: true });
      // and redirect user to standalone booking widget
      window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.STANDALONE_BOOKING_WIDGET));
    });
  };

  return (
    <Modal
      modalKey={MODAL_KEYS.RESFLOW_LOGOUT_MODAL}
      header={utils.i18n('resflow_logout_modal_header')}
      theme={MODAL_THEMES.WHITE}
    >
      {({ handleClose }) => (
        <>
          <p className='modal-themed__copy'>{utils.i18n('resflow_logout_modal_copy')}</p>
          <ModalFooter
            fullWidthButton
            buttons={[
              {
                type: MODAL_BUTTON_TYPES.DECLINE,
                label: utils.i18n('resflow_logout_modal_cancel'),
                analyticsKey: ANALYTICS.RESFLOW_LOGOUT_MODAL,
                analyticsValue: ANALYTICS.CANCEL,
              },
              {
                type: MODAL_BUTTON_TYPES.ACCEPT,
                label: utils.i18n('resflow_logout_modal_confirm'),
                handler: logoutHandler(handleClose),
                analyticsHandler: () =>
                  utils.analytics.interaction(ANALYTICS.MODAL, ANALYTICS.RESFLOW_LOGOUT_MODAL, ANALYTICS.SIGN_OUT),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

ResflowLogoutModal.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default ResflowLogoutModal;
