import React from 'react';
import utils from 'utils';
import { useSelector } from 'react-redux';
import { bundledAdditionalDriversSelector } from 'selectors/extrasSelectors';
import { additionalDriverReservationDetailsSelector } from 'selectors/reservationFlowSelectors';

const OtherDriverCost = () => {
  const addtlDriverDetails = useSelector(additionalDriverReservationDetailsSelector);
  const driversFromBundle = useSelector(bundledAdditionalDriversSelector);
  const { number_of_free_additional_drivers, rate_amount_view, total_amount_view, rate_type } = addtlDriverDetails;
  const isBundle = driversFromBundle === number_of_free_additional_drivers;
  const otherDriverCost = rate_amount_view?.format;
  const driversTotalCost = total_amount_view?.format;

  const price = utils.config.getIsIncrementalPricingEnabled() ? (
    <span>
      {otherDriverCost}/{utils.i18n(`vehicle_select_incremental_unit_${rate_type}`)}
    </span>
  ) : (
    driversTotalCost
  );

  const otherDriverCostDisplay =
    number_of_free_additional_drivers > 0
      ? utils.i18n(
          isBundle
            ? 'review_additional_drivers_num_drivers_from_bundle'
            : 'review_additional_drivers_num_drivers_included',
          [number_of_free_additional_drivers]
        )
      : price;

  return (
    <p className='additional-drivers__other-driver'>
      {utils.i18n('review_additional_drivers_other_driver')}
      <span className='additional-drivers__type-cost'>{otherDriverCostDisplay}</span>
      {number_of_free_additional_drivers > 0 &&
        utils.i18n('review_additional_drivers_more_drivers_each', [otherDriverCost])}
    </p>
  );
};

export default OtherDriverCost;
