import PropTypes from 'prop-types';

export const propTypes = {
  title: PropTypes.string,
  optionalLabel: PropTypes.bool,
  headerBorder: PropTypes.bool,
  children: PropTypes.node,
  titleContainerClass: PropTypes.string,
  themeClass: PropTypes.string,
  hideTitle: PropTypes.bool,
  completeWhenFilled: PropTypes.bool, // marked complete only when all fields have a value
  showComplete: PropTypes.bool, // manually show complete flag overriding state of contained fields
};
