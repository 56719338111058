import { createSelector } from 'reselect';

// loop through cursors, pull from state, stop when something is found
const getHasSomeDataFromCursors = (state, { config: { dataCursors } }) =>
  (dataCursors && dataCursors.some(cursor => !!state.getIn(cursor))) || null;
const getHasEveryDataFromCursors = (state, { config: { dataCursors } }) =>
  (dataCursors && dataCursors.every(cursor => !!state.getIn(cursor))) || null;

export const hasSomeDataSelector = createSelector(
  [getHasSomeDataFromCursors],
  someData => !!someData
);

export const hasEveryDataSelector = createSelector(
  [getHasEveryDataFromCursors],
  someData => !!someData
);
