import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import ViewModifyCancelFlow from './ViewModifyCancelFlow';

const ViewModifyCancelFlowWrapper = (props) => (
  <Router basename='/'>
    <CompatRouter>
      <ViewModifyCancelFlow {...props} />
    </CompatRouter>
  </Router>
);

export default ViewModifyCancelFlowWrapper;
