import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import utils from 'utils';
import CHECKIN from 'constants/checkin';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { sessionReservationObjectSelector, isReservationCancelledSelector } from 'selectors/reservationSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import { getOnlineCheckInEligibleFlag, isOciOrStcFlowNotAllowedSelector } from 'selectors/reservationFlowSelectors';
import ExitCheckInFlowModal from 'components/modals/ExitCheckInFlowModal';
import PostResFlowTemplate from 'components/PostRes/PostResFlowTemplate';
import DiscardReservationModal from 'components/ReservationFlow/SharedModals/DiscardReservationModal';
import SessionTimeoutModal from 'components/modals/SessionTimeoutModal';
import CheckInDriverLookup from './CheckInDriverLookup';
import CheckInProfile from './CheckInProfile';
import CheckInReview from './CheckInReview';
import CheckInExtras from './CheckInExtras';
import CheckInSkipTheCounterBridge from './CheckInSkipTheCounterBridge';

const { CHECKIN_PATH_CONFIG } = CHECKIN;

/**
 * Accelerated Check-in Flow SPA component
 * This component handles routing for the Accelerated Check-In flow's SPA.
 * Logic for routing itself is actually processed by GMA, so this just handles the SPA aspect.
 */
const AcceleratedCheckinFlow = () => {
  const isFetchingSession = useSelector((state) => isFetchingEndpointSelector(state, { endpoint: 'session/current' }));
  const reservation = useSelector(sessionReservationObjectSelector);
  const isCancelled = useSelector(isReservationCancelledSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const isOnlineCheckInEligible = useSelector(getOnlineCheckInEligibleFlag);
  const isOciOrStcFlowNotAllowed = useSelector(isOciOrStcFlowNotAllowedSelector);

  const isAuthorMode = utils.config.getIsAuthorModeEnabled();
  const isCustomPathEnabled = utils.config.getIsCustomPathEnabled();

  useEffect(() => {
    // If custom path flag is off, redirect to Retail Rental details page
    isCustomPathReservation && !isCustomPathEnabled && utils.loadingRedirect(WINDOW_OBJECT_KEYS.RENTAL_DETAILS);

    // if reservation is not oci eligible AND is cancelled redirect the user to V/M/C page
    // This used to be an OR check but we were redirecting normal STC eligible customers away from the `CheckInSkipTheCounterBridge` unintentionally
    if (!isFetchingSession && !isOnlineCheckInEligible && isCancelled && !isAuthorMode) {
      utils.loadingRedirect(
        isCustomPathReservation ? WINDOW_OBJECT_KEYS.CUSTOM_PATH_RENTAL_DETAILS : WINDOW_OBJECT_KEYS.RENTAL_DETAILS
      );
    }

    // if it's custom path reservation should redirect to custom path flow
    isOnlineCheckInEligible && isCustomPathReservation && utils.loadingRedirect(WINDOW_OBJECT_KEYS.CUSTOM_PATH_FLOW);
  }, [isFetchingSession, isCustomPathReservation, isCancelled, isOnlineCheckInEligible]);

  useEffect(() => {
    // if the session was already fetched and the reservation is null/invalid...
    if (!isFetchingSession && !reservation && !isAuthorMode) {
      // ...exit the check-in flow by redirecting back to check-in lookup
      window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CHECKIN_LOOKUP));
    }
  }, [isFetchingSession, reservation]);

  useEffect(() => {
    // Validate isOciOrStcFlowNotAllowed only after current session was fetched
    if (!isFetchingSession && isOciOrStcFlowNotAllowed) {
      // After navigating back from Upcoming Reservation page via browser back button, Should redirect to home page
      utils.loadingRedirect(WINDOW_OBJECT_KEYS.HOME_PAGE_URL);
    }
  }, [isFetchingSession]);

  const handleRouteUpdate = () => {
    if (!window.pageXOffset) {
      utils.dom.scrollPage();
    }

    // Dispatch the analytics ready event to indicate a "new page load" (since this is a SPA)
    utils.analytics.init(utils.config.getPageInfo());
  };

  return (
    <Router basename={'/'}>
      <PostResFlowTemplate>
        <Route>
          {({ location }) => (
            <utils.OnUpdateWrapper pathname={location.pathname} onUpdate={handleRouteUpdate}>
              <Switch>
                <Route path={CHECKIN_PATH_CONFIG.driverLookup} exact component={CheckInDriverLookup} />
                <Route path={CHECKIN_PATH_CONFIG.profile} exact component={CheckInProfile} />
                <Route path={CHECKIN_PATH_CONFIG.checkInReview} exact component={CheckInReview} />
                <Route path={CHECKIN_PATH_CONFIG.extras} exact component={CheckInExtras} />
                <Route path={CHECKIN_PATH_CONFIG.skipTheCounterBridge} exact component={CheckInSkipTheCounterBridge} />
                <Redirect to={CHECKIN_PATH_CONFIG.driverLookup[0]} from={'/'} />
              </Switch>
            </utils.OnUpdateWrapper>
          )}
        </Route>
        <ExitCheckInFlowModal />
        <DiscardReservationModal />
        <SessionTimeoutModal />
      </PostResFlowTemplate>
    </Router>
  );
};

export default AcceleratedCheckinFlow;
