import React from 'react';
import cn from 'classnames';
import { GLOBAL } from 'constants';
import utils from 'utils';
import VisHiddenText from 'components/VisHiddenText';
import { propTypes, defaultProps } from './VehicleDetailsCardPropTypes';
import VehicleDetailsCardImage from './VehicleDetailsCardImage';

const VehicleDetailsCard = (props) => {
  const {
    isOtherVehicle,
    name,
    makeModelOrSimilarText,
    images,
    transmission,
    peopleCapacity,
    electric,
    luggageCapacity,
    twoOrFourDoorText,
    url,
    code,
  } = props;

  const isDriveHappyDeal = GLOBAL.HAPPY_DEAL_CODE === code;

  let finalCopyText = isDriveHappyDeal
    ? utils.i18n('drive_happy_deal_copy')
    : utils.i18n('vehicle_details_model_or_similar', [makeModelOrSimilarText]);
  if (isOtherVehicle) {
    finalCopyText = null;
  }

  // commenting this code since we have AWR-5281
  // let carAssetAlt = isDriveHappyDeal
  //   ? utils.i18n('drive_happy_deal_car_alt')
  //   : utils.i18n('vehicle_details_car', [name]);
  // if (isOtherVehicle) {
  //   carAssetAlt = utils.i18n('vehicle_details_type', [name]);
  // }

  const carAssetClassName = cn('vehicle-details-card__image', {
    'vehicle-details-card__image--drive-happy': isDriveHappyDeal,
  });

  const cardClassName = cn('vehicle-details-card', { 'vehicle-details-card--other-vehicles': isOtherVehicle });

  // These configs are hard-coded to match the configs of the pre-loaded HTL version of the grid:
  //  aem/apps/jcr_root/apps/aweb/components/content/vehicles/vehicle-grid/vehicle-grid.html
  // If either file changes, the other one should be updated accordingly.
  const carImageSrc = images
    ? utils.url.replaceUrlParamsInBrackets(images.ThreeQuarter.imageUrl, { width: 640, quality: 'high' })
    : '';

  return (
    <section className={cardClassName}>
      <div className='vehicle-details-card__container card'>
        <div>
          <h3 className='vehicle-details-card__header'>
            <a href={url} className='card__a-link card__a-link--block'>
              {isDriveHappyDeal ? utils.i18n('drive_happy_deal_title') : name}
              <VisHiddenText message='Opens in a new window' />
            </a>
          </h3>
          {finalCopyText && <p>{finalCopyText}</p>}
        </div>
        {!isOtherVehicle && (
          <div className='vehicle-details-card__details'>
            <ul>
              {electric && (
                <li
                  title={utils.i18n('vehicle_details_electric')}
                  className='vehicle-details-card__icon vehicle-details-card__icon--electric'
                >
                  {electric}
                </li>
              )}
              {transmission && (
                <li
                  title={utils.i18n('vehicle_details_transmission')}
                  className={cn('vehicle-details-card__icon vehicle-details-card__icon--transmission', {
                    stretch: peopleCapacity && luggageCapacity && twoOrFourDoorText,
                  })}
                >
                  {transmission}
                </li>
              )}
              {peopleCapacity && !isDriveHappyDeal && (
                <li
                  title={utils.i18n('vehicle_details_passenger_capacity')}
                  className='vehicle-details-card__icon vehicle-details-card__icon--passenger'
                >
                  {peopleCapacity}
                </li>
              )}
              {luggageCapacity && !isDriveHappyDeal && (
                <li
                  title={utils.i18n('vehicle_details_baggage_capacity')}
                  className='vehicle-details-card__icon vehicle-details-card__icon--suitcase'
                >
                  {luggageCapacity}
                </li>
              )}
              {twoOrFourDoorText && !isDriveHappyDeal && (
                <li
                  title={utils.i18n('vehicle_details_number_of_doors')}
                  className='vehicle-details-card__icon vehicle-details-card__icon--doors'
                >
                  {twoOrFourDoorText}
                </li>
              )}
            </ul>
          </div>
        )}
        <div className={carAssetClassName}>
          {!!carImageSrc && <VehicleDetailsCardImage carImageSrc={carImageSrc} />}
          {isDriveHappyDeal && (
            <div className='vehicle-details-card__badge-container'>
              <div className='vehicle-details-card__badge'>{utils.i18n('special_offer')}</div>
            </div>
          )}
        </div>
        <div className='vehicle-details-card__link'>
          <span className='link--arrow'>{utils.i18n('vehicle_details_link_text')}</span>
        </div>
      </div>
    </section>
  );
};

VehicleDetailsCard.propTypes = propTypes;
VehicleDetailsCard.defaultProps = defaultProps;

export default VehicleDetailsCard;
