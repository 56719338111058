import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import ASSETS from 'constants/assets';
import Anchor from 'components/Anchor';
import Picture from 'components/Picture';
import { analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';

/**
 * EmailUnsubscribeSuccess
 *
 * @return {JSX}
 */
const EmailUnsubscribeSuccess = ({ email }) => {
  analyticsInteraction(ANALYTICS.PAGE, ANALYTICS.UNSUBSCRIBE_SUCCESSFUL, ANALYTICS.INTERACTION_OPEN);

  return (
    <>
      <p className='email-unsubscribe__paragraph'>
        {utils.i18n('email_unsub_success_email_copy', [<strong key='email_unsub'>{email}</strong>], { jsx: true })}
      </p>

      <p className='email-unsubscribe__paragraph'>
        {utils.i18n(
          'email_unsub_success_sign_in_copy',
          [
            <Anchor
              href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.EMAIL_SPECIALS)}
              className='email-unsubscribe__link'
              key='email_unsub_home_link'
              data-track-dtm='email_unsubscribe|start|sign_up'>
              {utils.i18n('email_unsub_success_sign_in_link')}
            </Anchor>,
          ],
          {
            jsx: true,
          }
        )}
      </p>

      <hr className='email-unsubscribe__divider' />

      <div className='email-unsubscribe__callout'>
        <Picture
          srcMobile={ASSETS.DEALS_1}
          srcTablet={ASSETS.DEALS_2}
          src={ASSETS.DEALS_3}
          pictureClassName='email-unsubscribe__callout-icon'
          isDecorative
        />
        <div className='email-unsubscribe__callout-content'>
          <p className='email-unsubscribe__paragraph email-unsubscribe__paragraph--mobile-left-align'>
            {utils.i18n('email_unsub_success_deals_copy')}
          </p>
          <p className='email-unsubscribe__paragraph email-unsubscribe__paragraph--mobile-left-align'>
            <Anchor
              href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.DEALS)}
              className='link link--arrow'
              data-track-dtm='email_unsubscribe|start|view_deals'>
              {utils.i18n('email_unsub_success_deals_cta')}
            </Anchor>
          </p>
        </div>
      </div>
    </>
  );
};

EmailUnsubscribeSuccess.propTypes = {
  email: PropTypes.string,
};

export default EmailUnsubscribeSuccess;
