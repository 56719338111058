import React from 'react';
import utils from 'utils';
import { ANALYTICS, MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const AdditionalDriverRequiredModal = () => (
  <Modal
    modalKey={MODAL.ADDITIONAL_DRIVER_REQUIRED_MODAL}
    header={utils.i18n('additional_driver_required_modal_header')}
    theme={MODAL_THEMES.WHITE}>
    {({ handleClose }) => (
      <>
        <p className='modal-themed__paragraph'>{utils.i18n('additional_driver_required_modal_description')}</p>
        <ModalFooter
          buttons={[
            {
              type: MODAL_BUTTON_TYPES.ACCEPT,
              label: utils.i18n('common_continue'),
              handler: () => handleClose({ analyticsValue: ANALYTICS.CONTINUE }),
              largeButton: true,
            },
          ]}
        />
      </>
    )}
  </Modal>
);

export default AdditionalDriverRequiredModal;
