import React from 'react';
import PropTypes from 'prop-types';
import Picture from 'components/Picture';
import utils from 'utils';
import ASSETS from 'constants/assets';

/**
 * STCConfirmationArrivalInstruction - Skip the Counter Pickup Instructions
 * @param {object} props - React Props
 * @param {function} props.wayfindingPolicy - object with Virgin wayfinding instructions
 * @return {JSX}
 */
const STCConfirmationArrivalInstruction = ({ wayfindingPolicy }) => (
  <div className='skip-the-counter-confirmation__section-arrival-instructions-block'>
    <Picture
      className='skip-the-counter-confirmation__section-arrival-instructions-block-image'
      src={ASSETS.HELPFUL_TIPS_ICON}
      alt={utils.i18n('rental_details_arrival_instructions')}
    />

    <div
      className='skip-the-counter-confirmation__section-arrival-instructions-block-list'
      dangerouslySetInnerHTML={utils.sanitize(wayfindingPolicy.text)}
    />
  </div>
);

STCConfirmationArrivalInstruction.propTypes = {
  wayfindingPolicy: PropTypes.object,
};

export default STCConfirmationArrivalInstruction;
