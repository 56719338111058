import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import { ModalFooter } from 'components/Modal/ModalFooter';
import Picture from 'components/Picture';
import { getReservationConfirmationNumber, selectedPaymentMethodSelector } from 'selectors/reservationSelectors';

/**
 * STCTicketModal - Skip the Counter Ticket Modal
 * @param {object} props - React Props
 * @param {string} props.barcodeImage - Barcode image source
 * @return {JSX}
 */
const STCTicketModal = ({ barcodeImage, carClassDetails, isTourVoucherReservation }) => {
  const confirmationNumber = useSelector(getReservationConfirmationNumber);
  const selectedPayment = useSelector(selectedPaymentMethodSelector);
  const paymentCardType = selectedPayment?.card_type;
  const paymentCardNumber = selectedPayment?.number ? selectedPayment?.number : '';

  let paymentCardTypeLabel = paymentCardType;
  if (isTourVoucherReservation) {
    paymentCardTypeLabel = '';
  }

  return (
    <Modal
      modalKey={MODAL.STC_VIEW_TICKET_MODAL}
      theme={MODAL_THEMES.WHITE}
      customClass={'skip-the-counter-confirmation-modal'}>
      {() => (
        <div className='skip-the-counter-confirmation__ticket-modal'>
          <i className='skip-the-counter-confirmation__ticket-modal-icon' />

          <h3 className='skip-the-counter-confirmation__ticket-modal-title'>{utils.i18n('stc_pickup_ticket_label')}</h3>
          <p className='skip-the-counter-confirmation__section-text skip-the-counter-confirmation__section-pickup-check-items'>
            {utils.i18n('stc_skip_the_counter_mobile_subheader')}
          </p>
          <p className='skip-the-counter-confirmation__section-text skip-the-counter-confirmation__section-pickup-check-items'>
            {utils.i18n('stc_vehicle_type', [carClassDetails?.name])}
          </p>
          <Picture src={barcodeImage} className='skip-the-counter-confirmation__ticket-modal-image' />

          <span className='skip-the-counter-confirmation__ticket-modal-confirmation'>
            {utils.i18n('confirmation_number', [confirmationNumber])}
          </span>

          <span className='skip-the-counter-confirmation__ticket-modal-heading'>
            {utils.i18n('stc_make_sure_bring')}
          </span>
          <ul className='skip-the-counter-confirmation__ticket-modal-list'>
            <li>
              {`${utils.i18n('stc_pickup_ticket_label')} ${' '} (${utils.i18n(`stc_printed_or_saved_ticket_mobile`)})`}
            </li>
            <li>{utils.i18n('stc_credit_card_label', [paymentCardTypeLabel, `******${paymentCardNumber}`])}</li>
            <li>{utils.i18n('stc_driver_license_drivers')}</li>
          </ul>

          <div className='skip-the-counter-confirmation__ticket-modal-cta-content'>
            <ModalFooter
              buttons={[
                {
                  type: MODAL_BUTTON_TYPES.ACCEPT,
                  label: utils.i18n('common_close'),
                },
              ]}
              fullWidthButton
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

STCTicketModal.propTypes = {
  barcodeImage: PropTypes.string,
  carClassDetails: PropTypes.object,
  isTourVoucherReservation: PropTypes.bool,
};

export default STCTicketModal;
