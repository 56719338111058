import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { MODAL } from 'constants';
import PropTypes from 'prop-types';
import utils from 'utils';
import { exclusionsSelector } from 'selectors/reservationSelectors';
import FlyoutTabDrawer from 'components/FlyoutTabs/FlyoutTabDrawer';
import { openModalWithProps } from "actions/modal/openModalWithProps";

/**
 * ExtraFlyoutTabDrawer - A wrapper for FlyoutTabDrawer with Extras logic
 * this component is responsible for getting exclusions form redux state for each Extra item
 *
 * @param {object}    props - React Props
 * @param {object}   props.exclusions - policy_exclusions inside key_facts_policies from reservation
 * @param {boolean}   props.showExclusionsModal - an openModalWithProps function
 * @return {JSX}
 */

const ExtraFlyoutTabDrawer = props => {
  const exclusions = useSelector(state => exclusionsSelector(state, props.item.lrd_policy_code));
  const showExclusionsModal = useActions(openModalWithProps);

  const { exclusionsDescription, exclusionsText } = exclusions || {};

  const handleExclusionsModal = useCallback(
    () => {
      showExclusionsModal(MODAL.EXTRAS_EXCLUSION_MODAL, {
        header: exclusionsDescription,
        gboCopy: exclusionsText,
        modalKey: MODAL.EXTRAS_EXCLUSION_MODAL,
      });
    },
    [exclusionsDescription, exclusionsText]
  );

  return (
    <FlyoutTabDrawer
      {...props}
      hasLink={!!exclusionsDescription}
      linkText={utils.i18n('view_exclusions')}
      onClickLink={handleExclusionsModal}
    />
  );
};

ExtraFlyoutTabDrawer.propTypes = {
  exclusions: PropTypes.object,
  showExclusionsModal: PropTypes.func,
};

export default ExtraFlyoutTabDrawer;
