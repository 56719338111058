import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import utils from 'utils';
import { MODAL, MODAL_THEMES, ASSETS } from 'constants';
import ANALYTICS from 'constants/analytics';
import Modal from 'components/Modal';
import { isLoggedInSelector, profileDataSelector } from 'selectors/profileSelectors';
import Button from 'components/Button';
import { openModal } from "actions/modal/openModal";
import DealsEmailSpecialsModalContent from './DealsEmailSpecialsModalContent';

const DealsEmailSpecialsModal = () => {
  const dispatch = useDispatch();
  const isAuthorMode = utils.config.getIsAuthorModeEnabled();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { special_offers } = useSelector(state => profileDataSelector(state, { special_offers: true }) || {});

  const dealsEmailSpecialsImage = {
    src: ASSETS.DEALS_EMAIL_SPECIALS_MODALS,
    alt: utils.i18n('deals_email_specials_modal_dont_miss'),
    className: 'sold-out-modal__image',
  };

  const showModal = () => {
    dispatch(openModal(MODAL.DEALS_EMAIL_SPECIALS_MODAL, {
      analyticsKey: ANALYTICS.EMAIL_SIGNUP_MODAL,
    }));
  };

  useEffect(
    () => {
      const dealsEmailSpecialsModalDisplayed = sessionStorage.getItem(utils.config.dealsEmailSpecialsModal);
      if (
        !isAuthorMode &&
        !dealsEmailSpecialsModalDisplayed &&
        typeof isLoggedIn !== 'undefined' &&
        (!isLoggedIn || (isLoggedIn && !special_offers))
      ) {
        setTimeout(() => {
          showModal();
          sessionStorage.setItem(utils.config.dealsEmailSpecialsModal, true);
        }, 1000 * 10);
      }
    },
    [isLoggedIn, special_offers]
  );

  return (
    <>
      <Modal
        modalKey={MODAL.DEALS_EMAIL_SPECIALS_MODAL}
        analyticsKey={ANALYTICS.EMAIL_SIGNUP_MODAL}
        header={utils.i18n('deals_email_specials_modal_dont_miss')}
        showHeaderImg
        headerImg={dealsEmailSpecialsImage}
        theme={MODAL_THEMES.WHITE}
        customClass={'deals-email-special__modal'}
      >
        <DealsEmailSpecialsModalContent />
      </Modal>

      {isAuthorMode && (
        <Button link onClick={showModal}>
          Click here to view the Deals modal (this button displays on author only)
        </Button>
      )}
    </>
  );
};

export default DealsEmailSpecialsModal;
