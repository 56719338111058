import React from 'react';
import utils from 'utils';
import propTypes from 'prop-types';

/**
 * ProgressBarExtras - displays the number of extras
 * @param {object} props                        - React Props
 * @param {string} props.numberOfExtrasSelected - number of selected extras
 * @return {JSX}
 */

const ProgressBarExtras = ({ numberOfExtrasSelected }) => {
  const tabHeader =
    utils.pluralHandler(
      numberOfExtrasSelected,
      'progress_bar_extras_tab',
      'progress_bar_extras_tab_plural',
      true,
      numberOfExtrasSelected
    ) || utils.i18n('progress_bar_extras_none_label');
  return <span className='section__value progress-bar-mobile__extra'>{tabHeader}</span>;
};

ProgressBarExtras.propTypes = {
  numberOfExtrasSelected: propTypes.number,
};

export default ProgressBarExtras;
