import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import { lookupContactProfileForAuthUser } from 'actions/profile';
import useActions from 'hooks/useActions';
import useWizardSteps from 'hooks/useWizardSteps';
import Modal from 'components/Modal';
import { closeModal } from "actions/modal/closeModal";
import EditDriversLicenseForm from '../EditDriversLicenseForm';
import EditContactInformationForm from '../EditContactInformationForm';
import EditProfileWizardModalContent from './EditProfileWizardModalContent';

/**
 * stepMap
 * Holds all possible configuration objects for steps in this wizard modal.
 */
const stepMap = {
  driversLicenseStep: {
    analyticsKey: 'add driver license wizard',
    title: utils.i18n('my_profile_drivers_license_modal_title'),
    text: utils.i18n('my_profile_wizard_modal_drivers_license_info'),
    Form: EditDriversLicenseForm,
  },
  contactInformationNoMatchStep: {
    analyticsKey: 'add contact information wizard',
    title: utils.i18n('my_profile_edit_contact_information_modal_title'),
    text: utils.i18n('my_profile_wizard_modal_contact_info_no_match'),
    Form: EditContactInformationForm,
  },
  contactInformationMatchStep: {
    analyticsKey: 'verify contact information wizard',
    title: utils.i18n('my_profile_wizard_modal_verify_contact_information'),
    text: utils.i18n('my_profile_wizard_modal_contact_info_driver_match'),
    Form: EditContactInformationForm,
  },
};

// This is the default setup for step order/info - when there is no match found on the driver lookup
const defaultStepsConfig = [stepMap.driversLicenseStep, stepMap.contactInformationNoMatchStep];
// This is the setup in case driver lookup was successful
const matchFoundConfig = [stepMap.driversLicenseStep, stepMap.contactInformationMatchStep];

/**
 * EditProfileWizardModal
 * Handles adding both Driver License and Contact Details in a wizard-like, step-by-step modal.
 *
 * @return {JSX} EditProfileWizardModal jsx component
 */
const EditProfileWizardModal = () => {
  // the first step (DL info) requires an after success action to be dispatched after completion
  const lookupAction = useActions(lookupContactProfileForAuthUser);

  const closeModalAction = useActions(closeModal);
  const stepsConfig = useWizardSteps(defaultStepsConfig, closeModalAction);
  const { steps, currentStep, setSteps } = stepsConfig;

  // dynamically set the lookup action to the first step
  defaultStepsConfig[0].onBeforeSubmit = payload =>
    lookupAction(payload).then(data => {
      if (data) {
        setSteps(matchFoundConfig);
      }

      return data;
    });

  const { title } = steps[currentStep - 1];

  return (
    <Modal
      modalKey={MODAL.EDIT_PROFILE_WIZARD_MODAL}
      header={title}
      theme={MODAL_THEMES.WHITE}
      customClass='my-profile__wizard-modal'
      dataDtmTrackOpen={`account_managment|step${currentStep}_modal|open`}
      dataDtmTrackClose={`account_managment|step${currentStep}_modal|close`}>
      <EditProfileWizardModalContent stepsConfig={stepsConfig} />
    </Modal>
  );
};

export default EditProfileWizardModal;
