import React from 'react';

/**
  This is a HelloWorld component
  DO NOT REMOVE -- backend uses this to test things 
*/

const HelloWorld = ({ heading, description }) => (
  <section>
    <h1>{heading}</h1>
    <p>{description}</p>
  </section>
);

export default HelloWorld;
