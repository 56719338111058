import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { MODAL, MODAL_THEMES } from 'constants';
import utils from 'utils';
import ExtendRentalInformationModalContent from './ExtendRentalInformationModalContentContainer';

/**
 * ExtendRentalInformationModal - Wrapper for ExtendRentalInformationModalContent since its open via openModalWithProps
 */

const ExtendRentalInformationModal = () => (
  <Modal
    modalKey={MODAL.EXTEND_RENTAL_INFORMATION_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('extend_rental_modal_title')}>
    <ExtendRentalInformationModalContent />
  </Modal>
);

ExtendRentalInformationModal.propTypes = {
  phoneNumber: PropTypes.string,
};

export default ExtendRentalInformationModal;
