import React from 'react';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import MODAL_KEYS, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import utils from 'utils';
import PropTypes from 'prop-types';

/**
 * RemoveCouponModal Description
 *
 * @param {object} props - React Props
 * @param {func}   props.clearDiscountInReservationFlow - dispatch function to clear discount using deeplinks
 * @return {JSX}
 */

export default function RemoveCouponModal({ clearDiscountInReservationFlow }) {
  const clearCouponAndCloseModal = handleClose => () => {
    clearDiscountInReservationFlow({ coupons: true });
    handleClose({ analyticsValue: 'Yes, Remove' });
  };

  return (
    <Modal
      modalKey={MODAL_KEYS.REMOVE_COUPON_MODAL}
      header={utils.i18n('coupon_remove_modal_header')}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose, couponDescription }) => (
        <>
          <p className='modal-themed__copy'>{utils.i18n('coupon_remove_modal_copy', [couponDescription])}</p>
          <ModalFooter
            fullWidthButton
            buttons={[
              {
                type: MODAL_BUTTON_TYPES.DECLINE,
                label: utils.i18n('progress_bar_cid_remove_modal_decline'),
                analyticsValue: "No, Don't Remove",
              },
              {
                type: MODAL_BUTTON_TYPES.ACCEPT,
                label: utils.i18n('progress_bar_cid_remove_modal_accept'),
                handler: clearCouponAndCloseModal(handleClose),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
}

RemoveCouponModal.propTypes = {
  clearDiscountInReservationFlow: PropTypes.func.isRequired,
};
