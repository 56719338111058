import React, { Component } from 'react';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import LoadingWidget from 'components/LoadingWidget';
import PrintButtonModal from 'components/Modal/PrintButtonModal';
import propTypes from './prePaymentTCRequestModalContentPropTypes';

class PrePaymentTermsAndConditionsModalContent extends Component {
  static propTypes = propTypes;

  componentDidMount() {
    const { getPrepayTerms, prepayTerms } = this.props;
    if (!prepayTerms) {
      getPrepayTerms();
    }
  }

  render() {
    const { classNames = '', prepayTerms, showPrintButton } = this.props;
    return (
      <div className='modal-print__container padding-top--condensed'>
        {prepayTerms ? (
          <>
            <PrintButtonModal
              showPrintButton={showPrintButton}
              analyticsTitle={ANALYTICS.PREPAYMENT_TERMS_CONDITIONS}
            />
            <div className={classNames} dangerouslySetInnerHTML={utils.sanitize(prepayTerms)} />
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
    );
  }
}

export default PrePaymentTermsAndConditionsModalContent;
