import React from 'react';
import { useSelector } from 'react-redux';
import { addressObjToSingleLine } from 'utils/locations';
import { i18n } from 'utils/i18n';
import BranchLocationDetail from 'components/BranchLocationDetail';
import LocationServices from 'components/LocationServices';
import { getReservationPickupLocation, getReservationReturnLocation } from 'selectors/reservationSelectors';
import { getRentalPickupLocation, getRentalReturnLocation } from 'selectors/rentalTicketSelectors';

const RentalBranchLocation = ({ isOneWayRental, confirmation, isRental, rentalLocationType = '' }) => {
  const pickupLocation = useSelector((state) =>
    isRental ? getRentalPickupLocation(state) : getReservationPickupLocation(state)
  );
  const returnLocation = useSelector((state) =>
    isRental ? getRentalReturnLocation(state) : getReservationReturnLocation(state)
  );

  const { id: pickupId } = pickupLocation;

  const {
    gps,
    location_type,
    wayfindings,
    phones,
    address,
    id: returnId,
  } = isOneWayRental ? returnLocation : pickupLocation;

  const getCorrectFormattedPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      return phoneNumber.filter((numberObject) => numberObject)[0];
    }
    return null;
  };

  return (
    <section className='confirmation__location-details-section'>
      <div className='confirmation__location-details-container row'>
        {isOneWayRental ? (
          <h2 className='confirmation__branch-details-title'>
            {i18n('branch_location_details_confirmation_title_one_way')}
          </h2>
        ) : (
          <h2 className='confirmation__branch-details-title'>
            {i18n('branch_location_details_confirmation_title_round_trip')}
          </h2>
        )}
        {returnId && (
          <BranchLocationDetail
            address={addressObjToSingleLine(address)}
            lat={gps?.latitude}
            long={gps?.longitude}
            locationType={isRental ? rentalLocationType : location_type}
            id={returnId}
            directions={wayfindings}
            unFormattedPhone={getCorrectFormattedPhoneNumber(phones)?.phone_number}
            confirmation={confirmation}
            formattedPhone={getCorrectFormattedPhoneNumber(phones)?.formatted_phone_number}
          />
        )}
      </div>
      <div className='row'>
        <h2 className='confirmation__location-Services-title'>{i18n('location_services')}</h2>
        <LocationServices id={pickupId} />
      </div>
    </section>
  );
};

export default RentalBranchLocation;
