import React from 'react';
import utils from 'utils';
import THEMES from 'constants/themes';
import PostResLayout from 'components/PostRes/PostResLayout';
import PostResRentalInfoLine from 'components/PostRes/PostResRentalInfoLine';
import ConfirmationHeaderPickupStatus from '../../Confirmation/ConfirmationHeaderPickupStatus';

/**
 * CheckInSkipTheCounterBridge
 * Page that acts as a bridge between Accelerated Check-In and Skip The Counter;
 *  Users will see this page when they commit OCI and the reservation is counter bypass eligible.
 *
 * @return {JSX} CheckInSkipTheCounterBridge jsx component
 */
const CheckInSkipTheCounterBridge = () => (
  <PostResLayout theme={THEMES.LIGHT_BLUE}>
    <section className='skip-the-counter-bridge row'>
      <section className='skip-the-counter-bridge__oci-stc-column'>
        <h1 className='skip-the-counter-bridge__heading'>{utils.i18n('check_in_stc_bridge_title')}</h1>
        <PostResRentalInfoLine isStcBridgePage />
        <ConfirmationHeaderPickupStatus isCheckedIn isStcBridgePage />
      </section>
    </section>
  </PostResLayout>
);

export default CheckInSkipTheCounterBridge;
