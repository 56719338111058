import React, { PureComponent } from 'react';
import ResponsiveListbox from 'components/Form/ResponsiveListbox';
import { propTypes } from './DriversAgePropTypes';

class DriversAge extends PureComponent {
  render() {
    const { label, options, setAge, readOnly, tooltip, driversAge, isDriverAgeUpdated } = this.props;
    return (
      <ResponsiveListbox
        id={'driversAge'}
        name={'driversAge'}
        className='fill'
        onChange={setAge}
        label={label}
        options={options}
        required
        readOnly={readOnly}
        tooltip={tooltip}
        useMobileVersionForTablet
        driversAge={driversAge}
        isDriverAgeUpdated={isDriverAgeUpdated}
      />
    );
  }
}

DriversAge.propTypes = propTypes;

export default DriversAge;
