import React, { useState, Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';

/**
 * ExtraDetailWithDrawer - This component is responsible to render a extra of a reversation.
 * This extra is rendered as a drawer, so we can reuse it on places RecommendedExtrasAlert
 *
 * @param {object} props - React Props
 * @param {string} props.code - Code of extra
 * @param {string} props.name - Name of extra
 * @param {string} props.description - Description of extra
 * @param {object} props.rateAmount - Amount per day/week/year in $ to add that extra
 * @param {object} props.totalAmount - Total amount if user adds extra
 * @param {object} props.rateType - Rate type that should show to user
 * @param {string} props.titleExtraClasses - Additional classes for title of each extra
 * @param {string} props.expandedExtraClasses - Additional classes for expanded state of extra description
 * @return {JSX} Extra Detail With Drawer component
 */

const ExtraDetailWithDrawer = ({
  code,
  name,
  description,
  rateAmount,
  totalAmount,
  rateType,
  titleExtraClasses,
  expandedExtraClasses,
}) => {
  const [expanded, setExpanded] = useState(false);
  const priceDisplay = totalAmount && utils.getFormattedPrice(totalAmount);
  const incrementalPriceDisplay =
    utils.config.getIsIncrementalPricingEnabled() &&
    rateAmount &&
    `${utils.getFormattedPrice(rateAmount)}/${utils.i18n(`vehicle_select_incremental_unit_${rateType}`)}`;

  function handleExpand() {
    // Analytics Event, this goes here since this is a local state interaction (so, not redux based!)
    utils.analytics.interaction(ANALYTICS.DRAWER, name, expanded ? ANALYTICS.CLOSE : ANALYTICS.OPEN);

    // Toggle expanded state
    setExpanded(!expanded);
  }

  return (
    <Fragment key={code}>
      <div
        className={cn('modal-themed__drawer', titleExtraClasses, {
          'modal-themed__drawer--expanded': expanded,
        })}>
        {name}
        <div className='modal-themed__drawer-price'>
          {incrementalPriceDisplay && <span>{incrementalPriceDisplay}</span>} <span>{priceDisplay}</span>
        </div>
        <Button onClick={handleExpand} link ariaExpanded={expanded.toString()}>
          {utils.i18n('extras_expand_button')}
        </Button>
      </div>
      {expanded && (
        <div
          className={cn('modal-themed__section modal-themed__gbo-content', expandedExtraClasses)}
          dangerouslySetInnerHTML={utils.sanitize(description)}
        />
      )}
    </Fragment>
  );
};

ExtraDetailWithDrawer.propTypes = {
  code: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  rateAmount: PropTypes.object,
  totalAmount: PropTypes.object,
  rateType: PropTypes.string,
  titleExtraClasses: PropTypes.string,
  expandedExtraClasses: PropTypes.string,
};

export default ExtraDetailWithDrawer;
