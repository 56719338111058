import React from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import ProfileAccountMemberBadge from 'components/ProfileAccount/ProfileAccountMemberBadge';

/**
 * Enrollment Confirmation Header
 * This is the header on the Enrollment Confirmation page
 *
 * @param {object}  props - React Props
 * @param {object} props.profileData - Authenticated user profile data
 * @param {string} props.profileData.first_name - Authenticated user first name
 * @param {string} props.profileData.email - Authenticated user Alamo Insider email
 * @param {string} props.profileData.memberNumber - Authenticated user Alamo Insiders member number
 * @return {JSX}
 */

const EnrollmentConfirmationHeader = ({ profileData }) => {
  const { first_name, email, memberNumber } = profileData || {};

  return (
    <div className='enrollment__confirmation__header'>
      <div>
        <h1>{utils.i18n('enrollment_confirmation_header_title')}</h1>
        <p className='enrollment__confirmation__header-message'>
          {utils.i18n('enrollment_confirmation_header_message', [first_name, email])}
        </p>
      </div>
      <ProfileAccountMemberBadge memberNumber={memberNumber} />
    </div>
  );
};

EnrollmentConfirmationHeader.propTypes = {
  profileData: PropTypes.shape({
    first_name: PropTypes.string,
    email: PropTypes.string,
    memberNumber: PropTypes.string,
  }),
};

export default EnrollmentConfirmationHeader;
