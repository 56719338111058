import { connect } from 'react-redux';
import {
  getModifyFlag,
  getExtrasInvalidFlag,
  includedOrAddedExtrasSelector,
  selectedBundleNotDefaultSelector,
  recommendedExtrasNotIncludedSelector,
} from 'selectors';
import ExtrasHeader from './ExtrasHeader';

const mapStateToProps = state => {
  const extras = state.getIn(['gmi', 'ui', 'reservations', 'extras'])?.toJS() || [];
  const { includedExtras } = includedOrAddedExtrasSelector(state);
  const showRecommendedExtrasBanner = !!recommendedExtrasNotIncludedSelector(state).length;
  const numberOfExtrasSelected = extras.length;
  const numberOfIncludedExtras = includedExtras.length;

  return {
    selectedBundle: selectedBundleNotDefaultSelector(state),
    numberOfExtrasSelected,
    numberOfIncludedExtras,
    showRecommendedExtrasBanner,
    isTrueModify: getModifyFlag(state),
    extrasInvalid: getExtrasInvalidFlag(state),
  };
};

export default connect(mapStateToProps)(ExtrasHeader);
