import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import ADDITIONAL_DRIVER from 'constants/additionalDriver';
import utils from 'utils';
import PostResFlowTemplate from 'components/PostRes/PostResFlowTemplate';
import DiscardReservationModal from 'components/ReservationFlow/SharedModals/DiscardReservationModal';
import SessionTimeoutModal from 'components/modals/SessionTimeoutModal';
import AdditionalDriverEdit from './AdditionalDriverEdit';
import AdditionalDriverSTCBridge from './AdditionalDriverSTCBridge';

const { ADDITIONAL_DRIVER_PATH_CONFIG } = ADDITIONAL_DRIVER;

const AdditionalDriverFlow = () => {
  const handleRouteUpdate = () => {
    if (!window.pageXOffset) {
      utils.dom.scrollPage();
    }
    // Dispatch the analytics ready event to indicate a "new page load" (since this is a SPA)
    utils.analytics.init(utils.config.getPageInfo());
  };

  return (
    <Router>
      <PostResFlowTemplate>
        <Route>
          {({ location }) => (
            <utils.OnUpdateWrapper pathname={location.pathname} onUpdate={handleRouteUpdate}>
              <Switch>
                <Route path={ADDITIONAL_DRIVER_PATH_CONFIG.edit} exact component={AdditionalDriverEdit} />
                <Route path={ADDITIONAL_DRIVER_PATH_CONFIG.confirm} exact component={AdditionalDriverSTCBridge} />
              </Switch>
            </utils.OnUpdateWrapper>
          )}
        </Route>
        <DiscardReservationModal />
        <SessionTimeoutModal />
      </PostResFlowTemplate>
    </Router>
  );
};

export default AdditionalDriverFlow;
