import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { breakpointSelector, profileDataSelector, isThirdPartyReservationSelector } from 'selectors';

import {
  getReturnDateAndTime,
  getRentalPickupLocation,
  getRentalReturnLocation,
  getRentalInfoFirstName,
  getRentalInfoLastName,
  getRentalInfoEmail,
  getRentalInfoPhoneNumber,
  getOneWayRentalFlag,
  isCustomPathReservationSelector,
  rentalCouponsSelector,
  selectedPaymentTypePath,
  guaranteedResCardRequiredSelector,
  vehicleRateSelector,
  selectedBundleWithExtrasSelector,
  selectedExtras,
  extrasWithoutBundlesSelector,
  numberOfExtrasSelector,
  rentalFormattedPoliciesSelector,
  getLocationInfoByIdSelector,
} from 'selectors/rentalTicketSelectors';

const RenterInformationCommonContainer = (state) => {
  const rentalTicket = state.getIn(['gmi', 'session', 'rental', 'ticket']);
  const carClassDetails = rentalTicket?.get('car_class_details');
  const profileData = profileDataSelector(state, { memberNumber: true, first_name: true, last_name: true });
  const selectedPaymentMethodType = state.getIn(selectedPaymentTypePath);
  const updateVehiclePrice = vehicleRateSelector(state)?.get('price_summary');
  const locationInfo = getLocationInfoByIdSelector(state);

  return {
    rentalReady: !!rentalTicket,
    isFetchingSession: isFetchingEndpointSelector(state, { endpoint: 'session/current' }),
    isCustomPathReservation: isCustomPathReservationSelector(state),

    ...(rentalTicket && {
      breakpoint: breakpointSelector(state),
      policies: rentalFormattedPoliciesSelector(state),
      ticketNumber: rentalTicket.get('ticket_number'),
      firstName: getRentalInfoFirstName(state),
      lastName: getRentalInfoLastName(state),
      maskedEmail: getRentalInfoEmail(state),
      isOneWayRental: getOneWayRentalFlag(state),
      pickupLocation: getRentalPickupLocation(state),
      returnLocation: getRentalReturnLocation(state),
      returnTime: getReturnDateAndTime(state),
      vehicleName: carClassDetails.get('name'),
      vehicleSubCategory: carClassDetails.get('sub_category'),
      makeModelOrSimilarText: carClassDetails.get('make_model_or_similar_text'),
      features: carClassDetails.get('features'),
      peopleCapacity: carClassDetails.get('people_capacity'),
      luggageCapacity: carClassDetails.get('luggage_capacity'),
      twoOrFourDoorText: carClassDetails.get('two_or_four_door_text'),
      images: carClassDetails.get('images'),
      vehicleCode: carClassDetails.get('code'),
      priceSummary: updateVehiclePrice,
      extras: selectedExtras(state),
      mileageInfo: carClassDetails.get('mileage_info'),
      prepaySelected: rentalTicket.get('prepay_selected'),
      afterHoursReturn: rentalTicket.get('after_hours_return_selected'),
      afterHoursPickup: rentalTicket.get('after_hours_pickup_selected'),
      blockAssociateProfile: rentalTicket.get('block_associate_profile'),
      maskedPhoneNumber: getRentalInfoPhoneNumber(state),
      selectedBundleWithExtras: selectedBundleWithExtrasSelector(state),
      extrasWithoutBundles: extrasWithoutBundlesSelector(state),
      numberOfExtrasSelected: numberOfExtrasSelector(state),
      isThirdPartyReservation: isThirdPartyReservationSelector(state),
      countryCode: rentalTicket.getIn(['pickup_location', 'address', 'country_code']),
      memberNumber: profileData?.memberNumber,
      guaranteedResCardRequired: guaranteedResCardRequiredSelector(state),
      coupons: rentalCouponsSelector(state),
      selectedPaymentType: selectedPaymentMethodType,
      profileFirstname: profileData?.first_name,
      profileLastname: profileData?.last_name,
      returnConfirmed: rentalTicket.get('return_confirmed'),
      locationType: locationInfo?.location_type,
    }),
  };
};

export default RenterInformationCommonContainer;
