import React from 'react';
import { THEMES } from 'constants';
import propTypes from './MessageBannerPropTypes';

const MessageBanner = ({ title, message, theme = THEMES.DARKEST_BLUE }) => (
  <div className={`message-banner component-theme--${theme}`}>
    {title && <h4 className='message-banner__title'>{title}</h4>}
    {message && <p className='message-banner__message'>{message}</p>}
  </div>
);

MessageBanner.propTypes = propTypes;

export default MessageBanner;
