import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Button from 'components/Button';
import FieldGroupCard from 'components/ReservationFlow/Review/FieldGroupCard';
import SecureConnectionDisplay from 'components/SecureConnectionDisplay';
import CreditCardDetails from 'components/CreditCardDetails';

/**
 * GuaranteedReservationAddCard card with copy regarding guaranteed vehicle selection which require a CC and CTA to add card
 *
 * @param {object}   props - React Props
 * @param {function} props.showGuaranteedResModal - opens GUARANTEED_RES modal
 * @param {function} props.showGuaranteedResIframeModal - opens GUARANTEED_RES_IFRAME modal
 * @param {object}   props.cardDetails - contains values for CC last four and card type label
 * @param {function} props.removeInflightPaymentDetails - clears data related to payment from store
 * @param {object}   props.selectedPaymentMethod - object with existing payment details from a true modify flow
 * @return {JSX}
 */

const GuaranteedReservationAddCard = ({
  showGuaranteedResModal,
  showGuaranteedResIframeModal,
  cardDetails,
  removeInflightPaymentDetails,
  selectedPaymentMethod,
}) => (
  <FieldGroupCard
    title={utils.i18n('guaranteed_res_add_card_title')}
    titleContainerClass='guaranteed-res-add-card-title'
    showComplete={!!cardDetails}>
    <div className='guaranteed-res-add-card-content'>
      <p>
        {utils.i18n(
          'guaranteed_res_copy',
          { 0: <strong key='0'>{utils.i18n('guaranteed_selection_bold')}</strong> },
          { jsx: true }
        )}
      </p>

      <p>
        {utils.i18n(
          'guaranteed_res_fee_copy',
          {
            0: (
              <Button key='0' link onClick={showGuaranteedResModal}>
                {utils.i18n('guaranteed_res_policy_link')}
              </Button>
            ),
          },
          { jsx: true }
        )}
      </p>

      <div className='guaranteed-res-add-card-content__card-section'>
        {cardDetails || selectedPaymentMethod ? (
          <CreditCardDetails
            cardDetails={selectedPaymentMethod || cardDetails}
            remove={!selectedPaymentMethod ? removeInflightPaymentDetails : null}
          />
        ) : (
          <>
            <SecureConnectionDisplay />
            <Button inverse onClick={showGuaranteedResIframeModal}>
              {utils.i18n('guaranteed_res_add_card_cta')}
            </Button>
          </>
        )}
      </div>
    </div>
  </FieldGroupCard>
);

GuaranteedReservationAddCard.propTypes = {
  showGuaranteedResModal: PropTypes.func.isRequired,
  showGuaranteedResIframeModal: PropTypes.func.isRequired,
  cardDetails: PropTypes.object,
  removeInflightPaymentDetails: PropTypes.func.isRequired,
  selectedPaymentMethod: PropTypes.object,
};

export default GuaranteedReservationAddCard;
