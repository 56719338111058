import React, { Component } from 'react';
import utils from 'utils';
import { WINDOW_OBJECT_KEYS } from 'constants';
import cn from 'classnames';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import TabindexLoop from 'components/TabindexLoop';
import SignIn from 'components/SignIn';
import PropTypes from 'prop-types';

const getHeaderWrapper = () => document.querySelector('.headerWrapper');
const overlayClass = 'show-overlay';

/**
 * UnauthenticatedFlyout Description:
 * toggles the flyout that contains the signin form
 *
 * @param {Object} props
 * @param {Object} props.breakpoint - our breakpoint selector object
 * @param {boolean} props.isFetching - true while fetching "profile/login/rememberme"
 * @param {function} props.loginRememberMe - tries to auto login the user
 * @param {boolean} props.isRememberMeEligible - is false, while logged-in, true if previously logged in and requested to stay logged in
 * @param {string} props.insidersLogoSrc - data attribute to display alamo insiders logo
 * @return {JSX}
 */
class UnauthenticatedFlyout extends Component {
  state = {
    expanded: false,
  };

  buttonRef = React.createRef();

  componentDidMount() {
    // refer to ui/src/actions/authentication.js for more information
    this.props.isRememberMeEligible && this.props.loginRememberMe();
  }

  componentDidUpdate(prevProps, prevState) {
    // if expanded state changes, toggle the overlay class on the headerWrapper
    if (prevState.expanded !== this.state.expanded) {
      const headerWrapper = getHeaderWrapper();
      headerWrapper && this.state.expanded
        ? headerWrapper.classList.add(overlayClass)
        : headerWrapper.classList.remove(overlayClass);
    }

    if (this.state.expanded && prevProps.breakpoint.isDesktop !== this.props.breakpoint.isDesktop) {
      this.setState({ expanded: false });
    }

    !prevProps.isRememberMeEligible && this.props.isRememberMeEligible && this.props.loginRememberMe();
  }

  componentWillUnmount() {
    const headerWrapper = getHeaderWrapper();
    headerWrapper?.classList.remove(overlayClass);
  }

  // on desktop this toggles the flyout, on mobile/tablet, this link to a sign in page
  handleSigninClick = () => {
    const { disableRedirectOnSignIn, breakpoint } = this.props;
    if (breakpoint.isDesktop) {
      this.state.expanded ? this.handleCollapse() : this.handleExpand();
    } else {
      // get redirect object from the context
      const redirectUrls = utils.gmi.get(window, 'ehi.aem.redirects');

      const getKeyByValue = utils.object.getKeyByValue(redirectUrls, location.pathname);

      // Condition to get specifi key name based on pathname and navigating to the page if user uses confirmation / view modify details page for logging in
      if (
        getKeyByValue &&
        [WINDOW_OBJECT_KEYS.CONFIRMATION, WINDOW_OBJECT_KEYS.RENTAL_DETAILS].includes(getKeyByValue)
      ) {
        localStorage.setItem(utils.config.goToSpecificPagePath, getKeyByValue);
      }

      window.location.assign(
        `${utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.SIGN_IN_PAGE)}${
          disableRedirectOnSignIn ? '?redirectBack=true' : ''
        }`
      );
      utils.analytics.interaction('menu', 'nav', utils.i18n('signin_flyout_toggle'));
    }
  };

  handleExpand = () => {
    this.setState({ expanded: true }, () => {
      const input = this.clickOutsideRef.current.querySelector('input');
      input && input.focus();
      utils.analytics.interaction('menu', 'nav', utils.i18n('signin_flyout_toggle'));
    });
  };

  handleCollapse = () => {
    this.setState({ expanded: false }, () => {
      const btn = this.buttonRef.current;
      btn && btn.focus();
      utils.analytics.interaction('menu', 'nav', utils.i18n('signin_flyout_toggle'));
    });
  };

  handleClickOutside = () => {
    this.setState({ expanded: false });
  };

  handleKeyDown = e => {
    const key = utils.accessibility.formatKeys(e);
    // close the flyout on "Escape"
    key.escape && this.handleCollapse();
  };

  onSuccessfulSignIn = () => {
    const { disableRedirectOnSignIn } = this.props;
    !disableRedirectOnSignIn && utils.loadingRedirect(WINDOW_OBJECT_KEYS.AUTH_HOME_PAGE);
  };

  render() {
    const { breakpoint, isFetching, disableJoin } = this.props;
    const { expanded } = this.state;
    const enrollPageLink = utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.ENROLL_PAGE);
    const utilitySectionCount = document.getElementById('navigation-support').childElementCount === 0;
    const currentLanguage = utils.config.getLanguage();

    return (
      <div
        className={cn('header-flyout header-flyout--unauthenticated', {
          disabled: isFetching,
          'header-flyout-before': utilitySectionCount,
        })}
        ref={this.clickOutsideRef}>
        <div className='header-flyout__top'>
          <div className='header-flyout__top__text'>
            <div className='header-flyout__top__links'>
              <Button
                link
                className={cn('header-flyout__top__toggle navItem', { expanded })}
                onClick={this.handleSigninClick}
                buttonRef={this.buttonRef}
                disabled={isFetching}
                ariaExpanded={expanded.toString()}>
                {utils.i18n('signin_flyout_toggle')}
              </Button>
              {!breakpoint.isMobile && !disableJoin && (
                <>
                  <span className='header-flyout__top__separator'>{utils.i18n('signin_flyout_or')}</span>
                  <Anchor
                    className='link header-sign-in-button button--decline'
                    href={enrollPageLink}
                    onClick={() => utils.analytics.interaction('menu', 'nav', utils.i18n('signin_flyout_join'))}
                    disabled={isFetching}
                    data-dtm-track='alamo_insiders|sign_in|join_now'>
                    {utils.i18n('signin_flyout_join')}
                  </Anchor>
                </>
              )}
            </div>
          </div>
          {/* {breakpoint.isDesktop && !!insidersLogoSrc && (
            <img
              className='header-flyout__top__logo'
              src={insidersLogoSrc}
              alt={utils.i18n('alamo_insiders_logo_alt')}
            />
          )} */}
        </div>

        {breakpoint.isDesktop && expanded && (
          <div className='header-flyout__shadow-clipper'>
            <div
              className={`header-flyout__content component-theme--light content_${currentLanguage}`}
              id='login-dialog'
              role='dialog'
              aria-modal='true'
              aria-labelledby='login-dialog-label'
              onKeyDown={this.handleKeyDown}>
              <TabindexLoop>
                <h3 className='header-flyout__content__heading' id='login-dialog-label'>
                  {utils.i18n('signin_flyout_alamo_insiders')}
                </h3>
                <p className='header-flyout__content__join-text'>
                  {utils.i18n('signin_flyout_not_member_yet')}{' '}
                  <Anchor data-dtm-track='alamo_insiders|sign_in|join_now' href={enrollPageLink}>
                    {utils.i18n('signin_flyout_join_now')}
                  </Anchor>
                </p>
                <SignIn onSuccess={this.onSuccessfulSignIn} unAuthenticatedFlyout />
              </TabindexLoop>
            </div>
          </div>
        )}
      </div>
    );
  }
}

UnauthenticatedFlyout.propTypes = {
  breakpoint: PropTypes.object,
  isFetching: PropTypes.bool,
  loginRememberMe: PropTypes.func,
  isRememberMeEligible: PropTypes.bool,
  insidersLogoSrc: PropTypes.string,
  disableJoin: PropTypes.bool,
  disableRedirectOnSignIn: PropTypes.bool,
};

export default utils.withClickOutside(UnauthenticatedFlyout);
