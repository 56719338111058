import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import COUNTRY_CODE from 'constants/countryCode';
import { breakpointSelector } from 'selectors/breakpoint';
import { getPickupTime, getReturnTime } from 'selectors/progressBarSelectors';
import { getInitiatePickupTime, getInitiateReturnTime, getModifyFlag } from 'selectors/reservationSelectors';
import { getReservationInitiateError } from 'selectors/errorSelectors';
import { isDomainCodeLanguageCodeEqual } from 'utils/locations';
import { useSelector } from 'react-redux';

const ProgressBarDatesTimes = ({ preview, mobile }) => {
  const pickupTime = useSelector(getPickupTime);
  const returnTime = useSelector(getReturnTime);
  const pickupTimeInitiateRequest = useSelector(getInitiatePickupTime);
  const returnTimeInitiateRequest = useSelector(getInitiateReturnTime);
  const isTrueModify = useSelector(getModifyFlag);
  const reservationInitiateError = useSelector(getReservationInitiateError);

  const { isTablet } = useSelector(breakpointSelector);
  const isFrDomainWithFrLanguage = isDomainCodeLanguageCodeEqual(COUNTRY_CODE.FR, COUNTRY_CODE.FR.toLowerCase());

  const dateTimeFormat =
    preview || isTablet
      ? utils.config.getDateTimeShortFormat('ll_no_year')
      : utils.config.getDateTimeShortFormat('lll_no_year');

  const getDateTime = (dateTime) => utils.gmi.getDateTimeObjFromTs(dateTime).format(dateTimeFormat);
  const isPickupTimeChanged =
    isTrueModify && reservationInitiateError.length > 0 && pickupTime !== pickupTimeInitiateRequest;
  const isReturnTimeChanged =
    isTrueModify && reservationInitiateError.length > 0 && returnTime !== returnTimeInitiateRequest;

  let newPickupTimeInitiateRequest = getDateTime(pickupTimeInitiateRequest);
  let newPickupTime = getDateTime(pickupTime);
  let newReturnTimeInitiateRequest = getDateTime(returnTimeInitiateRequest);
  let newReturnTime = getDateTime(returnTime);

  if (isFrDomainWithFrLanguage) {
    newPickupTimeInitiateRequest = getDateTime(pickupTimeInitiateRequest).toLowerCase();
    newPickupTime = getDateTime(pickupTime).toLowerCase();
    newReturnTimeInitiateRequest = getDateTime(returnTimeInitiateRequest).toLowerCase();
    newReturnTime = getDateTime(returnTime).toLowerCase();
  }

  return (
    <span className='section__values dates-times'>
      {pickupTime && (
        <>
          {mobile && <span className='section__value-label'>{utils.i18n('rental_details_location_pickup')}</span>}
          <span className='section__value pickup-time'>
            {isPickupTimeChanged ? newPickupTimeInitiateRequest : newPickupTime}
          </span>
        </>
      )}
      {returnTime && (
        <>
          {mobile && <span className='section__value-label'>{utils.i18n('rental_details_location_return')}</span>}
          <span className='section__value return-time'>
            {isReturnTimeChanged ? newReturnTimeInitiateRequest : newReturnTime}
          </span>
        </>
      )}
    </span>
  );
};

ProgressBarDatesTimes.propTypes = {
  preview: PropTypes.bool,
  mobile: PropTypes.bool,
  breakpoint: PropTypes.object,
};

export default ProgressBarDatesTimes;
