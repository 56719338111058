import { GoogleMapsPropTypes, setDefaultProps } from 'data/sharedProps';

export const propTypes = GoogleMapsPropTypes;

export const defaultProps = setDefaultProps({
  mapContainerClassName: 'google-map__container',
  center: { lat: 41.850033, lng: -87.6500523 },
  zoom: 9,
  minZoom: 1,
  maxZoom: 20,
});
