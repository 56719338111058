import React from 'react';
import { i18n } from 'utils/i18n';
import { config } from 'utils/config';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import AdditionalDriversConfirmation from 'components/Confirmation/ReservationDetails/AdditionalDriversConfirmation';

const RenterInfoSection = ({
  fullName,
  memberNumber,
  maskedEmail,
  maskedPhoneNumber,
  flightInfo,
  partner_reward_program,
  contractDetails,
  displayFlightDelayLink,
  handleFlightModal,
  isCancelled,
  isFoundRental,
}) => (
  <div className='confirmation__reservation-details__content__renter-info-section'>
    <p className='rental-details__section-label'>{i18n('confirmation_reservation_renter_info_label')}</p>
    <p className='confirmation__reservation-details__content__renter-info-section__value'>{fullName}</p>
    {memberNumber && (
      <p className='confirmation__reservation-details__content__renter-info-section__value'>
        {i18n('confirmation_reservation_alamo_insider_number', [memberNumber])}
      </p>
    )}
    {maskedEmail && (
      <p className='confirmation__reservation-details__content__renter-info-section__value'>
        {i18n('confirmation_reservation_email', [maskedEmail])}
      </p>
    )}
    {maskedPhoneNumber && (
      <p className='confirmation__reservation-details__content__renter-info-section__value'>
        {i18n('confirmation_reservation_phone', [maskedPhoneNumber])}
      </p>
    )}
    <AdditionalDriversConfirmation isFoundRental={isFoundRental} isCancelled={isCancelled} />

    {flightInfo && (
      <div className='confirmation__reservation-details__content__renter-info-section__flight-info'>
        <p className='rental-details__section-label rental-details__section-label-flight'>
          {i18n('confirmation_reservation_flight_label')}
          {config?.getIsEnableFlightDelay?.() && displayFlightDelayLink && (
            <Button key={0} className='rental-details__flight-delayed' onClick={handleFlightModal} link>
              {i18n('flight_delayed_changed_title')}
            </Button>
          )}
        </p>
        <p className='confirmation__reservation-details__content__renter-info-section__value'>{flightInfo}</p>
      </div>
    )}

    {partner_reward_program && (
      <div className='confirmation__reservation-details__content__renter-info-section__flight-info'>
        <p className='rental-details__section-label'>{i18n('confirmation_partner_rewards_label')}</p>
        <p className='confirmation__reservation-details__content__renter-info-section__value'>
          {partner_reward_program.program_name}
        </p>
        <p className='confirmation__reservation-details__content__renter-info-section__value'>
          {i18n('confirmation_partner_rewards_member_number', [partner_reward_program.member_id])}
        </p>
      </div>
    )}

    {contractDetails && !partner_reward_program && (
      <div className='confirmation__reservation-details__content__renter-info-section__cid'>
        <p className='rental-details__section-label'>{i18n('confirmation_reservation_contract_cid')}</p>
        <p className='confirmation__reservation-details__content__renter-info-section__value'>
          {contractDetails.contract_name}
        </p>
      </div>
    )}
  </div>
);

export default RenterInfoSection;

RenterInfoSection.propTypes = {
  fullName: PropTypes.string,
  memberNumber: PropTypes.string,
  maskedEmail: PropTypes.string,
  maskedPhoneNumber: PropTypes.string,
  partner_reward_program: PropTypes.shape({
    code: PropTypes.string,
    member_id: PropTypes.string,
    program_name: PropTypes.string,
  }),
  flightInfo: PropTypes.string,
};
