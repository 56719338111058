import React from 'react';
import cn from 'classnames';
import utils from 'utils';
import { WINDOW_OBJECT_KEYS, ANALYTICS } from 'constants';
import propTypes from './StoreFinderNoResultsPropTypes';

const StoreFinderNoResults = ({
  isUserDraggedOrZoom,
  isReservationFlow,
  setMobileExpanded,
  setExpandedTab,
  isProgressBarExpanded,
  breakpoint,
}) => {
  // Expanding the location progress bar by default if there are no locations found and user click on search for different location
  const handleSearchForDifferentLocation = () => {
    if (!isProgressBarExpanded) {
      const { isMobile } = breakpoint;
      setExpandedTab('location');
      isMobile && setMobileExpanded(isMobile);
    }
  };

  return (
    <div className='store-finder-no-results__container'>
      <h3 className='store-finder-no-results__main-title'>{utils.i18n('store_finder_main_title')}</h3>

      {!isUserDraggedOrZoom && (
        <p className='store-finder-no-results__sub-title-text'>
          {utils.i18n(isReservationFlow ? 'resflow_no_results_try_searching_message' : 'store_finder_sub_title_text')}
        </p>
      )}

      {!isReservationFlow && !isUserDraggedOrZoom && (
        <div className='store-finder-no-results__divider-container'>
          <span className='store-finder-no-results__divider'>{utils.i18n('store_finder_divider_text')}</span>
        </div>
      )}

      <div className='store-finder-no-results__actions'>
        {!isReservationFlow ? (
          <>
            <a
              data-dtm-track={utils.analytics.dtm(
                ANALYTICS.UI_BUTTON,
                ANALYTICS.NO_LOCATIONS,
                'explore_other_locations'
              )}
              className={cn('link link--arrow', { 'store-finder-no-results__orphan-link': isUserDraggedOrZoom })}
              href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.LOCATIONS_LANDING_PAGE)}>
              {utils.i18n('store_finder_link_main')}
            </a>

            {!isUserDraggedOrZoom && (
              <a
                href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)}
                className='link link--arrow store-finder-no-results__contact-support-link'>
                {utils.i18n('store_finder_link_sub_text')}
              </a>
            )}
          </>
        ) : (
          <>
            {!isUserDraggedOrZoom && (
              <a
                data-dtm-track={utils.analytics.dtm(
                  ANALYTICS.UI_BUTTON,
                  ANALYTICS.NO_LOCATIONS,
                  'search_different_locations'
                )}
                onClick={handleSearchForDifferentLocation}
                className='link link--arrow'>
                {utils.i18n('store_finder_search_different_location_link')}
              </a>
            )}

            <a
              data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.NO_LOCATIONS, 'call_customer_support')}
              href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)}
              className='link link--arrow store-finder-no-results__contact-support-link'>
              {utils.i18n('store_finder_link_sub_text')}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

StoreFinderNoResults.propTypes = propTypes;

export default StoreFinderNoResults;
