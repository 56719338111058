import { createSelector } from 'reselect';
import { CURSORS } from 'constants';

const getPickupTime = state => state.getIn(['gmi', 'ui', 'reservations', 'initiate', 'pickup_time']);
const getPickupLocationHours = (state, props) => state.getIn(CURSORS.locationHours(props.pickup_location_id), false);
const getReturnTime = state => state.getIn(['gmi', 'ui', 'reservations', 'initiate', 'return_time']);
const getReturnLocationHours = (state, props) => state.getIn(CURSORS.locationHours(props.return_location_id), false);

export const locationDateTimeSelectors = createSelector(
  [getPickupTime, getPickupLocationHours, getReturnTime, getReturnLocationHours],
  (pickup_time, pickupEligibility, return_time, returnEligibility) => {
    const pickupTimeParts = pickup_time ? pickup_time.split('T') : [''];
    const pickupDate = pickupTimeParts[0];
    const pickupTime = pickupTimeParts[1];

    const returnTimeParts = return_time ? return_time.split('T') : [''];
    const returnDate = returnTimeParts[0];
    const returnTime = returnTimeParts[1];

    return {
      pickupDate,
      pickupEligibility: pickupEligibility && pickupEligibility.toJS(),
      pickupTime,
      returnDate,
      returnEligibility: returnEligibility && returnEligibility.toJS(),
      returnTime,
    };
  }
);
