import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Anchor from 'components/Anchor';
import LoadingWidget from 'components/LoadingWidget';
import utils from 'utils';
import { MODAL, MODAL_THEMES } from 'constants';

/**
 * LearnMoreAboutRoadsideAssistanceModal - This Modal will open when the user clicks on roadside assitance
 * and will have the number for that country. A loading screen may appear with the request for the number is
 * being made.
 * @param {object} props - React Props
 * @param {function} props.getSupportContactInfo -gmi service call fetch support contact data from GBO
 * @param {function} props.roadsideAssistancePhoneNumber - gets phone number based on domain_country
 * @return {JSX} LearnMoreAboutRoadsideAssistanceModal component
 */

const LearnMoreAboutRoadsideAssistanceModal = ({ getSupportContactInfo, roadsideAssistancePhoneNumber }) => {
  useEffect(() => {
    if (!roadsideAssistancePhoneNumber) {
      getSupportContactInfo(utils.config.getDomainCountry());
    }
  }, []);
  return (
    <Modal
      modalKey={MODAL.LEARN_MORE_ABOUT_ROADSIDE_ASSISTANCE_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('learn_more_about_roadside_assistance_modal_title')}>
      <>
        {!!roadsideAssistancePhoneNumber && (
          <p className='modal-themed__paragraph'>
            {utils.i18n(
              'learn_more_about_roadside_assistance_modal_description',
              {
                0: (
                  <Anchor
                    className='link link--text'
                    key={0}
                    href={`tel:${roadsideAssistancePhoneNumber}`}
                    aria-label={roadsideAssistancePhoneNumber}>
                    {roadsideAssistancePhoneNumber}
                  </Anchor>
                ),
              },
              {
                jsx: true,
              }
            )}
          </p>
        )}
        {!roadsideAssistancePhoneNumber && <LoadingWidget />}
      </>
    </Modal>
  );
};

LearnMoreAboutRoadsideAssistanceModal.propTypes = {
  getSupportContactInfo: PropTypes.func,
  roadsideAssistancePhoneNumber: PropTypes.string,
};

export default LearnMoreAboutRoadsideAssistanceModal;
