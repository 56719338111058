import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'components/Button';
import TabsPanel from 'components/Tabs/TabsPanel';
import './styles/tabs.scss';

const Tabs = ({ activeTab, setActiveTab, tabsArray, tabsRightContent }) => {
  const handleActive = (tabIndex) => () => {
    setActiveTab(tabIndex);
  };

  const handleNextTab = (firstTabInRound, nextTab, lastTabInRound) => {
    const tabToSelect = activeTab === lastTabInRound ? firstTabInRound : nextTab;
    setActiveTab(tabToSelect);
    tabsArray[tabToSelect].ref.current.focus();
  };

  const handleKeyPress = (event) => {
    const tabCount = Object.keys(tabsArray).length - 1;
    if (event.key === 'ArrowLeft') {
      const last = tabCount;
      const next = activeTab - 1;
      handleNextTab(last, next, 0);
    }
    if (event.key === 'ArrowRight') {
      const first = 0;
      const next = activeTab + 1;
      handleNextTab(first, next, tabCount);
    }
  };

  return (
    <section>
      <div className='row'>
        <div className='tab-list__tabs'>
          <ul role='tablist' aria-orientation='horizontal' className='tab-list' onKeyDown={handleKeyPress}>
            {tabsArray.map((tab, index) => (
              <li role='presentation' key={index}>
                <Button
                  plain
                  role='tab'
                  id={`tab_${index}`}
                  className={cn('tab-list__tab-button', { 'tab-list__tab-button--active': activeTab === index })}
                  aria-controls={`tabpanel_${index}`}
                  aria-selected={activeTab === index}
                  tabIndex={activeTab === index ? 0 : -1}
                  buttonRef={tab.ref}
                  onClick={handleActive(index)}
                  onKeyUp={handleActive(index)}
                  data-dtm-track={tab.dtmAttribute}
                >
                  {tab.buttonLabel}
                </Button>
              </li>
            ))}
          </ul>
          {tabsRightContent && tabsRightContent}
        </div>
      </div>
      <div className='tab-list__tab-content theme--light-blue'>
        <TabsPanel activeTab={activeTab} tabsArray={tabsArray} />
      </div>
    </section>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  tabsArray: PropTypes.array,
  tabsRightContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default Tabs;
