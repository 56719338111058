import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const RemoveProductCodeModal = () => (
  <Modal
    modalKey={MODAL.REMOVE_PRODUCT_CODE_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('coupon_remove_modal_header')}>
    {({ productCode, onConfirm }) => (
      <>
        <p className='modal-themed__copy'>{utils.i18n('coupon_remove_modal_copy', [productCode])}</p>

        <ModalFooter
          fullWidthButton
          buttons={[
            {
              type: MODAL_BUTTON_TYPES.DECLINE,
              label: utils.i18n('progress_bar_cid_remove_modal_decline'),
              analyticsValue: "No, Don't Remove",
            },
            {
              type: MODAL_BUTTON_TYPES.ACCEPT,
              label: utils.i18n('progress_bar_cid_remove_modal_accept'),
              handler: onConfirm,
            },
          ]}
        />
      </>
    )}
  </Modal>
);

export default RemoveProductCodeModal;
