export const VIEW_MODIFY_CANCEL_VIEWS = {
  UPCOMING: 'upcoming',
  LOOKUP_BY: {
    RENTAL_BY_PHONE: 'rental_by_phone',
    RENTAL_BY_EMAIL: 'rental_by_email',
    RENTAL_BY_CONF_NUMBER: 'rental_by_conf_number',
    VERIFY: 'verify',
  },
  PHONE_CONFIRMATION: 'phone_confirmation',
  EMAIL_CONFIRMATION: 'email_confirmation',
  VERIFY: 'verify',
  MY_RENTALS: 'my_rentals',
  CONFIRMED: 'confirmed',
};

export const LOOK_UP_BY_TYPE = {
  PHONE: 'phone',
  EMAIL: 'email',
  CONF_NUMBER: 'conf_number',
};

export const VERIFY_DELIVERY_OPTIONS = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
};
