import React from 'react';
import { i18n } from 'utils/i18n';
import PropTypes from 'prop-types';
import Sort from 'components/SortFilterWrapper/Sort/Sort';

const SMSConsent = ({ smsOptInUpdate, name, privacyPolicyLink, smsTermsLink }) => {
  const handleSMSOptInUpdate = (value) => {
    smsOptInUpdate(value);
  };

  return (
    <>
      <Sort
        isRequired={false}
        hideTheme={true}
        updateSort={handleSMSOptInUpdate}
        customTitle={i18n('sms_opt_in_title')}
        name={name}
        buttons={[
          {
            label: i18n('yes_opt_in_for_sms'),
            value: 'true',
          },
          {
            label: i18n('do_not_opt_in_for_sms'),
            value: 'false',
          },
        ]}
      />
      <p className='review__section-sms__opt-in-context'>
        {i18n('sms_opt_in_consent', [privacyPolicyLink, smsTermsLink], { jsx: true })}
      </p>
    </>
  );
};

export default SMSConsent;

// proptypes
SMSConsent.propTypes = {
  smsOptInUpdate: PropTypes.func,
  name: PropTypes.string,
  privacyPolicyLink: PropTypes.object,
  smsTermsLink: PropTypes.object,
};
