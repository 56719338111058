import React from 'react';
import PropTypes from 'prop-types';
import { actions } from '@ehi/global-marketing-interface';
import useActions from 'hooks/useActions';
import utils from 'utils';
import MODAL, { MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants/modals';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import Anchor from 'components/Anchor';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';

const { ACCEPT } = MODAL_BUTTON_TYPES;

/**
 * DoNotRentModal
 * Modal that will pop up if GMA returns a Do Not Rent Profile error.
 *
 * @return {JSX} ...
 */
const DoNotRentModal = ({ onModalClose }) => {
  const supportPhone = useSupportPhoneNumber();
  // If there is no callback provided, clear the error message on modal close
  const cleanMessages = useActions(actions.cleanMessages);

  return (
    <Modal
      modalKey={MODAL.DO_NOT_RENT_MODAL}
      header={utils.i18n('do_not_rent_oci_modal_header')}
      onClose={onModalClose || cleanMessages}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose }) => (
        <>
          <p className='modal-themed__paragraph'>
            {utils.i18n(
              'do_not_rent_oci_modal_copy',
              {
                0: (
                  <Anchor
                    className='link link--text'
                    key='dnr_support_phone_0'
                    isExternal
                    hideExternalIcon
                    href={`tel:${supportPhone}`}>
                    {supportPhone}
                  </Anchor>
                ),
              },
              { jsx: true }
            )}
          </p>

          <ModalFooter
            buttons={[
              {
                type: ACCEPT,
                label: utils.i18n('common_close'),
                handler: handleClose,
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

DoNotRentModal.propTypes = {
  onModalClose: PropTypes.func,
};

export default DoNotRentModal;
