import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { inPathAdditionalDriversSelector } from 'selectors/reservationFlowSelectors';
import useActions from 'hooks/useActions';
import { MODAL } from 'constants';
import LearnMoreAboutAdditionalDriverModal from 'components/modals/AdditionalDriverModals/LearnMoreAboutAdditionalDriverModal';
import Button from 'components/Button';
import AdditionalDriverOciRequestModal from 'components/modals/AdditionalDriverModals/AdditionalDriverOciRequestModal';
import { openModal } from "actions/modal/openModal";
import AdditionalDriversListItem from './AdditionalDriversListItem';

const AdditionalDriversConfirmation = ({ isFoundRental, isCancelled }) => {
  const openLearnMore = useActions(() => openModal(MODAL.LEARN_MORE_ABOUT_ADDITIONAL_DRIVER));
  const driversList = useSelector(inPathAdditionalDriversSelector);

  if (!driversList || driversList.length === 0) {
    return null;
  }

  return (
    <div className='additional-driver-confirmation'>
      <p className='rental-details__section-label'>
        {utils.i18n('review_additional_drivers_count', [driversList.length])}
      </p>
      <p className='confirmation__reservation-details__content__renter-info-section__value'>
        {utils.i18n('additional_driver_true_modify_description')}
        <Button key={0} className='additional-drivers__learn-more' onClick={openLearnMore} link>
          {utils.i18n('common_learn_more')}
        </Button>
      </p>

      {driversList.map((driver, idx) => (
        <AdditionalDriversListItem key={idx} driver={driver} isCancelled={isCancelled} />
      ))}

      <LearnMoreAboutAdditionalDriverModal />
      <AdditionalDriverOciRequestModal isFoundRental={isFoundRental} />
    </div>
  );
};

export default AdditionalDriversConfirmation;
