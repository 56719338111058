import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import { MODAL_BUTTON_TYPES, RESERVATIONS } from 'constants';
import Button from 'components/Button';
import Anchor from 'components/Anchor';

const buttonTypeClasses = {
  [MODAL_BUTTON_TYPES.ACCEPT]: 'modal-themed__footer__button--accept',
  [MODAL_BUTTON_TYPES.LINK]: 'modal-themed__footer__button--accept',
  [MODAL_BUTTON_TYPES.EXTERNAL_LINK]: 'modal-themed__footer__button--accept link--external',
  [MODAL_BUTTON_TYPES.DECLINE]: 'modal-themed__footer__button--decline button--inverse',
  [MODAL_BUTTON_TYPES.SKIP]: 'modal-themed__footer__button--skip button--inverse',
};

const resflowPaths = Object.values(RESERVATIONS.RESFLOW_PATHS_CONFIG).reduce((acc, paths) => acc.concat(paths), []);

/**
 * BaseModalFooter - base class for ModalFooter, can be wrapped in Router on export
 *
 * @param {object}    props - React Props
 * @param {object[]}  props.buttons - array of button objects, see propTypes button object shape
 * @param {string}    props.buttons[].type - one of MODAL_BUTTON_TYPES constants
 * @param {string}    props.buttons[].label - button text content, serves as key
 * @param {function}  props.buttons[].handler - custom handler
 * @param {function}  props.buttons[].analyticsHandler - custom analytics handler
 * @param {string}    props.buttons[].linkTo - In case of a LINK type button, requires a path to link to
 * @param {boolean}   props.buttons[].external - This property can be used to force an external link to open in the same tab (default: `true`)
 * @param {string}    props.buttons[].key - In case there is a need for a unique key if for some reason labels are the same
 * @param {boolean}   props.buttons[].largeButton - flag to add large button class to this specific button
 * @param {boolean}   props.buttons[].extraLargeButton - flag to add extra large button class to this specific button
 * @param {string}    props.buttons[].customClass - custom class name for the button
 *
 * @param {boolean}   props.fullWidthButton - flag to add class to
 * @param {string}    props.parentLayoutState - Array of state that controls layout of parent Modal content, used to recalculate footer position on layout change
 * @param {function}  props.closeModal - dispatch method for closing currently opened modal
 * @param {function}  props.clearModalQueue - dispatch method for clearing all modals in modal queue
 * @param {object}    props.history - passed in when wrapped by withRouter to control Router state
 * @param {boolean}   props.disableStickyFooter - manually disable sticky footer functionality
 *
 * @return {JSX} BaseModalFooter jsx component
 */

class BaseModalFooter extends Component {
  state = { stickyFooter: false, vehicleCategoryLoaded: this.props.vehicleCategoryLoaded };

  modalFooter = createRef();

  buttonRef = createRef();

  invisibleFooter = createRef();

  componentDidMount() {
    this.calculateFooterPosition();
    this.props?.sessionTimeoutButton && this.buttonRef?.current?.focus();
  }

  componentDidUpdate(prevProps) {
    const { parentLayoutState } = this.props;

    if (parentLayoutState?.length) {
      parentLayoutState.find((layoutState, index) => {
        const layoutStateChanged = layoutState !== prevProps.parentLayoutState[index];
        if (layoutStateChanged) {
          this.calculateFooterPosition();
        }
        return layoutStateChanged;
      });
    }
    if (this.props.isVehicleClassFilter && prevProps.vehicleCategoryLoaded !== this.props.vehicleCategoryLoaded) {
      this.calculateFooterPosition();
    }
  }

  calculateFooterPosition = () => {
    if (this.props.disableStickyFooter) {
      return;
    }
    const { stickyFooter } = this.state;
    const { top } = this.modalFooter.current.getBoundingClientRect();
    if (top >= window.innerHeight && !stickyFooter) {
      this.setState({ stickyFooter: true });
    }
    if (stickyFooter && this.invisibleFooter?.current.getBoundingClientRect().top < window.innerHeight) {
      this.setState({ stickyFooter: false });
    }
  };

  handleClick =
    (buttonProps) =>
    (...args) => {
      const { closeModal, clearModalQueue, history } = this.props;
      const { analyticsHandler, analyticsKey, analyticsValue, clearQueue, handler, linkTo, type } = buttonProps;

      // Analytics
      analyticsHandler?.();

      if (handler) {
        handler(...args);
        if (clearQueue) {
          clearModalQueue({ skipAnalytics: true });
        }
        return;
      }

      switch (type) {
        case MODAL_BUTTON_TYPES.DECLINE:
          clearQueue === false
            ? closeModal({ analyticsKey, analyticsValue })
            : clearModalQueue({ analyticsKey, analyticsValue });
          break;

        case MODAL_BUTTON_TYPES.ACCEPT:
          closeModal({ analyticsKey, analyticsValue });
          break;

        case MODAL_BUTTON_TYPES.LINK:
          if (history && resflowPaths.indexOf(linkTo) > -1) {
            history.push(linkTo);
          } else {
            window.location.assign(linkTo);
          }
          clearModalQueue({ analyticsKey, analyticsValue });
          break;

        default:
      }
    };

  renderButton = (buttonProps) => {
    const { key, label, type, largeButton, extraLargeButton, linkTo, external = true, customClass } = buttonProps;

    return type === MODAL_BUTTON_TYPES.EXTERNAL_LINK ? (
      <Anchor
        key={key || label}
        onClick={this.handleClick(buttonProps)}
        href={linkTo}
        {...(external && { target: '_blank', rel: 'noopener noreferrer' })}
        className={cn(
          buttonTypeClasses[type],
          'button',
          { 'modal-themed__footer__button--large': largeButton },
          { 'modal-themed__footer__button--extra-large': extraLargeButton },
          customClass
        )}
      >
        {label}
      </Anchor>
    ) : (
      <Button
        key={key || label}
        onClick={this.handleClick(buttonProps)}
        buttonRef={this.buttonRef}
        className={cn(
          buttonTypeClasses[type],
          { 'modal-themed__footer__button--large': largeButton },
          { 'modal-themed__footer__button--extra-large': extraLargeButton },
          customClass
        )}
      >
        {label}
      </Button>
    );
  };

  render() {
    const { buttons, fullWidthButton, sessionTimeoutButton, stickyFooterCTA } = this.props;
    const { stickyFooter } = this.state;
    return (
      <>
        <div
          className={cn('modal-themed__footer', {
            'modal-themed__footer--sticky': stickyFooter || stickyFooterCTA,
            'modal-themed__footer--full-width-buttons': fullWidthButton,
            'modal-themed__footer--session-timeout-button': sessionTimeoutButton,
          })}
          ref={this.modalFooter}
        >
          {buttons.map((buttonProps) => this.renderButton(buttonProps))}
        </div>
        {stickyFooter && <div className='modal-themed__footer--hidden' ref={this.invisibleFooter} aria-hidden={true} />}
      </>
    );
  }
}

BaseModalFooter.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(MODAL_BUTTON_TYPES)).isRequired,
      label: PropTypes.string.isRequired,
      handler: PropTypes.func,
      analyticsHandler: PropTypes.func,
      linkTo: PropTypes.string,
      key: PropTypes.string,
      customClass: PropTypes.string,
      largeButton: PropTypes.bool,
      extraLargeButton: PropTypes.bool,
      external: PropTypes.bool,
    })
  ).isRequired,
  fullWidthButton: PropTypes.bool,
  parentLayoutState: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
  clearModalQueue: PropTypes.func.isRequired,
  history: PropTypes.object,
  disableStickyFooter: PropTypes.bool,
  vehicleCategoryLoaded: PropTypes.bool,
  isVehicleClassFilter: PropTypes.bool,
  stickyFooterCTA: PropTypes.bool,
};

// import this version when your modal does not rely on Router
// for example, the Accept button links to an entire new page, not a route within an SPA
export const ModalFooter = BaseModalFooter;

// only import the "withRouter" version when your modal is in a view with a Router parent (ReservationFlow for example)
// withRouter will error if there is no Router parent
export const ModalFooterWithRouter = withRouter(BaseModalFooter);
