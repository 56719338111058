import React from 'react';
import Modal from 'components/Modal';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import utils from 'utils';
import ReturnDirectionsModalContent from './ReturnDirectionsModalContent';

/**
 * ReturnDirectionsModal - wrapper for ReturnDirectionsModalContent
 *
 * @return {JSX}
 */

const ReturnDirectionsModal = () => (
  <Modal
    modalKey={MODAL.RETURN_DIRECTIONS_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('return_directions_modal_title')}>
    <ReturnDirectionsModalContent />
  </Modal>
);

export default ReturnDirectionsModal;
