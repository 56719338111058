import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import utils from 'utils';
import { profileDataSelector } from 'selectors/profileSelectors';
import {
  onlineCheckInProfileSelector,
  isEditableCheckInProfileSelector,
  onlineCheckInStatusSelector,
} from 'selectors/checkinSelectors';
import useSupportPhoneNumber from 'hooks/useSupportPhoneNumber';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import Anchor from 'components/Anchor';
import ToolTip from 'components/ToolTip';
import Button from 'components/Button';
import DateFieldset from 'components/Form/DateFieldset';
import TextField from 'components/Form/TextField';
import SelectField from 'components/Form/SelectField';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * CheckInDriverLicenseForm
 * Form section for Driver License info during the check-in profile step
 *
 * @param {object} props - React Props
 * @param {function} props.onEditLicense - callback from parent to call when editing, allowing parent container
 *                                        to display custom container UX if necessary or trigger some kind of navigation
 *                                        (which varies according to the flow, such as In Res OCI modal vs Out of Res OCI)
 * @param {bool} props.hideEdit - boolean that hides the edit button, required for certain oci flows
 * @param {object} props.form - FinalForm instance - used to validate date fields
 *
 * @return {JSX} CheckInDriverLicenseForm jsx component
 */
const CheckInDriverLicenseForm = ({ onEditLicense, hideEdit, form, updateInitialState }) => {
  const profile = useSelector(onlineCheckInProfileSelector);
  const authProfile = useSelector((state) => profileDataSelector(state, { license_profile: true }));
  const isProfileEditable = useSelector(isEditableCheckInProfileSelector);
  const { driverProfileComplete } = useSelector(onlineCheckInStatusSelector);
  const alamoInsidersSupportNumber = useSupportPhoneNumber();

  const driversLicenseCountryCode = profile.license_profile.country_code || domainCountryCode;
  const { selectedCountry, setSelectedCountry, countryConfig, countries, statesOrProvinces } =
    useCountryFieldsConfig(driversLicenseCountryCode);

  const [isViewMode, setIsViewMode] = useState(driverProfileComplete);

  const { country_subdivision_code, license_number, license_expiration_date, license_issue_date, birth_date } =
    profile.license_profile;

  useEffect(() => {
    setIsViewMode(driverProfileComplete);
    // This is for forcing the form reset when clearProfile is called
    if (!driverProfileComplete && form) {
      form.reset();
    }
  }, [driverProfileComplete]);

  // Should prefill country_subdivision_code if it's not already set
  // check profile country_subdivision_code and form country_subdivision_code
  const shouldPrefillCountrySubdivision =
    form &&
    !isViewMode &&
    !driverProfileComplete &&
    !country_subdivision_code &&
    !form.getState().values?.license_info?.country_subdivision_code &&
    selectedCountry &&
    statesOrProvinces[selectedCountry.country_code];

  const pageStatus = document.getElementById('pageStatus');

  // This is for updating the form values with default country_subdivision_code if the user doesn't change the SubdivisionField
  useEffect(() => {
    if (shouldPrefillCountrySubdivision) {
      form.change(
        'license_info.country_subdivision_code',
        statesOrProvinces[selectedCountry.country_code][0]?.country_subdivision_code
      );
    }

    // To ensure assistive technology users or to notify that the page has been updated, within Reservation flow
    pageStatus.setAttribute('aria-live', 'polite');
    pageStatus.innerText = utils.i18n('check_in_driver_lookup_form_title');

    setTimeout(() => {
      pageStatus.innerText = '';
      pageStatus.setAttribute('aria-live', 'off');
    }, 15000);
  }, [form, statesOrProvinces[selectedCountry?.country_code], isViewMode]);

  const {
    shouldShowIssueDate,
    shouldShowExpirationDate,
    shouldShowBothIssueAndExpirationDate,
    shouldShowSubdivisionField,
    shouldShowIssuingAuthorityField,
    issuingAuthorities,
  } = countryConfig;

  const authBirthDate = authProfile?.license_profile?.birth_date;

  const setCountry = (countryCode) => {
    const countryObject = utils.locations.findCountryCode(countries, countryCode);
    setSelectedCountry(countryObject);
  };

  const setSelectCountry = (event) => {
    setCountry(event?.target?.value);
  };

  const toggleViewMode = () => {
    if (driverProfileComplete) {
      // If the user had a complete profile in the first place, change to show the form...
      setIsViewMode(!isViewMode);
    }
    updateInitialState?.();
    onEditLicense?.();
  };

  return (
    <div className={cn('check-in-flow__form__section')}>
      <div className='check-in-flow__form__section-title'>
        <h4>
          {utils.i18n(
            !driverProfileComplete || isViewMode
              ? 'check_in_driver_lookup_form_title'
              : 'in_res_oci_update_drivers_license'
          )}
        </h4>
        {!hideEdit && isProfileEditable ? (
          <Button
            className='check-in-flow__form__edit-cta'
            onClick={toggleViewMode}
            data-dtm-track='check_in|edit|license'
            link
          >
            {!driverProfileComplete || isViewMode ? utils.i18n('common_edit') : utils.i18n('common_cancel')}
          </Button>
        ) : null}
      </div>

      {(!driverProfileComplete || isViewMode) && license_number ? (
        <>
          <p className='check-in-flow__form__info-row'>
            {utils.i18n('check_in_driver_license_issued_by')}: {selectedCountry?.country_name}
            {country_subdivision_code && !utils.fieldValidation.isMasked(country_subdivision_code)
              ? `, ${country_subdivision_code}`
              : ''}
          </p>
          <p className='check-in-flow__form__info-row'>
            {utils.i18n('my_profile_drivers_license_number')}: {license_number}
          </p>
        </>
      ) : (
        <>
          <div className='check-in-flow__form__field-row'>
            {!!countries?.length && (
              <SelectField
                id='license_info.country_code'
                name='license_info.country_code'
                label={utils.i18n('my_profile_drivers_license_issuing_country')}
                onChange={setSelectCountry}
                options={countries}
                valueKey='country_code'
                labelKey='country_name'
                required
                includeHiddenOption
              />
            )}
            {shouldShowSubdivisionField && !shouldShowIssuingAuthorityField && (
              <SelectField
                id='license_info.country_subdivision_code'
                name='license_info.country_subdivision_code'
                label={utils.i18n('my_profile_drivers_license_state_province')}
                options={statesOrProvinces[selectedCountry.country_code]}
                valueKey='country_subdivision_code'
                labelKey='country_subdivision_name'
                required
                includeHiddenOption
                maskedOption={country_subdivision_code}
              />
            )}
            {shouldShowIssuingAuthorityField && !!issuingAuthorities?.length && (
              <SelectField
                id='license_info.issuing_authority'
                name='license_info.issuing_authority'
                label={utils.i18n('my_profile_drivers_license_issuing_authority')}
                options={issuingAuthorities}
                valueKey='country_subdivision_code'
                labelKey='country_subdivision_name'
                required
                includeHiddenOption
              />
            )}
          </div>
          <div className='check-in-flow__form__field-row'>
            <TextField
              id='license_info.license_number'
              name='license_info.license_number'
              label={utils.i18n('my_profile_drivers_license_number')}
              fill
              required
              autoComplete='license_number'
              initialValueButton={license_number}
            />
          </div>
        </>
      )}

      {isViewMode ? (
        <>
          {license_expiration_date && (
            <p className='check-in-flow__form__info-row'>
              {utils.i18n('my_profile_drivers_license_expiration_date')}:{' '}
              {utils.gmi.getMaskedGBODate(license_expiration_date)}
            </p>
          )}
          {license_issue_date && (
            <p className='check-in-flow__form__info-row'>
              {utils.i18n('my_profile_drivers_license_issue_date')}: {utils.gmi.getMaskedGBODate(license_issue_date)}
            </p>
          )}
          {birth_date && (
            <p className='check-in-flow__form__info-row'>
              {utils.i18n('my_profile_drivers_license_birth_date')}: {utils.gmi.getMaskedGBODate(birth_date)}
            </p>
          )}
        </>
      ) : (
        <>
          {shouldShowBothIssueAndExpirationDate && (
            <div className='check-in-flow__date-info'>
              <h4 className='check-in-flow__date-info__title'>{utils.i18n('check_in_driver_license_drivers_date')}</h4>
              <p>{utils.i18n('check_in_driver_license_drivers_date_description')}</p>
            </div>
          )}
          {shouldShowExpirationDate && (
            <div
              className={cn('check-in-flow__form__date-row', {
                'check-in-flow__form__date-row-no-padding': shouldShowBothIssueAndExpirationDate,
              })}
            >
              <DateFieldset
                id='license_expiration_date'
                label={utils.i18n('my_profile_drivers_license_expiration_date')}
                countryCode={selectedCountry?.country_code}
                isRequired={!shouldShowBothIssueAndExpirationDate}
                initialValue={utils.gmi.parseGBODate(license_expiration_date)}
                validate={false}
                form={form}
              />
            </div>
          )}
          {shouldShowIssueDate && (
            <div className='check-in-flow__form__date-row'>
              <DateFieldset
                id='license_issue_date'
                label={utils.i18n('my_profile_drivers_license_issue_date')}
                countryCode={selectedCountry?.country_code}
                isRequired={!shouldShowBothIssueAndExpirationDate}
                initialValue={utils.gmi.parseGBODate(license_issue_date)}
                validate={false}
                form={form}
              />
            </div>
          )}
          {shouldShowBothIssueAndExpirationDate && utils.date.isExpirationAndIssueFormStateNotValid(form) && (
            <span className='check-in-flow__form__date-error-message'>
              {utils.i18n('my_profile_drivers_license_expiration_or_issue_date_required')}
            </span>
          )}
          {shouldShowBothIssueAndExpirationDate && <hr className='check-in-flow__divider' />}

          {authBirthDate ? (
            <div className='check-in-flow__form__field-row check-in-flow__form__date-field'>
              <div className='check-in-flow__form__date-field__label'>
                {utils.i18n('my_profile_drivers_license_date_of_birth')}
                {` (${utils.config.getDateTimeFormat('l')})`}
                <ToolTip placement='top-start'>
                  <span>
                    {utils.i18n(
                      'my_profile_drivers_license_dob_tooltip',
                      [
                        <Anchor
                          key='0'
                          className='link'
                          href={`tel:${alamoInsidersSupportNumber}`}
                          aria-label={alamoInsidersSupportNumber}
                        >
                          {alamoInsidersSupportNumber}
                        </Anchor>,
                      ],
                      { jsx: true }
                    )}
                  </span>
                </ToolTip>
              </div>
              <p>{utils.gmi.getMaskedGBODate(authBirthDate)}</p>
            </div>
          ) : (
            <div className='check-in-flow__form__date-row'>
              <DateFieldset
                id='birth_date'
                label={utils.i18n('my_profile_drivers_license_date_of_birth')}
                countryCode={selectedCountry?.country_code}
                initialValue={utils.gmi.parseGBODate(birth_date)}
                form={form}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

CheckInDriverLicenseForm.propTypes = {
  onEditLicense: PropTypes.func,
  hideEdit: PropTypes.bool,
  form: PropTypes.object,
};

export default CheckInDriverLicenseForm;
