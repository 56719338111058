import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useActions from 'hooks/useActions';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import { updateProfileInRes } from 'actions/checkin';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import useOCIProfileInitialValues from 'hooks/useOCIProfileInitialValues';
import ServiceErrors from 'components/ServiceErrors';
import Form from 'components/Form/Form';
import CheckInDriverLicenseForm from 'components/AcceleratedCheckInFlow/CheckInProfile/CheckInDriverLicenseForm';
import CheckInContactInformationForm from 'components/AcceleratedCheckInFlow/CheckInProfile/CheckInContactInformationForm';
import RequiredFieldsIndicator from 'components/RequiredFieldsIndicator';
import SignIn from 'components/SignIn';
import Button from 'components/Button';
import {
  isUnauthUserWithLoyaltyProfileSelector,
  onlineCheckInProfileSelector,
  onlineCheckInStatusSelector,
} from 'selectors/checkinSelectors';
import { getUiStateEmail, getUiStatePhone } from 'selectors/uiStateSelectors';
import { authenticatedSelector } from 'selectors/profileSelectors';
import { closeModal as closeModalAction } from "actions/modal/closeModal";

const domainCountryCode = utils.config.getDomainCountry();

/**
 * ProfileForm Component
 * In res OCI profile form rendering within In Res OCI wizard modal
 *
 * @param {object} props - React Props
 * @param {string} props.firstName - first name saved in InResAcceleratedCheckinWizardModal, used to set initialState
 * // TODO - remove firstName once available in online_check_in_profile
 * @param {func} props.setEditing - handler to notify parent modal that user is editing forms. Used to control footer content
 * @param {func} props.resetSteps - callback passed to CheckInDriverLicenseForm to reset wizard modal steps to edit Drivers License
 * @param {func} props.onSubmitSuccess - callback passed by In Res OCI wizard modal, that runs wizard modal functionality
 * @param {func} props.renderFooter - function used to render Modal footer, takes the onSubmit handler as first param
 * @param {func} props.scrollToModalTop - handler to scroll parent modal, used to show errors if they pop up
 * @param {func} props.setHasConfirmed - function to set state on InResOciWizardModal to validate if should abortOCI on handleModalClose
 * @param {boolean} props.isFoundProfileWithConflictName - if it's an unauth loyalty profile found with different first name
 *
 * @return {JSX} ProfileForm jsx component
 */
const ProfileForm = ({
  firstName,
  setEditing,
  resetSteps,
  onSubmitSuccess,
  renderFooter,
  scrollToModalTop,
  setHasConfirmed,
  isFoundProfileWithConflictName,
}) => {
  const closeModal = useActions(closeModalAction);
  const profile = useSelector(onlineCheckInProfileSelector);
  const { driverProfileComplete, contactProfileComplete } = useSelector(onlineCheckInStatusSelector);
  const isAuth = useSelector(authenticatedSelector);
  const isUnauthUserWithLoyaltyProfile = useSelector(isUnauthUserWithLoyaltyProfileSelector);
  const commitStateEmail = useSelector(getUiStateEmail);
  const commitStatePhone = useSelector(getUiStatePhone);
  const authProfileExists = isAuth && driverProfileComplete;

  const [editingLicense, setLicenseEditState] = useState(false);
  const [editingContactInfo, setContactInfoEditState] = useState(false);
  const [showSignin, setShowSignin] = useState(false);

  const toggleEditingLicense = () => setLicenseEditState(!editingLicense);
  const toggleEditingContactInfo = () => setContactInfoEditState(!editingContactInfo);
  const updateProfileAction = useActions(updateProfileInRes);

  const { first_name, last_name } = profile.basic_profile;
  const profileFirstName = first_name || firstName;
  const foundCompleteProfile = driverProfileComplete && contactProfileComplete;

  const driversLicenseCountryCode = profile.license_profile.country_code || domainCountryCode;
  const { countryConfig } = useCountryFieldsConfig(driversLicenseCountryCode);
  const { shouldShowBothIssueAndExpirationDate, shouldShowIssueDate, shouldShowExpirationDate } = countryConfig;

  const shouldValidateDateFields = !driverProfileComplete || editingLicense;

  useEffect(
    () => {
      setEditing?.(editingLicense || editingContactInfo);
    },
    [editingLicense, editingContactInfo]
  );

  const formSubmitCallback = formData =>
    updateProfileAction(formData, profileFirstName).then(resp =>
      resp.messages ? scrollToModalTop() : onSubmitSuccess()
    );

  const onSubmit = formData => {
    const payload = utils.checkin.parseProfileUpdatePayload(
      formData,
      !driverProfileComplete || editingLicense || authProfileExists,
      !driverProfileComplete || editingContactInfo || authProfileExists
    );
    formSubmitCallback(payload);
  };

  const onSuccessfulSignIn = handleSubmit => () => {
    utils.analytics.interaction(ANALYTICS.MODAL, 'check in renter information modal', ANALYTICS.SIGN_IN);
    setHasConfirmed?.(true);
    // Really edge case but if user found a partial profile and signed in with complete profile,
    // on sign in needs to close the modal
    foundCompleteProfile ? closeModal() : handleSubmit();
  };

  const initialValues = useOCIProfileInitialValues(profile, commitStateEmail, commitStatePhone);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values =>
        utils.date.validateIssueAndExpiration(
          values,
          shouldValidateDateFields && shouldShowBothIssueAndExpirationDate,
          shouldValidateDateFields && shouldShowIssueDate,
          shouldValidateDateFields && shouldShowExpirationDate
        )
      }
      subscription={{ submitFailed: true, values: true }}>
      {({ handleSubmit, form }) => (
        <>
          {(!driverProfileComplete || (editingContactInfo || editingLicense)) && <RequiredFieldsIndicator />}
          {!showSignin ? (
            <>
              <ServiceErrors />
              <form className='check-in-flow__form component-theme--light' noValidate onSubmit={handleSubmit}>
                {isFoundProfileWithConflictName && (
                  <>
                    <div className='check-in-flow__form__section-title'>
                      <h4>{utils.i18n('common_name')}</h4>
                    </div>
                    <p className='check-in-flow__form__info-row'>
                      {first_name} {last_name}
                    </p>
                  </>
                )}

                <CheckInDriverLicenseForm
                  onEditLicense={driverProfileComplete ? toggleEditingLicense : resetSteps}
                  hideEdit={isAuth && !driverProfileComplete}
                  form={form}
                />
                <CheckInContactInformationForm
                  initialValues={initialValues}
                  editContactInfo={toggleEditingContactInfo}
                  form={form}
                />

                {isUnauthUserWithLoyaltyProfile && (
                  <div className='in-res-oci-modal__signin'>
                    <h4>{utils.i18n('check_in_update_account_subtitle')}</h4>
                    <p onClick={() => setShowSignin(true)}>
                      {utils.i18n(
                        'check_in_sign_in_cta',
                        [
                          <Button key={0} link linkText onClick={() => setShowSignin(true)}>
                            {utils.i18n('common_sign_in')}
                          </Button>,
                        ],
                        { jsx: true }
                      )}
                    </p>
                  </div>
                )}

                {renderFooter(handleSubmit)}
              </form>
            </>
          ) : (
            <div className='check-in-flow__form component-theme--light'>
              {isFoundProfileWithConflictName && (
                <>
                  <div className='check-in-flow__form__section-title'>
                    <h4>{utils.i18n('common_name')}</h4>
                  </div>
                  <p className='check-in-flow__form__info-row'>
                    {first_name} {last_name}
                  </p>
                </>
              )}
              <CheckInDriverLicenseForm />
              <CheckInContactInformationForm initialValues={initialValues} form={form} />

              {isUnauthUserWithLoyaltyProfile ? (
                <div className='in-res-oci-modal__signin in-res-oci-modal__signin--expanded'>
                  <div className='in-res-oci-modal__signin-header'>
                    <h3>{utils.i18n('signin_submit')}</h3>
                    <Button link linkText onClick={() => setShowSignin(false)}>
                      {utils.i18n('in_res_oci_cancel_signin')}
                    </Button>
                  </div>
                  <SignIn onSuccess={onSuccessfulSignIn(handleSubmit)} onCancel={closeModal} />
                </div>
              ) : (
                renderFooter(handleSubmit)
              )}
            </div>
          )}
        </>
      )}
    </Form>
  );
};

ProfileForm.propTypes = {
  firstName: PropTypes.string,
  setEditing: PropTypes.func,
  resetSteps: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  renderFooter: PropTypes.func,
  scrollToModalTop: PropTypes.func,
  setHasConfirmed: PropTypes.func,
  isFoundProfileWithConflictName: PropTypes.bool,
};

export default ProfileForm;
