import React, { Component } from 'react';
import utils from 'utils';
import Form from 'components/Form/Form';
import Button from 'components/Button';
import ServiceErrors from 'components/ServiceErrors';
import AdditionalInfoQuestions from 'components/AdditionalInfoQuestions/AdditionalInfoQuestions';
import propTypes from './PrerateAdditionalInfoModalContentPropTypes';

class PrerateAdditionalInfoModalContent extends Component {
  static propTypes = propTypes;

  handleOnSubmit = (values) => {
    const { contractRequirements, setPrerateAdditionalInformation, handleInitiate, handleClose } = this.props;
    const errorObject = {};
    const additionalInformation = [];

    contractRequirements.forEach((coupon) => {
      const { name, id, required } = coupon;
      const nameKey = utils.string.stringUnderscorer(name);
      //  is required and doesn't have a value
      if (required && !values[nameKey]) {
        errorObject[nameKey] = true;
      }

      additionalInformation.push({
        id,
        value: values[nameKey],
      });
    });
    if (Object.keys(errorObject).length !== 0) {
      return errorObject;
    }
    setPrerateAdditionalInformation(additionalInformation);
    return handleInitiate().then(() => handleClose({ skipAnalytics: true }));
  };

  render() {
    const { hasAdditionalFieldsError, contractRequirements, setPrerateAdditionalInformation } = this.props;
    return (
      <div className='prerate-additional-info-modal-content__container theme--light'>
        <p className='prerate-additional-info-modal-content__sub-title'>
          {utils.i18n('prerate_add_additional_info_sub_title')}
        </p>
        <Form onSubmit={this.handleOnSubmit}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} autoComplete='off' noValidate>
              <p className='prerate-additional-info-modal-content__header-note'>
                {utils.i18n('prerate_add_additional_info_header_note')}
              </p>
              {!hasAdditionalFieldsError && <ServiceErrors />}
              <AdditionalInfoQuestions contractRequirements={contractRequirements} formValues={values} handleAdditionalInfoUpdates={setPrerateAdditionalInformation}/>

              <div className='modal-themed__footer'>
                <Button type='submit' className='button prerate-additional-info-modal-content__submit-button'>
                  {utils.i18n('prerate_add_additional_info_second_input_button')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

export default PrerateAdditionalInfoModalContent;
