import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import ASSETS from 'constants/assets';
import Picture from 'components/Picture';
import GenericDrawer from 'components/GenericDrawer';
import STCEligibleLocationsBranches from './STCEligibleLocationsBranches';
import STCEligibleLocationsByState from './STCEligibleLocationsByState';

const { STC_ELIGIBLE_LOCATIONS_1, STC_ELIGIBLE_LOCATIONS_2, STC_ELIGIBLE_LOCATIONS_3 } = ASSETS;

const renderDrawerTitle = (title, titleSuffix) => (
  <span className='stc-eligible-locations__drawer-title'>
    {title} <small>({titleSuffix})</small>
  </span>
);

/**
 * STCEligibleLocations
 *
 * @param {object} props - React Props
 * @param {array} props.countryList - Array of countries with STC eligible locations
 *
 * @return {JSX}
 */
const STCEligibleLocations = ({ countryList = [] }) => (
  <div className='stc-eligible-locations'>
    <div className='stc-eligible-locations__header'>
      <Picture
        srcMobile={STC_ELIGIBLE_LOCATIONS_1}
        srcTablet={STC_ELIGIBLE_LOCATIONS_2}
        src={STC_ELIGIBLE_LOCATIONS_3}
        className={'stc-eligible-locations__header-image'}
        isDecorative
      />
      <h3 className='stc-eligible-locations__header-title'>{utils.i18n('stc_eligible_locations_title')}</h3>
    </div>
    {countryList.map(({ title, titleSuffix, branches, branchesByState }) => (
      <GenericDrawer
        headerText={renderDrawerTitle(title, titleSuffix)}
        openCta={utils.i18n('view_all')}
        closeCta={utils.i18n('hide_all')}
        analyticsText={title}
        key={`stc-eligible-locations-${title}`}>
        <div className='stc-eligible-locations__grid theme--light'>
          {utils.gmi.isArrayNotEmpty(branches) ? (
            <STCEligibleLocationsBranches branches={branches} />
          ) : (
            <STCEligibleLocationsByState branchesByState={branchesByState} />
          )}
        </div>
      </GenericDrawer>
    ))}
  </div>
);

STCEligibleLocations.propTypes = {
  countryList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      titleSuffix: PropTypes.string,
      branches: PropTypes.array,
      branchesByState: PropTypes.object,
    })
  ),
};

export default STCEligibleLocations;
