import PropTypes from 'prop-types';

export const propTypes = {
  fullWidth: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  labelKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired,
  hasClearBelow: PropTypes.bool,
  isExtrasDrawer: PropTypes.bool,
};

export const defaultProps = {
  fullWidth: false,
  items: [],
  labelKey: 'name',
  contentKey: 'content',
};
