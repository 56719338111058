import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import CreditCardDetails from 'components/CreditCardDetails';
import { ASSETS } from 'constants';
import guaranteedResIcon from 'images/illutration-secondary-guarantee.svg';
import Button from 'components/Button';

/**
 * AboutPayLater - shows extra details about user's pay later reservation
 *
 * @param {object} props - React Props
 * @param {object} props.isCancelled - flag indicating if this is a cancelled res
 * @param {string} props.guaranteedResCardRequired - flag indicating that this is a guaranteed res with payment required
 * @param {object} props.selectedPaymentMethod - object with payment card details
 * @param {func}   props.showGuaranteedResModal - opens GUARANTEED_RES modal
 * @param {object} props.cancelFee - object with details about cancellation fee
 * @return {JSX}
 */

const AboutPayLater = ({
  isCancelled,
  guaranteedResCardRequired,
  selectedPaymentMethod,
  showGuaranteedResModal,
  cancelFee,
}) => {
  const guaranteedResFeeApply = guaranteedResCardRequired && cancelFee?.fee_apply;

  const subtitleTextOne = utils.i18n(
    guaranteedResCardRequired ? 'guaranteed_res_about_pay_later_title' : 'about_pay_later_subtitle_one_content'
  );

  const cancelledSubTitleTextOne = guaranteedResFeeApply
    ? utils.i18n(
        'guaranteed_res_about_pay_later_cancelled_copy',
        [<strong key='0'>{utils.getFormattedPrice(cancelFee.fee_amount_view)}</strong>],
        { jsx: true }
      )
    : utils.i18n('pay_later_cancellation_subtitle_one_content');

  const guaranteedResModalButton = (
    <Button key='0' link onClick={showGuaranteedResModal}>
      {utils.i18n('guaranteed_res_policy_link')}
    </Button>
  );

  const subTitleTextTwo = guaranteedResCardRequired
    ? utils.i18n('guaranteed_res_about_pay_later_cancellation_copy', [guaranteedResModalButton], { jsx: true })
    : utils.i18n('about_pay_later_subtitle_two_content');

  const cancelledSubTitleTextTwo = guaranteedResFeeApply
    ? utils.i18n('guaranteed_res_about_pay_later_cancelled_more_info', [guaranteedResModalButton], { jsx: true })
    : utils.i18n('pay_later_cancellation_subtitle_two_content');

  return (
    <div className='about-pay-later about-payment__container theme--dark-blue'>
      <div className='about-payment__title-container'>
        {/* Add image from dam + style */}
        <img
          className='about-payment__image'
          src={guaranteedResCardRequired ? guaranteedResIcon : ASSETS.ABOUT_PAYMENT_CARD_ICON}
          alt=''
        />
        <h2 className='about-payment__main-title'>
          {utils.i18n(isCancelled ? 'pay_later_cancellation_title' : 'about_pay_later_title')}
        </h2>
      </div>
      {!isCancelled && <h3 className='about-payment__sub-title'>{utils.i18n('about_pay_later_subtitle_one')}</h3>}

      <p className='about-payment__sub-title-text'>{isCancelled ? cancelledSubTitleTextOne : subtitleTextOne}</p>

      {guaranteedResCardRequired && !isCancelled && <CreditCardDetails cardDetails={selectedPaymentMethod} />}

      {!isCancelled && <h3 className='about-payment__sub-title'>{utils.i18n('about_pay_later_subtitle_two')}</h3>}

      <p className='about-payment__sub-title-text'>{isCancelled ? cancelledSubTitleTextTwo : subTitleTextTwo}</p>

      {guaranteedResFeeApply && isCancelled && (
        <div className='about-payment__fee-charged'>
          <div className='about-payment__fee-charged-line-item'>
            <span>{utils.i18n('guaranteed_res_about_pay_later_amount_charged')}</span>
            <span>{utils.getFormattedPrice(cancelFee.fee_amount_view)}</span>
          </div>

          <CreditCardDetails cardDetails={selectedPaymentMethod} />
        </div>
      )}
    </div>
  );
};

AboutPayLater.propTypes = {
  isCancelled: PropTypes.bool,
  selectedPaymentMethod: PropTypes.shape({
    number: PropTypes.string,
    card_type: PropTypes.string,
  }),
  guaranteedResCardRequired: PropTypes.bool,
  showGuaranteedResModal: PropTypes.func.isRequired,
  cancelFee: PropTypes.object,
};

export default AboutPayLater;
