import { connect } from 'react-redux';
import utils from 'utils';
import MODAL from 'constants/modals';
import { getSelectedVehiclesFilterSelector, getAllVehiclesListSelector } from 'selectors/vehicleSelectSelectors';
import { getUiInitiateState } from 'selectors/uiStateSelectors';
import { openModal } from "actions/modal/openModal";
import LocationStateDrawer from './LocationStateDrawer';

// Connecting redux to location type
const mapStateToProps = state => {
  const initiate = getUiInitiateState(state);
  const vehicleCategoriesInitialValues = getAllVehiclesListSelector(state);
  return {
    vehicleSearchFilters: getSelectedVehiclesFilterSelector(state),
    vehicle_categories: vehicleCategoriesInitialValues || [],
    one_way_rental: initiate?.one_way_rental,
  };
};

const mapDispatchToProps = {
  handleLocationTypeFilterModal: () => openModal(MODAL.LOCATION_TYPE_FILTER_MODAL, { skipAnalytics: true }),
  handleVehicleClassFilterModal: () => openModal(MODAL.VEHICLE_CLASS_FILTER_MODAL, { skipAnalytics: true }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(LocationStateDrawer));
