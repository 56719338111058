/* eslint no-underscore-dangle: 0 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GMA_RESPONSE_CODES, MODAL } from 'constants';
import utils from 'utils';
import { gmaErrorMessagesSelector } from 'selectors';
import { openModal } from "actions/modal/openModal";
import { clearModalQueue } from "actions/modal/clearModalQueue";
import propTypes from './serviceErrorHandlerPropTypes';

/*
 *** IMPORTANT NOTE ***
 * If you want to apply this Hoc in a flow that needs to display the ServiceErrors component, ServiceErrors is already
 * wrapped and has access to all functionality. So if the new flow doesn't need any custom handling and just needs to
 * run the pre-defined handler methods below, there is no need to wrap any new components and ServiceErrors can run
 * these handlers automatically. ServiceErrors will run handlers for any service errors it is listening to if you pass
 * runAllHandlers prop.
 * TODO: Add functionality to specify handlers to run in case you want ServiceErrors to show a set of errors but
 *  only run handlers for some of them.
 *
 *
 *** Functionality: ***
 * This Hoc provides a way to consolidate service error handling code across the site. It provides access to
 * gmaErrorMessages redux state and exposes an error handling controller api for wrapped components.
 *
 * This Hoc can be passed errorCodes and statePath values to filter which errors to listen to
 * (errorCodes array will filter for specific error codes while statePath will filter for codes from a specific gma service.)
 * These values can be specified when calling ServiceHandlerHoc to wrap a component or by passing errorCodes or statePath as
 * props to the wrapped component. If both, the values passed as props will override.
 *
 * As gmaErrorMessages state changes, this Hoc passes a serviceErrorsController object down to the wrapped component
 * exposing a flexible control api for error handling:
 *
 * serviceErrorsController = {
 *   serviceErrorsArray: an array of all serviceErrors filtered by errorCodes or statePath
 *
 *   serviceErrors: the serviceErrorsArray normalized (keys are the errorCodes) along with additional values:
 *    [ERROR_CODE]: {
 *      ...gmaErrorMessage,
 *      newError: boolean - only true on specific update when this error was added (calculated by prop diffing)
 *      handler: func - the specific handler for this errorCode is accessible to the wrapped component giving more fine
 *       grained control than just running runAllHandlers
 *
 *   newError: boolean - only true on specific update when any error was added (calculated by prop diffing)
 *
 *   runAllHandlers: func - runs all handlers for active errors.
 * }
 *
 * Certain props can be passed to components wrapped in this Hoc to control functionality as gmaErrorMessages update:
 *   runAllHandlers: boolean - if true will runAllHandlers once whenever a newError is added
 *
 */

const ServiceErrorHandlerHoc = (errorCodes, statePath) => WrappedComponent => {
  /*
   * mapState and mapDispatch can pass in any additional state or actions that handlers may need access to. But these
   * props would be passed down to wrapped components as well, so its good to delete them in the buildProps method below
   *
   * To avoid naming collision if we want to pass certain props through to the wrapped component that could be
   * named the same as in these mapState or mapDispatch, use underscore naming convention here.
   */
  const mapStateToProps = (state, ownProps) => ({
    _gmaErrorMessages: gmaErrorMessagesSelector(state, {
      errorCodes: ownProps.errorCodes || errorCodes,
      statePath: ownProps.statePath || statePath,
    }),
    _currentKeyOfModalOpen: state.getIn(['app', 'modal', 'modalQueue']).get(0),
  });

  const mapDispatchToProps = {
    _openModal: openModal,
    _clearModalQueue: clearModalQueue,
  };

  class ServiceErrorHandler extends Component {
    static propTypes = propTypes;

    state = {
      serviceErrors: {},
      newError: false,
    };

    // TODO: updateErrorState being called on mount and update can be refactored to use static getDerivedStateFromProps
    componentDidMount() {
      this.updateErrorState();
    }

    componentDidUpdate(prevProps) {
      this.updateErrorState(prevProps._gmaErrorMessages);
    }

    updateErrorState = (prevErrors = []) => {
      /*
       * This method builds the serviceErrors state object with newError flags and handlers which are passed down to
       * the wrapped component through serviceErrorsController. newError flags are calculated by diffing current
       * gmaErrorMessages with prevErrors. This method can also be used to control running of handlers based on props
       * like runAllHandlers
       */
      const { _gmaErrorMessages, runAllHandlers } = this.props;
      let newError = false;

      const prevErrorLookup = prevErrors.reduce((lookupObj, error) => {
        lookupObj[error.code] = error;
        return lookupObj;
      }, {});

      const newServiceErrors = _gmaErrorMessages.reduce((serviceErrors, error) => {
        const isNewError = !prevErrorLookup[error.code];
        if (isNewError) {
          newError = true;
        }
        serviceErrors[error.code] = {
          ...error,
          newError: isNewError,
          handler: this.handlers[error.code],
        };
        return serviceErrors;
      }, {});

      const errorExpired = prevErrors.find(error => !newServiceErrors[error.code]);

      if (newError || errorExpired) {
        this.setState(
          { serviceErrors: newServiceErrors, newError },
          newError && runAllHandlers ? this.runAllHandlers : null
        );
      } else if (this.state.newError && !newError) {
        this.setState({ newError: false });
      }
    };

    handlers = {
      /* Currently following a pattern of checking if a particular error is a newError before allowing the handler
       * logic to run. This is not a requirement, but for something like opening warning modals it makes sense.
       * There may be scenarios which require a component to have more flexibility when to run a handler so this
       * restriction can be removed, just be careful if doing so when using runAllHandlers.
       *
       * Wrapped components can manually call these handlers through the serviceErrorsController:
       *  this.props.serviceErrorsController.serviceErrors[ERROR_CODE].handler?.(params)
       *
       * Dont forget you can pass params like this, so you can customize these handlers to specific scenarios
       */
      [GMA_RESPONSE_CODES.CROS_RES_PRE_RATE_ADDITIONAL_FIELD_REQUIRED]: () => {
        const { _openModal } = this.props;
        const { newError } = this.state.serviceErrors[GMA_RESPONSE_CODES.CROS_RES_PRE_RATE_ADDITIONAL_FIELD_REQUIRED];
        newError && _openModal(MODAL.PRE_RATE_ADDITIONAL_INFO_MODAL);
      },

      [GMA_RESPONSE_CODES.CROS_RES_INVALID_ADDITIONAL_FIELD]: () => {
        const { _currentKeyOfModalOpen, _openModal } = this.props;
        const { newError } = this.state.serviceErrors[GMA_RESPONSE_CODES.CROS_RES_INVALID_ADDITIONAL_FIELD];
        const isPreRateAdditionalInfoModalAlreadyOpen =
          _currentKeyOfModalOpen?.modalKey === MODAL.PRE_RATE_ADDITIONAL_INFO_MODAL;
        //  check if we have a new error and the modal isn't already popped;
        newError && !isPreRateAdditionalInfoModalAlreadyOpen && _openModal(MODAL.PRE_RATE_ADDITIONAL_INFO_MODAL);
      },

      [GMA_RESPONSE_CODES.GMA_PROFILE_DO_NOT_RENT]: () => {
        const { _openModal, _clearModalQueue } = this.props;
        const { newError } = this.state.serviceErrors[GMA_RESPONSE_CODES.GMA_PROFILE_DO_NOT_RENT];
        if (newError) {
          _clearModalQueue();
          _openModal(MODAL.DO_NOT_RENT_MODAL);
        }
      },
    };

    runAllHandlers = () => {
      Object.values(this.state.serviceErrors).forEach(error => {
        error.handler?.();
      });
    };

    buildProps = () => {
      const { serviceErrors, newError } = this.state;
      const { _gmaErrorMessages } = this.props;
      const passedProps = { ...this.props };

      // Automatically deletes properties that use underscore naming convention to avoid passing private
      // state and methods to wrapped component.
      Object.keys(passedProps).forEach(key => {
        if (key.charAt(0) === '_') {
          delete passedProps[key];
        }
      });

      const serviceErrorsController = {
        serviceErrorsArray: _gmaErrorMessages,
        serviceErrors,
        newError,
        runAllHandlers: this.runAllHandlers,
      };

      return { ...passedProps, serviceErrorsController };
    };

    render() {
      return <WrappedComponent {...this.buildProps()} />;
    }
  }

  ServiceErrorHandler.displayName = `ServiceErrorHandler(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'Component'})`;

  const enhance = utils.compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    utils.toJS
  );

  return enhance(ServiceErrorHandler);
};

export default ServiceErrorHandlerHoc;
