import { connect } from 'react-redux';
import utils from 'utils';
import { getPastTrips, getCurrentTrips, getUpcomingTrips } from 'actions/trips';
import { profileDataSelector } from 'selectors';
import { firstCurrentTripSelector, firstUpcomingTripSelector, totalCountTripsSelector } from 'selectors/tripsSelectors';
import { retrieveReservation } from "actions/reservation/retrieveReservation";
import UserCurrentNextTrip from './UserCurrentNextTrip';

const mapStateToProps = state => ({
  profileData: profileDataSelector(state, { first_name: true, last_name: true, memberNumber: true }),
  currentTrip: firstCurrentTripSelector(state),
  upcomingTrip: firstUpcomingTripSelector(state),
  totalCountTrips: totalCountTripsSelector(state),
});

const mapDispatchToProps = {
  getUpcomingTrips,
  getCurrentTrips,
  getPastTrips,
  retrieveReservation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(UserCurrentNextTrip));
