import React from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import ConfirmationContainer from 'components/Confirmation/ConfirmationContainer';

const RentalDetails = () => <ConfirmationContainer isFoundRental />;

const RentalDetailsEditableConfig = {
  emptyLabel: 'Found Rental Details Component',
  isEmpty: props => !props,
};

export default MapTo('aweb/components/content/rental-details')(RentalDetails, RentalDetailsEditableConfig);
