import React, { Component } from 'react';
import { sanitize } from 'utils/validation';
import { ANALYTICS } from 'constants';
import LoadingWidget from 'components/LoadingWidget';
import PrintButtonModal from 'components/Modal/PrintButtonModal';
import propTypes from './PrepayTermsPropTypes';

class PrepayTerms extends Component {
  static propTypes = propTypes;

  componentDidMount() {
    const { getPrepayTerms, prepayTerms } = this.props;
    if (!prepayTerms) {
      getPrepayTerms();
    }
  }

  render() {
    const { classNames = '', prepayTerms, showPrintButton } = this.props;
    return (
      <div className='padding-top--condensed pre-pay-terms__content--position'>
        {prepayTerms ? (
          <>
            <PrintButtonModal
              showPrintButton={showPrintButton}
              analyticsTitle={ANALYTICS.PREPAYMENT_TERMS_CONDITIONS}
              customCss='.pre-pay-terms__overlay'
            />
            <div className={classNames} dangerouslySetInnerHTML={sanitize(prepayTerms)} />
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
    );
  }
}

export default PrepayTerms;
