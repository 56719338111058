import { connect } from 'react-redux';
import { getBranchHours } from 'actions/location';
import utils from 'utils';
import BranchHours from './BranchHours';

const mapDispatchToProps = {
  getBranchHours,
};

const mapStateToProps = (state, ownProps) => ({
  hoursForStore: state.getIn(['gmi', 'solr', 'hours', ownProps.id, 'data']) || null,
  pickUpTime: state.getIn(['gmi', 'session', 'gma', 'initiate_request', 'pickup_time']),
  dropOffTime: state.getIn(['gmi', 'session', 'gma', 'initiate_request', 'return_time']),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(BranchHours));
