import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Picture from 'components/Picture';
import { ASSETS } from 'constants';

/**
 * ProfileAccountMemberBadge
 *
 * @param {object} props - React Props
 * @param {string} props.memberNumber - Authenticated user Alamo Insider member number
 *
 * @return {JSX} ProfileAccountMemberBadge component
 */
const ProfileAccountMemberBadge = ({ memberNumber }) => (
  <div className='profile-account-member-badge'>
    <p className='profile-account-member-badge__member-number'>
      <span className='profile-account-member-badge__title'>{utils.i18n('profile_account_member_number')}</span>
      {memberNumber}
    </p>
    <Picture
      className='profile-account-member-badge__insiders-logo'
      srcMobile={ASSETS.ALAMO_INSIDERS_1}
      srcTablet={ASSETS.ALAMO_INSIDERS_2}
      src={ASSETS.ALAMO_INSIDERS_3}
      alt={utils.i18n('alamo_insiders_logo_alt')}
    />
  </div>
);

ProfileAccountMemberBadge.propTypes = {
  memberNumber: PropTypes.string,
};

export default ProfileAccountMemberBadge;
