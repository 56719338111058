import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';
import ProfileProgressIndicator from 'components/ProfileProgressIndicator';
import EditProfileWizardModal from '../EditProfileWizardModal';

/**
 * getOpenModalFunction - determines  based on the user's profile completion
 * @param {string} profileCompletion - Profile completion percentage
 * @param {object} profileStatus - Authenticated user profile completion status
 * @param {function} openWizardModal - action that opens the Wizard Modal for profile completion
 * @param {function} openDriversLicenseModal - action that opens the Edit Drivers License Information Modal
 * @param {function} openContactInfoModal - action that opens the Edit Contact Information Modal
 */
const getOpenModalFunction = (
  profileCompletion,
  profileStatus,
  openWizardModal,
  openDriversLicenseModal,
  openContactInfoModal
) => {
  const { driverlicense_details_present, dob_details_present } = profileStatus || {};

  /**
   * If profile is at 90%, user already has either DL or Contact info;
   *  - if they have DL and DOB details present, should open the contact info modal;
   *  - if they don't, should open the DL info modal.
   */
  if (profileCompletion === 90) {
    if (driverlicense_details_present && dob_details_present) {
      return openContactInfoModal;
    }
    return openDriversLicenseModal;
  }
  // Default case (a.k.a. doesn't have both DL and Contact Info)
  return openWizardModal;
};

/**
 * ProfileProgressSection - This component uses the ProfileProgressIndicator
 * to show the user how much has been achieved on filling her/his/their profile.
 * Also, when clicking on the button, the profile wizard modal should be opened
 * for the user to fill in the missing data.
 * @param {object} props - React Props
 * @param {object} props.profileData - Authenticated user profile data
 * @param {function} props.openWizardModal - action that opens the Wizard Modal for profile completion
 * @param {function} props.openDriversLicenseModal - action that opens the Edit Drivers License Information Modal
 * @param {function} props.openContactInfoModal - action that opens the Edit Contact Information Modal
 * @return {JSX}
 */
const ProfileProgressSection = ({ profileData, openWizardModal, openDriversLicenseModal, openContactInfoModal }) => {
  const { profile_completion, oci_individual_status } = profileData || {};

  const openModalFunction = getOpenModalFunction(
    profile_completion,
    oci_individual_status,
    openWizardModal,
    openDriversLicenseModal,
    openContactInfoModal
  );

  return (
    <>
      <div className='profile-progress-section'>
        <ProfileProgressIndicator />
        {profile_completion < 100 ? (
          <>
            <p className='profile-progress-section__description'>
              {utils.i18n('profile_progress_indicator_description')}
            </p>
            <Button
              onClick={openModalFunction}
              className='profile-progress-section__button'
              data-dtm-track={utils.analytics.dtm(
                ANALYTICS.PROFILE,
                'main_cta',
                utils.i18n('profile_progress_indicator_add_information_button')
              )}>
              {utils.i18n('profile_progress_indicator_add_information_button')}
            </Button>
          </>
        ) : (
          <p className='profile-progress-section__description'>
            {utils.i18n('profile_progress_indicator_description_completed')}
          </p>
        )}
      </div>
      <EditProfileWizardModal />
    </>
  );
};

ProfileProgressSection.propTypes = {
  profileData: PropTypes.object,
  openWizardModal: PropTypes.func,
  openDriversLicenseModal: PropTypes.func,
  openContactInfoModal: PropTypes.func,
};

export default ProfileProgressSection;
