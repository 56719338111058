import React from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';
import ANALYTICS from 'constants/analytics';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const CancelUpdatesModal = ({ openUpdateFlightInfoModal }) => (
  <Modal
    modalKey={MODAL.CANCEL_UPDATES_MODAL}
    analyticsKey={'cancel flight update'}
    header={utils.i18n('flight_cancel_updates')}
    theme={MODAL_THEMES.WHITE}
    showHeaderTextIcon
    headerTextIcons={'plane'}
    secondIcon
    headerIconBgColor={'modal-red'}
  >
    {({ handleClose }) => (
      <div className='modal-themed__flight-change-delayed-modal'>
        <p className='modal-themed__paragraph'>{utils.i18n('flight_cancel_line_one')}</p>
        <div className='flight-delayed-changed-modal-footer column-reverse-footer'>
          <ModalFooter
            fullWidthButton
            buttons={[
              {
                type: DECLINE,
                label: utils.i18n('flight_cancel_yes_cancel'),
                handler: () => {
                  handleClose();
                },
                analyticsHandler: () =>
                  utils.analytics.interaction(ANALYTICS.MODAL, 'cancel flight update', ANALYTICS.CANCEL),
              },
              {
                type: ACCEPT,
                label: utils.i18n('flight_cancel_no_back'),
                handler: () => {
                  handleClose();
                  if (openUpdateFlightInfoModal) openUpdateFlightInfoModal();
                },
                analyticsHandler: () =>
                  utils.analytics.interaction(ANALYTICS.MODAL, 'cancel flight update', 'back to updates'),
              },
            ]}
          />
        </div>
      </div>
    )}
  </Modal>
);

CancelUpdatesModal.propTypes = {
  handleClose: PropTypes.func,
  openUpdateFlightInfoModal: PropTypes.func,
};

export default CancelUpdatesModal;
