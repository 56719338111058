import { connect } from 'react-redux';
import { getPastTrips } from 'actions/trips';
import { pastTripsSelector } from 'selectors/tripsSelectors';
import { profileDataSelector } from 'selectors/profileSelectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import PastTrips from './PastTrips';

const mapStateToProps = (state) => ({
  pastTrips: pastTripsSelector(state),
  profileData: profileDataSelector(state, { memberNumber: true }),
  isFetchingPastTrips: isFetchingEndpointSelector(state, { endpoint: 'trips/past' }),
});

const mapDispatchToProps = {
  getPastTrips,
  openMissingTripsModal: () => openModal(MODAL.MISSING_TRIPS_MODAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(PastTrips);
