import React from 'react';
import ListItem from './ListItem';
import { propTypes, defaultProps } from './ListPropTypes';

const List = ({ css, listItems, listItemComponent, onClick }) => (
  <ul className={css.List}>
    {listItems.map((listItem, i) => (
      <ListItem
        css={css}
        key={listItem.id || i}
        listItem={listItem}
        listItemComponent={listItemComponent}
        onClick={onClick}
      />
    ))}
  </ul>
);

List.defaultProps = defaultProps;
List.propTypes = propTypes;

export default List;
