import React from 'react';
import utils from 'utils';
import cn from 'classnames';
import { ANALYTICS, COMPONENTS } from 'constants';
import ToolTip from 'components/ToolTip';
import PropTypes from 'prop-types';

const { LIMITED_INVENTORY, RECOMMENDED } = COMPONENTS.INDIVIDUAL_EXTRA_ALERT;

//  This is a hashtable for predefined options
//  if you want to add more options please put the consts
//  in the components consts file

const typeOfAlertsHashTable = {
  [LIMITED_INVENTORY]: {
    mainText: utils.i18n('limited_inventory_banner_common'),
    toolTipContent: utils.i18n('limited_inventory_banner_tooltip_common'),
    mainAlertClass: 'limited-inventory',
    analyticsKey: ANALYTICS.LIMITED_INVENTORY,
  },
  [RECOMMENDED]: {
    mainText: utils.i18n('recommended_alert_common'),
    toolTipContent: false,
    mainAlertClass: 'recommended-extra',
    analyticsKey: ANALYTICS.RECOMMENDED,
  },
};

/**
 * IconAlert - shows an alert above an individual extra
 *
 * @param {object}    props - React Props
 * @param {string}    props.toolTipTheme - What tooltip theme you want the tooltip to use
 * @param {array}     props.additionalClass - an additional class you can put on the alert
 * @param {function}  props.placementOfToolTip - the position of the tooltip
 * @param {boolean}   props.hideToolTip - if the alert should display the tooltip
 * @param {function}  props.typeOfAlert - This is the value that goes in to the `typeOfAlertsHashTable`,
 *                                        each type of alert has different keys/ options that goes along with it
 * @return {JSX}      IconAlert component
 */

const IconAlert = ({ toolTipTheme, additionalClass, placementOfToolTip, hideToolTip, typeOfAlert }) => {
  const optionsForAlert = typeOfAlertsHashTable[typeOfAlert];
  return (
    <div className={cn('icon-alert', optionsForAlert.mainAlertClass, additionalClass)}>
      <span>{optionsForAlert.mainText}</span>
      {!hideToolTip && optionsForAlert.toolTipContent && (
        <ToolTip placement={placementOfToolTip} theme={toolTipTheme} analyticsKey={optionsForAlert.analyticsKey}>
          <label>{optionsForAlert.toolTipContent}</label>
        </ToolTip>
      )}
    </div>
  );
};

IconAlert.defaultProps = {
  placementOfToolTip: 'right-end',
};

IconAlert.propTypes = {
  toolTipTheme: PropTypes.string,
  additionalClass: PropTypes.string,
  placementOfToolTip: PropTypes.string,
  hideToolTip: PropTypes.bool,
  typeOfAlert: PropTypes.string,
};

export default IconAlert;
