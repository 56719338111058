import React from 'react';
import utils from 'utils';
import FlyoutTabs from 'components/FlyoutTabs';
import { propTypes } from './LocationServicesPropTypes';

class LocationServices extends React.Component {
  static propTypes = propTypes;

  componentDidMount() {
    const { locationInfo, locationInfoById, id } = this.props;
    !locationInfo[id] && locationInfoById(id);
  }

  createLocationServicesItems = () => {
    const { locationInfo, id } = this.props;
    const locationInfoHashTable = locationInfo[id]?.location?.service_indicators;
    if (!locationInfoHashTable) {
      return false;
    }

    // Location has both: Title = After-hours Services
    // Location has AH pickup but not AH return: Title = After-hours Pickup
    // Location has AH return but not AH pickup: Title = After-hours Return
    // Location has neither: Title = After-hours Services
    const i18nKeyForAfterHoursTitle =
      (locationInfoHashTable.afterHoursPickup &&
        locationInfoHashTable.offersAfterHoursDropoff &&
        'location_service_after_hours_services') ||
      (locationInfoHashTable.afterHoursPickup && 'location_service_after_hours_pickup') ||
      (locationInfoHashTable.offersAfterHoursDropoff && 'location_service_after_hours_dropoff') ||
      'location_service_after_hours_services';
    const serviceItems = [
      {
        //  Self Service Kosk
        name: utils.i18n('location_service_kiosk'),
        disabled: !locationInfoHashTable.kiosk,
        icon: 'kiosk',
        content: `<p>${utils.i18n('location_service_service_kiosk_paragraph')}</p>`,
      },
      {
        //  Skip counter
        name: utils.i18n('location_service_skip_counter'),
        disabled: !locationInfoHashTable.skipTheCounter,
        icon: 'counter',
        content: `<p>${utils.i18n('location_service_skip_the_counter_paragraph')}</p> <p>${utils.i18n(
          'location_service_skip_the_counter_paragraph_2'
        )}</p>`,
      },
      {
        //  Online Check in
        name: utils.i18n('location_service_online_checkin'),
        disabled: !locationInfoHashTable.onlineCheckIn,
        icon: 'check-in',
        content: `<p>${utils.i18n('location_service_online_check_in_paragraph')}</p>`,
      },
      {
        //  After-Hours Services
        name: utils.i18n(i18nKeyForAfterHoursTitle),
        disabled: !(locationInfoHashTable.afterHoursPickup || locationInfoHashTable.offersAfterHoursDropoff),
        icon: 'after-hours',
        content: `<h3>${utils.i18n('location_service_after_hours_services_header')}</h3>
        <p>${utils.i18n('location_service_after_hours_services_paragraph')}</p>
        <h3>${utils.i18n('location_service_after_hours_services_header_2')}</h3>
        <p>${utils.i18n('location_service_after_hours_services_paragraph_2')}</p>`,
      },
    ];

    return serviceItems.sort(a => (a.disabled ? 1 : -1)); // put the disabled ones last
  };

  render() {
    const locationServicesItems = this.createLocationServicesItems();
    return locationServicesItems && <FlyoutTabs items={locationServicesItems} />;
  }
}

export default LocationServices;
