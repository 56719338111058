import React from 'react';
import { Field } from 'react-final-form';
import { propTypes } from './fieldsStatePropTypes';

const FieldsState = ({ names, subscription, fieldsState = {}, children, originalRender }) => {
  if (!names.length) {
    return (originalRender || children)(fieldsState);
  }
  const [name, ...rest] = names;
  return (
    <Field name={name} subscription={subscription}>
      {fieldState => (
        <FieldsState
          names={rest}
          subscription={subscription}
          originalRender={originalRender || children}
          fieldsState={{ ...fieldsState, [name]: fieldState }}
        />
      )}
    </Field>
  );
};

FieldsState.propTypes = propTypes;

export default FieldsState;
