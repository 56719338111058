import PropTypes from 'prop-types';
import utils from 'utils';
import { MODAL_THEMES } from 'constants';

export const ModalPropTypes = {
  // Parent props
  /**
   * Unique key from "constants/modals" which identify modal
   */
  modalKey: PropTypes.string.isRequired,
  /**
   * Modal title
   */
  title: PropTypes.string,
  /**
   * Header text. Is over-ridden if a header value is passed in through openModalWithProps
   */
  header: PropTypes.string,
  /**
   * Closes currently opened modal
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Modal content
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func, PropTypes.bool]),
  /**
   * ARIA role for modal body
   */
  role: PropTypes.string,
  /**
   * ARIA label for close button in modal's header
   */
  headerCloseAriaLabel: PropTypes.string,
  /**
   * Boolean flag to determine if close button should be rendered in the header
   */
  shouldRenderHeaderCloseButton: PropTypes.bool,
  /**
   * Boolean flag to control the behavior if modal should close on click outside
   */
  shouldCloseOnClickOutside: PropTypes.bool,
  /**
   * Boolean flag to control the behavior if modal should close on ESC key press
   */
  shouldCloseOnEsc: PropTypes.bool,
  /**
   * Custom class to apply custom styling to all modal components - applies the customClass to modal-overlay
   */
  customClass: PropTypes.string,
  /**
   * Set one of the pre-defined styling themes - applies one of the theme classes defined in Modal component to modal-overlay
   */
  theme: PropTypes.oneOf(Object.values(MODAL_THEMES)),
  /**
   * Boolean flag to control showing or not showing header icons
   */
  showHeaderIcon: PropTypes.bool,
  /**
   * Object containing necessary values to fill <Picture /> props
   */
  headerIcons: PropTypes.shape({
    src: PropTypes.string,
    srcTablet: PropTypes.string,
    srcMobile: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
  }),
  /**
   * Boolean flag to control showing or not showing header Img
   */
  showHeaderImg: PropTypes.bool,
  /**
   * Object containing necessary values to render Img
   */
  headerImg: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
  }),
  /**
   * Custom action for the header close button
   */
  customActionOnClose: PropTypes.func,

  // container props
  /**
   * The modal open state
   */
  isOpen: PropTypes.bool,
  /**
   * Props that will be passed down to content component - passed in through openModalWithProps
   */
  contentProps: PropTypes.object,
  /**
   * Final modal queue action that is set by passing openModalWithProps({ finalAction }) - only available if queue is empty
   */
  finalAction: PropTypes.func,
  /**
   * Mobile breakpoint
   */
  breakpoint: PropTypes.string,
  /**
   * Action to run on modal close
   */
  onClose: PropTypes.func,

  /**
   * Modal header with clearAll button
   */
  headerRowWithClearButton: PropTypes.bool,

  /**
   * Action to run on modal header row cta
   */
  handleHeaderCTA: PropTypes.func,

  /**
   *  Boolean flag to control showing or not showing header cta
   */
  showHeaderCTA: PropTypes.bool,

  /**
   *  Boolean flag to control collapsable CTA
   */
  isExpanded: PropTypes.bool,

  /**
   *  header cta title
   */
  headerCtaTitle: PropTypes.string,
};

export const ModalDefaultProps = {
  role: 'dialog',
  shouldRenderHeaderCloseButton: true,
  shouldCloseOnClickOutside: true,
  shouldCloseOnEsc: true,
  headerRowWithClearButton: false,
  headerCloseAriaLabel: utils.i18n('default_modal_aria_close_label'),
};
