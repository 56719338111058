import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import useActions from 'hooks/useActions';
import FORMS from 'constants/forms';
import { skipTheCounterCommit } from 'actions/skipTheCounter';
import {
  getReservationConfirmationNumber,
  havePriceDifferenceSelector,
  inflightPaymentSelector,
  getCommitReservationRequest,
  reservationAirlineInfoSelector,
} from 'selectors/reservationSelectors';
import {
  getDidPrepay,
  isEUPrepaySelector,
  isNAPrepaySelector,
  estimatedTotalViewSelector,
} from 'selectors/reservationFlowSelectors';
import { selectedInsuranceExtrasSelector } from 'selectors/extrasSelectors';
import Form from 'components/Form/Form';
import SkipTheCounterFlowTemplate from '../SkipTheCounterFlowTemplate';
import STCReviewProtectionOptions from './STCReviewProtectionOptions/STCReviewProtectionOptions';
import STCTermsAndConditions from './STCTermsAndConditions';
import STCPaymentInformation from './STCPaymentInformation/STCPaymentInformation';
import STCLocalAddendum from './STCLocalAddendum';

const { ACCEPT_VALUE } = FORMS;

/**
 * STCProtectionsAndPayment
 *
 * @param {object} props - React Props
 *
 * @return {JSX}
 */
const STCProtectionsAndPayment = () => {
  const commitSkipTheCounter = useActions(skipTheCounterCommit);
  const reservationConfirmationNumber = useSelector(getReservationConfirmationNumber);
  const selectedInsuranceExtras = useSelector(selectedInsuranceExtrasSelector);
  const isPrepay = useSelector(getDidPrepay);
  const isEUPrepay = useSelector(isEUPrepaySelector);
  const isNAPrepay = useSelector(isNAPrepaySelector);
  const havePriceDifference = useSelector(havePriceDifferenceSelector);
  const estimatedTotal = useSelector(estimatedTotalViewSelector);
  const zeroChargeValue = utils.getFormattedZeroPrice(estimatedTotal);
  const paymentDetails = useSelector(inflightPaymentSelector);
  const airlineInfo = useSelector(reservationAirlineInfoSelector);
  const commitState = useSelector(getCommitReservationRequest);

  // Payment Info section is shown only for Pay Later reservations, EU domain prepay reservations and
  // for NA domain prepay reservations with price difference
  const shouldShowPaymentInfo = estimatedTotal && (!isPrepay || isEUPrepay || isNAPrepay);
  // Zero charge footnote shows for EU Prepay with payment details or for NA Prepay with price difference (Unpaid/Refund)
  const shouldRenderFootnote = (!!isEUPrepay && !!paymentDetails) || (isNAPrepay && havePriceDifference);

  const initialValues = useMemo(
    () => {
      const initialSelectedProtections = {};
      selectedInsuranceExtras.forEach(extra => {
        initialSelectedProtections[`opt-in-${extra.code}`] = ACCEPT_VALUE;
      });

      return {
        ...initialSelectedProtections,
      };
    },
    // Initial value must not change unless Reservation's Confirmation Number gets updated (reservation is loaded),
    // otherwise using other values like selectedInsuranceExtras would update the Initial Values everytime the user
    // accepts or decline a protection option because it will refresh the session and the value itself.
    [reservationConfirmationNumber]
  );

  const haveProtectionsError = errors => Object.keys(errors).some(field => field.includes('opt-in'));
  const haveTermsConditionsError = errors => !!errors.accept_terms_and_conditions;
  const haveLocalAddendumError = errors => !!errors.accept_branch_local_addenda;

  const formSubmit = ({ accept_branch_local_addenda }) => {
    // we're setting an airline info if it has been modifed/ updated in step 1 of skip the counter flow
    if (commitState?.airline_information && commitState?.airline_information?.code) {
      const { airline_information } = commitState;
      // Checking for updates if flight info is modified in skip the counter flow
      if (
        (!airlineInfo?.code && commitState?.airline_information?.code) ||
        (airlineInfo?.code &&
          (airline_information?.code !== airlineInfo?.code ||
            airline_information?.flight_number !== airlineInfo?.flight_number))
      ) {
        commitSkipTheCounter({ accept_branch_local_addenda, ...commitState });
      } else {
        commitSkipTheCounter({ accept_branch_local_addenda });
      }
    } else {
      commitSkipTheCounter({
        accept_branch_local_addenda,
      });
    }
  };

  return (
    <Form onSubmit={formSubmit} initialValues={initialValues} subscription={{ submitFailed: true, errors: true }}>
      {({ form, handleSubmit, submitFailed, errors }) => (
        <SkipTheCounterFlowTemplate
          submitAction={handleSubmit}
          withRequiredNote
          stepTwo
          submitFootNote={
            shouldRenderFootnote ? `${utils.i18n('stc_payment_information_total_charged_now')}: ${zeroChargeValue}` : ''
          }>
          <form onSubmit={handleSubmit} noValidate>
            {/* Placeholders */}
            <STCReviewProtectionOptions haveErrors={haveProtectionsError(errors)} submitFailed={submitFailed} />
            <STCTermsAndConditions haveErrors={haveTermsConditionsError(errors)} submitFailed={submitFailed} />
            <STCLocalAddendum haveErrors={haveLocalAddendumError(errors)} submitFailed={submitFailed} />
            {shouldShowPaymentInfo && <STCPaymentInformation submitFailed={submitFailed} form={form} />}
          </form>
        </SkipTheCounterFlowTemplate>
      )}
    </Form>
  );
};

export default STCProtectionsAndPayment;
