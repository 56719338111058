import { connect } from 'react-redux';
import utils from 'utils';
import { breakpointSelector } from 'selectors/breakpoint';
import { individualExtrasSelector } from 'selectors/extrasSelectors';
import IndividualExtras from './IndividualExtras';

const mapStateToProps = state => ({
  breakpoint: breakpointSelector(state),
  individualExtras: individualExtrasSelector(state),
});

export default connect(mapStateToProps)(utils.toJS(IndividualExtras));
