import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import cn from 'classnames';
import useActions from 'hooks/useActions';
import useYoungDriverFeeModal from 'hooks/useYoungDriverFeeModal';
import MODAL from 'constants/modals';
import TOOLTIP_THEMES from 'constants/tooltipThemes';
import Button from 'components/Button';
import ToolTip from 'components/ToolTip';
import useAdditionalDriversList from 'hooks/useAdditionalDriversList';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import FormattedPrice from 'components/FormattedPrice';
import DriverListItem from 'components/AdditionalDrivers/DriverListItem';
import AddAdditionalDriverButton from 'components/AdditionalDrivers/AddAdditionalDriverButton';
import SpouseDriverList from 'components/AdditionalDrivers/SpouseDriverList';
// Modals
import LearnMoreAboutAdditionalDriverModal from 'components/modals/AdditionalDriverModals/LearnMoreAboutAdditionalDriverModal';
import AddAdditionalDriverModal from 'components/modals/AdditionalDriverModals/AddAdditionalDriverModal';
import AdditionalDriverDetailsModal from 'components/modals/AdditionalDriverModals/AdditionalDriverDetailsModal';
import RemoveDriverModal from 'components/modals/AdditionalDriverModals/RemoveDriverModal';
import EuYoungDriverFeeModal from 'components/modals/AdditionalDriverModals/EuYoungDriverFeeModal';
import { openModal } from "actions/modal/openModal";

/**
 * PostResAdditionalDrivers
 *
 * @param {object} props - React Props
 * @param {boolean} props.isSTCFlow - STC Flow flag
 * @param {boolean} props.submitFailed - STCReviewSelection's submit state to validate AdditionalDriver data on submit form
 *
 * @return {JSX}
 */
const PostResAdditionalDrivers = ({ isSTCFlow, submitFailed }) => {
  const openLearnMore = useActions(() => openModal(MODAL.LEARN_MORE_ABOUT_ADDITIONAL_DRIVER));
  const isIncrementalPricingEnabled = utils.config.getIsIncrementalPricingEnabled();
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);

  const {
    driversList,
    allowMoreDrivers,
    maxAdditionalDrivers,
    numberOfFreeDrivers,
    otherDriverCost,
    fees,
    rateType,
    totalPrice,
  } = useAdditionalDriversList();

  useYoungDriverFeeModal();

  // This is a bit "unusual" but is the only way to get a total price for each driver
  const otherDriverTotalCost = driversList[0]?.total_amount_view?.format;

  const additionalDriverPrice = isIncrementalPricingEnabled ? (
    <>
      <strong>
        <FormattedPrice>
          {`${otherDriverCost}/${utils.i18n(`vehicle_select_incremental_unit_${rateType}`)} `}
        </FormattedPrice>
      </strong>
      {/* The use of totalPrice here must be updated, as this should show the driver total and not reservation's AD fees total */}
      {otherDriverTotalCost && <FormattedPrice>({otherDriverTotalCost})</FormattedPrice>}
    </>
  ) : (
    <FormattedPrice>{otherDriverCost}</FormattedPrice>
  );

  return (
    <div className='post-res__content component-theme--light'>
      <div className='post-res__section-header'>
        <h3 className='post-res__section-title'>
          {utils.i18n('common_additional_drivers')}
          <span className='post-res__title__optional-indicator'>{utils.i18n('common_optional')}</span>
        </h3>
      </div>

      <p className='post-res__paragraph post-res__paragraph--bold'>
        {utils.i18n(
          'review_additional_drivers_description_1',
          [
            <Button
              key={0}
              linkText
              onClick={openLearnMore}
              className='post-res__link-text post-res__link-text--inline'>
              {utils.i18n('review_additional_drivers_description_learn_more')}
            </Button>,
          ],
          { jsx: true }
        )}
      </p>

      <SpouseDriverList />

      {otherDriverCost && (
        <p className='post-res__paragraph post-res__paragraph--margin-top'>
          {`${utils.i18n('included_additional_drivers_extra_name')}: `}
          {additionalDriverPrice}
        </p>
      )}

      {driversList?.length > 0 && (
        <>
          <hr className='post-res__divider' />

          <p className='post-res__paragraph post-res__paragraph--bold'>
            {utils.i18n('review_additional_drivers_count', [driversList.length])}
            <ToolTip theme={TOOLTIP_THEMES.REGULAR_SLATE} placement='top-start'>
              <label>{utils.i18n('additional_driver_tooltip')}</label>
            </ToolTip>
          </p>
          <p
            className={cn('post-res__paragraph', 'post-res__paragraph--margin-bottom', {
              'post-res__error-message': submitFailed,
            })}>
            {isSTCFlow
              ? utils.i18n('additional_driver_add_license_copy')
              : utils.i18n('additional_driver_oci_add_license_copy')}
          </p>
        </>
      )}

      {driversList.map((driver, idx) => (
        <DriverListItem
          key={`${driver.first_name}-${driver.last_name}`}
          driver={driver}
          cost={otherDriverCost}
          rateType={rateType}
          totalPrice={totalPrice}
          included={numberOfFreeDrivers - idx > 0}
          addDriverDetails
          fees={fees}
          isPostRes
          submitFailed={submitFailed}
        />
      ))}

      {allowMoreDrivers ? (
        <AddAdditionalDriverButton
          numDriversRemaining={Math.max(numberOfFreeDrivers - driversList.length, 0)}
          dtmTrackValue='post_res|action|add_driver'
          isSTCOrCustomPathFlow={isSTCFlow || isCustomPathReservation}
        />
      ) : (
        <p className='additional-drivers__max-added'>
          {utils.pluralHandler(
            maxAdditionalDrivers,
            'review_additional_drivers_reached_max_drivers_singular',
            'review_additional_drivers_reached_max_drivers_plural',
            false,
            maxAdditionalDrivers
          )}
        </p>
      )}

      <LearnMoreAboutAdditionalDriverModal />
      <AddAdditionalDriverModal />
      <AdditionalDriverDetailsModal />
      <RemoveDriverModal />
      <EuYoungDriverFeeModal />
    </div>
  );
};

PostResAdditionalDrivers.propTypes = {
  isSTCFlow: PropTypes.bool,
  submitFailed: PropTypes.bool,
};

PostResAdditionalDrivers.defaultProps = {
  isSTCFlow: false,
  submitFailed: false,
};

export default PostResAdditionalDrivers;
