import { connect } from 'react-redux';
import utils from 'utils';
import { getSupportContactInfo } from 'actions/content';
import { profileDataSelector, breakpointSelector } from 'selectors';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import ProfileAccount from './ProfileAccount';

const mapStateToProps = (state) => ({
  breakpoint: breakpointSelector(state),
  profileData: profileDataSelector(state, { first_name: true, memberNumber: true, profile_completion: true }),
});

const mapDispatchToProps = {
  openEditModal: () => openModal(MODAL.EDIT_ACCOUNT),
  openEmailInUseModal: () => openModal(MODAL.EMAIL_ALREADY_IN_USE_MODAL),
  getSupportContactInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ProfileAccount));
