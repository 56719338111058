import { connect } from 'react-redux';
import { actions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import SoldOutModal from './SoldOutModal';

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
  onDateChange: (timestamp, exchangeType) =>
    actions.reservations.initiateState.set('date', { timestamp, exchangeType }),
  onTimeChange: (timestamp, exchangeType) =>
    actions.reservations.initiateState.set('time', { timestamp, exchangeType }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(SoldOutModal));
