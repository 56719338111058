import React from 'react';
import { useSelector } from 'react-redux';
import { actions } from '@ehi/global-marketing-interface';
import PropTypes from 'prop-types';
import utils from 'utils';
import { i18n } from 'utils/i18n';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import RESERVATIONS from 'constants/reservations';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import ANALYTICS from 'constants/analytics';
import { ModalFooter } from 'components/Modal/ModalFooter';
import Modal from 'components/Modal';
import useActions from 'hooks/useActions';
import { setExpandedTab, setMobileExpanded } from 'actions/progressBar';
import { showSoldOutNearbyLocations, setLastValidLocationSearched } from 'actions/locationFinder';
import { breakpointSelector } from 'selectors/breakpoint';
import { getProgressBarExpandedTab } from 'selectors/reservationFlowSelectors';
import {
  getInitialPickupLocation,
  isInitiateDateTimeisChanged,
  dateTimeInitiateRequestValuesSelector,
} from 'selectors/progressBarSelectors';

const { EXCHANGE_TYPE_PICKUP, EXCHANGE_TYPE_RETURN } = RESERVATIONS;

import carWarning from 'images/icons/car-warning.svg';
import './styles/sold-out-modal.scss';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const soldOutImage = {
  src: carWarning,
  alt: i18n('sold_out_modal_image'),
  className: 'sold-out-modal__image',
};

/**
 * SoldOutModal
 * Modal that displays the sold out message for a specific location.
 *
 * @param {object} props - React Props
 *
 * @return {JSX} SoldOutModal component
 */
const SoldOutModal = ({ currentPage, onDateChange, onTimeChange }) => {
  const dispatchSetExpandedTab = useActions(setExpandedTab);
  const dispatchSetMobileExpanded = useActions(setMobileExpanded);
  const dispatchShowNearbyLocations = useActions(showSoldOutNearbyLocations);
  const dispatchLastValidLocationSearched = useActions(setLastValidLocationSearched);
  const cleanMessagesAction = useActions(actions.cleanMessages);
  const { isMobile } = useSelector(breakpointSelector);
  const expandedTab = useSelector(getProgressBarExpandedTab);
  const gmaPickupLocation = useSelector(getInitialPickupLocation);

  const getInitiateDateTimeisChanged = useSelector(isInitiateDateTimeisChanged);
  const getDateTimeInitiateRequestValuesSelector = useSelector(dateTimeInitiateRequestValuesSelector);

  const handleAdjustDateTime = (handleClose) => () => {
    // check if it's inside reservation flow (not on the booking widget)
    if (currentPage) {
      utils.goTo(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESERVATION_INFLOW), { hash: 'start' });
      cleanMessagesAction();
    }
    handleClose({ skipAnalytics: true });
  };

  const handleSeeNearbyLocations = (handleClose) => () => {
    const locationSelectPage = RESERVATIONS.RESFLOW_PATHS_CONFIG.location[0];

    // If it's not location select page, redirect to location select page
    if (currentPage !== locationSelectPage) {
      utils.goTo(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESERVATION_INFLOW), { hash: locationSelectPage });
    }
    // If it's inside reservation flow (not on the booking widget), on close modal should make sure it closes progress bar
    if (currentPage) {
      !!expandedTab && dispatchSetExpandedTab(null);
      !!expandedTab && isMobile && dispatchSetMobileExpanded(false);
      dispatchShowNearbyLocations(true);
      dispatchLastValidLocationSearched(gmaPickupLocation?.id);
    }
    // used to check if filter by available locations should be selected by default
    sessionStorage.setItem('soldOutLocation', true);
    handleClose({ skipAnalytics: true });
    // pickup/return datetime is different than from GBO, should update with GBO info
    // reset gmi > ui, to match what's in gbo
    if (getInitiateDateTimeisChanged && getDateTimeInitiateRequestValuesSelector) {
      const { pickupDate, pickupTime, returnDate, returnTime } = getDateTimeInitiateRequestValuesSelector;
      onDateChange(pickupDate, EXCHANGE_TYPE_PICKUP);
      onTimeChange(pickupTime, EXCHANGE_TYPE_PICKUP);
      onDateChange(returnDate, EXCHANGE_TYPE_RETURN);
      onTimeChange(returnTime, EXCHANGE_TYPE_RETURN);
    }
  };

  return (
    <Modal
      modalKey={MODAL.SOLD_OUT_MODAL}
      customClass='sold-out-modal'
      header={i18n('sold_out_modal_title')}
      theme={MODAL_THEMES.WHITE}
      showHeaderImg
      headerImg={soldOutImage}
      customActionOnClose={() => {
        cleanMessagesAction();
      }}
    >
      {({ handleClose }) => (
        <>
          <p className='modal-themed__paragraph sold-out-modal__description'>{i18n('sold_out_modal_description')}</p>

          <ModalFooter
            buttons={[
              {
                type: DECLINE,
                label: i18n('sold_out_adjust_date_time'),
                handler: handleAdjustDateTime(handleClose),
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(MODAL.SOLD_OUT_MODAL),
                    ANALYTICS.ADJUST_DATE_TIME
                  ),
                extraLargeButton: true,
              },
              {
                type: ACCEPT,
                label: i18n('sold_out_see_nearby_locations'),
                handler: handleSeeNearbyLocations(handleClose),
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(MODAL.SOLD_OUT_MODAL),
                    ANALYTICS.SEE_NEARBY_LOCATIONS
                  ),
                extraLargeButton: true,
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

SoldOutModal.propTypes = {
  currentPage: PropTypes.string,
};

export default SoldOutModal;
