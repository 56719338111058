import PropTypes from 'prop-types';

export const propTypes = {
  bundles: PropTypes.array,
  code: PropTypes.string,
  vehicleImages: PropTypes.object,
  includedBundlesExtras: PropTypes.object,
  makeModelOrSimilarText: PropTypes.string,
  isPayLater: PropTypes.bool,
};
