import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Button from 'components/Button';
import Picture from 'components/Picture';
import ASSETS from 'constants/assets';

/**
 * STCConfirmationStepsList - Skip the Counter Steps list
 * @param {object} props - React Props
 * @param {function} props.openViewDirectionsLotModal - Trigger to open the wayfinding modal
 * @param {object} props.carClassDetails - Car class details object
 * @return {JSX}
 */
const STCConfirmationStepsList = ({ openViewDirectionsLotModal, carClassDetails, noImg }) => (
  <div className='skip-the-counter-confirmation__steps-list-container'>
    {!noImg && (
      <Picture
        className='skip-the-counter-confirmation__steps-list-image'
        src={ASSETS.STC_CONTENT_NO_COUNTER}
        alt={utils.i18n('stc_pickup_instructions')}
      />
    )}

    <div className='skip-the-counter-confirmation__steps-list-text'>
      <span className='skip-the-counter-confirmation__steps-list-text-title'>{utils.i18n('stc_steps_title')}</span>
      <ol className='skip-the-counter-confirmation__steps-list'>
        <li>
          <span>
            <span className='skip-the-counter-confirmation__sub-heading'>
              {utils.i18n('stc_no_need_counter_kiosk')}
            </span>
            <p className='skip-the-counter-confirmation__section-text'>{utils.i18n('stc_proceed_directly_lot')}</p>

            <Button link onClick={openViewDirectionsLotModal} className='skip-the-counter-confirmation__link'>
              {utils.i18n('stc_view_directions_lot')}
            </Button>
          </span>
        </li>

        <li>
          <span>
            <span className='skip-the-counter-confirmation__sub-heading'>
              {utils.i18n('stc_select_vehicle_matches')}
            </span>
            <p className='skip-the-counter-confirmation__section-text'>
              {utils.i18n('stc_vehicle_type', [carClassDetails?.name])}
            </p>
          </span>
        </li>

        <li>
          <span className='skip-the-counter-confirmation__sub-heading'>{utils.i18n('stc_keys_vehicle_exit_gate')}</span>
        </li>

        <li>
          <span>
            <span className='skip-the-counter-confirmation__sub-heading'>{utils.i18n('stc_show_pickup_ticket')}</span>
            <p className='skip-the-counter-confirmation__section-text'>{utils.i18n('stc_printed_or_saved_ticket')}</p>
          </span>
        </li>
      </ol>
    </div>
  </div>
);

STCConfirmationStepsList.propTypes = {
  openViewDirectionsLotModal: PropTypes.func,
  carClassDetails: PropTypes.object,
  noImg: PropTypes.bool,
};

export default STCConfirmationStepsList;
