import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';

export const propTypes = {// Container props
  one_way_rental: PropTypes.bool,
  pickup_location: PropTypes.object,
  return_location: PropTypes.object,
  hydrateUiReservationsState: PropTypes.func.isRequired,
  isTrueModify: PropTypes.bool,};

export const defaultProps = setDefaultProps({});
