import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { setAriaHiddenByElementAttribute } from 'utils/accessibility/aria';
import { LOCATIONS, IDS, ANALYTICS } from 'constants';
import cn from 'classnames';
import FieldControl from 'components/Form/FieldControl';
import Button from 'components/Button';
import { loadingOverlay } from 'utils/dom';
import LocationSuggestion from './LocationSuggestion';

const LOCATION_TYPE_LABELS = {
  airports: utils.i18n('location_search_suggestions_airports'),
  portsOfCall: utils.i18n('location_search_suggestions_portsOfCall'),
  railStations: utils.i18n('location_search_suggestions_railStations'),
  cities: utils.i18n('location_search_suggestions_cities'),
  branches: utils.i18n('location_search_suggestions_branches'),
};
const isIosSafari = () =>
  /iPhone/.test(navigator.userAgent) && /Mobile/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent);
/**
 * LocationSearch - Location search component
 * @extends Component
 */
class LocationSearch extends Component {
  state = {
    searchTerm: '',
    isActive: false,
    error: null,
    geoId: null,
    focusedItemIndex: -1,
    isDragging: false,
    handleFocus: true,
  };

  inputRef = React.createRef();

  /**
   * componentDidMount - fetches recent search history from local storage
   *
   */
  componentDidMount() {
    const { fetchRecentSearches } = this.props;
    utils.safeExecute(fetchRecentSearches);
    window.addEventListener('touchmove', this.setIsDraggingState(true));
    window.addEventListener('touchstart', this.setIsDraggingState(false));

    // Safari uses [back-forward cache] that shows the cached page when the user clicks the back button
    // so, we need to hide the loading overlay when the page is loaded from the cache
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        loadingOverlay(false);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.setIsDraggingState(true));
    window.removeEventListener('touchstart', this.setIsDraggingState(false));
  }

  moveOverlay = () => {
    const { id } = this.props;
    document.getElementById('dummyInput').focus();
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 0);
  };

  setIsDraggingState = (bol) => () => {
    this.setState({
      isDragging: bol,
    });
  };

  componentDidUpdate(prevProps) {
    const { input, selectLocation } = this.props;
    if (prevProps.selectLocation && !selectLocation) {
      input.onChange('');
    }
    if (!prevProps.selectLocation && selectLocation) {
      input.onChange(selectLocation);
    }
  }

  handleClickOutside = () => {
    const { input, selectOnBlur } = this.props;
    const { searchTerm, isActive } = this.state;

    // do not process scroll lock toggle to avoid scrolling to top when not intended
    if (!isActive) {
      return;
    }

    this.setState({ isActive: false });
    utils.scrollLock({ toggleValue: false });
    input.onBlur();
    if (searchTerm.length > 2 && selectOnBlur) {
      this.autoSelectLocation();
    }
  };

  /**
   * handleOnFocus - Activates the component
   */
  handleOnFocus = () => {
    const { breakpoint, input, formId } = this.props;
    if (this.state.handleFocus) {
      this.setState({ isActive: true });
      if (breakpoint.isTabletOrMobile) {
        if (isIosSafari()) {
          this.setState({ handleFocus: false });
          setTimeout(() => {
            this.moveOverlay();
          }, 0);
        } else {
          utils.dom.onFocusScrollToTop(formId, breakpoint.isMobile);
          this.inputRef.current.focus();
        }
      }
      input.onFocus();
    } else {
      this.setState({ handleFocus: true });
    }
  };

  handleOnBlur = (e) => {
    if (this.state.handleFocus) {
      const { id, breakpoint, input } = this.props;

      // IE11 does not support relatedTarget on blur(correctly), therefore:
      const relatedTarget =
        e.nativeEvent.relatedTarget || document.activeElement === document.getElementById(id)
          ? document.activeElement
          : null;

      if (!breakpoint.isTabletOrMobile && !relatedTarget) {
        this.setState({ isActive: false });
        utils.scrollLock({ toggleValue: false });
        input.onBlur();
      }
    }
  };

  /**
   * handleOnChange - Initiates search and terminates geo location request
   *
   * @param {Event} e DOM event
   */
  handleOnChange = (e) => {
    const { value: searchTerm } = e.target;
    if (this.state.geoId) {
      navigator.geolocation.clearWatch(this.state.geoId);
    }
    this.setState({ error: null, geoId: null, searchTerm /* , isActive: !(!searchTerm || searchTerm.length <= 2) */ });
    this.performSearch(searchTerm);
    if (searchTerm.length < 3) {
      this.props.clearTextQueryLocations();
    }
  };

  /**
   * handleLocationButton - Initiate user geo location search
   */
  handleLocationButton = () => {
    const geoId = navigator.geolocation.getCurrentPosition(this.handleLocationSuccess, this.handleLocationError, {
      enableHighAccuracy: false,
      maximumAge: 30000,
      timeout: 27000,
    });
    this.setState({ searchTerm: '', geoId });

    // Analytics
    utils.analytics.interaction(
      ANALYTICS.RESERVATION,
      this.props.id === IDS.inputPickupLocation ? ANALYTICS.PICKUP : ANALYTICS.RETURN,
      ANALYTICS.USE_CURRENT_LOCATION
    );
  };

  /**
   * handleLocationSuccess - Dispatches action that saves user location information in session and redirects user to storefinder
   *
   * @param {Object} position User location information
   */
  handleLocationSuccess = (position) => {
    const { type, redirectToLocationFinder } = this.props;
    const { latitude, longitude } = position.coords;
    const location = {
      name: utils.i18n('location_search_user_location_label'),
      gps: { latitude, longitude },
      type: LOCATIONS.TYPE_GEOLOCATION,
    };

    navigator.geolocation.clearWatch(this.state.geoId);
    this.setState({ geoId: null });
    if (type === LOCATIONS.LOCATION_SEARCH_TYPES.STANDALONE) {
      redirectToLocationFinder(location);
    } else {
      this.onSelectLocation(location);
    }
  };

  /**
   * handleLocationError - Geo location error handler - notifies user of a failed location detection attempt
   */
  handleLocationError = () => {
    navigator.geolocation.clearWatch(this.state.geoId);
    this.setState({ isActive: true, geoId: null, error: utils.i18n('location_search_locationError') });
    this.props.input.onFocus();
  };

  /**
   * performSearch - Dispatches debounced location search action
   *
   * @param {String} searchTerm Location search query
   */
  performSearch = utils.gmi.debounce((searchTerm) => {
    utils.safeExecute(this.props.locationsTextQuery, searchTerm);
  }, 250);

  /**
   * handleCloseCta - Deactivates fullscreen view in mobile breakpoint
   *
   * @returns {type} Description
   */
  handleCloseCta = () => {
    navigator.geolocation.clearWatch(this.state.geoId);
    this.setState({ isActive: false, searchTerm: '', geoId: null, error: null });
    utils.scrollLock({ toggleValue: false });
    this.props.input.onBlur();
  };

  /**
   * handleKeyDown - Handles keyboard interaction for accessibility
   *
   * @param {Event} e
   */
  handleKeyDown = (e) => {
    const key = utils.accessibility.formatKeys(e);
    const currentIndex = this.state.focusedItemIndex;
    if (key.arrowUpOrDown) {
      e.preventDefault();
      const newIndex = key.arrowUp ? currentIndex - 1 : currentIndex + 1;
      if (newIndex >= 0 && newIndex <= Object.values(this.props.locationsTextQueryResults).flat(1).length) {
        this.setState({ focusedItemIndex: newIndex });
      }
    } else if (key.escape) {
      this.setState({ focusedItemIndex: null, isActive: false });
      this.props.input.onBlur();
    } else if (key.tab) {
      const { findTabbableDescendants } = utils.accessibility;
      const node = this.clickOutsideRef.current;
      const focusables = findTabbableDescendants(node);
      const lastFocusable = focusables[focusables.length - 1];
      if (lastFocusable === document.activeElement) {
        this.setState({ focusedItemIndex: null, isActive: false });
        this.props.input.onBlur();
      }
    }
  };

  /**
   * resetFocusedState - Returns focus to the input field
   */
  resetFocusedState = () => {
    this.setState({ focusedItemIndex: -1 });
    this.inputRef.current.focus();
    this.props.input.onFocus();
  };

  /**
   * onSelectLocation - Handles location select - depending on this component type either redirects user to appropriate destination or passes location object to container
   *
   * @param {Object} location Location object from Solr
   */
  onSelectLocation = (location) => {
    const { locationsTextQueryResults, recentSearchItems, onSelectLocation, input } = this.props;
    const { searchTerm } = this.state;

    this.setState({ isActive: false, searchTerm: '', geoId: null, error: null });
    // input.onChange(location);
    // Trigger onSelectLocation method provided by the wrapper with component type specific handling
    onSelectLocation(location, input);
    this.props.clearTextQueryLocations();
    this.props.input.onBlur();

    // Analytics
    utils.analytics.interaction(
      ANALYTICS.RESERVATION,
      this.props.id === IDS.inputPickupLocation ? ANALYTICS.PICKUP : ANALYTICS.RETURN,
      location.name,
      {
        numberOfResults: Object.values(locationsTextQueryResults).flat(1).length || recentSearchItems?.length,
        searchTerm,
      }
    );
  };

  autoSelectLocation = () => {
    const { locationsTextQueryResults } = this.props;
    const { searchTerm } = this.state;
    const airportMatch = locationsTextQueryResults?.airports?.find(
      ({ airport_code }) => airport_code.toLowerCase() === searchTerm.toLowerCase()
    );
    if (airportMatch) {
      this.onSelectLocation(airportMatch);
    } else {
      const city = locationsTextQueryResults?.cities?.[0];
      city && this.onSelectLocation(city);
    }
  };

  handleUnsetLocation = () => {
    this.setState({ focusedItemIndex: -1 });
    this.props.unsetLocation();
    this.props.input.onChange('');
  };

  sortLocationsKeys = (keys) => {
    const locationSortWeights = {
      airports: 1,
      railStations: 2,
      portsOfCall: 3,
      cities: 4,
    };
    return keys.sort((a, b) => locationSortWeights[a] - locationSortWeights[b]);
  };

  ariaDescribedAttribute = (isSuggestionsVisible, showGuidedError) => {
    if (isSuggestionsVisible && showGuidedError) {
      return { 'aria-describedby': 'locationerror' };
    }
    return {};
  };

  render() {
    const {
      id,
      name,
      label,
      locationsTextQueryResults = {},
      breakpoint,
      selectedLocation,
      toggleOneWay,
      isOneWay,
      recentSearchItems,
      clearRecentSearches,
      isFetching,
      showGuidedError,
      renderGuidedError,
    } = this.props;

    const { searchTerm, isActive, error, geoId, focusedItemIndex, isDragging } = this.state;
    const isSuggestionsVisible = isActive; // && !isFetching;

    let renderedItemCount = -1;
    let emptyMessage;

    if (!error && Object.keys(locationsTextQueryResults).length < 1 && searchTerm.length <= 2) {
      emptyMessage = utils.i18n('location_search_startSearchMessage');
    } else if (error !== null) {
      emptyMessage = error;
    } else if (Object.keys(locationsTextQueryResults).length < 1 && searchTerm.length > 2 && !isFetching) {
      emptyMessage = utils.i18n('location_search_emptyMessage');
    }

    const selectedLocationButtonLabel =
      selectedLocation?.type === LOCATIONS.TYPE_GEOLOCATION
        ? utils.i18n('location_search_user_location_label')
        : selectedLocation?.name;

    /**
     * For Accessibility
     * Set aria-hidden for elements behind id='locationSearch' element.
     * aria-hidden = true when id='locationSearch' is selected
     * aria-hidden = false when id='locationSearch' is in its deafult state
     */
    setAriaHiddenByElementAttribute(
      ['div.booking-widget__input-group', 'div.aem-hero', 'div.title__container--suffix'],
      isSuggestionsVisible
    );

    return (
      <div ref={this.clickOutsideRef} className='locationSearch' onKeyDown={this.handleKeyDown}>
        {toggleOneWay && isOneWay && breakpoint.isMobile && (
          <Button
            plain
            type='button'
            onClick={toggleOneWay}
            title={utils.i18n('location_search_oneway_toggle')}
            aria-label={utils.i18n('location_search_oneway_toggle')}
            aria-expanded={isOneWay}
            data-dtm-track='button|reservation_widget|different_return'
            className={cn('locationSearch__onewayToggle', {
              'locationSearch__onewayToggle--remove': isOneWay,
            })}
          ></Button>
        )}
        <input
          id={id}
          name={name || id}
          type='text'
          aria-label={label}
          ref={this.inputRef}
          value={searchTerm}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          disabled={selectedLocation !== null}
          role='combobox'
          aria-autocomplete='list'
          aria-expanded={isSuggestionsVisible}
          aria-owns='locationsearch'
          aria-required='true'
          aria-invalid={showGuidedError}
          aria-activedescendant={location.id}
          {...this.ariaDescribedAttribute(isSuggestionsVisible, showGuidedError)}
        />
        {breakpoint.isTabletOrMobile && isActive && (
          <Button
            plain
            type='button'
            className='locationSearch__closeCta'
            onClick={this.handleCloseCta}
            aria-label={utils.i18n('location_search_close_cta')}
          >
            {utils.i18n('location_search_close_cta')}
          </Button>
        )}
        {selectedLocation && (
          <Button
            plain
            type='button'
            className='locationSearch__selectedLocation'
            onClick={this.handleUnsetLocation}
            aria-label={utils.i18n('location_search_remove_selected_location', [selectedLocationButtonLabel])}
          >
            <span>{selectedLocationButtonLabel}</span>
          </Button>
        )}
        {geoId !== null && (
          <span className='locationSearch__fetch__label animated-loader__after'>
            {utils.i18n('location_search_fetch_label')}
          </span>
        )}
        {toggleOneWay && !(isOneWay && breakpoint.isMobile) && !(isSuggestionsVisible && breakpoint.isMobile) && (
          <Button
            plain
            type='button'
            onClick={toggleOneWay}
            className={cn('locationSearch__onewayToggle', { 'locationSearch__onewayToggle--remove': isOneWay })}
            data-dtm-track='button|reservation_widget|different_return'
            aria-expanded={isOneWay}
            aria-label={utils.i18n('location_search_oneway_toggle')}
          >
            {utils.i18n('location_search_oneway_toggle')}
          </Button>
        )}
        {isSuggestionsVisible && (
          <div className='locationSearch__results component-theme--light'>
            {showGuidedError && (
              <p role='alert' id='locationerror' className='locationSearch__results__error-message'>
                {renderGuidedError()}
              </p>
            )}
            <ul id='locationsearch' role='listbox'>
              {emptyMessage && <li className='locationSearch__results__empty-message'>{emptyMessage}</li>}
              {emptyMessage && recentSearchItems.length > 0 && (
                <li className='locationSearch__results__recentSearches locationSearch__results__category'>
                  <h6 id='locationsuggestion' className='locationSearch__results__category__label'>
                    {utils.i18n('location_search_suggestions_recent')}
                    {breakpoint.isDesktop && (
                      <Button
                        plain
                        type='button'
                        className='locationSearch__results__recentSearches__link'
                        data-dtm-track='button|res_widget|clear_recent_searches'
                        aria-label={utils.i18n('location_search_suggestions_clear_recent')}
                        onClick={clearRecentSearches}
                      >
                        {utils.i18n('location_search_suggestions_clear_recent')}
                      </Button>
                    )}
                  </h6>
                  <ul
                    className='locationSearch__results__category__items'
                    role='group'
                    aria-labelledby='locationsuggestion'
                  >
                    {recentSearchItems.map((location) => {
                      renderedItemCount += 1;
                      return (
                        <LocationSuggestion
                          key={location.id || location.name}
                          location={location}
                          selectLocation={this.onSelectLocation}
                          isFocused={focusedItemIndex === renderedItemCount}
                          resetFocusedState={this.resetFocusedState}
                          input={this.props.input}
                        />
                      );
                    })}
                  </ul>
                  {breakpoint.isTabletOrMobile && (
                    <Button
                      plain
                      type='button'
                      className='locationSearch__results__recentSearches__link'
                      data-dtm-track='button|res_widget|clear_recent_searches'
                      aria-label={utils.i18n('location_search_suggestions_clear_recent')}
                      onClick={clearRecentSearches}
                    >
                      {utils.i18n('location_search_suggestions_clear_recent')}
                    </Button>
                  )}
                </li>
              )}
              {!error &&
                this.sortLocationsKeys(Object.keys(locationsTextQueryResults)).map((locationKey) => (
                  <li
                    className={`locationSearch__results__category locationSearch__results__category--${locationKey}`}
                    key={locationKey}
                  >
                    <h6 className='locationSearch__results__category__label' id={`cat-${locationKey}`}>
                      {LOCATION_TYPE_LABELS[locationKey]}
                    </h6>
                    <ul
                      className='locationSearch__results__category__items'
                      role='group'
                      aria-labelledby={`cat-${locationKey}`}
                    >
                      {locationsTextQueryResults[locationKey].map((location) => {
                        renderedItemCount += 1;
                        return (
                          <LocationSuggestion
                            key={location.id || location.name}
                            location={location}
                            locationKey={locationKey}
                            selectLocation={this.onSelectLocation}
                            isFocused={focusedItemIndex === renderedItemCount}
                            resetFocusedState={this.resetFocusedState}
                            isDragging={isDragging}
                            input={this.props.input}
                          />
                        );
                      })}
                    </ul>
                  </li>
                ))}
              {'geolocation' in navigator && (
                <li className='locationSearch__results__geolocation'>
                  <Button
                    plain
                    type='button'
                    className='locationSearch__results__geolocation__button link'
                    aria-label={utils.i18n('location_search_geolocation_cta')}
                    onClick={this.handleLocationButton}
                  >
                    {utils.i18n('location_search_geolocation_cta')}
                  </Button>
                </li>
              )}
            </ul>
          </div>
        )}
        <div style={{ height: '0', overflow: 'hidden' }}>
          <input id='dummyInput' />
        </div>
      </div>
    );
  }
}

const LocationSearchWithClickOutside = utils.withClickOutside(LocationSearch);

const isResflow = utils.isReservationFlow();

const LocationSearchField = (props) => (
  <FieldControl
    className={cn('location-search-control', {
      'location-search-control__true-modify': props.isTrueModify && isResflow,
    })}
    name={props.name || props.id}
    {...props}
    fill
    type='text'
  >
    <LocationSearchWithClickOutside {...props} />
  </FieldControl>
);

LocationSearch.propTypes = {
  locationsTextQueryResults: PropTypes.object,
  selectedLocation: PropTypes.object,
  locationsTextQuery: PropTypes.func,
  setLocation: PropTypes.func,
  unsetLocation: PropTypes.func,
  redirectToLocationFinder: PropTypes.func,
  resolveBranchURL: PropTypes.func,
  toggleOneWay: PropTypes.func,
  location_search_return_placeholder: PropTypes.string,
  isOneWay: PropTypes.bool,
  type: PropTypes.string,
  clearTextQueryLocations: PropTypes.func.isRequired,
  breakpoint: PropTypes.object,
  selectOnBlur: PropTypes.bool.isRequired,
  isLocationWithNoVehiclesAvailable: PropTypes.bool,
};

LocationSearch.defaultProps = {
  selectedLocation: null,
  type: 'STANDALONE',
};

export default LocationSearchField;
