import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';

/**
 * ProfileProgressIndicator - This component shows to the authenticated user
 * a visual representation of how much has been achieved on filling her/his/their profile.
 *
 * @param {object} props - React Props
 * @param {string} props.profileData - Authenticated alamo insider user's profile data
 * @param {string} props.profileData.profile_completion - GBO information on how much user's profile is complete in integer percentage
 * @return {JSX}
 */

const ProfileProgressIndicator = ({ profileData }) => {
  const profileProgressPercentage = profileData?.profile_completion || 80;
  // This is just setup to add a visual effect of the percentage number increasing while the bar is filled via css
  const [stateProgress, setProgress] = useState(0);
  useEffect(
    () => {
      let timer;

      if (profileProgressPercentage > stateProgress) {
        timer = setTimeout(() => {
          setProgress(stateProgress + 5);
        }, 150);
      }

      // On component unmount the timer have to be cleared out to prevent any possible
      // memory leak.
      return function cleanup() {
        clearTimeout(timer);
      };
    },
    [stateProgress, profileData]
  );

  const circleBgClassNames = cn('profile-progress-indicator__progress-background', {
    'profile-progress-indicator__progress-background--completed': stateProgress === 100,
  });

  return (
    <div className='profile-progress-indicator'>
      <div aria-hidden='true' className='profile-progress-indicator__progress-area'>
        <svg
          focusable='false'
          className='profile-progress-indicator__progress-circle'
          viewBox='0 0 100 100'
          width='67'
          height='67'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'>
          <circle className={circleBgClassNames} cx='50' cy='50' r='39' />
          <circle
            className={`profile-progress-indicator__progress-bar profile-progress-indicator__progress-bar--${profileProgressPercentage}`}
            cx='50'
            cy='50'
            r='48'
            fill='none'
          />
        </svg>
        <span className='profile-progress-indicator__number'>{`${stateProgress}%`}</span>
      </div>
      <div className='profile-progress-indicator__status-info'>
        <h4 className='profile-progress-indicator__title'>{utils.i18n('profile_progress_indicator_title')}</h4>
        <p className='profile-progress-indicator__percentage-complete'>
          {utils.i18n('profile_progress_indicator_status', [profileProgressPercentage])}
        </p>
      </div>
    </div>
  );
};

ProfileProgressIndicator.propTypes = {
  profileData: PropTypes.shape({
    profile_completion: PropTypes.number,
  }),
};

export default ProfileProgressIndicator;
