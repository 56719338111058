import { connect } from 'react-redux';
import utils from 'utils';
import { setContractNumber, setPartnerRewardProgram, setPartnerRewardProgramMemberId } from 'actions/bookingWidget';
import { initiateReservationFlow } from "actions/reservation/initiateReservationFlow";
import FrequentTravelerRecoveryFeeModalContent from './FrequentTravelerRecoveryFeeModalContent';

const mapDispatchToProps = {
  handleInitiate: () => initiateReservationFlow({ freshSession: true }),
  setContractNumber,
  setPartnerRewardProgram,
  setPartnerRewardProgramMemberId,
};

export default connect(
  null,
  mapDispatchToProps
)(utils.toJS(FrequentTravelerRecoveryFeeModalContent));
