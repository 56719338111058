import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Picture from 'components/Picture';

const STCAddToWallet = ({ srcImage, show, handleClick }) => {
  if (show) {
    return (
      <Button onClick={handleClick} link>
        <Picture src={srcImage} className='skip-the-counter-confirmation__ticket-modal-image' />
      </Button>
    );
  }

  return null;
};

STCAddToWallet.propTypes = {
  srcImage: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

export default STCAddToWallet;
