import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isMasked } from 'utils/validation';
import useActions from 'hooks/useActions';
import { LOOK_UP_BY_TYPE } from 'components/ViewModifyCancelApp/data/constants';
import { formatPhoneNumber } from 'utils/profile';
import ConfirmationLookUpBy from './ConfirmationLookUpBy';
import {
  getUserData,
  getDeeplinkDeliveryOptionSelector,
  getRentalAgreement,
  getSelectedCountry,
} from '../../redux/selectors';
import { onLookUpBySearch, verifyByConfirmationNumber } from '../../redux/actions';

const ConfirmationLookUpByContainer = (props) => {
  const { lookupType } = props;

  const userData = useSelector(getUserData);
  const agreementData = useSelector(getRentalAgreement);
  const selectedCountry = useSelector(getSelectedCountry);
  const deeplinkActionTypeSelector = useSelector(getDeeplinkDeliveryOptionSelector);
  const searchAction = useActions(onLookUpBySearch);
  const verifyByConfirmationNumberAction = useActions(verifyByConfirmationNumber);

  let lookupTypeState = lookupType;
  let renterInfo = userData || {};

  if (lookupType === LOOK_UP_BY_TYPE.CONF_NUMBER) {
    lookupTypeState = deeplinkActionTypeSelector?.toLowerCase();
    renterInfo = agreementData?.renter || {};
  }

  const { phone: { phone_number, phone_country_code } = {}, email_address } = renterInfo;

  let formattedPhoneNumber;
  if (selectedCountry?.currentDialingCode) {
    formattedPhoneNumber = formatPhoneNumber(phone_number, selectedCountry, false);
  } else if (isMasked(phone_number)) {
    formattedPhoneNumber = phone_number;
  } else {
    formattedPhoneNumber = phone_country_code ? `${phone_country_code || ''}${' '}${phone_number}` : phone_number;
  }

  const isConfirmNumberLookupType = lookupType === LOOK_UP_BY_TYPE.CONF_NUMBER;

  const combinedProps = {
    ...props,
    email: email_address,
    formatedPhone: formattedPhoneNumber,
    userData,
    searchAction,
    verifyByConfirmationNumberAction,
    lookupTypeState,
    isConfirmNumberLookupType,
  };

  return <ConfirmationLookUpBy {...combinedProps} />;
};

ConfirmationLookUpByContainer.propTypes = {
  lookupType: PropTypes.string,
};

export default ConfirmationLookUpByContainer;
