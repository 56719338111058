import React, { Component } from 'react';
import cn from 'classnames';
import { ANALYTICS, GLOBAL, TOOLTIP_THEMES, COMPONENTS, SESSION_STORAGE } from 'constants';
import DISCOUNTS from 'constants/discounts';
import RESERVATIONS from 'constants/reservations';
import utils from 'utils';
import Storage from 'utils/storageManager';
import Picture from 'components/Picture';
import Button from 'components/Button';
import FormattedPrice from 'components/FormattedPrice';
import IconAlert from 'components/IconAlert';
import VehicleDetailsIconList from 'components/VehicleDetailsIconList';
import { propTypes } from './vehicleSelectDetailsCardPropTypes';
import VehicleSelectDetailsCardExpansion from '../VehicleSelectDetailsCardExpansion';
import PriceComponent from './PriceComponent';

const { PREPAY_CODE } = RESERVATIONS;

const { LIMITED_INVENTORY } = COMPONENTS.INDIVIDUAL_EXTRA_ALERT;

export default class VehicleSelectDetailsCard extends Component {
  static propTypes = propTypes;

  state = {
    expanded: false,
  };

  handleExpandDetails = () => {
    const { getCarClassDetails, vehicle, payLaterRates, loadingDetails } = this.props;

    if (!payLaterRates && !loadingDetails) {
      getCarClassDetails(vehicle.code);
    }

    // Analytics - fire custom "drawer" event (push to interaction object)
    utils.analytics.interaction(
      ANALYTICS.DRAWER, // type
      ANALYTICS.CAR_CLASS_DETAILS, // key
      vehicle?.code // value
    );

    // Update state
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  handleSelectCarClass = ({ prepay = false } = {}) => {
    const {
      selectCarClass,
      vehicle,
      showTermsOfUseModal,
      showLimitedInventoryVehicleModal,
      showCouponDoesNotApplyModal,
      reservationCoupons,
      isTrueModify,
      vehicleTermsContent,
    } = this.props;

    const { terms_and_conditions_required, status, code, coupons } = vehicle;

    const showLimitedInventoryIcon = status.indexOf(GLOBAL.LIMITED_INVENTORY_CAR_CODE) !== -1;
    // Should show modal if there is no coupon applied for the vehicle or if it's prepay
    const shouldShowCouponModal =
      (reservationCoupons.length > 0 && !coupons) || (reservationCoupons.length > 0 && prepay);

    // prepay_selected line is present beforehand with its default "false" value,
    // to track analytics events, the issue is that it doesn't change to "true" soon enough when a user selects prepay.
    // so speeding up the availability of the correct prepay_selected value, when a user selects prepay/postpay
    const { _analytics } = window;
    const analyticsReservationObject = _analytics?.gbo?.reservation;
    if (analyticsReservationObject) {
      _analytics.gbo.reservation = {
        ...analyticsReservationObject,
        prepay_selected: prepay.toString(),
      };
    }

    //  If user selects vans that seat 15 or 12, pop modal to show terms of use
    if (terms_and_conditions_required) {
      const content = vehicleTermsContent;
      showTermsOfUseModal(code, content, prepay);
    }

    //  If user selects a limited inventory item we pop the modal informing them of this
    showLimitedInventoryIcon && showLimitedInventoryVehicleModal(code, prepay);

    //  We only want to select the car class if we don't want to pop a modal first
    //  selectCarClass is passed as the final action for the modals.
    if (!terms_and_conditions_required && !showLimitedInventoryIcon) {
      shouldShowCouponModal
        ? showCouponDoesNotApplyModal((continueWithoutCoupon) => {
            continueWithoutCoupon && selectCarClass({ code, prepay });
          })
        : selectCarClass({ code, prepay });
    }

    if (isTrueModify) {
      Storage.SessionStorage.set(SESSION_STORAGE.ADD_FEATURED_CAR_CLASS, true);
    }
  };

  handleSelectCarClassPrePay = () => {
    this.handleSelectCarClass({ prepay: true });
  };

  showTaxesFeesModal = () => {
    const { payLaterRates, prePayRates, showTaxesFeesModal, vehicle } = this.props;
    let feesArray;
    let taxesFeesTotal;
    const prePayPriceSummary = prePayRates?.price_summary;
    const payLaterPriceSummary = payLaterRates?.price_summary;
    const prePayFee = prePayPriceSummary?.payment_line_items?.FEE;
    const payLaterFee = payLaterPriceSummary?.payment_line_items?.FEE;

    if (prePayRates && payLaterRates) {
      feesArray = [prePayFee, payLaterFee];
      taxesFeesTotal = [utils.getTaxesFeesTotal(prePayPriceSummary), utils.getTaxesFeesTotal(payLaterPriceSummary)];
    } else if (payLaterRates && !prePayRates) {
      feesArray = [null, payLaterFee];
      taxesFeesTotal = [null, utils.getTaxesFeesTotal(payLaterPriceSummary)];
    } else if (prePayRates && !payLaterRates) {
      feesArray = [prePayFee];
      taxesFeesTotal = [utils.getTaxesFeesTotal(prePayPriceSummary), null];
    }

    const header = utils.i18n('taxes_fees_modal_header', [vehicle.name]);
    showTaxesFeesModal(header, feesArray, taxesFeesTotal);
  };

  renderExpandButton = () => {
    const expandButtonClass = cn('link vehicle-select-details__expand-button', {
      'vehicle-select-details__expand-button--expanded': this.state.expanded,
    });
    return (
      <Button
        plain
        type='button'
        aria-expanded={this.state.expanded}
        className={expandButtonClass}
        onClick={this.handleExpandDetails}
      >
        {utils.i18n('vehicle_select_expand_button')}
      </Button>
    );
  };

  renderCouponTooltip = (children) => (
    <div className='vehicle-select-details__coupon-tooltip-row'>
      <div className='vehicle-select-details__coupon-tooltip-container'>
        <div className='vehicle-select-details__coupon-tooltip'>{children}</div>
      </div>
    </div>
  );

  render() {
    const {
      vehicle,
      breakpoint,
      payLaterRates,
      prePayRates,
      discountContractDetails,
      showConvertedTotalsModal,
      selected,
      prepaySelected,
      prepayExistsInResults,
      isTrueModify,
      learnAboutPrepayAndPayLaterModal,
      showGuaranteedResModal,
      isEuropeanUnionCountry,
      isLastMinuteSpecials,
      isPlanAheadSpecials,
      vehicleSearchFilters,
      isPayTypeSelected,
      destinationCurrencyCode,
      destinationCurrencySelected,
      showFeaturedVehicle,
      hasDefaultLoyaltyContractProfile,
    } = this.props;
    const { expanded } = this.state;
    const {
      name,
      make_model_or_similar_text,
      features,
      people_capacity,
      luggage_capacity,
      images,
      two_or_four_door_text,
      charges,
      price_differences,
      mileage_info,
      code,
      status,
      guaranteed_vehicle,
      coupons,
      upgrade_car_class_name,
      vehicle_attribute,
    } = vehicle;

    const transmission = utils.getTransmissionDescriptionFromFeaturesArray(features);
    const carImage = utils.getImageFromImagesObject(images, { width: '200' });

    // TODO: may want to cleanup logic to get rates and savings variables - maybe move to selectors
    const payLaterTotal = charges?.PAYLATER?.total_price_payment;
    const payLaterTotalConverted = charges?.PAYLATER?.total_price_view;
    const payLaterMainRate = charges?.PAYLATER?.rates?.[0];
    const payLaterIncrementalPricingRates = payLaterRates?.price_summary?.payment_line_items?.VEHICLE_RATE;
    const prePayTotal = charges?.PREPAY?.total_price_payment;
    const prePayTotalConverted = charges?.PREPAY?.total_price_view;
    const prePayMainRate = charges?.PREPAY?.rates?.[0];
    const prePayIncrementalPricingRates = prePayRates?.price_summary?.payment_line_items?.VEHICLE_RATE;
    const isDriveHappyDeal = GLOBAL.HAPPY_DEAL_CODE === code;
    const showCustomRateIndicator = status === DISCOUNTS.AVAILABLE_AT_CONTRACT_RATE;
    const showPromotionSavings = status === DISCOUNTS.AVAILABLE_AT_PROMOTIONAL_RATE;
    const couponType = coupons?.[0]?.type;
    const showDiscountCoupons = couponType && couponType !== DISCOUNTS.COUPON_TYPE_UPGRADE;
    const showLimitedInventoryIcon = status.indexOf(GLOBAL.LIMITED_INVENTORY_CAR_CODE) !== -1;
    const showLMSorPASDiscountCoupon = (isLastMinuteSpecials || isPlanAheadSpecials) && showPromotionSavings;
    const isVCSelection =
      vehicleSearchFilters?.selectedVehicleCodes && vehicleSearchFilters.selectedVehicleCodes.includes(vehicle.code);
    const isRecommendedVehicle = showFeaturedVehicle && vehicle_attribute === 'top';

    let prePaySavings;
    if (prePayTotalConverted) {
      const differencesValue = destinationCurrencySelected ? 'difference_amount_payment' : 'difference_amount_view';

      prePaySavings =
        !!price_differences &&
        price_differences?.find((price) => price?.difference_type === PREPAY_CODE)?.[differencesValue];
    }
    let promotionAndCouponSavings;
    if (showPromotionSavings || showDiscountCoupons) {
      const differencesValue = destinationCurrencySelected ? 'difference_amount_payment' : 'difference_amount_view';
      promotionAndCouponSavings = price_differences?.find(
        (price) =>
          price?.difference_type === DISCOUNTS.PROMOTION_TYPE || price?.difference_type === DISCOUNTS.COUPON_TYPE
      )?.[differencesValue];
    }

    // To determine whether to show alamo insider savings, check if active contract is default loyalty. If so,
    // check price_differences array for item with type CONTRACT
    let alamoInsidersSavings;
    if (hasDefaultLoyaltyContractProfile) {
      const differencesValue = destinationCurrencySelected ? 'difference_amount_payment' : 'difference_amount_view';
      alamoInsidersSavings = price_differences?.find((price) => price?.difference_type === DISCOUNTS.CONTRACT_TYPE)?.[
        differencesValue
      ];
    }

    const { isDesktop } = breakpoint;
    const vehicleSelectDetailsClass = cn('vehicle-select-details component-theme--light', {
      'vehicle-select-details--expanded': expanded,
      // 'vehicle-select-details__deal-badge-spacing': isDriveHappyDeal || isVCSelection, // AWR-7613 - Vehicle Card Spacing Consistency
      'vehicle-select-details--selected': selected,
    });

    const driveHappyBanner = () => {
      // If we render two banners then we need to move one of them over
      if (isDriveHappyDeal && isVCSelection) {
        return (
          <div className='vehicle-select-details__badge-container-position-two'>
            <div className='vehicle-select-details__badge'>{utils.i18n('special_offer')}</div>
          </div>
        );
      }
      // Normal Drive Happy "Special Offer" banner placement
      return (
        <div className='vehicle-select-details__badge-container'>
          <div className='vehicle-select-details__badge'>{utils.i18n('special_offer')}</div>
        </div>
      );
    };

    const recommendedVehicleBanner = () => {
      if (isRecommendedVehicle && isVCSelection) {
        return (
          <div className='vehicle-select-details__badge-container-position-two'>
            <div className='vehicle-select-details__badge'>{utils.i18n('recommended_for_you')}</div>
          </div>
        );
      }
      return (
        <div className='vehicle-select-details__badge-container'>
          <div className='vehicle-select-details__badge'>{utils.i18n('recommended_for_you')}</div>
        </div>
      );
    };

    return (
      <div className={vehicleSelectDetailsClass}>
        <div className='vehicle-select-details__badge-container-wrapper'>
          {selected && (
            <div className='vehicle-select-details__selected-banner'>
              <div className='vehicle-select-details__selected-title'>
                {utils.i18n('vehicle_details_current_selected')}
              </div>
            </div>
          )}

          {isDriveHappyDeal && driveHappyBanner()}

          {isRecommendedVehicle && recommendedVehicleBanner()}

          {isVCSelection && (
            <div className='vehicle-select-details__vc_badge-container'>
              <div className='vehicle-select-details__vc_badge'>{utils.i18n('vc_your_preferred_class')}</div>
            </div>
          )}
        </div>
        <div className='vehicle-select-details__row'>
          <div
            className={cn('vehicle-select-details__info-image-group', {
              'info-image-group__guaranteed-res': guaranteed_vehicle,
            })}
          >
            <div className='vehicle-select-details__info-section'>
              {guaranteed_vehicle && (
                <div className='vehicle-select-details__guaranteed-res'>
                  <strong className='vehicle-select-details__guaranteed-res-label'>
                    {utils.i18n('guaranteed_res_label')}
                  </strong>
                  {utils.i18n('vehicle_details_guaranteed_res_copy')}{' '}
                  <Button link={true} onClick={showGuaranteedResModal}>
                    {utils.i18n('vehicle_details_guaranteed_res_learn_more')}
                  </Button>
                </div>
              )}

              <div className='vehicle-select-details__vehicle-info-group'>
                {showLimitedInventoryIcon && (
                  <IconAlert
                    typeOfAlert={LIMITED_INVENTORY}
                    additionalClass='limited-inventory__icon'
                    toolTipTheme={TOOLTIP_THEMES.REGULAR_SLATE}
                  />
                )}
                <h3 className='vehicle-select-details__header'>{name}</h3>

                {make_model_or_similar_text && (
                  <p className='vehicle-select-details__make-model'>
                    {isDriveHappyDeal
                      ? make_model_or_similar_text
                      : utils.i18n('vehicle_details_model_or_similar', [make_model_or_similar_text])}
                  </p>
                )}

                <VehicleDetailsIconList
                  transmission={transmission}
                  peopleCapacity={people_capacity}
                  luggageCapacity={luggage_capacity}
                  twoOrFourDoorText={two_or_four_door_text}
                  isDriveHappyDeal={isDriveHappyDeal}
                  features={features}
                />
              </div>

              {isDesktop && this.renderExpandButton()}
            </div>

            <div className={cn('vehicle-select-details__car-image', { 'pre-pay-layout': prepayExistsInResults })}>
              <Picture src={carImage} alt={make_model_or_similar_text} srcMobile={carImage} srcTablet={carImage} />
            </div>
          </div>

          <div
            className={cn('vehicle-select-details__pay-section', {
              // trick/hack to use PayLater only design with there is only prepay rates
              'pre-pay-layout': prepayExistsInResults && !!payLaterTotal,
              'vehicle-select-details__pay-section--with-coupon-tooltip':
                showDiscountCoupons || upgrade_car_class_name || showLMSorPASDiscountCoupon,
            })}
          >
            <div className='pay-section__row'>
              {prePayTotalConverted && (
                <div
                  className={cn({
                    // trick/hack to use PayLater only design with there is only prepay rates
                    'vehicle-select-details__pre-pay': !!payLaterTotal,
                    'vehicle-select-details__pay-later': !payLaterTotal,
                  })}
                >
                  {parseFloat(prePaySavings?.amount) < 0 && (
                    <div className='vehicle-select-details__pre-pay__savings'>
                      <span className='vehicle-select-details__pre-pay__savings__label'>
                        {utils.i18n('vehicle_select_total_savings')}{' '}
                        <FormattedPrice>{utils.getFormattedPrice(prePaySavings, { removeMinus: true })}</FormattedPrice>
                      </span>
                    </div>
                  )}
                  <div className='vehicle-price-component'>
                    <PriceComponent
                      incrementalPricing={prePayMainRate}
                      amount={prePayTotalConverted?.amount}
                      symbol={prePayTotalConverted?.symbol}
                    />

                    <Button
                      className='vehicle-select-details__pay-button'
                      button={false}
                      onClick={this.handleSelectCarClassPrePay}
                      data-dtm-track={`car_class|pre_pay|${vehicle?.code}`}
                    >
                      {utils.i18n(
                        isTrueModify && selected && prepaySelected
                          ? 'true_modify_vehicle_select_keep_prepay'
                          : 'vehicle_select_pay_now'
                      )}
                    </Button>
                  </div>
                </div>
              )}

              {!prePayTotalConverted && prepayExistsInResults && (
                <div className='vehicle-select-details__pre-pay-unavailable'>
                  <span className='pre-pay-unavailable__no-icon' />
                  <span className='pre-pay-unavailable__copy'>
                    {utils.i18n('vehicle_select_pre_pay_unavailable', [name])}
                  </span>
                </div>
              )}

              {payLaterTotal && (
                <div
                  className={cn('vehicle-select-details__pay-later', {
                    'with-rate-flag': showCustomRateIndicator || promotionAndCouponSavings || upgrade_car_class_name,
                  })}
                >
                  {showDiscountCoupons &&
                    this.renderCouponTooltip(
                      utils.i18n('coupon_name_applied', [
                        couponType === DISCOUNTS.COUPON_TYPE_FREE_DAY
                          ? utils.i18n('coupon_free_day_name')
                          : coupons[0].description,
                      ])
                    )}
                  {upgrade_car_class_name &&
                    this.renderCouponTooltip(
                      isDesktop
                        ? utils.i18n('coupon_upgrade_badge', [upgrade_car_class_name])
                        : utils.i18n('coupon_upgrade_adjustment_name')
                    )}
                  {showLMSorPASDiscountCoupon && this.renderCouponTooltip(discountContractDetails?.contractName)}
                  <div className='pay-later__savings-row'>
                    {promotionAndCouponSavings && (
                      <div className='vehicle-select-details__promotion-total-savings pay-later__savings-row-item'>
                        <span>
                          {utils.i18n('vehicle_select_total_savings')}{' '}
                          <FormattedPrice>
                            {utils.getFormattedPrice(promotionAndCouponSavings, { removeMinus: true })}
                          </FormattedPrice>
                        </span>
                      </div>
                    )}

                    {showCustomRateIndicator && (
                      <div className='vehicle-select-details__custom-rate-indicator pay-later__savings-row-item'>
                        {utils.i18n('vehicle_select_custom_rate')}
                      </div>
                    )}
                  </div>

                  {!showCustomRateIndicator && !promotionAndCouponSavings && alamoInsidersSavings && (
                    <div className='vehicle-select-details__alamo-insider-savings'>
                      {`${utils.i18n('alamo_insider_savings_flag')} `}{' '}
                      <FormattedPrice>
                        {utils.getFormattedPrice(alamoInsidersSavings, { removeMinus: true })}{' '}
                      </FormattedPrice>
                    </div>
                  )}

                  <PriceComponent
                    incrementalPricing={payLaterMainRate}
                    amount={payLaterTotalConverted?.amount}
                    symbol={payLaterTotalConverted?.symbol}
                  />

                  <Button
                    button={false}
                    className='vehicle-select-details__pay-button'
                    data-dtm-track={`car_class|pay_later|${vehicle?.code}`}
                    onClick={this.handleSelectCarClass}
                  >
                    {utils.i18n(
                      isTrueModify && selected && !prepaySelected
                        ? (prePayTotalConverted && 'true_modify_vehicle_select_keep_pay_later') ||
                            'true_modify_vehicle_select_keep_selection'
                        : (prepayExistsInResults && 'vehicle_select_pay_later') || 'vehicle_select_pay_button'
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isDesktop && this.renderExpandButton()}
        {expanded && (
          <VehicleSelectDetailsCardExpansion
            features={features}
            discountContractDetails={discountContractDetails}
            couponType={couponType}
            payLaterTotal={payLaterTotal}
            payLaterTotalConverted={payLaterTotalConverted}
            convertedPrices={
              (payLaterTotal || prePayTotal)?.code !== (payLaterTotalConverted || prePayTotalConverted)?.code
            }
            payLaterRates={payLaterRates}
            prePayTotal={prePayTotal}
            prePayTotalConverted={prePayTotalConverted}
            prePayRates={(prepayExistsInResults && prePayRates) || null}
            payLaterIncrementalPricing={payLaterIncrementalPricingRates}
            prePayIncrementalPricing={prePayIncrementalPricingRates}
            mileageInfo={mileage_info}
            showConvertedTotalsModal={showConvertedTotalsModal}
            showTaxesFeesModal={this.showTaxesFeesModal}
            learnAboutPrepayAndPayLaterModal={learnAboutPrepayAndPayLaterModal}
            isEuropeanUnionCountry={isEuropeanUnionCountry}
            isPayTypeSelected={isPayTypeSelected}
            destinationCurrencyCode={destinationCurrencyCode}
          />
        )}
      </div>
    );
  }
}
