import React from 'react';
import { i18n } from 'utils/i18n';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import Modal from 'components/Modal';

/**
 * Informs when to pay for trip enhancements
 * @return {JSX}
 */
const TripEnhancementsModal = () => (
  <Modal
    modalKey={MODAL.TRIP_ENHANCEMENTS_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={i18n('trip_enhancements_modal_title')}>
    <p className='modal-themed__paragraph'>{i18n('trip_enhancements_modal_description')}</p>
  </Modal>
);

export default TripEnhancementsModal;
