import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

/**
 * Title
 *
 * Renders a heading, an optional suffix, and an optional call to action
 *
 * @param {boolean} props.button - applies button class and appearance to the cta
 * @param {string} props.className - optional classes for wrapping element
 * @param {string} props.cta - Call to Action text
 * @param {string} props.ctaClassName - additional classes applied to the cta
 * @param {function} props.customCtaRender - render function that will entirely replace the standard Title CTA
 * @param {string} props.linkURL - String to the linked URL
 * @param {boolean} props.openInTab - Boolean if link will be opened in new tab
 * @param {string} props.text - Text of the main title
 * @param {string} props.titleSuffix - optional suffix text string
 * @param {string} props.type - Tag Type for the main header

 *
 * @return {JSX}
 */
class Title extends Component {
  render() {
    const {
      type: Tag,
      text,
      cta,
      ctaClassName,
      customCtaRender,
      linkURL,
      openInTab,
      button,
      titleSuffix,
      className,
    } = this.props;
    const hasSuffix = !!titleSuffix;
    const hasCta = !!cta || !!customCtaRender;

    return (
      <div
        className={cn(
          {
            'title__container--suffix': hasSuffix,
            'title__container--link': !hasSuffix && hasCta,
            title__container: !hasSuffix && !hasCta,
          },
          className
        )}>
        <Tag className='title__heading-text'>{text}</Tag>
        {titleSuffix && <span className='title__suffix'>{titleSuffix}</span>}
        {customCtaRender && customCtaRender()}
        {!customCtaRender && cta && (
          <a
            target={openInTab ? '_blank' : ''}
            className={cn(
              {
                'link title__link': !button,
                title__button: button,
                button,
                'button--alternative': button,
                'title__new-tab': openInTab,
              },
              ctaClassName
            )}
            href={linkURL} rel='noreferrer'>
            {cta}
          </a>
        )}
      </div>
    );
  }
}

Title.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  cta: PropTypes.string,
  ctaClassName: PropTypes.string,
  customCtaRender: PropTypes.func,
  openInTab: PropTypes.bool,
  linkURL: PropTypes.string,
  text: PropTypes.string,
  titleSuffix: PropTypes.string,
  button: PropTypes.bool,
};

export default Title;
