import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { loadingRedirect } from 'utils/redirect';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import CUSTOM_PATH from 'constants/customPathCheckIn';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import ANALYTICS from 'constants/analytics';
import Button from 'components/Button';

const { STC_PATH_CONFIG } = SKIP_THE_COUNTER;
const { CUSTOM_PATH_CONFIG } = CUSTOM_PATH;

/**
 * TripCardAcceleratedCheckin - This component will be present on only on Trip Card and it's separated because it has some specific behaviours:
 * - OCI (Accelerated Check-in) Not Complete
 * - OCI Complete & STC (Skip the counter) Eligible
 * - OCI Complete & STC Ineligible
 * - OCI & STC Complete
 *
 * @param {object} props - React Props
 * @param {string} props.firstName - Driver's first name
 * @param {string} props.lastName - Driver's last name
 * @param {string} props.confirmationNumber - Trip confirmation number
 * @param {Boolean} props.isAcceleratedCheckinInactive - OCI is inactive, so user need to fill some information
 * @param {Boolean} props.userCanActiveSkipTheCounter - User has filled all the info and Alamo allows her/him to skip the counter
 * @param {Boolean} props.skipTheCounterCompleted - User already activated skip the counter
 * @param {Function} props.openLearnMoreAboutSkipTheCounterModal - action to open LearnMoreAboutSkipTheCounterModal present in upcomingTrips component
 * @param {Function} props.openLearnMoreAboutSkipTheCounterEligibleModal - action to open LearnMoreAboutSkipTheCounterEligibleModal present in upcomingTrips component
 * @param {Function} props.openLearnMoreAboutSkipTheCounterIneligibleModal - action to open LearnMoreAboutSkipTheCounterIneligibleModal present in upcomingTrips component
 * @param {function} props.openLearnMoreAboutOCIInactiveModal - action to open LearnMoreAboutAcceleratedCheckinModal present in upcomingTrips with inactive status
 * @param {Function} props.activateCheckIn - action start Accelerated Check In for given trip
 * @param {Function} props.activateSkipTheCounter - action start Skip The Counter for given trip
 * @param {Function} props.retrieveReservation - retrieve reservation with names and confirmation number
 * @return {JSX} - TripCardAcceleratedCheckin component
 */

const TripCardAcceleratedCheckin = ({
  firstName,
  lastName,
  confirmationNumber,
  isAcceleratedCheckinInactive,
  userCanActiveSkipTheCounter,
  skipTheCounterCompleted,
  openLearnMoreAboutSkipTheCounterModal,
  openLearnMoreAboutSkipTheCounterEligibleModal,
  openLearnMoreAboutSkipTheCounterIneligibleModal,
  openLearnMoreAboutOCIInactiveModal,
  activateCheckIn,
  activateSkipTheCounter,
  retrieveReservation,
}) => {
  const reservationData = { firstName, lastName, confirmationNumber };

  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);

  const handleStartOCI = () => activateCheckIn(reservationData);

  const handleStartSTC = () => activateSkipTheCounter(reservationData);

  // For STC Print Ticket retrieve the reservation (set in session) and redirect user to STC Flow with Confirmation hash
  const stcPrintTicket = () =>
    retrieveReservation(reservationData, false).then(() =>
      loadingRedirect(
        WINDOW_OBJECT_KEYS[isCustomPathReservation ? 'CUSTOM_PATH_FLOW' : 'SKIP_THE_COUNTER_FLOW'],
        true,
        isCustomPathReservation ? CUSTOM_PATH_CONFIG.confirmation[0] : STC_PATH_CONFIG.confirmation[0]
      )
    );

  return (
    <div className='trip-card-aci'>
      {/*
       *  Accelerated Check in
       */}
      <h4
        className={cn('trip-card-aci__title', {
          'trip-card-aci__title--active': !isAcceleratedCheckinInactive || skipTheCounterCompleted,
        })}>
        <span className='trip-card-aci__title-text'>{utils.i18n('trip_card_title_accelerated_checkin')}</span>
      </h4>

      {/* OCI Not Complete */}
      {isAcceleratedCheckinInactive && !skipTheCounterCompleted && (
        <div className='trip-card-aci__info'>
          <div>
            {utils.i18n('trip_card_accelerated_checkin_description')}{' '}
            <Button
              onClick={() => {
                openLearnMoreAboutOCIInactiveModal();
              }}
              link
              className='trip-card-aci__learn-more'>
              {utils.i18n('trip_card_learn_more')}
            </Button>
          </div>
          <div className='trip-card-aci__button-area'>
            <Button
              className='trip-card-aci__action-button'
              onClick={handleStartOCI}
              data-dtm-track={utils.analytics.dtm(ANALYTICS.MY_TRIPS, ANALYTICS.UPCOMING_TRIPS, 'save_time')}>
              {utils.i18n('trip_card_save_time_activate_button')}
            </Button>
          </div>
        </div>
      )}

      {/*
       *  Skip the counter
       */}
      <div className='trip-card-aci__skip-counter'>
        <h4
          className={cn('trip-card-aci__title trip-card-aci__title--number-two', {
            'trip-card-aci__title--active': skipTheCounterCompleted,
            'trip-card-aci__title--inactive': !userCanActiveSkipTheCounter && !isAcceleratedCheckinInactive,
          })}>
          <span className='trip-card-aci__title-text'>{utils.i18n('trip_card_skip_the_counter')}</span>
        </h4>
        <div className='trip-card-aci__info'>
          {/* OCI Not Complete */}
          {isAcceleratedCheckinInactive && !skipTheCounterCompleted && (
            <>
              {utils.i18n('trip_card_skip_the_counter_description')}{' '}
              <Button link className='trip-card-aci__learn-more' onClick={openLearnMoreAboutSkipTheCounterModal}>
                {utils.i18n('trip_card_learn_more')}
              </Button>
            </>
          )}

          {/* OCI Complete & STC Eligible */}
          {!isAcceleratedCheckinInactive && userCanActiveSkipTheCounter && (
            <>
              {utils.i18n('trip_card_skip_the_counter_eligible_description')}{' '}
              <Button
                link
                className='trip-card-aci__learn-more'
                onClick={openLearnMoreAboutSkipTheCounterEligibleModal}>
                {utils.i18n('trip_card_learn_more')}
              </Button>
              <div className='trip-card-aci__button-area'>
                <Button
                  className='trip-card-aci__action-button'
                  data-dtm-track={utils.analytics.dtm(ANALYTICS.MY_TRIPS, ANALYTICS.UPCOMING_TRIPS, 'skip_counter')}
                  onClick={handleStartSTC}>
                  {utils.i18n('trip_card_skip_the_counter')}
                </Button>
              </div>
            </>
          )}

          {/* OCI Complete & STC Ineligible */}
          {!isAcceleratedCheckinInactive && !userCanActiveSkipTheCounter && (
            <>
              {utils.i18n('trip_card_skip_the_counter_not_eligible_description')}{' '}
              <Button
                link
                className='trip-card-aci__learn-more'
                onClick={openLearnMoreAboutSkipTheCounterIneligibleModal}>
                {utils.i18n('trip_card_learn_more')}
              </Button>
            </>
          )}

          {/* OCI & STC Complete */}
          {skipTheCounterCompleted && (
            <>
              {utils.i18n('trip_card_skip_the_counter_instructions')}
              <div className='trip-card-aci__button-area'>
                <Button
                  className='button trip-card-aci__action-button'
                  onClick={stcPrintTicket}
                  data-dtm-track={utils.analytics.dtm(ANALYTICS.MY_TRIPS, ANALYTICS.UPCOMING_TRIPS, 'view_ticket')}>
                  {utils.i18n('stc_view_ticket')}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

TripCardAcceleratedCheckin.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  confirmationNumber: PropTypes.string,
  isAcceleratedCheckinInactive: PropTypes.bool,
  userCanActiveSkipTheCounter: PropTypes.bool,
  skipTheCounterCompleted: PropTypes.bool,
  openLearnMoreAboutSkipTheCounterModal: PropTypes.func,
  openLearnMoreAboutSkipTheCounterEligibleModal: PropTypes.func,
  openLearnMoreAboutSkipTheCounterIneligibleModal: PropTypes.func,
  openLearnMoreAboutOCIInactiveModal: PropTypes.func,
  activateCheckIn: PropTypes.func,
  activateSkipTheCounter: PropTypes.func,
  retrieveReservation: PropTypes.func,
};

export default TripCardAcceleratedCheckin;
