import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const GenericNotification = ({ title, message, messageClass, bannerClass, dtmTrack }) => (
  <div className={cn('generic-notification component-theme--light', bannerClass)} data-dtm-track={dtmTrack}>
    <p className='generic-notification__message'>
      <span className={cn('generic-notification__title', messageClass)}>{title}</span>
      {message}
    </p>
  </div>
);

GenericNotification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.any.isRequired,
  additionalClass: PropTypes.string,
  dtmTrack: PropTypes.string,
};

GenericNotification.defaultProps = {
  dtmTrack: null,
};

export default GenericNotification;
