import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import utils from 'utils';

// update final form values for pickup and return date
// whenever the pickup date is later than the return date, clear the return date
// and manage the pickup date
class LocationDateTimeFormSpy extends Component {
  state = {
    lastActiveField: null,
  };

  handleChange = (formState) => {
    let { pickupDate, returnDate } = formState.values;

    // don't have access to the exchange type from the GMI actions,
    // so tracking the last active field instead to infer what the user is changing
    this.setState(
      (state) => ({
        lastActiveField: formState.active || state.lastActiveField,
      }),
      () => {
        const { form } = this.props;
        const { lastActiveField } = this.state;

        if (pickupDate && returnDate) {
          // make sure we're comparing date/moment objects
          // some values may initially appear as strings
          pickupDate = utils.gmi.getDateTimeObjFromTs(pickupDate, typeof pickupDate === 'string' ? 'YYYY-MM-DD' : null);
          returnDate = utils.gmi.getDateTimeObjFromTs(returnDate, typeof returnDate === 'string' ? 'YYYY-MM-DD' : null);

          if (pickupDate.isAfter(returnDate)) {
            form.change('returnDate', '');
            // if the return date was set earlier than pickup, use that date as the pickup
            lastActiveField === 'returnDate' && form.change('pickupDate', returnDate.toDate());
          }
        }
      }
    );
  };

  // hack for handleChange to get called  before render, referred from here https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
  render() {
    return (
      <FormSpy
        onChange={(formState) => setTimeout(() => this.handleChange(formState), 0)}
        subscription={{ active: true, values: true }}
      />
    );
  }
}

LocationDateTimeFormSpy.propTypes = {
  form: PropTypes.object.isRequired,
};

export default LocationDateTimeFormSpy;
