import { connect } from 'react-redux';
import utils from 'utils';
import { modalQueueSelector } from 'selectors';
import { MODAL } from 'constants';
import SessionTimeoutModal from './SessionTimeoutModal';

const { SESSION_TIMEOUT_MODAL } = MODAL;

const mapStateToProps = state => {
  const modalQueue = utils.safeToJS(modalQueueSelector(state));

  return {
    countDown: modalQueue?.props?.countDown || 0,
    runTimer: modalQueue?.props?.runTimer || false,
    modalKey: modalQueue?.modalKey === SESSION_TIMEOUT_MODAL,
    sessionTimeoutValue: modalQueue?.props?.currentTime,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(SessionTimeoutModal));
