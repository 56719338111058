import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { utils as ehiUtils } from '@ehi/global-marketing-interface';
import { capitalize } from 'utils/string';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import cn from 'classnames';
import Button from 'components/Button';
import Checkbox from 'components/Form/Checkbox';
import useActions from 'hooks/useActions';
import { getSearchFilterValue, getLocationSelectedValues } from 'utils/vehicles';
import { setFiltersByValue } from "actions/filter/setFiltersByValue";

const LocationStateDrawer = ({
  open,
  handleLocationTypeFilterModal,
  handleVehicleClassFilterModal,
  onChange,
  currentFilters,
  config,
  storeKey,
  vehicleSearchFilters,
  lowestCarFilterPrices,
  locations,
  one_way_rental,
  vehicle_categories,
}) => {
  const [, setIsLocationTypeFocus] = useState(true);
  const [, setIsVehicleClassFocus] = useState(true);
  const setFiltersByValueAction = useActions(setFiltersByValue);
  const [isLocationTypeActive, setIsLocationTypeActive] = useState(false);
  const disableVCFilter = one_way_rental;

  const handleFilterChange = params => {
    setFiltersByValueAction({ storeKey, currentFilters, ...params });
    utils.safeExecute(onChange, currentFilters, params.value);
  };

  const handleCheckboxChange = ({ keyPath, isRadio, isSingleSelect, value }) => (e, input, checked) => {
    handleFilterChange({ value, checked, keyPath, isRadio, isSingleSelect });
  };

  const handleLocationAvailability = () => {
    const locationAvailabilityFilters =
      currentFilters && currentFilters.length > 1 ? currentFilters.slice(1, 2) : currentFilters;
    return (
      <div className='location-state__location-availibity'>
        {locationAvailabilityFilters.map(filter => {
          const { available, category, keyPath } = filter;
          const availableSorted =
            category !== 'Vehicle Class' ? utils.gmi.sortArrayOfObjectsByKey(available, 'label') : available;

          const filterConfig = config.find(item => item.keyPath === keyPath);

          if (filterConfig) {
            const { isRadio, labelHandler } = filterConfig;
            return availableSorted.map(({ checked, label, value }) => {
              const id = `${keyPath}_${value}`;
              return (
                <Checkbox
                  checked={checked}
                  id={id}
                  key={id}
                  label={labelHandler?.(label, value, lowestCarFilterPrices) || label || value}
                  name={keyPath}
                  onChange={handleCheckboxChange({ keyPath, isRadio, value })}
                  value={value}
                  derivedState
                />
              );
            });
          }
          return null;
        })}
      </div>
    );
  };

  const islocationAvailability = currentFilters.filter(item => item.keyPath === 'location_availability');
  const showLocationAvailabilityPath = currentFilters.length > 1 ? true : islocationAvailability.length > 0;
  const locationSelectedValue =
    currentFilters.length === 1 && showLocationAvailabilityPath
      ? utils.i18n('common_all')
      : getLocationSelectedValues(currentFilters);
  const vehicleSelectedValue = getSearchFilterValue(vehicleSearchFilters);
  const isLocationTypeSelected = locationSelectedValue !== utils.i18n('common_all');
  const isVehicleClassSelected = vehicleSelectedValue !== utils.i18n('all_vehicles');

  const handleClickLocationTypeButton = () => {
    handleLocationTypeFilterModal();

    utils.analytics.interaction(
      capitalize(ANALYTICS.MODAL),
      utils.i18n('location_type_filter_modal_title'),
      capitalize(ANALYTICS.OPEN)
    );
    setIsLocationTypeActive(true);
  };

  const handleClickVehicleClassButton = () => {
    handleVehicleClassFilterModal();
    ehiUtils.isArrayNotEmpty(locations) && setIsLocationTypeActive(false);
    utils.analytics.interaction(
      capitalize(ANALYTICS.MODAL),
      ANALYTICS.FILTER_BY_VEHICLE_CLASS,
      capitalize(ANALYTICS.OPEN)
    );
  };

  // Checking to determine if there are options available for Vehicle Class Filtering
  const areCategoriesBlank = () => !ehiUtils.isArrayNotEmpty(vehicle_categories.flatMap(item => item.vehicles));

  // disabling locationType drawer only if the  vehicle filters applied results give us zero locations results
  const disableLocationTypeFilterModal = !isLocationTypeActive && !ehiUtils.isArrayNotEmpty(locations);

  return (
    open && (
      <div className='location-state__drawer-wrapper'>
        <div className='modal-themed__horizontal-separator' />
        <div className='fieldset fieldset-group'>
          <section
            className={cn('location-state__section', {
              'component-theme--dark-blue': !ehiUtils.isArrayNotEmpty(locations) || !currentFilters.length > 0,
              focus: ehiUtils.isArrayNotEmpty(locations) && currentFilters.length > 0,
              filled: isLocationTypeSelected,
            })}>
            <Button
              disabled={disableLocationTypeFilterModal || !currentFilters.length > 0}
              className='location-state__section-button'
              button={false}
              onFocus={() => setIsLocationTypeFocus(true)}
              ariaText={utils.i18n('location_finder_filter_label_location_type')}
              onClick={handleClickLocationTypeButton}>
              <span className='location-state__section-label'>
                {utils.i18n('location_finder_filter_label_location_type')}
              </span>
              <div className='location-state__section-values'>
                <span className='location-state__section-value'>{locationSelectedValue}</span>
              </div>
            </Button>
          </section>

          <section
            className={cn('location-state__section', {
              'component-theme--dark-blue': areCategoriesBlank(),
              focus: !areCategoriesBlank(),
              filled: isVehicleClassSelected,
            })}>
            <Button
              className='location-state__section-button'
              button={false}
              disabled={disableVCFilter || areCategoriesBlank()}
              onFocus={() => setIsVehicleClassFocus(true)}
              ariaText={utils.i18n('search_vehicle_class')}
              onClick={handleClickVehicleClassButton}>
              <span className='location-state__section-label'>{utils.i18n('search_vehicle_class')}</span>
              <div className='location-state__section-values'>
                <span className='location-state__section-value'>{vehicleSelectedValue}</span>
              </div>
            </Button>
          </section>
          {showLocationAvailabilityPath && handleLocationAvailability()}
        </div>
      </div>
    )
  );
};

export default LocationStateDrawer;

LocationStateDrawer.propTypes = {
  storeKey: PropTypes.string.isRequired,
  config: PropTypes.array.isRequired,
  currentFilters: PropTypes.array,
  onChange: PropTypes.func,
  lowestCarFilterPrices: PropTypes.object,
};
