import { connect } from 'react-redux';
import utils from 'utils';
import {
  includedOrAddedExtrasSelector,
  includedExtrasAndDriversSelector,
  selectedBundleWithExtrasSelector,
  bundledAdditionalDriversSelector,
  numberOfAddedExtrasSelector,
} from 'selectors';
import ProgressBarExtrasDrawer from './ProgressBarExtrasDrawer';

const mapStateToProps = state => {
  const { addedExtras } = includedOrAddedExtrasSelector(state);
  return {
    includedExtrasAndDrivers: includedExtrasAndDriversSelector(state),
    addedExtras,
    selectedBundle: selectedBundleWithExtrasSelector(state),
    bundledAdditionalDrivers: bundledAdditionalDriversSelector(state),
    addedExtrasAmount: numberOfAddedExtrasSelector(state),
  };
};

export default connect(mapStateToProps)(utils.toJS(ProgressBarExtrasDrawer));
