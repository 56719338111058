import React from 'react';
import utils from 'utils';
import cn from 'classnames';
import { ANALYTICS } from 'constants';
import printButtonModalPropTypes from './printButtonModalPropTypes';

const printPage = () => {
  utils.print(['.modal-overlay']);
};

const PrintButton = ({ showPrintButton, classNamesPrintButton = '', analyticsTitle }) =>
  showPrintButton ? (
    <p
      data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, analyticsTitle, ANALYTICS.PRINT)}
      className={cn('modal__print-tag', { classNamesPrintButton })}
      onClick={printPage}
    >
      {utils.i18n('modal_print')}
    </p>
  ) : null;
PrintButton.propTypes = printButtonModalPropTypes;

export default PrintButton;
