import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import THEMES from 'constants/themes';
import { branchLocalAddendaSelector } from 'selectors/skipTheCounterSelectors';
import Checkbox from 'components/Form/Checkbox';
import RentalTermsAndConditionsModal from 'components/modals/RentalTermsAndConditionsModal';
import STCFormCard from './STCFormCard';

/**
 * STCLocalAddendum
 *
 * @param {object} props - React props
 * @param {boolean} props.haveErrors - Is there any error in the form for Local Addenda Checkbox?
 * @param {boolean} props.submitFailed - Did submit failed? (Used to show error message only after any submit)
 * @return {JSX}
 */
const STCLocalAddendum = ({ haveErrors, submitFailed }) => {
  const branchLocalAddenda = useSelector(branchLocalAddendaSelector);
  // As the checkbox is the only field in this tile and it is required, `haveErrors` is also a way to
  // get if the field is checked or not.
  const dtmTrackValue = `post_res|local_addendum|${haveErrors ? 'check' : 'uncheck'}`;

  return (
    !!branchLocalAddenda?.length && (
      <STCFormCard title={utils.i18n('stc_accept_local_addendum_title')} isComplete={!haveErrors}>
        {haveErrors && submitFailed && (
          <p className='skip-the-counter-flow__paragraph skip-the-counter-flow__paragraph--error'>
            {utils.i18n('stc_accept_local_addendum_error_message')}
          </p>
        )}

        {branchLocalAddenda.map(addenda => (
          <p
            className='skip-the-counter-flow__paragraph skip-the-counter-flow__legal-copy'
            key={`local-addenda-${addenda.code}`}
            dangerouslySetInnerHTML={{ __html: addenda.display_text }}
          />
        ))}

        <Checkbox
          name='accept_branch_local_addenda'
          label={utils.i18n('stc_accept_local_addendum_checkbox')}
          theme={THEMES.LIGHT}
          validate={utils.fieldValidation.isTrueBool}
          dataDtmAnalytics={dtmTrackValue}
        />

        <RentalTermsAndConditionsModal />
      </STCFormCard>
    )
  );
};

STCLocalAddendum.propTypes = {
  haveErrors: PropTypes.bool,
  submitFailed: PropTypes.bool,
};

export default STCLocalAddendum;
