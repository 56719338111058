import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import Modal from 'components/Modal';
import BookWithPartnerModalContent from './BookWithPartnerModalContent';
/**
 * Informs users that by going to a partner website the current
 * reservation will lose its product code and coupon.
 * @return {JSX}
 */
const BookWithPartnerModal = () => (
  <Modal modalKey={MODAL.BOOK_WITH_PARTNER} theme={MODAL_THEMES.WHITE} header={utils.i18n('book_with_partner_title')}>
    <BookWithPartnerModalContent />
  </Modal>
);

export default BookWithPartnerModal;
