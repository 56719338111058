import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import Anchor from 'components/Anchor';
import PastTripCard from './PastTripCard';
import PastTripsEmpty from './PastTripsEmpty';

/**
 * PastTrips Description
 *
 * @param {object} props - React Props
 * @param {array} props.pastTrips - Array with all user's past trips GBO can send us
 * @param {Function} props.getPastTrips - Function to load past trips
 * @param {object} props.profileData - Object containing user's member number, necessary to call past trips
 * @param {boolean} props.loadingOverlay - in case of true, it will show whole page loader
 * @param {function} props.openMissingTripsModal - opens MISSING_TRIPS_MODAL
 * @return {JSX}
 */

const PastTrips = ({
  pastTrips,
  getPastTrips,
  profileData,
  loadingOverlay,
  openMissingTripsModal,
  isFetchingPastTrips,
}) => {
  useEffect(() => {
    // This is to avoid that getPastTrips is called before profileData is loaded
    if (profileData?.memberNumber && !isFetchingPastTrips) {
      const payload = { membership_number: profileData.memberNumber };
      getPastTrips(payload, loadingOverlay);
    }
  }, [profileData]);

  const helpDisclaimer = utils.i18n(
    'past_trips_disclaimer_2',
    {
      0: (
        <Anchor
          key='past_trips_disclaimer_0'
          className='link'
          href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)}
        >
          {utils.i18n('profile_account_customer_support')}
        </Anchor>
      ),
    },
    { jsx: true }
  );

  return (
    <div className='past-trips row'>
      {utils.gmi.isArrayNotEmpty(pastTrips) ? (
        <>
          {pastTrips?.map((trip) => (
            <PastTripCard trip={trip} key={trip.rental_agreement_number} isMyTripsPage />
          ))}
          <div className='past-trips__disclaimer'>
            <p>{utils.i18n('past_trips_disclaimer_1')}</p>
            <p>{helpDisclaimer}</p>
          </div>
        </>
      ) : (
        <PastTripsEmpty helpDisclaimer={helpDisclaimer} openMissingTripsModal={openMissingTripsModal} />
      )}
    </div>
  );
};

PastTrips.propTypes = {
  pastTrips: PropTypes.arrayOf(PropTypes.object),
  getPastTrips: PropTypes.func,
  profileData: PropTypes.object,
  loadingOverlay: PropTypes.bool,
  openMissingTripsModal: PropTypes.func,
};
export default PastTrips;
