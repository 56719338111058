import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import { onlineCheckInProfileSelector } from 'selectors/checkinSelectors';
import TextField from 'components/Form/TextField';
import SelectField from 'components/Form/SelectField';
import ServiceErrors from 'components/ServiceErrors';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * DriverLookupForm Component
 * Drivers license form UI including country fields config logic. Does not include submit button, submit is handled
 * by wrappers, so not necessary to pass in a submit handler.
 *
 * @param {object} props - React Props
 * @param {object} props.form - FinalForm instance - used to reset field data when country is selected
 * @param {function} props.handleSubmit - Submit function wrapped by Form HOC
 * @param {string} props.errorStatePath - indicates state path that ServiceErrors component should be listening to
 *
 * @return {JSX} DriverLicenseForm jsx component
 */
const DriverLookupForm = ({ form, handleSubmit, errorStatePath }) => {
  const { license_profile } = useSelector(onlineCheckInProfileSelector);

  const initialCountryCode = license_profile.country_code || domainCountryCode;

  const { selectedCountry, setSelectedCountry, countryConfig, countries, statesOrProvinces } = useCountryFieldsConfig(
    initialCountryCode
  );

  const setCountry = event => {
    const countryObject = countries.find(({ country_code }) => country_code === event?.target?.value);
    setSelectedCountry(countryObject);

    // resets the subdivision in case the country is changed to prevent invalid selections
    form.change('country_subdivision');
  };

  const { shouldShowSubdivisionField, shouldShowIssuingAuthorityField, issuingAuthorities } = countryConfig;

  return (
    <>
      <h3 className='check-in-flow__title'>{utils.i18n('check_in_driver_lookup_form_title')}</h3>
      <p className='check-in-flow__secure-note'>{utils.i18n('check_in_driver_lookup_form_secure_note')}</p>

      <form className='check-in-flow__form component-theme--light' onSubmit={handleSubmit} noValidate>
        {errorStatePath && <ServiceErrors statePath={errorStatePath} />}
        <div className='check-in-flow__form__field-row'>
          {!!countries?.length && (
            <SelectField
              id='country'
              name='country'
              label={utils.i18n('my_profile_drivers_license_issuing_country')}
              onChange={setCountry}
              options={countries}
              valueKey='country_code'
              labelKey='country_name'
              required
              includeHiddenOption
            />
          )}
          {shouldShowSubdivisionField && !shouldShowIssuingAuthorityField && (
            <SelectField
              id='country_subdivision'
              name='country_subdivision'
              label={utils.i18n('my_profile_drivers_license_state_province')}
              options={statesOrProvinces[selectedCountry.country_code]}
              valueKey='country_subdivision_code'
              labelKey='country_subdivision_name'
              required
              includeHiddenOption
            />
          )}
          {shouldShowIssuingAuthorityField && !!issuingAuthorities?.length && (
            <SelectField
              id='issuing_authority'
              name='issuing_authority'
              label={utils.i18n('my_profile_drivers_license_issuing_authority')}
              options={issuingAuthorities}
              valueKey='country_subdivision_code'
              labelKey='country_subdivision_name'
              required
              includeHiddenOption
            />
          )}
        </div>
        <div className='check-in-flow__form__field-row'>
          <TextField
            id='driver_license_number'
            name='driver_license_number'
            label={utils.i18n('my_profile_drivers_license_number')}
            fill
            required
            autoComplete='driver_license_number'
          />
        </div>
      </form>
    </>
  );
};

DriverLookupForm.propTypes = {
  form: PropTypes.object,
  errorStatePath: PropTypes.string,
};

export default DriverLookupForm;
