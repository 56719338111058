import React from 'react';
import useActions from 'hooks/useActions';
import { checkinStart } from 'actions/checkin';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import CHECKIN from 'constants/checkin';
import utils from 'utils';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { startAdditionalDriverFlow } from "actions/reservation/startAdditionalDriverFlow";

const AdditionalDriverOciRequestModal = ({ isFoundRental }) => {
  const checkinOrigin = isFoundRental
    ? CHECKIN.CHECKIN_ORIGIN.RESERVATION_DETAILS
    : CHECKIN.CHECKIN_ORIGIN.CONFIRMATION;

  const startOciCheckin = useActions(() => checkinStart({ origin: checkinOrigin }));
  const additionalDriverFlowStart = useActions(startAdditionalDriverFlow);

  return (
    <Modal
      modalKey={MODAL.ADDITIONAL_DRIVER_OCI_REQUEST_MODAL}
      header={utils.i18n('additional_driver_oci_request_modal_header')}
      theme={MODAL_THEMES.WHITE}>
      {() => (
        <>
          <p className='modal-themed__paragraph'>{utils.i18n('additional_driver_oci_request_modal_copy')}</p>
          <ModalFooter
            buttons={[
              {
                type: MODAL_BUTTON_TYPES.DECLINE,
                label: utils.i18n('additional_driver_oci_request_modal_decline'),
                largeButton: true,
                handler: additionalDriverFlowStart,
              },
              {
                type: MODAL_BUTTON_TYPES.ACCEPT,
                label: utils.i18n('additional_driver_oci_request_modal_accept'),
                largeButton: true,
                handler: startOciCheckin,
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default AdditionalDriverOciRequestModal;
