import { connect } from 'react-redux';
import utils from 'utils';
import { currentFiltersSelector } from 'selectors/uiStateSelectors';
import LocationTypeFilterModal from './LocationTypeFilterModal';

// Connecting redux to vehicle class need to implement with real data

const mapStateToProps = (state, ownProps) => {
  const currentFiltersSelectors = currentFiltersSelector(state, ownProps.storeKey);
  return {
    currentFilters: currentFiltersSelectors,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(LocationTypeFilterModal));
