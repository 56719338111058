import React, { Component } from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import TextField from 'components/Form/TextField';
import ToolTip from 'components/ToolTip';
import { ANALYTICS } from 'constants';
import FieldGroupCard from '../FieldGroupCard';

/**
 * PartnerRewardsCard component shows the partner rewards data and gives option to remove partner rewards in nonLoyalty reservations.
 *
 * @param {object}    props - React Props
 * @param {function}  props.curriedUpdateCommitState - curried function to update commit state. Param order is: (stateKey)(value)
 * @param {function}  props.clearPartnerRewards - opens Modal to confirm clearing Partner Rewards contract
 * @param {string}    props.partner_reward_program - partner reward program data from reservation object
 * @param {boolean}   props.defaultLoyaltyContract - indicates whether contract is Alamo Insiders
 * @param {boolean}   props.isTrueModify - true modify flag
 * @return {JSX}      PartnerRewardsCard component
 */
export default class PartnerRewardsCard extends Component {
  updatePartnerRewards = e => {
    const { curriedUpdateCommitState, partner_reward_program } = this.props;
    const partner_reward_program_member_id = e.target.value;
    const partner_reward_program_code = partner_reward_program?.code;
    curriedUpdateCommitState('partner_reward_program_code')(partner_reward_program_code);
    curriedUpdateCommitState('partner_reward_program_member_id')(partner_reward_program_member_id);
  };

  render() {
    const { partner_reward_program, defaultLoyaltyContract, clearPartnerRewards, isTrueModify } = this.props;
    return (
      <FieldGroupCard title={utils.i18n('review_partner_rewards_title')}>
        <div className='review__partner-rewards-information'>
          <div className='review__section-card__field-row'>
            <div className='review__partner-rewards-information__details'>
              <div className='review__partner-rewards-information__label'>
                {partner_reward_program?.program_name}
                <ToolTip placement='top-start' analyticsKey={ANALYTICS.PARTNER_REWARDS}>
                  <span>
                    {utils.i18n(
                      defaultLoyaltyContract
                        ? 'alamo_insider_partner_rewards_tooltip'
                        : 'review_partner_rewards_info_tool_tip'
                    )}
                  </span>
                </ToolTip>
              </div>

              {defaultLoyaltyContract && (
                <div className='review__partner-rewards-information__label'>
                  {utils.i18n('alamo_insider_partner_rewards_member_number', [partner_reward_program.member_id])}
                </div>
              )}

              {!defaultLoyaltyContract && !isTrueModify && (
                <Button
                  link
                  button={false}
                  className='review__partner-rewards-information__remove-button link--text'
                  onClick={clearPartnerRewards}>
                  {utils.i18n('review_partner_rewards_info_remove_button')}
                </Button>
              )}
            </div>

            {!defaultLoyaltyContract && (
              <TextField
                name='partner_reward_program.member_id'
                label={utils.i18n('review_partner_rewards_info_field_title')}
                fill
                initialValueButton={partner_reward_program?.member_id}
                required
                validations={[utils.fieldValidation.required]}
                onChange={this.updatePartnerRewards}
              />
            )}
          </div>
        </div>
      </FieldGroupCard>
    );
  }
}

PartnerRewardsCard.propTypes = {
  // container
  clearPartnerRewards: PropTypes.func.isRequired,
  isTrueModify: PropTypes.bool,

  // parent props
  partner_reward_program: PropTypes.object,
  defaultLoyaltyContract: PropTypes.bool,
  curriedUpdateCommitState: PropTypes.func.isRequired,
};
