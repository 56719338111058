import { connect } from 'react-redux';
import { setProductCode } from 'actions/bookingWidget';
import { MODAL } from 'constants';
import { getAllVehiclesListSelector } from 'selectors/vehicleSelectSelectors';
import { clearDiscountInReservationFlow } from "actions/reservation/clearDiscountInReservationFlow";
import { openModal } from "actions/modal/openModal";
import { closeModal } from "actions/modal/closeModal";
import DiscountCodeVehicleClass from './DiscountCodeVehicleClass';

const mapStateToProps = state => {
  const vehicleCategoriesInitialValues = getAllVehiclesListSelector(state);
  return {
    vehicle_categories: vehicleCategoriesInitialValues || [],
  };
};

const mapDispatchToProps = {
  clearDiscountInReservationFlow,
  setProductCode,
  closeModal,
  showVehicleClassFilterModal: () => openModal(MODAL.VEHICLE_CLASS_FILTER_MODAL, { skipAnalytics: true }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscountCodeVehicleClass);
