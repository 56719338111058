import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import RentalFlow from './RentalFlow';

const RentalFlowWrapper = (props) => (
  <Router basename='/'>
    <CompatRouter>
      <RentalFlow {...props} />
    </CompatRouter>
  </Router>
);

export default RentalFlowWrapper;
