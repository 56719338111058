import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS, RESERVATIONS, LOCATIONS } from 'constants';
import Button from 'components/Button';
import LocationDateTimeFieldGroup from 'components/BookingWidget/LocationDateTimeFieldGroup';
import cn from 'classnames';
import setFieldData from 'final-form-set-field-data';
import Form from 'components/Form/Form';
import FirstInvalidFieldSpy from 'components/Form/FirstInvalidFieldSpy';

const { EXCHANGE_TYPE_PICKUP, EXCHANGE_TYPE_RETURN } = RESERVATIONS;
const { TYPE_CITY } = LOCATIONS;

/**
 * When the user picks a location instead of a branch we take them to the `store finder` view
 * if they pick a branch that isnt open at the picked time we pop this modal.  If you make changes to time picker or
 * date picker please check this modal!  Here are some test cases:
 *
 * Invalid time, one way:
 * Select CHI, the place not a location. Pick any day and select 2 AM for the time on the return time. When you init click the first option on the location list,
 * Chicago Loop W. LaSalle St. You should see the unavailable location modal pop. The invalid time, return one. Should be red and you should not see a
 * time there (field should be empty). If you were to click update the error field should open up and an individual error should show in that field.
 *
 * Round trip, round trip, bad date and time:
 *
 * For the pick up place pick Provo and select 2 AM on a Tuesday. For return time pick CHI, 2AM, any day. One the pick up location select the Provo, UT
 * Municipal Airport. The date should error out according to the rules above. Once you correct that day the time should error out according to the rules
 * above. Then when you click update it should take you to the Chicago location. Same rules should apply for this page as the invalid time one way.
 *
 * The flow of this Component is user changes one of the inputs/ selects a branch -> make a validation request to get the hours for that selected
 * branch.
 */

const UnavailableLocationModalContent = (props) => {
  const formRef = useRef(null);
  const formId = utils.dom.uuid();

  const {
    locationName,
    locationIdSelectedInUnavailableLocationModal,
    pickupDate,
    pickupDateValue,
    pickupEligibility,
    pickupTime,
    returnDate,
    returnDateValue,
    returnEligibility,
    returnTime,
    one_way_rental,
    returnLocationType,
    pickUpLocationType,
    isOneWayReservation,
    exchangeType,
    pickupDateError,
    returnDateError,
    pickupTimeError,
    returnTimeError,
    updatePickupAndReturnTimes,
    updateReservationFlowLocation,
    closeModal,
    location,
    unSetLocationDataUnavailableLocationModal,
    fetchTimesForUnavailableLocationModal,
    returnLocationId,
    pickupLocationId,
  } = props;

  const [pickupLocationIdState, setPickupLocationIdState] = useState(pickupLocationId);
  const [returnLocationIdState, setReturnLocationIdState] = useState(returnLocationId);
  const isDateTimeError = pickupDateError || returnDateError || pickupTimeError || returnTimeError;

  const dateTimeErrors = [];
  if (isDateTimeError) {
    if (pickupDateError) {
      dateTimeErrors.push({ errorMessage: utils.i18n('unavailable_location_modal_invalid_pickup_date') });
    }
    if (returnDateError) {
      dateTimeErrors.push({ errorMessage: utils.i18n('unavailable_location_modal_invalid_return_date') });
    }
    if (pickupTimeError) {
      dateTimeErrors.push({ errorMessage: utils.i18n('unavailable_location_modal_invalid_pickup_time') });
    }
    if (returnTimeError) {
      dateTimeErrors.push({ errorMessage: utils.i18n('unavailable_location_modal_invalid_return_time') });
    }
  }

  const onSubmit = () => {
    if (!isDateTimeError) {
      // to prevent user submit/update with invalid date/time
      sessionStorage.setItem(ANALYTICS.SKIP_ANALYTICS_FOR_DATE_TIME_DRAWER_ON_UNAVAILABLE_LOCATION_MODAL_SUBMIT, true);
      const pickUpDateTime = `${pickupDate}T${pickupTime}`;
      const returnDateTime = `${returnDate}T${returnTime}`;
      if (!one_way_rental) {
        updatePickupAndReturnTimes(pickUpDateTime, EXCHANGE_TYPE_PICKUP);
        updatePickupAndReturnTimes(returnDateTime, EXCHANGE_TYPE_RETURN);
      } else if (exchangeType === EXCHANGE_TYPE_RETURN) {
        updatePickupAndReturnTimes(returnDateTime, EXCHANGE_TYPE_RETURN);
      } else {
        updatePickupAndReturnTimes(pickUpDateTime, EXCHANGE_TYPE_PICKUP);
      }
      closeModal({ analyticsValue: ANALYTICS.UPDATE });
      updateReservationFlowLocation(location, exchangeType);

      // On Submit, ProgressBarDatesTimesDrawer get update and the analytics values are pushed
      // Passing a key to sessionStorage on submit (using this key in middleware to prevent analytics push)
      // clearing sessionStorage values (with closing the modal) once the  pickup_time and return_time got updated for ProgressBarDatesTimesDrawer.
      if (sessionStorage.getItem(ANALYTICS.SKIP_ANALYTICS_FOR_DATE_TIME_DRAWER_ON_UNAVAILABLE_LOCATION_MODAL_SUBMIT)) {
        setTimeout(() => {
          sessionStorage.removeItem(ANALYTICS.SKIP_ANALYTICS_FOR_DATE_TIME_DRAWER_ON_UNAVAILABLE_LOCATION_MODAL_SUBMIT);
        }, 10);
      }
    }
  };

  const initialValues = useMemo(() => {
    let newPickupDateValue = pickupDate;
    let newReturnDateValue = returnDate;
    let newPickupTimeValue = pickupTime;
    let newReturnTimeValue = returnTime;

    if (!one_way_rental || exchangeType === EXCHANGE_TYPE_PICKUP) {
      if (pickupDateError) {
        newPickupDateValue = '';
      }
      if (pickupTimeError) {
        newPickupTimeValue = '';
      }
    }

    if (!one_way_rental || exchangeType === EXCHANGE_TYPE_RETURN) {
      if (returnDateError) {
        newReturnDateValue = '';
      }
      if (returnTimeError) {
        newReturnTimeValue = '';
      }
    }

    return {
      pickupDate: newPickupDateValue,
      returnDate: newReturnDateValue,
      pickupTime: newPickupTimeValue,
      returnTime: newReturnTimeValue,
    };
  }, [pickupDate, returnDate, pickupTime, returnTime, one_way_rental, exchangeType]);

  const handleClick = () => {
    unSetLocationDataUnavailableLocationModal();
    closeModal();
  };

  const getUsableDate = (date) => {
    const newDate = utils.gmi.getDateTimeObjFromTs(date);
    return newDate.format('YYYY-MM-DD');
  };

  //  This function shows the error if the user clicks the update button and they haven't selected a date/ time
  const validate = (values) => {
    const errors = {};
    // Only validate when pickup and return dates are updated on Redux state (coming from Container)
    if (getUsableDate(values?.pickupDate) === pickupDate && pickupDateError) {
      errors.pickupDate = utils.i18n('unavailable_location_modal_invalid_date');
    }
    if (getUsableDate(values?.pickupTime) === pickupTime && pickupTimeError) {
      errors.pickupTime = utils.i18n('unavailable_location_modal_invalid_time');
    }
    if (getUsableDate(values?.returnDate) === returnDate && returnDateError) {
      errors.returnDate = utils.i18n('unavailable_location_modal_invalid_date');
    }
    if (getUsableDate(values?.returnTime) === returnTime && returnTimeError) {
      errors.returnTime = utils.i18n('unavailable_location_modal_invalid_time');
    }
    return errors;
  };

  //  This makes a validation request for the branch/time selected
  const makeValidationRequest = (_pickupDate, _pickupTime, _returnDate, _returnTime) => {
    fetchTimesForUnavailableLocationModal(location.gps, _pickupDate, _pickupTime, _returnDate, _returnTime, location);
  };

  //  We make a validation for the field that has changed
  const onHandleUnavailableLocationModalContent = (timeOrDate, inputChanged) => {
    if (inputChanged === 'pickupTime' && timeOrDate !== pickupTime) {
      makeValidationRequest(pickupDate, timeOrDate, returnDate, returnTime);
    }
    if (inputChanged === 'pickupDate') {
      const pickupDateFormatted = getUsableDate(timeOrDate);
      pickupDateFormatted !== pickupDate &&
        makeValidationRequest(pickupDateFormatted, pickupTime, returnDate, returnTime);
    }

    if (inputChanged === 'returnDate') {
      const returnDateFormatted = getUsableDate(timeOrDate);
      returnDateFormatted !== returnDate &&
        makeValidationRequest(pickupDate, pickupTime, returnDateFormatted, returnTime);
    }
    if (inputChanged === 'returnTime' && timeOrDate !== returnTime) {
      makeValidationRequest(pickupDate, pickupTime, returnDate, timeOrDate);
    }
  };

  useEffect(() => {
    //  If we are in a round trip replace location ID's, with the one selected in the modal
    if (!isOneWayReservation) {
      setPickupLocationIdState(locationIdSelectedInUnavailableLocationModal);
      setReturnLocationIdState(locationIdSelectedInUnavailableLocationModal);
    }

    //  If we are doing are one way and the pick up is a city we want to replace the
    //  pick up with the branch location selected in the modal
    if (isOneWayReservation && pickUpLocationType === TYPE_CITY) {
      setPickupLocationIdState(locationIdSelectedInUnavailableLocationModal);
    }
    //  If we are doing are one way and the return location is a city we want to replace the
    //  return location with the branch location selected in the modal
    if (isOneWayReservation && returnLocationType === TYPE_CITY && pickUpLocationType !== TYPE_CITY) {
      setReturnLocationIdState(locationIdSelectedInUnavailableLocationModal);
    }
  }, [
    isOneWayReservation,
    pickUpLocationType,
    returnLocationType,
    setPickupLocationIdState,
    setReturnLocationIdState,
    locationIdSelectedInUnavailableLocationModal,
  ]);

  return useMemo(
    () => (
      <div className='unavailable-location-modal-content__container'>
        <div className='unavailable-location-modal-content__mobile-container'>
          <h3 className='unavailable-location-modal-content__sub-header'>
            {utils.i18n('unavailable_location_modal_sub_header')}
          </h3>
        </div>
        {isDateTimeError && (
          <div className='service-errors'>
            {dateTimeErrors?.map((message, index) => (
              <div key={index} className='service-error'>
                <span className='message-priority--ERROR'>{utils.i18n('message_priority_ERROR')}</span>
                <p>{message.errorMessage}</p>
              </div>
            ))}
          </div>
        )}
        <p
          className={cn(
            'unavailable-location-modal-content__rez-detail-header',
            'unavailable-location-modal-content__show-line'
          )}
        >
          {utils.i18n('progress_bar_location_title')}
        </p>
        <div className='unavailable-location-modal-content__location-container'>
          <h4 className='unavailable-location-modal-content__selected-location'>{locationName}</h4>
          <Button
            link
            data-dtm-track={utils.analytics.dtm(
              ANALYTICS.UI_BUTTON,
              ANALYTICS.CLOSED_LOCATION,
              ANALYTICS.CHANGE_LOCATION
            )}
            onClick={handleClick}
            className='unavailable-location-modal-content__change-location-link'
            aria-label={utils.i18n('unavailable_location_modal_change_location')}
          >
            {utils.i18n('unavailable_location_modal_change_location')}
          </Button>
        </div>
        <p className='unavailable-location-modal-content__rez-detail-header'>
          {utils.i18n('progress_bar_datestimes_title')}
        </p>
        <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate} mutators={{ setFieldData }}>
          {({ form, handleSubmit }) => (
            <form id={formId} onSubmit={handleSubmit} autoComplete='off' ref={formRef}>
              <div className='unavailable-location-modal-container'>
                <LocationDateTimeFieldGroup
                  pickup_location_id={pickupLocationIdState}
                  return_location_id={returnLocationIdState}
                  pickupDate={pickupDate}
                  pickupDateValue={pickupDateValue}
                  pickupEligibility={pickupEligibility}
                  pickupTime={pickupTime}
                  returnDate={returnDate}
                  returnDateValue={returnDateValue}
                  returnEligibility={returnEligibility}
                  returnTime={returnTime}
                  one_way_rental={one_way_rental}
                  showErrorsOnLoadReturn
                  showErrorsOnLoadPickup
                  onDateChangeReturn={onHandleUnavailableLocationModalContent}
                  onTimeChangeReturn={onHandleUnavailableLocationModalContent}
                  onDateChangePickUp={onHandleUnavailableLocationModalContent}
                  onTimeChangePickUp={onHandleUnavailableLocationModalContent}
                  monthsShownDatePicker={1}
                  timeAndDatePickerFullHeight
                  isUnavailableLocationContent
                />
              </div>
              <div className='unavailable-location-modal-content__submit-button-container'>
                <Button
                  data-dtm-track={utils.analytics.dtm(
                    ANALYTICS.UI_BUTTON,
                    ANALYTICS.CLOSED_LOCATION,
                    ANALYTICS.CONTINUE
                  )}
                  type='submit'
                  className='unavailable-location-modal-content__submit-button'
                >
                  {utils.i18n('unavailable_location_modal_submit_button')}
                </Button>
              </div>
              <FirstInvalidFieldSpy form={form} formId={formId} formRef={formRef} />
            </form>
          )}
        </Form>
      </div>
    ),
    [pickupLocationIdState, returnLocationIdState, pickupDate, returnDate, pickupTime, returnTime]
  );
};

UnavailableLocationModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  pickup_location_id: PropTypes.string,
  locationName: PropTypes.string,
  return_location_id: PropTypes.string,
  locationId: PropTypes.string,
  timeAndDatePickerFullHeight: PropTypes.bool,
};

UnavailableLocationModalContent.defaultProps = {};

export default UnavailableLocationModalContent;
