import React from 'react';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import useActions from 'hooks/useActions';
import { checkinStart } from 'actions/checkin';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * STCNotCheckedInModal - Modal shown to the user trying to start Skip The Counter
 * with a Reservation that OCI is not activated, giving the option to start check-in flow.
 * @return {JSX}
 */
const STCNotCheckedInModal = () => {
  const checkinStartHandler = useActions(checkinStart);

  return (
    <Modal
      modalKey={MODAL.STC_NOT_CHECKED_IN_MODAL}
      theme={MODAL_THEMES.WHITE}
      header={utils.i18n('stc_not_checked_in_modal_title')}>
      <>
        <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
          {utils.i18n('stc_not_checked_in_modal_copy')}
        </p>

        <ModalFooter
          buttons={[
            {
              type: DECLINE,
              label: utils.i18n('common_cancel'),
              analyticsValue: ANALYTICS.CANCEL,
            },
            {
              type: ACCEPT,
              label: utils.i18n('stc_not_checked_in_modal_start_oci'),
              handler: checkinStartHandler,
              analyticsHandler: () =>
                utils.analytics.interaction(
                  ANALYTICS.MODAL,
                  utils.analytics.prepareKey(MODAL.STC_NOT_CHECKED_IN_MODAL),
                  ANALYTICS.ACTIVATE
                ),
            },
          ]}
        />
      </>
    </Modal>
  );
};

export default STCNotCheckedInModal;
