import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import Anchor from 'components/Anchor';
import cn from 'classnames';
import Button from 'components/Button';
import { breakpointSelector } from 'selectors/breakpoint';
import VisHiddenText from 'components/VisHiddenText';
import './styles/title-list.scss';
import Picture from 'components/Picture';
import adChoicesIcon from 'images/adChoices-logo.svg';
import privacyOptionIcon from 'images/privacy-options-icon.svg';

const ICON_MAPPING = {
  'ad-choices': {
    enabled: utils.config.getIsEnableAdChoiceIcon(),
    icon: adChoicesIcon,
  },
  'privacy-choices': {
    enabled: utils.config.getIsEnablePrivacyChoiceIcon(),
    icon: privacyOptionIcon,
  },
};

const getIconInfo = (name) => ICON_MAPPING[name];

const TitleList = ({ className, items, titleText }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { isMobile } = useSelector(breakpointSelector);
  const showCollapsedList = isMobile && !collapsed;

  const handleToggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    !!items?.length && (
      <nav className={cn('title-list-nav', className, { collapsed })}>
        {titleText && isMobile ? (
          <h3 className='title-list-nav__title'>
            <Button
              className='title-list-nav__button'
              onClick={handleToggleCollapsed}
              type='button'
              button={false}
              ariaExpanded={collapsed ? 'false' : 'true'}
              removeAriaLabel={true}>
              {titleText}
            </Button>
          </h3>
        ) : (
          <h3 className='title-list-nav__title nav__title-only'>{titleText}</h3>
        )}
        {(showCollapsedList || !isMobile) && (
          <ul className='title-list-nav__list' role='menu'>
            {items.map(({ external, name, title, url }) => {
              const iconInfo = getIconInfo(name);

              return (
                <li className='title-list-nav__list-item' key={name}>
                  <Anchor
                    className={cn('title-list-nav__link', {
                      internal: !external,
                      externalTextDecoration: external,
                      'has-custom-link-icon': iconInfo?.enabled,
                      [`${name}-custom-icon`]: iconInfo?.enabled,
                    })}
                    href={url}
                    fromFooter
                    isExternal={external}>
                    {title}
                    {external && (
                      <span className='title-list-external-icon'>
                        <VisHiddenText message='Opens in a new window' />
                      </span>
                    )}
                    {iconInfo?.enabled && <Picture src={iconInfo.icon} className='title-list-custom-icon' />}
                  </Anchor>
                </li>
              );
            })}
          </ul>
        )}
      </nav>
    )
  );
};

TitleList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  titleText: PropTypes.string.isRequired,
};

export default TitleList;
