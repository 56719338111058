import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CHECKIN from 'constants/checkin';
import utils from 'utils';
import MODAL from 'constants/modals';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import useActions from 'hooks/useActions';
import { checkinCommit } from 'actions/checkin';
import {
  havePriceDifferenceSelector,
  pickupLocationIsAirportSelector,
  reservationAirlineInfoSelector,
} from 'selectors/reservationSelectors';
import { reservationPartnerRewardsProgramSelector } from 'selectors/partnerRewardsSelectors';
import {
  isCustomPathReservationSelector,
  isRestrictedReservationSelector,
  isVirginReservationSelector,
} from 'selectors/customPathCheckInSelectors';
import { isEUPrepaySelector } from 'selectors/reservationFlowSelectors';
import Form from 'components/Form/Form';
import useAdditionalDriversList from 'hooks/useAdditionalDriversList';
import FrequentTravelerSurchargeModal from 'components/modals/FrequentTravelerSurchargeModal';
import VirginAtlanticFlyingClubModal from 'components/modals/VirginAtlanticFlyingClubModal';
import TripEnhancementsModal from 'components/modals/TripEnhancementsModal';
import LimitedInventoryModal from 'components/modals/LimitedInventoryModal';
import PostResTripSummary from 'components/PostRes/PostResTripSummary';
import PostResFlightInfo from 'components/PostRes/PostResFlightInfo';
import PostResFrequentTravelInfo from 'components/PostRes/PostResFrequentTravelInfo';
import PostResAdditionalDrivers from 'components/PostRes/PostResAdditionalDrivers';
import PostResTripEnhancements from 'components/PostRes/PostResTripEnhancements';
import PostResReviewPrice from 'components/PostRes/PostResReviewPrice';
import WarnDriverDetailsMissingOciRequestModal from 'components/modals/AdditionalDriverModals/WarnDriverDetailsMissingOciRequestModal';
import { uiStateReservationCommitPath } from 'paths/uiState';
import { modifyReservationStart } from "actions/reservation/modifyReservationStart";
import { openModal } from "actions/modal/openModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import CheckInReviewExtras from './CheckInReviewExtras';
import CheckInReviewTemplate from './CheckInReviewTemplate';

/**
 * CheckInReview
 * Form that handles Reservation Review info display + update.
 *
 * @return {JSX} CheckInReview jsx component
 */
const CheckInReview = () => {
  const [storeFormData, setStoreFormData] = useState();
  const handleCommit = useActions(checkinCommit);
  const modifyStart = useActions(modifyReservationStart);
  const openDriverDetailsMissingModal = useActions(() => openModal(MODAL.DRIVER_DETAILS_MISSING_OCI_REQUEST_MODAL));

  const havePriceDifference = useSelector(havePriceDifferenceSelector);
  const isEUPrePay = useSelector(isEUPrepaySelector);
  const isAirport = useSelector(pickupLocationIsAirportSelector);
  const airlineInfo = useSelector(reservationAirlineInfoSelector);
  const reservationPartnerRewardsProgram = useSelector(reservationPartnerRewardsProgramSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const isVirginReservation = useSelector(isVirginReservationSelector);
  const isRestrictedReservation = useSelector(isRestrictedReservationSelector);
  const commitState = useSelector(state => utils.safeToJS(state?.getIn(uiStateReservationCommitPath)));
  const { driversList, allowMoreDrivers, numberOfFreeDrivers } = useAdditionalDriversList();

  const handleFormSubmit = formData => {
    if (isCustomPathReservation) {
      /* If custom path user has additional driver(s) on reservation without their details and attempts to check in,
      then user should see the warning modal. */
      const isDriverInfoComplete = driversList.length > 0 && driversList.every(driver => !driver.partial_driver_info);
      if (allowMoreDrivers && numberOfFreeDrivers > 0 && driversList.length < numberOfFreeDrivers) {
        openDriverDetailsMissingModal();
        setStoreFormData(formData);
      } else if (
        allowMoreDrivers &&
        numberOfFreeDrivers > 0 &&
        driversList.length > numberOfFreeDrivers &&
        !isDriverInfoComplete
      ) {
        openDriverDetailsMissingModal();
        setStoreFormData(formData);
      } else if (allowMoreDrivers && driversList.length > 0 && !isDriverInfoComplete) {
        openDriverDetailsMissingModal();
        setStoreFormData(formData);
      } else {
        handleCommit(formData, { redirect: false, isFromOCICustomPathFlow: true });
      }
    } else {
      handleCommit(formData);
    }
  };

  const openExitModal = useActions(() =>
    openModalWithProps(MODAL.EXIT_CHECK_IN_FLOW_MODAL, {
      onExit: () =>
        utils.loadingRedirect(
          isCustomPathReservation ? WINDOW_OBJECT_KEYS.CUSTOM_PATH_RENTAL_DETAILS : WINDOW_OBJECT_KEYS.RENTAL_DETAILS
        ),
    })
  );

  const openModifyModal = useActions(() =>
    openModalWithProps(MODAL.EXIT_CHECK_IN_FLOW_MODAL, {
      onExit: modifyStart,
    })
  );

  const initialValues = useMemo(
    () => ({
      airline_information: {
        code: airlineInfo.code || commitState.airline_information?.code,
        flight_number: airlineInfo.flight_number || commitState.airline_information?.flight_number,
      },
      partner_reward_program_code: reservationPartnerRewardsProgram?.code,
      partner_reward_program_member_id: reservationPartnerRewardsProgram?.memberId,
    }),
    [
      airlineInfo.code,
      airlineInfo.flight_number,
      reservationPartnerRewardsProgram?.memberId,
      reservationPartnerRewardsProgram?.code,
      commitState.airline_information?.code,
      commitState.airline_information?.flight_number,
    ]
  );

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true, submitFailed: true }}>
      {({ form, handleSubmit }) => (
        <CheckInReviewTemplate
          confirmAction={handleSubmit}
          exitCheckin={openExitModal}
          havePriceDifference={havePriceDifference}>
          <form onSubmit={handleSubmit} noValidate>
            <PostResTripSummary
              handleModifyReservation={openModifyModal}
              editProfileLinkTo={CHECKIN?.CHECKIN_PATH_CONFIG?.profile[0]}
            />
            <PostResAdditionalDrivers />
            {isAirport && <PostResFlightInfo />}
            {isCustomPathReservation && <PostResFrequentTravelInfo />}
            {isCustomPathReservation && <PostResTripEnhancements />}
            {!isEUPrePay && !isCustomPathReservation && <CheckInReviewExtras />}
          </form>
          {isVirginReservation && <VirginAtlanticFlyingClubModal />}
          {isRestrictedReservation && <FrequentTravelerSurchargeModal />}
          {isCustomPathReservation && <LimitedInventoryModal />}
          {isCustomPathReservation && <TripEnhancementsModal />}
          <PostResReviewPrice />
          <WarnDriverDetailsMissingOciRequestModal formData={storeFormData} />
        </CheckInReviewTemplate>
      )}
    </Form>
  );
};

export default CheckInReview;
