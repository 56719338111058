import { connect } from 'react-redux';
import { MODAL } from 'constants';
import { getModifyFlag } from 'selectors/reservationSelectors';
import { openModal } from "actions/modal/openModal";
import PartnerRewardsCard from './PartnerRewardsCard';

const mapStateToProps = state => ({
  isTrueModify: getModifyFlag(state),
});

const mapDispatchToProps = {
  clearPartnerRewards: () => openModal(MODAL.REMOVE_PARTNER_REWARDS_MODAL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerRewardsCard);
