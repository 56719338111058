import PropTypes from 'prop-types';

const priceObj = PropTypes.shape({
  amount: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
});

const ratesObj = PropTypes.shape({
  charge_type: PropTypes.string.isRequired,
  extras: PropTypes.object.isRequired,
  price_summary: PropTypes.shape({
    estimated_total_taxes_and_fees_view: priceObj.isRequired,
    estimated_total_vehicle_view: priceObj.isRequired,
    payment_line_items: PropTypes.shape({
      FEE: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string.isRequired,
          total_amount_view: priceObj,
        })
      ),
    }).isRequired,
  }).isRequired,
});

export const propTypes = {
  // Container Props
  breakpoint: PropTypes.object.isRequired,
  selectCarClass: PropTypes.func.isRequired,
  showConvertedTotalsModal: PropTypes.func.isRequired,
  showTermsOfUseModal: PropTypes.func.isRequired,
  getCarClassDetails: PropTypes.func.isRequired,
  showTaxesFeesModal: PropTypes.func.isRequired,
  loadingDetails: PropTypes.bool,
  prePayRates: ratesObj,
  payLaterRates: ratesObj,
  learnAboutPrepayAndPayLaterModal: PropTypes.func.isRequired,
  showLimitedInventoryVehicleModal: PropTypes.func.isRequired,

  // Parent Props
  vehicle: PropTypes.shape({
    name: PropTypes.string,
    make_model_or_similar_text: PropTypes.string,
    features: PropTypes.array.isRequired,
    people_capacity: PropTypes.number,
    luggage_capacity: PropTypes.number,
    images: PropTypes.shape({
      ThreeQuarter: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }).isRequired,
    }),
    two_or_four_door_text: PropTypes.string,
    charges: PropTypes.shape({
      PAYLATER: PropTypes.shape({
        total_price_payment: priceObj.isRequired,
        total_price_view: priceObj.isRequired,
      }),
      PREPAY: PropTypes.shape({
        total_price_payment: priceObj.isRequired,
      }),
    }).isRequired,
    price_differences: PropTypes.arrayOf(
      PropTypes.shape({
        difference_amount_payment: priceObj.isRequired,
        difference_type: PropTypes.string.isRequired,
      })
    ),
    mileage_info: PropTypes.shape({
      unlimited_mileage: PropTypes.bool.isRequired,
    }),
    guaranteed_vehicle: PropTypes.bool,
    coupons: PropTypes.array,
    upgrade_car_class_name: PropTypes.string,
  }),
  discountContractDetails: PropTypes.object,
  selected: PropTypes.bool,
  prepaySelected: PropTypes.bool,
  prepayExistsInResults: PropTypes.bool,
  isEuropeanUnionCountry: PropTypes.bool,
  reservationCoupons: PropTypes.array,
  isLastMinuteSpecials: PropTypes.bool,
};

export const defaultProps = {};
