import PropTypes from 'prop-types';

export const propTypes = {
  /**
   * Total count of filtered vehicles
   * @type {Number}
   */
  vehiclesCount: PropTypes.number.isRequired,

  /**
   * Indicator for vehicle landing page
   * @type {Boolean}
   */
  isVehicleLandingPage: PropTypes.bool,

  /**
   * Name of the current category on vehicle type pages
   * @type {String}
   */
  categoryName: PropTypes.string,
};

export const defaultProps = { isVehicleLandingPage: false };
