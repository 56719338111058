import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';

export const propTypes = {
  locationInfo: PropTypes.object,
  locationInfoById: PropTypes.func,
  id: PropTypes.string,
};

export const defaultProps = setDefaultProps();
