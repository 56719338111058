import React from 'react';
import { Container } from '@adobe/aem-react-editable-components';
import utils from 'utils';
import cn from 'classnames';
import { propTypes } from './DrawerPropTypes';

/**
  This is a Drawer component
*/

class Drawer extends Container {
  static propTypes = propTypes;

  get containerProps() {
    return {
      ...super.containerProps,
      className: cn(super.containerProps.className, 'calloutContainer', 'cq-Editable-dom cq-Editable-dom--container'),
    };
  }

  get placeholderProps() {
    return {
      ...super.placeholderProps,
      placeholderClassNames: `${super.placeholderProps.placeholderClassNames} aem-Grid-newComponent cq-Editable-dom`,
    };
  }

  render() {
    const { title, titleSuffix, titleIcon, titleIconActive, openText, closeText } = this.props;
    const uuid = utils.dom.uuid();

    return (
      <section className='drawer' {...this.containerProps}>
        <input type='checkbox' className='drawer__toggle' id={uuid} />
        <label className='drawer__header' htmlFor={uuid}>
          <h3 className='drawer__header__title'>
            {titleIcon && <img className='drawer__header__icon' src={titleIcon} alt={title} />}
            {titleIconActive && (
              <img className='drawer__header__icon drawer__header__icon--active' src={titleIconActive} alt={title} />
            )}
            {title}
            {titleSuffix && <span> ({titleSuffix})</span>}
          </h3>
          <div className='drawer__header__openCta'>{openText}</div>
          <div className='drawer__header__closeCta'>{closeText}</div>
        </label>
        <div className='drawer__content'>{this.childComponents}</div>
        {super.placeholderComponent}
      </section>
    );
  }
}

export default Drawer;
