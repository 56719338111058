import React from 'react';
import utils from 'utils';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL_BUTTON_TYPES } from 'constants';
import propTypes from './removeCIDModalContentPropTypes';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const RemoveCIDModalContent = ({ contract_name, handleClose, clearDiscountInReservationFlow }) => {
  const clearCID = () => {
    clearDiscountInReservationFlow();
    handleClose({ analyticsValue: utils.i18n('progress_bar_cid_remove_modal_accept') });
  };

  return (
    <>
      <p>{utils.i18n('progress_bar_cid_remove_modal_main_text', [contract_name])}</p>
      <ModalFooter
        buttons={[
          {
            type: DECLINE,
            label: utils.i18n('progress_bar_cid_remove_modal_decline'),
          },
          {
            type: ACCEPT,
            label: utils.i18n('progress_bar_cid_remove_modal_accept'),
            handler: clearCID,
          },
        ]}
      />
    </>
  );
};

RemoveCIDModalContent.propTypes = propTypes;

export default RemoveCIDModalContent;
