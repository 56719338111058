import { connect } from 'react-redux';
import utils from 'utils';
import ProgressBarVehicle from './ProgressBarVehicle';

const carClassDetailCursor = ['gmi', 'session', 'gbo', 'reservation', 'car_class_details'];
const mapStateToProps = state => {
  const carClassDetails = state.getIn(carClassDetailCursor);
  return {
    vehicleName: carClassDetails?.get('name'),
  };
};

export default connect(mapStateToProps)(utils.toJS(ProgressBarVehicle));
