import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';

/**
 * Component to display the total price (and, when applicable, the incremental pricing) of a vehicle class
 *  during vehicle selection.
 * @param {object} props                      - React Props
 * @param {object} props.incrementalPricing   - Highest level incremental display for the vehicle pricing. i.e.:
 *  const incrementalPricing = {
 *    unit_amount_view: {
 *      symbol: '$',
 *      amount: '50.10'
 *    },
 *    unit_rate_type: 'WEEKLY'
 *  };
 *  // displayed as: $ 50.10 Weekly
 * @param {string} props.symbol               - Currency symbol to display for the total price (i.e. $ or €).
 * @param {string} props.amount               - Amount to be displayed for the total price (i.e. 90.20).
 * @return {JSX} PriceComponent component
 */
const PriceComponent = ({ incrementalPricing, symbol, amount }) => {
  const shouldShowIncrementalPricing =
    utils.config.getIsIncrementalPricingEnabled() && incrementalPricing?.unit_amount_view;

  return (
    <div className='vehicle-price-component'>
      {shouldShowIncrementalPricing ? (
        <p className='vehicle-price-component__charge'>
          <span className='vehicle-price-component__pay-symbol'>{incrementalPricing.unit_amount_view.symbol}</span>
          {incrementalPricing.unit_amount_view.amount}
          <span className='vehicle-price-component__total-text vehicle-price-component__rate-text'>
            {utils.i18n(`vehicle_select_incremental_rate_${incrementalPricing.unit_rate_type}`)}
          </span>
        </p>
      ) : null}
      <p
        className={cn('vehicle-price-component__charge', {
          'vehicle-price-component__charge--secondary': shouldShowIncrementalPricing,
        })}>
        <span className='vehicle-price-component__pay-symbol'>{symbol}</span>
        {amount}
        <span className='vehicle-price-component__total-text'>{utils.i18n('vehicle_select_price_total')}</span>
      </p>
    </div>
  );
};

PriceComponent.propTypes = {
  incrementalPricing: PropTypes.object,
  symbol: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
};

export default PriceComponent;
