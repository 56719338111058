import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import useActions from 'hooks/useActions';
import { logout } from 'actions/authentication';
import { checkinStart } from 'actions/checkin';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * CheckInLoyaltyConflictModal
 * Modal that displays the Sign In component as its' content.
 * After a successful login, the modal closes.
 *
 * @return {JSX} CheckInLoyaltyConflictModal component
 */
const CheckInLoyaltyConflictModal = () => {
  const checkinStartAction = useActions(checkinStart);
  const logoutAction = useActions(logout);

  const onSignOutHandler = (handleClose, checkinOrigin = '') => () => {
    logoutAction({ loadingOverlay: true }).then(() => {
      handleClose();
      checkinStartAction({ origin: checkinOrigin });
    });
  };

  return (
    <Modal
      modalKey={MODAL.CHECKIN_LOYALTY_CONFLICT_MODAL}
      customClass='signin-modal'
      header={utils.i18n('check_in_loyalty_conflict_modal_title')}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose, checkinOrigin }) => (
        <>
          <p className='modal-themed__paragraph'>{utils.i18n('check_in_loyalty_conflict_modal_info')}</p>
          <p className='modal-themed__paragraph'>{utils.i18n('check_in_loyalty_conflict_modal_instructions')}</p>

          <ModalFooter
            buttons={[
              {
                type: DECLINE,
                label: utils.i18n('check_in_loyalty_conflict_modal_stay_signed_in_cta'),
                largeButton: true,
              },
              {
                type: ACCEPT,
                label: utils.i18n('common_sign_out'),
                handler: onSignOutHandler(handleClose, checkinOrigin),
                largeButton: true,
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default CheckInLoyaltyConflictModal;
