import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import THEMES from 'constants/themes';
import CHECKIN from 'constants/checkin';
import utils from 'utils';
import {
  selectedExtras,
  selectedBundleWithExtrasSelector,
  extrasWithoutBundlesSelector,
  numberOfExtrasSelector,
} from 'selectors/extrasSelectors';
import Button from 'components/Button';

/**
 * CheckInReviewExtras
 * Component that displays reservation's extras on the check-in review page,
 *  along with a CTA to add/edit the extras selection (routes to OCI extras page).
 *
 * @return {JSX}
 */
const CheckInReviewExtras = () => {
  const extras = useSelector(selectedExtras);
  const selectedBundleWithExtras = useSelector(selectedBundleWithExtrasSelector);
  const extrasWithoutBundles = useSelector(extrasWithoutBundlesSelector);
  const numberOfExtrasSelected = useSelector(numberOfExtrasSelector);

  const bundlesExtras = selectedBundleWithExtras?.bundleExtras || [];
  const hasBundleExtras = !!bundlesExtras.length;
  const bundledAdditionalDrivers =
    selectedBundleWithExtras?.vehicle_rates?.supplemental?.additional_driver?.number_of_free_additional_drivers || null;
  const extrasToMap = hasBundleExtras ? extrasWithoutBundles : extras;

  return (
    <div className='check-in-template__content theme--light'>
      <div className='check-in-review__section-header'>
        <h3 className='check-in-review__section-title'>
          {utils.i18n('extras_title_counter', [numberOfExtrasSelected])}
        </h3>
      </div>

      <div className='check-in-review__extras-list'>
        {!numberOfExtrasSelected ? (
          <div className='check-in-review__extra'>{utils.i18n('check_in_review_extras_empty')}</div>
        ) : (
          <>
            {extrasToMap.map(({ code, name, selected_quantity }) => (
              <div className='check-in-review__extra' key={code}>
                {name}
                {selected_quantity > 1 ? utils.i18n('rental_details_extra_quantity', [selected_quantity]) : null}
              </div>
            ))}
            {hasBundleExtras && (
              <div className='check-in-review__extra check-in-review__extra--bundle-title'>
                {selectedBundleWithExtras?.title}
              </div>
            )}
            {bundlesExtras.map(({ code, name, selected_quantity }) => (
              <div className='check-in-review__extra check-in-review__extra--bundle-item' key={code}>
                {name}
                {selected_quantity > 1 ? utils.i18n('rental_details_extra_quantity', [selected_quantity]) : null}
              </div>
            ))}
            {bundledAdditionalDrivers && (
              <div className='check-in-review__extra check-in-review__extra--bundle-item'>
                {utils.i18n('bundles_card_additional_drivers', [bundledAdditionalDrivers])}
              </div>
            )}
          </>
        )}
      </div>

      <Link to={CHECKIN.CHECKIN_PATH_CONFIG.extras[0]}>
        <Button
          className='check-in-review__edit-button'
          theme={THEMES.DARK}
          ghosted
          data-dtm-track='check_in|review|edit_extras'>
          {utils.i18n(numberOfExtrasSelected ? 'extras_button_edit' : 'extras_button_add')}
        </Button>
      </Link>
    </div>
  );
};

export default CheckInReviewExtras;
