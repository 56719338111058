import PropTypes from 'prop-types';
import { ListPropTypes, setDefaultProps } from 'data/sharedProps';

export const propTypes = Object.assign(ListPropTypes, {
  /**
   * A list item prop for rendering
   *
   * @type {Object|String}
   */
  listItem: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
});

export const defaultProps = setDefaultProps({
  listItem: {},
});
