import React from 'react';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';
import { propTypes } from './NoResultsPartnerDisplayProptypes';

class NoResultsPartnerDisplay extends React.Component {
  state = {
    isShowingCloseBtn: false,
  };

  static propTypes = propTypes;

  componentDidMount() {
    // Component mounts because first search provides only partner locations (without pan/zoom)
    if (this.props.shouldShowCloseButton) {
      this.setState({ isShowingCloseBtn: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { shouldShowCloseButton } = this.props;
    if (
      shouldShowCloseButton !== prevProps.shouldShowCloseButton &&
      shouldShowCloseButton !== prevState.isShowingCloseBtn
    ) {
      this.setState({ isShowingCloseBtn: shouldShowCloseButton });
    }
  }

  handleVisibility = () => {
    const { handleHidePartnerResultsToggle } = this.props;
    handleHidePartnerResultsToggle();
  };

  handleClick = () => {
    const { handleToggleShowPartnerLocations } = this.props;
    handleToggleShowPartnerLocations();
    this.setState({ isShowingCloseBtn: true });
  };

  render() {
    const { isShowingCloseBtn } = this.state;
    const { shouldShowCloseButton } = this.props;

    return (
      <div className='no-results-partner-display__container'>
        {isShowingCloseBtn && (
          <Button
            plain
            tabIndex='-1'
            onClick={this.handleVisibility}
            title={utils.i18n('no_results_partner_close')}
            aria-label={utils.i18n('no_results_partner_close')}
            className='no-results-partner-display__button'
          />
        )}
        <h3 className='no-results-partner-display__main-text'>{utils.i18n('no_results_partner_main_text')}</h3>
        <p className='no-results-partner-display__sub-text'>{utils.i18n('no_results_partner_sub_text')}</p>
        {(!isShowingCloseBtn || !shouldShowCloseButton) && (
          <Button
            data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.LOCATION, 'view_partner_locations')}
            className='button no-results-partner-display__view-partner-locations-button'
            onClick={this.handleClick}
          >
            {utils.i18n('location_finder_view_partner_locations_button')}
          </Button>
        )}
      </div>
    );
  }
}
export default NoResultsPartnerDisplay;
