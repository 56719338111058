import React, { useState, useRef } from 'react';
import cn from 'classnames';
import utils from 'utils';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import useClickOutside from 'hooks/useClickOutside';

/**
 * DiscountDropDown - a dropdown used to display discount banners on tablet because space is limited
 *
 * @param {object} props - React Props
 * @param {array}  props.discountElements - an array of discount banner React elements that is rendered as the
 *                                         dropdown content
 * @return {JSX}
 */

export default function DiscountDropDown({ discountElements }) {
  const [expanded, setExpanded] = useState(false);
  const dropdownContainer = useRef();

  const toggleExpand = () => setExpanded(prevState => !prevState);

  useClickOutside(
    {
      container: dropdownContainer,
      handler: toggleExpand,
      listenerConditional: expanded,
      // only activate the click listener while expanded
    },
    [expanded]
  );

  return (
    <div className='discount-dropdown__container' ref={dropdownContainer}>
      <Button
        className={cn('discount-dropdown__button', expanded && 'discount-dropdown__button--open')}
        onClick={toggleExpand}
        button={false}>
        {`${utils.i18n('navigation_bar_discount_dropdown_label')} (${discountElements.length})`}
      </Button>

      {expanded && <div className='discount-dropdown__dropdown'>{discountElements}</div>}
    </div>
  );
}

DiscountDropDown.propTypes = {
  discountElements: PropTypes.arrayOf(PropTypes.element).isRequired,
};
