import React from 'react';
import { useDispatch } from 'react-redux';
import { selectCarClass } from 'actions/reservation/selectCarClass';
import utils from 'utils';
import { ANALYTICS, MODAL_BUTTON_TYPES } from 'constants';
import { ModalFooter } from 'components/Modal/ModalFooter';
import termsOfUseVanModalContentPropTypes from './termsOfUseVanModalContentPropTypes';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

const TermsOfUseVanModalContent = (props) => {
  const { description, handleClose, code, prepay } = props;

  const dispatch = useDispatch();

  const handleAccept = () => {
    dispatch(selectCarClass({ code, prepay }));
    handleClose();
  };

  return (
    <>
      <p className='modal-themed__copy'>{utils.i18n('terms_use_van_model_main_title_sub_text')}</p>

      <div className='modal-themed__gbo-content' dangerouslySetInnerHTML={utils.sanitize(description)} />

      <ModalFooter
        buttons={[
          {
            type: DECLINE,
            label: utils.i18n('terms_use_van_model_decline'),
            analyticsValue: ANALYTICS.DECLINE,
          },
          {
            type: ACCEPT,
            label: utils.i18n('terms_use_van_model_accept'),
            analyticsValue: ANALYTICS.ACCEPT,
            handler: handleAccept,
          },
        ]}
      />
    </>
  );
};

TermsOfUseVanModalContent.propTypes = termsOfUseVanModalContentPropTypes;

export default TermsOfUseVanModalContent;
