import React, { Component } from 'react';
import cn from 'classnames';

const baseSelector = 'al-icon';
const classPrefix = 'al-icon-';
const iconNames = [
  'add',
  'additional-driver',
  'after-hours-alt',
  'after-hours',
  'alert',
  'arrow-alt-left',
  'arrow-alt-right',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'boat',
  'bookmark',
  'building',
  'bus',
  'calendar-checkmark',
  'calendar-empty',
  'calendar-full',
  'calendar-star',
  'car-door',
  'car-front-sparkle',
  'car-front',
  'car-side',
  'car-with-key',
  'car-x',
  'carseat',
  'chat-info',
  'chat',
  'checkmark',
  'checkmark-crossed',
  'checkmark-narrow',
  'chevron-alt-down',
  'chevron-alt-left',
  'chevron-alt-right',
  'chevron-alt-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'city',
  'city2',
  'clock-alt',
  'clock',
  'close-alt',
  'close-nav',
  'close',
  'close2',
  'counter-service',
  'counter',
  'credit-card',
  'cross',
  'directions',
  'document-list',
  'download',
  'edit',
  'enterprise',
  'envelope',
  'error',
  'expand',
  'external',
  'eye',
  'filter',
  'flag',
  'focus',
  'frown-alt',
  'frown',
  'funnel',
  'gas',
  'gavel',
  'gears',
  'globe',
  'grid',
  'guaranteed-res',
  'hamburger',
  'handshake',
  'heart',
  'help-alt',
  'help',
  'home',
  'icon-manual',
  'info',
  'key-normal',
  'key-old',
  'key-ring',
  'laptop',
  'license',
  'lightening',
  'list',
  'location',
  'lock',
  'low',
  'map-folded',
  'map-pin',
  'map',
  'map2',
  'microphone',
  'mileage',
  'minus',
  'mobile',
  'money',
  'more',
  'music',
  'national',
  'no',
  'notifications-off',
  'notifications-on',
  'page-1',
  'partners-res-flow',
  'percentage',
  'phone',
  'photo',
  'plane-port',
  'plane',
  'plus',
  'price-tag',
  'print',
  'pulling-luggage',
  'question',
  'radio',
  'rail',
  'reload',
  'review',
  'search',
  'settings',
  'share',
  'shield',
  'sign-out',
  'silverware',
  'skip-the-counter',
  'social-facebook',
  'social-pinterest',
  'social-twitter',
  'sort',
  'sparkle-one',
  'sparkle',
  'square-empty',
  'star',
  'stopwatch',
  'subtract',
  'suitcase',
  'tablet',
  'tolls',
  'tow-truck',
  'tow-truck2',
  'transmission',
  'trash',
  'turn-arrow',
  'turn',
  'umbrella',
  'user',
  'vector-arrow-alt-down',
  'vector-arrow-alt-right',
  'walking',
  'wifi',
];

class IconLibrary extends Component {
  render() {
    return (
      <div className='iconLibrary'>
        {iconNames.map(name => (
          <div key={name} className={cn(baseSelector, `${classPrefix}${name}`)}>
            <p>{name}</p>
          </div>
        ))}
      </div>
    );
  }
}

export default IconLibrary;
