import React, { useEffect, useState } from 'react';
import { utils as globalUtils, actions } from '@ehi/global-marketing-interface';
import { useDispatch } from 'react-redux';
import { analyticsClearErrors } from 'utils/analytics';
import Button from 'components/Button';
import DateTimeFieldset from 'components/Form/DateTimeFieldset';
import Form from 'components/Form/Form';
import utils from 'utils';
import { RESERVATIONS, LOCATIONS } from 'constants';
import { getFormattedContractRestrictionDates } from 'utils/reservation';
import CreditCardDateExpirationModal from 'components/modals/CreditCardDateExpirationModal/CreditCardDateExpirationModal';
import { MODIFY_RENTAL_ERRORS } from 'constants/errors';

const { initiateState } = actions.reservations;

const { LAST_MINUTE_SPECIALS, PLAN_AHEAD_SPECIALS, EXCHANGE_TYPE_RETURN } = RESERVATIONS;

const GMI_TIME_FORMAT = 'HH:mm';
const GMI_DATE_FORMAT = 'YYYY-MM-DD';

// If minutes are less than 30, round down to the nearest hour
const roundDownToNearestHalfHour = (timeStr) => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  const roundedMinutes = minutes < 30 ? '00' : '30';
  return `${hours.toString().padStart(2, '0')}:${roundedMinutes}`;
};

const ModifyReturnWidget = ({
  onProgressBar,
  afterSubmit,
  showCreditCardErrorModal,
  initiateModifyRental,
  returnDateAndTime,
  returnLocation,
  lastReturnDate,
  pickUpDateAndTime,
  contractDetails,
  onRentalError,
}) => {
  const pickupDate = globalUtils.getDateTimeObjFromTs(pickUpDateAndTime).format(GMI_DATE_FORMAT);
  const returnDate = globalUtils.getDateTimeObjFromTs(returnDateAndTime).format(GMI_DATE_FORMAT);

  let returnTime = globalUtils.getDateTimeObjFromTs(returnDateAndTime).format(GMI_TIME_FORMAT);
  returnTime = roundDownToNearestHalfHour(returnTime);

  const return_location_id = returnLocation?.id;

  const [returnDateStore, setReturnDateStore] = useState();
  const [returnTimeStore, setReturnTimeStore] = useState();
  const [formErrors, setFormErrors] = useState([]);
  const dispatch = useDispatch();

  // Contract Restrictions
  const contractIsLmOrPlanAheadSpecials =
    contractDetails && contractDetails?.contract_sub_type === (LAST_MINUTE_SPECIALS || PLAN_AHEAD_SPECIALS);
  const initialContractRestriction = contractDetails && contractDetails?.restrictions;
  const formattedContractRestrictions =
    initialContractRestriction &&
    getFormattedContractRestrictionDates(
      initialContractRestriction,
      contractDetails?.contract_sub_type === PLAN_AHEAD_SPECIALS
    );

  const onDateChange = (date) => {
    if (date) {
      const timestamp = utils.gmi.getDateTimeObjFromTs(date).format().slice(0, 10);

      if (timestamp > pickupDate) {
        setReturnDateStore(timestamp);
      }
      if (timestamp !== returnDateStore) {
        const exchangeType = EXCHANGE_TYPE_RETURN;
        dispatch(initiateState.set('date', { timestamp, exchangeType }));
      }
    }
  };

  const onTimeChange = (timestamp) => {
    setReturnTimeStore(timestamp);
    const exchangeType = EXCHANGE_TYPE_RETURN;
    if (timestamp !== returnTimeStore) {
      dispatch(initiateState.set('time', { timestamp, exchangeType }));
    }
  };

  const onSubmit = () => {
    analyticsClearErrors();
    setFormErrors([]);
    if (onRentalError && typeof onRentalError === 'function') {
      onRentalError([]);
    }

    const payload = {
      return_location: {
        name: returnLocation?.name,
        type: LOCATIONS.TYPE_BRANCH,
        date_time: `${returnDateStore}T${returnTimeStore}`,
        location_type: LOCATIONS.TYPE_BRANCH,
        id: returnLocation?.id,
        latitude: returnLocation?.gps?.latitude,
        longitude: returnLocation?.gps?.longitude,
      },
    };

    initiateModifyRental(payload, afterSubmit).then((res) => {
      if (res?.messages?.length) {
        setFormErrors(res.messages);

        if (onRentalError && typeof onRentalError === 'function') {
          onRentalError(res.messages);
        }
        if (afterSubmit && typeof afterSubmit === 'function') {
          afterSubmit();
        }

        const creditCardError = res.messages.find((message) => message.code === MODIFY_RENTAL_ERRORS.CREDIT_CARD);

        if (creditCardError) {
          showCreditCardErrorModal();
        }
      }
    });
  };

  useEffect(() => {
    if (returnDate && returnDateStore !== returnDate) {
      setReturnDateStore(returnDate);
    }
  }, [returnDateAndTime]);

  useEffect(() => {
    if (returnTime && returnTimeStore !== returnTime) {
      setReturnTimeStore(returnTime);
    }
  }, [returnDateAndTime]);

  useEffect(() => {
    if (formErrors.length > 0) {
      setFormErrors([]);
      if (onRentalError && typeof onRentalError === 'function') {
        onRentalError([]);
      }
    }
  }, [returnDateStore, returnTimeStore]);

  return (
    <div className={`theme--light-blue modify-return-widget ${onProgressBar ? 'on-progress-bar' : ''}`}>
      <div className='row'>
        <div>
          <h2 className='modify-return-widget__title'>{utils.i18n('return_date_time')}</h2>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form className='modify-return-widget__booking-widget' onSubmit={handleSubmit} autoComplete='off'>
                <div className='modify-return-widget__booking-widget-inner-container'>
                  <DateTimeFieldset
                    {...{
                      selectsEnd: true,
                      startDate: pickupDate,
                      endDate: returnDateStore,
                      dateId: 'returnDate',
                      dateName: 'returnDate',
                      dateLabel: utils.i18n('date_range_label_return'),
                      helpText: utils.i18n('date_range_help_text_return'),
                      timeId: 'returnTime',
                      time: returnTimeStore,
                      timeName: 'returnTime',
                      timeLabel: utils.i18n('return_time'),
                      eligibility: false,
                      guided: true,
                      isSixColumnGrid: false,
                      onDateChange,
                      onTimeChange,
                      contractRestrictions: contractIsLmOrPlanAheadSpecials && formattedContractRestrictions,
                      latestDate: lastReturnDate,
                      isRental: true,
                      locationId: return_location_id,
                    }}
                  />
                  <Button className='modify-return-widget__booking-widget-button' type='submit'>
                    {utils.i18n('common_continue')}
                  </Button>
                </div>
              </form>
            )}
          </Form>

          {!onProgressBar && (
            <p className='modify-return-widget__disclaimer'>
              {utils.i18n('changes_return_details_may_result_changes_cost')}
            </p>
          )}
        </div>
      </div>

      <CreditCardDateExpirationModal />
    </div>
  );
};

export default ModifyReturnWidget;
