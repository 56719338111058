import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { WINDOW_OBJECT_KEYS } from 'constants';

const CondensedFooterLink = ({ url, label }) => (
  <a className='link link--text link--arrow' target='_blank' rel='noopener noreferrer' href={url}>
    {label}
  </a>
);

CondensedFooterLink.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
};

const CondensedFooter = () => {
  const adChoicesLink = utils.config.getAdChoicesUrl();
  const showAdChoices = utils.config.getShowAdChoices();

  return (
    <div className='condensed-footer component-theme--light'>
      <div className='condensed-footer__row row'>
        <ul className='condensed-footer__links'>
          <li>
            <CondensedFooterLink
              url={utils.config.getUrl(WINDOW_OBJECT_KEYS.PRIVACY_POLICY)}
              label={utils.i18n('condensed_footer_privacy_policy')}
            />
          </li>
          <li>
            <CondensedFooterLink
              url={utils.config.getUrl(WINDOW_OBJECT_KEYS.COOKIE_POLICY)}
              label={utils.i18n('condensed_footer_cookie_policy')}
            />
          </li>
          <li>
            <CondensedFooterLink
              url={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.TERMS_OF_USE_REDIRECT)}
              label={utils.i18n('condensed_footer_terms_of_use')}
            />
          </li>
          {showAdChoices && (
            <li>
              <CondensedFooterLink url={adChoicesLink} label={utils.i18n('ad_choices_label')} />
            </li>
          )}
        </ul>
        <p className='condensed-footer__copyright'>{utils.i18n('condensed_footer_copyright')}</p>
      </div>
    </div>
  );
};

export default CondensedFooter;
