import React, { Component } from 'react';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS } from 'constants';
import ExtraFlyoutTabDrawer from 'components/ReservationFlow/Extras/ExtraFlyoutTabDrawer';
import Button from 'components/Button';
import FlyoutTabDrawer from './FlyoutTabDrawer';
import { propTypes, defaultProps } from './FlyoutTabPropTypes';

class FlyoutTab extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  handleClick = () => {
    const { isExpanded, item, labelKey } = this.props;

    // Analytics
    utils.analytics.interaction(ANALYTICS.DRAWER, item[labelKey], isExpanded ? ANALYTICS.CLOSE : ANALYTICS.OPEN);

    this.props.onClick();
  };

  render() {
    const { isExpanded, icon, item, isSmall, fullWidth, uuid, contentKey, labelKey, isExtrasDrawer } = this.props;
    const { disabled } = item;
    const uuidButton = `${uuid}_button`;
    const uuidDrawer = `${uuid}_drawer`;
    const TabDrawer = isExtrasDrawer ? ExtraFlyoutTabDrawer : FlyoutTabDrawer;

    return (
      <li className={cn('flyout-tabs__list-item', { 'has-disabled': disabled })}>
        {disabled && <span className='flyout-tabs__disabled-text'>{utils.i18n('unavailable_at_this_location')}</span>}
        <div className={cn('flyout-tab', { disabled, expanded: !disabled && isExpanded })}>
          <Button
            plain
            className='flyout-tab__toggler'
            id={uuidButton}
            aria-label={item[labelKey]}
            aria-expanded={isExpanded.toString()}
            aria-controls={uuidDrawer}
            onClick={this.handleClick}
            role='tab'
            type='button'
          >
            <span className={`flyout-tab__title ${item.icon || icon || ''}`}>
              {item[labelKey]}
              {item.showCount ? ` (x${item.selected_quantity})` : ''}
            </span>
          </Button>

          {(isSmall || !fullWidth) && (
            <TabDrawer contentKey={contentKey} item={item} isExpanded={isExpanded} uuid={uuid} />
          )}
        </div>
      </li>
    );
  }
}

export default FlyoutTab;
