import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps';

export const propTypes = {
  locations: PropTypes.array.isRequired,
  title: PropTypes.shape({
    cta: PropTypes.string,
    linkURL: PropTypes.string,
    openInTab: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export const defaultProps = setDefaultProps({
  locations: [],
});
