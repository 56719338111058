import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import useActions from 'hooks/useActions';
import MODAL from 'constants/modals';
import { individualExtrasSelector } from 'selectors/extrasSelectors';
import MessageBanner from 'components/MessageBanner';
import Button from 'components/Button';
import Extra from 'components/Extra';
import { openModal } from "actions/modal/openModal";

const PostResTripEnhancements = () => {
  const [hasWarnedAboutLimitedInventoryItem, setHasWarnedAboutLimitedInventoryItem] = useState(false);
  const openTripEnhancementsModal = useActions(() => openModal(MODAL.TRIP_ENHANCEMENTS_MODAL));
  const individualExtras = useSelector(individualExtrasSelector);
  const { tripEnhancements } = individualExtras;

  return (
    <div className='post-res__trips-enhancement theme--light'>
      <div className='post-res__content'>
        <div className='post-res__section-header'>
          <h3 className='post-res__section-title'>{i18n('extras_trip_enhancements')}</h3>
        </div>
        <p className='post-res__paragraph'>
          {i18n(
            'trip_enhancements_custom_path',
            [
              <Button key={0} linkText onClick={openTripEnhancementsModal} className='post-res__link-text'>
                {i18n('common_learn_more')}
              </Button>,
            ],
            { jsx: true }
          )}
        </p>
      </div>
      {tripEnhancements && !!tripEnhancements.length ? (
        tripEnhancements.map(extra => (
          <Extra
            key={extra.code}
            hasWarnedAboutLimitedInventoryItem={hasWarnedAboutLimitedInventoryItem}
            setHasWarnedAboutLimitedInventoryItemToTrue={() => setHasWarnedAboutLimitedInventoryItem(true)}
            extra={extra}
            isCustomPathCheckIn
          />
        ))
      ) : (
        <MessageBanner
          title={i18n('extras_no_trip_enhancements_available_title')}
          message={i18n('extras_no_trip_enhancements_available_message')}
        />
      )}
    </div>
  );
};

export default PostResTripEnhancements;
