import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import RESERVATIONS from 'constants/reservations';
import LoadingWidget from 'components/LoadingWidget';
import Button from 'components/Button';
import FormattedPrice from 'components/FormattedPrice';
import { DISCOUNTS } from 'constants';
import RateRow from './RateRow';
import RateSection from './RateSection';

const { PAYLATER_CODE, PREPAY_CODE } = RESERVATIONS;

const generateExtrasRateRows = ({ extras, additionalDriver, onlyValue, convertedPrices }) => {
  // The extrasRateRows derivation code below derives the extras data retrieved from the carClassDetails service.
  // But the additional driver data is separate from and has a different structure than the extras object so
  // this code below compiles the additional driver data to be concatenated and consumed by the extras derivation
  const additionalDriverWithName = additionalDriver
    ? {
      ...additionalDriver,
      name: `${utils.i18n('included_additional_drivers_extra_name')} (x${
        additionalDriver.number_of_free_additional_drivers
      })`,
    }
    : [];
  return Object.values(extras)
    .flat()
    .concat(additionalDriverWithName)
    .reduce((acc, option) => {
      const isOptionMandatory = option.status === RESERVATIONS.EXTRAS_MANDATORY_STATUS;
      if (
        option.status === RESERVATIONS.EXTRAS_INCLUDED_STATUS ||
        option.status === RESERVATIONS.ADDITIONAL_DRIVER_INCLUDED_STATUS ||
        isOptionMandatory
      ) {
        acc.push({
          key: option.code,
          item: !onlyValue ? option.name : null,
          value: !isOptionMandatory
            ? utils.i18n('vehicle_select_extras_included')
            : utils.getFormattedPrice(option.total_amount_view, {
              asterisk: convertedPrices,
              doubleAsterisk: !convertedPrices,
            }),
          asterisks: false,
        });
      }
      return acc;
    }, []);
};

/**
 * VehicleSelectDetailsCardExpansion - renders expanded details for VehicleSelectDetailsCard
 *
 * @param {object}  props - React Props
 * @param {array}   props.features - list of features for particular vehicle from car_classes list
 * @param {object}  props.discountContractDetails - info from contract_details res object, used here as a flag to signal rendering contract adjustments
 * @param {string}  props.couponType - coupon type property from res object coupons array, used to signal rendering coupon adjustments
 * @param {object}  props.payLaterTotal - pay later total info for particular vehicle from car_classes list
 * @param {object}  props.payLaterTotalConverted - payLaterTotal converted to correct currency
 * @param {bool}    props.convertedPrices - derived boolean indicating that there is a converted currency available
 * @param {object}  props.payLaterRates - granular rate data retrieved from carClassDetails call
 * @param {object}  props.prePayTotal- prepay total info for particular vehicle from car_classes list
 * @param {object}  props.prePayTotalConverted - prepay total converted to correct currency
 * @param {object}  props.prePayRates - granular rate data retrieved from carClassDetails call
 * @param {object}  props.mileageInfo - mileage info for particular vehicle from car_classes list
 * @param {func}    props.showConvertedTotalsModal - dispatches MODAL.CONVERTED_PRICES_MODAL modal
 * @param {func}    props.showTaxesFeesModal - parent handler that dispatches MODAL.TAXES_FEES_MODAL with vehicle specific fees/info
 * @param {func}    props.learnAboutPrepayAndPayLaterModal - dispatches MODAL.LEARN_ABOUT_PREPAY_AND_PAY_LATER modal

 * @return {JSX}    VehicleSelectDetailsCardExpansion component
 */
const VehicleSelectDetailsCardExpansion = ({
  features,
  discountContractDetails,
  couponType,
  payLaterTotal,
  payLaterTotalConverted,
  convertedPrices,
  payLaterRates,
  prePayTotal,
  prePayTotalConverted,
  prePayRates,
  payLaterIncrementalPricing,
  prePayIncrementalPricing,
  mileageInfo,
  showConvertedTotalsModal,
  showTaxesFeesModal,
  learnAboutPrepayAndPayLaterModal,
  isEuropeanUnionCountry,
  destinationCurrencyCode,
  isPayTypeSelected,
}) => {
  const isDestinationCurrencySelected = isPayTypeSelected === destinationCurrencyCode;
  const incrementalPricingDefaultArray = payLaterIncrementalPricing || prePayIncrementalPricing;
  const prePayRentalRate = utils.getFormattedPrice(
    isDestinationCurrencySelected
      ? prePayRates?.price_summary?.estimated_total_vehicle_payment
      : prePayRates?.price_summary?.estimated_total_vehicle_view
  );
  const prePayTaxesFeesRate = prePayRates && utils.getTaxesFeesTotal(prePayRates?.price_summary);
  const payLaterRentalRate = utils.getFormattedPrice(
    isDestinationCurrencySelected
      ? payLaterRates?.price_summary?.estimated_total_vehicle_payment
      : payLaterRates?.price_summary?.estimated_total_vehicle_view
  );
  const payLaterTaxesFeesRate = payLaterRates && utils.getTaxesFeesTotal(payLaterRates.price_summary);
  const INCLUDED = utils.i18n('vehicle_select_extras_included');
  // If only PrePay is available (NA PrePaid True Modify), RateSections should hide the PayLater section.
  const shouldHidePaylaterSection = !payLaterTotalConverted && !!prePayTotalConverted;

  const extrasObject = {};
  const first = '170';
  features.sort((x, y) => {
    const code = y.code === first ? 1 : 0;
    return x.code === first ? -1 : code;
  });

  if (payLaterRates?.extras || prePayRates?.extras) {
    // If there is prepay rates with extras we use this values as the main part of the row,
    // otherwise we use the pay later rates directly.
    extrasObject.main = generateExtrasRateRows({
      extras: prePayRates?.extras || payLaterRates.extras,
      additionalDriver: prePayRates?.supplemental?.additional_driver || payLaterRates?.supplemental?.additional_driver,

      convertedPrices,
    });
    // Also if we are using the main part with prepay extras, we use the payLater as second part.
    if (payLaterRates?.extras && prePayRates?.extras) {
      extrasObject.side = generateExtrasRateRows({
        extras: payLaterRates.extras,
        additionalDriver: payLaterRates?.supplemental?.additional_driver,
        onlyValue: true,
        convertedPrices,
      });
    }
  }

  const prePayAdjustments = prePayRates && utils.getVehicleRateSavingsArray(prePayRates);
  const payLaterAdjustments = utils.getVehicleRateSavingsArray(payLaterRates);
  const adjustments = payLaterAdjustments || prePayAdjustments || [];
  const showAdjustments = (discountContractDetails && !!adjustments.length) || couponType;
  const showPrepayAdjustments =
    (payLaterAdjustments && prePayAdjustments) ||
    (couponType === DISCOUNTS.COUPON_TYPE_UPGRADE && prePayRates && payLaterRates);
  const distanceUnit = utils.getDistanceUnit(mileageInfo);
  const shouldShowIncrementalPricing = utils.config.getIsIncrementalPricingEnabled();

  // In case there is no guarantee the adjustments arrays for PREPAY and PAYLATER will have same adjustment types and be
  // in same order, this adjustmentsObject construct guards against that by being arranged by an adjustment description
  // key and rendering an empty RateRow when that adjustment is not available for one of the payment types.
  const adjustmentsObject = {};
  let paylaterSegmentObject;
  let showAdjustmentsItems = {};
  const adjustmentPaylaterRowValue = [];

  if (showAdjustments) {
    // If couponType UPGRADE, add a free upgrade row
    if (couponType === DISCOUNTS.COUPON_TYPE_UPGRADE) {
      const upgradeName = utils.i18n('coupon_upgrade_adjustment_name');
      const upgradeValue = utils.i18n('coupon_upgrade_adjustment_value');
      const payLaterName = showPrepayAdjustments ? null : upgradeName;
      adjustmentsObject.carClassUpgrade = {
        [PAYLATER_CODE]: { ...{ item: payLaterName, value: upgradeValue } },
        [PREPAY_CODE]: { ...{ item: upgradeName, value: upgradeValue } },
      };
    }

    adjustments.forEach(({ description, total_amount_view }) => {
      const paymentType = payLaterAdjustments ? PAYLATER_CODE : PREPAY_CODE;
      const itemName = showPrepayAdjustments ? null : description;
      adjustmentsObject[description] = {
        [PAYLATER_CODE]: { item: itemName, value: null },
        [PREPAY_CODE]: { item: itemName, value: null },
      };
      adjustmentsObject[description][paymentType] = {
        ...{ item: itemName, value: utils.getFormattedPrice(total_amount_view) },
      };
    });

    if (payLaterAdjustments && prePayAdjustments) {
      prePayAdjustments.forEach(({ description, total_amount_view }) => {
        if (!adjustmentsObject[description]) {
          adjustmentsObject[description] = {
            [PAYLATER_CODE]: { item: description, value: null },
            [PREPAY_CODE]: { item: description, value: null },
          };
        }
        adjustmentsObject[description][PREPAY_CODE] = {
          ...{ item: description, value: utils.getFormattedPrice(total_amount_view) },
        };
      });
    }

    //

    if (showAdjustments && adjustmentsObject) {
      paylaterSegmentObject =
        showPrepayAdjustments && Object.values(adjustmentsObject).map((item) => item[PAYLATER_CODE]);
      showAdjustmentsItems = Object.values(adjustmentsObject).map(
        (item) => item[showPrepayAdjustments ? PREPAY_CODE : PAYLATER_CODE]
      );

      showAdjustmentsItems = showAdjustmentsItems.filter((data) => data.item && data.value);

      if (showPrepayAdjustments) {
        !!paylaterSegmentObject &&
        paylaterSegmentObject.forEach((data, index) => {
          adjustmentPaylaterRowValue.push(
            <span key={`${data.value}${index}`} className='td-column-flow'>
                {data.item && <span>{data.item}</span>}
              {data.value && (
                <span>
                    {data.value} {!utils.isAlphabetic(data.value) ? '**' : null}
                  </span>
              )}
              </span>
          );
        });
      }
      if (!showPrepayAdjustments) {
        showAdjustmentsItems.forEach((data, index) => {
          data.value &&
          adjustmentPaylaterRowValue.push(
            <Fragment key={`${data.value}${data.value}${index}`}>
                <span>
                  {data.value} {!utils.isAlphabetic(data.value) ? '**' : null}
                </span>
            </Fragment>
          );
        });
      }
    }
  }

  return (
    <div className='vehicle-select-expanded-details'>
      <div className='vehicle-select-expanded-details__features'>
        <h4 className='vehicle-select-expanded-details__features__header'>
          {utils.i18n('vehicle_select_features_header')}
        </h4>

        <ul className='vehicle-select-expanded-details__features__list'>
          {features &&
            features.map((feature) => (
              <li className='vehicle-select-expanded-details__features__list-item' key={feature.code}>
                {feature.code === '170' ? utils.i18n('vehicle_details_electric') : feature.description}
              </li>
            ))}
        </ul>
      </div>

      <Fragment>
        {(!shouldHidePaylaterSection && !payLaterRates) || (shouldHidePaylaterSection && !prePayRates) ? (
          <LoadingWidget />
        ) : (
          <table className='vehicle-select-expanded-details__rates'>
            <thead>
            {prePayTotalConverted && payLaterTotalConverted ? (
              <tr>
                <th scope='col' colSpan='2' align='right'>
                  {utils.i18n('vehicle_select_pre_pay')}{' '}
                </th>
                <th scope='col' align='right'>
                  {utils.i18n('vehicle_select_pay_later')}
                </th>
              </tr>
            ) : (
              <tr className='tr-default_height'>
                <td colSpan='2' />
              </tr>
            )}
            </thead>
            <tbody>
            <RateSection
              title={utils.i18n('vehicle_select_rental_rate')}
              payLaterAsterisks={!shouldShowIncrementalPricing}
              payLaterSegment={prePayRates && (!shouldShowIncrementalPricing ? payLaterRentalRate : <></>)}
              hidePayLaterSegment={shouldHidePaylaterSection}
              borderless={shouldShowIncrementalPricing}
              // Because of layout differences between with/without incremental pricing,
              // this row would only have a value in case IP is *not* enabled/available
              // - in case it does have a value, we check between prepay/paylater, as usual
              {...(!shouldShowIncrementalPricing && {
                item: !shouldShowIncrementalPricing && <></>,
                value: prePayRates
                  ? prePayRentalRate !== 'undefined' &&
                  prePayRentalRate &&
                  `${prePayRentalRate}${convertedPrices ? '*' : '**'}`
                  : payLaterRentalRate !== 'undefined' &&
                  payLaterRentalRate &&
                  `${payLaterRentalRate}${convertedPrices ? '*' : '**'}`,
              })}
              withBorder={!shouldShowIncrementalPricing}
              convertedPrices={convertedPrices}
            />

            {shouldShowIncrementalPricing &&
              incrementalPricingDefaultArray?.map((payLaterIncrement, index) => {
                const prePayIncrement = prePayRates && prePayIncrementalPricing?.[index];
                let payLaterSegment = { increment: payLaterIncrement, key: payLaterIncrement.rate_type };
                let increment = prePayRates && prePayTotalConverted ? prePayIncrement : payLaterIncrement;
                const asterisksWithCurrency = convertedPrices ? '*' : '**';

                increment = increment ? (
                  <Fragment key={`${payLaterIncrement.rate_type}${index}`}>
                    {`${`(${utils.getFormattedPrice(increment.rate_amount_view)}/${utils.i18n(
                      `vehicle_select_incremental_unit_${increment.rate_type}`
                    )})`}
                        ${utils.getFormattedPrice(increment.total_amount_view)}`}
                    {asterisksWithCurrency}
                  </Fragment>
                ) : null;

                payLaterSegment = (
                  <Fragment key={`${payLaterIncrement.rate_type}${index}`}>
                    <FormattedPrice>{`(${utils.getFormattedPrice(
                      payLaterSegment.increment.rate_amount_view
                    )}/${utils.i18n(
                      `vehicle_select_incremental_unit_${payLaterSegment.increment.rate_type}`
                    )})`}</FormattedPrice>
                    <FormattedPrice>
                      {utils.getFormattedPrice(payLaterSegment.increment.total_amount_view)}
                      {asterisksWithCurrency}
                    </FormattedPrice>
                  </Fragment>
                );

                return (
                  /**
                   * Incremental pricing lines use RateSections because they need to align
                   * prepay and paylater cells even when they wrap on the row itself
                   * TODO refactor this whole table
                   */
                  <RateSection
                    key={payLaterIncrement.rate_type}
                    borderless={index < incrementalPricingDefaultArray.length - 1}
                    withBorder={index === incrementalPricingDefaultArray.length - 1}
                    payLaterAsterisks={false}
                    payLaterSegment={prePayIncrement && payLaterSegment}
                    hidePayLaterSegment={shouldHidePaylaterSection}
                    item={`${payLaterIncrement.rate_quantity} ${utils.pluralHandler(
                      payLaterIncrement.rate_quantity,
                      `vehicle_select_incremental_unit_${payLaterIncrement.rate_type}`,
                      `vehicle_select_incremental_unit_${payLaterIncrement.rate_type}_plural`
                    )}`}
                    value={increment}
                    title={index === 0 && utils.i18n('vehicle_select_rental_rate')}
                  />
                );
              })}

            {!!extrasObject.main.length && (
              <Fragment>
                {extrasObject.main.map((data, index) => {
                  const payLaterSegmentValue = extrasObject?.side && extrasObject.side[index]?.value;
                  return (
                    <RateSection
                      key={`${data.value}${index}`}
                      payLaterSegment={prePayRates && payLaterSegmentValue}
                      hidePayLaterSegment={shouldHidePaylaterSection}
                      payLaterAsterisks={false}
                      item={data.item}
                      value={data.value}
                      asterisks={false}
                      border={index === extrasObject.main.length - 1}
                      title={index === 0 && utils.i18n('vehicle_select_extras', [extrasObject.main.length])}
                    />
                  );
                })}
              </Fragment>
            )}

            {showAdjustments && (
              <Fragment>
                {showAdjustmentsItems.map((data, index) => (
                  <RateSection
                    key={`${data.value}${index}`}
                    payLaterAsterisks={false}
                    payLaterSegment={showPrepayAdjustments && adjustmentPaylaterRowValue}
                    hidePayLaterSegment={shouldHidePaylaterSection}
                    item={data.item}
                    value={utils.isAlphabetic(data.value) ? data.value : `${data.value}**`}
                    border={index === showAdjustmentsItems.length - 1}
                    tdColumnSpan={prePayTotalConverted && payLaterTotalConverted && !showPrepayAdjustments ? 2 : 1}
                    title={index === 0 && utils.i18n('vehicle_select_adjustments')}
                  />
                ))}
              </Fragment>
            )}

            <RateSection
              payLaterSegment={prePayRates && payLaterTaxesFeesRate}
              hidePayLaterSegment={shouldHidePaylaterSection}
              payLaterAsterisks={payLaterTaxesFeesRate !== INCLUDED}
              button={utils.i18n('vehicle_select_taxes_fees_view_details')}
              buttonHandler={showTaxesFeesModal}
              value={prePayRates ? prePayTaxesFeesRate !== 'undefined' && prePayTaxesFeesRate : payLaterTaxesFeesRate}
              asterisks={prePayRates ? prePayTaxesFeesRate !== INCLUDED : payLaterTaxesFeesRate !== INCLUDED}
              border={true}
              title={utils.i18n('vehicle_select_taxes_fees')}
            />

            <RateRow title={utils.i18n('vehicle_select_price_total')} className='tax-row-border'>
              {prePayTotal && (
                <td align='right' className='total-rates-section'>
                    <span className='row-value'>
                      {utils.getFormattedPrice(convertedPrices ? prePayTotalConverted : prePayTotal, {
                        doubleAsterisk: !!convertedPrices,
                      })}
                    </span>
                  {convertedPrices && prePayTotal && !isEuropeanUnionCountry && (
                    <span className='converted-copy'>
                        {utils.i18n(
                          'vehicle_select_converted_rates_copy_pre_pay',
                          [
                            <Fragment key='0'>{prePayTotal.code}</Fragment>,
                            <FormattedPrice key='1'>{utils.getFormattedPrice(prePayTotal)}</FormattedPrice>,
                          ],
                          { jsx: true }
                        )}
                      </span>
                  )}
                </td>
              )}

              {
                <td align='right' className='total-rates-section'>
                    <span className='row-value vehicle-select-expanded-details__rates__converted-copy'>
                      {utils.getFormattedPrice(convertedPrices ? payLaterTotalConverted : payLaterTotal, {
                        doubleAsterisk: !!convertedPrices,
                      })}
                    </span>
                  {convertedPrices && (
                    <span className='converted-copy'>
                        {utils.i18n(
                          'vehicle_select_converted_rates_copy',
                          [
                            <Fragment key='0'>{payLaterTotal?.code}</Fragment>,
                            <FormattedPrice key='1'>{utils.getFormattedPrice(payLaterTotal)}</FormattedPrice>,
                          ],
                          { jsx: true }
                        )}
                      </span>
                  )}
                </td>
              }
            </RateRow>

            {mileageInfo && mileageInfo.unlimited_mileage ? (
              <tr>
                <td colSpan='3'>
                  <div className='vehicle-select-expanded-details__mileage-copy'>
                    {`${utils.i18n('vehicle_select_mileage_copy_1')} `}
                    <span className='vehicle-select-expanded-details__mileage-copy--bold'>
                        {utils.i18n('vehicle_select_mileage_copy_2')}
                      </span>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan='3'>
                  <div className='vehicle-select-expanded-details__mileage-copy'>
                    {utils.i18n('rental_details_mileage_pricing', [
                      mileageInfo.total_free_miles,
                      utils.i18n(`rental_details_distance_unit_plural_${distanceUnit}`),
                      utils.getFormattedPrice(mileageInfo.excess_mileage_rate_view),
                      utils.i18n(`rental_details_distance_unit_singular_${distanceUnit}`),
                    ])}
                  </div>
                </td>
              </tr>
            )}
            </tbody>
            <tfoot>
            <tr>
              <td colSpan='3'>
                <p className='vehicle-select-expanded-details__price-disclaimer'>
                  {convertedPrices
                    ? utils.i18n('vehicle_select_converted_price_disclaimer')
                    : utils.i18n('vehicle_select_price_disclaimer')}
                </p>
              </td>
            </tr>
            {convertedPrices && (
              <tr>
                <td colSpan='3'>
                  <div className='vehicle-select-expanded-details__converted-copy'>
                    {utils.i18n('vehicle_select_converted_rates_disclaimer')}
                    <span
                      className='vehicle-select-expanded-details__converted-copy__button'
                      onClick={showConvertedTotalsModal}
                    >
                        {utils.i18n('rental_details_view_currency_conversion')}
                      </span>
                  </div>
                </td>
              </tr>
            )}

            {prePayTotalConverted && (
              <tr>
                <td colSpan='3'>
                  <Button
                    className='link vehicle-select-expanded-details__pre-pay-terms-modal-button'
                    onClick={learnAboutPrepayAndPayLaterModal}
                    button={false}
                  >
                    <span>{utils.i18n('learn_about_pre_pay_button')}</span>
                  </Button>
                </td>
              </tr>
            )}
            </tfoot>
          </table>
        )}
      </Fragment>
    </div>
  );
};

VehicleSelectDetailsCardExpansion.propTypes = {
  features: PropTypes.array.isRequired,
  discountContractDetails: PropTypes.object,
  couponType: PropTypes.string,
  payLaterTotal: PropTypes.object,
  payLaterTotalConverted: PropTypes.object,
  convertedPrices: PropTypes.bool,
  payLaterRates: PropTypes.object,
  prePayTotal: PropTypes.object,
  prePayTotalConverted: PropTypes.object,
  prePayRates: PropTypes.object,
  mileageInfo: PropTypes.object,
  showConvertedTotalsModal: PropTypes.func.isRequired,
  showTaxesFeesModal: PropTypes.func.isRequired,
  learnAboutPrepayAndPayLaterModal: PropTypes.func.isRequired,
  isEuropeanUnionCountry: PropTypes.bool,
};

export default VehicleSelectDetailsCardExpansion;
