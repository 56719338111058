import React from 'react';
import PropTypes from 'prop-types';

const ProgressBarPreviewMobile = ({ isAllowed, isCompleted, config, match }) => {
  const { ProgressBarLabelComponent, useLabelComponentForCurrentStep } = config;
  const currentStep = match && config.paths.indexOf(match.path) !== -1;
  const showLabelComponent = isCompleted || (useLabelComponentForCurrentStep && currentStep);

  return showLabelComponent && isAllowed ? (
    <ProgressBarLabelComponent config={config} currentStep={currentStep} match={match} preview />
  ) : null;
};

ProgressBarPreviewMobile.propTypes = {
  isAllowed: PropTypes.bool,
  isCompleted: PropTypes.bool,
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    progressBarLabel: PropTypes.string.isRequired,
  }),
  match: PropTypes.object.isRequired,
};

export default ProgressBarPreviewMobile;
