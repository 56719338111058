import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import { i18n } from 'utils/i18n';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import { ModalFooter } from 'components/Modal/ModalFooter';
import Modal from 'components/Modal';
import useActions from 'hooks/useActions';
import { setExpandedTab, setMobileExpanded } from 'actions/progressBar';
import { breakpointSelector } from 'selectors/breakpoint';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * NoSpecialsAvailableModal
 * Modal that displays the no specials available copy.
 *
 * @param {boolean} isLMS - If it's Last Minute or Plan Ahead Specials
 *
 * @return {JSX} NoSpecialsAvailableModal component
 */
const NoSpecialsAvailableModal = ({ isLMS }) => {
  const dispatchSetExpandedTab = useActions(setExpandedTab);
  const dispatchSetMobileExpanded = useActions(setMobileExpanded);
  const { isMobile } = useSelector(breakpointSelector);
  const specialCopyTitle = isLMS ? i18n('last_minute_specials') : i18n('plan_ahead_specials');
  const specialCopyDesc = specialCopyTitle.toLowerCase();

  const handleAdjustDateLocation = handleClose => () => {
    dispatchSetExpandedTab('datesTimes');
    isMobile && dispatchSetMobileExpanded(true);

    handleClose({ skipAnalytics: true });
  };

  return (
    <Modal
      modalKey={isLMS ? MODAL.NO_LMS_AVAILABLE_MODAL : MODAL.NO_PAS_AVAILABLE_MODAL}
      header={i18n('no_specials_available_modal_title', [specialCopyTitle])}
      theme={MODAL_THEMES.WHITE}>
      {({ handleClose }) => (
        <>
          <p className='modal-themed__paragraph'>{i18n('no_specials_available_description', [specialCopyDesc])}</p>

          <ModalFooter
            buttons={[
              {
                type: DECLINE,
                label: i18n('sold_out_adjust_date_time'),
                handler: handleAdjustDateLocation(handleClose),
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(isLMS ? MODAL.NO_LMS_AVAILABLE_MODAL : MODAL.NO_PAS_AVAILABLE_MODAL),
                    ANALYTICS.ADJUST_DATE_TIME
                  ),
                extraLargeButton: true,
              },
              {
                type: ACCEPT,
                label: i18n('common_continue'),
                handler: () => handleClose({ skipAnalytics: true }),
                analyticsHandler: () =>
                  utils.analytics.interaction(
                    ANALYTICS.MODAL,
                    utils.analytics.prepareKey(isLMS ? MODAL.NO_LMS_AVAILABLE_MODAL : MODAL.NO_PAS_AVAILABLE_MODAL),
                    ANALYTICS.CONTINUE
                  ),
                extraLargeButton: true,
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

NoSpecialsAvailableModal.propTypes = {
  isLMS: PropTypes.bool,
};

export default NoSpecialsAvailableModal;
