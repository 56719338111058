import React from 'react';
import utils from 'utils';
import MODAL from 'constants/modals';
import useActions from 'hooks/useActions';
import Button from 'components/Button';
import FindingReservationTipsModal from 'components/modals/FindingReservationTipsModal';
import { openModal } from "actions/modal/openModal";

/**
 * TipsForFindingReservationLink
 * Link for "Tips for Finding a Reservation" with modal opening to be used in
 * "Need Help" section for OCI and Skip The Counter
 *
 * @return {JSX}
 */
const TipsForFindingReservationLink = () => {
  const openModalHandler = useActions(openModal);
  const openTipsModal = () => openModalHandler(MODAL.FINDING_RESERVATION_TIPS_MODAL);

  return (
    <div>
      <Button onClick={openTipsModal} link linkText>
        {utils.i18n('tips_for_finding_reservation_info')}
      </Button>
      <FindingReservationTipsModal />
    </div>
  );
};

export default TipsForFindingReservationLink;
