import React from 'react';
import PropTypes from 'prop-types';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL_BUTTON_TYPES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import utils from 'utils';

/**
 * After Hours Return Instructions Modal - shows some instructions and return location in case of after hours vehicle return
 *
 * @param {object} contentProps - Modal content props
 * @param {object} contentProps.return_directions - GBO return location object
 * @param {string} contentProps.afterHoursPolicies - GBO Policies directions to return car that
 * @return {JSX}
 */

const AfterHoursReturnInstructionsModalContent = ({ afterHoursPolicies, return_directions }) => (
  <>
    <div className='padding-top--condensed'>
      <h4>{utils.i18n('after_hours_return_instructions_highlight')}</h4>
      <div className='modal-themed__paragraph' dangerouslySetInnerHTML={utils.sanitize(afterHoursPolicies)} />
      <h4>{utils.i18n('after_hours_return_instructions_return_location')}</h4>
      <p className='modal-themed__paragraph'>
        {return_directions?.street_addresses?.map((line, idx) => <span key={idx}>{line}</span>)}
        <br />
        {return_directions?.city}, {return_directions?.country_subdivision_code} {return_directions?.postal}
      </p>
    </div>
    {return_directions?.street_addresses && (
      <ModalFooter
        buttons={[
          {
            type: MODAL_BUTTON_TYPES.EXTERNAL_LINK,
            label: utils.i18n('modal_view_map'),
            linkTo: utils.locations.getGoogleMapsURL(utils.locations.addressObjToSingleLine(return_directions)),
            analyticsValue: ANALYTICS.VIEW_MAP,
          },
        ]}
      />
    )}
  </>
);

AfterHoursReturnInstructionsModalContent.propTypes = {
  return_directions: PropTypes.object,
  afterHoursPolicies: PropTypes.string,
};

export default AfterHoursReturnInstructionsModalContent;
