import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import utils from 'utils';
import CHECKIN from 'constants/checkin';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import SCROLL from 'constants/scroll';
import useLoadingOverlay from 'hooks/useLoadingOverlay';
import {
  hasExtrasSelector,
  includedExtrasAndDriversSelector,
  showBundlesSectionSelector,
} from 'selectors/extrasSelectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import PostResLayout from 'components/PostRes/PostResLayout';
import FlyoutTabs from 'components/FlyoutTabs';
import Modal from 'components/Modal';
import Button from 'components/Button';
import MessageBanner from 'components/MessageBanner';
import GenericGboCopyModalContent from 'components/Modal/GenericGboCopyModalContent';
import Bundles from 'components/ReservationFlow/Extras/Bundles';
import IndividualExtras from 'components/ReservationFlow/Extras/IndividualExtras';
import BundlesDetailsModalContent from 'components/ReservationFlow/Extras/Modals/BundlesDetailsModalContent';
import CheckInExtrasHeader from './CheckInExtrasHeader';

/**
 * Check-in Extras component
 * Accelerated Check-in flow page that allows the user to add/remove extras from their reservation.
 * Very similar to the Extras step in the reservation flow.
 *
 * @return {JSX} CheckInExtras jsx component
 */
const CheckInExtras = () => {
  useLoadingOverlay({ endpoint: 'session/current' });
  const hasExtras = useSelector(hasExtrasSelector);
  const includedExtrasAndDrivers = useSelector(includedExtrasAndDriversSelector);
  const showBundles = useSelector(showBundlesSectionSelector);
  const isLoadingExtras = useSelector(state =>
    isFetchingEndpointSelector(state, { endpoint: 'reservations/extras/update' })
  );

  const scrollRef = useRef(null);

  // current place of ref it should go - heigh of progress header
  const scrollToRef = () => window.scrollTo(0, scrollRef.current.offsetTop - SCROLL.HEIGHT_OF_PROGRESS_BAR_DESKTOP);

  const displayCarClass = includedExtrasAndDrivers.length < 4;

  return (
    <PostResLayout>
      <CheckInExtrasHeader />

      {!hasExtras ? (
        <MessageBanner
          title={utils.i18n('extras_no_extras_available_title')}
          message={utils.i18n('extras_no_extras_available_message')}
        />
      ) : (
        <>
          {includedExtrasAndDrivers.length > 0 && (
            <div
              className={cn('extras-select__included-section', {
                'extras-select__included-section--no-bundles': !showBundles,
              })}>
              <h2 className='extras-select__included-heading'>{utils.i18n('extras_included_in_your_reservation')}</h2>
              <FlyoutTabs
                items={includedExtrasAndDrivers}
                className='included-extras'
                contentKey={'detailed_description'}
                fullWidth
                icon='extra'
                hasClearBelow={displayCarClass}
              />
            </div>
          )}
          {showBundles && (
            <Bundles
              displayCarClass={displayCarClass}
              scrollToRef={scrollToRef}
              hasClearAbove={!includedExtrasAndDrivers.length}
            />
          )}
          <IndividualExtras
            shouldShowTitle={true}
            customTitle={utils.i18n('check_in_extras_title')}
            scrollRef={scrollRef}
            isCheckIn
          />

          <div className='extras-footer'>
            <Link to={CHECKIN.CHECKIN_PATH_CONFIG.checkInReview[0]}>
              <Button loading={isLoadingExtras} disabled={isLoadingExtras}>
                {utils.i18n('common_save_and_continue')}
              </Button>
            </Link>
          </div>
        </>
      )}
      <Modal modalKey={MODAL.BUNDLES_DETAILS_MODAL} theme={MODAL_THEMES.WHITE}>
        <BundlesDetailsModalContent />
      </Modal>
      <Modal modalKey={MODAL.EXTRAS_EXCLUSION_MODAL} theme={MODAL_THEMES.WHITE}>
        <GenericGboCopyModalContent />
      </Modal>
    </PostResLayout>
  );
};

export default CheckInExtras;
