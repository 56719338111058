import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

/**
 * Picture
 *
 * @param {object} props - React Props
 * @param {string} props.src - Image source (3x)
 * @param {string} props.alt - Alt text for image tag
 * @param {string} props.srcMobile - Image source for mobile (1x)
 * @param {string} props.srcTablet - Image source for tablets (2x)
 * @param {string} props.className - Image custom class name
 * @param {string} props.pictureClassName - Picture element custom class name
 * @param {string} props.fallbackImage - Fallback image to be set in case the original one gets a 404 from the server
 * @param {boolean} props.isDecorative - Flag for images with decorative role
 * @return {JSX}
 */
const Picture = ({ src, alt, srcMobile, srcTablet, className, pictureClassName, isDecorative, fallbackImage }) => {
  /**
   * This will let the `IntersectionObserver` API know that a new react
   * image is in place and that it should be lazy loaded. @see lazyLoad.js
   */
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('reactpicturemounted'));
  }, [src]);

  /**
   * This method will set a fallback image in case the original one errors.
   * If for some reason the asset is not available, and we have a fallback
   * image set, the original request will fail and this piece of code will run.
   */
  const setFallbackImage = useCallback(
    (event) => {
      if (fallbackImage) {
        // eslint-disable-next-line no-param-reassign
        event.target.src = fallbackImage;
      }
    },
    [fallbackImage]
  );

  return (
    <picture key={src} className={cn('lazy-load', pictureClassName)}>
      {srcMobile && <source srcSet={srcMobile} data-srcset={srcMobile} media='(max-width: 768px)' />}
      {srcTablet && (
        <source srcSet={srcTablet} data-srcset={srcTablet} media='(min-width: 768px) and (max-width: 1280px)' />
      )}
      <img
        className={className}
        data-src={src}
        onError={setFallbackImage}
        {...(!isDecorative && { alt })}
        {...(!!isDecorative && { role: 'presentation' })}
      />
    </picture>
  );
};

Picture.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  srcMobile: PropTypes.string,
  srcTablet: PropTypes.string,
  className: PropTypes.string,
  pictureClassName: PropTypes.string,
  fallbackImage: PropTypes.string,
  isDecorative: PropTypes.bool,
};

export default Picture;
