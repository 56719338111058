import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HashRouter as Router, Route } from 'react-router-dom';
import cn from 'classnames';
import utils from 'utils';
import { findTabbableDescendants } from 'utils/accessibility/tabbable';
import { isCustomPathFlowSPASelector } from 'selectors/customPathCheckInSelectors';
import { isLoggedInSelector } from 'selectors/profileSelectors';
import RESERVATIONS from 'constants/reservations';
import ANALYTICS from 'constants/analytics';
import useReservationRedirect from 'hooks/useReservationRedirect';
import Anchor from 'components/Anchor';
import Button from 'components/Button';
import ProgressBarCID from 'components/ReservationFlow/ProgressBar/ProgressBarCID';
import Authenticated from 'components/Authenticated';
import AuthenticatedFlyout from 'components/AuthenticatedFlyout';
import CouponNavigationBanner from 'components/CouponNavigationBanner';
import Picture from 'components/Picture';
import SessionTimeoutModal from 'components/modals/SessionTimeoutModal';
import DiscountDropDown from './DiscountDropDown';
import KeyFactsDropDown from './KeyFactsDropDown';
import TotalDropDown from './TotalDropDown';
import './Header.scss';

const { RESFLOW_PATHS_CONFIG } = RESERVATIONS;
const ORDERED_PATHS = Object.keys(RESFLOW_PATHS_CONFIG).reduce(
  (acc, config) => acc.concat(RESFLOW_PATHS_CONFIG[config]),
  []
);

const resFlowPathsWithKeyFactsDropDown = [
  RESFLOW_PATHS_CONFIG.vehicle[0],
  RESFLOW_PATHS_CONFIG.extras[0],
  RESFLOW_PATHS_CONFIG.review[0],
];

const isInternetExplorer11 = utils.browser.isInternetExplorer11();

/**
 * Header
 *
 * @param {object} props
 * @param {boolean} props.alwaysShowKeyFactsDropdown - force key facts dropdown to always show despite paths
 * @param {boolean} props.showDiscountElements - show/hide discount elements (coupon/CID) on the navigation bar
 * @param {boolean} props.showAlamoInsidersIfEnabled - show/hide AI flyout on the navigation bar (if enabled by domain)
 * @param {string} props.currentPage
 * @param {object} props.contract_details
 * @param {object} props.breakpoint
 * @param {object} props.gmiReservationState
 * @param {function} props.showDiscardReservationModal
 * @param {boolean} props.isTrueModify
 * @param {string} props.confirmationNumber - reservation confirmation number
 * @param {object} props.partner_reward_program
 * @param {string} props.productCode - Product code (e.g. MCO3)
 */
const Header = ({
  alwaysShowKeyFactsDropdown = false,
  showDiscountElements = false,
  showAlamoInsidersIfEnabled = false,
  breakpoint,
  contract_details,
  showDiscardReservationModal,
  isTrueModify,
  confirmationNumber,
  partner_reward_program,
  coupons,
  productCode,
  isLastMinuteSpecials,
  isPlanAheadSpecials,
  gmaBrandInformation,
  isRestrictedReservation,
  virginBrandInformation,
  isVirginReservation,
  reservationStatus,
  isRentalFlow = false,
}) => {
  const [usePartnerBrandLogo, setUsePartnerBrandLogo] = useState(false);
  const [headerLogoInformation, setHeaderLogoInformation] = useState(null);
  const isCustomPathReservation = useSelector(isCustomPathFlowSPASelector);
  const redirectPage = useReservationRedirect();
  const isLoggedIn = useSelector(isLoggedInSelector);

  const shouldRenderKeyFactsDropDown = (path) =>
    alwaysShowKeyFactsDropdown || resFlowPathsWithKeyFactsDropDown.includes(path);

  const shouldRenderTotalDropDown = (path) => resFlowPathsWithKeyFactsDropDown.slice(1).includes(path);

  const onSkipNavigation = (e) => {
    e.preventDefault();
    const allTabbableEls = findTabbableDescendants(document.body);

    const [progressBarElem] = document.getElementsByClassName('progress-bar');
    let lastNavTabbableEl = null;
    // if we have progress bar, skip it, otherwise, skip the nav items
    if (progressBarElem) {
      const progressBarTabbableEls = findTabbableDescendants(progressBarElem);
      lastNavTabbableEl = progressBarTabbableEls.pop();
    } else {
      const headerElem = document.getElementById('res-flow-header');
      const navTabbableEls = findTabbableDescendants(headerElem);

      lastNavTabbableEl = navTabbableEls.pop();
    }

    const globalIndexOfLastNavTabbableEl = allTabbableEls.indexOf(lastNavTabbableEl);

    if (globalIndexOfLastNavTabbableEl !== allTabbableEls.length - 1) {
      allTabbableEls[globalIndexOfLastNavTabbableEl + 1].focus();
    }
  };

  useEffect(() => {
    // Set header information based on the type of reservations.
    if (isRestrictedReservation && gmaBrandInformation) {
      // If reservations is non-virgin restricted reservation, and GMA does not have Virgin branding info
      setHeaderLogoInformation({
        logoName: gmaBrandInformation?.brandName,
        logoPath: gmaBrandInformation?.brandLogo,
      });
      setUsePartnerBrandLogo(true);
    } else if (isVirginReservation) {
      // If reservations is Virgin restricted reservation, use ehi object
      const { brandName, brandLogo } = virginBrandInformation;
      setHeaderLogoInformation({
        logoName: brandName,
        logoPath: brandLogo,
      });

      setUsePartnerBrandLogo(true);
    } else {
      // If reservations is non-virgin restricted reservation and GMA has virgin brand info
      // or is a retail reservation
      setHeaderLogoInformation({
        logoName: utils.i18n('progress_bar_alamo_image_alt'),
        logoPath: utils.config.getSiteLogoImage(),
      });
      setUsePartnerBrandLogo(false);
    }
  }, [isVirginReservation, isRestrictedReservation, virginBrandInformation, gmaBrandInformation]);

  const renderLogo = () =>
    headerLogoInformation ? (
      <Picture
        className={cn('header__res-flow-logo', {
          'header__custom-path__third-party-brand-logo': usePartnerBrandLogo,
        })}
        src={headerLogoInformation.logoPath}
        alt={headerLogoInformation.logoName}
      />
    ) : null;

  const renderPoweredByContainer = () => (
    <div className='header__custom-path__powered-by-container'>
      <span className='header__custom-path__powered-by-text'>{utils.i18n('powered_by')}</span>
      <Picture
        className='header__custom-path__alamo-logo'
        src={utils.config.getSiteLogoImage()}
        alt={utils.i18n('progress_bar_alamo_image_alt')}
      />
    </div>
  );

  // Only show powered by container if is a partner brand logo (i.e. virgin, non-virgin third party reservation)
  const shouldShowPoweredBy = usePartnerBrandLogo && headerLogoInformation.logoPath !== utils.config.getSiteLogoImage();

  const shouldShowProgressBarCID =
    partner_reward_program?.program_name || (contract_details?.contract_type && contract_details?.contract_name);

  // If the reservation isn't being modified and has a confirmation number, then this is a confirmation/bridge page.
  // also checking for reservation_status,to make sure whether reservation is confirmed or still in way to booking,
  // For some reason when we switch from prepay to pay later while adding a payment details ,  gbo is returing confirmationNumber details
  // and this logic was causing issue with redirecting without  showing discard modal .
  const shouldLogoRedirect =
    (!isTrueModify || isCustomPathReservation) && typeof reservationStatus === 'string' && !!confirmationNumber;

  const discountElements = [];

  if (coupons[0]) {
    discountElements.push(<CouponNavigationBanner coupons={coupons} key='couponBanner' />);
  }

  if (productCode) {
    discountElements.push(
      <CouponNavigationBanner coupons={[{ description: productCode }]} key='productCodeBanner' isProductCode />
    );
  }

  if (shouldShowProgressBarCID) {
    discountElements.push(
      <ProgressBarCID
        type={contract_details?.contract_type}
        message={contract_details?.contract_name}
        termsConditions={contract_details?.terms_and_conditions}
        isTrueModify={isTrueModify}
        key='cidBanner'
        isCustomPathFlowSPA={isCustomPathReservation}
      />
    );
  }

  // Render profile or total dropdown conditionally
  const showUserProgileDropDown = showAlamoInsidersIfEnabled && utils.config.getIsEnableAlamoInsider() && !isTrueModify;

  // condtionally rendering the policy link based on the feature flag
  const renderPolicyLink = (match) => {
    if (shouldRenderKeyFactsDropDown(match?.path)) {
      return <KeyFactsDropDown rightSide={isCustomPathReservation && shouldRenderKeyFactsDropDown(match?.path)} />;
    }
    return null;
  };

  return (
    <Router basename={'/'}>
      <Route path={ORDERED_PATHS} exact>
        {({ match }) => (
          <div
            id='res-flow-header'
            className={cn('header res-flow', {
              'custom-path-flow': usePartnerBrandLogo,
            })}
          >
            <div
              className={cn('header__left-side', {
                'header__custom-path__left-side': usePartnerBrandLogo,
                logged_in: isLoggedIn,
                progress_bar_total: shouldRenderTotalDropDown(match?.path),
              })}
            >
              {isInternetExplorer11 || shouldLogoRedirect ? (
                <Anchor
                  href={utils.config.getRedirectUrl(redirectPage)}
                  data-dtm-track={utils.analytics.dtm(
                    isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.CHECK_IN,
                    ANALYTICS.UI_HEADER,
                    'alamo_logo'
                  )}
                >
                  {renderLogo()}
                </Anchor>
              ) : (
                <Button
                  link
                  ariaText={utils.i18n('progress_bar_alamo_image_alt')}
                  onClick={showDiscardReservationModal}
                  data-dtm-track={utils.analytics.dtm(
                    isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.CHECK_IN,
                    ANALYTICS.UI_HEADER,
                    'alamo_logo'
                  )}
                >
                  {renderLogo()}
                </Button>
              )}
              <Button
                plain
                id='primary-nav-skip-link'
                className='header__skip-link header__skip-link--res-flow'
                onClick={onSkipNavigation}
              >
                {utils.i18n('a11y_skip_navigation_link_text')}
              </Button>
              {!isCustomPathReservation && !isRentalFlow && renderPolicyLink(match)}
              {shouldShowPoweredBy && renderPoweredByContainer()}
            </div>

            <div className='header__right-side'>
              {showDiscountElements && (
                <>
                  {breakpoint.isTablet && !!discountElements.length && (
                    <div className='header__right-side__section'>
                      {(isLastMinuteSpecials || isPlanAheadSpecials) && (
                        <div className='header__discount-container'>{discountElements}</div>
                      )}
                      {!isLastMinuteSpecials && !isPlanAheadSpecials && (
                        <DiscountDropDown discountElements={discountElements} />
                      )}
                    </div>
                  )}

                  {breakpoint.isDesktop &&
                    !!discountElements.length &&
                    discountElements.map((discountBar) => (
                      <div key={discountBar.key} className='header__right-side__section'>
                        {discountBar}
                      </div>
                    ))}
                </>
              )}

              {isCustomPathReservation && !isRentalFlow && renderPolicyLink(match)}

              {(showUserProgileDropDown || (breakpoint.isMobile && shouldRenderTotalDropDown(match?.path))) && (
                <div className='header__right-side__section'>
                  {showUserProgileDropDown && (
                    <Authenticated options={{ first_name: true, last_name: true }}>
                      {({ logged_in, profileData }) =>
                        logged_in ? <AuthenticatedFlyout {...profileData} isResPath /> : null
                      }
                    </Authenticated>
                  )}
                  {breakpoint.isMobile && shouldRenderTotalDropDown(match?.path) && (
                    <TotalDropDown shouldRenderTotalDropDown={shouldRenderTotalDropDown} match={match} />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </Route>
      <SessionTimeoutModal />
    </Router>
  );
};

Header.propTypes = {
  alwaysShowKeyFactsDropdown: PropTypes.bool,
  showDiscountElements: PropTypes.bool,
  showAlamoInsidersIfEnabled: PropTypes.bool,
  currentPage: PropTypes.string,
  contract_details: PropTypes.object,
  breakpoint: PropTypes.object,
  gmiReservationState: PropTypes.object,
  showDiscardReservationModal: PropTypes.func.isRequired,
  isTrueModify: PropTypes.bool,
  confirmationNumber: PropTypes.string,
  productCode: PropTypes.string,
  partner_reward_program: PropTypes.shape({
    code: PropTypes.string,
    member_id: PropTypes.string,
    program_name: PropTypes.string,
  }),
  isRentalFlow: PropTypes.bool,
};

export default Header;
