import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import THEMES from 'constants/themes';

/**
 * PostResLayout Component
 * Wrapper for post-res SPA flows main content layout - a.k.a. the middle/centered section.
 *
 * @param {object} props - React Props
 * @param {string} props.theme - Theme used for the content layout background - check `constants/themes` for possible values
 * @param {string} props.noMinHeight - Flag to unset the container min-height
 * @param {array|node} props.children - JSX Components passed onto this component to be rendered within it
 *
 * @return {JSX} PostResLayout jsx component
 */
const PostResLayout = ({ theme = THEMES.DARK_BLUE, noMinHeight, noMarignTop, noPaddingTopBottom, children }) => (
  <section
    className={cn('post-res__layout', {
      [`theme--${theme}`]: theme,
      'post-res__layout--auto-height': noMinHeight,
      'post-res__layout--margin-top-spacing': noMarignTop,
    })}
  >
    <section className='aem-Grid aem-Grid--12 aem-Grid--default--12'>
      <main
        className={cn('aem-GridColumn aem-GridColumn--default--12', {
          'post-res__main-column': !noPaddingTopBottom,
        })}
      >
        {children}
      </main>
    </section>
  </section>
);

PostResLayout.propTypes = {
  customClass: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(THEMES)),
  noMinHeight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default PostResLayout;
