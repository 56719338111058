import React from 'react';
import utils from 'utils';
import { MODAL, MODAL_THEMES } from 'constants';
import Modal from 'components/Modal';
import PrepayTerms from 'components/PrepayTerms';

// TODO: Move all modal components to relevant folder, either to relevant page of res flow or to SharedModals in ResFlow

const PrepayTermsModal = () => (
  <Modal
    modalKey={MODAL.PREPAY_TERMS_CONDITIONS_MODAL}
    header={utils.i18n('prepayment_terms_and_conditions')}
    theme={MODAL_THEMES.WHITE}
  >
    <PrepayTerms showPrintButton />
  </Modal>
);

export default PrepayTermsModal;
