import { connect } from 'react-redux';
import utils from 'utils';
import { setUpdateProfileWithPartnerRewards } from 'actions/bookingWidget';
import { initiateReservationFlow } from "actions/reservation/initiateReservationFlow";
import PRPAuthAddProgramModal from './PRPAuthAddProgramModal';

const mapStateToProps = state => ({
  partner_rewards_program_name: state.getIn(['gmi', 'ui', 'reservations', 'initiate', 'partner_reward_program_name']),
});

const mapDispatchToProps = {
  handleInitiate: () => initiateReservationFlow({ freshSession: true }),
  setUpdateProfileWithPartnerRewards,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(PRPAuthAddProgramModal));
