import PropTypes from 'prop-types';
import { setDefaultProps } from 'data/sharedProps/index';

export const propTypes = {
  /**
   * clear date in gmi.ui.reservations.initiate, by exchange type
   *
   * @type {Function}
   */
  clearDate: PropTypes.func.isRequired,

  /**
   * clear time in gmi.ui.reservations.initiate, by exchange type
   *
   * @type {Function}
   */
  clearTime: PropTypes.func.isRequired,

  /**
   * date part of pickup timestamp -  based on gmi.ui.reservations.initiate.pickup_time
   *
   * @type {String}
   */
  pickupDate: PropTypes.string,

  /**
   * time part of pickup timestamp - based on gmi.ui.reservations.initiate.pickup_time
   *
   * @type {String}
   */
  pickupTime: PropTypes.string,

  /**
   * comes from gmi.ui.reservations.initiate.pickup_location_id
   *
   * @type {String}
   */
  pickup_location_id: PropTypes.string,

  /**
   * comes from gmi.ui.reservations.initiate.return_location_id
   *
   * @type {String}
   */
  return_location_id: PropTypes.string,

  /**
   * time part of return timestamp -  based on gmi.ui.reservations.initiate.return_time
   *
   * @type {String}
   */
  returnDate: PropTypes.string,

  /**
   * date part of return timestamp -  based on gmi.ui.reservations.initiate.return_time
   *
   * @type {String}
   */
  returnTime: PropTypes.string,

  /**
   * store date in gmi.ui.reservations.initiate, by exchange type
   *
   * @type {Function}
   */
  setDate: PropTypes.func.isRequired,

  /**
   * store time in gmi.ui.reservations.initiate, by exchange type
   *
   * @type {Function}
   */
  setTime: PropTypes.func.isRequired,
  pickUpDateError: PropTypes.bool,
  pickUpTimeError: PropTypes.bool,
  returnDateError: PropTypes.bool,
  returnTimeError: PropTypes.bool,
  isSixColumnGrid: PropTypes.bool,
  monthsShownDatePicker: PropTypes.number,
  timeAndDatePickerFullHeight: PropTypes.bool,
};

export const defaultProps = setDefaultProps();
