import React from 'react';
import utils from 'utils';
import Button from 'components/Button';

const PrintRecommendedAddonBanner = ({
  countryCode,
  scrollToRef,
  code,
  name,
  printAddOnBanner,
  printRecommendedCoverage,
}) => (
  <>
    {printAddOnBanner && (
      <p>
        {utils.i18n(`lac_recommended_addon_banner_description_${countryCode?.toLowerCase()}`)}
        {utils.i18n(
          'lac_recommended_addon_banner_jump_to_extras',
          {
            0: (
              <Button linkText onClick={scrollToRef} key='lac_recommended_addon_banner_jump_to_extras_link'>
                {utils.i18n('lac_recommended_addon_banner_jump_to_extras_link')}
              </Button>
            ),
          },
          {
            jsx: true,
          }
        )}
      </p>
    )}

    {printRecommendedCoverage && (
      <p>
        {utils.i18n('recommended_alert_header_individual_label', {
          0: name,
          1: code,
        })}
      </p>
    )}
  </>
);

export default PrintRecommendedAddonBanner;
