import React from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import usePostResDriverProfile from 'hooks/usePostResDriverProfile';
import GenericDriverDetailsRow from './GenericDriverDetailsRow';

const DriverDetailsRow = ({ fromPostRes }) => {
  const { license_profile, address_profile, contact_profile } = usePostResDriverProfile();

  const { country_subdivision_code, country_name } = license_profile || {};
  const issuedBySubdivision =
    !!country_subdivision_code && !utils.fieldValidation.isMasked(country_subdivision_code)
      ? `, ${country_subdivision_code}`
      : '';
  const phoneData = contact_profile?.phones?.[0];

  const driverDetails = {
    issuedBy: `${country_name || ''}${issuedBySubdivision}`,
    license_profile,
    phoneData,
    address_profile,
  };

  return <GenericDriverDetailsRow driverDetails={driverDetails} fromPostRes={fromPostRes} />;
};

DriverDetailsRow.propTypes = {
  fromPostRes: PropTypes.bool,
};

DriverDetailsRow.defaultProps = {
  fromPostRes: false,
};

export default DriverDetailsRow;
