import PropTypes from 'prop-types';
import { ListPropTypes, setDefaultProps } from 'data/sharedProps';

export const propTypes = Object.assign(ListPropTypes, {
  /**
   * Items to render based on array or arrays in an object
   *
   * @type {Array|Object}
   */
  listItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * Controls rendering of list items
   *
   * @type {Boolean}
   */
  open: PropTypes.bool,
});

export const defaultProps = setDefaultProps({
  listItems: [],
  open: false,
});
