import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import utils from 'utils';
import { getCurrentPageHash } from 'utils/url';
import CUSTOM_PATH from 'constants/customPathCheckIn';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { sessionReservationObjectSelector, isReservationCancelledSelector } from 'selectors/reservationSelectors';
import { isCustomPathReservationSelector, isRestrictedReservationSelector } from 'selectors/customPathCheckInSelectors';
import {
  isCheckedInReservationSelector,
  getOnlineCheckInEligibleFlag,
  getCounterBypassEligibleFlag,
  isSkipTheCounterCompletedReservationSelector,
} from 'selectors/reservationFlowSelectors';
import ExitCheckInFlowModal from 'components/modals/ExitCheckInFlowModal';
import PostResFlowTemplate from 'components/PostRes/PostResFlowTemplate';
import SessionTimeoutModal from 'components/modals/SessionTimeoutModal';
import DiscardReservationModal from 'components/ReservationFlow/SharedModals/DiscardReservationModal';
import { isThirdPartyReservationSelector } from 'selectors';
import CheckInDriverLookup from '../AcceleratedCheckInFlow/CheckInDriverLookup';
import CheckInProfile from '../AcceleratedCheckInFlow/CheckInProfile';
import CheckInReview from '../AcceleratedCheckInFlow/CheckInReview';
import STCProtectionsAndPayment from '../SkipTheCounterFlow/STCProtectionsAndPayment';
import STCConfirmation from '../SkipTheCounterFlow/STCConfirmation';

const { CUSTOM_PATH_CONFIG } = CUSTOM_PATH;

/**
 * Custom Path Checkin Flow SPA component
 * This component handles routing for the Custom Path Checkin Flow's SPA.
 * Logic for routing itself is actually processed by GMA, so this just handles the SPA aspect.
 */
const CustomPathCheckInFlow = () => {
  const isFetchingSession = useSelector((state) => isFetchingEndpointSelector(state, { endpoint: 'session/current' }));
  const reservation = useSelector(sessionReservationObjectSelector);
  const isCancelled = useSelector(isReservationCancelledSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const isSkipTheCounterEligible = useSelector(getCounterBypassEligibleFlag);
  const isOnlineCheckInEligible = useSelector(getOnlineCheckInEligibleFlag);
  const isCheckedIn = useSelector(isCheckedInReservationSelector);
  const isSkippingTheCounter = useSelector(isSkipTheCounterCompletedReservationSelector);
  const isRestrictedReservation = useSelector(isRestrictedReservationSelector);
  const isThirdPartyReservation = useSelector((state) => isThirdPartyReservationSelector(state));

  const isAuthorMode = utils.config.getIsAuthorModeEnabled();

  const currentStepHash = getCurrentPageHash();

  useEffect(() => {
    // if reservation is not oci/stc eligible, is cancelled, or is not isCustomPathReservation redirect the user to V/M/C page
    if (
      !isFetchingSession &&
      ((!isOnlineCheckInEligible && !isSkipTheCounterEligible && isCustomPathReservation) ||
        !isCustomPathReservation ||
        isCancelled) &&
      !isAuthorMode
    ) {
      if (isSkippingTheCounter) {
        // This means that reservation is STC enabled and it's able to access the STC confirmation page
        if (currentStepHash !== CUSTOM_PATH_CONFIG.confirmation[0]) {
          utils.loadingRedirect(WINDOW_OBJECT_KEYS.CUSTOM_PATH_FLOW, false, CUSTOM_PATH_CONFIG.confirmation[0]);
        }
        return;
      }
      if (isCustomPathReservation || isRestrictedReservation || isThirdPartyReservation) {
        utils.loadingRedirect(WINDOW_OBJECT_KEYS.CUSTOM_PATH_RENTAL_DETAILS);
      } else {
        utils.loadingRedirect(WINDOW_OBJECT_KEYS.RENTAL_DETAILS);
      }
    }
  }, [
    isFetchingSession,
    isCustomPathReservation,
    isCancelled,
    isOnlineCheckInEligible,
    isSkipTheCounterEligible,
    currentStepHash,
    isSkippingTheCounter,
  ]);

  useEffect(() => {
    // if the session was already fetched and the reservation is null/invalid...
    if (!isFetchingSession && !reservation && !isAuthorMode) {
      // ...exit the custom path flow by redirecting back to restricted oci lookup page
      window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESTRICTED_OCI_LANDING_PAGE));
    }
  }, [isFetchingSession, reservation]);

  const handleRouteUpdate = () => {
    if (!window.pageXOffset) {
      utils.dom.scrollPage();
    }
    // Dispatch the analytics ready event to indicate a "new page load" (since this is a SPA)
    utils.analytics.init(utils.config.getPageInfo());
  };

  return (
    <Router basename={'/'}>
      <PostResFlowTemplate>
        <Route>
          {({ location }) => (
            <utils.OnUpdateWrapper pathname={location.pathname} onUpdate={handleRouteUpdate}>
              <Switch>
                <Route path={CUSTOM_PATH_CONFIG.driverLookup} exact component={CheckInDriverLookup} />
                <Route path={CUSTOM_PATH_CONFIG.profile} exact component={CheckInProfile} />
                <Route path={CUSTOM_PATH_CONFIG.checkInReview} exact component={CheckInReview} />
                <Route path={CUSTOM_PATH_CONFIG.skipTheCounter} exact component={STCProtectionsAndPayment} />
                <Route path={CUSTOM_PATH_CONFIG.confirmation} exact component={STCConfirmation} />
                <Redirect
                  to={!isCheckedIn ? CUSTOM_PATH_CONFIG.driverLookup[0] : CUSTOM_PATH_CONFIG.skipTheCounter[0]}
                  from={'/'}
                />
              </Switch>
            </utils.OnUpdateWrapper>
          )}
        </Route>
        <ExitCheckInFlowModal />
        <DiscardReservationModal />
        <SessionTimeoutModal />
      </PostResFlowTemplate>
    </Router>
  );
};

export default CustomPathCheckInFlow;
