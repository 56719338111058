import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

class ProgressBarDrawerWrapper extends Component {
  static propTypes = {
    shouldScroll: PropTypes.bool,
    children: PropTypes.any,
  };

  // todo: leaving this commentted out for now because it's clear this sticky behavior was intentional
  // small chance the business will want to roll back these changes and will be easier to do so if its commented
  // componentDidMount() {
  //   utils.scrollLock();
  // }

  // componentWillUnmount() {
  //   utils.scrollLock({ toggleValue: false });
  // }

  render() {
    return (
      <div
        className={cn('progress-bar__drawer-wrapper theme--light-blue', { 'should-scroll': this.props.shouldScroll })}>
        <div className='progress-bar__drawer-content'>{this.props.children}</div>
      </div>
    );
  }
}

export default ProgressBarDrawerWrapper;
