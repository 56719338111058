import React from 'react';
import utils from 'utils';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL_BUTTON_TYPES } from 'constants';
import propTypes from './GenricPolicyContentModalPropTypes';

const { ACCEPT } = MODAL_BUTTON_TYPES;

const GenricPolicyContentModal = props => (
  <>
    <div dangerouslySetInnerHTML={utils.sanitize(props.policy_text)} />
    <ModalFooter buttons={[{ type: ACCEPT, label: utils.i18n('common_close') }]} />
  </>
);

GenricPolicyContentModal.propTypes = propTypes;

export default GenricPolicyContentModal;
