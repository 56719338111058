import React from 'react';
import { ANALYTICS } from 'constants';
import cn from 'classnames';
import VisHiddenText from 'components/VisHiddenText';
import { propTypes } from './GuidedErrorPropTypes';

const GuidedError = ({ className, message }) => (
  <span className={cn('guided-error', className)}>
    <VisHiddenText message={ANALYTICS.ERROR} />
    {message}
  </span>
);

GuidedError.propTypes = propTypes;

export default GuidedError;
