import { connect } from 'react-redux';
import utils from 'utils';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import RenterInformationCommonContainer from '../RenterInformationCommonContainer';

import ConfirmReturn from './ConfirmReturn';

const mapStateToProps = (state) => {
  const renterInfoDetails = RenterInformationCommonContainer(state);

  return {
    ...renterInfoDetails,
  };
};

const mapDispatchToProps = {
  showRentalNotEligibleModal: () => openModal(MODAL.RENTAL_NOT_ELIGIBLE_FOR_MODIFICATION_MODAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ConfirmReturn));
