import React from 'react';
import utils from 'utils';
import Button from 'components/Button';
import ServiceErrors from 'components/ServiceErrors';
import { propTypes } from './prepaymentReadyDriverCardPropTypes';

/**
 * This component returns the Driver Information (1. Information) section for the review step
 * in prepay resflow once a user has clicked to add a credit card (so they are on step 2 "Payment")
 *
 * @param {object} props - react props
 * @param {string} props.airline - Passed down by MSTP, this is the "airline" on the current res.
 * @param {string} props.email_address - Passed down by MSTP, this is the "email address" on the current res.
 * @param {string} props.first_name - Passed down by MSTP, this is the "first name" on the current res.
 * @param {string} props.flight_number - Passed down by MSTP, this is the "flight number" on the current res.
 * @param {string} props.last_name - Passed down by MSTP, this is the "last name" on the current res.
 * @param {string} props.phone_number - Passed down by MSTP, this is the "phone number" on the current res.
 * @param {function} props.returnToConfirmPrepay - A function passed down to "Change Driver's Information" (exit out of IBM iframe)
 *
 * @return {JSX}
 */
const PrepaymentReadyDriverCard = ({
  airline,
  email_address,
  first_name,
  flight_number,
  last_name,
  phone_number,
  returnToConfirmPrepay,
}) => (
  <>
    <div className='review__section-prepayment-information'>
      <div className='review__section-header'>
        <h2 className='review__section-header__title'>{utils.i18n('review_prepayment_section_title_step_one')}</h2>
      </div>
      <ServiceErrors />
      <div className='review__section-wrapper'>
        <div className='review__section-card__field-column review__section-card__prepayment__details'>
          <div className='review__section-card__field-row review__section-card__prepayment__detail'>
            <p className='review__section-card__prepayment__detail--key'>
              {utils.i18n('review_prepayment_section_drive_details_name')}
            </p>
            <p className='review__section-card__prepayment__detail--value'>
              {first_name}&nbsp;{last_name}
            </p>
          </div>
          {phone_number && (
            <div className='review__section-card__field-row review__section-card__prepayment__detail'>
              <p className='review__section-card__prepayment__detail--key'>
                {utils.i18n('review_prepayment_section_drive_details_phone_number')}
              </p>
              <p className='review__section-card__prepayment__detail--value'>{phone_number}</p>
            </div>
          )}
          <div className='review__section-card__field-row review__section-card__prepayment__detail'>
            <p className='review__section-card__prepayment__detail--key'>
              {utils.i18n('review_prepayment_section_drive_details_email')}
            </p>
            <p className='review__section-card__prepayment__detail--value'>{email_address}</p>
          </div>
          {airline && (
            <div className='review__section-card__field-row review__section-card__prepayment__detail'>
              <p className='review__section-card__prepayment__detail--key'>
                {utils.i18n('review_prepayment_section_drive_details_flight_info')}
              </p>
              <p className='review__section-card__prepayment__detail--value'>
                {airline}&nbsp;{flight_number}
              </p>
            </div>
          )}
        </div>
        <div className='review__section-card__field-column review__section-card__prepayment__details'>
          <Button
            button
            className='pill pill-dark review__section-card__prepayment__details--change-button'
            onClick={returnToConfirmPrepay}>
            {utils.i18n('review_prepayment_change_information_button')}
          </Button>
        </div>
      </div>
    </div>
  </>
);

PrepaymentReadyDriverCard.propTypes = propTypes;

export default PrepaymentReadyDriverCard;
