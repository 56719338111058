import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import Picture from 'components/Picture';
import { getFormattedAddress } from 'utils/locations';
import { Vehicle, Extras, TaxesFees, TotalPrice, AdditionalDrivers } from 'components/RentalDetails';
import STCConfirmationRenterInfo from './STCConfirmationRenterInfo';
import STCConfirmationPickupInstructions from './STCConfirmationPickupInstructions';
import STCConfirmationStepsList from './STCConfirmationStepsList';
import STCConfirmationArrivalInstruction from './STCConfirmationArrivalInstruction';

/**
 * STCConfirmationPrint - Skip The Counter Confirmation Print Page
 * @param {object} props - React Props
 * @param {function} props.openViewTicketModal - Trigger to open the ticket modal
 * @param {string} props.paymentCardType - Credit Card type
 * @param {string} props.paymentCardNumber - Credit Card number
 * @param {function} props.openViewDirectionsLotModal - Trigger to open the wayfinding modal
 * @param {object} props.carClassDetails - Car Class details object
 * @param {object} props.pickupLocation - Pickup location object
 * @param {object} props.returnLocation - Return location object
 * @param {string} props.pickupTime - Pickup date and time
 * @param {string} props.returnTime - Return date and time
 * @param {array} props.driversList - Drivers list
 * @param {array} props.driverInfo - Driver info
 * @param {array} props.branchLocalAddenda - Branch Local Addendum
 * @param {string} props.reservationConfirmationNumber - Confirmation number
 * @param {string} props.barcodeImage - Barcode image source
 * @param {boolean} props.isSameLocation - Flag to identify when if pickup and return location are the same
 * @return {JSX}
 */
const STCConfirmationPrint = ({
  openViewTicketModal,
  paymentCardType,
  paymentCardNumber = '',
  openViewDirectionsLotModal,
  carClassDetails,
  pickupLocation,
  returnLocation,
  pickupTime,
  returnTime,
  driversList,
  branchLocalAddenda,
  reservationConfirmationNumber,
  barcodeImage,
  isSameLocation,
  wayfindingPolicy,
  isVirginReservation,
  fullSessionReservation,
  vehicleRate,
  selectedBundleWithExtras,
  extras,
  numberOfExtrasSelected,
  extrasWithoutBundles,
  coupons,
}) => {
  const DATE_FORMAT = utils.config.getDateTimeLongFormat('lll');
  const asterisk = true;

  const { car_class_details, prepay_selected } = fullSessionReservation;
  const {
    estimated_total_vehicle_view,
    estimated_total_payment,
    estimated_total_view,
    payment_line_items,
  } = vehicleRate.price_summary;

  const chargedRate = payment_line_items?.VEHICLE_RATE || [];
  const savings = payment_line_items?.SAVINGS || [];
  const { features, images, make_model_or_similar_text, name } = car_class_details;
  const transmission = utils.getTransmissionDescriptionFromFeaturesArray(features);
  const carImage = utils.getImageFromImagesObject(images);
  const freeUpgradeApplied = utils.isFreeUpgradeAvailable(coupons);

  return (
    <div className='skip-the-counter-confirmation__print'>
      <div className='skip-the-counter-confirmation__print-header'>
        <div className='skip-the-counter-confirmation__print-header-logo'>
          <Picture
            className='skip-the-counter-confirmation__print-header-logo-image'
            src={utils.config.getSiteLogoImage()}
            alt={utils.i18n('progress_bar_alamo_image_alt')}
          />
        </div>

        <div className='skip-the-counter-confirmation__print-header-barcode'>
          <Picture
            className='skip-the-counter-confirmation__print-header-barcode-image'
            src={barcodeImage}
            isDecorative
          />
        </div>
      </div>

      <h1 className='skip-the-counter-confirmation__print-title'>{utils.i18n('stc_ticket_modal_title')}</h1>

      <span className='skip-the-counter-confirmation__print-confirmation'>
        {utils.i18n('confirmation_number', [reservationConfirmationNumber])}
      </span>

      {wayfindingPolicy && isVirginReservation && (
        <>
          <span className='skip-the-counter-confirmation__print-pickup-instructions'>
            {utils.i18n('rental_details_arrival_instructions')}
          </span>
          <div className='skip-the-counter-confirmation__print-info skip-the-counter-confirmation__print-info-margin'>
            <div className='skip-the-counter-confirmation__print-info-steps-details'>
              <STCConfirmationArrivalInstruction wayfindingPolicy={wayfindingPolicy} />
            </div>
          </div>
        </>
      )}

      <span className='skip-the-counter-confirmation__print-pickup-instructions'>
        {utils.i18n('stc_pickup_instructions')}
      </span>

      <div className='skip-the-counter-confirmation__print-info'>
        <div className='skip-the-counter-confirmation__print-info-steps'>
          <div className='skip-the-counter-confirmation__print-info-steps-details'>
            <STCConfirmationPickupInstructions {...{ openViewTicketModal, paymentCardType, paymentCardNumber }} noImg />
            <STCConfirmationStepsList {...{ openViewDirectionsLotModal, carClassDetails }} noImg />
          </div>
        </div>

        <div className='skip-the-counter-confirmation__print-info-details'>
          <div className='skip-the-counter-confirmation__print-info-location'>
            <div className='skip-the-counter-confirmation__print-info-location-item'>
              <Vehicle
                name={name}
                makeModelOrSimilarText={make_model_or_similar_text}
                transmission={transmission}
                carImage={carImage}
                chargedRate={chargedRate}
                estimatedTotalVehicleView={estimated_total_vehicle_view}
                asterisk={asterisk}
                hideEditButtons={true}
                selectedBundleWithExtras={selectedBundleWithExtras}
                shouldDisplayUpgradeOption
                freeUpgradeApplied={freeUpgradeApplied}
                features={features}
              />

              <Extras
                extras={extras}
                numberOfExtrasSelected={numberOfExtrasSelected}
                hideEditButtons={true}
                asterisk={asterisk}
                selectedBundleWithExtras={selectedBundleWithExtras}
                extrasWithoutBundles={extrasWithoutBundles}
              />

              <AdditionalDrivers />

              <TaxesFees taxesDrawerExpanded={true} priceSummary={vehicleRate.price_summary} asterisk={asterisk} />

              <TotalPrice
                isReview
                estimatedTotalView={estimated_total_view}
                estimatedTotalPayment={estimated_total_payment}
                adjustments={savings}
                freeUpgradeApplied={freeUpgradeApplied}
                asterisk={asterisk}
                isPayLater={!prepay_selected}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='skip-the-counter-confirmation__print-info-details-bottom'>
        <div className='skip-the-counter-confirmation__print-info-location-bottom'>
          {/* Bottom Left Section */}
          <div className='skip-the-counter-confirmation__print-info-location-item-bottom'>
            <STCConfirmationRenterInfo driversList={driversList} />
          </div>
          {/* Bottom Center Section */}
          <div className='skip-the-counter-confirmation__print-info-location-item-bottom'>
            <span className='skip-the-counter-confirmation__print-info-location-item-title'>
              {utils.i18n('rental_details_dates_times')}
            </span>

            <ul className='skip-the-counter-confirmation__print-info-location-item-list'>
              <span className='skip-the-counter-confirmation__print-info-location-item-subtitle'>
                {utils.i18n('rental_details_pickup')}
              </span>
              <li>{utils.gmi.getDateTimeObjFromTs(pickupTime).format(DATE_FORMAT)}</li>
              <span className='skip-the-counter-confirmation__print-info-location-item-subtitle'>
                {utils.i18n('rental_details_return')}
              </span>
              <li>{utils.gmi.getDateTimeObjFromTs(returnTime).format(DATE_FORMAT)}</li>
            </ul>
          </div>
          {/* Bottom Right Section */}
          <div className='skip-the-counter-confirmation__print-info-location-item-bottom'>
            <span className='skip-the-counter-confirmation__print-info-location-item-title'>
              {utils.i18n('stc_pickup_and_return')}
            </span>

            <ul className='skip-the-counter-confirmation__print-info-location-item-list skip-the-counter-confirmation__print-info-location-item-list--address'>
              <li>{pickupLocation?.name}</li>
              <li>{pickupLocation?.address?.street_addresses?.join(', ')}</li>
              <li>{getFormattedAddress(pickupLocation?.address)}</li>
              <li>{`${utils.i18n('common_phone')}: ${pickupLocation?.phones?.[0]?.formatted_phone_number}`}</li>
              <li>{pickupLocation?.legal_name}</li>
            </ul>

            {!isSameLocation && (
              <ul className='skip-the-counter-confirmation__print-info-location-item-list skip-the-counter-confirmation__print-info-location-item-list--address'>
                <li>{returnLocation?.name}</li>
                <li>{returnLocation?.address?.street_addresses?.join(', ')}</li>
                <li>{getFormattedAddress(returnLocation?.address)}</li>
                <li>{`${utils.i18n('common_phone')}: ${returnLocation?.phones?.[0]?.formatted_phone_number}`}</li>
                <li>{returnLocation?.legal_name}</li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className='skip-the-counter-confirmation__print-info-steps-rental-agreement'>
        <p>{utils.i18n('stc_ticket_print_terms_n_conditions')}</p>
        {branchLocalAddenda?.map(addenda => <p key={`local-addenda-${addenda.code}`}>{addenda.display_text}</p>)}
      </div>
    </div>
  );
};

STCConfirmationPrint.propTypes = {
  openViewTicketModal: PropTypes.func,
  paymentCardType: PropTypes.string,
  paymentCardNumber: PropTypes.string,
  openViewDirectionsLotModal: PropTypes.func,
  carClassDetails: PropTypes.object,
  pickupLocation: PropTypes.object,
  returnLocation: PropTypes.object,
  pickupTime: PropTypes.string,
  returnTime: PropTypes.string,
  driversList: PropTypes.array,
  driverInfo: PropTypes.object,
  branchLocalAddenda: PropTypes.array,
  reservationConfirmationNumber: PropTypes.string,
  barcodeImage: PropTypes.string,
  isSameLocation: PropTypes.bool,
};

export default STCConfirmationPrint;
