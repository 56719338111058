import React from 'react';
import utils from 'utils';
import { ANALYTICS, MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import EditContactInformationForm from '../EditContactInformationForm';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * The footer works like a render prop, passing a callback so the Form can render it
 * with the formSubmit function. The formSubmit here is the handleSubmit function above
 * with handleClose and formData passed as arguments.
 */
const renderFooter = formSubmit => (
  <ModalFooter
    buttons={[
      {
        type: DECLINE,
        label: utils.i18n('my_profile_edit_contact_information_cancel'),
        analyticsValue: ANALYTICS.CANCEL,
      },
      {
        type: ACCEPT,
        label: utils.i18n('my_profile_edit_contact_information_save'),
        handler: formSubmit,
      },
    ]}
  />
);

/**
 * EditContactInformationModal
 * Handles adding or updating Contact Informations in a single-purpose modal.
 *
 * @return {JSX} EditContactInformationModal jsx component
 */
const EditContactInformationModal = () => (
  <Modal
    modalKey={MODAL.EDIT_PROFILE_CONTACT_INFORMATION_MODAL}
    header={utils.i18n('my_profile_edit_contact_information_modal_title')}
    theme={MODAL_THEMES.WHITE}>
    {({ handleClose }) => (
      <EditContactInformationForm
        onSubmitSuccess={() => handleClose({ analyticsValue: ANALYTICS.SAVE })}
        footer={renderFooter}
      />
    )}
  </Modal>
);

export default EditContactInformationModal;
