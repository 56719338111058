import React from 'react';
import { TOOLTIP_THEMES } from 'constants';
import PropTypes from 'prop-types';

export const propTypes = {
  //  How big the screen is, this comes from redux
  breakpoint: PropTypes.object,
  //  Content of modal
  children: PropTypes.any,
  //  placement of the modal
  //  https://github.com/mohsinulhaq/react-popper-tooltip#placement
  placement: PropTypes.string,
  analyticsKey: PropTypes.string,
};

export const defaultProps = {
  breakpoint: { isDesktop: true, isMobile: false },
  children: <h1>Modal Body</h1>,
  placement: 'right-end',
  theme: TOOLTIP_THEMES.LIGHT_BLUE,
};
