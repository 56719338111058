import { connect } from 'react-redux';
import { actions } from '@ehi/global-marketing-interface';
import utils from 'utils';
import {
  profileDataSelector,
  supportPhoneNumberByCountryAndTypeSelector,
  hasGmaErrorMessagesSelector,
} from 'selectors';
import { modifyProfile } from 'actions/profile';
import { MODAL_PATH } from 'reducers/modal';
import { MODAL } from 'constants';
import { closeModal } from "actions/modal/closeModal";
import EditAccountModal from './EditAccountModal';

const profileDataOptions = {
  first_name: true,
  last_name: true,
  user_name: true,
  email: true,
  special_offers: true,
  phones: true,
  email_update_required: true,
};

const mapStateToProps = (state) => {
  const modalQueue = state.getIn(MODAL_PATH.MODAL_QUEUE);
  const activeModalState = utils.safeToJS(modalQueue.get(0));
  const isOpen = activeModalState?.modalKey === MODAL.EDIT_ACCOUNT;
  const countryCode = utils.config.getDomainCountry();

  return {
    isOpen,
    modalKey: MODAL.EDIT_ACCOUNT,
    profileData: profileDataSelector(state, profileDataOptions) || {},
    supportPhone: supportPhoneNumberByCountryAndTypeSelector(state, { countryCode, phone_type: 'ALAMO_INSIDER' }),
    hasServiceError: hasGmaErrorMessagesSelector(state),
  };
};

const mapDispatchToProps = {
  cleanMessages: actions.cleanMessages,
  closeEditAccountModal: closeModal,
  modifyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountModal);
