import React, { useEffect } from 'react';
import { ASSETS, WINDOW_OBJECT_KEYS } from 'constants';
import Picture from 'components/Picture';
import Anchor from 'components/Anchor';
import utils from 'utils';
import './styles/ad-choices.scss';

/**
 * This component renders the adChoices banner based on an AEM config.
 */
const AdChoices = () => {
  const adChoicesLink = utils.config.getAdChoicesUrl();

  /**
   * When the component mounts, we set the `adchoices` cookie so next time
   * they enter the page, they will not see the banner anymore.
   */
  useEffect(() => {
    utils.cookie.set('adchoices', true, { maxAge: 60 * 60 * 24 });
  }, []);

  const cookiePolicy = (
    <Anchor
      className='link link--text'
      key='cookie_policy'
      isExternal
      hideExternalIcon
      href={utils.config.getUrl(WINDOW_OBJECT_KEYS.COOKIE_POLICY)}>
      {utils.i18n('ad_choices_cookie_policy')}
    </Anchor>
  );

  const adChoices = (
    <Anchor
      className='link link--text ad-choices__logo-link'
      key='ad_choices'
      isExternal
      hideExternalIcon
      href={adChoicesLink}>
      <Picture src={ASSETS.AD_CHOICES} alt={utils.i18n('ad_choices_label')} />
    </Anchor>
  );

  return (
    <section className='ad-choices'>
      <div className='aem-Grid aem-Grid--12 aem-Grid--default--12'>
        <p className='aem-GridColumn aem-GridColumn--default--12 ad-choices__content'>
          {utils.i18n('ad_choices', [cookiePolicy, adChoices], { jsx: true })}
        </p>
      </div>
    </section>
  );
};

export default AdChoices;
