import React, { Component } from 'react';
import utils from 'utils';
import cn from 'classnames';
import { navigationKeyEvent } from 'utils/accessibility/navigationKeyEvent';
import AccordionTitle from './AccordionTitle';
import AccordionContent from './AccordionContent';
import { accordionListPropTypes, accordionListDefaultProps } from './AccordionListPropTypes';

// constants
const TITLE_KEY = 'tab-title';
const CONTENT_KEY = 'tab-content';
const ID_PREFIX = 'id';

export const createComponentArray = ({ items = [], titleKey, contentKey, options = {}, analyticsTitle }) => {
  const { print = false } = options;
  const components = [];
  items.forEach(item => {
    if (item[titleKey] && item[contentKey]) {
      components.push(
        <AccordionTitle key={`${item[titleKey]}-title`} title={item[titleKey]} analyticsTitle={analyticsTitle} />
      );
      components.push(
        <AccordionContent key={`${item[titleKey]}-content`} print={print} analyticsTitle={analyticsTitle}>
          <div dangerouslySetInnerHTML={utils.sanitize(item[contentKey])} />
        </AccordionContent>
      );
    }
  });
  return components.length ? components : null;
};

class AccordionList extends Component {
  static propTypes = accordionListPropTypes;

  static defaultProps = accordionListDefaultProps;

  constructor(props) {
    super(props);
    const { activeIndex, isVertical, isCollapsedInVertical } = props;

    this.titlesSectionRef = React.createRef();
    this.domElIds = [];

    this.state = {
      activeIndex: isVertical && isCollapsedInVertical ? null : activeIndex,
    };
  }

  componentDidMount() {
    if (!this.titlesSectionRef.current) {
      return;
    }

    const onArrowRight = ({
      props: {
        current: { el },
      },
      e,
    }) => {
      e.preventDefault();
      el.click();
    };

    // collapse selected content in accordion
    const onArrowLeft = ({
      props: {
        current: { el },
      },
      e,
    }) => {
      e.preventDefault();
      const isSelected = el.getAttribute('aria-selected') === 'true';
      this.props.isVertical && isSelected && el.click();
    };

    const customHandlers = new Map();
    customHandlers.set('arrowRight', onArrowRight);
    customHandlers.set('arrowLeft', onArrowLeft);

    this.titlesSectionRef.current.addEventListener('keydown', e =>
      navigationKeyEvent({
        isVertical: true,
        isSpaceClick: true,
        domEl: this.titlesSectionRef.current,
        customHandlers,
        e,
      })
    );
  }

  setActiveIndex = newActiveIndex => {
    let activeIndex = newActiveIndex;
    // collapse open section on click in accordion
    if (this.props.isVertical && newActiveIndex === this.state.activeIndex) {
      activeIndex = null;
    }
    this.setState({ activeIndex });
  };

  getAugmentedChildren = () => {
    const { children, isVertical } = this.props;

    const childrenMap = {
      [TITLE_KEY]: [],
      [CONTENT_KEY]: [],
    };
    const indexMap = {
      [TITLE_KEY]: 0,
      [CONTENT_KEY]: 0,
    };

    React.Children.forEach(children, child => {
      let childType = null;

      if (child.type === AccordionTitle) {
        childType = TITLE_KEY;
      } else if (child.type === AccordionContent) {
        childType = CONTENT_KEY;
      } else {
        return;
      }

      const currentIndex = indexMap[childType];
      let currentId;

      if (currentIndex > this.domElIds.length - 1) {
        currentId = utils.gmi.uid(ID_PREFIX);
        this.domElIds.push(currentId);
      } else {
        currentId = this.domElIds[currentIndex];
      }

      const augmentedChild = React.cloneElement(child, {
        isVertical,
        titleId: `${TITLE_KEY}-${currentId}`,
        contentId: `${CONTENT_KEY}-${currentId}`,
        key: `${childType}-${currentIndex}`,
        index: currentIndex,
        isActive: currentIndex === this.state.activeIndex,
        setActiveIndex: this.setActiveIndex,
      });

      childrenMap[childType].push(augmentedChild);
      indexMap[childType] += 1;
    });

    return childrenMap;
  };

  getVerticalLayoutChildren = augmentedChildren => {
    const groupedChildren = [];
    for (let i = 0; i < augmentedChildren[TITLE_KEY].length; i += 1) {
      groupedChildren.push(augmentedChildren[TITLE_KEY][i]);
      groupedChildren.push(augmentedChildren[CONTENT_KEY][i]);
    }
    return groupedChildren;
  };

  renderVerticalLayout = () => {
    const { customClass } = this.props;
    const augmentedChildren = this.getAugmentedChildren();
    return (
      <section
        ref={this.titlesSectionRef}
        className={cn('tabs tabs-vertical', { [customClass]: customClass })}
        role='tablist'>
        {this.getVerticalLayoutChildren(augmentedChildren)}
      </section>
    );
  };

  renderHorizontalLayout = () => {
    const { customClass } = this.props;
    const augmentedChildren = this.getAugmentedChildren();

    return (
      <section className={cn('tabs tabs-horizontal', { [customClass]: customClass })}>
        <section ref={this.titlesSectionRef} className='tabs-horizontal__titles-container' role='tablist'>
          {augmentedChildren[TITLE_KEY]}
        </section>
        <section className='tabs-horizontal__items-container'>{augmentedChildren[CONTENT_KEY]}</section>
      </section>
    );
  };

  render() {
    const { children, isVertical } = this.props;
    if (!children) {
      return null;
    }
    return isVertical ? this.renderVerticalLayout() : this.renderHorizontalLayout();
  }
}

export default AccordionList;
