import React from 'react';
import utils from 'utils';
import Button from 'components/Button';
import { ANALYTICS } from 'constants';
import propTypes from './modifyResBannerPropTypes';

const ModifyResBanner = ({ handleExitModify, breakpoint }) => (
  <div className='modify-res-banner modify-res-banner__container'>
    {!breakpoint.isMobile && (
      <div className='modify-res-banner__content'>{utils.i18n('modify_reservation_banner_copy')}</div>
    )}
    <Button
      link
      data-dtm-track={utils.analytics.dtm(ANALYTICS.UI_BUTTON, ANALYTICS.MODIFY, 'exit_without_modifying')}
      className='modify-res-banner__edit-btn'
      onClick={handleExitModify}>
      {utils.i18n('modify_reservation_exit_modify_button')}
    </Button>
  </div>
);

ModifyResBanner.propTypes = propTypes;

export default ModifyResBanner;
