import { connect } from 'react-redux';
import { clearDiscountInReservationFlow } from "actions/reservation/clearDiscountInReservationFlow";
import RemoveCouponModal from './RemoveCouponModal';

const mapDispatchToProps = {
  clearDiscountInReservationFlow,
};

export default connect(
  null,
  mapDispatchToProps
)(RemoveCouponModal);
