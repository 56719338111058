import React, { Component } from 'react';
import utils from 'utils';
import Button from 'components/Button';
import { propTypes, defaultProps } from './ListItemPropTypes';

class ListItem extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  handleClick = e => {
    const { listItem, onClick } = this.props;

    utils.safeExecute(onClick, { e, listItem });
  };

  render() {
    const { css, listItem, listItemComponent } = this.props;
    return (
      <li className={css.ListItem}>
        {listItemComponent ? (
          <listItemComponent css={css} onClick={this.handleClick} listItem={listItem} />
        ) : (
          <Button css={css} onClick={this.handleClick}>
            {listItem.name}
          </Button>
        )}
      </li>
    );
  }
} //

export default ListItem;
