import React, { Component } from 'react';
import utils from 'utils';
import Button from 'components/Button';
import { propTypes } from './holdCreditCardAlertPropTypes';

class HoldCreditCardAlert extends Component {
  static propTypes = propTypes;

  render() {
    const { showModal } = this.props;
    return (
      <div className='hold-credit-card-alert__container component-theme--light'>
        <p className='hold-credit-card-alert__main-text'>{utils.i18n('credit_card_alert_hold_message')}</p>
        <Button link className='hold-credit-card-alert__link' onClick={showModal}>
          {utils.i18n('credit_card_alert_hold_link_text')}
        </Button>
      </div>
    );
  }
}

export default HoldCreditCardAlert;
