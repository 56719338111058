import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import RESERVATIONS from 'constants/reservations';
import { getReservationConfirmationNumber } from 'selectors/reservationSelectors';
import { extrasSelector } from 'selectors/extrasSelectors';
import STCProtectionOptionDetailsModal from 'components/modals/STCProtectionOptionDetailsModal';
import STCFormCard from '../STCFormCard';
import STCProtectionItem from './STCProtectionItem';

const { EXTRAS_INCLUDED_STATUS, EXTRAS_OPTIONAL_STATUS, EXTRAS_MANDATORY_STATUS } = RESERVATIONS;

const filterExtrasByStatus = (extras, status) => extras.filter(extra => extra?.status === status);

/**
 * STCReviewProtectionOptions
 *
 * @param {object} props
 * @param {boolean} props.haveErrors - Is there any error in the form for Protection Options inputs?
 * @param {boolean} props.submitFailed - Did submit failed? (Used to show error message only after any submit)
 * @return {JSX}
 */
const STCReviewProtectionOptions = ({ haveErrors, submitFailed }) => {
  const extras = useSelector(extrasSelector);
  const reservationConfirmationNumber = useSelector(getReservationConfirmationNumber);

  const extrasSections = useMemo(
    () => {
      const { insurance = [] } = extras;
      const optionsByStatus = [];

      const includedExtras = filterExtrasByStatus(insurance, EXTRAS_INCLUDED_STATUS);
      if (utils.gmi.isArrayNotEmpty(includedExtras)) {
        optionsByStatus.push({
          title: utils.i18n('stc_protection_option_included'),
          items: includedExtras,
        });
      }

      const mandatoryExtras = filterExtrasByStatus(insurance, EXTRAS_MANDATORY_STATUS);
      if (utils.gmi.isArrayNotEmpty(mandatoryExtras)) {
        optionsByStatus.push({
          title: utils.i18n('stc_protection_option_mandatory'),
          items: mandatoryExtras,
        });
      }

      const optionalExtras = filterExtrasByStatus(insurance, EXTRAS_OPTIONAL_STATUS);
      const selectedOptionals = optionalExtras.filter(extra => !!extra.selected_quantity);
      if (utils.gmi.isArrayNotEmpty(selectedOptionals)) {
        optionsByStatus.push({
          title: utils.i18n('stc_protection_option_previously_selected'),
          items: selectedOptionals,
        });
      }
      const additionalOptionals = optionalExtras.filter(extra => !extra.selected_quantity);
      if (utils.gmi.isArrayNotEmpty(additionalOptionals)) {
        // If Additional Protection Options is the only section available, no title is added.
        const haveOnlyAdditionalOption = !!optionsByStatus.length;
        optionsByStatus.push({
          title: haveOnlyAdditionalOption && utils.i18n('stc_protection_option_additional'),
          items: additionalOptionals,
        });
      }

      return optionsByStatus;
    },
    // Sections must not change unless Reservation's Confirmation Number gets updated (reservation is loaded),
    // otherwise using the extras object would update the sections everytime the user accepts or decline
    // a protection option because it will refresh the session and the value itself.
    [reservationConfirmationNumber]
  );

  return (
    <STCFormCard
      title={utils.i18n('stc_review_protection_options_title')}
      tooltipText={utils.i18n('stc_review_protection_options_tooltip')}
      isComplete={!haveErrors}>
      {haveErrors && submitFailed && (
        <p className='skip-the-counter-flow__paragraph skip-the-counter-flow__paragraph--error'>
          {utils.i18n('stc_review_protection_options_error_message')}
        </p>
      )}
      <p className='skip-the-counter-flow__paragraph skip-the-counter-flow__paragraph--margin-bottom'>
        {utils.i18n('stc_review_protection_options_copy')}
      </p>

      {extrasSections.map(section => (
        <div className='skip-the-counter-flow-protection-item__container' key={`options_${section.title}`}>
          {section.title && <h4 className='skip-the-counter-flow-protection-item__section-title'>{section.title}</h4>}
          {section.items?.map(item => <STCProtectionItem item={item} key={item.code} />)}
        </div>
      ))}
      <STCProtectionOptionDetailsModal />
    </STCFormCard>
  );
};

STCReviewProtectionOptions.propTypes = {
  haveErrors: PropTypes.bool,
  submitFailed: PropTypes.bool,
};

export default STCReviewProtectionOptions;
