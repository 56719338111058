import React, { useEffect } from 'react';
import { utils as globalUtils, actions } from '@ehi/global-marketing-interface';
import { config } from 'utils/config';
import { i18n } from 'utils/i18n';
import { capitalize } from 'utils/string';
import useActions from 'hooks/useActions';
import { rentalRetrieveAndConfirm, modifyRental } from 'actions/modifyRental';
import PostResLayout from 'components/PostRes/PostResLayout';
import Title from 'components/Title';
import RentalTripCard from 'components/RentalFlow/RentalTripCard';
import ServiceErrors from 'components/ServiceErrors';
import RentalNotEligibleForModificationsModal from 'components/modals/RentalNotEligibleForModificationsModal';

const ConfirmReturn = (props) => {
  const { firstName, returnLocation, ticketNumber, showRentalNotEligibleModal } = props || {};
  const rentalRetrieveAndConfirmAction = useActions(rentalRetrieveAndConfirm);
  const modifyRentalAction = useActions(modifyRental);
  const cleanMessagesAction = useActions(actions.cleanMessages);

  useEffect(() => {
    // clean gmi message actions
    cleanMessagesAction();
  }, []);

  const returnTime = globalUtils
    .getDateTimeObjFromTs(props.returnTime)
    .format(config.getDateTimeLongFormat('llll_with_comma'));

  return (
    <React.Fragment>
      <PostResLayout noMinHeight>
        <>
          <ServiceErrors />
          <div className='confirm-return-header'>
            <Title
              type='h1'
              className='rental-updated__title'
              text={i18n('confirm_return_heading', [capitalize(firstName)])}
            />
            <p className='rental-updated__title-subheading-title-copy'>{i18n('confirm_return_heading_details')}</p>
          </div>

          <div className='theme--dark-blue'>
            <RentalTripCard
              rentalRetrieveAndConfirmAction={rentalRetrieveAndConfirmAction}
              modifyRentalAction={modifyRentalAction}
              ticketNumber={ticketNumber}
              returnLocation={returnLocation}
              dateOfRentInteraction={returnTime}
              showRentalNotEligibleModal={showRentalNotEligibleModal}
            />
          </div>
        </>
      </PostResLayout>
      <RentalNotEligibleForModificationsModal ticketNumber={ticketNumber} />
    </React.Fragment>
  );
};

export default ConfirmReturn;
