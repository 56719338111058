import React from 'react';
import cn from 'classnames';
import utils from 'utils';
import FormattedPrice from 'components/FormattedPrice';
import propTypes from './mileagePropTypes';

const Mileage = ({ mileageInfo, className }) => {
  const distanceUnit = utils.getDistanceUnit(mileageInfo);

  return (
    <div className={cn('rental-details__mileage', className)}>
      {mileageInfo.unlimited_mileage ? (
        <p className='rental-details__unlimited-mileage'>
          {utils.i18n('rental_details_rental_includes')} <span>{utils.i18n('rental_details_unlimited_mileage')}</span>
        </p>
      ) : (
        <p className='rental-details__mileage-pricing'>
          {utils.i18n(
            'rental_details_mileage_pricing',
            [
              <>{mileageInfo.total_free_miles}</>,
              <>{utils.i18n(`rental_details_distance_unit_plural_${distanceUnit}`)}</>,
              <FormattedPrice key='0'>{utils.getFormattedPrice(mileageInfo.excess_mileage_rate_view)}</FormattedPrice>,
              <>{utils.i18n(`rental_details_distance_unit_singular_${distanceUnit}`)}</>,
            ],
            { jsx: true }
          )}
        </p>
      )}
    </div>
  );
};

Mileage.propTypes = propTypes;

export default Mileage;
