import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RESERVATIONS } from 'constants';
import Button from 'components/Button';
import ProgressBarCID from 'components/ReservationFlow/ProgressBar/ProgressBarCID';
import CouponNavigationBanner from 'components/CouponNavigationBanner';
import cn from 'classnames';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';

const { RESFLOW_PATHS_CONFIG } = RESERVATIONS;

class ProgressBarTab extends Component {
  handleClick = () => {
    const { config, expanded, handleExpandTab, mobile } = this.props;
    handleExpandTab(!expanded ? config.id : null);
    // analytics for mobile location , date time cta
    let key = '';
    if (config.id === 'datesTimes') {
      key = ANALYTICS.DATE_TIME;
    }

    if (config.id === 'location') {
      key = ANALYTICS.LOCATION_PAGE;
    }

    if (mobile) {
      utils.analytics.interaction(utils.analytics.prepareKey(ANALYTICS.PROGRESS_BAR), key, ANALYTICS.EDIT);
    }
  };

  openModal = () => {
    const { config, openModalWithProps } = this.props;
    const { modalKey, props } = config.mobileEditOpensModal;

    utils.analytics.interaction(
      utils.analytics.prepareKey(ANALYTICS.PROGRESS_BAR),
      ANALYTICS.VEHICLE_PAGE,
      ANALYTICS.EDIT
    );

    return openModalWithProps(modalKey, props, { skipAnalytics: true });
  };

  // analytics for extras cta
  handleMobileEditLinkForExtras = () => {
    const { config } = this.props;
    if (config.mobileEditIsLink && config.id === ANALYTICS.EXTRAS) {
      utils.analytics.interaction(utils.analytics.prepareKey(ANALYTICS.PROGRESS_BAR), ANALYTICS.EXTRAS, ANALYTICS.EDIT);
    }
  };

  componentDidUpdate(prevProps) {
    // Checking to see if route has changed to the same step where the tab bar is
    // expanded, such as in using the edit vehicle btn, and toggle it off
    // also check if the path has changed
    const { config, expanded, match, handleExpandTab } = this.props;
    const isCurrentStep = match && config.paths.indexOf(match.path) !== -1;
    if (expanded && prevProps.expanded && match.path !== prevProps.match.path && isCurrentStep) {
      handleExpandTab();
    }
  }

  render() {
    const {
      config,
      expanded,
      isAllowed,
      isCompleted,
      match,
      listNumber,
      mobile,
      contract_details,
      isTrueModify,
      partner_reward_program,
      coupons,
      freeUpgradeApplied,
    } = this.props;
    const {
      id,
      progressBarLabel,
      ProgressBarExpandedMobileComponent,
      ProgressBarLabelComponent,
      hiddenBeforeAllowed,
      mobileEditIsLink,
      mobileEditOpensModal,
      paths,
      disabledOnPaths,
      enabledOnOwnPaths,
      isNeverCompleted,
    } = config;
    const completed = isCompleted && !isNeverCompleted;
    const hidden = hiddenBeforeAllowed && !isAllowed;
    const currentStep = match && paths.indexOf(match.path) !== -1;
    const isVehicleSelectOrExtrasSelect =
      currentStep && (match.path === RESFLOW_PATHS_CONFIG.vehicle[0] || match.path === RESFLOW_PATHS_CONFIG.extras[0]);
    const showLabelComponent = isAllowed && !isVehicleSelectOrExtrasSelect;
    const isOnDisabledPath = disabledOnPaths && match && disabledOnPaths.indexOf(match.path) !== -1;
    const disabled = isOnDisabledPath || !isAllowed || (currentStep && !enabledOnOwnPaths);
    const isTotal = id === 'total';
    const isReviewandReserve = id === 'review';

    const shouldShowProgressBarCID =
      listNumber === 1 &&
      ((contract_details?.contract_type && contract_details?.contract_name) || partner_reward_program?.program_name);

    // mobile  progress bar handling active step, completed step based on available reservation utils
    const availableReservationPaths = Object.values(RESFLOW_PATHS_CONFIG).flat();
    const isReservationFLowUtilsAvailable = currentStep && availableReservationPaths.includes(match.path);

    let showLabelComponentMobile;
    let currentActiveStep;

    // mobile  progress bar handling active step based on available reservation utils
    if (isReviewandReserve) {
      currentActiveStep = false;
    } else if (isReservationFLowUtilsAvailable) {
      currentActiveStep = false;
    } else {
      currentActiveStep = completed;
    }

    // mobile  progress bar handling completed step based on available reservation utils
    if (isReviewandReserve) {
      showLabelComponentMobile = false;
    } else if (isAllowed && !isReservationFLowUtilsAvailable) {
      showLabelComponentMobile = true;
    } else {
      showLabelComponentMobile = false;
    }

    if (mobile) {
      return (
        !hidden && (
          <section
            className={cn('progress-bar__section component-theme--light-blue', {
              'current-step': currentStep,
              disabled,
              enabled: !disabled,
              completed: currentActiveStep,
              total: isTotal,
            })}
          >
            {shouldShowProgressBarCID && (
              <ProgressBarCID
                type={contract_details?.contract_type}
                message={contract_details?.contract_name}
                termsConditions={contract_details?.terms_and_conditions}
                isTrueModify={isTrueModify}
              />
            )}
            {coupons?.[0] && <CouponNavigationBanner coupons={coupons} />}

            <span className='section__label'>
              {!showLabelComponentMobile && `${listNumber}. `}
              {utils.i18n(progressBarLabel)}

              {!isReviewandReserve &&
                !disabled &&
                (mobileEditIsLink ? (
                  <Link to={paths[0]} onClick={this.handleMobileEditLinkForExtras}>
                    <Button
                      data-dtm-track={utils.analytics.dtm(
                        utils.analytics.prepareKey(ANALYTICS.PROGRESS_BAR),
                        ANALYTICS.EXTRAS,
                        ANALYTICS.EDIT
                      )}
                      className='section__edit-button'
                      button={false}
                    >
                      {utils.i18n('progress_bar_mobile_edit_button')}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    className='section__edit-button'
                    button={false}
                    onClick={mobileEditOpensModal ? this.openModal : this.handleClick}
                  >
                    {utils.i18n(isTotal ? 'progress_bar_mobile_details_button' : 'progress_bar_mobile_edit_button')}
                  </Button>
                ))}
            </span>

            {showLabelComponent && (
              <span className='section__values'>
                {id === 'vehicle' && freeUpgradeApplied && (
                  <div className='rental-details__free-upgrade'>
                    {utils.i18n('coupon_name_applied', [utils.i18n('coupon_upgrade_adjustment_name')])}
                  </div>
                )}
                {ProgressBarExpandedMobileComponent ? (
                  <ProgressBarExpandedMobileComponent />
                ) : (
                  !isReviewandReserve && (
                    <ProgressBarLabelComponent config={config} currentStep={currentStep} match={match} mobile />
                  )
                )}
              </span>
            )}
          </section>
        )
      );
    }

    return (
      !hidden && (
        <section
          className={cn('progress-bar__section component-theme--light-blue', {
            'current-step': currentStep,
            disabled,
            enabled: !disabled,
            expanded,
            completed,
          })}
        >
          {shouldShowProgressBarCID && (
            <ProgressBarCID
              type={contract_details?.contract_type}
              message={contract_details?.contract_name}
              termsConditions={contract_details?.terms_and_conditions}
              isTrueModify={isTrueModify}
            />
          )}
          <Button
            className='section__top section__button'
            button={false}
            onClick={this.handleClick}
            disabled={disabled}
            ariaText={utils.i18n(progressBarLabel)}
            ariaExpanded={expanded.toString()}
          >
            <span className='section__label'>{utils.i18n(progressBarLabel)}</span>
            {showLabelComponent && (
              <ProgressBarLabelComponent config={config} currentStep={currentStep} match={match} />
            )}
            {/* this next section may result in an empty span. this is desired to maintain height consistency across tabs */}
            {!showLabelComponent && (
              <span className='section__values'>
                <span className={cn('section__value', { 'current-step': currentStep })}>
                  {currentStep && utils.i18n('progress_bar_current_step')}
                </span>
              </span>
            )}
          </Button>
        </section>
      )
    );
  }
}

ProgressBarTab.propTypes = {
  isAllowed: PropTypes.bool,
  isCompleted: PropTypes.bool,
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    progressBarLabel: PropTypes.string.isRequired,
  }),
  match: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  handleExpandTab: PropTypes.func.isRequired,
  openModalWithProps: PropTypes.func.isRequired,
  gmiReservationState: PropTypes.object,
  isTrueModify: PropTypes.bool,
  coupons: PropTypes.array,
  freeUpgradeApplied: PropTypes.bool,
  vehicleRate: PropTypes.object,
};

export default ProgressBarTab;
