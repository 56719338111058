import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import { config } from 'utils/config';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import cn from 'classnames';
import CHECKIN from 'constants/checkin';
import GLOBAL from 'constants/global';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import {
  onlineCheckinExternalLinkSelector,
  counterByPassExternalLinkSelector,
} from 'selectors/reservationFlowSelectors';
import SignInModal from 'components/modals/SignInModal';
import CheckInLoyaltyConflictModal from 'components/modals/CheckInLoyaltyConflictModal';
import WayfindingsModal from 'components/modals/WayfindingsModal';
import AcceleratedCheckinPickupHeaderStatus from './AcceleratedCheckinPickupHeaderStatus';
import SkipTheCounterPickupHeaderStatus from './SkipTheCounterPickupHeaderStatus';

const { STC_ORIGIN } = SKIP_THE_COUNTER;
const { CHECKIN_ORIGIN } = CHECKIN;
/**
 * Confirmation and Rental Details OCI status and actions for the reservation in session
 *
 * @param {object} props - the React props passed into this component
 * @param {boolean} props.isCheckedIn - Is OCI already activated for this reservation?
 * @param {boolean} props.isPickupStatusAddedAutomatically - Is OCI auto activated by profile data?
 * @param {boolean} props.additionalDriverDataNotProvided - Is Additional Driver Data not provided? (future work)
 * @param {boolean} props.isSkipTheCounterEligible - Is this reservation eligible for Skip the Counter?
 * @param {boolean} props.isSkipTheCounterCompleted - Is this reservation already Skipping the Counter?
 * @param {boolean} props.isFoundRental - Is this reservation looked up or was it just confirmed?
 * @param {function} props.openLearnMoreAboutSkipTheCounterModal - Open the Learn More modal about Skip the Counter
 * @param {function} props.openLearnMoreAboutOCIInactiveModal - Open the Learn More modal about Accelerated Check in with inactive status
 * @param {function} props.openLearnMoreAboutOCIActivatedModal - Open the Learn More modal about Accelerated Check in with activated status
 * @param {function} props.checkinStart - Start OCI flow for this reservation
 * @param {function} props.skipTheCounterStart - Start STC flow for this reservation
 *
 * @return {JSX}
 */
const ConfirmationHeaderPickupStatus = ({
  isCheckedIn,
  isPickupStatusAddedAutomatically,
  isSkipTheCounterEligible,
  isSkipTheCounterCompleted,
  isFoundRental,
  checkinStart,
  skipTheCounterStart,
  isOnlineCheckInEligible,
  breakpoint,
  isStcBridgePage = false,
  isTourVoucherReservation,
  isTourReservation,
  isVirginReservation,
  rentalState,
}) => {
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const onlineCheckinExternalLink = useSelector(onlineCheckinExternalLinkSelector);
  const counterByPassExternalLink = useSelector(counterByPassExternalLinkSelector);

  // to verify reservation is non-virgin , we will be checking non-virgin restricted reservation or
  // checking if thre reservation is virgin reservation
  const isNonVirginTourReservation = isTourReservation && isTourVoucherReservation && !isVirginReservation;

  const isCustomPathEnabled = config.getIsCustomPathEnabled();

  const checkinOrigin = isFoundRental ? CHECKIN_ORIGIN.RESERVATION_DETAILS : CHECKIN_ORIGIN.CONFIRMATION;

  const skipTheCounterOrigin = isFoundRental ? STC_ORIGIN.RESERVATION_DETAILS : STC_ORIGIN.CONFIRMATION;

  const isCheckedInCompleted = isCheckedIn || (isSkipTheCounterCompleted && !isCheckedIn);

  const tripCardTitle =
    isSkipTheCounterCompleted && !isCheckedIn ? i18n('trip_card_all_set') : i18n('confirmation_pick_up_card_title');

  // save time and activate checkin
  const showOCIEligibleFlow =
    isNonVirginTourReservation &&
    ((!isCheckedIn && !isSkipTheCounterCompleted && isOnlineCheckInEligible) ||
      (!isSkipTheCounterCompleted && !isSkipTheCounterEligible && isCheckedIn));

  // Completed OCI and STC inElgible
  const showOCICompleteIfSTCInElgible =
    isNonVirginTourReservation && isCheckedIn && !isSkipTheCounterCompleted && !isSkipTheCounterEligible;

  // save time and complete Stc flow  if elgible
  const showSTCEligibleFlow =
    isNonVirginTourReservation && !isSkipTheCounterCompleted && isSkipTheCounterEligible && isCheckedIn;

  // stc complete
  const showSTCCompleteEligibleFlow = isNonVirginTourReservation && isSkipTheCounterCompleted && !isCheckedIn;

  // Custom path reservation with rentalState as Pending Confirmation
  const customPathResWithPendingConfirmation = isCustomPathReservation && rentalState === GLOBAL.PENDING_CONFIRMATION;

  // Check-in available
  const renderOCIFLow = () => (
    <AcceleratedCheckinPickupHeaderStatus
      isCustomPathReservation={isCustomPathReservation}
      onlineCheckinExternalLink={onlineCheckinExternalLink}
      counterByPassExternalLink={counterByPassExternalLink}
      isCustomPathEnabled={isCustomPathEnabled}
      checkinOrigin={checkinOrigin}
      isSkipTheCounterCompleted={isSkipTheCounterCompleted}
      isOnlineCheckInEligible={isOnlineCheckInEligible}
      isPickupStatusAddedAutomatically={isPickupStatusAddedAutomatically}
      checkinStart={checkinStart}
      isCheckedIn={isCheckedInCompleted}
      isNonVirginTourReservation={isNonVirginTourReservation}
      breakpoint={breakpoint}
    />
  );

  // STC Elgible / In Elgible

  const renderSTCFlow = () => (
    <SkipTheCounterPickupHeaderStatus
      isCustomPathReservation={isCustomPathReservation}
      onlineCheckinExternalLink={onlineCheckinExternalLink}
      counterByPassExternalLink={counterByPassExternalLink}
      isCustomPathEnabled={isCustomPathEnabled}
      skipTheCounterOrigin={skipTheCounterOrigin}
      skipTheCounterStart={skipTheCounterStart}
      isSkipTheCounterCompleted={isSkipTheCounterCompleted}
      isOnlineCheckInEligible={isOnlineCheckInEligible}
      isSkipTheCounterEligible={isSkipTheCounterEligible}
      isCheckedIn={isCheckedIn}
      isPickupStatusAddedAutomatically={isPickupStatusAddedAutomatically}
      isStcBridgePage={isStcBridgePage}
      isNonVirginTourReservation={isNonVirginTourReservation}
      breakpoint={breakpoint}
      customPathResWithPendingConfirmation={customPathResWithPendingConfirmation}
    />
  );

  const renderOCIAndStvDivider = () => (
    <div className='confirmation-oci-status__oci-step-container'>
      <div
        className={cn({
          'oci-step-inner': !breakpoint.isMobile,
          'horizontal-lines': breakpoint.isMobile,
        })}
      >
        <div className='confirmation-oci-status__oci-step-indicator-circle' />
      </div>
    </div>
  );

  return (
    <section className='confirmation-oci-status'>
      <h3 className='confirmation-oci-status__header-title'>{tripCardTitle}</h3>
      <div
        className={cn('confirmation-oci-status__header-section-row', {
          'confirmation-oci-status__no-acc-check-in': !isCheckedInCompleted,
          'confirmation-oci-status__check-in-done': isCheckedInCompleted,
          'confirmation-oci-status__stc-completed': isSkipTheCounterCompleted,
          'confirmation-oci-status__stc-not-eligible':
            !isSkipTheCounterEligible || customPathResWithPendingConfirmation,
        })}
      >
        {!isNonVirginTourReservation && (
          <React.Fragment>
            {renderOCIFLow()}
            {renderOCIAndStvDivider()}
            {renderSTCFlow()}
          </React.Fragment>
        )}
        {isNonVirginTourReservation && (showOCIEligibleFlow || showOCICompleteIfSTCInElgible) && renderOCIFLow()}
        {isNonVirginTourReservation && (showSTCEligibleFlow || showSTCCompleteEligibleFlow) && renderSTCFlow()}
      </div>
      <SignInModal />
      <CheckInLoyaltyConflictModal />
      <WayfindingsModal />
    </section>
  );
};

ConfirmationHeaderPickupStatus.propTypes = {
  isCheckedIn: PropTypes.bool,
  isPickupStatusAddedAutomatically: PropTypes.bool,
  additionalDriverDataNotProvided: PropTypes.bool,
  isSkipTheCounterEligible: PropTypes.bool,
  isSkipTheCounterCompleted: PropTypes.bool,
  isFoundRental: PropTypes.bool,
  openLearnMoreAboutSkipTheCounterModal: PropTypes.func,
  openLearnMoreAboutOCIInactiveModal: PropTypes.func,
  openLearnMoreAboutOCIActivatedModal: PropTypes.func,
  checkinStart: PropTypes.func,
  skipTheCounterStart: PropTypes.func,
  isOnlineCheckInEligible: PropTypes.bool,
  isVirginReservation: PropTypes.bool,
  isStcBridgePage: PropTypes.bool,
  isTourVoucherReservation: PropTypes.bool,
  isTourReservation: PropTypes.bool,
  gmaBrandInformation: PropTypes.bool,
  isRestrictedReservation: PropTypes.bool,
};

export default ConfirmationHeaderPickupStatus;
