import { connect } from 'react-redux';
import utils from 'utils';
import { authenticatedSelector } from 'selectors';
import LearnAboutPrepayAndPayLaterContent from './LearnAboutPrepayAndPayLaterContent';

const mapStateToProps = state => ({
  authenticated: authenticatedSelector(state),
});

export default connect(
  mapStateToProps,
  null
)(utils.toJS(LearnAboutPrepayAndPayLaterContent));
