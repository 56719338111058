import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS, MODAL } from 'constants';
import Button from 'components/Button';
import PrePaymentTCRequestModalContent from 'components/PrePaymentTCRequestModalContent';

/**
 * LearnAboutPrepayAndPayLaterContent component showing payment options copy and Prepay terms drawer
 *
 * @param {object}    props - React Props
 * @param {object}    props.authenticated - indicates whether Alamo Insider user is signed in
 * @param {boolean}   props.guaranteedRes - indicates whether this is a guaranteed reservation vehicle
 * @return {JSX}      LearnAboutPrepayAndPayLaterContent component
 */
export default class LearnAboutPrepayAndPayLaterContent extends Component {
  state = { expanded: false };

  handleExpandDrawer = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }));

    // Analytics - fire custom "drawer" event (push to interaction object)
    utils.analytics.interaction(
      ANALYTICS.DRAWER, // type
      ANALYTICS.PREPAY_POLICY, // key
      this.state.expanded ? ANALYTICS.CLOSE : ANALYTICS.OPEN // value
    );
  };

  render() {
    const { authenticated, guaranteedRes } = this.props;
    const { expanded } = this.state;
    return (
      <div
        className='pre-pay-terms-modal modal-themed__paragraph padding-top--condensed'
        id={`${MODAL.LEARN_ABOUT_PREPAY_AND_PAY_LATER}_desc`}>
        <div className='modal-themed__section'>
          <h3 className='modal-themed__section__header pre-pay-terms-modal__pre-pay-header'>
            {utils.i18n('pre_pay_terms_modal_pre_pay_header')}
          </h3>

          {utils.i18n(authenticated ? 'authenticated_pre_pay_terms_modal_copy' : 'pre_pay_terms_modal_copy')}
        </div>

        <div className='modal-themed__section'>
          <h3 className='modal-themed__section__header'>{utils.i18n('vehicle_select_pay_later')}</h3>

          {utils.i18n(authenticated ? 'authenticated_pay_later_terms_modal_copy' : 'pay_later_terms_modal_copy')}
        </div>

        {guaranteedRes && (
          <div className='modal-themed__section guaranteed-res-section'>
            {utils.i18n('guaranteed_selection_payment_policy')}
          </div>
        )}

        <div className='modal-themed__drawer-container'>
          <Button
            button={false}
            type='button'
            aria-expanded={expanded}
            className={cn('modal-themed__drawer', { 'modal-themed__drawer--expanded': expanded })}
            onClick={this.handleExpandDrawer}>
            {utils.i18n('pre_pay_policy_drawer')}
          </Button>
        </div>

        {expanded && <PrePaymentTCRequestModalContent className='modal-themed__section modal-themed__gbo-content' />}
      </div>
    );
  }
}

LearnAboutPrepayAndPayLaterContent.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  guaranteedRes: PropTypes.bool,
};
