import React, { Component } from 'react';
import cn from 'classnames';
import utils from 'utils';
import Button from 'components/Button';
import RegionTileContent from './RegionTileContent';
import { propTypes } from './RegionTilePropTypes';

class RegionTile extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = {
      index: props.index,
      uuid: utils.dom.uuid(),
      isExpanded: props.expanded,
    };
  }

  static getDerivedStateFromProps(props) {
    // If onToggle is set, let callback handle expanding states via prop
    return props.onToggle ? { isExpanded: props.expanded } : null;
  }

  handleToggle = (e) => {
    const { onToggle } = this.props;
    e.preventDefault();
    // If onToggle is set, let callback handle expanding states via prop
    onToggle ? onToggle(this.state) : this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { tileImage, name, countrySet, mapImage, disabled } = this.props;
    const { uuid, isExpanded } = this.state;
    const { countries } = countrySet;

    return (
      <div className={cn('region-tile', { disabled, expanded: isExpanded })}>
        <Button
          plain
          className='region-tile__toggler'
          id={uuid}
          aria-label={name}
          aria-expanded={isExpanded ? 'true' : 'false'}
          aria-controls={`${uuid}__drawer`}
          onClick={this.handleToggle}
        >
          <div className='region-tile__image'>
            <img src={tileImage} alt={name} />
          </div>
          <div className='region-tile__title-bar'>
            <h3>{name}</h3>
            <span className='region-tile__countries-count'>
              {utils.i18n('countries_count', { 0: Object.keys(countries).length })}
            </span>
            <span className='region-tile__view-all'>
              <span className='text'>{utils.i18n('view_all')}</span>
            </span>
            <span className='region-tile__hide-all'>
              <span className='text'>{utils.i18n('hide_all')}</span>
            </span>
          </div>
        </Button>
        <div
          className={`region-tile__drawer`}
          id={`${uuid}__drawer`}
          aria-labelledby={uuid}
          role='region'
          hidden={!isExpanded}
        >
          <RegionTileContent {...countrySet} name={name} mapImage={mapImage} />
        </div>
      </div>
    );
  }
}

export default RegionTile;
