import { connect } from 'react-redux';
import utils from 'utils';
import { GMA_RESPONSE_CODES } from 'constants';
import { setPrerateAdditionalInformation } from 'actions/bookingWidget';
import { initiateReservationFlow } from "actions/reservation/initiateReservationFlow";
import PrerateAdditionalInfoModalContent from './PrerateAdditionalInfoModalContent';

const mapStateToProps = state => {
  let contractRequirements = utils.safeToJS(
    state.getIn(['gmi', 'session', 'gma', 'initiate_request', 'contract_details', 'additional_information']),
    []
  );

  //  if validate_additional_info is set to false, it is a post rate item which we address in the review step
  contractRequirements = contractRequirements.filter(requirement => requirement.validate_additional_info);

  const reservationsError = state.getIn(['gmi', 'messages', 'gma_services', 'reservations/initiate']) || null;

  const hasAdditionalFieldsError =
    (reservationsError &&
      reservationsError.find(
        message => message.code === GMA_RESPONSE_CODES.CROS_RES_PRE_RATE_ADDITIONAL_FIELD_REQUIRED
      )) ||
    false;

  return {
    hasAdditionalFieldsError,
    contractRequirements,
    reservationsError,
  };
};

const mapDispatchToProps = {
  handleInitiate: initiateReservationFlow,
  setPrerateAdditionalInformation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(PrerateAdditionalInfoModalContent));
