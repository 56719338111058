import React from 'react';
import Modal from 'components/Modal';
import { MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants';
import { ModalFooter } from 'components/Modal/ModalFooter';
import utils from 'utils';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import ANALYTICS from 'constants/analytics';
import CreditCardDateExpirationContentModal from './CreditCardDateExpirationContentModal';

/**
 * CreditCardDateExpirationModal - Wrapper for CreditCardDateExpirationContentModal
 * @returns {JSX}
 */
const CreditCardDateExpirationModal = () => {
  const { CUSTOMER_SUPPORT } = WINDOW_OBJECT_KEYS;
  const linkTo = utils.config.getRedirectUrl(CUSTOMER_SUPPORT);

  return (
    <Modal
      modalKey={MODAL.PAYMENT_METHOD_EXPIRING_SOON_MODAL}
      header={utils.i18n('modal_title_payment_method_expired')}
      theme={MODAL_THEMES.WHITE}
    >
      <>
        <CreditCardDateExpirationContentModal />
        <ModalFooter
          fullWidthButton
          buttons={[
            {
              type: MODAL_BUTTON_TYPES.DECLINE,
              label: utils.i18n('modal_cta_choose_another_date'),
              analyticsKey: ANALYTICS.PAYMENT_METHOD_EXPIRING_SOON,
              analyticsValue: ANALYTICS.CHOOSE_ANOTHER_DATE,
            },
            {
              type: MODAL_BUTTON_TYPES.LINK,
              label: utils.i18n('modal_cta_call_for_assistance'),
              linkTo,
              analyticsKey: ANALYTICS.PAYMENT_METHOD_EXPIRING_SOON,
              analyticsValue: ANALYTICS.CALL_FOR_ASSISTANCE,
            },
          ]}
        />
      </>
    </Modal>
  );
};

export default CreditCardDateExpirationModal;
