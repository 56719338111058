import React from 'react';
import Modal from 'components/Modal';
import { MODAL, MODAL_THEMES } from 'constants';
import utils from 'utils';
import FindingReservationTipsModalContent from './FindingReservationTipsModalContent';

/**
 * FindingReservationTipsModal - Wrapper for FindingReservationTipsModalContent
 * @returns {JSX}
 */
const FindingReservationTipsModal = () => (
  <Modal
    modalKey={MODAL.FINDING_RESERVATION_TIPS_MODAL}
    header={utils.i18n('reservation_lookup_tips_modal_header')}
    theme={MODAL_THEMES.WHITE}>
    <FindingReservationTipsModalContent />
  </Modal>
);

export default FindingReservationTipsModal;
