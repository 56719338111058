import React, { Component } from 'react';
import utils from 'utils';
import { ANALYTICS, RESERVATIONS, GLOBAL, TOOLTIP_THEMES, COMPONENTS } from 'constants';
import IconAlert from 'components/IconAlert';
import FormattedPrice from 'components/FormattedPrice';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Button from 'components/Button';

const { EXTRAS_INCLUDED_STATUS, EXTRAS_MANDATORY_STATUS, EXTRAS_WAIVED_STATUS } = RESERVATIONS;
const { LIMITED_INVENTORY, RECOMMENDED } = COMPONENTS.INDIVIDUAL_EXTRA_ALERT;

/**
 * Extra:
 * This is the header on the extras page
 *
 * @param {object}  props - React Props
 * @param {func}    props.extrasUpdate - we use this to update extras
 * @param {func}    props.showExclusionsModal - we use this to show the exclusions modal
 * @param {string}  props.exclusionsDescription - the exclusions description
 * @param {boolean} props.isBundleIncluded - is the buddle included
 * @param {object}  props.breakpoint - breakpoint flags
 * @param {string}  props.exclusionsText - exclusions text
 * @param {object}  props.extra - the extra object
 * @param {bool}    props.hasWarnedAboutLimitedInventoryItem - if the user has already been warned they are adding a limited inventory item
 * @param {bool}    props.isCheckIn - if this is showing in OCI, use different DTM Track attributes
 * @param {bool}    props.isCustomPathCheckIn - if this is showing in Custom Path OCI, use different DTM Track attributes
 * @param {func}    props.setHasWarnedAboutLimitedInventoryItemToTrue - function to set the hasWarnedAboutLimitedInventoryItem value
 * @param {func}    props.openLimitedInventoryModal - opens the limited inventory warning modal
 * @param {object}  props.hashTableOfRecommendedExtras - hash table with the extras code as keys
 * @return {JSX}
 */

class Extra extends Component {
  constructor(props) {
    super(props);
    const businessLogic = this.getBusinessLogic(props.extra);

    this.state = {
      expanded: false,
      isUpdating: false,
      shouldShowLimitedInventoryModal: false,
      ...businessLogic,
    };
  }

  componentDidUpdate(prevProps) {
    const { extra, hasWarnedAboutLimitedInventoryItem, closeModal, isBundleIncluded } = this.props;
    const { shouldShowLimitedInventoryModal } = this.state;
    const shouldReloadBusinessLogic =
      prevProps.extra.selected_quantity !== extra.selected_quantity || prevProps.isBundleIncluded !== isBundleIncluded;

    if (shouldReloadBusinessLogic) {
      // extra has updated from a bundle selection or removal
      const businessLogic = this.getBusinessLogic(extra);
      this.setState({
        ...businessLogic,
      });
    }
    //  Sometimes the extra.selected_quantity comes down as undefined, we do a check before setting into state.
    //  It could also come down as 0, in this case we still want to set it, but this wouldn't pass a truthy check
    if (
      extra.selected_quantity !== prevProps.extra.selected_quantity &&
      (extra.selected_quantity || extra.selected_quantity === 0)
    ) {
      this.setState({ selected_quantity: extra.selected_quantity });
    }

    if (
      !prevProps.hasWarnedAboutLimitedInventoryItem &&
      hasWarnedAboutLimitedInventoryItem &&
      shouldShowLimitedInventoryModal
    ) {
      this.handleIncrementExtra();
      closeModal();
    }
  }

  handleAcceptForLimitedInventoryModal = () => {
    //  What we pass to the openLimitedInventoryModal modal to update the item + close modal
    const { setHasWarnedAboutLimitedInventoryItemToTrue } = this.props;
    //  hasWarnedAboutLimitedInventoryItem in the parent component is the flag to tell us if we have already have shown the
    //  the limited inventory modal, this only happens when the user clicks add.  Since setState is async we need to make sure that is complete before opening the modal
    //  otherwise we have the edge case of when a _quick_ user opening the modal, closes it and opens it again when really we should
    // just add the extra w/o showing the modal.
    //  So we set shouldShowLimitedInventoryModal to true in internal, then when hasWarnedAboutLimitedInventoryItem changes from false to true AND
    // shouldShowLimitedInventoryModal is true we know this individual extra should pop the modal.  And we know it won't pop again.
    this.setState(
      {
        shouldShowLimitedInventoryModal: true,
      },
      () => {
        setHasWarnedAboutLimitedInventoryItemToTrue();
      }
    );
  };

  getBusinessLogic = () => {
    const { isBundleIncluded } = this.props;
    const { max_quantity, selected_quantity = 0, status, rate_amount_view } = this.props.extra;
    const hasCost = rate_amount_view && parseFloat(rate_amount_view?.amount) > 0;
    const isIncluded = status === EXTRAS_INCLUDED_STATUS;
    const isMandatory = status === EXTRAS_MANDATORY_STATUS;
    const isBuiltIn = isIncluded || isMandatory; // covers any status that defaults to a selected_quantity > 0.
    const minQuantity = isBuiltIn || isBundleIncluded ? 1 : 0; // assumes that included (bundles or otherwise) must default to 1

    return {
      hasCost,
      isIncluded,
      isMandatory,
      isBuiltIn,
      maxQuantity: max_quantity,
      minQuantity,
      selected_quantity,
    };
  };

  getDtmTrack = (type, code) => {
    const { isCheckIn, isCustomPathCheckIn } = this.props;
    if (isCheckIn) {
      return `check_in|extras_${type}|${code}`;
    }
    if (isCustomPathCheckIn) {
      return `custom_path_check_in|extras_${type}|${code}`;
    }
    return `extras|extras_${type}|${code}`;
  };

  getRenderLogic = () => {
    const { breakpoint, extra, isBundleIncluded } = this.props;
    const { code, status, total_amount_view, rate_amount_view, rate_type } = extra;
    const { hasCost, isBuiltIn, maxQuantity, minQuantity, selected_quantity, isUpdating } = this.state;
    const { isMobile } = breakpoint;

    const isFeeWaived = status === EXTRAS_WAIVED_STATUS;
    const disableSubtract = selected_quantity <= minQuantity;
    const disableAdd = selected_quantity >= maxQuantity;
    const isSelected = selected_quantity > 0;
    const showRemoveButton = !isBuiltIn && selected_quantity === maxQuantity && maxQuantity === 1;
    const showButtons = !showRemoveButton && isSelected && maxQuantity !== minQuantity;

    const showStatusTop = !isMobile || showButtons;

    const addedText =
      (isBundleIncluded &&
        utils.i18n(maxQuantity === 1 ? 'extras_in_bundle' : 'extras_in_bundle_amount', [minQuantity])) ||
      utils.i18n(`extras_status_${status}`);
    let priceDisplay = null;
    let incrementalPriceDisplay = null;
    let renderedStatus = null;
    if (!isSelected) {
      renderedStatus = (
        <Button
          button={false}
          className='individual-extra__add individual-extra__single-add'
          data-dtm-track={this.getDtmTrack('add', code)}
          disabled={isUpdating}
          loading={isUpdating}
          onClick={this.handleIncrementExtra}
          title=''>
          {utils.i18n('extras_add')}
        </Button>
      );
    } else if (isBuiltIn) {
      renderedStatus = <span className='individual-extra__added-text'>{addedText}</span>;
    } else if (isSelected) {
      renderedStatus = showRemoveButton && (
        <Button
          className='individual-extra__sub individual-extra__single-remove'
          data-dtm-track={this.getDtmTrack('remove', code)}
          disabled={isUpdating}
          loading={isUpdating}
          link
          onClick={this.handleRemoveExtra}
          title=''>
          {utils.i18n('extras_remove')}
        </Button>
      );
    }

    if (hasCost) {
      priceDisplay = utils.getFormattedPrice(total_amount_view);

      if (utils.config.getIsIncrementalPricingEnabled()) {
        incrementalPriceDisplay = `${utils.getFormattedPrice(rate_amount_view)}/${utils.i18n(
          `extras_rate_unit_${rate_type}`
        )}`;
        priceDisplay = isMobile
          ? `(${utils.getFormattedPrice(total_amount_view)} ${utils.i18n('vehicle_select_price_total').toLowerCase()})`
          : priceDisplay;
      }
    } else if (isFeeWaived) {
      priceDisplay = utils.i18n('extras_fee_waived');
    }

    return {
      showStatusTop,
      disableAdd,
      disableSubtract,
      isSelected,
      priceDisplay,
      incrementalPriceDisplay,
      renderedStatus,
      showButtons,
    };
  };

  handleExclusionsModal = () => {
    const {
      showExclusionsModal,
      exclusions: { exclusionsDescription, exclusionsText },
    } = this.props;
    showExclusionsModal(exclusionsDescription, exclusionsText);
  };

  debouncedUpdate = utils.gmi.debounce(quantity => {
    const { extra, extrasUpdate, toggleExtrasLoading } = this.props;
    toggleExtrasLoading?.(true);
    this.setState({
      isUpdating: true,
    });
    extrasUpdate(extra, quantity).then(() => {
      toggleExtrasLoading?.(false);
      this.setState({
        isUpdating: false,
      });
    });
  }, 600);

  handleIncrementExtra = () => {
    const { hasWarnedAboutLimitedInventoryItem, openLimitedInventoryModal, extra } = this.props;
    const isLimitedInventoryItem = GLOBAL.LIMITED_INVENTORY_EXTRA_CODE === extra?.allocation;
    if (!hasWarnedAboutLimitedInventoryItem && isLimitedInventoryItem) {
      openLimitedInventoryModal(this.handleAcceptForLimitedInventoryModal);
    } else {
      this.setState(
        ({ selected_quantity }) => ({ selected_quantity: selected_quantity + 1 }),
        () => this.debouncedUpdate(this.state.selected_quantity)
      );
    }
  };

  handleDecrementExtra = () => {
    this.setState(
      ({ selected_quantity }) => ({ selected_quantity: selected_quantity - 1 }),
      () => this.debouncedUpdate(this.state.selected_quantity)
    );
  };

  handleRemoveExtra = () => {
    const { extra, extrasUpdate, toggleExtrasLoading } = this.props;
    this.setState({ selected_quantity: 0 });
    toggleExtrasLoading?.(true);
    this.setState({
      isUpdating: true,
    });
    extrasUpdate(extra, 0).then(() => {
      toggleExtrasLoading?.(false);
      this.setState({
        isUpdating: false,
      });
    });
  };

  handleToggleExpandDetails = () => {
    const { expanded } = this.state;
    const {
      extra: { name },
    } = this.props;

    this.setState({
      expanded: !expanded,
    });

    // Fire off analytics "interaction" event for drawer
    utils.analytics.interaction(ANALYTICS.DRAWER, name, expanded ? ANALYTICS.CLOSE : ANALYTICS.OPEN);
  };

  render() {
    const { extra, exclusions, selectedMoreThanBundled, hashTableOfRecommendedExtras } = this.props;
    const { expanded, selected_quantity, isIncluded, isUpdating, isBuiltIn } = this.state;
    const { code, detailed_description, name } = extra;
    const {
      disableAdd,
      disableSubtract,
      isSelected,
      priceDisplay,
      incrementalPriceDisplay,
      renderedStatus,
      showButtons,
      showStatusTop,
    } = this.getRenderLogic();

    const isLimitedInventoryItem = GLOBAL.LIMITED_INVENTORY_EXTRA_CODE === extra.allocation;
    const shouldDisplayPrice = (!isIncluded || selectedMoreThanBundled) && !!priceDisplay;

    const sectionClasses = cn('individual-extra', {
      expanded,
      selected: isSelected,
      'component-theme--light-blue': isSelected,
      'component-theme--light': !isSelected,
    });
    return (
      <section className={sectionClasses}>
        {isLimitedInventoryItem && (
          <IconAlert
            typeOfAlert={LIMITED_INVENTORY}
            placementOfToolTip='top-start'
            additionalClass={cn({
              'icon-alert__not-selected-extra': !isSelected,
            })}
            toolTipTheme={cn({ [TOOLTIP_THEMES.LIGHT_BLUE]: !isSelected, [TOOLTIP_THEMES.REGULAR_SLATE]: !isSelected })}
          />
        )}
        {/* If the code is a recommended extra, put alert */}
        {hashTableOfRecommendedExtras[code] && (
          <IconAlert
            typeOfAlert={RECOMMENDED}
            placementOfToolTip='top-start'
            additionalClass={cn({
              'icon-alert__not-selected-extra': !isSelected,
            })}
            toolTipTheme={cn({ [TOOLTIP_THEMES.LIGHT_BLUE]: isSelected, [TOOLTIP_THEMES.REGULAR_SLATE]: !isSelected })}
          />
        )}
        <div className='individual-extra__upper'>
          <div className='individual-extra__upper-left'>
            <p className='individual-extra__name'>{name}</p>
          </div>
          <div
            className={cn('individual-extra__price-status', {
              'individual-extra__price-status--included': isIncluded && !selectedMoreThanBundled,
            })}>
            {shouldDisplayPrice && (
              <>
                {incrementalPriceDisplay && (
                  <FormattedPrice classNames='individual-extra__incremental-price'>
                    {incrementalPriceDisplay}
                  </FormattedPrice>
                )}
                <FormattedPrice classNames='individual-extra__price'>{priceDisplay}</FormattedPrice>
              </>
            )}
            {showStatusTop && (
              <div className={cn('individual-extra__status', { 'individual-extra__status--included': isBuiltIn })}>
                {renderedStatus}
              </div>
            )}
          </div>
        </div>

        <div className='individual-extra__lower'>
          <Button
            onClick={this.handleToggleExpandDetails}
            link
            className='individual-extra__details-toggle'
            ariaExpanded={expanded.toString()}
            data-track-dtm={this.getDtmTrack('detail', code)}>
            {utils.i18n('extras_expand_button')}
          </Button>

          {showButtons && (
            <div className='individual-extra__buttons'>
              <div
                className={cn('individual-extra__actions', {
                  'individual-extra__actions--loading animated-loader__before': isUpdating,
                })}>
                <Button
                  className='individual-extra__sub'
                  data-dtm-track={this.getDtmTrack('minus', code)}
                  disabled={disableSubtract || isUpdating}
                  button={false}
                  onClick={this.handleDecrementExtra}
                  ariaText={utils.i18n('extras_decrease_accessibility', [name])}
                />
                <span className='individual-extra__quantity'>{selected_quantity}</span>
                <Button
                  className='individual-extra__add'
                  data-dtm-track={this.getDtmTrack('plus', code)}
                  disabled={disableAdd || isUpdating}
                  button={false}
                  onClick={this.handleIncrementExtra}
                  ariaText={utils.i18n('extras_increase_accessibility', [name])}
                />
              </div>
              <span className='individual-extra__how-many'>{utils.i18n('extras_how_many')}</span>
            </div>
          )}
          {!showStatusTop && (
            <div className={cn('individual-extra__status', { 'individual-extra__status--included': isBuiltIn })}>
              {renderedStatus}
            </div>
          )}
        </div>

        {expanded && (
          <div className='individual-extra__details'>
            <div
              className='individual-extra__details-text'
              dangerouslySetInnerHTML={utils.sanitize(detailed_description)}
            />

            {exclusions?.exclusionsDescription && (
              <Button
                link
                className='individual-extra__details__exclusion-button'
                onClick={this.handleExclusionsModal}
                button={false}>
                {utils.i18n('view_exclusions')}
              </Button>
            )}
          </div>
        )}
      </section>
    );
  }
}

Extra.propTypes = {
  // Container props
  breakpoint: PropTypes.object,
  exclusions: PropTypes.object,
  isBundleIncluded: PropTypes.bool,
  selectedMoreThanBundled: PropTypes.bool,
  hashTableOfRecommendedExtras: PropTypes.object,
  extrasUpdate: PropTypes.func,
  showExclusionsModal: PropTypes.func,
  openLimitedInventoryModal: PropTypes.func,
  closeModal: PropTypes.func,

  // Parent props
  extra: PropTypes.shape({
    max_quantity: PropTypes.number,
    selected_quantity: PropTypes.number,
    status: PropTypes.string,
    rate_amount_payment: PropTypes.object,
    rate_amount_view: PropTypes.object,
  }),
  hasWarnedAboutLimitedInventoryItem: PropTypes.bool,
  setHasWarnedAboutLimitedInventoryItemToTrue: PropTypes.func,
  toggleExtrasLoading: PropTypes.func,
  isCheckIn: PropTypes.bool,
  isCustomPathCheckIn: PropTypes.bool,
};

export default Extra;
