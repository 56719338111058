import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './styles/generic-notification-tag.scss';

const GenericNotificationTag = ({ message, bannerClass, messageClass }) => (
  <div className={cn('generic-notification-tag', bannerClass)}>
    <p className={cn('generic-notification-tag__message', messageClass)}>{message}</p>
  </div>
);

GenericNotificationTag.propTypes = {
  message: PropTypes.string.isRequired,
  bannerClass: PropTypes.string,
  messageClass: PropTypes.string,
};

export default GenericNotificationTag;
