import PropTypes from 'prop-types';

export const propTypes = {
  // Container props
  vehicleRate: PropTypes.object,
  prepayReservationsError: PropTypes.array,
  showAHViewDetailsModal: PropTypes.func,
  showLearnMoreModal: PropTypes.func,
  showViewDetailsModal: PropTypes.func,
  showEditModal: PropTypes.func,
  reservation: PropTypes.shape({
    pickup_time: PropTypes.string,
    return_time: PropTypes.string,
    one_way_rental: PropTypes.bool,
    pickup_location: PropTypes.object,
    return_location: PropTypes.object,
    car_class_details: PropTypes.object,
    after_hours_pickup_selected: PropTypes.bool,
    after_hours_return_selected: PropTypes.bool,
    prepay_selected: PropTypes.bool,
  }),
  selectedBundleWithExtras: PropTypes.object,
  extrasWithoutBundles: PropTypes.array,
  numberOfExtrasSelected: PropTypes.number,
  coupons: PropTypes.array.isRequired,

  // Parent props
  extras: PropTypes.array,
  policies: PropTypes.array,
  isVehicleLimitedInventory: PropTypes.bool,
  allowLimitedInventoryIconExtra: PropTypes.bool,
  resHasLimitedInventoryItemOrVehicle: PropTypes.bool,
  isGuaranteedRes: PropTypes.bool,
};
