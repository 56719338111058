import React from 'react';

const ToolTipTemplate = (toolTipHeader, toolTipBody, tooltipID) => ({
  arrowRef,
  tooltipRef,
  getArrowProps,
  getTooltipProps,
  placement,
}) => (
  <div
    {...getTooltipProps({
      className: 'tooltips__container tooltips__desktop-color',
      ref: tooltipRef,
    })}
    role='tooltip'
    aria-hidden='false'
    data-is-tooltip='true'
    id={tooltipID}>
    <div
      {...getArrowProps({
        className: 'tooltip-arrow',
        'data-placement': placement,
        ref: arrowRef,
      })}
    />
    {toolTipHeader}
    {toolTipBody}
  </div>
);

export default ToolTipTemplate;
