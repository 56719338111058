import React from 'react';
import PropTypes from 'prop-types';
import Picture from 'components/Picture';
import Button from 'components/Button';
import utils from 'utils';
import { ASSETS } from 'constants';

/**
 * CreditCardDetails shows last 4 and credit card type
 * to commit state on submission.
 *
 * @param {object} props - React Props
 * @param {object} props.cardDetails: { card_type {string}, number {string}: last four digits }
 * @param {function} props.remove - removes inflight card details from redux UI state
 * @param {string} props.removeDTMTrack - DTM track attribute for remove CTA
 * @return {JSX}
 */
const CreditCardDetails = ({ cardDetails = {}, remove, removeDTMTrack }) => {
  const { card_type, number } = cardDetails;
  // Card types are returned with an underscore or space separating the name words and the images use a hyphen
  const friendlyCardLabel = utils.string.capitalizeAll(card_type?.replace(/[ _]/g, ' '));
  const card = card_type?.replace(/[ _]/g, '');
  const imageSrc = ASSETS[`CREDIT_CARD_${card}`] || ASSETS.CREDIT_CARD_BLANK;

  return (
    <p className='credit-card-details'>
      <span className='credit-card-details-info'>
        <Picture
          className='credit-card-details__cc-logo'
          src={imageSrc}
          alt={friendlyCardLabel}
          fallbackImage={ASSETS.CREDIT_CARD_BLANK}
        />
        {utils.i18n('payment_details_credit_card_info', [friendlyCardLabel, number])}
      </span>

      {remove && (
        <Button link onClick={remove} data-dtm-track={removeDTMTrack}>
          {utils.i18n('credit_card_details_remove')}
        </Button>
      )}
    </p>
  );
};

CreditCardDetails.propTypes = {
  cardDetails: PropTypes.shape({
    card_type: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }),
  remove: PropTypes.func,
  removeDTMTrack: PropTypes.string,
};

export default CreditCardDetails;
