import React, { Component } from 'react';
import utils from 'utils';
import MODAL from 'constants/modals';
import LoadingWidget from 'components/LoadingWidget';
import propTypes from './taxesModalContentPropTypes';

class TaxesModalContent extends Component {
  static propTypes = propTypes;

  componentDidMount() {
    if (!this.props.taxesCopy) {
      this.props.getTaxesPolicy();
    }
  }

  render() {
    const { taxesCopy } = this.props;

    return taxesCopy ? (
      <div
        id={`${MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL}_desc`}
        className='modal-themed__section modal-themed__gbo-content'
        dangerouslySetInnerHTML={utils.sanitize(taxesCopy)}
      />
    ) : (
      <LoadingWidget />
    );
  }
}

export default TaxesModalContent;
