import React from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import THEMES from 'constants/themes';
import MODAL from 'constants/modals';
import useActions from 'hooks/useActions';
import { estimatedTotalViewSelector } from 'selectors/reservationFlowSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import Button from 'components/Button';
import STCAddCreditCardModal from 'components/modals/STCAddCreditCardModal';
import { openModal } from "actions/modal/openModal";

/**
 * STCAddPaymentCardFooter
 *
 * @return {JSX}
 */
const STCAddPaymentCardFooter = () => {
  const estimatedTotal = useSelector(estimatedTotalViewSelector);
  const zeroChargeValue = utils.getFormattedZeroPrice(estimatedTotal);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const openAddPaymentCardModal = useActions(() => openModal(MODAL.STC_ADD_CREDIT_CARD_MODAL));

  return (
    <>
      <div className='skip-the-counter-flow-payment__footer'>
        <p className='skip-the-counter-flow-payment__legal-copy skip-the-counter-flow-payment__footer-copy'>
          {isCustomPathReservation
            ? utils.i18n('stc_payment_information_copy_custom_path')
            : utils.i18n('stc_payment_information_copy')}
        </p>
        <div className='skip-the-counter-flow-payment__footer-action'>
          <Button onClick={openAddPaymentCardModal} theme={THEMES.DARK} data-dtm-track='check_in|counter|add_card'>
            {utils.i18n(isCustomPathReservation ? 'post_res_add_method_of_payment' : 'stc_payment_information_add_cta')}
          </Button>
          {zeroChargeValue && (
            <p className='skip-the-counter-flow-payment__footer-note'>
              {`(${utils.i18n('stc_payment_information_total_charged_now')}: ${zeroChargeValue})`}
            </p>
          )}
        </div>
      </div>
      <STCAddCreditCardModal />
    </>
  );
};

export default STCAddPaymentCardFooter;
