import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { dtm } from 'utils/analytics';
import useActions from 'hooks/useActions';
import { resetPasswordProfile } from 'actions/profile';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import ANALYTICS from 'constants/analytics';
import GMI_SERVICE_PATHS from 'constants/gmiServicePaths';
import TextField from 'components/Form/TextField';
import PasswordField from 'components/Form/PasswordField';
import PasswordRequirements from 'components/Form/PasswordRequirements';
import Button from 'components/Button';
import FormWrapper from '../FormWrapper/FormWrapper';

const ForgotPasswordForm = () => {
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const isFetchingReset = useSelector(state =>
    isFetchingEndpointSelector(state, { endpoint: 'profile/resetPassword' })
  );

  const resetPasswordAction = useActions(resetPasswordProfile);

  const handleFormSubmit = ({ username, new_password }) => resetPasswordAction({ username, new_password });

  /**
   * This function checks if password validation state from the password requirements
   * is met (password is valid) in order to show an error on the field or not.
   * This is different from showing a 'required' error on the password field.
   */
  const customPasswordValidation = values => {
    const errors = {};

    if (!isPasswordValid) {
      errors.password = true;
    }

    if (values.password !== values.new_password) {
      errors.new_password = i18n('passwords_do_not_match');
    }

    return errors;
  };

  return (
    <FormWrapper
      handleFormSubmit={handleFormSubmit}
      validate={customPasswordValidation}
      formTitle={i18n('enrollment_form_account_information')}
      statePath={GMI_SERVICE_PATHS.RESET_PASSWORD}>
      <div className='form-wrapper__field-row'>
        <TextField
          name='username'
          label={i18n('signin_email_or_username')}
          fill
          isFullWidth
          required
          disabled={isFetchingReset}
          autoComplete='email'
        />
      </div>
      <div className='form-wrapper__field-row'>
        <PasswordField
          name='password'
          label={i18n('edit_account_modal_new_password')}
          fill
          isFullWidth
          required
          disabled={isFetchingReset}
          autoComplete='current-password'
        />
      </div>
      <div className='form-wrapper__field-row'>
        <PasswordRequirements password='password' email='username' setPasswordValid={setIsPasswordValid} />
      </div>
      <div className='form-wrapper__field-row'>
        <PasswordField
          name='new_password'
          label={i18n('confirm_password')}
          fill
          isFullWidth
          required
          disabled={isFetchingReset}
          autoComplete='current-password'
        />
      </div>
      <Button
        type='submit'
        className='form-wrapper__submit-button'
        disabled={isFetchingReset}
        data-dtm-track={dtm('reset_password', ANALYTICS.UI_BUTTON, ANALYTICS.SUBMIT)}>
        {i18n('reset_password')}
      </Button>
    </FormWrapper>
  );
};

export default ForgotPasswordForm;
