import React, { useState } from 'react';
import { i18n } from 'utils/i18n';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { analyticsInteraction } from 'utils/analytics';
import { getFormattedPrice } from 'utils/prices';
import { ANALYTICS } from 'constants';
import FormattedPrice from 'components/FormattedPrice';
import BundleAddedCost from 'components/RentalDetails/RentalDetailsSharedComponents/BundleAddedCost';
import Button from 'components/Button';
import AdditionalDrivers from '../AdditionalDrivers';
import RentalRate from '../RentalRate';
import TotalPrice from '../TotalPrice';
import TaxesFees from '../TaxesFees';
import Extras from '../Extras';

const RenterInfoExpandCTA = ({
  breakpoint,
  expandButtonKey,
  isTourVoucherReservation,
  voucherExcludedValue,
  estimated_total_view,
  showTotalPriceAdjustments,
  selectedBundleWithExtras,
  estimated_total_vehicle_view,
  chargedRate,
  isThirdPartyReservation,
  extras,
  numberOfExtrasSelected,
  extrasWithoutBundles,
  priceSummary,
  showViewDetailsModal,
  estimated_total_payment,
  showLearnMoreModal,
  savings,
  prepaySelected,
  freeUpgradeApplied,
  isRental,
}) => {
  const [expandedDetails, setExpandedDetails] = useState(false);

  const handleExpand = () => {
    setExpandedDetails(!expandedDetails);

    // Analytics - fire custom "drawer" event (push to interaction object)
    analyticsInteraction(
      ANALYTICS.DRAWER, // type
      ANALYTICS.PRICE_AND_PAYMENT_DETAILS, // key
      expandedDetails ? ANALYTICS.CLOSE : ANALYTICS.OPEN // value
    );
  };

  const getTotalCopy = (type) => {
    const isMobile = type === 'mobile';

    if (isTourVoucherReservation) {
      return isMobile ? 'amount_due_at_pickup_value' : 'amount_due_at_pickup';
    }
    if (!isTourVoucherReservation && prepaySelected) {
      return isMobile
        ? 'confirmation_reservation_details_pre_pay_total'
        : 'confirmation_reservation_details_pre_pay_total_desktop';
    }

    return isMobile
      ? 'confirmation_reservation_details_pay_later_total'
      : 'confirmation_reservation_details_pay_later_total_desktop';
  };

  return (
    <>
      <Button
        className='confirmation__reservation-details__content__expand-button'
        button={false}
        onClick={handleExpand}
        ariaText={i18n('confirmation_reservation_details_expand_button')}
        ariaExpanded={expandedDetails.toString()}
      >
        <span
          className={cn('confirmation__reservation-details__content__expand-button__label', {
            expanded: expandedDetails,
            'confirmation__reservation-details__content__expand-button__label--expanded':
              breakpoint.isMobile && expandedDetails,
          })}
        >
          {/* Depending on the key we don't necessarily use estimated_total_view, this depends on if we are in a mobile breakpoint.
          On mobile we use it because the UI gets simpler and we want to put the price in one key.  On desktop we put it in another key, see code
          below */}
          {i18n(
            expandButtonKey,
            [
              <FormattedPrice key='0'>
                {isTourVoucherReservation
                  ? getFormattedPrice(voucherExcludedValue.view)
                  : getFormattedPrice(estimated_total_view)}
              </FormattedPrice>,
            ],
            { jsx: true }
          )}
        </span>
        {!breakpoint.isMobile && (
          <span className='confirmation__reservation-details__content__expand-button__total'>
            {i18n(getTotalCopy())}
            <FormattedPrice classNames='confirmation__reservation-details__content__expand-button__total-number'>
              {isTourVoucherReservation
                ? getFormattedPrice(voucherExcludedValue.view)
                : getFormattedPrice(estimated_total_view)}
            </FormattedPrice>
          </span>
        )}
      </Button>

      <div
        aria-hidden={(!expandedDetails).toString()}
        className={cn('confirmation__reservation-details__content__expanded-section', {
          hidden: !expandedDetails,
        })}
      >
        <h3 className='rental-details__section-group-label'>
          {i18n('confirmation_reservation_details_price_breakdown')}
        </h3>
        <div
          className={cn(
            'confirmation__reservation-details__price-breakdown',
            showTotalPriceAdjustments && 'confirmation__reservation-details__price-breakdown--with-border'
          )}
        >
          <div
            className={cn('confirmation__reservation-details__content__details-row', {
              'confirmation__reservation-details__content__details-row--with-bundle': selectedBundleWithExtras,
            })}
          >
            <RentalRate
              estimatedTotalVehicleView={estimated_total_vehicle_view}
              chargedRate={chargedRate}
              isThirdPartyReservation={isThirdPartyReservation}
              asterisk={true}
            />
            {selectedBundleWithExtras && <BundleAddedCost selectedBundleWithExtras={selectedBundleWithExtras} />}
          </div>

          {!!extras.length && (
            <div className='confirmation__reservation-details__content__details-row'>
              <Extras
                extras={extras}
                numberOfExtrasSelected={numberOfExtrasSelected}
                asterisk={true}
                selectedBundleWithExtras={selectedBundleWithExtras}
                extrasWithoutBundles={extrasWithoutBundles}
                isThirdPartyReservation={isThirdPartyReservation}
              />
            </div>
          )}

          <AdditionalDrivers isThirdPartyReservation={isThirdPartyReservation} />

          <div className='confirmation__reservation-details__content__details-row'>
            <TaxesFees
              isThirdPartyReservation={isThirdPartyReservation}
              priceSummary={priceSummary}
              asterisk={true}
              handleViewDetailsModal={showViewDetailsModal}
            />
          </div>
        </div>

        <div className='confirmation__reservation-details__content__details-row'>
          <TotalPrice
            isThirdPartyReservation={isThirdPartyReservation}
            estimatedTotalView={estimated_total_view}
            estimatedTotalPayment={estimated_total_payment}
            showLearnMoreModal={showLearnMoreModal}
            asterisk={true}
            adjustments={savings}
            freeUpgradeApplied={freeUpgradeApplied}
            isPayLater={!prepaySelected}
            isRental={isRental}
          />
        </div>
      </div>
    </>
  );
};

RenterInfoExpandCTA.propTypes = {
  numberOfExtrasSelected: PropTypes.number,
  breakpoint: PropTypes.object,
  isThirdPartyReservation: PropTypes.bool,
  isFoundRental: PropTypes.bool,
};

export default RenterInfoExpandCTA;
