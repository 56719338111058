import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import RESERVATIONS from 'constants/reservations';
import SKIP_THE_COUNTER from 'constants/skipTheCounter';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import { checkinAbort } from 'actions/checkin';
import useReservationRedirect from 'hooks/useReservationRedirect';
import { skipTheCounterAbort } from 'actions/skipTheCounter';
import { abortModifyRental } from 'actions/modifyRental';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import { getCurrentPageHash } from 'utils/url';
import Modal from 'components/Modal';
import { ModalFooterWithRouter as ModalFooter } from 'components/Modal/ModalFooter';
import { modifyAbort } from "actions/reservation/modifyAbort";

const { RENTAL_DETAILS } = WINDOW_OBJECT_KEYS;
const currentPageHash = getCurrentPageHash();

const resflowPaths = Object.values(RESERVATIONS.RESFLOW_PATHS_CONFIG).reduce((acc, paths) => acc.concat(paths), []);
const stcFlowPaths = Object.values(SKIP_THE_COUNTER.STC_PATH_CONFIG).reduce((acc, paths) => acc.concat(paths), []);

const DiscardReservationModalContent = () => {
  const skipTheCounterAbortAction = useActions(skipTheCounterAbort);
  const checkinAbortAction = useActions(checkinAbort);
  const modifyAbortAction = useActions(modifyAbort);
  const redirectPage = useReservationRedirect();
  const abortModifyRentalAction = useActions(abortModifyRental);

  const confirmHandler = (modifyAbortFn, skipTheCounter) =>
    skipTheCounter ? skipTheCounterAbortAction() : modifyAbortFn();

  const hasMatchingRoutes = (routePage) => history && routePage.indexOf(currentPageHash) > -1;

  const handleAccept = (requestData) => {
    const { modifyAbortFn, headerRedirect, backToRentalDetails, skipTheCounter, isTrueModify, isModifyRental } =
      requestData;
    if (backToRentalDetails || skipTheCounter) {
      confirmHandler(modifyAbortFn, skipTheCounter);
    } else if (isModifyRental) {
      abortModifyRentalAction();
    } else if (headerRedirect && isTrueModify) {
      if (hasMatchingRoutes(resflowPaths)) {
        modifyAbortAction();
      } else if (hasMatchingRoutes(stcFlowPaths)) {
        skipTheCounterAbortAction();
      } else {
        checkinAbortAction();
      }
    }
  };

  return (
    <Modal
      modalKey={MODAL.CONFIRM_DISCARD_RESERVATION_MODAL}
      header={utils.i18n('discard_reservation_modal_header')}
      theme={MODAL_THEMES.WHITE}
    >
      {({
        backToRentalDetails = false,
        ccHold = false,
        isTrueModify,
        modifyAbortFn,
        skipTheCounter = false,
        headerRedirect = false,
        isModifyRental,
      }) => {
        const declineBtnLabel = utils.i18n(
          isTrueModify ? 'true_modify_discard_modal_decline' : 'discard_reservation_modal_decline'
        );
        const confirmBtnLabel = utils.i18n(
          isTrueModify ? 'true_modify_discard_modal_accept' : 'discard_reservation_modal_accept'
        );

        const linkTo = backToRentalDetails
          ? utils.config.getRedirectUrl(RENTAL_DETAILS)
          : utils.config.getRedirectUrl(redirectPage);

        const requestData = {
          modifyAbortFn,
          headerRedirect,
          backToRentalDetails,
          skipTheCounter,
          linkTo,
          isTrueModify,
          isModifyRental,
        };

        const isAccept =
          backToRentalDetails || skipTheCounter || isTrueModify || isModifyRental || (headerRedirect && isTrueModify);

        return (
          <>
            <p className='modal-themed__copy'>
              {(() => {
                if (ccHold) {
                  return utils.i18n('confirm_discard_reservation_cc_hold');
                }
                if (isModifyRental) {
                  return utils.i18n('discard_all_modifications_copy');
                }
                if (isTrueModify) {
                  return utils.i18n('true_modify_discard_modal_copy');
                }
                return utils.i18n('discard_reservation_modal_copy');
              })()}
            </p>

            <ModalFooter
              buttons={[
                {
                  type: MODAL_BUTTON_TYPES.DECLINE,
                  label: declineBtnLabel,
                  analyticsValue: declineBtnLabel,
                },
                {
                  type: MODAL_BUTTON_TYPES.LINK,
                  label: confirmBtnLabel,
                  analyticsValue: confirmBtnLabel,
                  handler: isAccept ? () => handleAccept(requestData) : null,
                  linkTo,
                  analyticsHandler:
                    backToRentalDetails || skipTheCounter || isTrueModify || isModifyRental
                      ? () =>
                          utils.analytics.interaction(
                            ANALYTICS.MODAL,
                            utils.analytics.prepareKey(MODAL.CONFIRM_DISCARD_RESERVATION_MODAL),
                            isModifyRental ? confirmBtnLabel : ANALYTICS.EXIT_AND_DISCARD
                          )
                      : null,
                },
              ]}
            />
          </>
        );
      }}
    </Modal>
  );
};

export default DiscardReservationModalContent;
