import React from 'react';
import utils from 'utils';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import Modal from 'components/Modal';

/**
 * STCProtectionOptionDetailsModal
 * Modal to show a Protectio Option details in STC Flow. The content is carried over from the action as props, so
 * this modal requires `openModalWithProps` action with `header` and `detailed_description` props.
 * @return {JSX}
 */
const STCProtectionOptionDetailsModal = () => (
  <Modal modalKey={MODAL.STC_PROTECTION_OPTION_DETAILS_MODAL} theme={MODAL_THEMES.WHITE}>
    {({ detailed_description }) => (
      <div className='modal-themed__paragraph' dangerouslySetInnerHTML={utils.sanitize(detailed_description)} />
    )}
  </Modal>
);

export default STCProtectionOptionDetailsModal;
