import React from 'react';
import utils from 'utils';
import { ASSETS } from 'constants';
import Button from 'components/Button';
import FormattedPrice from 'components/FormattedPrice';
import CreditCardDetails from 'components/CreditCardDetails';
import { propTypes } from './aboutPrepayPropTypes';

const AboutPrepay = ({
  paymentCardData,
  handlePrepayTermsModal,
  isCancelled,
  appliedCancelFeeDetails,
  estimatedTotalView,
}) => (
  <div className='about-prepay about-payment__container component-theme--dark-blue'>
    <div className='about-payment__title-container'>
      {/* Add image from dam + style */}
      <img
        className='about-payment__image'
        src={ASSETS.ABOUT_PAYMENT_CARD_ICON}
        alt={utils.i18n('about_pay_later_image_alt')}
      />
      <h2 className='about-payment__main-title'>
        {utils.i18n(isCancelled ? 'prepay_cancellation_title' : 'about_prepay_title')}
      </h2>
    </div>
    {isCancelled ? (
      <>
        <p className='about-payment__sub-title-text'>
          {appliedCancelFeeDetails?.refund_amount_view
            ? utils.i18n(
                'prepay_cancellation_status',
                {
                  0: (
                    <b key={0} className='formatted-price'>
                      {utils.getFormattedPrice(appliedCancelFeeDetails.refund_amount_view)}
                    </b>
                  ),
                },
                {
                  jsx: true,
                }
              )
            : utils.i18n('prepay_cancellation_status_undefined_refund')}
        </p>
        <p className='about-payment__sub-title-text'>
          {utils.i18n(
            'prepay_cancellation_more_info',
            {
              0: (
                <Button key={0} link className='about-payment__inline-button' onClick={handlePrepayTermsModal}>
                  {utils.i18n('prepay_cancellation_view_terms_conditions')}
                </Button>
              ),
            },
            {
              jsx: true,
            }
          )}
        </p>

        <h3 className='about-payment__sub-title'>
          {utils.i18n(
            appliedCancelFeeDetails?.refund_amount_view
              ? 'prepay_cancellation_refund_breakdown'
              : 'prepay_cancellation_cancellation_fee'
          )}
        </h3>
        <p className='about-payment__disclaimer-text'>{utils.i18n('prepay_cancellation_cancel_fee_warning')}</p>

        {appliedCancelFeeDetails?.refund_amount_view && (
          <div className='about-payment__refund-breakdown'>
            <div className='about-payment__refund-breakdown__item'>
              <p className='label'>{utils.i18n('confirmation_reservation_cancel_original_amount')}</p>
              <p className='value'>
                <FormattedPrice>{utils.getFormattedPrice(estimatedTotalView)}</FormattedPrice>
              </p>
            </div>
            <div className='about-payment__refund-breakdown__item refund'>
              <p className='label'>{utils.i18n('confirmation_reservation_cancel_amount_refunded')}</p>
              <p className='value'>
                <FormattedPrice>{utils.getFormattedPrice(appliedCancelFeeDetails.refund_amount_view)}</FormattedPrice>
              </p>
            </div>
          </div>
        )}
      </>
    ) : (
      <>
        <h3 className='about-payment__sub-title'>{utils.i18n('about_prepay_credit_card_header')}</h3>
        <p className='about-payment__sub-title-text'>{utils.i18n('about_prepay_credt_card_status')}</p>

        {paymentCardData && <CreditCardDetails cardDetails={paymentCardData.card_details} />}
        <h3 className='about-payment__sub-title'>{utils.i18n('about_prepay_modify_cancel_header')}</h3>
        <p className='about-payment__sub-title-text'>{utils.i18n('about_prepay_modifiy_cancel_info')}</p>

        <Button link className='link--arrow' onClick={handlePrepayTermsModal}>
          {utils.i18n('about_prepay_terms_conditions')}
        </Button>
      </>
    )}
  </div>
);

AboutPrepay.propTypes = propTypes;

export default AboutPrepay;
