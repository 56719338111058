import React, { Component } from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import LoadingWidget from 'components/LoadingWidget';
import Modal from 'components/Modal';
import { MODAL, MODAL_THEMES } from 'constants';
import Button from 'components/Button';

/**
 * LoyaltyTermsConditionsLink Description
 * fetches and displays the alamo insider terms and conditions
 *
 * @param {object} props - React Props
 * @param {string} props.loyaltyTerms - the T&C text that will be displayed
 * @param {function} props.getLoyaltyTerms - service call to fetch the text
 * @param {function} props.handleOpenModal - launches the modal
 * @return {JSX}
 */

class LoyaltyTermsConditionsLink extends Component {
  handleClick = e => {
    // prevents label click from firing
    e.preventDefault();
    const { loyaltyTerms, getLoyaltyTerms, handleOpenModal } = this.props;
    !loyaltyTerms && getLoyaltyTerms();
    handleOpenModal();
  };

  render() {
    const { loyaltyTerms } = this.props;
    return (
      <>
        <Button className='enrollment__terms-conditions-button' onClick={this.handleClick} linkText>
          {utils.i18n('alamo_insider_terms_conditions')}
        </Button>
        <Modal
          modalKey={MODAL.LOYALTY_TERMS_AND_CONDITIONS}
          header={utils.i18n('alamo_insider_terms_conditions')}
          theme={MODAL_THEMES.WHITE}>
          {loyaltyTerms ? (
            <>
              <div className='modal-print__container modal-themed__paragraph'>
                <div dangerouslySetInnerHTML={utils.sanitize(loyaltyTerms)} />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
        </Modal>
      </>
    );
  }
}

LoyaltyTermsConditionsLink.propTypes = {
  loyaltyTerms: PropTypes.string,
  getLoyaltyTerms: PropTypes.func,
  handleOpenModal: PropTypes.func,
};

export default LoyaltyTermsConditionsLink;
