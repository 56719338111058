import { connect } from 'react-redux';
import utils from 'utils';
import {
  hasExtrasSelector,
  includedExtrasAndDriversSelector,
  showBundlesSectionSelector,
  recommendedExtrasNotAddedSelector,
  includedOrAddedExtrasSelector,
  selectedBundleNotDefaultSelector,
} from 'selectors';
import { preSelectedExtrasSelector } from 'selectors/extrasSelectors';
import { vehicleRateSelector } from 'selectors/reservationFlowSelectors';
import { MODAL } from 'constants';
import { setAllExtras } from 'actions/reservation/setAllExtras';
import { extrasComplete } from 'actions/reservation/extrasComplete';
import { openModal } from 'actions/modal/openModal';
import Extras from './Extras';

const mapStateToProps = (state) => {
  const extras = state.getIn(['gmi', 'ui', 'reservations', 'extras'])?.toJS() || [];
  const numberOfExtrasSelected = extras.length;
  const { includedExtras } = includedOrAddedExtrasSelector(state);
  const numberOfIncludedExtras = includedExtras.length;

  return {
    hasExtras: hasExtrasSelector(state),
    selectedBundle: selectedBundleNotDefaultSelector(state),
    includedExtrasAndDrivers: includedExtrasAndDriversSelector(state),
    showBundles: showBundlesSectionSelector(state),
    showRecommendedExtrasAlertModal: recommendedExtrasNotAddedSelector(state).length > 0,
    preSelectedExtras: preSelectedExtrasSelector(state),
    vehicleRate: vehicleRateSelector(state),
    numberOfExtrasSelected,
    numberOfIncludedExtras,
  };
};

const mapDispatchToProps = {
  extrasComplete,
  setAllExtras,
  openRecommendedExtrasAlertModal: () => openModal(MODAL.RECOMMENDED_EXTRAS_ALERT_MODAL),
  showConvertedTotalsModal: () => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(Extras));
