import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ModalFooter } from 'components/Modal/ModalFooter';
import ANALYTICS from 'constants/analytics';
import { MODAL_BUTTON_TYPES } from 'constants';
import THEMES from 'constants/themes';
import Checkbox from 'components/Form/Checkbox';
import { reservationHasAdditionalDriverFreeSpouseSelector } from 'selectors/reservationFlowSelectors';
import useActions from 'hooks/useActions';
import { setIsSpouseAdditionalDriver } from 'actions/reservation/setIsSpouseAdditionalDriver';

const { ACCEPT } = MODAL_BUTTON_TYPES;

const FreeSpouseBanner = (props) => {
  const { handleCloseModal, isSpouseLocalState, setIsSpouseLocalState, modalKey } = props;
  const hasAdditionalDriverFreeSpouse = useSelector(reservationHasAdditionalDriverFreeSpouseSelector);
  const setIsSpouse = useActions(setIsSpouseAdditionalDriver);

  const onDone = () => {
    setIsSpouse(isSpouseLocalState);
    handleCloseModal({ skipAnalytics: true });
  };

  const handleChange = () => {
    setIsSpouseLocalState(!isSpouseLocalState);
    !isSpouseLocalState ? utils.analytics.interaction(ANALYTICS.FILTER, 'spouse or partner', 'checked') : null;
  };

  return (
    (hasAdditionalDriverFreeSpouse && (
      <div className='additional-drivers__add-modal'>
        <div className='additional-drivers__spouse-banner'>
          <h4 className='additional-drivers__spouse-banner__header'>
            {utils.i18n('add_additional_driver_spouse_banner_title')}
          </h4>
          <p>{utils.i18n('add_additional_driver_spouse_banner_description')}</p>
          <Checkbox
            label={utils.i18n('add_additional_driver_modal_spouse')}
            name='isSpouse'
            theme={THEMES.LIGHT}
            checked={isSpouseLocalState}
            onChange={() => handleChange()}
          />
        </div>

        {isSpouseLocalState && (
          <ModalFooter
            buttons={[
              {
                type: ACCEPT,
                label: utils.i18n('filter_cta_done'),
                handler: onDone,
                analyticsHandler: () =>
                  utils.analytics.interaction(ANALYTICS.MODAL, utils.analytics.prepareKey(modalKey), ANALYTICS.DONE),
              },
            ]}
          />
        )}
      </div>
    )) ||
    null
  );
};

FreeSpouseBanner.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  isSpouseLocalState: PropTypes.bool.isRequired,
  setIsSpouseLocalState: PropTypes.func.isRequired,
};

export default FreeSpouseBanner;
