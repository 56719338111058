import React from 'react';
import { i18n } from 'utils/i18n';
import THEMES from 'constants/themes';
import ReservationLookupTemplate from 'components/ReservationLookup/ReservationLookupTemplate';
import SearchReservation from 'components/ReservationLookup/SearchReservation';

const UpcomingRentalUI = ({ breakpoint }) => {
  const showSideSection = breakpoint.isDesktop;

  return (
    <ReservationLookupTemplate
      headerSectionTheme={THEMES.DARK_BLUE}
      mainSectionTheme={THEMES.LIGHT_BLUE}
      titleText={i18n('view_modify_cancel')}
      noMinHeight
      noMarignTop
      showSideSection={showSideSection}
    >
      <SearchReservation />
    </ReservationLookupTemplate>
  );
};

export default UpcomingRentalUI;
