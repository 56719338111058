import React from 'react';
import { utils as globalUtils } from '@ehi/global-marketing-interface';
import Button from 'components/Button';
import { i18n } from 'utils/i18n';
import { config } from 'utils/config';
import { analyticsInteraction } from 'utils/analytics';
import { ANALYTICS, SESSION_STORAGE } from 'constants';
import THEMES from 'constants/themes';
import useActions from 'hooks/useActions';
import { rentalModifyDetails } from 'actions/modifyRental';
import propTypes from './ModifyMyReturnPropTypes';

function ModifyMyReturn(props) {
  const { showChangeReturnLocationDetailsModal, returnTime } = props;
  const rentalModifyDetailsAction = useActions(rentalModifyDetails);

  const dateOfRentInteraction = globalUtils
    .getDateTimeObjFromTs(returnTime)
    .format(config.getDateTimeLongFormat('llll_with_comma'));

  const handleModifyReturn = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.MODIFY_RETURN);
    sessionStorage.removeItem(SESSION_STORAGE.MODIFY_RENTAL_ORIGINAL_DATE);
    rentalModifyDetailsAction().then((res) => {
      if (!res.messages) {
        sessionStorage.setItem(SESSION_STORAGE.MODIFY_RENTAL_ORIGINAL_DATE, dateOfRentInteraction);
      }
    });
  };

  const changeReturnLocation = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.CHANGE_RETURN_LOCATION);
    showChangeReturnLocationDetailsModal();
  };

  return (
    <section className='theme--midnight-blue modify-cancel-reservation confirmed-rental'>
      <div className='modify-cancel-reservation__edit-icon' />

      <p className='modify-cancel-reservation__description'>
        {i18n('confirmation_reservation_modify_cancel_description')}
      </p>
      <>
        <div className='modify-cancel-reservation__buttons-container single-button'>
          <Button disabled={false} onClick={handleModifyReturn} theme={THEMES.MEDIUM} ghosted>
            {i18n('rental_modify_my_return_button')}
          </Button>
        </div>
      </>
      <Button key={0} onClick={changeReturnLocation} className='modify-cancel-reservation__change-return-location' link>
        <span>{i18n('rental_modify_label')}</span>
      </Button>
    </section>
  );
}

ModifyMyReturn.propTypes = propTypes;

export default ModifyMyReturn;
