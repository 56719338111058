import React, { Component } from 'react';
import cn from 'classnames';
import VehicleDetailsCard from 'components/VehicleDetailsCard';
import { propTypes, defaultProps } from './VehicleDetailsCardGridPropTypes';

const SHRINK_POINT = 4;

class VehicleDetailsCardGrid extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  getCards = () => {
    const cards = [];
    React.Children.forEach(this.props.children, child => child.type === VehicleDetailsCard && cards.push(child));
    return cards;
  };

  render() {
    const { children, isOtherVehicle } = this.props;
    if (!children) {
      return null;
    }

    const cards = this.getCards();
    const shouldShrink = isOtherVehicle && cards.length === SHRINK_POINT;
    const className = cn(
      'vehicle-details-card-grid',
      { 'vehicle-details-card-grid--default': !shouldShrink },
      { 'vehicle-details-card-grid--shrink': shouldShrink }
    );

    return <section className={className}>{cards}</section>;
  }
}

export default VehicleDetailsCardGrid;
