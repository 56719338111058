import React from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { MODAL, MODAL_BUTTON_TYPES, MODAL_THEMES } from 'constants';

const { ACCEPT } = MODAL_BUTTON_TYPES;

const FlightConfirmedModal = () => (
  <Modal
    modalKey={MODAL.FLIGHT_CONFIRMED_MODAL}
    header={utils.i18n('flight_confirmed_title')}
    theme={MODAL_THEMES.WHITE}
    customClass='flight-confirmed-modal flight-delayed-changed-modal'
    showHeaderTextIcon
    headerTextIcons={'plane'}
    headerIconBgColor={'modal-green'}>
    {() => (
      <div className='modal-themed__flight-change-delayed-modal'>
        <p className='modal-themed__paragraph'>
          {utils.i18n('flight_confirmed_description')}
          {utils.i18n('flight_delayed_customer_support')}
          {utils.i18n('flight_confirmed_description_two')}
        </p>
        <div className='flight-confirmed-modal-footer'>
          <ModalFooter
            fullWidthButton
            buttons={[
              {
                type: ACCEPT,
                label: utils.i18n('common_close'),
              },
            ]}
          />
        </div>
      </div>
    )}
  </Modal>
);

FlightConfirmedModal.propTypes = {
  handleClose: PropTypes.func,
  openUpdateFlightInfoModal: PropTypes.func,
};

export default FlightConfirmedModal;
