import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { utils as globalUtils } from '@ehi/global-marketing-interface';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { i18n } from 'utils/i18n';
import { loadingOverlay } from 'utils/dom';
import { sanitize } from 'utils/validation';
import { MODAL, MODAL_THEMES, ASSETS, THEMES, SESSION_STORAGE } from 'constants';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import Modal from 'components/Modal';
import Title from 'components/Title';
import ModifyReservationModal from 'components/modals/ModifyReservationModal';
import ChangeReturnLocationModal from 'components/modals/ChangeReturnLocationModal';
import TaxesModalContent from 'components/TaxesModalContent';
import PostResLayout from 'components/PostRes/PostResLayout';
import PoliciesComponent from 'components/PoliciesComponent';
import RentalBranchLocation from 'components/RentalBranchLocation/RentalBranchLocation';

import { getAfterHoursPolicy } from 'actions/reservation/getAfterHoursPolicy';
import { openModal } from 'actions/modal/openModal';
import RenterInformation from '../RenterInformation';

const FORMAT = 'llll';

const ConfirmedRental = (props) => {
  const [afterHoursPolicyState, setAfterHoursPolicyState] = useState(false);
  const { policies, rentalReady, firstName, ticketNumber, locationType, isReturnUpdated, details } = props || {};
  const showAHViewDetailsModal = useActions(() => openModal(MODAL.RENTAL_DETAILS_AFTER_HOURS_MODAL));
  const showViewDetailsModal = useActions(() => openModal(MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL));
  const showChangeReturnLocationDetailsModal = useActions(() =>
    openModal(MODAL.CHANGE_RETURN_LOCATION_MODAL, { skipAnalytics: true })
  );
  const getAfterHoursPolicyAction = useActions(getAfterHoursPolicy);
  const isFetchingSession = useSelector((state) => isFetchingEndpointSelector(state, { endpoint: 'session/current' }));
  const termsAndConditionsRef = useRef(null);

  const setAfterHoursPolicy = () => {
    const { afterHoursReturn, afterHoursPickup, pickupLocation, returnLocation } = props;
    getAfterHoursPolicyAction({
      policies,
      afterHoursReturn,
      afterHoursPickup,
      pickupLocation,
      returnLocation,
    }).then((altAfterHoursPolicyContent) => {
      altAfterHoursPolicyContent && setAfterHoursPolicyState(altAfterHoursPolicyContent);
    });
  };

  useEffect(() => {
    if (policies) {
      setAfterHoursPolicy();
    }
    // when entering confirmed / updated rental flow show the overlay if we're fetching the session
    isFetchingSession ? loadingOverlay() : loadingOverlay(false);
  }, [isFetchingSession]);

  const returnTime = sessionStorage.getItem(SESSION_STORAGE.MODIFY_RENTAL_ORIGINAL_DATE);

  const updatedTime = globalUtils.getDateTimeObjFromTs(props.returnTime).format(FORMAT);

  return (
    <>
      <PostResLayout noMinHeight>
        {!isFetchingSession && (
          <div className='rental-updated'>
            {isReturnUpdated && (
              <div className='rental-updated__confirmed-title'>
                <span
                  dangerouslySetInnerHTML={sanitize(i18n('rental_agreement_updated_copy', [returnTime, updatedTime]))}
                />
              </div>
            )}
            {!details && (
              <Title
                type='h1'
                className='rental-updated__title'
                text={i18n(props.returnConfirmed || !isReturnUpdated ? 'return_confirmed' : 'return_updated')}
              />
            )}
            <p className='rental-updated__title-subheading-title'>
              {i18n('trip_card_agreement_number', [ticketNumber])}
            </p>
            <p className='rental-updated__title-subheading-title-copy'>
              {i18n('rental_agreement_copy', [firstName, updatedTime], { jsx: true })}
            </p>
          </div>
        )}
      </PostResLayout>

      <PostResLayout theme={THEMES.LIGHT_BLUE} noMinHeight noMarignTop noPaddingTopBottom>
        {rentalReady && (
          <RenterInformation
            {...props}
            showViewDetailsModal={showViewDetailsModal}
            showAHViewDetailsModal={showAHViewDetailsModal}
            showChangeReturnLocationDetailsModal={showChangeReturnLocationDetailsModal}
            isRental
          />
        )}
      </PostResLayout>

      <PostResLayout noMinHeight noMarignTop noPaddingTopBottom>
        {rentalReady && <RentalBranchLocation isRental rentalLocationType={locationType} />}
      </PostResLayout>

      {!!policies?.length && (
        <div ref={termsAndConditionsRef}>
          <PostResLayout theme={THEMES.LIGHT_BLUE} noMinHeight noMarignTop>
            <PoliciesComponent
              title={i18n('stc_confirmation_your_rentals_policies')}
              policies={policies}
              backgroundArtURL={ASSETS.RENTAL_POLICIES_PLACEHOLDER}
              backgroundArtAlt={i18n('policy_background_image_alt')}
              hideTermsConditionsCta
              isRental
            />
          </PostResLayout>
        </div>
      )}

      {policies && (
        <Modal
          modalKey={MODAL.RENTAL_DETAILS_AFTER_HOURS_MODAL}
          header={i18n('rental_details_after_hours_modal_header')}
          theme={MODAL_THEMES.WHITE}
        >
          <div
            className='modal-themed__section modal-themed__gbo-content'
            dangerouslySetInnerHTML={sanitize(afterHoursPolicyState)}
          />
        </Modal>
      )}

      <Modal
        modalKey={MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL}
        theme={MODAL_THEMES.WHITE}
        header={i18n('rental_details_taxes_fees_modal_header')}
      >
        <TaxesModalContent />
      </Modal>
      <ModifyReservationModal />
      <ChangeReturnLocationModal />
    </>
  );
};

ConfirmedRental.propTypes = {
  details: PropTypes.bool,
};

ConfirmedRental.defaultProps = {
  details: false, // hide the banner,if details is true.
};

export default ConfirmedRental;
