import { connect } from 'react-redux';
import { getPrepayTerms } from "actions/reservation/getPrepayTerms";
import PrePaymentTermsAndConditionsModalContent from './PrePaymentTCRequestModalContent';

const mapStateToProps = state => ({
  prepayTerms: state.getIn(['gmi', 'gma', 'content', 'terms', 'prepay', 'prepay_terms_and_conditions']),
});

const mapDispatchToProps = {
  getPrepayTerms,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrePaymentTermsAndConditionsModalContent);
