import PropTypes from 'prop-types';

export const propTypes = {
  link: PropTypes.string,
  lazyEnabled: PropTypes.bool,
  fileReference: PropTypes.isRequired,
  title: PropTypes.title,
  displayPopupTitle: PropTypes.string,
  alt: PropTypes.string,
};
