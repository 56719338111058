import { connect } from 'react-redux';
import { breakpointSelector, modalQueueSelector } from 'selectors';
import { MODAL } from 'constants';
import ResponsiveListbox from './ResponsiveListbox';

const { INVALID_DATE_FOR_RES_FLOW } = MODAL;

const mapStateToProps = state => ({
  breakpoint: breakpointSelector(state),
  isInvalidDateModalOpen: modalQueueSelector(state)?.modalKey === INVALID_DATE_FOR_RES_FLOW,
});

export default connect(mapStateToProps)(ResponsiveListbox);
