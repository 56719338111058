import React, { useRef, useState } from 'react';
import cn from 'classnames';
import utils from 'utils';
import { BREAKPOINTS, THEMES } from 'constants';
import VehicleGrid from 'components/VehicleGrid';
import VehicleGridHeader from 'components/VehicleGridHeader';
import SortFilterWrapper from 'components/SortFilterWrapper';
import CountrySelector from 'components/CountrySelector';
import VehicleFilterToggle from 'components/VehicleFilterToggle';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import { filters } from './vehicleGridViewFiltersConfig';

const VehicleGridView = (props) => {
  const {
    breakpoint,
    categoryName,
    countrySelector,
    ctaLink,
    isVehicleLandingPage,
    title,
    vehicles,
    numFiltersSelected,
    storeKey,
    locationCode,
  } = props;

  const vehicleFilterFormRef = useRef(null);
  const [filtersOpen, setFiltersOpen] = useState(false);

  // passing donotFetchStateAndProvinces as true,
  // to avoid calling state and provinces as we need country list that gives a response for drive type filter.
  const donotFetchStateAndProvinces = true;
  // passing loadingOverlay as false to avoid showing loading indicator while fetching country selector on vehicle pages.
  const loadingOverlay = false;

  const { selectedCountry } = useCountryFieldsConfig(locationCode, donotFetchStateAndProvinces, loadingOverlay);

  let filtersConfig = filters;
  const showDriveTypeFilter = selectedCountry?.country_content?.drive_type_filter;
  if (selectedCountry && !showDriveTypeFilter) {
    filtersConfig = filters.filter((item) => item.keyPath !== 'filters.DRIVE.filterCode');
  }

  const clearFilters = () => {
    //  We need to have ref here to get access to the reset function
    //  _outside_ the form
    vehicleFilterFormRef.current?.reset?.();
  };

  const handleToggleFilter = () => {
    setFiltersOpen(!filtersOpen);
    breakpoint.isMobile && utils.scrollLock({ toggleValue: !filtersOpen });
  };

  const filterWrapperClass = cn('vehicle-grid__filters vehicle-select__filter-wrapper', {
    'vehicle-select__filter-side-nav-expanded': filtersOpen,
  });

  return (
    <section className='row'>
      {!isVehicleLandingPage && ctaLink && (
        <div className='vehicle-grid__cta'>
          {breakpoint === BREAKPOINTS.SMALL && (
            <span>{utils.i18n('vehicle_grid_cta_showing_only', { 0: categoryName })}</span>
          )}
          <a className={breakpoint !== BREAKPOINTS.SMALL ? 'link--arrow' : ''} href={ctaLink.url}>
            {ctaLink.label}
          </a>
        </div>
      )}
      {isVehicleLandingPage && breakpoint === BREAKPOINTS.SMALL && (
        <CountrySelector {...countrySelector} currentPageTitle={title?.currentPageTitle || ''} />
      )}
      <nav className={filterWrapperClass}>
        <SortFilterWrapper
          filterFormRef={vehicleFilterFormRef}
          filterConfig={filtersConfig}
          storeKey={storeKey}
          open
          theme={THEMES.DARKEST_BLUE}
          resultsCount={vehicles?.length}
          closeButtonLabel={utils.i18n('filter_view_results')}
          onClose={handleToggleFilter}
          vertical
        />
        {filtersOpen && <VehicleFilterToggle numFiltersSelected={numFiltersSelected} onClick={handleToggleFilter} />}
      </nav>
      <div className='vehicle-grid__results'>
        <div className='vehicle-grid__header'>
          <VehicleGridHeader
            className='vehicle-grid__total'
            vehiclesCount={vehicles && vehicles.length ? vehicles.length : 0}
            isVehicleLandingPage={isVehicleLandingPage}
            categoryName={categoryName}
          />
          {isVehicleLandingPage && breakpoint !== BREAKPOINTS.SMALL && (
            <CountrySelector {...countrySelector} currentPageTitle={title?.currentPageTitle || ''} />
          )}
          {breakpoint === BREAKPOINTS.SMALL && (
            <VehicleFilterToggle numFiltersSelected={numFiltersSelected} onClick={handleToggleFilter} />
          )}
        </div>
        <div className='vehicle-grid__categories'>
          <VehicleGrid clearFilters={clearFilters} {...props} handleToggleFilter={handleToggleFilter} />
        </div>
      </div>
    </section>
  );
};

export default VehicleGridView;
