import React from 'react';
import utils from 'utils';
import FormattedPrice from 'components/FormattedPrice';
import propTypes from './BundleAddedCostPropTypes';

const BundleAddedCost = ({ selectedBundleWithExtras }) => {
  const bundlesExtras = selectedBundleWithExtras?.bundleExtras || [];
  const hasBundleExtras = !!bundlesExtras.length;

  return (
    hasBundleExtras && (
      <p className='rental-details__bundle-cost'>
        {utils.i18n(
          'rental_details_included_bundle_price',
          [
            <FormattedPrice key='0'>
              {utils.getFormattedPrice(selectedBundleWithExtras?.upgrade_bundle_view)}
            </FormattedPrice>,
          ],
          { jsx: true }
        )}
      </p>
    )
  );
};

BundleAddedCost.propTypes = propTypes;

export default BundleAddedCost;
