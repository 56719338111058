import React from 'react';
import { i18n } from 'utils/i18n';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT } = MODAL_BUTTON_TYPES;

const NoCurrentRentalModal = () => (
  <Modal modalKey={MODAL.NO_CURRENT_RENTAL} header={i18n('no_current_rental_found')} theme={MODAL_THEMES.WHITE}>
    {({ handleClose }) => (
      <>
        <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
          {i18n('no_current_rental_found_errMsg')}
        </p>

        <ModalFooter
          buttons={[
            {
              type: ACCEPT,
              label: i18n('common_ok'),
              largeButton: true,
              handler: handleClose,
            },
          ]}
        />
      </>
    )}
  </Modal>
);

export default NoCurrentRentalModal;
