import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'utils/i18n';
import './styles/error-banner.scss';

const ErrorBanner = ({ errorDescription }) => (
  <div className='errorBanner'>
    <div className='errorBanner__flex'>
      <span className='message-priority--ERROR'>{i18n('message_priority_ERROR')}</span>
      <p>{errorDescription}</p>
    </div>
  </div>
);

ErrorBanner.propTypes = {
  errorDescription: PropTypes.string.isRequired,
};

export default ErrorBanner;
