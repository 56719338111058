import { createSelector } from 'reselect';
import { currentFiltersSelector } from 'selectors/uiStateSelectors';

export const vehicleFiltersCursor = ['gmi', 'ui', 'filters', 'vehicle-page__grid'];

const getFilters = (state) => currentFiltersSelector(state, 'vehicle-page__grid');

export const filtersSelector = createSelector([getFilters], (filters) => {
  const keyPaths = ['filters.CLASS.filterCode', 'filters.TRANSMISSION.filterCode', 'filters.PASSENGERS.filterCode'];

  const inputs = [];

  filters.forEach((filter) => {
    const { available, keyPath, category } = filter;
    if (keyPaths.some((opt) => opt === keyPath)) {
      if (keyPath === 'filters.CLASS.filterCode') {
        inputs.unshift({
          legend: 'Vehicle Type',
          type: 'checkbox',
          keyPath: filter.keyPath,
          options: available.map(({ label, value, checked }) => ({
            id: `${keyPath}_${label}`,
            name: `${keyPath}_FILTER`,
            label,
            value,
            checked,
          })),
        });
      } else {
        inputs.push({
          type: 'select',
          keyPath: filter.keyPath,
          id: category,
          name: category,
          label: category,
          options: available.map(({ label, value, checked }) => ({
            id: label,
            name: label,
            label,
            value,
            checked,
          })),
        });
      }
    }
  });
  return inputs;
});
