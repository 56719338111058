import React from 'react';
import { i18n } from 'utils/i18n';
import { analyticsInteraction, analyticsPrepareKey } from 'utils/analytics';
import { loadingRedirect } from 'utils/redirect';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import ANALYTICS from 'constants/analytics';

const RentalNotEligibleForModificationsModal = ({ ticketNumber }) => (
  <Modal
    modalKey={MODAL.RENTAL_NOT_ELIGIBLE_FOR_MODIFICATION_MODAL}
    header={i18n('rental_not_eligible_title')}
    theme={MODAL_THEMES.WHITE}
  >
    {({ rentalTicketNumber, handleClose }) => (
      <>
        <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
          {i18n('rental_not_eligible_paragraph')}
        </p>
        <p className='modal-themed__paragraph modal-themed__paragraph--center-align'>
          <b>{i18n('trip_card_agreement_number', [ticketNumber || rentalTicketNumber])}</b>
        </p>

        <ModalFooter
          fullWidthButton
          buttons={[
            {
              type: MODAL_BUTTON_TYPES.DECLINE,
              label: i18n('common_cancel'),
              analyticsValue: ANALYTICS.CANCEL,
            },
            {
              type: MODAL_BUTTON_TYPES.ACCEPT,
              label: i18n('Rental_not_eligible_call_customer_service'),
              handler: () => {
                loadingRedirect(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT)
                handleClose({skipAnalytics: true})
              },
              analyticsHandler: () =>
                analyticsInteraction(
                  ANALYTICS.MODAL,
                  analyticsPrepareKey(MODAL.RENTAL_NOT_ELIGIBLE_FOR_MODIFICATION_MODAL),
                  ANALYTICS.CALL_CUSTOMER_SERVICE
                ),
            },
          ]}
        />
      </>
    )}
  </Modal>
);

export default RentalNotEligibleForModificationsModal;
