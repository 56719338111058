import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import FORMS from 'constants/forms';
import FieldState from 'components/Form/FieldsState';
import FieldControl from 'components/Form/FieldControl';

const { ACCEPT_VALUE, DECLINE_VALUE } = FORMS;

/**
 * AcceptDeclineToggle component
 * Set of radio group for accept/decline toggle
 *
 * @param {object} props - React Props
 * @param {string} props.name - Name of the input group
 * @param {string} props.onChange - Callback that will run on input value change
 * @param {boolean} props.disabled - Flag to disable the complete input
 * @param {string} props.acceptDTMTrack - DTM track attribute for accept option
 * @param {string} props.declineDTMTrack - DTM track attribute for decline option
 */
const AcceptDeclineToggle = ({ name, onChange, disabled, acceptDTMTrack, declineDTMTrack }) => (
  <FieldState names={[name]}>
    {fieldsState => {
      const { meta, input } = fieldsState[name];
      const { error, submitFailed } = meta;
      const showError = error && submitFailed;

      const options = [
        {
          label: utils.i18n('common_decline'),
          value: DECLINE_VALUE,
          dtmValue: declineDTMTrack,
        },
        {
          label: utils.i18n('common_accept'),
          value: ACCEPT_VALUE,
          dtmValue: acceptDTMTrack,
        },
      ];

      return (
        <div
          className={cn('field-control__accept-decline-container field-control__radio-container', {
            'field-control__accept-decline-container--with-error': showError,
            'field-control__accept-decline-container--accepted': input.value === ACCEPT_VALUE,
            'field-control__accept-decline-container--declined': input.value === DECLINE_VALUE,
            'field-control__accept-decline-container--disabled': disabled,
          })}>
          {options.map(({ value, label, dtmValue }) => (
            <FieldControl
              key={`${name}--${value}`}
              name={name}
              type='radio'
              value={value}
              label={label}
              id={`${name}--${value}`}
              onChange={onChange}
              required
              asterisk={false}
              dontShowError
              data-dtm-track={dtmValue}
              disabled={disabled}>
              <input />
            </FieldControl>
          ))}
        </div>
      );
    }}
  </FieldState>
);

AcceptDeclineToggle.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  acceptDTMTrack: PropTypes.string,
  declineDTMTrack: PropTypes.string,
};

export default AcceptDeclineToggle;
