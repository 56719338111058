import React from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'utils/i18n';
import { stepConfigSelector } from 'selectors/skipTheCounterSelectors';

const SkipTheCounterStepCount = () => {
  const { currentStepCounter } = useSelector(stepConfigSelector);
  const stepCounterLine = i18n('check_in_step_count', [currentStepCounter, 2]);

  return <span className='skip-the-counter-template__step-counter'>{stepCounterLine}</span>;
};

export default SkipTheCounterStepCount;
