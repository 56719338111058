import { connect } from 'react-redux';
import { getModifyFlag } from 'selectors';
import PrepaymentReadyDriverCard from './PrepaymentReadyDriverCard';

const mapStateToProps = (state, { airlineOptions }) => {
  const commitStatePath = ['gmi', 'ui', 'reservations', 'commit'];
  const isTrueModify = getModifyFlag(state);

  const propsPaths = {
    code: ['airline_information', 'code'],
    email_address: ['driver_info', 'email_address'],
    first_name: ['driver_info', 'first_name'],
    flight_number: ['airline_information', 'flight_number'],
    last_name: ['driver_info', 'last_name'],
    phone_number: ['driver_info', 'phone', 'phone_number'],
  };

  let props = Object.entries(propsPaths).reduce((acc, [prop, val]) => {
    acc[prop] = state.getIn(commitStatePath.concat(val));
    return acc;
  }, {});

  // For True Modify if something is changed in the res, the "Commit State" will only hvae th echanges,
  // so in this scase we have to look at the original reservation to pull any missing data to render in the UI.
  if (isTrueModify) {
    const resPath = ['gmi', 'session', 'gbo', 'reservation'];

    // Loop over the props we want to pass down, checking if the "commit state" has a value and if it does not,
    // falling back to the reservation state.
    props = Object.entries(props).reduce((acc, [prop, val]) => {
      // Of course in Commit state Air Line Info is `airline_information` where as in res session it is `airline_info`
      if (propsPaths[prop][0] === 'airline_information') {
        propsPaths[prop][0] = 'airline_info';
      }

      acc[prop] = val || state.getIn(resPath.concat(propsPaths[prop]));
      return acc;
    }, {});
  }

  // Set the airline based on the `airlineOptions` prop and the selected airline's code.
  props.airline = airlineOptions?.find(item => item.value === props.code)?.label;

  return props;
};

export default connect(mapStateToProps)(PrepaymentReadyDriverCard);
