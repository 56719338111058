import { connect } from 'react-redux';
import utils from 'utils';
import { openModal } from "actions/modal/openModal";
import PoliciesComponent from './PoliciesComponent';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  openModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(PoliciesComponent));
