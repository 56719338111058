import React from 'react';
import utils from 'utils';
import amex from 'images/payment-logos-secure-amex.svg';
import masterCard from 'images/payment-logos-secure-master-card.svg';
import visa from 'images/payment-logos-secure-visa.svg';

/**
 * SecureConnectionDisplay displays the credit card safety services icons
 * @return {JSX}
 */

function SecureConnectionDisplay() {
  return (
    <div className='secure-connection'>
      <p className='secure-connection__copy'>{utils.i18n('guaranteed_res_secure_connection_copy')}</p>

      <div className='secure-connection__card-icons'>
        <img className='secure-connection__card-icon' src={masterCard} alt={utils.i18n('mastercard_secure_code_alt')} />

        <img className='secure-connection__card-icon' src={amex} alt={utils.i18n('american_express_safe_key_alt')} />

        <img className='secure-connection__card-icon' src={visa} alt={utils.i18n('verified_by_visa_alt')} />
      </div>
    </div>
  );
}

export default SecureConnectionDisplay;
