import { connect } from 'react-redux';
import utils from 'utils';
import { vehicleRateSelector, isDestinationCurrencySelected } from 'selectors';
import TotalPriceFormatted from './TotalPriceFormatted';

const mapStateToProps = state => {
  const isDestinationCurrency = isDestinationCurrencySelected(state);
  const estimatedTotalPaymentDetails = utils.safeToJS(
    vehicleRateSelector(state)?.getIn(['price_summary', 'estimated_total_payment'])
  );
  const estimatedTotalViewDetails = utils.safeToJS(
    vehicleRateSelector(state)?.getIn(['price_summary', 'estimated_total_view'])
  );

  const estimatedTotalView = isDestinationCurrency ? estimatedTotalPaymentDetails : estimatedTotalViewDetails;

  const priceSummary = estimatedTotalPaymentDetails?.code === estimatedTotalViewDetails?.code;

  return {
    estimatedTotalView,
    isDestinationCurrency: isDestinationCurrency || priceSummary,
  };
};

export default connect(mapStateToProps)(utils.toJS(TotalPriceFormatted));
