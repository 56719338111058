import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { i18n } from 'utils/i18n';
import { dtm, analyticsInteraction } from 'utils/analytics';
import ANALYTICS from 'constants/analytics';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import Button from 'components/Button';
import DriverDetailsRow from 'components/PostRes/DriverDetailsRow';
import PostResRenterInformation from './PostResRenterInformation';

/**
 * PostResTripSummary
 *
 * @param {object} props - React Props
 * @param {function} props.handleModifyReservation - Function for "Modify Reservation" CTA
 * @param {string} props.editProfileLinkTo - Route string to redirect user for "Edit Details" CTA in Profile section (optional)
 *
 * @return {JSX}
 */
const PostResDriverDetails = ({ editProfileLinkTo }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);

  const openDrawerAndAnalytics = () => {
    setIsExpanded(!isExpanded);

    analyticsInteraction(ANALYTICS.DRAWER, 'drivers license info', !isExpanded ? ANALYTICS.OPEN : ANALYTICS.CLOSE);
  };

  return (
    <div
      className={cn('post-res__content theme--light', {
        'post-res__content--no-bottom-padding': !isExpanded,
      })}>
      <div className='post-res__section-header'>
        <h3 className='post-res__section-title'>{i18n('review_driver_details_title')}</h3>
      </div>

      {/* Renter Information Section */}
      <PostResRenterInformation />

      <Button
        onClick={() => openDrawerAndAnalytics()}
        className={cn('post-res-trip-summary__expand-button', {
          'post-res-trip-summary__expand-button--expanded': isExpanded,
        })}
        button={false}
        ariaExpanded={isExpanded.toString()}
        data-dtm-track={dtm(
          isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.CHECK_IN,
          ANALYTICS.REVIEW,
          'driver_license',
          isExpanded ? 'collapse' : 'expand'
        )}>
        {i18n('check_in_review_license')}
      </Button>
      {/* DL Details */}
      <div className={cn('post-res-trip-summary__drawer', { 'post-res-trip-summary__drawer--open': isExpanded })}>
        <DriverDetailsRow fromPostRes />

        {editProfileLinkTo && (
          <div className='post-res-trip-summary__drawer-footer'>
            <Link to={editProfileLinkTo} className='link--text link--arrow'>
              {i18n('check_in_review_summary_edit_details')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

PostResDriverDetails.propTypes = {
  handleModifyReservation: PropTypes.func,
  editProfileLinkTo: PropTypes.string,
};

export default PostResDriverDetails;
