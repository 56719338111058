import React from 'react';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import { actions } from '@ehi/global-marketing-interface';
import useActions from 'hooks/useActions';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';

const EmailAlreadyInUseModal = ({ openEditModal, isInsideProfileModifyModal, errorMessage, declineBtnLabel }) => {
  const cleanMessagesAction = useActions(actions.cleanMessages);

  const handleBack = (handleClose) => {
    handleClose({ skipAnalytics: true });
    openEditModal();
  };

  const handleSigninClick = (handleClose) => {
    window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.SIGN_IN_PAGE));
    handleClose({ analyticsValue: utils.i18n('common_sign_in').toLowerCase() });
  };

  let errorParagraph;

  if (errorMessage) {
    errorParagraph = errorMessage;
  } else if (isInsideProfileModifyModal) {
    errorParagraph = 'email_already_in_use_modal_according_to_our_records_text';
  } else {
    errorParagraph = 'email_already_in_use_modal_found_an_existing_text';
  }

  const declineButtonLabel = declineBtnLabel || utils.i18n('email_already_in_use_modal_back_button');

  return (
    <Modal
      modalKey={MODAL.EMAIL_ALREADY_IN_USE_MODAL}
      header={utils.i18n('email_already_in_use_modal_title')}
      theme={MODAL_THEMES.WHITE}
      customClass={'email-already-in-use__modal'}
      customActionOnClose={isInsideProfileModifyModal ? cleanMessagesAction : null}
    >
      {({ handleClose }) => (
        <>
          <p className='modal-themed__paragraph'>{utils.i18n(errorParagraph)}</p>

          {isInsideProfileModifyModal ? (
            <ModalFooter
              buttons={[
                {
                  type: MODAL_BUTTON_TYPES.EXTERNAL_LINK,
                  label: utils.i18n('common_contact_us'),
                  linkTo: utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOMER_SUPPORT),
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      ANALYTICS.MODAL,
                      'duplicate email',
                      utils.i18n('common_contact_us').toLowerCase()
                    ),
                  largeButton: true,
                  customClass: 'button--inverse',
                },
                {
                  type: MODAL_BUTTON_TYPES.ACCEPT,
                  label: utils.i18n('email_already_in_use_modal_button_try_different_email'),
                  handler: () => handleBack(handleClose),
                  largeButton: true,
                },
              ]}
            />
          ) : (
            <ModalFooter
              buttons={[
                {
                  type: MODAL_BUTTON_TYPES.DECLINE,
                  label: declineButtonLabel,
                  handler: () => handleClose({ analyticsValue: declineButtonLabel.toLowerCase() }),
                  largeButton: true,
                },
                {
                  type: MODAL_BUTTON_TYPES.ACCEPT,
                  label: utils.i18n('common_sign_in'),
                  handler: () => handleSigninClick(handleClose),
                  largeButton: true,
                },
              ]}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default EmailAlreadyInUseModal;
