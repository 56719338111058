import { connect } from 'react-redux';
import utils from 'utils';
import { guaranteedResCardRequiredSelector } from 'selectors/reservationSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';

import { getDidPrepay, appliedCancelFeeDetailsSelector, cancelFeeSelector } from 'selectors/reservationFlowSelectors';
import { cancelReservation } from 'actions/reservation/cancelReservation';
import ConfirmationCancelReservationModal from './ConfirmCancelReservationModal';

const mapStateToProps = (state) => ({
  prepaySelected: getDidPrepay(state),
  appliedCancelFeeDetails: appliedCancelFeeDetailsSelector(state),
  guaranteedResCardRequired: guaranteedResCardRequiredSelector(state),
  cancelFee: cancelFeeSelector(state),
  isCustomPathReservation: isCustomPathReservationSelector(state),
});

const mapDispatchToProps = {
  cancelReservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ConfirmationCancelReservationModal));
