import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/Header';

/**
 * RentalFlowTemplate component
 * This wrapper component provides post-res SPA flows with the overall layout of
 *  a true modify flow - including the navigation bar and condensed footer.
 *
 * Hide footer when is Custom Path Check In Flow SPA
 *
 * @param {object} props
 * @param {object} props.children - React Components/Nodes to be rendered at the "main section" main section of the layout
 *
 * @return {JSX} RentalFlowTemplate jsx component
 */
const RentalFlowTemplate = ({ children, hideNavBar }) => (
  <section className='post-res__modify-rental-flow'>
    {hideNavBar && <Header alwaysShowKeyFactsDropdown isRentalFlow showDiscountElements={false} />}
    {children}
  </section>
);

RentalFlowTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  hideNavBar: PropTypes.bool,
};

export default RentalFlowTemplate;
