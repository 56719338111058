import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { breakpointSelector } from 'selectors';

import ProgressBarModifyReturn from './ProgressBar';

const ProgressBarModifyReturnContainer = (props) => {
  const breakPoint = useSelector(breakpointSelector);
  const history = useHistory();

  return (
    <ProgressBarModifyReturn
      {...{
        breakPoint,
        history,
        ...props,
      }}
    />
  );
};

export default ProgressBarModifyReturnContainer;
