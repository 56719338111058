import React from 'react';
import { useSelector } from 'react-redux';
import { config } from 'utils/config';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import { getFormattedPrice } from 'utils/prices';
import { getLocationCompleteName } from 'utils/locations';
import cn from 'classnames';
import {
  getReservationPickupLocation,
  getReservationPickupTime,
  getReservationReturnLocation,
  getReservationReturnTime,
  getOneWayRentalFlag,
  sessionReservationObjectSelector,
} from 'selectors/reservationSelectors';
import { estimatedTotalViewSelector, isCheckedInReservationSelector } from 'selectors/reservationFlowSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import FormattedPrice from 'components/FormattedPrice';

const SEPARATOR = ' | ';
const DATES_SEPARATOR = ' - ';

/**
 * Rental Info Line component
 * Displays the Rental Info in a single line - for use within the Post Rental Templates
 *
 * @return {JSX} PostResRentalInfoLine jsx component
 */
const PostResRentalInfoLine = ({ isSTCFlow = false, isStcBridgePage = false }) => {
  const isOneWayRental = useSelector(getOneWayRentalFlag);
  const pickupLocation = useSelector(getReservationPickupLocation);
  const pickupTime = useSelector(getReservationPickupTime);
  const returnLocation = useSelector(getReservationReturnLocation);
  const returnTime = useSelector(getReservationReturnTime);
  const estimatedTotal = useSelector(estimatedTotalViewSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const isCheckedInReservation = useSelector(isCheckedInReservationSelector);
  const reservationObj = useSelector(sessionReservationObjectSelector);
  const confirmationNumber = reservationObj?.get('confirmation_number');

  const pickupDate = gmiUtils.getDateTimeObjFromTs(pickupTime).format(config.getDateTimeShortFormat('ll_no_year'));
  const returnDate = gmiUtils.getDateTimeObjFromTs(returnTime).format(config.getDateTimeShortFormat('ll_no_year'));

  const getCheckInBadge = () => (
    <div className='post-res-rental-info__check-in-badge-container'>
      <span
        className={cn('post-res-rental-info__check-in-label', {
          'post-res-rental-info__check-in-label--activated': isCheckedInReservation,
        })}>
        {i18n('common_check_in')}:
      </span>
      <span
        className={cn('post-res-rental-info__check-in-label-badge', {
          'post-res-rental-info__check-in-label-badge--activated': isCheckedInReservation,
        })}
        aria-hidden='true'>
        {isCheckedInReservation ? i18n('trip_card_activated_label') : i18n('trip_card_not_activated_label')}
      </span>
    </div>
  );

  return (
    <>
      <div
        className={cn('post-res-rental-info__container', {
          'post-res-rental-info__container-custom-path': isCustomPathReservation && isSTCFlow,
        })}>
        <p className='post-res-rental-info'>
          {isSTCFlow && (
            <>
              <span className='post-res-rental-info--stc-confirmation'>
                {i18n('confirmation_number', [confirmationNumber])}
              </span>
              {SEPARATOR}
            </>
          )}
          {getLocationCompleteName(pickupLocation)}
          {isOneWayRental && ` - ${getLocationCompleteName(returnLocation)}`}
          {SEPARATOR}
          <span className='post-res-rental-info__date'>{pickupDate}</span>
          {DATES_SEPARATOR}
          <span className='post-res-rental-info__date'>{returnDate}</span>
          {!isCustomPathReservation && !isStcBridgePage && (
            <>
              {SEPARATOR}
              <FormattedPrice>{getFormattedPrice(estimatedTotal)}</FormattedPrice>
            </>
          )}
        </p>
      </div>
      {isCustomPathReservation && isSTCFlow && getCheckInBadge()}
    </>
  );
};

export default PostResRentalInfoLine;
