import { connect } from 'react-redux';
import utils from 'utils';
import { profileDataSelector } from 'selectors';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import AccountAndPassword from './AccountAndPassword';

const mapStateToProps = (state) => ({
  profileData: profileDataSelector(state, {
    first_name: true,
    last_name: true,
    email: true,
    email_preference: true,
    email_update_required: true,
  }),
});

const mapDispatchToProps = {
  openEditModal: () => openModal(MODAL.EDIT_ACCOUNT),
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(AccountAndPassword));
