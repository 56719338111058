import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import FORMS from 'constants/forms';
import RESERVATIONS from 'constants/reservations';
import MODAL from 'constants/modals';
import useActions from 'hooks/useActions';
import Button from 'components/Button';
import AcceptDeclineToggle from 'components/Form/AcceptDeclineToggle';
import { extrasUpdate } from "actions/reservation/extrasUpdate";
import { openModalWithProps } from "actions/modal/openModalWithProps";

const { EXTRAS_INCLUDED_STATUS, EXTRAS_OPTIONAL_STATUS } = RESERVATIONS;
const { ACCEPT_VALUE } = FORMS;

/**
 * STCProtectionItem
 * Protection Item line for STC flow review card, with item name, pricing, details modal and Accept/Decline toggle.
 *
 * @param {object} props
 * @param {object} props.item - Protection item object
 *
 * @return {JSX}
 */
const STCProtectionItem = ({ item }) => {
  const {
    code,
    name,
    detailed_description,
    status,
    rate_type,
    selected_quantity,
    rate_amount_view,
    total_amount_view,
  } = item;
  const handleExtrasUpdate = useActions(extrasUpdate);
  const openSTCProtectionOptionDetails = useActions(() =>
    openModalWithProps(MODAL.STC_PROTECTION_OPTION_DETAILS_MODAL, { header: name, detailed_description })
  );
  const isIncrementalPricing = utils.config.getIsIncrementalPricingEnabled();
  // This is a bit "hacky", but the Protection Option Accept/Decline have an "empty" state (null),
  // so we control this in a ref for checks on input change
  const isAcceptedRef = useRef(selected_quantity > 0 ? true : null);
  const [isLoading, updateIsLoading] = useState(false);

  const priceDisplay = utils.getFormattedPrice(total_amount_view);
  const incrementalPriceDisplay = `${utils.getFormattedPrice(rate_amount_view)}/${utils.i18n(
    `extras_rate_unit_${rate_type}`
  )}`;

  const onChange = (e, input) => {
    const { value } = input;
    const newValueIsAccepted = value === ACCEPT_VALUE;
    updateIsLoading(true);
    handleExtrasUpdate(item, newValueIsAccepted ? 1 : 0).then(() => updateIsLoading(false));
    isAcceptedRef.current = newValueIsAccepted;
  };

  return (
    <div className='skip-the-counter-flow-protection-item'>
      <p className='skip-the-counter-flow-protection-item__heading'>
        {name}{' '}
        {status !== EXTRAS_INCLUDED_STATUS && (
          <span className='skip-the-counter-flow-protection-item__price'>
            {isIncrementalPricing ? incrementalPriceDisplay : priceDisplay}
          </span>
        )}
      </p>
      <Button
        onClick={openSTCProtectionOptionDetails}
        link
        linkText
        className='skip-the-counter-flow__link-text skip-the-counter-flow-protection-item__details-button'
        data-track-dtm={`check_in|counter_detail|${code}`}
      >
        {utils.i18n('stc_view_details')}
      </Button>
      <div className='skip-the-counter-flow-protection-item__radio-group'>
        {status === EXTRAS_OPTIONAL_STATUS && (
          <AcceptDeclineToggle
            name={`opt-in-${code}`}
            onChange={onChange}
            disabled={isLoading}
            acceptDTMTrack={`check_in|counter_add|${code}`}
            declineDTMTrack={`check_in|counter_remove|${code}`}
          />
        )}
      </div>
    </div>
  );
};

STCProtectionItem.propTypes = {
  item: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    detailed_description: PropTypes.string,
    status: PropTypes.string,
    rate_type: PropTypes.string,
    selected_quantity: PropTypes.number,
    rate_amount_view: PropTypes.object,
    total_amount_view: PropTypes.object,
  }),
};

export default STCProtectionItem;
