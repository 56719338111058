import React from 'react';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import utils from 'utils';

/**
 * LearnMoreAboutOCIActivatedModal - Show a little description of what is accelerated check-in
 * @return {JSX} LearnMoreAboutOCIActivatedModal component
 */

const LearnMoreAboutOCIActivatedModal = () => (
  <Modal
    modalKey={MODAL.LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_ACTIVATED_MODAL}
    theme={MODAL_THEMES.WHITE}
    header={utils.i18n('learn_more_about_accelerated_checkin_modal_title')}>
    <>
      <div id={`${MODAL.LEARN_MORE_ABOUT_ACCELERATED_CHECKIN_ACTIVATED_MODAL}_desc`}>
        <p className='modal-themed__paragraph'>
          <strong>{utils.i18n('learn_more_activated_accelerated_checkin_modal_bold_paragraph_one')}</strong>
        </p>
        <p>{utils.i18n('learn_more_activated_accelerated_checkin_modal_paragraph_two')}</p>
      </div>

      <ModalFooter
        buttons={[
          {
            type: MODAL_BUTTON_TYPES.ACCEPT,
            label: utils.i18n('common_close'),
          },
        ]}
      />
    </>
  </Modal>
);

export default LearnMoreAboutOCIActivatedModal;
