import PropTypes from 'prop-types';

export const propTypes = {
  prepayTermsLink: PropTypes.element,
  oppositeEstimatedTotal: PropTypes.object,
  breakpoint: PropTypes.object,
  isForeignCurrency: PropTypes.bool,
  estimatedTotal: PropTypes.object,
  estimatedTotalPayment: PropTypes.object,
  showLearnMoreModal: PropTypes.func,
  handleTogglePrepay: PropTypes.func,
  isTrueModify: PropTypes.bool,
  prepayTotal: PropTypes.string,
};
