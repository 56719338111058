import { connect } from 'react-redux';
import { getLoyaltyTerms } from 'actions/content';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import LoyaltyTermsConditionsLink from './LoyaltyTermsConditionsLink';

const mapStateToProps = state => ({
  loyaltyTerms: state.getIn(['gmi', 'gma', 'content', 'terms', 'loyalty', 'terms_and_conditions']),
});

const mapDispatchToProps = {
  getLoyaltyTerms,
  handleOpenModal: () => openModal(MODAL.LOYALTY_TERMS_AND_CONDITIONS),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoyaltyTermsConditionsLink);
