import { connect } from 'react-redux';
import utils from 'utils';
import { profileDataSelector } from 'selectors';
import { MODAL } from 'constants';
import { openModal } from "actions/modal/openModal";
import ProfilePRP from './ProfilePRP';

const mapStateToProps = state => ({
  profileData: profileDataSelector(state, { partnerRewardsProgram: true }),
});

const mapDispatchToProps = {
  openEditModal: () => openModal(MODAL.EDIT_PROFILE_PRP_MODAL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.toJS(ProfilePRP));
