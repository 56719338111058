import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import cn from 'classnames';
import { breakpointSelector } from 'selectors/breakpoint';
import Button from 'components/Button';

const LocationSuggestion = ({
  location,
  locationKey,
  isFocused,
  resetFocusedState,
  isDragging,
  selectLocation,
  input,
}) => {
  const { name, airport_code = null, address } = location;
  const itemRef = useRef(null);
  const { isDesktop } = useSelector(breakpointSelector);

  const handleOnClick = () => {
    // This is for mobile devices:  We use the touch end device to determine when the user has selected a location.
    // But we don't want the user to be scrolling and on the touch end of the scroll we select that city/branch
    // to prevent this we have a var that tells us when the user is dragging.  If the user is dragging aka scrolling
    // don't select the location.
    if (!isDragging) {
      utils.safeExecute(selectLocation(location));
      document.getElementById(input.name).blur();
    }
  };

  const handleKeyDown = (e) => {
    const key = utils.accessibility.formatKeys(e);
    if (key.space || key.enter) {
      e.preventDefault();
      handleOnClick();
    }
  };

  if (isFocused && itemRef.current) {
    itemRef.current.focus();
  }

  return (
    <li
      ref={itemRef}
      className={cn('locationSearch__results__category__items__item', {
        'locationSearch__results__category__items__item--focused': isFocused,
      })}
      id={location.id}
      tabIndex='-1'
      aria-selected={isFocused ? 'true' : 'false'}
      role='option'
      onMouseDown={handleOnClick}
      onTouchEnd={handleOnClick}
      onKeyDown={handleKeyDown}
      onMouseEnter={resetFocusedState}
    >
      <div className='locationSearch__results__category__items__item__info'>
        {name} {airport_code && `(${airport_code})`}{' '}
        {locationKey !== 'cities' && address && (
          <span>{`${address?.city && address?.city !== 'undefined' ? `${address?.city},` : ''}
          ${
            address?.country_subdivision_code && address?.country_subdivision_code !== 'undefined'
              ? ` ${address?.country_subdivision_code}`
              : ''
          } 
          ${address?.postal && address?.postal !== 'undefined' ? `${address?.postal},` : ''} ${
            address?.country_code && address?.country_code !== 'undefined' ? address?.country_code : ''
          }`}</span>
        )}
      </div>
      {isDesktop && (
        <Button className='locationSearch__results__category__items__item__cta'>
          {utils.i18n('location_search_select_cta')}
        </Button>
      )}
    </li>
  );
};

LocationSuggestion.propTypes = {
  locationKey: PropTypes.string,
  selectLocation: PropTypes.func.isRequired,
  id: PropTypes.string,
  location: PropTypes.object,
  isDragging: PropTypes.bool,
  isFocused: PropTypes.bool,
  resetFocusedState: PropTypes.func,
  input: PropTypes.object,
};

export default LocationSuggestion;
