import { connect } from 'react-redux';
import { setProductCode } from 'actions/bookingWidget';
import MODAL_KEYS from 'constants/modals';
import { clearDiscountInReservationFlow } from "actions/reservation/clearDiscountInReservationFlow";
import { closeModal } from "actions/modal/closeModal";
import { openModalWithProps } from "actions/modal/openModalWithProps";
import CouponNavigationBanner from './CouponNavigationBanner';

const mapDispatchToProps = {
  setProductCode,
  clearDiscountInReservationFlow,
  closeModal,
  openRemoveProductCodeModal: (productCode, onConfirm) =>
    openModalWithProps(MODAL_KEYS.REMOVE_PRODUCT_CODE_MODAL, { productCode, onConfirm }),
  openRemoveCouponModal: couponDescription => openModalWithProps(MODAL_KEYS.REMOVE_COUPON_MODAL, { couponDescription }),
};

export default connect(
  null,
  mapDispatchToProps
)(CouponNavigationBanner);
