import React from 'react';
import { Container } from '@adobe/aem-react-editable-components';
import cn from 'classnames';

/**
  This is a Section component - mapps to Section in AEM
*/
// TODO: once AEM model is ready, this component needs to be hooked up correctly (DA)
class Section extends Container {
  get containerProps() {
    return {
      ...super.containerProps,
      className: cn(super.containerProps.className, 'cq-Editable-dom cq-Editable-dom--container'),
    };
  }

  get placeholderProps() {
    return {
      ...super.placeholderProps,
      placeholderClassNames: `${super.placeholderProps.placeholderClassNames} aem-Grid-newComponent cq-Editable-dom`,
    };
  }

  render() {
    return (
      <section {...this.containerProps}>
        {this.childComponents}
        {super.placeholderComponent}
      </section>
    );
  }
}

export default Section;
