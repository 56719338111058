import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { ANALYTICS, MODAL, MODAL_THEMES, MODAL_BUTTON_TYPES, THEMES } from 'constants';
import Form from 'components/Form/Form';
import Checkbox from 'components/Form/Checkbox';
import RadioGroup from 'components/Form/RadioGroup';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

// Local constants
const EXISTING_PARTNER = 'existing_partner';
const UPDATE_PROFILE = 'update_profile';

/**
 * PRPAuthConflictModal
 *
 * @param {object} props - React Props
 * @param {string} props.partner_rewards_program_name - Booking Widget selected PRP complete name
 * @param {object} props.profileData - Auth user profile data with preferred Partner Rewards Program
 * @param {function} props.handleInitiate - Function to initiate the reservation
 * @param {function} props.setContractNumber - Function to set or unset reservation contract for initiate request
 * @param {function} props.setPartnerRewardProgram - Function to set or unset PRP info for initiate request
 * @param {function} props.setPartnerRewardProgramMemberId - Function to set or unset PRP member ID for initiate request
 * @param {function} props.setUpdateProfileWithPartnerRewards - Function to set a flag to update the profile with the selected program from the Booking Widget
 *
 * @return {JSX} PRPAuthConflictModal jsx component
 */
class PRPAuthConflictModal extends Component {
  state = {
    selectedOption: UPDATE_PROFILE,
    requiredError: false,
    feeAccepted: false,
  };

  handleSubmit = handleClose => () => {
    const {
      profileData,
      setPartnerRewardProgram,
      setPartnerRewardProgramMemberId,
      setContractNumber,
      setUpdateProfileWithPartnerRewards,
      handleInitiate,
    } = this.props;
    const { selectedOption, requiredError, feeAccepted } = this.state;
    if (selectedOption === UPDATE_PROFILE) {
      // If fee is not accepted when updating the profile, user will see a error message and will not initiate
      if (!feeAccepted) {
        this.setState({ requiredError: !requiredError });
        return;
      }

      // If fee is accepted, the flag to update user profile with new PRP details will be set as true
      setUpdateProfileWithPartnerRewards(true);
    } else {
      // If user wants to continue with existing program, the PRP and contract details form initiate request will be cleared
      setContractNumber();
      setPartnerRewardProgram();
      setPartnerRewardProgramMemberId();
    }
    // Initiate the reservation with selected options
    handleInitiate().then(() =>
      handleClose({
        analyticsValue:
          selectedOption === UPDATE_PROFILE
            ? `update & reserve with ${profileData?.partnerRewardsProgram.name}`
            : 'proceed with existing partner',
      })
    );
  };

  // Selected option toggle between initiate with existing or update profile with new PRP
  onSelect = (e, input) => this.setState({ selectedOption: input.value });

  // Checkbox for Frequent Traveler Recovery Fee acceptance
  handleCheckbox = () => this.setState({ feeAccepted: !this.state.feeAccepted });

  // As ReactFinalForm needs a onSubmit prop, we load it with this dummy submit
  dummySubmit = () => false;

  render() {
    const { partner_rewards_program_name, profileData } = this.props;
    const { selectedOption, requiredError, feeAccepted } = this.state;
    const formInitialValues = { prpConflictOption: selectedOption };

    return (
      <Modal
        modalKey={MODAL.PRP_AUTH_CONFLICT_MODAL}
        header={utils.i18n('prp_auth_conflict_title')}
        theme={MODAL_THEMES.WHITE}>
        {({ handleClose }) => (
          <>
            <p className='partner-rewards-modal-content__copy'>{utils.i18n('prp_auth_conflict_copy_1')}</p>
            <p className='partner-rewards-modal-content__copy partner-rewards-modal-content__copy--bold'>
              {`${profileData?.partnerRewardsProgram.name}, ${profileData?.partnerRewardsProgram.number}`}
            </p>
            <p className='partner-rewards-modal-content__copy'>
              {utils.i18n('prp_auth_conflict_copy_2', [partner_rewards_program_name])}
            </p>

            <Form initialValues={formInitialValues} onSubmit={this.dummySubmit}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className='partner-rewards-modal-content__form'>
                  <RadioGroup
                    name='prpConflictOption'
                    onChange={this.onSelect}
                    buttons={[
                      {
                        label: utils.i18n('prp_auth_conflict_radio_existing_partner'),
                        value: EXISTING_PARTNER,
                      },
                      {
                        label: utils.i18n('prp_auth_conflict_radio_update_profile', [partner_rewards_program_name]),
                        value: UPDATE_PROFILE,
                      },
                    ]}
                  />
                  {selectedOption === UPDATE_PROFILE && (
                    <div className='partner-rewards-conflict-form__fee-checkbox'>
                      {requiredError && !feeAccepted && (
                        <p className='partner-rewards-conflict-form__error'>
                          {utils.i18n('prp_auth_conflict_checkbox_error')}
                        </p>
                      )}
                      <Checkbox
                        checked={feeAccepted}
                        label={utils.i18n('prp_auth_conflict_checkbox_label', [partner_rewards_program_name])}
                        theme={THEMES.LIGHT}
                        name='updateProfileWithPRP'
                        onChange={this.handleCheckbox}
                      />
                      <p className='partner-rewards-conflict-form__checkbox-helper'>
                        {utils.i18n('prp_auth_conflict_checkbox_copy')}
                      </p>
                    </div>
                  )}
                </form>
              )}
            </Form>

            <ModalFooter
              buttons={[
                {
                  type: DECLINE,
                  label: utils.i18n('prp_auth_conflict_cancel'),
                  analyticsValue: ANALYTICS.CANCEL,
                },
                {
                  type: ACCEPT,
                  label: utils.i18n('prp_auth_conflict_continue'),
                  handler: this.handleSubmit(handleClose),
                  // Note we _dont_ need an `analyticsHandler` here because `this.handleSubmit` covers analytics
                },
              ]}
            />
          </>
        )}
      </Modal>
    );
  }
}

PRPAuthConflictModal.propTypes = {
  partner_rewards_program_name: PropTypes.string,
  profileData: PropTypes.object,
  handleInitiate: PropTypes.func,
  setContractNumber: PropTypes.func,
  setPartnerRewardProgram: PropTypes.func,
  setPartnerRewardProgramMemberId: PropTypes.func,
  setUpdateProfileWithPartnerRewards: PropTypes.func,
};

export default PRPAuthConflictModal;
