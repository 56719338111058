import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

/**
 * ReceiptBlockPrices - Receipt block price
 * @param {object} props - React Props
 * @param {string} props.title - Block title
 * @param {object} props.list - Block data
 * @param {string} props.className - Block custom class name
 * @return {JSX}
 */
const ReceiptBlockPrices = ({ title, list, className, marginTop }) => (
  <>
    {!!title && (
      <tr align='left' className={cn('receipt-charges-block', { 'receipt-charges-block-top': marginTop })}>
        <th align='left' scope='row' rowSpan='2'>
          {title}
        </th>
      </tr>
    )}
    {list.map((item, index) => (
      <tr
        key={index}
        className={cn('receipt-charges-block-prices', {
          [`${className}__list`]: className,
          'border-bottom': index === list.length - 1,
          [className]: className,
        })}>
        <th align='left' scope='row'>
          {item.label}
        </th>
        <td align='right'>{item.value}</td>
        {item?.list && <ReceiptBlockPrices list={item.list} className='receipt-charges-block-sub' />}
      </tr>
    ))}
  </>
);

ReceiptBlockPrices.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  className: PropTypes.string,
};

export default ReceiptBlockPrices;
